import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Company } from 'app/main/pages/company/company.model';
import { RestService } from '../../../shared/rest.service';
import { AccountType } from 'app/main/pages/accounts/account.model';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from '../pages.service';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements Resolve<any> {
  onCompanyListChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;
  onCompanyChanged: Subject<any>;
  companies: Company[];
  searchText: string;
  companyPageNumber: number = 1;
  companyPageSize: number = 100;
  sortBy: string = "companyName";
  sortDirection: string = "asc";
  companyTotalCount: number = 0;
  companyData: any = {};
  onCompanyDataChanged: BehaviorSubject<any>;
  onCompanyEdit: BehaviorSubject<boolean>;
  onCompanySave: Subject<any>;
  onPerMessageCostEdit: BehaviorSubject<any>;
  onUpdatePerMessageCost: BehaviorSubject<any>;
  onAccountsChanged: BehaviorSubject<any>;
  onExternalAccountsChanged: BehaviorSubject<any>;
  onAllAccountsChanged: BehaviorSubject<any>;
  onAllExternalAccountsChanged: BehaviorSubject<any>;
  onAddTemplate: BehaviorSubject<any>;
  accounts: any[];
  externalAccounts: any[];
  AllAccounts: any[];
  allExternalAccounts: any[]
  constructor(
    private _restService: RestService,
    private _authService: AuthService,
    private _pageService: PageService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _toastr: ToastrService
  ) {
    this.init();
  }

  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.init()
      ]).then(
        ([files]) => {
          resolve('init');
        },
        reject
      );
    });
  }
  init() {
    this.searchText = "";
    this.companyPageNumber = 1;
    this.companyPageSize = 100;
    this.sortBy = "companyName";
    this.sortDirection = "asc";
    this.companyTotalCount = 0;
    this.companyData = {};
    this.onCompanyListChanged = new BehaviorSubject([]);
    this.onSearchTextChanged = new Subject();
    this.onCompanyDataChanged = new BehaviorSubject({});
    this.onCompanyChanged = new Subject();
    this.onCompanyEdit = new BehaviorSubject(false);
    this.onCompanySave = new Subject();
    this.accounts = [];
    this.externalAccounts = [];
    this.AllAccounts = [];
    this.allExternalAccounts = [];
    this.onAccountsChanged = new BehaviorSubject([]);
    this.onExternalAccountsChanged = new BehaviorSubject([]);
    this.onAllAccountsChanged = new BehaviorSubject([]);
    this.onAllExternalAccountsChanged = new BehaviorSubject([]);
    this.onAddTemplate = new BehaviorSubject(false);
    this.onPerMessageCostEdit = new BehaviorSubject(null);
    this.onUpdatePerMessageCost = new BehaviorSubject(null);
  }

  getCompanies(companyReq): Promise<any> {
    return new Promise((resolve, reject) => {
      companyReq.pageNumber = this.companyPageNumber;
      companyReq.pageSize = this.companyPageSize;
      companyReq.sortBy = this.sortBy;
      companyReq.sortDirection = this.sortDirection;
      this._fuseSplashScreenService.show();
      this._restService.Post('/Company/GetAllCompany', companyReq)
        .subscribe((response: any) => {
          this._fuseSplashScreenService.hide();
          if (response.status == 200) {
            this.companies = response.data.report;
            this.companyTotalCount = response.data.totalCount;
            this.onCompanyListChanged.next(this.companies);
            resolve(this.companies);
          }
        },
          error => {
            this._fuseSplashScreenService.hide();
          },
          reject);
    }
    );
  }

  saveCompany(CompanyRequest: any) {
    var _url = "/Company/AddEditCompany";
    return this._restService.Post(_url, CompanyRequest);
  }

  editCompany(CompanyRequest: any) {
    var _url = "/Company/AddEditCompany";
    return this._restService.Post(_url, CompanyRequest);
  }
  getCompanyById(id: any) {
    return this._restService.Get('/Company/GetCompany/' + id)
  }

  uploadImage(data) {
    var _url = "/Company/UploadImage";
    return this._restService.PostFile(_url, data);
  }
  deleteCompany(companyIds) {
    var _url = "/Company/DeleteCompany";
    return this._restService.Delete(_url, companyIds);
  }
  getCompanyDataById(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._fuseSplashScreenService.show();
      this._restService.Get('/Company/GetCompany/' + id)
        .subscribe((response: any) => {
          this._fuseSplashScreenService.hide();
          if (response.status == 200) {
            this.companyData = response.data;
            this.onCompanyDataChanged.next(this.companyData);
            resolve(this.companyData);
          }
        },
          error => {
            this._fuseSplashScreenService.hide();
          },
          reject);
    }
    );
  }

  getAccounts(CompanyId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._fuseSplashScreenService.show();
      this._restService.Get('/Company/GetAllUnifiedAccounts/' + CompanyId)
        .subscribe((response: any) => {
          this._fuseSplashScreenService.hide();
          if (response.status == 200) {
            this.accounts = response.data;
            this.AllAccounts = response.data;
            this.onAccountsChanged.next(this.accounts);
            this.onAllAccountsChanged.next(this.accounts);
            resolve(this.accounts);
          }
        },
          error => {
            this._fuseSplashScreenService.hide();
          },
          reject);
    }
    );
  }
  getAccountsByType(CompanyId: number, TypeId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._restService.Get('/Company/GetUnifiedAccountByCompanyAndType/' + CompanyId + '/' + TypeId)
        .subscribe((response: any) => {
          this._fuseSplashScreenService.hide();
          if (response.status == 200) {
            if (response.data) {
              this.accounts = [response.data];
            }
            else {
              this.accounts = [];
            }
            this.onAccountsChanged.next(this.accounts);
            resolve(this.accounts);
          }
        },
          error => {
            this._fuseSplashScreenService.hide();
          }, reject);
    }
    );
  }  
  deleteAccount(id: number) {
    var _url = "/Company/DeleteUnifiedAccount/" + id;
    return this._restService.Delete(_url, null);
  }
  getAllaccounts(companyId: number) {
    return this._restService.Get('/Company/GetAllUnifiedAccounts/' + companyId)
  }
  getAccountById(id: any) {
    return this._restService.Get('/Company/GetUnifiedAccountById/' + id)
  }
  saveAccount(CompanyId: number, AccountRequest: any) {
    var _url = "/Company/CreateUnifiedAccount/" + CompanyId;
    return this._restService.Post(_url, AccountRequest);
  }
  editAccount(CompanyId: number, AccountRequest: any) {
    var _url = "/Company/UpdateUnifiedAccount/" + CompanyId;
    return this._restService.Post(_url, AccountRequest);
  }
  getSubCompanies(req: any) {
    return this._restService.Post('/Company/GetAllCompany', req);
  }
  getExternalAccounts(CompanyId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._fuseSplashScreenService.show();
      this._restService.Get('/Company/GetAllExternalAccounts/' + CompanyId)
        .subscribe((response: any) => {
          this._fuseSplashScreenService.hide();
          if (response.status == 200) {
            this.externalAccounts = response.data;
            this.allExternalAccounts = response.data;
            this.onExternalAccountsChanged.next(this.externalAccounts);
            this.onAllExternalAccountsChanged.next(this.externalAccounts);
            resolve(this.externalAccounts);
          }
        },
          error => {
            this._fuseSplashScreenService.hide();
          },
          reject);
    }
    );
  }
  getExternalAccountsByType(CompanyId: number, TypeId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._restService.Get('/Company/GetExternalAccountByCompanyAndType/' + CompanyId + '/' + TypeId)
        .subscribe((response: any) => {
          this._fuseSplashScreenService.hide();
          if (response.status == 200) {
            if (response.data) {
              this.externalAccounts = [response.data];
            }
            else {
              this.externalAccounts = [];
            }
            this.onExternalAccountsChanged.next(this.externalAccounts);
            resolve(this.externalAccounts);
          }
        },
          error => {
            this._fuseSplashScreenService.hide();
          }, reject);
    }
    );
  } 
  deleteExternalAccount(id: number) {
    var _url = "/Company/DeleteExternalAccount/" + id;
    return this._restService.Delete(_url, null);
  }
  getCompanyRank(id) {
    return this._restService.Get('/Company/GetCompanyRanks/' + id);
  }
  updateCompanyRank(id, formData) {
    var _url = "/Company/UpdateCompanyRanks/" + id;
    return this._restService.Post(_url, formData);
  }
  getCompanyAssociateTypes(id) {
    return this._restService.Get('/Company/GetCompanyAssociateTypes/' + id);
  }
  updateAssociateTypes(id, formData) {
    var _url = "/Company/UpdateCompanyAssociateType/" + id;
    return this._restService.Post(_url, formData);
  }
  getCompanyAssociateStatus(id) {
    return this._restService.Get('/Company/GetCompanyAssociateStatus/' + id);
  }
  updateAssociateStatus(id, formData) {
    var _url = "/Company/UpdateCompanyAssociateStatus/" + id;
    return this._restService.Post(_url, formData);
  } 
  GetCompanyShipMethods(id) {
    return this._restService.Get('/Company/GetCompanyShipMethod/' + id);
  }
  updateShipMethods(id, formData) {
    var _url = "/Company/UpdateCompanyShipMethod/" + id;
    return this._restService.Post(_url, formData);
  }
  GenerateClientAPICredentials(formData) {
    return this._restService.Post('/Company/GenerateClientAPICredentials', formData);
  }
  GetCompanyApprovedTemplates(CompanyId,typeId) {
    return this._restService.Get("/template/GetCompanyApprovedTemplates/" + CompanyId +"/" + typeId); 
  }
  DeleteTemplate(id: number) {
    var _url = "/Template/DeleteTemplate/" + id;
    return this._restService.Delete(_url, null);
  }
  archiveCompany(id: number, Archive) {
    var _url = "/Company/ArchiveCompany/" + id+'?Archive='+ Archive;
    return this._restService.Delete(_url, null);
  }
  GetPaymentStatus(){
    return this._restService.Get('/Company/GetPaymentStatus');
  }
  GetUpdatePaymentStatus(formData){
    var _url = "/Company/UpdatePaymentStatus" ;
    return this._restService.Post(_url, formData);
  }
  disableAccount(id: number, Disable ) {
    var _url = "/Company/DisableCompany/" + id+'?Disable='+ Disable;
    return this._restService.Delete(_url, null);
  }
}
