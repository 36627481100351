import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Component({
    selector: 'add-merge-field',
    templateUrl: './add-merge-field.component.html',
    styleUrls: ['./add-merge-field.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddMergeFieldComponent implements OnInit, AfterViewInit{
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    formData: any;
    dialogTitle: string = "Add Merge Field";
    dialogRef: any;
    mediaList: any[] = [];
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    constructor(
        public matDialogRef: MatDialogRef<AddMergeFieldComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        public _pageService: PageService
    ) {
        this.dialogTitle = this._data.dialogTitle;
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
    }
    ngAfterViewInit(): void {
     
    }
    selectedMergeField(event) {
      this._pageService.SlectionMergeFieldChanges.next(true);
    }
    addMedia() {
      this.matDialogRef.close();
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("add-merge-field-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("add-merge-field-content").style.maxHeight = "65vh";
        document.getElementById("add-merge-field-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("add-merge-field-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("add-merge-field-content").style.maxHeight = "100vh";
        document.getElementById("add-merge-field-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

