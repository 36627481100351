import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from 'app/shared/rest.service';



@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  // http: any;
  Base_URL = "https://linkbuilder.ziplingo.com";

  constructor(private http: HttpClient,
    private _restService: RestService) {

    }

  getStates(request) {
    let URL = "https://linkbuilder.ziplingo.com/api/Stats/GetStatsWithPegination";
    return this.http.post(URL,request);
  }

  getAllCompany() {
    return this.http.get("https://linkbuilder.ziplingo.com/api/CompanyInfo/GetAllCompany");
  }
  
  getEvent() {
    return this.http.get(this.Base_URL + "/api/Event/GetAllEvent")
  }
  
  getROIData(companyId:string, days){
    return this.http.get(`https://linkbuilder.ziplingo.com/api/Roi/GetRoiSummary?companyId=${companyId}`+'&days='+days)
  }

  getPageViewDetails(companyId:string){
    return this.http.get(`https://linkbuilder.ziplingo.com/api/Roi/GetPageViewDetails?companyId=${companyId}`)
  }

  getAbandonCartDetails(companyId:string){
    return this.http.get(`https://linkbuilder.ziplingo.com/api/Roi/GetAbandonCartDetails?companyId=${companyId}`)
  }
  getRoiOrderDetails(companyId:string){
    return this.http.get(`https://linkbuilder.ziplingo.com/api/Roi/GetRoiOrderDetails?companyId=${companyId}`)
  }
  getCampaignEventDetails(request) {
    let URL = "https://linkbuilder.ziplingo.com/api/Roi/GetCampaignEventDetails";
    return this.http.post(URL,request);
  }
  getClicksDetails(request) {
     var _url ="/Inbox/GetLinkTrackingData";
     return this._restService.Post(_url,request);
  }
}
