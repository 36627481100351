import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from 'app/main/pages/pages.service';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatPaginator, MatDialog, Sort, MatSort, MatDialogRef } from '@angular/material';
import * as moment from "moment-timezone";
import { ChatDetailsDialogComponent } from '../dialogs/chat-details/chat-details.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import { ViewMessageDialogComponent } from '../dialogs/view-message/view-message.component';

@Component({
  selector: 'external-reporting-recent-replies',
  templateUrl: './external-reporting-recent-replies.component.html',
  styleUrls: ['./external-reporting-recent-replies.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ExternalReportingRecentRepliesComponent implements OnInit {
  userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  dialogRef: any;
  @Input() isFilter: boolean;
  @Input() isHeader: boolean;
  @Input() contactId: boolean;
  List: any = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['type', 'CreatedAt', 'SenderFirstName', 'SenderEmail', 'SenderMobile', 'RecipientFirstName', 'RecipientEmail', 'RecipientMobile', 'Message', 'status'];
  TotalCount: any = 0;
  pageNumber = 1;
  pageSize = 10;
  searchInput: FormControl;
  filterForm: FormGroup;
  reportType: any = 1;
  private _unsubscribeAll: Subject<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  sortBy: string = "CreatedAt";
  sortDirection: string = "desc";

  constructor(
    public _matDialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _projectDashboardService: ProjectDashboardService,
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _pageService: PageService,
    private _router: Router,
    private _contactsService: ContactsService
  ) {
    this._unsubscribeAll = new Subject();
    this.searchInput = new FormControl('');
    this.filterForm = this._formBuilder.group({
      searchText: [''],
      searchByFromDate: [''],
      searchByToDate: [''],
      accounttypes: [[]],
      referenceId: ['']
    });
  }

  ngOnInit() {
    this._fuseSplashScreenService._initById('splash-external-reporting-replies-table');
    this.getExternalReportingRecentReplies();
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  onSearch() {
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.getExternalReportingRecentReplies();
  }

  getExternalReportingRecentReplies() {
    if (this.filterForm.invalid) {
      return false;
    }
    const ExternalReportingRecentMessagerequest = {
      "referenceId": this.filterForm.get('referenceId').value,
      "startDate": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).local().format("MM/DD/YYYY") : '',
      "endDate": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).local().format("MM/DD/YYYY") : '',
      "pageNo": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.filterForm.get('searchText').value,
      "sortColumn": this.sortBy,
      "sortColumnDir": this.sortDirection
    };
    this._fuseSplashScreenService.showById('splash-external-reporting-replies-table');
    this._projectDashboardService.getExternalReportingRecentReplies(ExternalReportingRecentMessagerequest)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hideById('splash-external-reporting-replies-table');
        if (res.status == 200) {
          this.List = res.data.messages;
          this.TotalCount = res.data.total;
          this.dataSource = new MatTableDataSource(this.List);
        }
        else {
          this.List = [];
          this.TotalCount = 0;
          this.dataSource = new MatTableDataSource(this.List);
        }
      },
        error => {
          this._fuseSplashScreenService.hideById('splash-external-reporting-replies-table');
          this.List = [];
          this.TotalCount = 0;
          this.dataSource = new MatTableDataSource(this.List);
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  onPageChange(event): void {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getExternalReportingRecentReplies();
  }
  sortData(event) {
    this.sortBy = event.active;
    this.sortDirection = event.direction;
    this.getExternalReportingRecentReplies();
  }
  onRefreshList() {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.filterForm.patchValue({
      searchText: '',
      searchByFromDate: '',
      searchByToDate: '',
      accounttypes: [],
      referenceId: ''
    });
    this.getExternalReportingRecentReplies();
  }
  viewMessage(message): void {
    this.dialogRef = this._matDialog.open(ViewMessageDialogComponent, {
      panelClass: 'view-message',
      data: {
        message: message
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  goToContactDetails(id: number, email: string, mobile: string) {
    this._pageService.getContactIdByAssociateId(id, email, mobile)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200 && res.data) {
          this._router.navigate(['/pages/contactdetails/' + res.data]);
        }
        else {
          this._toastr.error('Contact Not Found', 'Error');
        }
      },
        error => {
          console.log(error);
          this._fuseSplashScreenService.hide();
          if (error && error.error && error.error.error) {
            this._toastr.error(error.error.error, 'Error');
          }
          else {
            this._toastr.error(error.message, 'Error');
          }
        }
      );
  }
}
