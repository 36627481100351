import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AuthService } from 'app/shared/auth.service';
import * as moment from "moment-timezone";
import * as momentTimezone from 'moment-timezone';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import * as _ from 'lodash';
import { ErrorDescriptionDialogComponent } from '../error-description/error-description.component';
import { AddGroupComponent } from 'app/main/pages/contacts/dialogs/add-group/add-group.component';
import { endOfMonth, startOfMonth } from 'date-fns';
@Component({
  selector: 'app-sent-message-details',
  templateUrl: './sent-message-details.component.html',
  styleUrls: ['./sent-message-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SentMessageDetailsComponent implements OnInit {
  IsDialogMax: boolean = false;
  private _unsubscribeAll: Subject<any>;
  weekType: any = 0;
  filterForm: FormGroup;
  List: any = [];
  dataSource: MatTableDataSource<any>;
  TotalCount: any = 0;
  pageNumber = 1;
  pageSize = 30;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(FusePerfectScrollbarDirective, { static: false })
  directiveScroll: FusePerfectScrollbarDirective;
  showCountLoader: boolean;
  hostHeight: any;
  hostWidth: any;
  DialogRef: any;
  userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
  offset = new Date().getTimezoneOffset();
  filterOffset = (this.offset >= 720) ? 719 : (this.offset < -720) ? -600 : this.offset;
  repoType: any = null;
  constructor(
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _projectDashboardService: ProjectDashboardService,
    private _authService: AuthService,
    public matDialogRef: MatDialogRef<SentMessageDetailsComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialog,
    private _matDialog: MatDialog
  ) {
    this.weekType = this._data.weekType ? parseInt(this._data.weekType) : 0;
    this._unsubscribeAll = new Subject();
    this.filterForm = this._formBuilder.group({
      reportingtype: [this._data.reportingType],
      searchText: [''],
      searchByFromDate: [this.weekType ==2 ? startOfMonth(new Date()) : ((this._data && this._data.searchByFromDate) ? this._data.searchByFromDate+'T00:00:00' : '')],
      searchByToDate: [this.weekType ==2 ? endOfMonth(new Date()) : ((this._data && this._data.searchByToDate) ? this._data.searchByToDate+'T00:00:00' : '')],
      accounttypes: [(this._data.accounttypes == undefined || this._data.accounttypes == null || this._data.accounttypes == '' ) ? [] : this._data.accounttypes],
    });
  }
  displayedColumns = this._data && this._data.isRecentSentInProgress ? ['typeName', 'phone', 'traffic', 'segments', 'opened', 'click', 'date', 'desc'] : ['typeName', 'firstName', 'phone', 'traffic', 'segments', 'opened', 'click', 'date', 'desc'];
  ngOnInit(): void {
    this.filterForm.get("searchByToDate").valueChanges.subscribe((val) => {
      if(this.weekType == 1 && val) {
        this.weekType = 0;
      }else {
        this.weekType = this._data.weekType ? parseInt(this._data.weekType) : 0;
      }
    });
    if(this._data && this._data.isRecentSentInProgress){
      this.getInredisSentMessage();
    } else {
      this.getSentDetails();
    }
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  getInredisSentMessage() {
    let localeTimeZone = momentTimezone.tz.guess();
    let getInredisSentMessagerequest = {
      "messageId": this._data.messageId,
      "status": this.filterForm.get('reportingtype').value,
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.filterForm.get('searchText').value,
      "timeZoneId": localeTimeZone,
      "week": 0,
      "from": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "to": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "campaignId": this._data.campaignId,
      "unifiedTypes": this._data.unifiedTypes,
      "dripID": this._data.dripID ? this._data.dripID : 0,
      "journeyId": (this._data && this._data.journeyId) ? this._data.journeyId : 0,
      "journeyItemId": (this._data && this._data.journeyItemId) ? this._data.journeyItemId : 0
    }
    if(this.weekType == 1) {
      getInredisSentMessagerequest.week =  this.weekType;
    }
    this.showCountLoader = true;
    this._fuseSplashScreenService.show();
    this._projectDashboardService.getInredisSentMessage(getInredisSentMessagerequest)
    .subscribe((res: any) => {
      this.showCountLoader = false;
      this._fuseSplashScreenService.hide();
      if (res.status == 200) {
        this.repoType = this.filterForm.get('reportingtype').value == 4 ? 'Total Clicks' : (this.filterForm.get('reportingtype').value == 5 ? 'Total Segments' : (this.filterForm.get('reportingtype').value == 7 ? 'Total Opens' : null));
        let temp_list = [];
        this.TotalCount = res.data.totalCount;
        res.data.report.forEach((obj: any) => {
          const found = _.find(this.List, function (t) { return t.messageId == obj.messageId });
          obj.traffic = this.filterForm.get('reportingtype').value == 6 ? 'Received' : (obj.isSent ? 'Sent' : 'Failed');
          if (found == undefined || found == null) {
            temp_list.push(obj);
          }
        });
        Array.prototype.push.apply(this.List, temp_list);
        this.dataSource = new MatTableDataSource(this.List);
      }
    }, error => {
      this.showCountLoader = false;
      this._fuseSplashScreenService.hide();
      this.TotalCount = 0;
      this.List = [];
      this.dataSource = new MatTableDataSource(this.List);
      this._toastr.error(error.message, 'Error');      
    })    
  }

  getSentDetails() {
    if (this.filterForm.invalid) {
      return false;
    }
    let localeTimeZone = momentTimezone.tz.guess();
    let getSentDetailsrequest = {
      "messageId": this._data.messageId,
      "status": this.filterForm.get('reportingtype').value,
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.filterForm.get('searchText').value,
      "from": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "to": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "timeZoneId": localeTimeZone,
      "week": 0,
      "campaignId": this._data.campaignId,
      "unifiedTypes": this._data.unifiedTypes ?  this._data.unifiedTypes : this.filterForm.get('accounttypes').value.toString(),
      "dripID": this._data.dripID ? this._data.dripID : 0,
      "journeyId": (this._data && this._data.journeyId) ? this._data.journeyId : 0,
      "journeyItemId": (this._data && this._data.journeyItemId) ? this._data.journeyItemId : 0
    }
    if(this.weekType == 1) {
      getSentDetailsrequest.week =  this.weekType;
    }
    this._fuseSplashScreenService.show();
    this._projectDashboardService.getSentDetails(getSentDetailsrequest, false)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          this.repoType = this.filterForm.get('reportingtype').value == 4 ? 'Total Clicks' : (this.filterForm.get('reportingtype').value == 5 ? 'Total Segments' : (this.filterForm.get('reportingtype').value == 7 ? 'Total Opens' : null));
          let temp_list = [];
          res.data.forEach((obj: any) => {
            const found = _.find(this.List, function (t) { return t.id == obj.id });
            obj.traffic = this.filterForm.get('reportingtype').value == 6 ? 'Received' : (obj.isSent ? 'Sent' : 'Failed');
            if (found == undefined || found == null) {
              temp_list.push(obj);
            }
          });
          Array.prototype.push.apply(this.List, temp_list);
          this.dataSource = new MatTableDataSource(this.List);
          if(this.pageNumber == 1) {
            this.getSentDetailsCount();
          }
        }
        else {
          this.List = [];
          this.dataSource = new MatTableDataSource(this.List);
        }
      },
        error => {
          this._fuseSplashScreenService.hide();
          this.List = [];
          this.dataSource = new MatTableDataSource(this.List);
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  getSentDetailsCount() {
    if (this.filterForm.invalid) {
      return false;
    }
    let localeTimeZone = momentTimezone.tz.guess();
    let getSentDetailsrequest = {
      "messageId": this._data.messageId,
      "status": this.filterForm.get('reportingtype').value,
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.filterForm.get('searchText').value,
      "from": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "to": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "timeZoneId": localeTimeZone,
      "week": 0,
      "campaignId": this._data.campaignId,
      "unifiedTypes": this._data.unifiedTypes ?  this._data.unifiedTypes : this.filterForm.get('accounttypes').value.toString(),
      "dripID": this._data.dripID ? this._data.dripID : 0,
      "journeyId": (this._data && this._data.journeyId) ? this._data.journeyId : 0,
      "journeyItemId": (this._data && this._data.journeyItemId) ? this._data.journeyItemId : 0
    }
    if(this.weekType == 1) {
      getSentDetailsrequest.week =  this.weekType;
    }
    this.showCountLoader = true;
    this._projectDashboardService.getSentDetailsCount(getSentDetailsrequest)
      .subscribe((res: any) => {
        this.showCountLoader = false;
        if (res.status == 200) {
          this.TotalCount = res.data;
        }
        else {
          this.TotalCount = 0;
        }
      },
        error => {
          this.showCountLoader = false;
          this.TotalCount = 0;
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  search(event) {
    this.List = [];
    this.pageNumber = 1;
    this.directiveScroll.scrollToTop();
    if(this._data && this._data.isRecentSentInProgress){
      this.getInredisSentMessage();
    }else {
      this.getSentDetails();
    }
  }
  onRefreshList(event) {
    this.List = [];
    this.pageNumber = 1;
    this.pageSize = 100;
    this.directiveScroll.scrollToTop();
    this.filterForm.patchValue({
      reportingtype: 0,
      searchText: '',
      searchByFromDate: '',
      searchByToDate: '',
      accounttypes: [],
    });
    if(this._data && this._data.isRecentSentInProgress){
      this.getInredisSentMessage();
    } else {
      this.getSentDetails();
    }
  }
  goToContactDetails(contactId): void {
    this.matDialogRef.close(contactId);
    this.dialogRef.closeAll();
    this._router.navigate(['/pages/contactdetails/' + contactId]);
  }
  downloadCSV() {
    if (this.filterForm.invalid) {
      return false;
    }
    let localeTimeZone = momentTimezone.tz.guess();
    let getSentDetailsrequest = {
      "messageId": this._data.messageId,
      "status": this.filterForm.get('reportingtype').value,
      "searchText": this.filterForm.get('searchText').value,
      "from": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "to": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "timeZoneId": localeTimeZone,
      "week": 0,
      "campaignId": this._data.campaignId,
      "unifiedTypes": this._data.unifiedTypes ?  this._data.unifiedTypes : this.filterForm.get('accounttypes').value.toString(),
      "dripID": this._data.dripID ? this._data.dripID : 0,
      "journeyId": (this._data && this._data.journeyId) ? this._data.journeyId : 0,
      "journeyItemId": (this._data && this._data.journeyItemId) ? this._data.journeyItemId : 0
    }
    if(this.weekType == 1) {
      getSentDetailsrequest.week =  this.weekType;
    }
    this._fuseSplashScreenService.show();
    this._projectDashboardService.getSentDetails(getSentDetailsrequest, true)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        var blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
        this._projectDashboardService.saveFile(blob, "MessageDetails.csv");
      },
        error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  onScrollDown(ev) {
    if(this.dataSource.filteredData.length < this.TotalCount.totalCount) {
      this.pageNumber += 1;
      if(this._data && this._data.isRecentSentInProgress){
        this.getInredisSentMessage();
      } else {
        this.getSentDetails();
      }
    }
  }
  addGroup(addToGroup): void {
    let localeTimeZone = momentTimezone.tz.guess();
    this.DialogRef = this._matDialog.open(AddGroupComponent, {
      panelClass: 'add-group-dialog',
      disableClose: true,
      data: {
        action: 'new',
        pageName: 'sentMessageDetails',
        messageId: this._data.messageId,
        status: this.filterForm.get('reportingtype').value,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        searchText: this.filterForm.get('searchText').value,
        from: this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
        to: this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
        timeZoneId: localeTimeZone,
        week: (this.filterForm.get('searchByFromDate').value || this.filterForm.get('searchByToDate').value) ? 0 : this.weekType,
        campaignId: this._data.campaignId,
        unifiedTypes: this._data.unifiedTypes,
        dripID: this._data.dripID ? this._data.dripID : 0,
        addToGroup: addToGroup,
        journeyId: (this._data && this._data.journeyId) ? this._data.journeyId : 0,
        journeyItemId: (this._data && this._data.journeyItemId) ? this._data.journeyItemId : 0
      }
    });

    this.DialogRef.afterClosed()
      .subscribe((response: any) => {
        if (!response) {
          return;
        }
      });
  }
  goToContactInfo(messageId) {
    this._fuseSplashScreenService.show();
    this._projectDashboardService.GetContactIdByMessageId(messageId)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          let contactId = res.data;
          this.matDialogRef.close(contactId);
          this.dialogRef.closeAll();
          this._router.navigate(['/pages/contactdetails/' + contactId]);
        }
      },
        error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("sent-message-details");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("sendmsgdetail-content").style.maxHeight = "65vh";
    document.getElementById("sendmsgdetail-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("sent-message-details");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("sendmsgdetail-content").style.maxHeight = "100vh";
    document.getElementById("sendmsgdetail-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  errorDescription(report) {
    this.DialogRef = this._matDialog.open(ErrorDescriptionDialogComponent, {
      panelClass: 'error-description-dialog',
      disableClose: true,
      data: {
        reason: report.reason,
        statusCode : report.statusCode
      }
    });
    this.DialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
}
