import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { ProjectDashboardService } from '../../../main/pages/dashboard/project.service';
import { AuthService } from 'app/shared/auth.service';
import * as moment from "moment-timezone";
import { MatPaginator } from '@angular/material/paginator';
import { ViewMessageDialogComponent } from '../dialogs/view-message/view-message.component';
import { ExternalReportingSentMessageDetailsComponent } from '../dialogs/external-reporting-sent-message-details/external-reporting-sent-message-details.component';

@Component({
  selector: 'external-reporting-recent-sent',
  templateUrl: './external-reporting-recent-sent.component.html',
  styleUrls: ['./external-reporting-recent-sent.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ExternalReportingRecentSentComponent implements OnInit {
  userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
  dialogRef: any;
  @Input() isFilter: boolean;
  @Input() isHeader: boolean;
  @Input() contactId: boolean;
  @Input() isGroup: boolean;
  List: any = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['type', 'CreatedAt', 'Message', 'Sent', 'Delivered','Opened', 'Replies', 'Clicked', 'Error', 'Total'];
  TotalCount: any;
  pageNumber = 1;
  pageSize = 10;
  filterForm: FormGroup;
  private _unsubscribeAll: Subject<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  sortBy: string = "CreatedAt";
  sortDirection: string = "desc";
  constructor(
    public _matDialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _formBuilder: FormBuilder,
    private _pageService: PageService,
    private _projectDashboardService: ProjectDashboardService,
    private _authService: AuthService
  ) {
    this._unsubscribeAll = new Subject();
    this.filterForm = this._formBuilder.group({
      searchText: [''],
      searchByFromDate: [''],
      searchByToDate: [''],
      accounttypes: [[]],
      referenceId: ['']
    });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  ngOnInit() {
    this._fuseSplashScreenService._initById('splash-external-reporting-recent-sent');
    this.getExternalReportingRecentSentMessage();
  }
  getExternalReportingRecentSentMessage() {
    if (this.filterForm.invalid) {
      return false;
    }
    const RecentSentMessagelogsrequest = {
      "referenceId": this.filterForm.get('referenceId').value,
      "startDate": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).local().format("MM/DD/YYYY") : '',
      "endDate": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).local().format("MM/DD/YYYY") : '',
      "pageNo": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.filterForm.get('searchText').value,
      "sortColumn": this.sortBy,
      "sortColumnDir": this.sortDirection
    }
    this._fuseSplashScreenService.showById('splash-external-reporting-recent-sent');
    this._projectDashboardService.getExternalReportingSentMessage(RecentSentMessagelogsrequest)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hideById('splash-external-reporting-recent-sent');
        if (res.status == 200) {
          this.List = res.data.messages;
          this.TotalCount = res.data.total;
          this.dataSource = new MatTableDataSource(this.List);
        }
        else {
          this.List = [];
          this.TotalCount = 0;
          this.dataSource = new MatTableDataSource(this.List);
        }
      },
        error => {
          this._fuseSplashScreenService.hideById('splash-external-reporting-recent-sent');
          this.List = [];
          this.TotalCount = 0;
          this.dataSource = new MatTableDataSource(this.List);
          this._toastr.error(error.message, 'Error');
        }
      );
  }

  search(): void {
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.getExternalReportingRecentSentMessage();
  }
  onSearch(): void {
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.getExternalReportingRecentSentMessage();
  }
  onPageChange(event): void {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getExternalReportingRecentSentMessage();
  }
  sortData(event) {
    this.sortBy = event.active;
    this.sortDirection = event.direction;
    this.getExternalReportingRecentSentMessage();
  }

  reportGroupTypeChange() {
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.getExternalReportingRecentSentMessage();
  }
  refreshList() {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.filterForm.patchValue({
      searchText: '',
      searchByFromDate: '',
      searchByToDate: '',
      accounttypes: [],
      referenceId: ''
    });
    this.getExternalReportingRecentSentMessage();
  }

  goToDetails(type, messageId): void {
    this.dialogRef = this._matDialog.open(ExternalReportingSentMessageDetailsComponent, {
      panelClass: 'externalReporting-sent-message-details',
      data: {
        reportingType: type,
        messageId: messageId
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  viewMessage(message): void {
    this.dialogRef = this._matDialog.open(ViewMessageDialogComponent, {
      panelClass: 'view-message',
      data: {
        message: message
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
}
