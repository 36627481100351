import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HelpMergeFieldDialogComponent } from '../dialogs/help-merge-field/help-merge-field.component';
import { HelpTinyUrlDialogComponent } from '../dialogs/help-tiny-url/help-tiny-url.component';
import { MatDialog } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-advance-options',
  templateUrl: './advance-options.component.html',
  styleUrls: ['./advance-options.component.scss']
})
export class AdvanceOptionsComponent implements OnInit,AfterViewInit {
  helpMergeFieldDialogRef: any;
  helpTinyUrlDialogRef: any;
  advanceOption = false;
  @Input() isViewInBrowser: any;
  @Input() isUnsubscribe: any;
  @Input() isValidateLink: any;
  @Input() mergeTagArray: any;
  @Output() onMergeField = new EventEmitter<any>();
  @Output() onAddTinyUrl = new EventEmitter<any>();
  @Output() onAddViewInBrowser = new EventEmitter<any>();
  @Output() onAddUnsubscribe = new EventEmitter<any>();
  @ViewChild('tinyUrlData', {static:false}) tinyUrlDataRef: ElementRef;
  tinyUrlStatus:any = "";
  advanceForm: FormGroup;
  fieldOptions: any = [
    {
      name: 'Basic',
      basicTag: [
        {
          name: " Associate ID",
          value: "[AssociateId]"
        },
        {
          name: "First Name",
          value: "[firstName]"
        },
        {
          name: "Last Name",
          value: "[lastName]"
        },
        {
          name: "Phone",
          value: "[Phone]"
        },
        {
          name: "Email",
          value: "[Email]"
        },
        {
          name: "Highest Rank",
          value: "[HighestRank]"
        },
        {
          name: "Paid Rank",
          value: "[paidAsRank]"
        },
        {
          name: "WebAlias",
          value: "[WebAlias]"
        },
        {
          name: "Associate Status",
          value: "[AssociateStatus]"
        },
        {
          name: "Associate Type",
          value: "[AssociateType]"
        },
        {
          name: "Company Logo",
          value: "[CompanyLogoURL]"
        },
        {
          name: "Company Name",
          value: "[CompanyName]"
        },
        {
          name: "CompanyAddress",
          value: "[CompanyAddress]"
        },
        {
          name: "CompanyCity",
          value: "[CompanyCity]"
        },
        {
          name: "CompanyState",
          value: "[CompanyState]"
        },
        {
          name: "CompanyZip",
          value: "[CompanyZipCode]"
        },
        {
          name: "BackOffice Id",
          value: "[BackOfficeId]"
        }
      ],
    },
    {
      name: 'Sponsor',
      sponsorTag: [
        {
          name: "Sponsor First Name",
          value: "[_SponsorfirstName]"
        },
        {
            name: "Sponsor Last Name",
            value: "[_SponsorlastName]"
        },
        {
            name: "Sponsor Email",
            value: "[_Sponsoremail]"
        },
        {
            name: "Sponsor Phone Number",
            value: "[_SponsorphoneNumber]"
        },
        {
            name: "Sponsor WebAlias",
            value: "[_SponsorWebAlias]"
        },
        {
            name: "Sponsor ID",
            value: "[_SponsorAssociateId]"
        }
      ],
    },
    {
      name: 'Custom',
      customTag: [],
    }
  ];
  constructor(
    public _matDialog: MatDialog,
    private _toastr: ToastrService,
  ) {
    for(let i = 1; i <=20; i++) {
      let tag = {
          name: "Custom"+i,
          value: "[Custom"+i+"]"
      };
      this.fieldOptions[2].customTag.push(tag);
    }
    this.advanceForm = this.createAdvanceForm();
  }

  ngOnInit() {
  }
  createAdvanceForm(): FormGroup {
    return new FormGroup({
      mergefield: new FormControl(''),
      tinyUrl: new FormControl('')
    });
  }

  helpMergefield() {
    this.helpMergeFieldDialogRef = this._matDialog.open(HelpMergeFieldDialogComponent, {
      panelClass: 'help-merge-field-dialog'
    });
    this.helpMergeFieldDialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });

  }
  helpTinyUrl() {
    this.helpTinyUrlDialogRef = this._matDialog.open(HelpTinyUrlDialogComponent, {
      panelClass: 'help-tiny-url-dialog'
    });
    this.helpTinyUrlDialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  addField(field) {
    this.onMergeField.emit(field);
  }
  addTinyUrl(url) {
    if(this.tinyUrlStatus == "Valid") {
      this.onAddTinyUrl.emit(url);
    }else {
      this._toastr.error('Please enter the valid Tiny Url', 'error');
    }
  }
  addViewInBrowser() {
    this.onAddViewInBrowser.emit('<a id="viewInBrowser" style="cursor: pointer;" href="[ViewInBrowser]" target="_blank" rel="noopener">ViewInBrowser</a>');
  }
  addUnsubscribe() {
    this.onAddUnsubscribe.emit('<a id="unsubscribe-id" style="cursor: pointer;" href="%unsubscribe_url%" target="_blank" rel="noopener">Unsubscribe</a>');
  }
  addValidateLink() {
    this.onMergeField.emit('[ValidateLink]');
  }
  tinyurlData() {
    var expression = /https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const tinyUrl = this.tinyUrlDataRef.nativeElement.value;
    if(tinyUrl != "" && tinyUrl != null) {
      if (tinyUrl.match(expression)) {
        this.tinyUrlStatus = "Valid";
      } else {
        this.tinyUrlStatus = "InValid";
      }
    }
    
  }
  ngAfterViewInit(): void {
    if(this.mergeTagArray && this.mergeTagArray.length>2 && this.mergeTagArray[2].customTag && this.mergeTagArray[2].customTag.length == 0) {
      this.mergeTagArray.splice(2, 1)
    }
  }
}
