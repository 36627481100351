import { Data } from '@agm/core/services/google-maps-types';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, interval } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Mail } from 'app/main/pages/mail/mail.model';
import { RestService } from '../../../shared/rest.service';
import { AccountType } from 'app/main/pages/accounts/account.model';
import { Contact } from 'app/main/pages/contacts/contact.model';
import { Inbox } from './mail.model';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from '../pages.service';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment-timezone";
import { startOfMonth, lastDayOfMonth, subDays } from 'date-fns';

@Injectable({
    providedIn: 'root'
})
export class MailService implements Resolve<any>
{
    userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
    isAdminRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'user' || this.userDetails.roles[0] == 'administrator');
    isCompanyRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'company');
    isAgentRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
    isAgent = this.isAgentRole && this._pageService.isAgentView;
    inboxlist: any[] = [];
    inboxPageNumber: number = 1;
    inboxPageSize: number = 100;
    inboxTotalCount: number = 0;
    inboxDayFilter: boolean = false;
    onInboxChanged: BehaviorSubject<any>;
    onInboxCountChanged: BehaviorSubject<any>;
    sentlist: any[] = [];
    sentPageNumber: number = 1;
    sentPageSize: number = 30;
    sentTotalCount: number = 0;
    sentDayFilter: boolean = false;
    onSentChanged: BehaviorSubject<any>;
    onSentCountChanged: BehaviorSubject<any>;
    selectedMessages: any[] = [];
    searchText = '';
    onSelectedMessagesChanged: BehaviorSubject<any>;
    onCurrentMessageChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onRefreshList: Subject<any>;
    onSelectFolder: Subject<any>;
    getTemplateListedTrue: boolean = false;
    onInboxSelectedMessagesChanged: BehaviorSubject<any>;
    onSentSelectedMessagesChanged: BehaviorSubject<any>;
    Files :any= [];
    onTemplateFilesChanged: BehaviorSubject<any>;
    onTemplateFileSelected: BehaviorSubject<any>;
    onTemplateSubFileSelected: BehaviorSubject<any>;
    onMasterTemplateSubFileSelected: BehaviorSubject<any>;
    showCurrentFolder = new BehaviorSubject<any>('');
    offset = new Date().getTimezoneOffset();
    filterOffset = (this.offset >= 720) ? 719 : (this.offset < -600) ? -600 : this.offset;

    accountTypeColors: any[] = [
        {
            'id': 0,
            'color': '#000000'
        },
        {
            'id': 1,
            'color': '#d84315'
        },
        {
            'id': 2,
            'color': '#607d8b'
        },
        {
            'id': 3,
            'color': '#03c300'
        },
        {
            'id': 4,
            'color': '#0072c3'
        },
        {
            'id': 5,
            'color': 'rgb(21, 81, 216)'
        }
    ];
    inboxFolders: any[] = [
        {
            'id': 0,
            'handle': 'inbox',
            'title': 'Inbox',
            'icon': 'inbox'
        },
        {
            'id': 1,
            'handle': 'sent',
            'title': 'Sent',
            'icon': 'send'
        },
        {
            'id': 2,
            'handle': 'archive',
            'title': 'Archive',
            'icon': 'error'
        },
        {
            'id': 3,
            'handle': 'trash',
            'title': 'Trash',
            'icon': 'delete'
        },
        {
            'id': 4,
            'handle': 'Template',
            'title': 'Template',
            'icon': 'library_books'
        },
        {
            'id': 5,
            'handle': 'View Browser',
            'title': 'View Browser',
            'icon': 'list'
        },
        // {
        //     'id': 6,
        //     'handle': 'ARTemplates',
        //     'title': 'ARTemplates',
        //     'icon': 'library_books'
        // }
    ];
    filterByType: any = 0;
    reportType: any = 1;
    isArchived: boolean = false;
    isDeleted: boolean = false;
    sendByUser: boolean = false;
    fromFilter: any = "";
    toFilter: any = "";
    accountTypeFilter: any = [];
    sentfromFilter: any = "";
    senttoFilter: any = "";
    groupTypeFilter: number = 0;
    sentaccountTypeFilter: any = [];
    selectedFolder: any = 0;
    inboxSortBy: string = "createdAt";
    inboxSortDirection: string = "desc";
    sentSortBy: string = "createdAt";
    sentSortDirection: string = "desc";
    templateList: any[] = [];
    onTemplateChanged: BehaviorSubject<any>;
    onTemplateData : BehaviorSubject<any>;
    /**
     * Constructor
     *
     * @param {RestService} _restService
     */
    constructor(
        private _restService: RestService,
        private _authService: AuthService,
        private _pageService: PageService,
        private _contactsService: ContactsService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
        private router: Router,
        private _activeRoute: ActivatedRoute
    ) {
        this.onTemplateData = new BehaviorSubject([]);
        this.init();
    }


    /**
     * Resolver
     *
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.init()
            ]).then(
                (res) => {
                    resolve('init');
                },
                reject
            );
        });
    }

    init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
            this.isAdminRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'user' || this.userDetails.roles[0] == 'administrator');
            this.isCompanyRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'company');
            this.isAgentRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
            if (this._pageService.selectedFolder) {
                this.selectedFolder = this._pageService.selectedFolder;
            }
            else {
                this.selectedFolder = 0;
            }
            this._pageService.selectedFolder = 0;

            if (this._pageService.inboxAcountTypeFilter) {
                this.filterByType = this._pageService.inboxAcountTypeFilter;
            }
            else {
                this.filterByType = 0;
            }
            this._pageService.inboxAcountTypeFilter = 0;
            if (this._pageService.inboxIsAgent != null) {
                this.isAgent = this._pageService.inboxIsAgent;
            }
            else {
                this.isAgent = this.isAgentRole;
            }
            this._pageService.inboxIsAgent = null;

            if (this._pageService.inboxReportType == 'month') {
                this.fromFilter = startOfMonth(new Date());
                this.toFilter = new Date();
                this.inboxDayFilter = false;
            }
            else if (this._pageService.inboxReportType == 'day') {
                this.fromFilter = "";
                this.toFilter = "";
                this.inboxDayFilter = true;
            }
            else {
                this.fromFilter = "";
                this.toFilter = "";
                this.inboxDayFilter = false;
            }
            this._pageService.inboxReportType = '';

            if (this._pageService.sentReportType == 'month') {
                this.sentfromFilter = startOfMonth(new Date());
                this.senttoFilter = new Date();
                this.sentDayFilter = false;
            }
            else if (this._pageService.sentReportType == 'day') {
                this.sentfromFilter = "";
                this.senttoFilter = "";
                this.sentDayFilter = true;
            }
            else {
                this.sentfromFilter = "";
                this.senttoFilter = "";
                this.sentDayFilter = false;
            }
            this._pageService.sentReportType = '';

            this.inboxlist = [];
            this.inboxPageNumber = 1;
            this.inboxPageSize = 100;
            this.inboxTotalCount = 0;
            this.sentlist = [];
            this.sentPageNumber = 1;
            this.sentPageSize = 30;
            this.sentTotalCount = 0;
            this.selectedMessages = [];
            this.searchText = '';
            this.reportType = 1;
            this.isArchived = false;
            this.isDeleted = false;
            this.sendByUser = false;
            this.accountTypeFilter = [];
            this.groupTypeFilter = 0;
            this.sentaccountTypeFilter = [];
            this.inboxSortBy = "createdAt";
            this.inboxSortDirection = "desc";
            this.sentSortBy = "createdAt";
            this.sentSortDirection = "desc";
            this.templateList = [];
            this.selectedMessages = [];
            this.onInboxChanged = new BehaviorSubject([]);
            this.onInboxCountChanged = new BehaviorSubject(0);
            this.onSentChanged = new BehaviorSubject([]);
            this.onSentCountChanged = new BehaviorSubject(0);
            this.onSelectedMessagesChanged = new BehaviorSubject([]);
            this.onInboxSelectedMessagesChanged = new BehaviorSubject([]);
            this.onSentSelectedMessagesChanged = new BehaviorSubject([]);
            this.onCurrentMessageChanged = new BehaviorSubject([]);
            this.onSearchTextChanged = new Subject();
            this.onRefreshList = new Subject();
            this.onSelectFolder = new Subject();
            this.deselectMessages();
            this._pageService.getCurrentPage(false);
            this._pageService.getAccountsTypesByUser();
            this.initFolder(this.selectedFolder);
            this.Files = [];
            this.onTemplateChanged = new BehaviorSubject([]);
            this.onTemplateData = new BehaviorSubject([]);
            this.onTemplateFileSelected = new BehaviorSubject({});
            this.onTemplateSubFileSelected = new BehaviorSubject(null);
            this.onMasterTemplateSubFileSelected = new BehaviorSubject(null);
            this.showCurrentFolder = new BehaviorSubject<any>('');
            this._contactsService.deselectContacts();
            resolve('initialized');
        });
    }
    getInboxList(reloadCurrentMessage) {
        var GetMessagelogsrequest = {
            "archived": this.isArchived,
            "deleted": this.isDeleted,
            "sendByUser": this.sendByUser,
            "pageNumber": this.inboxPageNumber,
            "pageSize": this.inboxPageSize,
            "searchName": this.searchText,
            "from": this.fromFilter ? moment.utc(this.fromFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "to": this.toFilter ? moment.utc(this.toFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "unifiedTypes": this.accountTypeFilter.length > 0 ? this.accountTypeFilter.toString() : (this.filterByType ? this.filterByType.toString() : null),
            "sortBy": this.inboxSortBy,
            "sortDirection": this.inboxSortDirection,
            "day": (this.fromFilter || this.toFilter) ? false : this.inboxDayFilter,
            "isAgent": this._pageService.isAgentView
        };
        if (reloadCurrentMessage) {
            this._fuseSplashScreenService.show();
        }
        this._restService.Post('/Inbox/GetMessagelogsInbox', GetMessagelogsrequest)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hide();
                if (response.status == 200) {
                    this.inboxlist = response.data;
                    this.onInboxChanged.next(this.inboxlist);
                    this.getInboxListCount();
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.inboxlist = [];
                    this.onInboxChanged.next(this.inboxlist);
                });
    }
    getInboxListCount() {
        var GetMessagelogsrequest = {
            "archived": this.isArchived,
            "deleted": this.isDeleted,
            "sendByUser": this.sendByUser,
            "pageNumber": this.inboxPageNumber,
            "pageSize": this.inboxPageSize,
            "searchName": this.searchText,
            "from": this.fromFilter ? moment.utc(this.fromFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "to": this.toFilter ? moment.utc(this.toFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "unifiedTypes": this.accountTypeFilter.length > 0 ? this.accountTypeFilter.toString() : (this.filterByType ? this.filterByType.toString() : null),
            "sortBy": this.inboxSortBy,
            "sortDirection": this.inboxSortDirection,
            "day": (this.fromFilter || this.toFilter) ? false : this.inboxDayFilter,
            "isAgent": this._pageService.isAgentView
        };
        this._restService.Post('/Inbox/GetMessagelogsInboxCount', GetMessagelogsrequest)
            .subscribe((response: any) => {
                if (response.status == 200) {
                    this.inboxTotalCount = response.data;
                    this.onInboxCountChanged.next(this.inboxTotalCount);
                }
            },
                error => {
                    this.inboxTotalCount = 0;
                    this.onInboxCountChanged.next(this.inboxTotalCount);
                });
    }
    getSentList(reloadCurrentMessage) {
        var GetMessagelogsrequest = {
            "pageNumber": this.sentPageNumber,
            "pageSize": this.sentPageSize,
            "searchText": this.searchText,
            "unifiedTypes": this.sentaccountTypeFilter.length > 0 ? this.sentaccountTypeFilter.toString() : (this.filterByType ? this.filterByType.toString() : null),
            "from": this.sentfromFilter ? moment.utc(this.sentfromFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "to": this.senttoFilter ? moment.utc(this.senttoFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "reportGroupType": this.groupTypeFilter,
            "sortBy": this.sentSortBy,
            "sortDirection": this.sentSortDirection,
            "day": (this.sentfromFilter || this.senttoFilter) ? false : this.sentDayFilter,
            "isAgent": this._pageService.isAgentView
        };
        if (reloadCurrentMessage) {
            this._fuseSplashScreenService.show();
        }
        this._restService.Post('/Inbox/GetSentMessagelogsCommon', GetMessagelogsrequest)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hide();
                if (response.status == 200) {
                    this.sentlist = response.data;
                    this.onSentChanged.next(this.sentlist);
                    this.getSentListCount();
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.sentlist = [];
                    this.onSentChanged.next(this.sentlist);
                });
    }
    getSentListCount() {
        var GetMessagelogsrequest = {
            "pageNumber": this.sentPageNumber,
            "pageSize": this.sentPageSize,
            "searchText": this.searchText,
            "unifiedTypes": this.sentaccountTypeFilter.length > 0 ? this.sentaccountTypeFilter.toString() : (this.filterByType ? this.filterByType.toString() : null),
            "from": this.sentfromFilter ? moment.utc(this.sentfromFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "to": this.senttoFilter ? moment.utc(this.senttoFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "reportGroupType": this.groupTypeFilter,
            "sortBy": this.sentSortBy,
            "sortDirection": this.sentSortDirection,
            "day": (this.sentfromFilter || this.senttoFilter) ? false : this.sentDayFilter,
            "isAgent": this._pageService.isAgentView
        };
        this._restService.Post('/Inbox/GetSentMessagelogsCount', GetMessagelogsrequest)
            .subscribe((response: any) => {
                if (response.status == 200) {
                    this.sentTotalCount = response.data;
                    this.onSentCountChanged.next(this.sentTotalCount);
                }
            },
                error => {
                    this.sentTotalCount = 0;
                    this.onSentCountChanged.next(this.sentTotalCount);
                });
    }
    getTemplateList(reloadCurrentMessage, MenuId=0) {
        this.getTemplateListedTrue = false;
        if (reloadCurrentMessage) {
            this._fuseSplashScreenService.show();
        }
        let apiUrl = this.filterByType ? '/Template/GetTemplatesByType/' + this.filterByType : '/Template/GetTemplateList?MenuId='+MenuId;
        this._restService.Get(apiUrl)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hide();
                if (response.status == 200) {
                    this.templateList = response.data;
                    this.onTemplateChanged.next(this.templateList);
                }
                else {
                    this.templateList = [];
                    this.onTemplateChanged.next(this.templateList);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.templateList = [];
                    this.onTemplateChanged.next(this.templateList);
                });
    }
    initFolder(id): void {
        this.selectedFolder = id;
        this.onRefreshList.next();
        if (id == 0) {
            this.isArchived = false;
            this.isDeleted = false;
            this.sendByUser = false;
            this.onSelectFolder.next(id);
            this.getInboxList(true);
        }
        else if (id == 1) {
            this.isArchived = false;
            this.isDeleted = false;
            this.sendByUser = true;
            this.onSelectFolder.next(id);
            this.getSentList(true);
        }
        else if (id == 2) {
            this.isArchived = true;
            this.isDeleted = false;
            this.sendByUser = false;
            this.onSelectFolder.next(id);
            this.getInboxList(true);
        }
        else if (id == 3) {
            this.isArchived = false;
            this.isDeleted = true;
            this.sendByUser = false;
            this.onSelectFolder.next(id);
            this.getInboxList(true);
        }
        else if (id == 4) {
            this.onSelectFolder.next(id);
            this.getTemplateList(true, 0);
        }
    }
    selectFolder(id): void {
        this.selectedFolder = id;
        this.onRefreshList.next();
        if (id == 0) {
            this.fromFilter = "";
            this.toFilter = "";
            this.inboxDayFilter = false;
            this.searchText = "";
            this.accountTypeFilter = [];
            this.isArchived = false;
            this.isDeleted = false;
            this.sendByUser = false;
            this.inboxPageNumber = 1;
            this.inboxTotalCount = 0;
            this.inboxlist = [];
            this.onSelectFolder.next(id);
            this.getInboxList(true);
        }
        else if (id == 1) {
            this.sentfromFilter = "";
            this.senttoFilter = "";
            this.sentDayFilter = false;
            this.searchText = "";
            this.groupTypeFilter = 0;
            this.sentaccountTypeFilter = [];
            this.isArchived = false;
            this.isDeleted = false;
            this.sendByUser = true;
            this.sentPageNumber = 1;
            this.sentTotalCount = 0;
            this.sentlist = [];
            this.onSelectFolder.next(id);
            this.getSentList(true);
        }
        else if (id == 2) {
            this.fromFilter = "";
            this.toFilter = "";
            this.inboxDayFilter = false;
            this.searchText = "";
            this.accountTypeFilter = [];
            this.isArchived = true;
            this.isDeleted = false;
            this.sendByUser = false;
            this.inboxPageNumber = 1;
            this.inboxTotalCount = 0;
            this.inboxlist = [];
            this.onSelectFolder.next(id);
            this.getInboxList(true);
        }
        else if (id == 3) {
            this.fromFilter = "";
            this.toFilter = "";
            this.inboxDayFilter = false;
            this.searchText = "";
            this.accountTypeFilter = [];
            this.isArchived = false;
            this.isDeleted = true;
            this.sendByUser = false;
            this.inboxPageNumber = 1;
            this.inboxTotalCount = 0;
            this.inboxlist = [];
            this.onSelectFolder.next(id);
            this.getInboxList(true);
        }
        else if (id == 4) {
            this.templateList = [];
            this.onSelectFolder.next(id);
            this.getTemplateList(true, 0);
        }else if(id == 5) {
            this.onSelectFolder.next(id);
        }
        // else if(id == 6) {
        //     this.onSelectFolder.next(id);
        // }
    }
    onSearch(id): void {
        this.onRefreshList.next();
        if (id == 0) {
            this.inboxPageNumber = 1;
            this.inboxTotalCount = 0;
            this.inboxlist = [];
            this.getInboxList(true);
        }
        else if (id == 1) {
            this.sentPageNumber = 1;
            this.sentTotalCount = 0;
            this.sentlist = [];
            this.getSentList(true);
        }
        else if (id == 2) {
            this.inboxPageNumber = 1;
            this.inboxTotalCount = 0;
            this.inboxlist = [];
            this.getInboxList(true);
        }
        else if (id == 3) {
            this.inboxPageNumber = 1;
            this.inboxTotalCount = 0;
            this.inboxlist = [];
            this.getInboxList(true);
        }
        else if (id == 4) {
            this.templateList = [];
            this.getTemplateList(true, 0);
        }
    }
    onFilter(id): void {
        this.filterByType = id;
        this.onRefreshList.next();
        if (this.selectedFolder == 0) {
            this.inboxPageNumber = 1;
            this.inboxTotalCount = 0;
            this.inboxlist = [];
            this.getInboxList(true);
        }
        else if (this.selectedFolder == 1) {
            this.sentPageNumber = 1;
            this.sentTotalCount = 0;
            this.sentlist = [];
            this.getSentList(true);
        }
        else if (this.selectedFolder == 2) {
            this.inboxPageNumber = 1;
            this.inboxTotalCount = 0;
            this.inboxlist = [];
            this.getInboxList(true);
        }
        else if (this.selectedFolder == 3) {
            this.inboxPageNumber = 1;
            this.inboxTotalCount = 0;
            this.inboxlist = [];
            this.getInboxList(true);
        }
        else if (this.selectedFolder == 4) {
            this.templateList = [];
            this.getTemplateList(true, 0);
        }
    }
    getTemplateListed(reloadCurrentMessage, MenuId = 0) {
        this.getTemplateListedTrue = true;
        if (reloadCurrentMessage) {
            this._fuseSplashScreenService.show();
        }
        let apiUrl = this.filterByType ? '/Template/GetTemplatesByType/' + this.filterByType : '/Template/GetTemplateList?MenuId='+MenuId;
        this._restService.Get(apiUrl)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hide();
                if (response.status == 200) {
                    this.templateList = response.data;
                    this.onTemplateData.next(this.templateList);
                }
                else {
                    this.templateList = [];
                    this.onTemplateData.next(this.templateList);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.templateList = [];
                    this.onTemplateData.next(this.templateList)
                });
    }
    toggleSelectedMessage(id): void {
        // First, check if we already have that mail as selected...
        if (this.selectedMessages.length > 0) {
            for (const message of this.selectedMessages) {
                // ...delete the selected mail
                if (message.messageDetailId === id) {
                    const index = this.selectedMessages.indexOf(message);

                    if (index !== -1) {
                        this.selectedMessages.splice(index, 1);

                        // Trigger the next event
                        this.onSelectedMessagesChanged.next(this.selectedMessages);
                        // Return
                        return;
                    }
                }
            }
        }

        // If we don't have it, push as selected
        this.selectedMessages.push(
            this.inboxlist.find(message => {
                return message.messageDetailId === id;
            })
        );
        // Trigger the next event
        this.onSelectedMessagesChanged.next(this.selectedMessages);
    }
    toggleSelectAllInbox(): void {
        if (this.selectedMessages.length > 0) {
            this.deselectMessages();
        }
        else {
            this.selectMessages();
        }
    }
    selectMessages(filterParameter?, filterValue?): void {
        this.selectedMessages = [];
        this.inboxlist.forEach((message: any) => {
            this.toggleSelectedMessage(message.messageDetailId);
        });
    }
    deselectMessages(): void {
        this.selectedMessages = [];
        this.onSelectedMessagesChanged.next(this.selectedMessages);
    }
    sendMail(mail: any,unifiedTypeId?) {
        let _url;
        if(unifiedTypeId == 21){
            _url = "/Infobip/SendMailToContacts?IsAgent=" + this._pageService.isAgentView;
        }else{
           _url = "/MailgunEmail/SendMailToContacts?IsAgent=" + this._pageService.isAgentView;
        }
        return this._restService.Post(_url, mail);
    }
    sendTwilioMessage(Url:string,message: any) {
        var _url =  Url+"?IsAgent=" + this._pageService.isAgentView;
        return this._restService.Post(_url, message);
    }
    sendSMSMessage(message: any, Url: string) {
        var _url = Url+"?IsAgent=" + this._pageService.isAgentView;
        return this._restService.Post(_url, message);
    }
    sendWhatsappMessage(message: any) {
        var _url = "/TwilioMessage/SendToContact?isWhatsApp=true&IsAgent=" + this._pageService.isAgentView;
        return this._restService.Post(_url, message);
    }
    sendLineMessage(message: any) {
        var _url = "/LineMessage/SendToContact?IsAgent=" + this._pageService.isAgentView;
        return this._restService.Post(_url, message);
    }
    sendFacebookMessage(message: any) {
        var _url = "/FacebookMessage/SendToContact?IsAgent=" + this._pageService.isAgentView;
        return this._restService.Post(_url, message);
    }
    sendTelegramMessage(message: any) {
        var _url = "/TelegramMessage/SendMessageToContactTelegram?IsAgent=" + this._pageService.isAgentView;
        return this._restService.Post(_url, message);
    }
    archiveSelectedMessages(isarchive, selectedMessageIds) {
        var _url = "/Inbox/ArchiveMessages?archive=" + isarchive;
        return this._restService.Delete(_url, selectedMessageIds);
    }
    deleteSelectedMessages(isdelete, selectedMessageIds) {
        var _url = "/Inbox/DeleteMessages?delete=" + isdelete;
        return this._restService.Delete(_url, selectedMessageIds);
    }
    SendMessageToContacts(req: any) {
        var _url = "/Campaign/SendMessageToContacts";
        req.isAgent = this._pageService.isAgentView;
        return this._restService.Post(_url, req);
    }
    getAllTemplates(menuId?) {
        var params = (menuId !== null && menuId !== undefined && menuId !== '') ? menuId : 0;
        return this._restService.Get('/Template/GetTemplateList?MenuId='+params);
    }

    NotificationMessage(notificationReq){
        var _url = "/NotificationMessage/SendToContact";
        return this._restService.Post(_url,notificationReq);
    }
    getAllNotificationType(){
        return this._restService.Get('/UnifiedType/GetAllNotificationTypes');
    }
    async getInboxSentCSV(response) {
        var GetMessagelogsrequest = {
            "pageNumber": response.pageNumber,
            "pageSize": response.pageSize,
            "searchText": this.searchText,
            "unifiedTypes": this.sentaccountTypeFilter.length > 0 ? this.sentaccountTypeFilter.toString() : (this.filterByType ? this.filterByType.toString() : null),
            "from": this.sentfromFilter ? moment.utc(this.sentfromFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "to": this.senttoFilter ? moment.utc(this.senttoFilter).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "reportGroupType": this.groupTypeFilter,
            "sortBy": this.sentSortBy,
            "sortDirection": this.sentSortDirection,
            "day": (this.sentfromFilter || this.senttoFilter) ? false : this.sentDayFilter,
            "isAgent": this._pageService.isAgentView
        };
        this._toastr.info("Start downloading process", 'Info');
        return await this._restService.Postblob('/Inbox/GetSentMessagelogsCommon?export=true', GetMessagelogsrequest).subscribe((res: any) => {
                var blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
                this._pageService.saveFile(blob, "SentList.csv");
                this._toastr.success("Download Completed", 'Success');
            },
                error => {
                    this._toastr.error("Download Failed", 'Error');
                }
            );
    }
    getProgressMessageReport(id) {
        return this._restService.Get('/Inbox/GetInredisMessageReport?MessageId='+id);
    }
    UpdateTemplateMenuId(MenuId,TemplateId){
        return this._restService.Get('/Template/UpdateTemplateMenuId?MenuId='+MenuId+'&TemplateId='+TemplateId);
    }
}
