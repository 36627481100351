import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'add-url',
    templateUrl: './add-url.component.html',
    styleUrls: ['./add-url.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddUrlComponent implements OnInit, AfterViewInit {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    formData: any;
    dialogTitle: string = "Add Url";
    dialogRef: any;
    linkUrlStatus:any = "";
    fontFamilies:any=[
      'Arial, sans-serif',
      'Helvetica, sans-serif',
      'Gill Sans, sans-serif',
      'Times, serif',
      'Palatino, serif',
      'Bookman, serif',
      'New Century Schoolbook, serif',
      'Andale Mono, monospace',
      'Courier, monospace',
      'Fixed, monospace',
      'Florence, cursive',
    ]
    linkUrl: FormControl = new FormControl();
    fontSize: FormControl = new FormControl();
    fontFamily: FormControl = new FormControl();
    @ViewChild('linkUrlData', {static:false}) linkUrlDataRef: ElementRef;
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    constructor(
        public matDialogRef: MatDialogRef<AddUrlComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        public _pageService: PageService,
        public _formBuilder:FormBuilder,
    ) {
        this.dialogTitle = this._data.dialogTitle;
        this._unsubscribeAll = new Subject();
        if(this._data && this._data.linkUrl && this._data.linkUrl.length > 0) {
          this.linkUrl.setValue(this._data.linkUrl);
          this.linkUrlStatus = "Valid";
        }
        if(this._data && this._data.preferencePrivacyFont){
          this._data.preferencePrivacyFont.fontSize?this.fontSize.setValue(this._data.preferencePrivacyFont.fontSize.slice(0,-2)):'';
          this._data.preferencePrivacyFont.fontFamily?this.fontFamily.setValue(this._data.preferencePrivacyFont.fontFamily):'';
        } 
     }
    ngOnInit(): void {

    }
    ngAfterViewInit(): void {
     
    }
    
    addLink() {
      let req={
        url:this.linkUrl.value,
        fontSize:this.fontSize.value + 'pt',
        fontFamily:this.fontFamily.value
      }
      if(this._data && this._data.type && this._data.type=='preferencePrivacy' && this.linkUrlStatus=='Valid'){
        this._toastr.info('Link added successfully');
        this.matDialogRef.close(req);
      }
      if(this._data && !this._data.type && this.linkUrlStatus == "Valid") {
        this._toastr.info('Link added successfully');
        this.matDialogRef.close(this.linkUrl.value);
      }  
    }
    onLinkUrlData() {
        var expression = /https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const UrlData = this.linkUrlDataRef.nativeElement.value;
        if(UrlData != "" && UrlData != null) {
          if (UrlData.match(expression)) {
            this.linkUrlStatus = "Valid";
          } else {
            this.linkUrlStatus = "InValid";
          }
        }
        
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("add-url-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("add-url-content").style.maxHeight = "65vh";
        document.getElementById("add-url-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("add-url-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("add-url-content").style.maxHeight = "100vh";
        document.getElementById("add-url-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

