import { Component, OnInit,Inject, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";

@Component({
    selector: 'app-edit-logo',
    templateUrl: './edit-logo.component.html',
    styleUrls: ['./edit-logo.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditLogoComponent implements OnInit{
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    dialogTitle: string = "Edit Logo";
    ImageUrl: string;
    dialogRef: any;
    width: FormControl = new FormControl();
    height: FormControl = new FormControl();
    defaultLogoRatio:any=1;
    defaultLogoSize: any = {
        "width": "300px",
        "height": "200px",
    }
    private _unsubscribeAll: Subject<any>;
    constructor(
        public matDialogRef: MatDialogRef<EditLogoComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
    ){
        console.log(_data);
        this.ImageUrl = this._data;
        this._unsubscribeAll = new Subject();
        if(this._data && this._data.logo && this._data.logo.length > 0 && this._data.logoSize != null && this._data.logoSize !== "null" && this._data.logoSize != undefined) {

            (this._data.logoSize.width.slice(0,-2) !== undefined &&  this._data.logoSize.width.slice(0,-2) !== null && this._data.logoSize.width.slice(0,-2) !== "null" && this._data.logoSize.width.slice(0,-2) !== '') ? this.width.setValue(this._data.logoSize.width.slice(0,-2)) : '300px';
            (this._data.logoSize.height.slice(0,-2) !== undefined &&  this._data.logoSize.height.slice(0,-2) !== null && this._data.logoSize.height.slice(0,-2) !== "null" && this._data.logoSize.height.slice(0,-2) !== '') ? this.height.setValue(this._data.logoSize.height.slice(0,-2)) : '200px';
        }
        else{
            this._data.optInLogoSize = this.defaultLogoSize;
            this._data.logoRatio=this.defaultLogoSize;
            this.width.setValue(this._data.logoSize.width.slice(0,-2));
            this.height.setValue(this._data.logoSize.height.slice(0,-2));
        }
    }

    ngOnInit(): void {
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("edit-logo-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("edit-logo-content").style.maxHeight = "65vh";
        document.getElementById("edit-logo-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("edit-logo-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("edit-logo-content").style.maxHeight = "100vh";
        document.getElementById("edit-logo-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    changelogoWidth(){
        this.height.setValue(this.width.value/this._data.logoRatio);
        document.getElementById("logo").style.width = this.width.value + 'px'  || '3000px';
        document.getElementById("logo").style.height = this.height.value + 'px'  || '2000px';
    }
    changelogoHeight(){
        this.width.setValue(this.height.value*this._data.logoRatio);
        document.getElementById("logo").style.width = this.width.value + 'px'  || '3000px';
        document.getElementById("logo").style.height = this.height.value + 'px'  || '2000px';
    }
    Save(){
        var req = {
            "width": document.getElementById("logo").style.width || this._data.logoSize.width,
            "height": document.getElementById("logo").style.height || this._data.logoSize.height
        }
        this._toastr.info('logoSize Updated successfully');
        this.matDialogRef.close(req);
    }
}