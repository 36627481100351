import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MailService } from 'app/main/pages/mail/mail.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { RestService } from 'app/shared/rest.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ProvidersService } from 'app/main/pages/providers/providers.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'add-category-dialog',
    templateUrl: './add-category.component.html',
    styleUrls: ['./add-category.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddCategoryComponent {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    confirmDialogRef: any;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    action: string;
    categoryForm: FormGroup;
    dialogTitle: string;
    saveButtom: string;
    menus: any[] = [];
    private _unsubscribeAll: Subject<any>;
    public filterRank: FormControl = new FormControl();
    public filterCountry: FormControl = new FormControl();
    constructor(
        public matDialogRef: MatDialogRef<AddCategoryComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _providersService: ProvidersService,
        private _restService: RestService,
        private _pageService: PageService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _mailService: MailService,
        public _matDialog: MatDialog
    ) {
        // Set the defaults
        this.action = _data.action;
        this.menus = (_data.pageName == 'releaseNotes') ? this._providersService.releaseNotesMenuList : this._providersService.learningMenuList;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        if (this.action === 'new') {
            this.dialogTitle = _data.dialogTitle;
            this.saveButtom = 'ADD';
        }
        else {
            this.dialogTitle = _data.dialogTitle;
            this.saveButtom = 'SAVE';
        }
        this.categoryForm = this.createCategoryForm();
    }
    ngOnInit(): void {
        if (this._data) {
            this.categoryForm.patchValue({
                name: this._data.category ? this._data.category.name : '',
                menu: this._data.menu ? this._data.menu : ( this._data.category ? this._data.category.menuId : '')
            });
        }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    createCategoryForm(): FormGroup {
        return this._formBuilder.group({
            menu: ['', [Validators.required]],
            name: ['', [Validators.required]]
        });
    }

    addEditCategory() {
        if (this.categoryForm.invalid) {
            this._pageService.validateAllFormFields(this.categoryForm);
            return false;
        }
        var req: any = {
            ...((this._data && this._data.category) ? { "id": this._data.category.id } : {}),
            "name": this.categoryForm.get('name').value,
            "menuId": this.categoryForm.get('menu').value
        };
        this._fuseSplashScreenService.show();
        this._providersService.addEditCategory(req, this._data.pageName)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(this.categoryForm);
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error.error, 'Error');
                    console.log(error);
                }
            );
    }
    deleteCategory() {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete this category?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._fuseSplashScreenService.show();
                this._providersService.DeleteCategory(this._data.category.id, this._data.pageName)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success('Category deleted successfully', 'Success');
                            this.matDialogRef.close({'type': 'category', 'categoryId': this._data.category.id});
                        } else {
                            this._toastr.error(res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            console.log(error);
                            this._toastr.error(error.error.error, 'Error');
                        }
                    );
            }
            this.confirmDialogRef = null;
        });
    }

    deleteMenuItem() {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete this menu item?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._fuseSplashScreenService.show();
                this._providersService.DeleteMenu(this._data.category.menuId, this._data.pageName)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success('Menu Item deleted successfully', 'Success');
                            this.matDialogRef.close({'type': 'menu', 'menuId': this._data.category.menuId});
                        } else {
                            this._toastr.error(res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            console.log(error);
                            this._toastr.error(error.error.error, 'Error');
                        }
                    );
            }
            this.confirmDialogRef = null;
        });
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("add-category-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("addlcategorymenu-content").style.maxHeight = "65vh";
        document.getElementById("addlcategorymenu-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("add-category-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("addlcategorymenu-content").style.maxHeight = "100vh";
        document.getElementById("addlcategorymenu-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

