import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import { MatTableDataSource } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'import-stats',
    templateUrl: './import-stats.component.html',
    styleUrls: ['./import-stats.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ImportStatsDialogComponent {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    stats: any;
    groupList: any = [];
    groupSource: MatTableDataSource<any>;
    groupColumns: string[] = ['groupName', 'total', 'importedTotal', 'wireless', 'nonWireless'];
    countriesList: any = [];
    countriesSource: MatTableDataSource<any>;
    countriesColumns: string[] = ['contactCount', 'countryName'];
    private _unsubscribeAll: Subject<any>;
    constructor(
        public matDialogRef: MatDialogRef<ImportStatsDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _contactsService: ContactsService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        if (this._data.importId) {
            this._fuseSplashScreenService.show();
            this._contactsService.getStatsById(this._data.importId)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this.stats = res.data.importStats;
                        this.groupList = res.data.groupContactImportStats;
                        this.groupSource = new MatTableDataSource(this.groupList);
                        this.countriesList = res.data.contactByCountry;
                        this.countriesSource = new MatTableDataSource(this.countriesList);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        console.log(error);
                    }
                );
        }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("import-stats-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("importstats-content").style.maxHeight = "65vh";
        document.getElementById("importstats-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("import-stats-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("importstats-content").style.maxHeight = "100vh";
        document.getElementById("importstats-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


}

