import { Component, Inject, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { ToastrService } from 'ngx-toastr';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { PageService } from 'app/main/pages/pages.service';
import * as _ from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
@Component({
  selector: 'app-add-contacts',
  templateUrl: './add-contacts.component.html',
  styleUrls: ['./add-contacts.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AddContactsComponent implements OnInit {
  hostHeight: any;
  hostWidth: any;
  IsDialogMax: boolean = false;
  pageNumber: number = 1;
  pageSize: number = 10;
  TotalCount: number = 0;
  contacts: any = [];
  selectedContacts: any[];
  checkboxes: {};
  contactIds: any = [];
  hasSelectedAll: boolean;
  showCountLoader: boolean = true;
  listLength: number = 0;
  dripCampaignId: any
  dataSource: MatTableDataSource<any>;
  private _unsubscribeAll: Subject<any>;
  hasSelectedContacts: boolean;
  searchInput: FormControl;
  searchInteger: FormControl;
  flag: boolean = true;
  @ViewChild('paginatorTop', { static: false }) paginatorTop: MatPaginator;
  @ViewChild('paginatorBottom', { static: false }) paginatorBottom: MatPaginator;
  @ViewChild(FusePerfectScrollbarDirective, { static: false })
  directiveScroll: FusePerfectScrollbarDirective;
  displayedColumns = ['checkbox', 'firstName', 'lastName', 'phonenumber', 'associateId', 'emailVerified', 'verifiedNumber', 'statusName'];
  constructor(
    public _contactsService: ContactsService,
    public _pageService: PageService,
    public matDialogRef: MatDialogRef<AddContactsComponent>,
    private _campaignsService: CampaignsService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _toastr: ToastrService,
  ) { 
    this.searchInput = new FormControl('');
    this.searchInteger = new FormControl('');
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.dripCampaignId = this._data.campaignId;
    this.checkboxes = {};
    this._contactsService.init().then((resolved) => {
  
      if(this._contactsService.appledFilters.value) {
          this._contactsService.appledFilters.value.searchText = this._contactsService.searchText;
          this._contactsService.appledFilters.value.associateId = this._contactsService.searchInteger ? parseInt(this._contactsService.searchInteger) : 0;
      }
      else {
          this._contactsService.appledFilters.next({});
          this._contactsService.appledFilters.value.channels = [];
          this._contactsService.appledFilters.value.paidAsRanks = [];
          this._contactsService.appledFilters.value.highestRanks = [];
          this._contactsService.appledFilters.value.countries = [];
          this._contactsService.appledFilters.value.countries= [];
          this._contactsService.appledFilters.value.associateTypes= [];
          this._contactsService.appledFilters.value.associateStatus = [];
          this._contactsService.appledFilters.value.hasActiveAutoship = null;
          this._contactsService.appledFilters.value.languageCodes = [];
          this._contactsService.appledFilters.value.optFilter = [];
          this._contactsService.appledFilters.value.invalidData = [];
          this._contactsService.appledFilters.value.fromDate ='';
          this._contactsService.appledFilters.value.toDate = '';
          this._contactsService.appledFilters.value.customFields = null;
          this._contactsService.appledFilters.value.searchText = this._contactsService.searchText;
          this._contactsService.appledFilters.value.associateId = this._contactsService.searchInteger ? parseInt(this._contactsService.searchInteger) : 0;
          this._contactsService.appledFilters.value.customFieldsCompare = null;
          this._contactsService.appledFilters.value.sponsorId = 0;
      }
  
      this._contactsService.appledFilters
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(filters => {
          this._contactsService.onResetContactList.next(true);
          this.getAllContacts();
      });
      this._contactsService.onContactsChanged
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(contacts => {
              let temp_list = [];
              contacts.forEach((obj: any) => {
                  const found = _.find(this.contacts, function (t) { return t.id == obj.id });
                  if (found == undefined || found == null) {
                      temp_list.push(obj);
                  }
              });
              Array.prototype.push.apply(this.contacts, temp_list);
              temp_list.map(contact => {
                this.checkboxes[contact.id] = false;
              });
              this.dataSource = new MatTableDataSource(this.contacts);
              //this.resetPagination(this._contactsService.contactsPageNumber - 1);
          });
      this._contactsService.onContactsLengthChanged
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(length => {
              this.listLength = length;
          });
      this._contactsService.onSelectedContactsChanged
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(selectedContacts => {
              for (const id in this.checkboxes) {
                  if (!this.checkboxes.hasOwnProperty(id)) {
                      continue;
                  }

                  this.checkboxes[id] = selectedContacts.includes(parseInt(id));
              }
              this.selectedContacts = selectedContacts;
              if (this.selectedContacts.length > 0 && (this.selectedContacts.length == this.contacts.length)) {
                  this.hasSelectedAll = true;
              }
              else {
                  this.hasSelectedAll = false;
              }
          });
      this._contactsService.onResetContactList
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(event => {
              if (event) {
                  this.contacts = [];
                  this.checkboxes = {};
                  this.directiveScroll.scrollToTop();
              }
          });
      this._contactsService.showCountChanged.
          pipe(takeUntil(this._unsubscribeAll))
          .subscribe(event => {
              if (event) {
                  this.showCountLoader = true;
              }
              else {
                  this.showCountLoader = false;
              }
          });

      this.searchInput.valueChanges
          .pipe(
              takeUntil(this._unsubscribeAll),
              debounceTime(2000),
              distinctUntilChanged()
          )
          .subscribe(searchText => {
              this._contactsService.searchText = searchText;
              this._contactsService.contactsPageNumber = 1;
              this._contactsService.onResetContactList.next(true);
              this._contactsService.getContacts();
              //this._contactsService.getContactsLength();
          });

        this.searchInteger.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(2000),
                distinctUntilChanged()
            )
            .subscribe(searchInteger => {
                this._contactsService.searchInteger = searchInteger;
                this._contactsService.contactsPageNumber = 1;
                this._contactsService.onResetContactList.next(true);
                this._contactsService.getContacts();
                //this._contactsService.getContactsLength();
            });
    })
    
  }

  onScrollDown(ev) {
    this._contactsService.contactsPageNumber += 1;
    this._contactsService.getContacts();
  }

  getAllContacts() {
    if (this._pageService.contactDetailsRedirection) {
        this._contactsService.contactsPageNumber = 1;
        this._contactsService.contactsPageSize = 100;
        this._contactsService.searchText = this._pageService.contactDetailsRedirection.selectedSearchText;
        this._contactsService.searchInteger = this._pageService.contactDetailsRedirection.selectedSearchInteger;
        this.searchInput.patchValue(this._contactsService.searchText, { emitEvent: false, onlySelf: true });
        this._pageService.contactDetailsRedirection = null;
    }
    else {
        this._contactsService.contactsPageNumber = 1
        this._contactsService.contactsPageSize = 100;
    }
    this._contactsService.getContacts();
    //this._contactsService.getContactsLength();
}

  onSelectedChange(contactId) {
    //this.contactIds.push(contactId);
    this._contactsService.toggleSelectedContact(contactId);
  }

  addContactToCampaign() {
    if(this.flag) {
      if (this.selectedContacts.length > 0) {
        this.flag = false;
        this._campaignsService.addDripForContact(this.selectedContacts, this.dripCampaignId).subscribe((res: any) => {
          if (res.status == 200) {
            this._fuseSplashScreenService.hide();
            this._toastr.success("Process started", 'Success');
            this.matDialogRef.close(true);
          } else {
            this.flag = true;
            this._toastr.error(res.message, 'Error');
          }
        },
          error => {
            this.flag = true;
            this._fuseSplashScreenService.hide();
            this._toastr.error(error.message, 'Error');
          });
      } else {
        this._toastr.warning("Please select any Contact", 'Warning');
      }
    }
  }

  minimizeingDialog() {
    let elements: any = document.getElementsByClassName("add-contacts-details");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("sendmsgdetail-content").style.maxHeight = "65vh";
    document.getElementById("sendmsgdetail-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeingDialog() {
    let elements: any = document.getElementsByClassName("add-contacts-details");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("sendmsgdetail-content").style.maxHeight = "100vh";
    document.getElementById("sendmsgdetail-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}



