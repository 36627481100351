import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getByDynamicColumn',
    pure: false
})
export class GetByDynamicColumnPipe implements PipeTransform
{
    transform(value: any[], id: any, property: string, column: string): any
    {
        const foundItem = value.find(item => {
            if ( item[column] !== undefined )
            {
                return item[column] === id;
            }

            return false;
        });

        if ( foundItem )
        {
            return foundItem[column];
        }
    }
}
