import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { RestService } from '../../../shared/rest.service';
@Injectable()
export class ProfileService {
    userNumberList: any[] = [];
    onuserNumberListChanged = new BehaviorSubject<any>([]);
    constructor(
        private _restService: RestService,
        private _toastr: ToastrService,
    ) {
        this.onuserNumberListChanged = new BehaviorSubject<any>([]);
    }
    /*
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    getProfile() {
        var _url = "/Account/users/me";
        return this._restService.Get(_url);
    }
    updateProfile(data) {
        var _url = "/Account/users/me";
        return this._restService.Put(_url, data);
    }
    uploadImage(data) {
        var _url = "/Account/users/UploadImage";
        return this._restService.PostFile(_url, data);
    }
    two2FA(id, req) {
        return this._restService.Put("/Account/users/TwoFA/" + id, req);
    }
    GetUserNumbers() {
        this._restService.Get("/Company/GetUserNumbers").subscribe((res: any) => {
        if (res.status == 200) {
          this.userNumberList = res.data;
          this.onuserNumberListChanged.next(this.userNumberList);
        } else {
          this._toastr.error(res.error, 'Error');
          this.userNumberList = [];
          this.onuserNumberListChanged.next(this.userNumberList);
        }
      },
        error => {
          this._toastr.error(error.error.error, 'Error');
        }
      );
    }
}
