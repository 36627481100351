import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { PageService } from "app/main/pages/pages.service";
import { ToastrService } from "ngx-toastr";
import { AddEditSideMenuName } from "../addEdit-sideMenu-name/addEdit-sideMenu-name.component";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  menuList: any
  dialogRef: any;
  filterById: any = 0;
  @Input() showCurrentFolder: string;
  @Input() title: string;
  @Input() sideMenuApiList: any;
  @Output() onSelected = new EventEmitter<any>();
  private _unsubscribeAll: Subject<any>;
  constructor(
    public _fuseSplashScreenService: FuseSplashScreenService,
    private _pageService: PageService,
    public _matDialog: MatDialog, private _toastr: ToastrService, private _fuseNavigationService: FuseNavigationService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._pageService.onEmailTemplateCatlogChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
      if(val) {
        this.filterById = 0;
      }
    });
    this.getAllMenuList();
  }

  getAllMenuList() {
    this._fuseSplashScreenService.show();
    this._pageService.getAllMenuList(this.sideMenuApiList.getMenuListApi).subscribe((res) => {
      this._fuseSplashScreenService.hide();
      if (res.status == 200) {
        this.menuList = res['data'];
      } else {
        this._toastr.error(res['error'], 'Error');
      }
    }, error => {
      this._fuseSplashScreenService.hide();
      this._toastr.error(error.error.error, 'Error');
    });
  }

  addEditSubMenu(type, item?) {
    this.dialogRef = this._matDialog.open(AddEditSideMenuName, {
      panelClass: "addEdit-menu-dialog",
      disableClose: true,
      data: {
        type: type,
        item: item,
        dialogTitle: type == 'edit' ? "Edit Menu" : "Add Menu"
      }
    });
    this.dialogRef.afterClosed()
      .subscribe((response: any) => {
        if (!response) {
          return;
        }
        let req = {
          'name': response
        }
        if (type == 'edit') {
          req['id'] = item.id;
        }
        let url = this.sideMenuApiList.addEditMenuApi;
        this._fuseSplashScreenService.show();
        this._pageService.addEditMenu(url, req).subscribe((res) => {
          this._fuseSplashScreenService.hide();
          if (res.status == 200) {
            this.getAllMenuList();
            this._toastr.success(type == 'edit' ? 'Menu updated successfully' : 'Menu added successfully', 'Success');
          }
        });
      });
  }

  deleteMenu(subItem): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete folder?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._fuseSplashScreenService.show();
        let url = this.sideMenuApiList['deleteMenuApi'] + subItem.id;
        this._pageService.deleteMenu(url).subscribe((res: any) => {
          this._fuseSplashScreenService.hide();
          if (res.status == 200) {
            this.getAllMenuList();
            this.onMenuIdChange(0);
          } else {
            this._toastr.error(res.error, 'Error');
          }
        },
          error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error(error.error.error, 'Error');
          }
        );
      }
      this.confirmDialogRef = null;
    });
  }
  onMenuIdChange(id): void {
    this.filterById = id;
    this.onSelected.emit(id);
  }
}
