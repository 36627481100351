import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { PageService } from 'app/main/pages/pages.service';
import { ProfileService } from 'app/main/pages/profile/profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'add-more-num-list',
  templateUrl: './add-more-num-list.component.html',
  styleUrls: ['./add-more-num-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AddMoreNumList implements OnInit {
  hostHeight: any;
  hostWidth: any;
  IsDialogMax: boolean = false;
  TotalCount: number = 0;
  dialogRef:any;
  dataSource: MatTableDataSource<any>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  displayedColumns: string[] = ['phone', 'status', 'actions'];
  constructor(
    public matDialogRef: MatDialogRef<AddMoreNumList>,
    private _fuseSplashScreenService: FuseSplashScreenService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _toastr: ToastrService,
    private _matDialog: MatDialog,
    private _pageService: PageService,
    private _profileService: ProfileService,
  ) { 
      this.TotalCount = _data.NumData.length;
      this.dataSource = new MatTableDataSource(this._data.NumData);
  }

  ngOnInit() {
    this._profileService.onuserNumberListChanged.subscribe((res) => {
      this.TotalCount = res.length;
      this.dataSource = new MatTableDataSource(res);
    });
  }
  deleteNumber(report): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete this Number?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._fuseSplashScreenService.show();
        this._pageService.deleteNumber(report.id)
          .subscribe((res: any) => {
            this._fuseSplashScreenService.hide();
            if (res.status == 200) {
              this._toastr.success('Number Deleted Successfully', 'Success');
              this._profileService.GetUserNumbers();
            } else {
              this._toastr.error(res.error, 'Error');
            }
          },
            error => {
              this._fuseSplashScreenService.hide();
              if (error.error) {
                this._toastr.error(error.error.error, 'Error');
              }
              else {
                this._toastr.error(error.message, 'Error');
              }
            }
          );
      }
      this.confirmDialogRef = null;
    });

  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("add-more-num-list");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("add-more-num-content").style.maxHeight = "65vh";
    document.getElementById("add-more-num-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("add-more-num-list");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("add-more-num-content").style.maxHeight = "100vh";
    document.getElementById("add-more-num-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
