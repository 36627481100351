import { FuseUtils } from '@fuse/utils';

export class Account
{
    id: number;
    userId: string;
    user:string;
    unifiedTypeId: number;
    unifiedType: AccountType;
    username: string;
    password: string;
    emailOrNumber: string

    /**
     * Constructor
     *
     * @param account
     */
    constructor(account)
    {
        {
            this.id = account.id || FuseUtils.generateGUID();
            this.userId = account.userId || 0;
            this.user = account.user || '';
            this.unifiedTypeId = account.unifiedTypeId || 0;
            this.unifiedType = account.unifiedType || {};
            this.username = account.username || '';
            this.password = account.password || '';
            this.emailOrNumber = account.emailOrNumber || '';
        }
    }
}

export class AccountType
{
    id: number;
    name: string;

    /**
     * Constructor
     *
     * @param account
     */
    constructor(accounttype)
    {
        {
            this.id = accounttype.id || FuseUtils.generateGUID();
            this.name = accounttype.name || '';
        }
    }
}
