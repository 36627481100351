import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Account, AccountType } from 'app/main/pages/accounts/account.model';
import { AccountsService } from 'app/main/pages/accounts/accounts.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuseSplashScreenService } from '../../../../../@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { PageService } from 'app/main/pages/pages.service';
import { CompanyService } from 'app/main/pages/company/company.service';
import { isThisSecond } from 'date-fns';

@Component({
    selector: 'external-account-form-dialog',
    templateUrl: './external-account-form.component.html',
    styleUrls: ['./external-account-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ExternalAccountsFormDialogComponent {
    IsDialogMax: boolean = false;
    companyId: number;
    action: string;
    account: any;
    accountTypes: any[] = [];
    allUnifiedAccounts: any[];
    registredAccounts: any;
    accountForm: FormGroup;
    dialogTitle: string;// Private
    private _unsubscribeAll: Subject<any>;
    istitle: boolean = false;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    usernameLabel: string = "Username";
    passwordLabel: string = "Password";
    emailOrNumberLabel: string = "Email/Phone Number";
    supportDomainsId: number = 0;
    supportDomainsArray: any = [];
    marketingSupportDomainsId: number = 0;
    marketingSupportDomainsArray: any = [];
    hostWidth: number;
    hostHeight: number;
    alphaPattern: string = "^[a-zA-Z]$";
    accountTypeId:any;
    password;
    show: boolean = false;
    constructor(
        public matDialogRef: MatDialogRef<ExternalAccountsFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: FormBuilder,
        private _accountsService: AccountsService,
        private _pageService: PageService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
        private _companyService: CompanyService,
        private chRef: ChangeDetectorRef,
    ) {
        // Set the defaults
        this.companyId = this._data.companyId;
        this.action = this._data.action;
        this.accountTypeId = this._data.typeId;
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Account';
            this.account = this._data.account;
            this.istitle = true;
        }
        else {
            this.dialogTitle = 'New Account';
            this.account = new Account({});
            this.istitle = false;
        }
        this.accountForm = this.createaccountForm();
    }
    ngOnInit(): void {
        this.password = 'password';
        this.accountTypes = [];
        this._pageService.onExternalAccountTypeChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(types => {
                if (!this._data.agentId) {
                    this.accountTypes = this._pageService.allAccountTypes.filter(type => ((type.id != 7) && (type.id != 11) && (type.id != 12) && (type.id != 14)));
                    if (this.action === 'new') {
                        if (this.accountTypes.length > 0) {
                            if (this._data.typeId) {
                                this.accountForm.patchValue({
                                    unifiedTypeId: this._data.typeId
                                });
                            }
                            else {
                                this.chRef.detectChanges();
                                this.accountForm.patchValue({
                                    unifiedTypeId: this.accountTypes[0].id
                                });
                            }
                        }
                        this.changeAccountType(true);

                    }
                }
            });
            this._pageService.onExternalAccountTypeByUserChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(types => {
                if (this._data.agentId) {
                    this.accountTypes = this._pageService.externalAccountTypesByUser.filter(type => ((type.id != 7) && (type.id != 11) && (type.id != 12) && (type.id != 14)));
                    if (this.action === 'new') {
                        if (this.accountTypes.length > 0) {
                            if (this._data.typeId) {
                                this.accountForm.patchValue({
                                    unifiedTypeId: this._data.typeId
                                });
                            }
                            else {
                                this.accountForm.patchValue({
                                    unifiedTypeId: this.accountTypes[0].id
                                });
                            }
                        }
                        this.changeAccountType(true);

                    }
                }
            });
        if (this.action === 'edit') {
            this._fuseSplashScreenService.show();
            this._pageService.getExternalAccountById(this._data.account.id)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this.account = res.data;
                        if(this.account && this.account.unifiedTypeId) {
                            if(this.account.unifiedTypeId == 2) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "SMS") {
                                        item.id = 2;
                                    }
                                });
                            }else if(this.account.unifiedTypeId == 11) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "SMS") {
                                        item.id = 11;
                                    }
                                });
                            }else if(this.account.unifiedTypeId == 13) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "International SMS") {
                                        item.id = 13;
                                    }
                                });
                            }else if(this.account.unifiedTypeId == 14) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "International SMS") {
                                        item.id = 14;
                                    }
                                });
                            }
                            this.chRef.detectChanges();
                        }
                        this.accountForm.patchValue({
                            id: this.account.id,
                            userId: this.account.userId,
                            username: this.account.userName,
                            password: this.account.password,
                            unifiedTypeId: this.account.unifiedTypeId,
                            emailOrNumber: this.account.emailOrNumber,
                            signatureText: this.account.signatureText,
                            allowMMS: this.account.allowMMS,
                            prefix: this.account.prefix
                        });
                        if(this.account && this.account.unifiedTypeId == 11 || this.account.unifiedTypeId == 14) {
                            this.accountForm.patchValue({
                                apiToken: this.account.apiToken,
                                apiSecret: this.account.apiSecret,
                                providerType: this.account.unifiedTypeId
                            });    
                        }
                        if(this.account && this.account.unifiedTypeId == 13) {
                            this.accountForm.patchValue({
                                providerType: this.account.unifiedTypeId
                            });    
                        }
                        if(this.account && this.account.unifiedTypeId == 11) {
                            this.accountForm.patchValue({
                                mps: this.account.mps
                            });    
                        }
                        if(this.account.supportAccounts && this.account.supportAccounts.length > 0 && this.account.supportAccounts.length > 0) {
                            this.accountForm.get('supportAccounts.domain').setValue(this.account.supportAccounts[0].domain ? this.account.supportAccounts[0].domain : '');
                            this.accountForm.get('supportAccounts.prefix').patchValue(this.account.supportAccounts[0].prefix ? this.account.supportAccounts[0].prefix : '');
                        }
                        if (this.account.supportDomains.length > 0) {
                            this.account.supportDomains.forEach((domain: any, index: number) => {
                                this.addDomains(domain);
                            });
                        }
                        if (this.account.supportAccounts && this.account.supportAccounts.length > 0 && this.account.supportAccounts[0].supportDomains.length > 0) {
                            this.account.supportAccounts[0].supportDomains.forEach((supportDomainValue: any, index: number) => {
                                this.addMarketingDomains(supportDomainValue);
                            });
                        }
                        this.accountForm.get('unifiedTypeId').disable();
                        this.accountForm.get('providerType').disable();
                        this.changeAccountType(false);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        console.log(error);
                        //this._toastr.error(error.error.error, 'Error');
                    }
                );
        }
        this.accountForm.get('unifiedTypeId').valueChanges.subscribe(val => {
            if (val == 11 || val == 14) {
              this.accountForm.controls['apiToken'].setValidators([Validators.required]);
              this.accountForm.controls['apiSecret'].setValidators([Validators.required]);
            } else {
              this.accountForm.controls['apiToken'].clearValidators();
              this.accountForm.controls['apiSecret'].clearValidators();
            }
            this.accountForm.controls['apiToken'].updateValueAndValidity();
            this.accountForm.controls['apiSecret'].updateValueAndValidity();
            if(val == 2 || val == 13 || val == 14 || val == 11) {
                this.accountForm.patchValue({
                    providerType: val
                }); 
            }
            if (val == 9) {
                this._pageService.removeValidators(this.accountForm, "username");
                this._pageService.removeValidators(this.accountForm, "password");
                this._pageService.removeValidators(this.accountForm, "emailOrNumber");
            }
            else {
                this._pageService.addValidators(this.accountForm, "username");
                this._pageService.addValidators(this.accountForm, "password");
                this._pageService.addValidators(this.accountForm, "emailOrNumber");
            }
          });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createaccountForm(): FormGroup {
        return this._formBuilder.group({
            id: [0],
            userId: [''],
            username: ['', [Validators.required]],
            password: ['', [Validators.required]],
            unifiedTypeId: ['', [Validators.required]],
            emailOrNumber: this.accountTypeId !=1 ? ['', [Validators.required]] : ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
            signatureText: [''],
            supportDomains: this._formBuilder.array([]),
            allowMMS: [false],
            prefix: ['', [Validators.pattern('[a-zA-Z]*')]],
            supportAccounts: new FormGroup({
                // id:new FormControl(0),
                domain: new FormControl(''),
                supportDomains: this._formBuilder.array([]),
                prefix:new FormControl('',  [Validators.pattern('[a-zA-Z]*')]),
              }),
            providerType: [''],
            apiToken: [''],
            apiSecret: [''],
            mps: [0]
        });
    }

    addAccount() {
        var FormData = this.accountForm.getRawValue();
        if (this.accountForm.invalid) {
            this._pageService.validateAllFormFields(this.accountForm);
            return false;
        }
        if (FormData.unifiedTypeId == 6 && !FormData.emailOrNumber) {
            this._toastr.warning('Bot Id is not found, Please use a valid API Token', 'Warning');
            return false;
        }
        let supportDomains: any = [];
        let marketingSupportDomain: any = [];
        if (FormData.unifiedTypeId == 1) {
            FormData.supportDomains.map(item => {
                if (item.domain) {
                    supportDomains.push(item.domain);
                }
            });
            FormData.supportAccounts.supportDomains.map(item => {
                if (item.marketingdomain) {
                    marketingSupportDomain.push(item.marketingdomain);
                }
            });
            FormData.supportAccounts.supportDomains = marketingSupportDomain;
        }
        if (FormData.unifiedTypeId == 2) {
            FormData.supportDomains.map(item => {
                if (item.domain) {
                    supportDomains.push(item.domain);
                }
            });
        }
        
        let ContactsRequest: any = {
            "unifiedTypeId": FormData.unifiedTypeId,
            "emailOrNumber": FormData.unifiedTypeId == 9 ? 'notification' : FormData.emailOrNumber,
            "userName": FormData.username,
            "password": FormData.password,
            "signatureText": FormData.unifiedTypeId == 1 ? FormData.signatureText : '',
            "supportDomains": supportDomains,
            "allowMMS": FormData.allowMMS
        };
        let supAccount = [];
        if (FormData.unifiedTypeId == 1) {
            let supportAccountsData =  FormData.supportAccounts;
            supAccount.push(supportAccountsData);
            ContactsRequest.prefix = FormData.prefix;
            ContactsRequest.supportAccounts = supAccount;
        }
        if (FormData.unifiedTypeId == 11 || FormData.unifiedTypeId == 14) {
            ContactsRequest.apiToken = FormData.apiToken;
            ContactsRequest.apiSecret = FormData.apiSecret;
            ContactsRequest.mps = FormData.unifiedTypeId == 11 ? FormData.mps : null;
        }
        this._fuseSplashScreenService.show();
        this._pageService.createExternalAccount(ContactsRequest, this.companyId)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(this.accountForm);
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error.error, 'Error');
                }
            );
    }
    editAccount() {
        var FormData = this.accountForm.getRawValue();
        if (this.accountForm.invalid) {
            this._pageService.validateAllFormFields(this.accountForm);
            return false;
        }
        if (FormData.unifiedTypeId == 6 && !FormData.emailOrNumber) {
            this._toastr.warning('Bot Id is not found, Please use a valid API Token', 'Warning');
            return false;
        }
        let supportDomains: any = [];
        let marketingSupportDomain: any = [];
        if (FormData.unifiedTypeId == 1) {
            FormData.supportDomains.map(item => {
                if (item.domain) {
                    supportDomains.push(item.domain);
                }
            });
            FormData.supportAccounts.supportDomains.map(item => {
                if (item.marketingdomain) {
                    marketingSupportDomain.push(item.marketingdomain);
                }
            });
            FormData.supportAccounts.supportDomains = marketingSupportDomain;
        }
        if (FormData.unifiedTypeId == 2) {
            FormData.supportDomains.map(item => {
                if (item.domain) {
                    supportDomains.push(item.domain);
                }
            });
        }
        let ContactsRequest: any = {
            "id": this.account.id,
            "unifiedTypeId": FormData.unifiedTypeId,
            "emailOrNumber": FormData.unifiedTypeId == 9 ? 'notification' : FormData.emailOrNumber,
            "userName": FormData.username,
            "password": FormData.password,
            "signatureText": FormData.unifiedTypeId == 1 ? FormData.signatureText : '',
            "supportDomains": supportDomains,
            "allowMMS": FormData.allowMMS
        };
        let supAccount = [];
        if (FormData.unifiedTypeId == 1) {
            let supportAccountsData =  FormData.supportAccounts;
            supAccount.push(supportAccountsData);
            ContactsRequest.prefix = FormData.prefix;
            ContactsRequest.supportAccounts = supAccount;
            ContactsRequest.supportAccounts[0].id = (this.account && this.account.supportAccounts && this.account.supportAccounts.length>0 && this.account.supportAccounts[0].id) ? this.account.supportAccounts[0].id : 0;
        }
        if (FormData.unifiedTypeId == 11) {
            ContactsRequest.apiToken = FormData.apiToken;
            ContactsRequest.apiSecret = FormData.apiSecret;
            ContactsRequest.mps = FormData.unifiedTypeId == 11 ? FormData.mps : null;
        }
        this._fuseSplashScreenService.show();
        this._pageService.editExternalAccount(ContactsRequest, this.companyId)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(this.accountForm);
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error.error, 'Error');
                }
            );

    }
    changeAccountType(reset) {
        var FormData = this.accountForm.getRawValue();
        if (!FormData.unifiedTypeId) {
            this.usernameLabel = "Username";
            this.passwordLabel = "Password";
            this.emailOrNumberLabel = "Email/Phone Number";
            this.accountForm.get('emailOrNumber').enable();
        }
        if (FormData.unifiedTypeId == 1) {
            this.usernameLabel = "Transactional Domain";
            this.passwordLabel = "API Key";
            this.emailOrNumberLabel = "Email";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 2 || FormData.unifiedTypeId == 8 || FormData.unifiedTypeId == 13) {
            this.usernameLabel = "ACCOUNT SID";
            this.passwordLabel = "AUTH TOKEN";
            this.emailOrNumberLabel = "Message Service Id";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 3) {
            this.usernameLabel = "ACCOUNT SID";
            this.passwordLabel = "AUTH TOKEN";
            this.emailOrNumberLabel = "Phone Number";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 4) {
            this.usernameLabel = "ACCOUNT SID";
            this.passwordLabel = "AUTH TOKEN";
            this.emailOrNumberLabel = "Channel Id";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 5) {
            this.usernameLabel = "ACCOUNT SID";
            this.passwordLabel = "AUTH TOKEN";
            this.emailOrNumberLabel = "Page Id";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 6) {
            this.usernameLabel = "API Token";
            this.passwordLabel = "Password";
            this.emailOrNumberLabel = "Bot Id";
            this.accountForm.get('emailOrNumber').disable();
        }
        else if(FormData.unifiedTypeId == 11 || FormData.unifiedTypeId == 14) {
            this.usernameLabel = "Application Id";
            this.passwordLabel = "Account Id";
            this.emailOrNumberLabel = "Sender Number";
            this.accountForm.get('emailOrNumber').enable();
        }
        if (reset) {
            this.resetAccountForm();
        }
    }
    removeByAttr(arr, attr, value) {
        var i = arr.length;
        while (i--) {
            if (arr[i]
                && arr[i].hasOwnProperty(attr)
                && (arguments.length > 2 && arr[i][attr] === value)) {
                arr.splice(i, 1);
            }
        }
        return arr;
    }
    onUsernameChange() {
        if (this.accountForm.get('unifiedTypeId').value == 6) {
            this.accountForm.patchValue({
                emailOrNumber: ''
            });
            this._fuseSplashScreenService.show();
            this._accountsService.GetTelegramMeByToken(this.accountForm.get('username').value)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this.accountForm.patchValue({
                            emailOrNumber: res.data.id
                        });
                    } else {
                        this._toastr.error('Bot Id not found', 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error('Bot Id not found', 'Error');
                        console.log(error);
                        //this._toastr.error(error.error.error, 'Error');
                    }
                );
        }
    }
    resetAccountForm() {
        this.accountForm.patchValue({
            userId: '',
            username: '',
            password: '',
            emailOrNumber: ''
        });
    }
    addDomains(domainValue) {
        this.supportDomainsArray.push(this.supportDomainsId);
        this.supportDomainsId = this.supportDomainsId + 1;
        this.getArray().push(this._formBuilder.group({
            domain: [domainValue ? domainValue : '']
        }));
    }
    addMarketingDomains(supportDomainValue) {
        this.marketingSupportDomainsArray.push(this.marketingSupportDomainsId);
        this.marketingSupportDomainsId = this.marketingSupportDomainsId + 1;
        this.getMarketingSupportDomainArray().push(this._formBuilder.group({
            marketingdomain: [supportDomainValue ? supportDomainValue : '']
        }));
    }
    removeDomain(index) {
        this.supportDomainsArray.splice(index, 1);
        this.getArray().removeAt(index);
    }
    getArray() {
        return this.accountForm.get('supportDomains') as FormArray;
    }
    getMarketingSupportDomainArray() {
        return this.accountForm.get("supportAccounts.supportDomains") as FormArray;
    }
    removeMarketingSupportDomain(index) {
        this.marketingSupportDomainsArray.splice(index, 1);
        this.getMarketingSupportDomainArray().removeAt(index);
    }
    providerTypeChange(Event) {
        if(Event.value == 2) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "SMS") {
                    item.id = 2;
                }
            });
        }else if(Event.value == 11) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "SMS") {
                    item.id = 11;
                }
            });
        }else if(Event.value == 13) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "International SMS") {
                    item.id = 13;
                }
            });
        }else if(Event.value == 14) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "International SMS") {
                    item.id = 14;
                }
            });
        }
        this.chRef.detectChanges();
        this.accountForm.get("unifiedTypeId").setValue(Event.value);
        this.changeAccountType(true);
    }
    onClick() {
        if (this.password === 'password') {
          this.password = 'text';
          this.show = true;
        } else {
          this.password = 'password';
          this.show = false;
        }
    }
    checkMPSValidation(event) {
        if(!(event.target.value >=1 && event.target.value<=30)) {
            if(event.target.value != '') {
                this.accountForm.get('mps').markAsTouched();
                this.accountForm.get('mps').setErrors({'incorrect': true});
            }
        }
      }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("external-account-form-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("newaccount-content").style.maxHeight = "65vh";
        document.getElementById("newaccount-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("external-account-form-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("newaccount-content").style.maxHeight = "100vh";
        document.getElementById("newaccount-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}
