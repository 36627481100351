import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
    selector   : 'partial-splash-loader',
    templateUrl: './partial-splash-loader.component.html',
    styleUrls  : ['./partial-splash-loader.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PartialSplashLoaderComponent implements OnInit, AfterViewInit, OnDestroy
{
    constructor(
    )
    {
    }
    ngOnInit(): void
    {       

    }

    ngAfterViewInit(): void
    {
    }
    ngOnDestroy(): void
    {
    }

    
}
