import { Component, OnInit, ViewEncapsulation, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from 'app/main/pages/pages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatPaginator, MatDialog, Sort, MatSort, MatDialogRef } from '@angular/material';
import * as moment from "moment-timezone";
import { ChatDetailsDialogComponent } from '../dialogs/chat-details/chat-details.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FiltersComponent implements OnInit {
    @Input() isRecentSentPage: boolean;
    @Input() isSearch: boolean;
    @Input() isGroup: boolean;
    @Input() isRefresh: boolean;
    @Input() isType: boolean;
    @Input() isValidDataType: boolean;
    @Input() isSentReportingtype: boolean;
    @Input() noDateFilters: boolean;
    @Input() showSearchWithoutDateFilter: boolean;
    @Input() filterForm: FormGroup;
    @Output() onRefreshList = new EventEmitter<any>();
    @Output() onSearch = new EventEmitter<any>();
    @Input() IsError: boolean;
    @Input() IsCompanyList: boolean;
    @Input() IsEventList: boolean;
    @Input() isNextRowFilter: boolean;
    @Input() isReferenceId: boolean;
    @Input() isSelectType: boolean;
    @Input() isROIReportingtype: boolean;
    companyNameCtrl: FormControl = new FormControl('');
    eventNameCtrl: FormControl = new FormControl('');
    accountTypes: any[] = [];
    groupTypes: any = [
        {
            id: 0,
            name: 'All'
        },
        {
            id: 1,
            name: 'Group'
        },
        {
            id: 2,
            name: 'Single'
        }
    ];
    validDataTypes: any = [
        {
            id: 1,
            name: 'Valid Mobile Numbers'
        },
        {
            id: 2,
            name: 'Invalid Mobile Numbers'
        },
        {
            id: 3,
            name: 'Valid Emails'
        },
        {
            id: 4,
            name: 'Invalid Emails'
        }
    ];
    reportingTypes: any[] = [
        {
            id: 0,
            name: "Total"
        },
        {
            id: 1,
            name: "Delivered"
        },
        {
            id: 2,
            name: "Undelivered"
        },
        {
            id: 3,
            name: "Failed"
        },
        {
            id: 4,
            name: "Clicks"
        },
        {
            id: 5,
            name: "Segments"
        },
        {
            id: 6,
            name: "Replies"
        },
        {
            id: 7,
            name: "Opens"
        },
        {
            id: 8,
            name: "Bounced"
        },
        {
            id: 9,
            name: "Dropped"
        },
        {
            id: 10,
            name: "Complained"
        },
        {
            id: 11,
            name: "Opt Out"
        },
        {
            id: 12,
            name: "Not Clicked"
        },
        {
            id: 13,
            name: "Not Opened"
        }
    ];
    ROIReportingtype: any[] = [
        {
            id: 0,
            name: "Clicks"
        },
        {
            id: 1,
            name: "Views"
        },
        {
            id: 2,
            name: "AbandonedCart"
        },
        {
            id: 3,
            name: "Revenue"
        },
        {
            id: 4,
            name: "Orders"
        }
    ]
    errorLogs: any[] = [
        {
            Id: 0,
            name: "All"
        },
        {
            Id: 1,
            name: "Success"
        },
        {
            Id: 2,
            name: "Fail"
        },


    ];
    Types: any = [
        {
            id: 0,
            name: 'All Types'
        },
        {
            id: 1,
            name: 'List'
        },
        {
            id: 2,
            name: 'Segments'
        },
        {
            id: 3,
            name: 'Starred'
        }
    ];
    companyList: any;

    public date = new Date()
    public currentyear: number = this.date.getFullYear()

    maxdate = new Date(this.currentyear, 12, 1)
    minDate = new Date(2000, 0, 1);

    private _unsubscribeAll: Subject<any>;
    ApiLogsCompanies: any;
    ApiLogsEvents: any = [];

    constructor(
        private _pageService: PageService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        if (this.IsCompanyList) {
            this.getApiLogsCompany();
        }
        if (this.IsEventList) {
            this.getApiLogsEvents("");
        }
        this._pageService.onAccountTypeByUserChanged
            .subscribe(types => {
                if (types) {
                    this.accountTypes = this._pageService.accountTypesByUser;
                }
                else {
                    this.accountTypes = [];
                }
            });
    }
    getApiLogsCompany() {
        this._pageService.getApiLogsCompanyList().subscribe(
            (res: any) => {

                if (res) {
                    this.ApiLogsCompanies = res.data;
                }
            },
            error => {
                console.log(error);
            }
        );
    }
    getApiLogsEvents(companyKey: any) {
        this._pageService.getEventsForPublicapis(companyKey.value).subscribe((res) => {
                if (res['data'].length > 0) {
                    this.ApiLogsEvents = res['data'];
                }
            },
            error => {
                this.ApiLogsEvents = [];
                console.log(error);
            }
        );
    }

    refreshList() {
        if (this.IsEventList) {
            this.getApiLogsEvents("");
        }
        this.onRefreshList.emit();
    }
    search() {
        this.onSearch.emit();
    }
}
