import { Validators } from '@angular/forms';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';

@Component({
    selector: 'setPage-Num-Size',
    templateUrl: './setPage-Num-Size.component.html',
    styleUrls: ['./setPage-Num-Size.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SetPageNumSize {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    setPageNumSizeForm: FormGroup;
    dialogTitle: string;
    dialogRef: any;
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    pageNumArray: any[] = [];
    listLength: any;
    pageSizeLimit: number;
    constructor(
        public matDialogRef: MatDialogRef<SetPageNumSize>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: FormBuilder,
        private _contactsService: ContactsService,
        private _pageService: PageService,
        private _toastr: ToastrService,
        public _matDialog: MatDialog
    ) {
        this.pageSizeLimit = (_data && (_data.pageName == 'allContacts' || _data.pageName == 'optIns' || _data.pageName == 'optOut')) ? 100000 : 100
        this._unsubscribeAll = new Subject();
        this.dialogTitle = 'Set Page Number & Size';
        this.listLength = _data.totalCount;
        if(this.listLength) {
           var limit  = Math.ceil(this.listLength / this.pageSizeLimit);
           if(limit !=0 && limit != Infinity) {
            this.pageNumArray = [];
            for(let i=1; i<=limit; i++) {
                this.pageNumArray.push(i);
            }
           }
        }
        this.setPageNumSizeForm = this.createSetPageNumSizeForm();
    }
    ngOnInit(): void {
        this.setPageNumSizeForm.get('pageSize').valueChanges.subscribe(val => {
            if(this._data && (this._data.pageName == 'allContacts' || this._data.pageName == 'optIns' || this._data.pageName == 'optOut')) {
                if(this.setPageNumSizeForm.get('pageSize').value > 100000) {
                    this._toastr.warning('Page Size should not be greater than 100000', 'Warning');
                    return false;
                }
            }else if(this._data && (this._data.pageName != 'allContacts' || this._data.pageName != 'optIns' || this._data.pageName != 'optOut')) {
                if(this.setPageNumSizeForm.get('pageSize').value > 100) {
                    this._toastr.warning('Page Size should not be greater than 100', 'Warning');
                    return false;
                }
            }
            if(val && val <= (this._data && (this._data.pageName == 'allContacts' || this._data.pageName == 'optIns' || this._data.pageName == 'optOut')) ? 100000 : 100) {
                var limit  = Math.ceil(this.listLength / this.setPageNumSizeForm.get('pageSize').value);
                if(limit > 22768) {
                    this._toastr.warning('Increase the page size limit minmun 10', 'Warning');
                    return false;
                }
                if(limit !=0 && limit != Infinity && limit <= 22768) {
                    this.pageNumArray = [];
                    for(let i=1; i<=limit; i++) {
                        this.pageNumArray.push(i);
                    }
                }
            }
           if(this.pageNumArray && this.pageNumArray.length > 0 && this.pageNumArray.indexOf(this.setPageNumSizeForm.get('pageNumber').value) == -1) {
              this.setPageNumSizeForm.patchValue({
                pageNumber: 1
              });
           }
        })
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    createSetPageNumSizeForm(): FormGroup {
        return this._formBuilder.group({
            pageNumber: [1, [Validators.required]],
            pageSize: (this._data && (this._data.pageName == 'allContacts' || this._data.pageName == 'optIns' || this._data.pageName == 'optOut')) ? [this.pageSizeLimit, [Validators.required, Validators.min(10), Validators.max(100000)]] : [this.pageSizeLimit, [Validators.required, Validators.min(10), Validators.max(100)]]
        });
    }
    setNumSize () {
        if(this._data && (this._data.pageName == 'allContacts' || this._data.pageName == 'optIns' || this._data.pageName == 'optOut')) {
            if(this.setPageNumSizeForm.get('pageSize').value > 100000) {
                this._toastr.warning('Page Size should not be greater than 100000', 'Warning');
                return false;
            }
        }else if(this._data && (this._data.pageName != 'allContacts' || this._data.pageName != 'optIns' || this._data.pageName != 'optOut')) {
            if(this.setPageNumSizeForm.get('pageSize').value > 100) {
                this._toastr.warning('Page Size should not be greater than 100', 'Warning');
                return false;
            }
        } 
        if(this.setPageNumSizeForm.get('pageSize').value < 10) {
            this._toastr.warning('Page Size should not be less than 10', 'Warning');
            return false;
        }
        if (this.setPageNumSizeForm.invalid) {
            this._pageService.validateAllFormFields(this.setPageNumSizeForm);
            return false;
        }
        var setPageNumSizeFormData = this.setPageNumSizeForm.getRawValue();
        this.matDialogRef.close(setPageNumSizeFormData);
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("SetPageNumSize-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("SetPageNumSize-content").style.maxHeight = "65vh";
        document.getElementById("SetPageNumSize-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("SetPageNumSize-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("SetPageNumSize-content").style.maxHeight = "100vh";
        document.getElementById("SetPageNumSize-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

