import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import { RestService } from '../../../shared/rest.service';
import { PageService } from '../pages.service';
import { AuthService } from 'app/shared/auth.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService implements Resolve<any>
{

    onContactSelected: BehaviorSubject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;

    onChatLogsChanged: BehaviorSubject<any>;
    onChatSearchChanged: Subject<any>;
    onViewContactDetails: Subject<any>;
    onChatDetailsChanged: BehaviorSubject<any>;
    chats: any[] = [];
    chatLogsList: any[] = [];
    chatLogsPageNumber: number = 1;
    chatLogsPageSize: number = 100;
    chatLogsTotalCount: number = 0;
    searchTextForChat: string = '';

    chatDetailsList: any[] = [];
    chatDetailsPageNumber: number = 1;
    chatDetailsPageSize: number = 50;
    chatDetailsTotalCount: number = 0;
    constructor(
        private _restService: RestService,
        private _pageService: PageService,
        private _authService: AuthService,
        private _chatPanelService: ChatPanelService,
        private _fuseSplashScreenService: FuseSplashScreenService
    ) {
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();
        this.onContactSelected = new BehaviorSubject(null);

        // Set the defaults
        this.onChatLogsChanged = new BehaviorSubject([]);
        this.onChatSearchChanged = new Subject();
        this.onViewContactDetails = new Subject();
        this.onChatDetailsChanged = new BehaviorSubject([]);
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.init()
            ]).then(
                (res) => {
                    resolve('');
                },
                reject
            );
        });
    }
    init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.onContactSelected = new BehaviorSubject(null);
            this.onChatLogsChanged = new BehaviorSubject([]);
            this.onChatSearchChanged = new Subject();
            this.onViewContactDetails = new Subject();
            this.onChatDetailsChanged = new BehaviorSubject([]);
            this.chatLogsPageNumber = 1;
            this.searchTextForChat = "";
            this.getChatlogs();
            resolve('initialized');
        });
    }
    getChatlogs() {
        this._fuseSplashScreenService.show();
        var userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
        var isAgentRole = userDetails && userDetails.roles.length > 0 && (userDetails.roles[0] == 'agent') && userDetails.defaultAgentView;
        var chatLogsRequest = {
            "pageNumber": this.chatLogsPageNumber,
            "pageSize": this.chatLogsPageSize,
            "searchName": this.searchTextForChat,
            "isAgent": isAgentRole
        };
        this._restService.Post('/Inbox/GetChatlogs', chatLogsRequest)
            .subscribe((response: any) => {
                if (response.status == 200) {
                    this.chatLogsList = response.data;
                    this.onChatLogsChanged.next(this.chatLogsList);
                    this._fuseSplashScreenService.hide();
                }
            });
    }

    getChatDetails(id, isMarkAsRead): void {
        if (id) {
            this._fuseSplashScreenService.show();
            var GetMessageDetailssrequest = {
                "messageId": id,
                "pageNumber": this.chatDetailsPageNumber,
                "unifiedTypeId": 0,
                "pageSize": this.chatDetailsPageSize
            };
            this._restService.Post('/Inbox/GetMessageDetailsNew', GetMessageDetailssrequest)
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.chatDetailsList = response.data;
                        //this.chatDetailsTotalCount = response.data.totalCount;
                        if (isMarkAsRead) {
                            this._chatPanelService.markMessagesAsRead(id);
                        }
                        this.onChatDetailsChanged.next(this.chatDetailsList);
                        this._fuseSplashScreenService.hide();
                    }
                },
                    error => {
                        console.log(error);
                    });
        }

    }


    /**
     * Select contact
     *
     * @param contact
     */
    selectContact(contact): void {
        this.chatDetailsPageNumber = 1;
        if (contact.unread > 0) {
            this.getChatDetails(contact.messageId, true);
        }
        else {
            this.getChatDetails(contact.messageId, false);
        }
        this.onContactSelected.next(contact);
    }
}
