import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {RestService} from '../../../shared/rest.service';
@Injectable()
export class ApiLogsService
{
    selectedApiLogs: string[] = [];
    onSelectedApiLogsChanged: BehaviorSubject<any>;
    constructor(
        private _restService:RestService
    )
    {
        this.onSelectedApiLogsChanged = new BehaviorSubject([]);
    }

    resolve(): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.init()
            ]).then(
                (res) => {
                    resolve('init');
                },
                reject
            );
        });
    }

    init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.onSelectedApiLogsChanged = new BehaviorSubject([]);
            resolve('initialized');
        });
    }

    toggleSelectedApiLogs(id): void {
        if (this.selectedApiLogs.length > 0) {
            const index = this.selectedApiLogs.indexOf(id);
            if (index !== -1) {
                this.selectedApiLogs.splice(index, 1);
                this.onSelectedApiLogsChanged.next(this.selectedApiLogs);
                return;
            }
        }
        this.selectedApiLogs.push(id);
        this.onSelectedApiLogsChanged.next(this.selectedApiLogs);
    }
    deselectApiLogs(): void {
        this.selectedApiLogs = [];
        this.onSelectedApiLogsChanged.next(this.selectedApiLogs);
    }
    GetPublicApiLogs(req){
        var _url="/Inbox/GetPublicApiLogs";
        return this._restService.Post(_url,req);
    }

    ReRunAPI(report, isArchive){
        var _url="/Campaign/ReRunPublicApi/"+ report._id+'?isArchive='+isArchive;
        return this._restService.Get(_url);
    }
    ReRunMultipleAPI(request, isArchive){
        var _url="/Campaign/ReRunPublicApiList?isArchive="+isArchive;
        return this._restService.Post(_url, request);
    }
}
