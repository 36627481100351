import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { BasicAuthInterceptor } from './basic-auth.interceptor';
import { ErrorInterceptor } from './http-error.interceptor';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './auth.guard';
import { AuthService } from './shared/auth.service';
import { PreventLoggedInAccess } from './shared/preventAccess.guard';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserService } from './shared/user.service';
import { SharedModule } from './shared/shared.module'
import { PageService } from './main/pages/pages.service';
const appRoutes: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(a => a.PagesModule),
        // loadChildren: './main/pages/pages.module#PagesModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./LogIn/login.module').then(a => a.LoginModule),
        canActivate: [PreventLoggedInAccess]
    },
    {
        path: 'forgotpassword',
        loadChildren: () => import('./main/forgot-password/forgot-password.module').then(a => a.ForgotPasswordModule)
    },
    {
        path: 'distributorsignup',
        loadChildren: () => import('./main/distributor-signup/distributor-signup.module').then(a => a.DistributorSignupModule)
    },
    {
        path: 'public',
        loadChildren: () => import('./main/public/public.module').then(a => a.publicModule)
    },
    {
        path: '',
        redirectTo: '/pages/dashboard',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '/pages/dashboard',
        pathMatch: 'full',
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'ignore' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatTooltipModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        // App modules
        LayoutModule,
        SharedModule,

        // toaster
        ToastrModule.forRoot()
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        AuthGuard,
        AuthService,
        UserService,
        PageService
    ]
})
export class AppModule {
}
