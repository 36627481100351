import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewEncapsulation, AfterViewInit, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { $ } from 'protractor';
import { Subject } from 'rxjs';
import { HelpTinyUrlDialogComponent } from '../../dialogs/help-tiny-url/help-tiny-url.component';


@Component({
    selector: 'add-tiny-url',
    templateUrl: './add-tiny-url.component.html',
    styleUrls: ['./add-tiny-url.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class addTinyUrlDialogComponent implements OnInit {
  dialogTitle: string = "Add Link Tracking";
  IsDialogMax: boolean = false;
  hostHeight: any;
  hostWidth: any;
  advanceForm: FormGroup;
  helpTinyUrlDialogRef: any;
  private _unsubscribeAll: Subject<any>;
  constructor(
      public matDialogRef: MatDialogRef<addTinyUrlDialogComponent>,
      private _formBuilder: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public _data: any,
      public _matDialog: MatDialog,
      private chRef: ChangeDetectorRef,
  ) {
    this._unsubscribeAll = new Subject();
    this.advanceForm = this.createAdvanceForm();
    setTimeout(() => {
      this.dirtyComponent();
    }, 1)
  }
  createAdvanceForm(): FormGroup {
      return this._formBuilder.group({
        tinyUrl: ['']
      });
  }
  ngOnInit(): void {
    this.chRef.markForCheck();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  helpTinyUrl() {
    this.helpTinyUrlDialogRef = this._matDialog.open(HelpTinyUrlDialogComponent, {
      panelClass: 'help-tiny-url-dialog'
    });
    this.helpTinyUrlDialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  addTinyUrl(url) {
    this.matDialogRef.close(url);
  }
  addMergeField(event) {
     this.advanceForm.controls['tinyUrl'].patchValue(this.advanceForm.controls['tinyUrl'].value +''+ event);
 }

 dirtyComponent = () => {
  document.getElementById('tiny-label').click();
  this.chRef.markForCheck();
 }
 
}
