import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { PageService } from 'app/main/pages/pages.service';

@Component({
  selector: 'add-trigger-events',
  templateUrl: './add-trigger-events.component.html',
  styleUrls: ['./add-trigger-events.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AddTriggerEventsComponent implements OnInit {
  IsDialogMax: boolean = false;
  hostHeight: any;
  hostWidth: any;
  action: string;
  triggerForm: FormGroup;
  dialogTitle: string;
  providerId: any;
  companyNameList:any;
  constructor(
    private _matDialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _campaignsService: CampaignsService,
    public matDialogRef: MatDialogRef<AddTriggerEventsComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _pageService: PageService
  ) {
    this.action = this._data.action;
    this.providerId = this._data.providerId;
    this.triggerForm = this.createTriggerForm();
    if (this.action === 'Edit') {
      this.dialogTitle = 'Edit Trigger Events';
    }
    else {
      this.dialogTitle = 'Add Trigger Events';
    }
  }

  ngOnInit() {
    if (this.action === 'Edit') {
      this.triggerForm.patchValue({
        eventName: this._data.triggerEvent.eventName,
        properties: this._data.triggerEvent.properties,
        companyIds: this._data.triggerEvent.companyIds
      });
    }
    this.getCompanyName();
  }
  createTriggerForm(): FormGroup {
    return this._formBuilder.group({
      eventName: ['', [Validators.required]],
      properties: [''],
      companyIds: ['']
    });
  }
  save() {
    if (this.triggerForm.invalid) {
      this.validateAllFormFields(this.triggerForm);
      return false;
    }
    let triggerFormData = this.triggerForm.getRawValue();
    let triggerReq: any = {
      "eventName": triggerFormData.eventName,
      "properties": triggerFormData.properties,
      "companyIds": triggerFormData.companyIds
    };
    if (this.providerId) {
      triggerReq.provider = this.providerId;
    }
    if (this.action === 'Edit') {
      triggerReq = {
        ...this._data.triggerEvent,
        "eventName": triggerFormData.eventName,
        "properties": triggerFormData.properties,
        "companyIds": triggerFormData.companyIds
      }
    }
    this._fuseSplashScreenService.show();
    this._campaignsService.AddEditTriggerEvent(triggerReq)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          this._toastr.success('Event saved successfully', 'Success');
          this.matDialogRef.close('success');
        } else {
          this._toastr.error(res.error, 'Error');
        }
      },
        error => {
          this._fuseSplashScreenService.hide();
          if (error.error.error) {
            this._toastr.error(error.error.error, 'Error');
          }
          else {
            this._toastr.error(error.error, 'Error');
          }
        }
      );
  }
  getCompanyName(){
    let request:any={
      "baseProvider": this.providerId
    }
      this._campaignsService.getAllCompanyNames(request).subscribe((res:any)=>{
       if(res.data.report){
        this.companyNameList = res.data.report;
       }else{
         this.companyNameList = [];
       }
      })
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control: any = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else {
        this.validateAllFormFields(control);
      }
    });
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("add-trigger-event");
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.width = (this.hostWidth + 1 + 'px');
        elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("addtriggerevent-content").style.maxHeight = "65vh";
    document.getElementById("addtriggerevent-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
}
maximizeDialog() {
    let elements: any = document.getElementsByClassName("add-trigger-event");
    for (var i = 0; i < elements.length; i++) {
        this.hostHeight = elements[i].clientHeight;
        this.hostWidth = elements[i].clientWidth;
        elements[i].style.width = ("100%");
        elements[i].style.height = ("100%");
    }
    document.getElementById("addtriggerevent-content").style.maxHeight = "100vh";
    document.getElementById("addtriggerevent-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
}

}
