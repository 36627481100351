import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'htmlToTruncatetext'})
export class htmlToTruncatetextPipe implements PipeTransform
{
    transform(value: string, args: any[] = []): string {
        value =  value.replace(/(<(script|style|id)\b[^>]*>).*?(<\/\2>)/is, ' ');
        value = value.replace(/@media[^{]+\{([\s\S]+?\})\s*\}/, ' ');
        var tmp = document.createElement("DIV");
        tmp.innerHTML = value;
        //var k = tmp.querySelector("p:nth-child(1)");
        var k = tmp.textContent.trim();
        return k ? k : '';
    }
}
