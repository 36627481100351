import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreviewComponent implements OnInit, OnChanges {
  @Input() message: any;
  @Input() optOutTrailor: any;
  @Input() messageSegements: any;
  @Input() media: any;
  @Input() mediaType: any;
  @Input() isTop: any;
  constructor() { }
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
