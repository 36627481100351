import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RestService } from '../../../shared/rest.service';
import { AuthService } from 'app/shared/auth.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { PageService } from '../pages.service';

@Injectable({
    providedIn: 'root'
})
export class JourneyService {
    onEventTypeChanged = new BehaviorSubject<any>(null);
    OnJourneyCampaignEventChanged = new BehaviorSubject<any>('');
    OnEventFilterChanged = new BehaviorSubject<any>(null);
    OnEventSubFilterChanged = new BehaviorSubject<any>(null);
    onMasterEventChange = new BehaviorSubject<any>('');
    onJourneyEventChange =new BehaviorSubject<any>('');
    OnJourneyCampaignChange = new BehaviorSubject<any>('');
    unifiedTypeIdsOnCampaignChange = new BehaviorSubject<any>({});
    // onSelectedCampChange= new BehaviorSubject<any>({});
    onSelectedCampChange: Subject<any>;
    /**
     * Constructor
     *
     * @param _restService: RestService
     */
    constructor(
        private _restService: RestService,
        private _authService: AuthService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
        private _pageService: PageService,
    ) {
        this.init();
    }

    resolve(): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.init()
            ]).then(
                (res) => {
                    resolve('init');
                },
                reject
            );
        });
    }

    init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.onEventTypeChanged = new BehaviorSubject<any>(null);
            this.OnJourneyCampaignEventChanged = new BehaviorSubject('');
            this.onMasterEventChange = new BehaviorSubject('');
            this.OnEventFilterChanged = new BehaviorSubject<any>(0);
            this.OnEventSubFilterChanged = new BehaviorSubject<any>(0);
            this.onJourneyEventChange = new BehaviorSubject('');
            this.OnJourneyCampaignChange = new BehaviorSubject('');
            this.unifiedTypeIdsOnCampaignChange = new BehaviorSubject({});
            // this.onSelectedCampChange = new BehaviorSubject({});
            this.onSelectedCampChange = new Subject<any>();
            
            resolve('initialized');
        });
    }

    getEventTypeMasters() {
        return this._restService.Get("/Journey/GetEventTypeMasters");
    }
    AssignJourneytoCampaign(journeyId, campaignId) {
        return this._restService.Get("/Journey/AssignJourneytoCampaign/"+journeyId+"/"+campaignId);
    }
    getJourenyAnalytics(req) {
        return this._restService.Post('/Analytics/GetTopCampaignsNew', req);
    }
    GetSurveyTypeMasters() {
        return this._restService.Get("/Journey/GetSurveyTypeMasters");
    }
    addEditSurvey(req) {
        return this._restService.Post('/Journey/AddEditSurvey', req);
    }
    getSurveyById(id) {
        return this._restService.Get("/Journey/GetSurveyById/"+id);
    }

    
    getAllCampaignEventMenus(companyId?) {
        return this._restService.Get('/Journey/GetAllJourneyEventMenus?CompanyId='+companyId);
    }

    addEditMenu(req: any) {
        var _url = '/Journey/AddEditJourneyEventMenu';
        return this._restService.Post(_url, req);
      }

    DeleteMenu(id) {
        var _url = '/Journey/DeleteJourneyEventMenu/'+id;
    return this._restService.Delete(_url, null);
    }

    updateJourneyEventMenus(MenuId,JourneyId) {
        return this._restService.Get('/Journey/UpdateJourneyMenuId?MenuId='+MenuId+'&JourneyId='+JourneyId);
    }

}