import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { PageService } from '../pages.service';
import { RestService } from 'app/shared/rest.service';
import * as moment from "moment-timezone";
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { CalendarEventModel } from './event.model';
import { lastDayOfMonth, startOfMonth, subDays } from 'date-fns';
import RRule from 'rrule';
moment.tz.setDefault('Utc');
@Injectable()
export class CalendarService implements Resolve<any>
{
    events: any[] = [];
    onEventsUpdated: Subject<any>;
    fromDate: string = moment.utc(startOfMonth(new Date())).local().format("YYYY-MM-DD");
    toDate: string = moment.utc(lastDayOfMonth(new Date())).local().format("YYYY-MM-DD");
    recurringDay:any;
    constructor(
        private _restService: RestService,
        private _pageService: PageService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
    ) {
        // Set the defaults
        this.onEventsUpdated = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.init()
            ]).then(
                (res) => {
                    resolve('initialized');
                },
                reject
            );
        });
    }

    init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.events = [];
            this.fromDate = moment.utc(startOfMonth(new Date())).local().format("YYYY-MM-DD");
            this.toDate = moment.utc(lastDayOfMonth(new Date())).local().format("YYYY-MM-DD");
            this._pageService.getCurrentPage(false);
            this._pageService.getAccountsTypesByUser();
            this.GetCalendar();
            resolve('initialized');
        });
    }
    GetCalendar() {
        this._fuseSplashScreenService.show();
        this._restService.Get('/Calendar/GetCalendar?from=' + this.fromDate + '&to=' + this.toDate)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hide();
                if (response.status == 200) {
                    this.events = [];
                    if (response.data.events.length > 0) {
                        response.data.events.forEach(evnt => {
                            let tmp: any = {
                                "id": evnt.id,
                                "type": "event",
                                "title": evnt.title,
                                "start": evnt.startDate + 'Z',
                                "end": evnt.endDate + 'Z',
                                "starttime": new Date(evnt.startDate + 'Z').toLocaleTimeString(),
                                "endtime": new Date(evnt.endDate + 'Z').toLocaleTimeString(),
                                "allDay": evnt.allDay,
                                "color": {
                                    "primary": evnt.primaryColor,
                                    "secondary": evnt.secondaryColor
                                },
                                "meta": {
                                    "location": evnt.location,
                                    "notes": evnt.notes
                                },
                                "draggable": false
                            }
                            this.events.push(tmp);
                        });
                    }
                    if (response.data.campaigns.length > 0) {
                        response.data.campaigns.forEach(cam => {
                            let tmp:any;
                            if(cam.isRecurring) {
                                const daySchedule = [
                                    {apiday : 1, changeday: 6, day: 'SU'},
                                    {apiday : 2, changeday: 0, day: 'MO'},
                                    {apiday : 3, changeday: 1, day: 'TU'},
                                    {apiday : 4, changeday: 2, day: 'WE'},
                                    {apiday : 5, changeday: 3, day: 'TH'},
                                    {apiday : 6, changeday: 4, day: 'FR'},
                                    {apiday : 7, changeday: 5, day: 'SA'}
                                    ];
                                daySchedule.forEach((val, index) => {
                                    if(cam.recurringDay == val.apiday) {
                                        this.recurringDay = val.changeday;
                                    }
                                });                   
                                let rule = new RRule({
                                    freq: RRule.WEEKLY,
                                    // interval: 5,
                                    byweekday: [this.recurringDay],
                                    dtstart: new Date(cam.sendDate),
                                    until: new Date( cam.onGoing ? this.toDate : cam.endDate)
                                  })
                                  rule.all().forEach((date, index) => {
                                    tmp = {
                                        "id": cam.id,
                                        "type": "Campaigns",
                                        "title": "Campaign",
                                        "start": moment(date).format("MM/DD/YYYY"),
                                        "name": cam.name,
                                        "starttime": moment(cam.sendDate).format("HH:mm:ss"),
                                        "timeZone": cam.timeZone ? cam.timeZone : 'UTC',
                                        "color": {
                                            "primary": cam.primaryColor,
                                            "secondary": cam.secondaryColor
                                        },
                                        "meta": {
                                            "location": cam.location,
                                            "notes": cam.notes
                                        },
                                        "draggable": false
                                    }
                                    this.events.push(tmp);
                                  });
                            }else {
                                tmp = {
                                    "id": cam.id,
                                    "type": "Campaigns",
                                    "title": "Campaign",
                                    "start": cam.sendDate,
                                    "name": cam.name,
                                    "starttime": moment(cam.sendDate).format("HH:mm:ss"),
                                    "timeZone": cam.timeZone ? cam.timeZone : 'UTC',
                                    "color": {
                                        "primary": cam.primaryColor,
                                        "secondary": cam.secondaryColor
                                    },
                                    "meta": {
                                        "location": cam.location,
                                        "notes": cam.notes
                                    },
                                    "draggable": false
                                }
                                this.events.push(tmp);
                            }
                        });
                    }
                    if (response.data.scheduleMessages.length > 0) {
                        response.data.scheduleMessages.forEach(schMsg => {
                            let tmp: any = {
                                "id": schMsg.id,
                                "type": "message",
                                "title": "Scheduled Message",
                                "subject": schMsg.subject,
                                "contactitems": schMsg.contacts,
                                "accounttypes": schMsg.deliveryType,
                                "groups": schMsg.groups,
                                "reports": schMsg.reports,
                                "start": schMsg.sendDate,
                                "starttime": moment(schMsg.sendDate).format("HH:mm:ss"),
                                "timeZone": schMsg.timeZone ? schMsg.timeZone : 'UTC',
                                "color": {
                                    "primary": schMsg.primaryColor,
                                    "secondary": schMsg.secondaryColor
                                }
                            }
                            this.events.push(tmp);
                        });
                    }
                    this.onEventsUpdated.next(this.events);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                });
    }

    AddEditscheduleMessage(msgReq: any) {
        var _url = "/Calendar/AddEditscheduleMessage";
        return this._restService.Post(_url, msgReq);
    }
    AddEditEvent(evntReq: any) {
        var _url = "/Calendar/AddEditEvent";
        return this._restService.Post(_url, evntReq);
    }
    DeleteScheduleMessag(id: any) {
        var _url = "/Calendar/DeleteScheduleMessage/" + id;
        return this._restService.Delete(_url, null);
    }
    DeleteEvent(id: any) {
        var _url = "/Calendar/DeleteEvent/" + id;
        return this._restService.Delete(_url, null);
    }
    getMessageById(id: any) {
        return this._restService.Get('/Calendar/GetScheduleMessage/' + id)
    }

}
