import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';

@Component({
    selector: 'import-instructions',
    templateUrl: './import-instructions.component.html',
    styleUrls: ['./import-instructions.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ImportInstructionsDialogComponent {
    private _unsubscribeAll: Subject<any>;
    IsDialogMax:boolean =false;
    hostWidth: number;
    hostHeight: number;
    constructor(
        public matDialogRef: MatDialogRef<ImportInstructionsDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any

    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("import-instructions-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width =  (this.hostWidth+1+'px');
            elements[i].style.height =  (this.hostHeight+1+'px');
        }
        document.getElementById("instructions-content").style.maxHeight = "65vh";
        document.getElementById("instructions-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("import-instructions-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("instructions-content").style.maxHeight = "100vh";
        document.getElementById("instructions-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    
}

