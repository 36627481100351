import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { RestService } from '../../../shared/rest.service';
import { PageService } from '../pages.service';
import { ToastrService } from 'ngx-toastr';
import { AccountType } from 'app/main/pages/accounts/account.model';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MediaService implements Resolve<any>
{
    private _unsubscribeAll: Subject<any>;
    onFilesChanged: BehaviorSubject<any>;
    onFileSelected: BehaviorSubject<any>;
    onSubFileSelected: BehaviorSubject<any>;
    showCurrentFolder = new BehaviorSubject<any>('');
    Files: any = [];
    selectedMedia: string[] = [];
    mediaPageNumber: number = 1;
    mediaPageSize: number = 100;
    mediaTotalCount: number = 0;
    mediaSortBy: string = "createdAt";
    mediaSortDirection: string = "desc";

    constructor(
        private _restService: RestService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService
    ) {
        this.init();
        
        
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.init()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    init() {
        this.mediaPageNumber = 1;
        this.mediaPageSize = 100;
        this.mediaTotalCount = 0;
        this.mediaSortBy = "createdAt";
        this.mediaSortDirection = "desc";
        this.Files = [];
        this.onFilesChanged = new BehaviorSubject({});
        this.onFileSelected = new BehaviorSubject({});
        this.onSubFileSelected = new BehaviorSubject({});
        this.showCurrentFolder = new BehaviorSubject<any>('');
    }


    getFiles(menuId?) {
        let mediaReq: any = {
            "searchText": "",
            "pageNumber": this.mediaPageNumber,
            "pageSize": this.mediaPageSize,
            "sortBy": this.mediaSortBy,
            "sortDirection": this.mediaSortDirection,
            "menuId":menuId || 0
        };
        this._fuseSplashScreenService.show();
        this._restService.Post('/MediaFile/GetMediaFiles', mediaReq)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hide();
                if (response.status == 200) {
                    this.Files = response.data.report;
                    this.mediaTotalCount = response.data.totalCount;
                    this.onFilesChanged.next(this.Files);
                }
                else {
                    this.Files = [];
                    this.mediaTotalCount = 0;
                    this.onFilesChanged.next(this.Files);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.Files = [];
                    this.mediaTotalCount = 0;
                    this.onFilesChanged.next(this.Files);
                });
    }
    uploadMedia(data) {
        var _url = "/MediaFile/UploadMediaFile";
        return this._restService.PostFile(_url, data);
    }
    deleteMedia(id) {
        var _url = "/MediaFile/DeleteMediaFile/" + id;
        return this._restService.Delete(_url, null);
    }

    
    updateMediaEventMenus(MenuId,MediaFileId) {
        return this._restService.Get('/MediaFile/UpdateMediaMenuId?MenuId='+MenuId+'&MediaFileId='+MediaFileId);
    }



}
