import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AuthService } from 'app/shared/auth.service';
import * as moment from "moment-timezone";
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import * as _ from 'lodash';
import { ErrorDescriptionDialogComponent } from '../error-description/error-description.component';
import { endOfMonth, startOfDay, startOfMonth, subDays } from 'date-fns';
import { TrackingService } from 'app/main/pages/tracking/tracking.service';
import { ViewMessageDialogComponent } from '../view-message/view-message.component';
import { InvoicePageComponent } from '../inovice-page/invoice-page.component';
import { RoiSummaryDetailsComponent } from '../roi-summary-details/roi-summary-details.component';
import { SentMessageDetailsComponent } from '../sent-message-details/sent-message-details.component';
import { PageService } from 'app/main/pages/pages.service';
import { takeUntil } from 'rxjs/operators';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { RoiClicksDetailsComponent } from '../roi-clicks-details/roi-clicks-details.component';
@Component({
  selector: 'app-order-roi-summary-details',
  templateUrl: './order-roi-summary-details.component.html',
  styleUrls: ['./order-roi-summary-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class OrderRoiSummaryDetailsComponent implements OnInit {
  IsDialogMax: boolean = false;
  private _unsubscribeAll: Subject<any>;
  weekType: any = 0;
  filterForm: FormGroup;
  accountTypes:any[]=[];
  camDataBlastList: any[] = [];
  blastList: any[] = [];
  List: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  TotalCount: any = 0;
  pageNumber = 1;
  pageSize = 10;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(FusePerfectScrollbarDirective, { static: false })
  directiveScroll: FusePerfectScrollbarDirective;
  showCountLoader: boolean;
  hostHeight: any;
  hostWidth: any;
  DialogRef: any;
  userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
  offset = new Date().getTimezoneOffset();
  filterOffset = (this.offset >= 720) ? 719 : (this.offset < -720) ? -600 : this.offset;
  repoType: any = null;
  pageTitle = "";
  companyTimeZone : any = '';
  selectedRow: any;
  dialogRefView: MatDialogRef<ViewMessageDialogComponent, any>;
  sortBy: string = "createdAt";
  sortDirection: string = "desc";
  constructor(
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _projectDashboardService: ProjectDashboardService,
    private _authService: AuthService,
    public matDialogRef: MatDialogRef<OrderRoiSummaryDetailsComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialog,
    private _matDialog: MatDialog,
    public trackingService:TrackingService,
    public _pageService:PageService
  ) {
    try {
      this.companyTimeZone = this.userDetails.timeZone.match(new RegExp("[A-Z](?!.*[\(])","g")).join('');
    } catch (error) {
      
    }
    const date = new Date();
    this._unsubscribeAll = new Subject();
    this.filterForm = this._formBuilder.group({
      groupType: [0],
      searchText: [''],
      searchByFromDate: [(this._data && this._data.daysRange>0) ? subDays(date, this._data.daysRange) : startOfMonth(new Date())],
      searchByToDate: [startOfDay(new Date())],
      accounttypes: [[]],
    });
  }
  displayedColumns = (!this._pageService.checkPermissions('cost-OnROIWidget') && !this._pageService.checkPermissions('orderROI-OnROIWidget')) ? ['typeName','createdAt','campaignName','sent','delivered'] : (!this._pageService.checkPermissions('cost-OnROIWidget') ? ['typeName','createdAt','campaignName','sent','delivered', 'orderRoi'] : (!this._pageService.checkPermissions('orderROI-OnROIWidget') ? ['typeName','createdAt','campaignName','sent','delivered', 'cost'] : ['typeName','createdAt','campaignName','sent','delivered','cost','orderRoi']));
  ngOnInit(): void {
    if(this._pageService.checkPermissions('pageView-OnROIWidget')) {
      this.displayedColumns.push('pageView');
    }else if(!this._pageService.checkPermissions('pageView-OnROIWidget') && this.displayedColumns.includes('pageView')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('pageView'), 1);
    }
    if(this._pageService.checkPermissions('abandonedCart-OnROIWidget')) {
      this.displayedColumns.push('abandonCart');
    }else if(!this._pageService.checkPermissions('abandonedCart-OnROIWidget') && this.displayedColumns.includes('abandonCart')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('abandonCart'), 1);
    }
    if(this._pageService.checkPermissions('revenue-OnROIWidget')) {
      this.displayedColumns.push('revenue');
    }else if(!this._pageService.checkPermissions('revenue-OnROIWidget') && this.displayedColumns.includes('revenue')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('revenue'), 1);
    }
    if(this._pageService.checkPermissions('orders-OnROIWidget')) {
      this.displayedColumns.push('orders');
    }else if(!this._pageService.checkPermissions('orders-OnROIWidget') && this.displayedColumns.includes('orders')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('orders'), 1);
    }
    if(this._pageService.checkPermissions('clicks-OnROIWidget')) {
      this.displayedColumns.push('linkClicks');
    }else if(!this._pageService.checkPermissions('cost-OnROIWidget') && this.displayedColumns.includes('linkClicks')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('linkClicks'), 1);
    }
    
   
    if (this._data.type == 'order') {
      this.pageTitle = 'Order ROI Details'
      this.getSentMessagelogsTracking();
    }else if (this._data.type == 'pageView') {
      this.pageTitle = 'Page View Details'
      this.getSentMessagelogsTracking();
    }else if(this._data.type == 'abandonedCart'){
      this.pageTitle = 'Abandon Cart Details'
      this.getSentMessagelogsTracking();
    }else if(this._data.type == 'clicks'){
      this.pageTitle = 'Clicks Details'
      this.getSentMessagelogsTracking();
    }
    this._pageService.onAccountTypeByUserChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(types => {
        if (types) {
          this.accountTypes = types;
        }
        else {
          this.accountTypes = [];
        }
      });
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
 
  search(event) {
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.getSentMessagelogsTracking();
  }
  
  onRefreshList(event) {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.filterForm.patchValue({
      reportingtype: 0,
      searchText: '',
      searchByFromDate: startOfMonth(new Date()),
      searchByToDate: startOfDay(new Date()),
      accounttypes: [],
      groupType: 0
    });
    if (this._data.type == 'order') {
      this.pageTitle = 'Order ROI Details'
      this.getSentMessagelogsTracking();
    }else if (this._data.type == 'pageView') {
      this.pageTitle = 'Page View Details'
      this.getSentMessagelogsTracking();
    }else if(this._data.type == 'abandonedCart'){
      this.pageTitle = 'Abandon Cart Details'
      this.getSentMessagelogsTracking();
    }
  }

  findType(obj){
    try {
      obj = JSON.parse(obj) || '';
    } catch (error) {
      obj = '';
    }
    if((typeof obj == 'object')){
      return true
    }
  }

  errorDescription(reason) {
    this.DialogRef = this._matDialog.open(ErrorDescriptionDialogComponent, {
      panelClass: 'error-description-dialog',
      disableClose: true,
      data: {
        reason: reason
      }
    });
    this.DialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  onPageChange(event): void {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getSentMessagelogsTracking();
  }
  sortData(event) {
    this.sortBy = event.active;
    this.sortDirection = event.direction;
    this.getSentMessagelogsTracking();
  }
  getSentMessagelogsTracking(){
    this._fuseSplashScreenService.show();
    var req = {
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "sortBy": this.sortBy,
      "sortDirection": this.sortDirection,
      "searchText": this.filterForm.get("searchText").value,
      "from": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "to": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "unifiedTypes": this.filterForm.get('accounttypes').value ? this.filterForm.get('accounttypes').value.toString() : null,
      "reportGroupType": this.filterForm.get("groupType").value,
      "messageDataId": 0,
      "week": 0,
      "contactId": 0,
      "day": false,
      "isAgent": this._data.isAgent,
      "campaignIds": ""
    }
    this._projectDashboardService.getSentMessagelogsTracking(req).subscribe((res:any) => {
      this._fuseSplashScreenService.hide();
      if (res && res.status == 200) {
          this.List = [];
          this.List = res.data;
          this.List.forEach((item) => {
            item.orderRoi = item['cost'] == 0 ? 0 : (isNaN(((parseFloat(item['revenue'])-(parseFloat(item['cost'])))/parseFloat(item['cost']))) ? 0 : ((parseFloat(item['revenue'])-(parseFloat(item['cost'])))/parseFloat(item['cost'])));
          });
          this.dataSource = new MatTableDataSource(this.List);
          if(this.pageNumber == 1) {
            this.getSentMessagelogsTrackingCount(req);
          }
      }
    }, error => {
      this._fuseSplashScreenService.hide();
      this._toastr.error(error.error.error, 'Error');
    });
  
  }
  getSentMessagelogsTrackingCount(req){
    this._fuseSplashScreenService.show();
    this._projectDashboardService.GetSentMessagelogsTrackingCount(req).subscribe((res:any) => {
      this._fuseSplashScreenService.hide();
        if (res && res.status == 200) {
            this.TotalCount = res.data;
        }
    },error =>{
      this._fuseSplashScreenService.hide();
      this._toastr.error(error.error.error, 'Error');
    });
  }
  
  viewDetail(message): void {
    let apiReq = message.jsonData == 'null' ? '' : message.jsonData;
    let apiReq2 = JSON.parse(JSON.stringify(apiReq));
    let formattedApiReq = '',
    f = {
        brace: 0
    };
    formattedApiReq = apiReq2.replace(/['"]+/g, '').replace(/({|}[,]*|[^{}:]+:[^{},]*[,{]*)/g, function (m, p1) {
        let rtnFn = function() {
                return '<div class="api-text" style="text-indent: ' + -(f['brace'] * 20) + 'px; padding-left: ' +(f['brace'] * 40)+ 'px;">' + p1 + '</div>';
            },
            rtnStr;
        if (p1.lastIndexOf('{') === (p1.length - 1)) {
            rtnStr = rtnFn();
            f['brace'] += 1;
        } else if (p1.indexOf('}') === 0) {
                f['brace'] -= 1;
            rtnStr = rtnFn();
        } else {
            rtnStr = rtnFn();
        }
        return rtnStr;
    });
    let beautifiedReq = formattedApiReq.replace(/[,]/g, '<br>').replace(/[\])}[{(|,]+/g, ' ').replace(/(\w+(?=:))/g, function(m) {
        if( m == 'indent' || m == 'left' || /^https/.test(m) || /\b[0-9]{2,}/.test(m)) {
            return m;
        }
        return `<b>${m}</b>`
    })
    this.dialogRefView = this._matDialog.open(ViewMessageDialogComponent, {
        panelClass: 'view-message',
        data: {
            message: `<div class="Responselog"><h1>Page View Details</h1><p class="Resdesc">${beautifiedReq}</p>` +
                `</div>`,
            mediaURL: '',
            dialogTitle: 'JSON Data'
        }
    });
    if (this.dialogRef) {
    }
    this.dialogRefView.afterClosed()
        .subscribe(response => {
            if (!response) {
                return;
            }
        });
  }
  goToInvoicePage(): void {
    this.DialogRef = this._matDialog.open(InvoicePageComponent, {
      panelClass: 'invoice-page',
      data: {
        type:1
      }
    });
    this.DialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  goToRoiClicksDetails(ROIReportingtype:any , report): void {
    this.DialogRef = this._matDialog.open(RoiClicksDetailsComponent, {
      panelClass: 'roi-summary-details',
      data: {
        ROIReportingtype:ROIReportingtype,
        campaignId: report.campaignId.toString(),
        messageId: report.id.toString(),
        from: this.filterForm.get('searchByFromDate').value,
        to: this.filterForm.get('searchByToDate').value,
      }
    });
    this.DialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  goToDetails(reportType, type, report): void {
    this.DialogRef = this._matDialog.open(SentMessageDetailsComponent, {
      panelClass: 'sent-message-details',
      data: {
        reportingType: reportType,
        accounttypes: this.filterForm.get('accounttypes').value ? this.filterForm.get('accounttypes').value : [],
        searchByFromDate: moment.utc(this.filterForm.get('searchByFromDate').value).local().format("YYYY-MM-DD"),
        searchByToDate: moment.utc(this.filterForm.get('searchByToDate').value).local().format("YYYY-MM-DD"),
        isType: true,
        messageId: report.id
      }
    });
    this.DialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }

  minimizeDialog() {
    let elements: any = document.getElementsByClassName("order-roi-summary-details");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("orderroisummarydetail-content").style.maxHeight = "65vh";
    document.getElementById("orderroisummarydetail-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("order-roi-summary-details");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("orderroisummarydetail-content").style.maxHeight = "100vh";
    document.getElementById("orderroisummarydetail-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
