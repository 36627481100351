import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSureDialogComponent } from './sure-dialog.component';

@NgModule({
    declarations: [
        FuseSureDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        FuseSureDialogComponent
    ],
})
export class FuseSureDialogModule
{
}
