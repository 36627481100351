import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
   name: 'innerHTMLtruncate'
})

export class InnerHTMLTruncatePipe implements PipeTransform {
   transform(value: string, length: number, isHTML: boolean): string {
      value =  value.replace(/(<(script|style|id)\b[^>]*>).*?(<\/\2>)/is, ' ');
      value = value.replace(/@media[^{]+\{([\s\S]+?\})\s*\}/, ' ');
      const biggestWord = 50; 
      const elipses = '...';
      if (typeof value === "undefined") return value;
      if (value.length <= length) return value;
      if (isHTML) {
         let tmp = document.createElement("DIV");
         tmp.innerHTML = value;
         let trimText = tmp.textContent.trim();
         if (trimText) {
            value = trimText;
         }
         else {
            return value;
         }
      }
      //.. truncate to about correct lenght
      let truncatedText = value.slice(0, length + biggestWord);
      //.. now nibble ends till correct length
      while (truncatedText.length > length - elipses.length) {

         let lastSpace = truncatedText.lastIndexOf(" ");
         if (lastSpace === -1) break;
         truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?;:]$/, '');

      };

      return truncatedText + elipses;

   }
}