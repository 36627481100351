import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { PageService } from 'app/main/pages/pages.service';

@Component({
  selector: 'add-trigger-properties',
  templateUrl: './add-trigger-properties.component.html',
  styleUrls: ['./add-trigger-properties.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AddTriggerPropertiesComponent implements OnInit {
  userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
  IsDialogMax: boolean = false;
  hostHeight: any;
  hostWidth: any;
  action: string;
  triggerForm: FormGroup;
  dialogTitle: string;
  providerId: any;
  tinymceEditor: any;
  config: any = {
    height: 350,
    branding: false,
    menubar: true,
    plugins: [
      'advlist autolink lists link charmap print hr preview',
      'searchreplace wordcount visualblocks visualchars advcode',
      'insertdatetime nonbreaking save table directionality',
      'powerpaste textpattern help',
      'fullpage tinymcespellchecker a11ychecker fullscreen image'
    ],
    toolbar1: 'undo redo | print custpreview | cut copy paste insert link | fontselect styleselect fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | spellchecker | code | a11ycheck | fullscreen | image',
    relative_urls: false,
    remove_script_host: false,
    powerpaste_allow_local_images: true,
    powerpaste_word_import: 'prompt',
    powerpaste_html_import: 'prompt',
    spellchecker_language: 'en',
    spellchecker_dialog: true,
    menu: {
      file: { title: 'File', items: 'print' },
      edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
      view: { title: 'View', items: 'code | visualaid visualchars visualblocks | preview fullscreen' },
      insert: { title: 'Insert', items: 'insertImage link | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
      format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats blockformats fontformats fontsizes align | removeformat' },
      table: { title: 'Table', items: 'inserttable tableprops deletetable row column cell' }
    },
    setup: (editor) => {
      this.tinymceEditor = editor;
    },
    convert_urls: false,
    image_advtab: false,
    image_list: [],
    image_title: true,
    automatic_uploads: true,
    file_picker_types: 'image',
    file_picker_callback: (cb, value, meta) => {
      var self = this;
      var input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.onchange = function (event: any) {
        var file = event.target.files[0];
        if (file.type.includes('image')) {
          self._pageService.getMediaUrl(file, (mediaUrl: any) => {
            if (mediaUrl) {
              cb(mediaUrl, { title: file.name });
            }
          });
        }
      };
      input.click();
    },
    content_style: "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Lato', sans-serif; }",
    font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats"
  };
  constructor(
    private _matDialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _campaignsService: CampaignsService,
    public matDialogRef: MatDialogRef<AddTriggerPropertiesComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _pageService: PageService
  ) {
    this.action = this._data.action;
    this.providerId = this._data.providerId;
    this.triggerForm = this.createTriggerForm();
    if (this.action === 'Edit') {
      this.dialogTitle = 'Edit Trigger Properties';
    }
    else if (this.action === 'Override') {
      this.dialogTitle = 'Override Trigger Properties';
    }
    else {
      this.dialogTitle = 'Add Trigger Properties';
    }
  }

  ngOnInit() {
    if (this.action === 'Edit' || this.action === 'Override') {
      this.triggerForm.patchValue({
        propertyName: this._data.triggerProperty.propertyName,
        propertyPattern: this._data.triggerProperty.propertyPattern,
        innerFields: this._data.triggerProperty.innerFields,
        emailPropertyPattern: this._data.triggerProperty.emailPropertyPattern,
        useEmailProperty: this._data.triggerProperty.useEmailProperty    
      });
    }
    if (this.action === 'Override' || !this.providerId) {
      this.triggerForm.get('propertyName').disable();
      this.triggerForm.get('innerFields').disable();
    }
  }
  createTriggerForm(): FormGroup {
    return this._formBuilder.group({
      propertyName: ['', [Validators.required]],
      propertyPattern: ['', [Validators.required]],
      innerFields: ['', [Validators.required]],
      emailPropertyPattern: [''],
      useEmailProperty: [false]
    });
  }
  save() {
    if (this.triggerForm.invalid) {
      this.validateAllFormFields(this.triggerForm);
      return false;
    }
    if(this.triggerForm.get("useEmailProperty").value) {
      let emailPropertyData = this.triggerForm.get("emailPropertyPattern").value;
      var doc = new DOMParser().parseFromString(emailPropertyData, "text/html");
      var elems = doc.querySelector('body').children;
      if(elems.length == 0) {
          this._toastr.warning("Please fill the email property pattern field.", "Warning");
        return false;  
      }
    }
    let triggerFormData = this.triggerForm.getRawValue();
    let triggerReq: any = {
      "propertyName": triggerFormData.propertyName,
      "propertyPattern": triggerFormData.propertyPattern,
      "innerFields": triggerFormData.innerFields,
      "emailPropertyPattern": triggerFormData.emailPropertyPattern,
      "useEmailProperty": triggerFormData.useEmailProperty
    };
    if (this.providerId) {
      triggerReq.provider = this.providerId;
      triggerReq.companyId = 0;
      triggerReq.masterId = 0;      
    }
    if (this.action === 'Edit') {
      triggerReq = {
        ...this._data.triggerProperty,
        "propertyName": triggerFormData.propertyName,
        "propertyPattern": triggerFormData.propertyPattern,
        "innerFields": triggerFormData.innerFields,
        "emailPropertyPattern": triggerFormData.emailPropertyPattern,
        "useEmailProperty": triggerFormData.useEmailProperty
      }
    }
    else if (this.action === 'Override') {
      triggerReq = {
        "propertyName": triggerFormData.propertyName,
        "propertyPattern": triggerFormData.propertyPattern,
        "emailPropertyPattern": triggerFormData.emailPropertyPattern,
        "innerFields": triggerFormData.innerFields,
        "provider": this._data.triggerProperty.provider,
        "masterId": this._data.triggerProperty.id,
        "companyId": this.userDetails.companyId ? this.userDetails.companyId : 0,
        "useEmailProperty": triggerFormData.useEmailProperty
      }
    }
    this._fuseSplashScreenService.show();
    this._campaignsService.AddEditTriggerProperty(triggerReq)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          this._toastr.success('Property saved successfully', 'Success');
          this.matDialogRef.close('success');
        } else {
          this._toastr.error(res.error, 'Error');
        }
      },
        error => {
          this._fuseSplashScreenService.hide();
          if (error.error.error) {
            this._toastr.error(error.error.error, 'Error');
          }
          else {
            this._toastr.error(error.error, 'Error');
          }
        }
      );
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control: any = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else {
        this.validateAllFormFields(control);
      }
    });
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("add-trigger-property");
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.width = (this.hostWidth + 1 + 'px');
        elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("addtriggerproperty-content").style.maxHeight = "65vh";
    document.getElementById("addtriggerproperty-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
}
maximizeDialog() {
    let elements: any = document.getElementsByClassName("add-trigger-property");
    for (var i = 0; i < elements.length; i++) {
        this.hostHeight = elements[i].clientHeight;
        this.hostWidth = elements[i].clientWidth;
        elements[i].style.width = ("100%");
        elements[i].style.height = ("100%");
    }
    document.getElementById("addtriggerproperty-content").style.maxHeight = "100vh";
    document.getElementById("addtriggerproperty-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
}

}
