import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector   : 'fuse-sure-dialog',
    templateUrl: './sure-dialog.component.html',
    styleUrls  : ['./sure-dialog.component.scss']
})
export class FuseSureDialogComponent
{
    public confirmMessage: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseSureDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseSureDialogComponent>
    )
    {
    }

}
