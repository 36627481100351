import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userDetails: any;
  constructor(
    private _router: Router,
    private _userService: UserService
  ) { }

  sendToken(token: any) {
    token.loggedInDate = new Date();
    localStorage.setItem("LoggedInUser", JSON.stringify(token))
  }
  sendAdminToken(token: any) {
    token.loggedInDate = new Date();
    localStorage.setItem("LoggedInAdmin", JSON.stringify(token))
  }
  sendCompanyToken(token: any) {
    token.loggedInDate = new Date();
    localStorage.setItem("LoggedInCompany", JSON.stringify(token))
  }
  sendParentCompanyToken(token: any) {
    token.loggedInDate = new Date();
    localStorage.setItem("LoggedInParentCompany", JSON.stringify(token))
  }
  sendUserData(data: string) {
    this._userService.setActiveUser(data);
  }
  setAdminBackToUrl(data: any) {
    window.localStorage.setItem('AdminBackToUrl', data);
  }
  getAdminBackToUrl() {
    return localStorage.getItem("AdminBackToUrl");
  }
  setCompanyBackToUrl(data: any) {
    window.localStorage.setItem('CompanyBackToUrl', data);
  }
  getCompanyBackToUrl() {
    return localStorage.getItem("CompanyBackToUrl");
  }
  setParentCompanyBackToUrl(data: any) {
    window.localStorage.setItem('ParentCompanyBackToUrl', data);
  }
  getParentCompanyBackToUrl() {
    return localStorage.getItem("ParentCompanyBackToUrl");
  }
  isAuthorised() {
    if (this.getToken()) {
      var token = JSON.parse(this.getToken());
      var loggedInDate = new Date(token.loggedInDate);
      loggedInDate.setSeconds(loggedInDate.getSeconds() + token.expires_in);
      if (loggedInDate < new Date()) return true;
      else return false;
    }
    return false;
  }

  getToken() {
    return localStorage.getItem("LoggedInUser")
  }
  getAdminToken() {
    return localStorage.getItem("LoggedInAdmin")
  }
  getCompanyToken() {
    return localStorage.getItem("LoggedInCompany")
  }
  getParentCompanyToken() {
    return localStorage.getItem("LoggedInParentCompany")
  }
  getUserData() {
    return JSON.parse(localStorage.getItem("LoggedInUserData"));
  }


  isLoggedIn() {
    return this.getToken() !== null;
  }
  isAdminLoggedIn() {
    return this.getAdminToken() !== null;
  }
  isParentCompanyLoggedIn() {
    return this.getParentCompanyToken() !== null;
  }
  isCompanyLoggedIn() {
    return this.getCompanyToken() !== null;
  }
  sendLanguageMenu(data: any) {
    localStorage.setItem("LanguageMenuList", data)
  }
  getLanguageMenu() {
    return JSON.parse(localStorage.getItem("LanguageMenuList"));
  }

  sendDefaultCountryCode(data: any) {
    localStorage.setItem("DefaultCountryCode", data)
  }
  sendDefaultLanguageCountryCode(data: any) {
    localStorage.setItem("DefaultLanguageCountryCode", data)
  }
  logout() {
    localStorage.clear();
    this._router.navigate(["login"]);
  }

}
