import { element } from 'protractor';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { UserService } from '../../../app/shared/user.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { views } from '../../../app/navigation/navigation';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    @Input()
    navigation: any;
    viewsNavigation: any[] = [];
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _userService: UserService
    ) {
        this.viewsNavigation = cloneDeep(views);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        this._userService.getActiveUser$().subscribe((user: any) => {
            this.viewsNavigation = cloneDeep(views);
            if(user && user.baseProviderId != 2) {
                this.viewsNavigation.forEach((element) => {
                    if(element.id == "triggers") {
                        element.children = element.children.filter(x => x.id != "ARtemplates");
                    }
                })
            }
            if (user) {
                let companyRoles = user.companyRoles ? user.companyRoles : [];
                let agentRoles = user.agentRoles ? user.agentRoles.pages : (user.companyRoles ? user.companyRoles : []);
                if (user.roles[0] == 'company') {
                    let pageViews: any[] = [];
                    this.viewsNavigation.forEach((pageObj: any, pageIndex: number) => {
                        if (pageObj.type === 'item' && this.isPageExist(companyRoles, pageObj.id)) {
                            pageViews.push(pageObj);
                        }
                        else if (pageObj.type === 'collapsable' && pageObj.id == "support") {
                            let group: any = {};
                            let groupChildren: any = [];
                            pageObj.children.forEach((groupObj: any, groupIndex: number) => {
                                if (this.isPageExist(companyRoles, groupObj.id)) {
                                    groupChildren.push(groupObj);
                                }
                            });
                            group.id = pageObj.id;
                            group.title = pageObj.title;
                            group.type = pageObj.type;
                            group.icon = pageObj.icon;
                            group.children = groupChildren;
                            group['children'].forEach((groupObj: any, groupIndex: number) => {
                                if (groupObj.type === 'collapsable' && groupObj.id === 'learning' && this.isPageExist(companyRoles, 'learning')) {
                                        let learningMenus = user.learningmenus.map(menu => {
                                            return {
                                                id: menu.id,
                                                title: menu.name,
                                                type: 'item',
                                                url: '/pages/learningcenter/' + menu.id
                                            }
                                        });
                                        groupObj.children = learningMenus;
                                    }
                            });
                            pageViews.push(group);
                        }
                        else if (pageObj.type === 'collapsable') {
                            let group: any = {};
                            let groupChildren: any = [];
                            pageObj.children.forEach((groupObj: any, groupIndex: number) => {
                                if (groupObj.type === 'item' && this.isPageExist(companyRoles, groupObj.id)) {
                                    groupChildren.push(groupObj);
                                }
                            });
                            if (groupChildren.length > 0) {
                                group.id = pageObj.id;
                                group.title = pageObj.title;
                                group.type = pageObj.type;
                                group.icon = pageObj.icon;
                                group.children = groupChildren;
                                pageViews.push(group);
                            }
                        }
                    });
                    this.navigation[0].children = pageViews;
                }
                else if (user.roles[0] == 'agent') {
                    let pageViews: any[] = [];
                    this.viewsNavigation.forEach((pageObj: any, pageIndex: number) => {
                        if (pageObj.type === 'item' && this.isPageExist(agentRoles, pageObj.id)) {
                            pageViews.push(pageObj);
                        }
                        else if (pageObj.type === 'collapsable' && pageObj.id == "support") {
                            let group: any = {};
                            let groupChildren: any = [];
                            pageObj.children.forEach((groupObj: any, groupIndex: number) => {
                                if (this.isPageExist(companyRoles, groupObj.id)) {
                                    groupChildren.push(groupObj);
                                }
                            });
                            group.id = pageObj.id;
                            group.title = pageObj.title;
                            group.type = pageObj.type;
                            group.icon = pageObj.icon;
                            group.children = groupChildren;
                            group['children'].forEach((groupObj: any, groupIndex: number) => {
                                if (groupObj.type === 'collapsable' && groupObj.id === 'learning' && this.isPageExist(agentRoles, 'learning')) {
                                        let learningMenus = user.learningmenus.map(menu => {
                                            return {
                                                id: menu.id,
                                                title: menu.name,
                                                type: 'item',
                                                url: '/pages/learningcenter/' + menu.id
                                            }
                                        });
                                        groupObj.children = learningMenus;
                                    }
                            });
                            pageViews.push(group);
                        }
                        else if (pageObj.type === 'collapsable') {
                            let group: any = {};
                            let groupChildren: any = [];
                            pageObj.children.forEach((groupObj: any, groupIndex: number) => {
                                if (groupObj.type === 'item' && this.isPageExist(agentRoles, groupObj.id)) {
                                    groupChildren.push(groupObj);
                                }
                            });
                            if (groupChildren.length > 0) {
                                group.id = pageObj.id;
                                group.title = pageObj.title;
                                group.type = pageObj.type;
                                group.icon = pageObj.icon;
                                group.children = groupChildren;
                                pageViews.push(group);
                            }
                        }
                    });
                    this.navigation[0].children = pageViews;
                }
                else {
                    let pageViews: any[] = [];
                    this.viewsNavigation.forEach((pageObj: any, pageIndex: number) => {
                        if (pageObj.type === 'item') {
                            pageViews.push(pageObj);
                        }
                        else if (pageObj.type === 'collapsable' && pageObj.id == "manage") {
                            let group: any = {};
                            group.id = pageObj.id;
                            group.title = pageObj.title;
                            group.type = pageObj.type;
                            group.icon = pageObj.icon;
                            group.children = pageObj.children;
                            pageViews.push(group);
                        }

                        else if (pageObj.type === 'collapsable' && pageObj.id == "support") {
                            let group: any = {};
                            group.id = pageObj.id;
                            group.title = pageObj.title;
                            group.type = pageObj.type;
                            group.icon = pageObj.icon;
                            group.children = pageObj.children;
                            group['children'].forEach((groupObj: any, groupIndex: number) => {
                                if (groupObj.type === 'collapsable' && groupObj.id === 'learning') {
                                        let learningMenus = user.learningmenus.map(menu => {
                                            return {
                                                id: menu.id,
                                                title: menu.name,
                                                type: 'item',
                                                url: '/pages/learningcenter/' + menu.id
                                            }
                                        });
                                        groupObj.children = learningMenus;
                                    }
                            });
                            pageViews.push(group);
                        }
                        else if (pageObj.type === 'collapsable') {
                            let group: any = {};
                            let groupChildren: any = [];
                            pageObj.children.forEach((groupObj: any, groupIndex: number) => {
                                if (groupObj.type === 'item' && groupObj.id != 'subaccounts' && groupObj.id != 'monthly-companyDetails') {
                                    groupChildren.push(groupObj);
                                }
                            });
                            if (groupChildren.length > 0) {
                                group.id = pageObj.id;
                                group.title = pageObj.title;
                                group.type = pageObj.type;
                                group.icon = pageObj.icon;
                                group.children = groupChildren;
                                pageViews.push(group);
                            }
                        }
                    });
                    this.navigation[0].children = pageViews;
                    //this.navigation[0].children = views;
                }
                this._changeDetectorRef.markForCheck();
            }
        });
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
        });  
    }
    isPageExist(arr: any, page: string) {
        return arr.some(function (el) {
            return el == page;
        });
    }
}
