import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MailService } from 'app/main/pages/mail/mail.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { RestService } from 'app/shared/rest.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ProvidersService } from 'app/main/pages/providers/providers.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { JourneyService } from 'app/main/pages/journeys/journey.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MediaService } from 'app/main/pages/manage/media.service';
import { BeePageBuilderService } from 'app/main/pages/bee-page-builder/bee-page-builder.service';

@Component({
    selector: 'add-learning-menu-dialog',
    templateUrl: './add-learning-menu.component.html',
    styleUrls: ['./add-learning-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddLearningMenuComponent {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    confirmDialogRef: any;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    action: string;
    menuForm: FormGroup;
    campaignUpdateForm: FormGroup;
    mediaUpdateForm: FormGroup;
    pagesUpdateForm: FormGroup;
    TemplatesUpdateForm: FormGroup;
    dialogTitle: string;
    saveButtom: string;
    private _unsubscribeAll: Subject<any>;
    public filterRank: FormControl = new FormControl();
    public filterCountry: FormControl = new FormControl();
    subEditMenu: any;
    eventTypes: any;
    eventTypeMT: any;
    isARTemplate: any;
    subMenuItems: any;
    allMediaMenu: any;
    dialogTitleName: any;
    constructor(
        public matDialogRef: MatDialogRef<AddLearningMenuComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _providersService: ProvidersService,
        private campaignsService : CampaignsService,
        private journeyService : JourneyService,
        private navigationService : FuseNavigationService,
        private _pageService: PageService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _mailService: MailService,
        public _matDialog: MatDialog,
        public _fileManagerService: MediaService,
        public _fuseNavigationService : FuseNavigationService,
        private _beePageBuilderService: BeePageBuilderService,
    ) {
        // Set the defaults
        this.action = _data.action;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        if (this.action === 'new') {
            this.dialogTitle = _data.dialogTitle;
            this.saveButtom = 'ADD';
        }
        else {
            this.dialogTitle = _data.dialogTitle;
            this.saveButtom = 'SAVE';
        }

        this.menuForm = this.createMenuForm();
        this.campaignUpdateForm = this.createCampaignForm();
        this.mediaUpdateForm = this.createMediaForm();
        this.pagesUpdateForm = this.createPagesForm();
        this.TemplatesUpdateForm = this.createTemplateForm();
    }
    ngOnInit(): void {
        this.MenuFolder();
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    createMenuForm(): FormGroup {
        return this._formBuilder.group({
            name: ['', [Validators.required]],
            id:['']
        });
    }

    createCampaignForm(): FormGroup {
        return this._formBuilder.group({
            subEventTypeId:['',[Validators.required]]
        });

    }

    createMediaForm(): FormGroup {
        return this._formBuilder.group({
            subEventTypeId:['',[Validators.required]]
        });

    }
    createPagesForm(): FormGroup {
        return this._formBuilder.group({
            subEventTypeId:['',[Validators.required]]
        });

    }
    createTemplateForm(): FormGroup{
        return this._formBuilder.group({
            subEventTypeId:['',[Validators.required]]
        });
    }



    MenuFolder(){
        if(this._data.pageType == 'pagesData' || this._data.pageName === 'page-Builder'){
            if (this._data && !this._data.showUpdateMenu) {
                this.menuForm.patchValue({
                    name: this._data.action !== 'new' ? this._data.editItem.name : '',
                });
            }
            if(this._data && ( this._data.pageName === 'page-Builder' )){
                this.dialogTitleName = "Folder"
            }else{
                this.dialogTitleName = "Menu"
            }
    
            if(this._data.showUpdateMenu === 'update pages'){
                this.fetchSubMenuItems()
            }
        }
        else if(this._data.pageType == 'manage-template-data' || this._data.pageName === 'manage-template'){
            if (this._data && !this._data.showUpdateMenu) {
                this.menuForm.patchValue({
                    name: this._data.action !== 'new' ? this._data.editItem.name : '',
                });
            }
            if(this._data && (this._data.pageName === 'manage-template')){
                this.dialogTitleName = "Folder"
            }else{
                this.dialogTitleName = "Menu"
            }
    
            if(this._data.showUpdateMenu === 'update manage templates'){
                this.fetchSubMenuItems()
            }
        }
        else if(this._data.pageType == 'master-template-data' || this._data.pageName === 'master-template'){
            if (this._data && !this._data.showUpdateMenu) {
                this.menuForm.patchValue({
                    name: this._data.action !== 'new' ? this._data.editItem.name : '',
                });
            }
            if(this._data && (this._data.pageName === 'master-template')){
                this.dialogTitleName = "Folder"
            }else{
                this.dialogTitleName = "Menu"
            }
    
            if(this._data.showUpdateMenu === 'update master templates'){
                this.fetchSubMenuItems()
            }
        }
        else{
            this.userDetails.companyRoles.forEach(element => {
                if(element == 'ARtemplates') {
                this.isARTemplate = element;
                return;
                }
            });  
            if (this._data && !this._data.showUpdateMenu) {
                this.menuForm.patchValue({
                    name: this._data.action !== 'new' ? this._data.editItem.name : '',
                });
            }
            if(this._data && (this._data.pageName === 'builder' || this._data.pageName === 'journey-report' || this._data.pageName === 'media' )){
                this.dialogTitleName = "Folder"
            }else{
                this.dialogTitleName = "Menu"
            }
    
            if(this._data.showUpdateMenu === 'update media'){
                this.fetchSubMenuItems()
            }
    
            if(this._data.pageType === 'builderCampaign'){
                this.fetchSubCamMenuItems(this._data.campaign.eventType);
            }
            if(this._data.pageType === 'journeyReport'){
                this.fetchSubJourMenuItems(this._data.campaign.campaignEventType);
            }
        }
    }


  fetchSubCamMenuItems(eventType) {
    this.campaignsService.getAllCampaignEventMenus(this.userDetails.companyId,this.userDetails.baseProviderId).subscribe((items:any) => {
        if (eventType === 'Manual') {
            this.subMenuItems = items.data.filter(x=>x.eventTypeId == 1);
        }else if(eventType === 'Trigger'){
            this.subMenuItems = items.data.filter(x=>x.eventTypeId == 3);
        }
        else {
        this.subMenuItems = [];
        }
    });
  }

  fetchSubJourMenuItems(eventType) {
    this.journeyService.getAllCampaignEventMenus(this.userDetails.companyId).subscribe((items:any) => {
        this.subMenuItems = items.data.filter(x=>x.campaignEventType == eventType);
    });
  }

  fetchSubMenuItems() {
    if(this._data.pageType == 'pagesData' || this._data.pageName === 'page-Builder'){
        this._fuseNavigationService.getAllPageBuilderMenu().subscribe((items:any) => {
            this.subMenuItems = items.data;
            });
    }
    else if(this._data.pageType == 'manage-template-data' || this._data.pageName === 'manage-template'){
        this._fuseNavigationService.getAllPagesTemplateMenu().subscribe((items:any) => {
            this.subMenuItems = items.data;
            });
    }
    else if(this._data.pageType == 'master-template-data' || this._data.pageName === 'master-template'){
        this._fuseNavigationService.getAllPagesTemplateMenu().subscribe((items:any) => {
            this.subMenuItems = items.data;
            });
    }
    else{
        this._fuseNavigationService.getAllMediaMenu().subscribe((items:any) => {
            this.subMenuItems = items.data;
            });
    }
}

    addEditMenu() {
        if(this._data.pageName === "builder"){
            if (this.menuForm.invalid) {
                this._pageService.validateAllFormFields(this.menuForm);
                return false;
            }
           
            var req: any = {
              "name": this.menuForm.value.name,
              "eventTypeId": this._data.addItem.id,
              "companyId": this.userDetails.companyId,
              "providerId": this.userDetails.baseProviderId,
              "createdAt": new Date()
            };
            if(this._data.action !== 'new'){
                req['id'] =this._data.editItem.id

            }
            this._fuseSplashScreenService.show();
            this.campaignsService.addEditMenu(req)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(this.menuForm);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error(error.error.error, 'Error');
                        console.log(error);
                    }
                ); 
        }
        else if(this._data.pageName === "journey-report"){
            console.log()
            if (this.menuForm.invalid) {
                this._pageService.validateAllFormFields(this.menuForm);
                return false;
            }
           
            var req: any = {
              "name": this.menuForm.value.name,
              "campaignEventType": this._data.addItem.id,
              "companyId": this.userDetails.companyId,
              "createdAt": new Date()
            };

            if(this._data.action !== 'new'){
                req['id'] =this._data.editItem.id

            }
            this._fuseSplashScreenService.show();
            this.journeyService.addEditMenu(req)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(this.menuForm);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error(error.error.error, 'Error');
                        console.log(error);
                    }
                ); 
        }
        
        else if(this._data.pageName === "media"){
            if (this.menuForm.invalid) {
                this._pageService.validateAllFormFields(this.menuForm);
                return false;
            }
           
            var req: any = {
              "name": this.menuForm.value.name,
              "companyId": this.userDetails.companyId,
              "createdAt": new Date()
            };

            if(this._data.action !== 'new'){
                req['id'] =this._data.editItem.id

            }``
            this._fuseSplashScreenService.show();
            this.navigationService.addEditMenu(req)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(this.menuForm);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error(error.error.error, 'Error');
                        console.log(error);
                    }
                ); 
        }

        // --------------------------
        else if(this._data.pageType === 'builderCampaign'){
            if (this.campaignUpdateForm.invalid) {
                this._pageService.validateAllFormFields(this.campaignUpdateForm);
                return false;
            }
              this._fuseSplashScreenService.show();
              this.campaignsService.updateCampaignEventMenus(this.campaignUpdateForm.value.subEventTypeId,this._data.campaign.id)
                  .subscribe((res: any) => {
                      this._fuseSplashScreenService.hide();
                      if (res.status == 200) {
                          this._toastr.success(res.data, 'Success');
                          this.matDialogRef.close(this.menuForm);
                      } else {
                          this._toastr.error(res.error, 'Error');
                      }
                  },
                      error => {
                          this._fuseSplashScreenService.hide();
                          this._toastr.error(error.error.error, 'Error');
                          console.log(error);
                      }
                  ); 
        }
        else if(this._data.pageType === 'journeyReport'){
            if (this.campaignUpdateForm.invalid) {
                this._pageService.validateAllFormFields(this.campaignUpdateForm);
                return false;
            }
              this._fuseSplashScreenService.show();
              this.journeyService.updateJourneyEventMenus(this.campaignUpdateForm.value.subEventTypeId,this._data.campaign.id)
                  .subscribe((res: any) => {
                      this._fuseSplashScreenService.hide();
                      if (res.status == 200) {
                          this._toastr.success(res.data, 'Success');
                          this.matDialogRef.close(this.menuForm);
                      } else {
                          this._toastr.error(res.error, 'Error');
                      }
                  },
                      error => {
                          this._fuseSplashScreenService.hide();
                          this._toastr.error(error.error.error, 'Error');
                          console.log(error);
                      }
                  ); 
        }
        else if(this._data.pageType === 'mediaData'){
            if (this.mediaUpdateForm.invalid) {
                this._pageService.validateAllFormFields(this.mediaUpdateForm);
                return false;
            }
              this._fuseSplashScreenService.show();
              this._fileManagerService.updateMediaEventMenus(this.mediaUpdateForm.value.subEventTypeId,this._data.campaign.id)
                  .subscribe((res: any) => {
                      this._fuseSplashScreenService.hide();
                      if (res.status == 200) {
                          this._toastr.success(res.data, 'Success');
                          this.matDialogRef.close(this.menuForm);
                      } else {
                          this._toastr.error(res.error, 'Error');
                      }
                  },
                      error => {
                          this._fuseSplashScreenService.hide();
                          this._toastr.error(error.error.error, 'Error');
                          console.log(error);
                      }
                  ); 
        }
        else if(this._data.pageName === 'page-Builder'){
            if (this.menuForm.invalid) {
                this._pageService.validateAllFormFields(this.menuForm);
                return false;
            }
           
            var req: any = {
              "name": this.menuForm.value.name,
            };

            if(this._data.action !== 'new'){
                req['id'] =this._data.editItem.id

            }
            this._fuseSplashScreenService.show();
            this.navigationService.addEditPages(req)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(this.menuForm);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error(error.error.error, 'Error');
                        console.log(error);
                    }
                ); 
        }
        else if(this._data.pageType === 'pagesData'){
            if (this.pagesUpdateForm.invalid) {
                this._pageService.validateAllFormFields(this.pagesUpdateForm);
                return false;
            }
              this._fuseSplashScreenService.show();
              this._beePageBuilderService.UpdatePagesMenuId(this.pagesUpdateForm.value.subEventTypeId,this._data.campaign.id)
                  .subscribe((res: any) => {
                      this._fuseSplashScreenService.hide();
                      if (res.status == 200) {
                          this._toastr.success(res.data, 'Success');
                          this.matDialogRef.close(this.menuForm);
                      } else {
                          this._toastr.error(res.error, 'Error');
                      }
                  },
                      error => {
                          this._fuseSplashScreenService.hide();
                          this._toastr.error(error.error.error, 'Error');
                          console.log(error);
                      }
                  ); 
        }
        else if(this._data.pageName === 'manage-template'){
            if (this.menuForm.invalid) {
                this._pageService.validateAllFormFields(this.menuForm);
                return false;
            }
           
            var req: any = {
              "name": this.menuForm.value.name,
            };

            if(this._data.action !== 'new'){
                req['id'] =this._data.editItem.id

            }
            this._fuseSplashScreenService.show();
            this.navigationService.addEditTemplateMenu(req)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(this.menuForm);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error(error.error.error, 'Error');
                        console.log(error);
                    }
                ); 
        }
        else if(this._data.pageType === 'manage-template-data'){
            if (this.TemplatesUpdateForm.invalid) {
                this._pageService.validateAllFormFields(this.TemplatesUpdateForm);
                return false;
            }
              this._fuseSplashScreenService.show();
              this._mailService.UpdateTemplateMenuId(this.TemplatesUpdateForm.value.subEventTypeId,this._data.campaign.id)
                  .subscribe((res: any) => {
                      this._fuseSplashScreenService.hide();
                      if (res.status == 200) {
                          this._toastr.success(res.data, 'Success');
                          this.matDialogRef.close(this.menuForm);
                      } else {
                          this._toastr.error(res.error, 'Error');
                      }
                  },
                      error => {
                          this._fuseSplashScreenService.hide();
                          this._toastr.error(error.error.error, 'Error');
                          console.log(error);
                      }
                  ); 
        }
        else if(this._data.pageName === 'master-template'){
            if (this.menuForm.invalid) {
                this._pageService.validateAllFormFields(this.menuForm);
                return false;
            }
           
            var req: any = {
              "name": this.menuForm.value.name,
            };

            if(this._data.action !== 'new'){
                req['id'] =this._data.editItem.id

            }
            this._fuseSplashScreenService.show();
            this.navigationService.addEditTemplateMenu(req)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(this.menuForm);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error(error.error.error, 'Error');
                        console.log(error);
                    }
                ); 
        }
        else if(this._data.pageType === 'master-template-data'){
            if (this.TemplatesUpdateForm.invalid) {
                this._pageService.validateAllFormFields(this.TemplatesUpdateForm);
                return false;
            }
              this._fuseSplashScreenService.show();
              this._mailService.UpdateTemplateMenuId(this.TemplatesUpdateForm.value.subEventTypeId,this._data.campaign.id)
                  .subscribe((res: any) => {
                      this._fuseSplashScreenService.hide();
                      if (res.status == 200) {
                          this._toastr.success(res.data, 'Success');
                          this.matDialogRef.close(this.menuForm);
                      } else {
                          this._toastr.error(res.error, 'Error');
                      }
                  },
                      error => {
                          this._fuseSplashScreenService.hide();
                          this._toastr.error(error.error.error, 'Error');
                          console.log(error);
                      }
                  ); 
        }
        else{
            if (this.menuForm.invalid) {
                this._pageService.validateAllFormFields(this.menuForm);
                return false;
            }
            var req: any = {
                ...((this._data && this._data.menu) ? { "id": this._data.menu.id } : {}),
                "name": this.menuForm.get('name').value,
                "providerId": this._data.providerId
            };
            this._fuseSplashScreenService.show();
            this._providersService.addEditMenu(req,this._data.pageName)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(this.menuForm);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error(error.error.error, 'Error');
                        console.log(error);
                    }
                );
        }
        
    }

    deleteMenu() {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete this menu?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._fuseSplashScreenService.show();
                this._providersService.DeleteMenu(this._data.menu.id, this._data.pageName)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success('Menu deleted successfully', 'Success');
                        this.matDialogRef.close(this._data.menu.id);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        console.log(error);
                        this._toastr.error(error.error.error, 'Error');
                    }
                );
            }
            this.confirmDialogRef = null;
        });
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("add-menu-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("addlearningmenu-content").style.maxHeight = "65vh";
        document.getElementById("addlearningmenu-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("add-menu-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("addlearningmenu-content").style.maxHeight = "100vh";
        document.getElementById("addlearningmenu-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

