import { Component, Inject, ViewEncapsulation, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import { MailService } from 'app/main/pages/mail/mail.service';
import { AccountType } from 'app/main/pages/accounts/account.model';
import { ToastrService } from 'ngx-toastr';
import { Contact } from 'app/main/pages/contacts/contact.model';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import * as _ from 'lodash';
import { fuseAnimations } from '@fuse/animations';
import { ChatService } from 'app/main/pages/chat/chat.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Component({
    selector: 'chat-details',
    templateUrl: './chat-details.component.html',
    styleUrls: ['./chat-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ChatDetailsDialogComponent {
    IsDialogMax:boolean =false;
    nextSendMessageType: any;
    private _unsubscribeAll: Subject<any>;
    replyInput: any;
    contact: any = {
        "avatar": "assets/images/avatars/profile.jpg",
    }
    recentChat: any[] = [];
    pageNumber: number = 1;
    throttle: number = 150;
    scrollUpDistance: number = 0.1;
    scrollDistance: number = 2;
    direction: string = 'down';
    lastElement: string = '';
    @ViewChild(FusePerfectScrollbarDirective, { static: false })
    directiveScroll: FusePerfectScrollbarDirective;
    @ViewChildren('replyInput')
    replyInputField;

    @ViewChild('replyForm', { static: false })
    private _replyForm: NgForm;
    hostHeight: any;
    hostWidth: any;
    constructor(
        public matDialogRef: MatDialogRef<ChatDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
        private _contactsService: ContactsService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _chatService: ChatService,
        private _mailService: MailService

    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.recentChat = [];
        this.getContactsById(this._data.contact.contactId);
        this._chatService.chatDetailsList = [];
        this._chatService.selectContact(this._data.contact);
        this._chatService.onChatDetailsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((chat: any) => {
                let temp_list = [];
                chat.forEach((obj: any) => {
                    obj.viewLength = 1000;
                    if (obj.messageId == this._data.contact.messageId) {
                        const found = _.find(this.recentChat, function (t) { return t.id == obj.id });
                        if (found == undefined || found == null) {
                            if (this.recentChat.length > 0) {
                                if (this.recentChat[0].id < obj.id) {
                                    this.recentChat.unshift(obj);
                                }
                                else {
                                    temp_list.push(obj);
                                }
                            }
                            else {
                                temp_list.push(obj);
                            }
                        }
                    }
                });
                Array.prototype.push.apply(this.recentChat, temp_list);
                if (this.recentChat.length > 0) {
                    this.nextSendMessageType = this._data.contact.unifiedTypeId;
                }
                this.readyToReply();
            });
    }
    ngAfterViewInit(): void {
        this.replyInput = this.replyInputField.first.nativeElement;
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    readyToReply(): void {
        setTimeout(() => {
            this.focusReplyInput();
            if (this.direction == 'down') {
                this.scrollToBottom();
            }
            else {
                if (!this.lastElement) {
                    this.scrollToTop(200);
                }
                else {
                    this.scrollToElement(this.lastElement);
                }

            }
        });
    }
    getContactsById(id): void {
        this._fuseSplashScreenService.show();
        this._contactsService.getContactsById(id)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this.contact = res.data;
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    console.log(error);
                }
            );
    }
    isFirstMessageOfGroup(message, i): boolean {
        return (i === 0 || this.recentChat[i - 1] && this.recentChat[i - 1].id !== message.id);
    }
    isLastMessageOfGroup(message, i): boolean {
        return (i === this.recentChat.length - 1 || this.recentChat[i + 1] && this.recentChat[i + 1].id !== message.id);
    }

    reply(event): void {
        event.preventDefault();

        if (!this._replyForm.form.value.message) {
            return;
        }

        this.sendMessage();
    }
    sendMessage() {
        let req: any = {
            "unifiedTypes": [this.nextSendMessageType],
            "recipients": [this._data.contact.contactId],
            "subject": this._data.contact.title,
            "mailText": this._replyForm.form.value.message,
            "messageText": this._replyForm.form.value.message,
            "IsReply": true,
            "ReplyMessageId": this._data.type == 'message' ? this._data.contact.messageDetailId : this._data.contact.id
        };
        this._fuseSplashScreenService.show();
        this._mailService.SendMessageToContacts(req)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this._chatService.chatDetailsPageNumber = 1;
                    this._chatService.getChatDetails(this._data.contact.messageId, false);
                    this._replyForm.reset();
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    console.log(error);
                    if (error.error.error) {
                        this._toastr.error(error.error.error, 'Error');
                    }
                    else {
                        this._toastr.error(error.error, 'Error');
                    }
                }
            );
    }
    onScrollUp(messageId) {
        this.direction = 'up';
        //if (this.recentChat.length < this._chatService.chatDetailsTotalCount) {
            let last = this.recentChat[this.recentChat.length - 1];
            this.lastElement = '.message-detail-id-' + last.id;
            console.log(this.lastElement);
            this.pageNumber += 1;
            this._chatService.chatDetailsPageNumber = this.pageNumber;
            this._chatService.getChatDetails(messageId, false);
        //}
    }
    onScrollDown(ev) {
        this.direction = 'down';
    }

    scrollToBottom(speed?: number): void {
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();

            setTimeout(() => {
                this.directiveScroll.scrollToBottom(0, speed);
            });
        }
    }
    scrollToTop(offset?: number, speed?: number): void {
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();

            setTimeout(() => {
                this.directiveScroll.scrollToTop(offset || 0, speed);
            });
        }
    }
    scrollToElement(qs: string, offset?: number, speed?: number): void {
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();

            setTimeout(() => {
                this.directiveScroll.scrollToElement(qs, offset || 0, speed);
            });
        }
    }

    focusReplyInput(): void {
        setTimeout(() => {
            this.replyInput.focus();
        });
    }
    ChatRefresh() {
        this._chatService.selectContact(this._data.contact);
    }
    goToLink(url: string) {
        window.open(url, "_blank");
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("chat-details-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width =  (this.hostWidth+1+'px');
            elements[i].style.height =  (this.hostHeight+1+'px');
        }
        document.getElementById("chatdetail-content").style.maxHeight = "60vh";
        document.getElementById("chatdetail-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("chat-details-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("chatdetail-content").style.maxHeight = "75vh";
        document.getElementById("chatdetail-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
     }

}

