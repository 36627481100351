import { Component, Inject, ViewEncapsulation, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';

@Component({
    selector: 'common-name',
    templateUrl: './common-name.component.html',
    styleUrls: ['./common-name.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CommonNameDialogComponent {
    commonForm: FormGroup;
    accTyped:any;
    constructor(
        public matDialogRef: MatDialogRef<CommonNameDialogComponent>, 
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _pageService: PageService,
        private chRef: ChangeDetectorRef
    ) {
      if(_data.accTyped){
        this.accTyped=_data.accTyped;
      }
      setTimeout(() => {
        this.dirtyComponent();
      }, 1)
    }

    ngOnInit(): void {
        this.commonForm = this.createCommonForm();  
    }
    createCommonForm(): FormGroup {
        return new FormGroup({
          name: new FormControl('', [Validators.required]),
          mediaUrl: new FormControl('')
        });
      }
      uploadImage(ev) {
        var file = ev.target.files[0];
        if (file.type.includes('image')) {
          this._pageService.getMediaUrl(file, (mediaUrl: any) => {
              if (mediaUrl) {
                  this.commonForm.get('mediaUrl').setValue(mediaUrl);
              }
          });
      }
    }

      saveName() {
        if (this.commonForm.invalid) {
            return;
        }
          if(this.commonForm){
            this.matDialogRef.close(this.commonForm);
          }
        
      }
      dirtyComponent = () => {
        document.getElementById('temp-label').click();
        this.chRef.markForCheck();
      }
}

