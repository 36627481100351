import { Injectable } from '@angular/core';
import { FuseUtils } from '@fuse/utils';
import { RestService } from '../../../shared/rest.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from 'app/main/pages/pages.service';

@Injectable({
    providedIn: 'root'
})
export class ChatPanelService {
    userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
    isAgentRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
    onChatLogsChanged: BehaviorSubject<any>;
    onRefreshChatLogsChanged: Subject<any>;
    onChatSearchChanged: Subject<any>;
    onMarkAsReadChanged: Subject<any>;
    onChatDetailsChanged: BehaviorSubject<any>;
    chats: any[] = [];
    chatLogsList: any[] = [];
    chatLogsPageNumber: number = 1;
    chatLogsPageSize: number = 100;
    chatLogsTotalCount: number = 0;
    searchTextForChat: string = '';

    chatDetailsList: any[] = [];
    chatDetailsPageNumber: number = 1;
    chatDetailsPageSize: number = 50;
    chatDetailsTotalCount: number = 0;
    constructor(
        private _restService: RestService,
        private _authService: AuthService,
        private _pageService: PageService
    ) {
        this.onRefreshChatLogsChanged = new Subject();
        this.onChatLogsChanged = new BehaviorSubject([]);
        this.onChatSearchChanged = new Subject();
        this.onMarkAsReadChanged = new Subject();
        this.onChatDetailsChanged = new BehaviorSubject([]);
    }
    init() {
        this.chats = [];
        this.chatLogsList = [];
        this.chatLogsPageNumber = 1;
        this.chatLogsPageSize = 100;
        this.chatLogsTotalCount = 0;
        this.searchTextForChat = '';
        this.chatDetailsList = [];
        this.chatDetailsPageNumber = 1;
        this.chatDetailsPageSize = 50;
        this.chatDetailsTotalCount = 0;
        this.userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
        this.isAgentRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
        // this.getChatlogs();
        this._pageService.getDomainType();
    }
    getChatlogs(): void {
        var chatLogsRequest = {
            "pageNumber": this.chatLogsPageNumber,
            "pageSize": this.chatLogsPageSize,
            "searchName": this.searchTextForChat,
            "isAgent": this._pageService.isAgentView != null ?  this._pageService.isAgentView : this.isAgentRole
        };
        this._restService.Post('/Inbox/GetChatlogs', chatLogsRequest)
            .subscribe((response: any) => {
                if (response.status == 200) {
                    this.chatLogsList = response.data;
                    this.onChatLogsChanged.next(this.chatLogsList);
                }
            },
                error => {
                    console.log(error);
                });
    }

    getChatDetails(id, isMarkAsRead): void {
        if (id) {
            var GetMessageDetailssrequest = {
                "messageId": id,
                "pageNumber": this.chatDetailsPageNumber,
                "unifiedTypeId": 0,
                "pageSize": this.chatDetailsPageSize
            };
            this._restService.Post('/Inbox/GetMessageDetailsNew', GetMessageDetailssrequest)
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.chatDetailsList = response.data;
                        //this.chatDetailsTotalCount = response.data.totalCount;
                        if (isMarkAsRead) {
                            this.markMessagesAsRead(id);
                        }
                        this.onChatDetailsChanged.next(this.chatDetailsList);
                    }
                },
                    error => {
                        console.log(error);
                    });
        }

    }

    markMessagesAsRead(messageId): void {
        this._restService.Get('/Inbox/MarkReadMessages/' + messageId)
            .subscribe((response: any) => {
                if (response.status == 200) {
                    this.onMarkAsReadChanged.next(messageId);
                    // this.getChatlogs();
                }
            },
                error => {
                    console.log(error);
                });
    }
}


