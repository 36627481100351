import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'brief' })
export class BriefPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} args
     * @returns {string}
     */
    transform(value: string, start: number, end: number): string {
        if (value.length > end) {
            return value ? String(value).slice(start, end).trim() + '...' : '';
        }
        else {
            return value;
        }
    }
}
