import { Component, Inject, ViewEncapsulation, ViewChild, Pipe } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'view-documents',
    templateUrl: './view-documents.component.html',
    styleUrls: ['./view-documents.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ViewDocumetsDialogComponent {
    IsDialogMax:boolean =false;
    dialogTitle: string;
    safeURL: any;
    hostWidth: number;
    hostHeight: number;
    constructor(
        public matDialogRef: MatDialogRef<ViewDocumetsDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
        private _sanitizer: DomSanitizer,
        private _toastr: ToastrService
    ) {
        this.dialogTitle = _data.dialogTitle;
        if (this._data.fileURL.includes('youtu.be') || this._data.fileURL.includes('youtube')) {
            let videoId = this.getYoutubeUrl(this._data.fileURL);
            if (videoId) {
                this._data.fileURL = 'https://www.youtube.com/embed/' + videoId;
            }
        }
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(_data.fileURL);
    }
    ngOnInit(): void {
    }

    getYoutubeUrl(url) {
        var videoid = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
        if (videoid != null) {
            // console.log("video id = ", videoid[1]);
            return videoid[1];
        } else {
            this._toastr.error('Invalid Url', 'Error');
            return false;
        }
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("view-documents");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width =  (this.hostWidth+1+'px');
            elements[i].style.height =  (this.hostHeight+1+'px');
        }
        document.getElementById("viewdoc-content").style.maxHeight = "65vh";
        document.getElementById("viewdoc-wrapper").style.maxHeight = "85vh";
        document.getElementById("iframe").style.minHeight = "60vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("view-documents");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("viewdoc-content").style.maxHeight = "100vh";
        document.getElementById("viewdoc-wrapper").style.maxHeight = "100vh";
        document.getElementById("iframe").style.minHeight = "80vh";
        this.IsDialogMax = !this.IsDialogMax;
     }
}

