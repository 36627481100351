import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AddUnifyVideosComponent } from '../dialogs/add-unify-videos/add-unify-videos.component';
import { ProvidersService } from 'app/main/pages/providers/providers.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { ViewDocumetsDialogComponent } from '../dialogs/view-documents/view-documents.component';

@Component({
  selector: 'app-unify-videos-list',
  templateUrl: './unify-videos-list.component.html',
  styleUrls: ['./unify-videos-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class UnifyVideosListComponent implements OnInit {
  menuId: any = 0;
  @Input() categoryId: any;
  @Input() onCategoryIdChanges: Observable<any>;
  confirmDialogRef: any;
  providerId: number;
  dialogRef: any;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['avatar', 'name', 'category', 'fileType', 'actions'];
  TotalCount: any = 0;
  pageNumber = 1;
  pageSize = 100;
  List: any[] = [];
  currentPage: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  sortBy: string = "name";
  sortDirection: string = "asc";
  private _unsubscribeAll: Subject<any>;
  constructor(
    private _activeRoute: ActivatedRoute,
    private _matDialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _providersService: ProvidersService,
    private _router: Router
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._activeRoute.params.subscribe((routeParams: any) => {
      this.providerId = parseInt(routeParams.id);
      this.menuId = parseInt(routeParams.menuId);
    });
    if (this._router.url.includes('masterLearningCenter')) {
     this.currentPage = "masterLearningCenter";
    }else if(this._router.url.includes('releaseNotes')){
      this.currentPage = "releaseNotes";
    }else if(this._router.url.includes('providerdetails')){
      this.currentPage = "providerdetails";
    }
    if (this.categoryId) {
      this.getVideos();
    }
    this.onCategoryIdChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((categoryId: any) => {
        this._providersService.isMasterType = false;
        this.categoryId = categoryId;
        this.getVideos();
      });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  onPageChange(event): void {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getVideos();
  }
  sortData(event) {
    this.sortBy = event.active;
    this.sortDirection = event.direction;
    this.getVideos();
  }
  getVideos() {
    let req = {
      "categoryId": this.categoryId,
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "sortBy": this.sortBy,
      "sortDirection": this.sortDirection
    }
    this._fuseSplashScreenService.show();
    this._providersService.GetFiles(req, this.currentPage)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          this.List = res.data.report;
            this.List.forEach((element)=> {
            this._providersService.itemArray.forEach((itemArrayData) => {
                if(element.categoryId == itemArrayData.id) {
                  element.type = "master"
                  this._providersService.isMasterType = true;
                }
              });
          })
          this.TotalCount = res.data.totalCount;
          this.dataSource = new MatTableDataSource(this.List);
        }
        else {
          this.List = [];
          this.TotalCount = 0;
          this.dataSource = new MatTableDataSource(this.List);
        }
      },
        error => {
          this.List = [];
          this.TotalCount = 0;
          this.dataSource = new MatTableDataSource(this.List);
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  addVideo() {
    this.dialogRef = this._matDialog.open(AddUnifyVideosComponent, {
      panelClass: 'add-video',
      disableClose: false,
      data: {
        action: 'add',
        categoryId: this.categoryId,
        pageName: this.currentPage
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        this.getVideos();
      });
  }
  editVideo(video) {
    this.dialogRef = this._matDialog.open(AddUnifyVideosComponent, {
      panelClass: 'add-video',
      disableClose: false,
      data: {
        action: 'edit',
        categoryId: this.categoryId,
        video: video,
        pageName: this.currentPage
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        this.getVideos();
      });
  }
  deleteVideo(id): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._fuseSplashScreenService.show();
        this._providersService.DeleteVideo(id, this.currentPage)
          .subscribe((res: any) => {
            this._fuseSplashScreenService.hide();
            if (res.status == 200) {
              this._toastr.success('Video deleted successfully', 'Success');
              this.getVideos();
            } else {
              this._toastr.error(res.error, 'Error');
            }
          },
            error => {
              this._fuseSplashScreenService.hide();
              console.log(error);
              this._toastr.error(error.error.error, 'Error');
            }
          );
      }
      this.confirmDialogRef = null;
    });
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }

  view(fileURL) {
    this.dialogRef = this._matDialog.open(ViewDocumetsDialogComponent, {
        panelClass: 'view-documents',
        disableClose: true,
        data: {
          dialogTitle: 'View Video/Pdf',
          fileURL: fileURL
        }
    });

    this.dialogRef.afterClosed()
        .subscribe((response: any) => {
            if (!response) {
                return;
            }
        });
}
}
