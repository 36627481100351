import { Component, Inject, ViewEncapsulation, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MailService } from 'app/main/pages/mail/mail.service';
import { AccountType } from 'app/main/pages/accounts/account.model';
import { RestService } from '../../../../../shared/rest.service';
import { ToastrService } from 'ngx-toastr';
import { CommonNameDialogComponent } from 'app/shared/common/dialogs/common-name/common-name.component';
import { addTinyUrlDialogComponent } from 'app/shared/common/dialogs/add-tiny-url/add-tiny-url.component';
import { emailTemplateCatalogDialogComponent } from 'app/shared/common/dialogs/email-template-catalog/email-template-catalog.component';
import { FuseSplashScreenService } from '../../../../../../@fuse/services/splash-screen.service';
import { PageService } from '../../../pages.service';
import { ProvidersService } from 'app/main/pages/providers/providers.service';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import * as _ from 'lodash';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { HtmlToPlaintextPipe } from '@fuse/pipes/htmlToPlaintext.pipe';
import Bee from 'bee-plugin'
import { JsonPipe } from '@angular/common';
import { TestContactDialogComponent } from 'app/shared/common/dialogs/test-contact/test-contact.component';
import { ContactsFiltersComponent } from 'app/main/pages/contacts/dialogs/contacts-filters/contacts-filters.component';
import { ContactListFilterComponent } from 'app/main/pages/contacts/dialogs/contact-list-filter/contact-list-filter.component';
import { AddGroupComponent } from 'app/main/pages/contacts/dialogs/add-group/add-group.component';
import { ViewBrowserNameDialogComponent } from 'app/shared/common/view-browser-name-dialog/view-browser-name.component';
import { SaveRowComponent } from 'app/shared/common/dialogs/save-row/save-row.component';
import { AddMediaComponent } from 'app/shared/common/dialogs/add-media/add-media.component';
import { AddMergeFieldComponent } from 'app/shared/common/dialogs/add-merge-field/add-merge-field.component';
import { AddLinkTrackingComponent } from 'app/shared/common/dialogs/add-link-tracking/add-link-tracking.component';
import { ViewBrowserListComponent } from 'app/shared/common/dialogs/view-browser-list/view-browser-list.component';
import { CompileSummaryKind } from '@angular/compiler';
import { AddPageLinkComponent } from 'app/shared/common/dialogs/add-page-link/add-page-link.component';

@Component({
    selector: 'mail-compose',
    templateUrl: './compose.component.html',
    styleUrls: ['./compose.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [HtmlToPlaintextPipe]
})

export class MailComposeDialogComponent implements OnInit, AfterViewInit {
    groupContactsCount: number = 0;
    showGroupContactsCountLoader: boolean = false;
    showfilterContactsCountLoader: boolean = false;
    afterEditorInit: boolean = false;
    contactsLabel: string = "";
    showExtraToFields: boolean;
    composeForm: FormGroup;
    accountTypes: AccountType[];
    isemail: boolean;
    dialogRef: any;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    contacts: any[] = [];
    groupsList: any[] = [];
    isShowAdvance: boolean = false;
    IsDialogMax: boolean = false;
    IsIFrameMax: boolean = false;
    allGroupsList: any[] = [];
    replyContact: boolean = false;
    checkboxes: {};
    selectedContacts: any[] = [];
    hasSelectedAll: boolean;
    contactsFilterCtrl: FormControl = new FormControl();  
    contactsFilterAssociateIdCtrl: FormControl = new FormControl();
    groupFilterCtrl: FormControl = new FormControl();
    mergeField: FormControl = new FormControl();
    pageSize: number = 100;
    currentPageNumber: number = 1;
    scrollStart: boolean = true;
    emailEditorConfig: any;
    notificationTypeName: any;
    notificationId:any;
    allNotificationType: any;
    gsm7 = ['@', '£', '$', '¥', 'è', 'é', 'ù', 'ì', 'ò', 'Ç', 'Ø', 'ø', 'Å', 'å', 'Δ', '_', 'Φ', 'Γ', 'Λ', 'Ω', 'Π', 'Ψ', 'Σ', 'Θ', 'Ξ', '^', '{', '}', '\\', '[', '~', ']', '|', '€', 'Æ', 'æ', 'ß', 'É', '!', '"', '#', '¤', '%', '&', '\'', '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', '¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ä', 'Ö', 'Ñ', 'Ü', '§', '¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'ä', 'ö', 'ñ', 'ü', 'à', ' ']
    maxAllowed: number;
    maxAllowedInt: number;
    messageLengthObservable: Subscription;
    intMessageLengthObservable: Subscription;
    @ViewChild('composeEmailEditor', null) ckeditor: any;
    @ViewChild('smsMMSActive', null) smsMMSActive: any;
    tinymceEditor: any;
    allTemplates: any[] = [];
    emailTemplates: any[] = [];
    beeEmailTemplates: any[] = [];
    beeNotificationTemplates: any[] = [];
    smsTemplates: any[] = [];
    shortCodeTemplates: any[] = [];
    whatsappTemplates: any[] = [];
    shoutTemplates: any[] = [];
    fbTemplates: any[] = [];
    lineTemplates: any[] = [];
    telegramTemplates: any[] = [];
    mmsTemplates: any[] = [];
    internationalSMSMMSTemplates: any[] = [];
    notificationTemplates: any[] = [];
    verbNotificationTemplates: any[] = []
    emailLinks: any[] = [];
    beeLinks: any[] = [];
    notificationBeeLinks: any[] = [];
    smsLinks: any[] = [];
    verbNotificationLinks: any[] = [];
    shoutLinks: any[] = [];
    internationalSMSMMSLinks: any[] = [];
    shortCodeLinks: any[] = [];
    whatsappLinks: any[] = [];
    lineLinks: any[] = [];
    fbLinks: any[] = [];
    telegramLinks: any[] = [];
    notificationLinks: any[] = [];
    allReportsList: any[] = [];
    reportsList: any[] = [];
    selectType:any;
    reportFilterCtrl: FormControl = new FormControl();
    mediaList: any[] = [];
    nowTechNotificationLinks: any[] =[];
    nowTechNotificationTemplates: any[] = [];
    mediaFilterCtrl: FormControl = new FormControl();
    DomainTypeEmail: any = JSON.parse(localStorage.getItem("domainTypeEmail"));
    DomainTypeSMS: any = JSON.parse(localStorage.getItem("domainTypeSMS"));
    DomainTypeInternationalSMS: any = JSON.parse(localStorage.getItem("domainTypeInternationalSMS"));
    appliedFilters: any;
    isSMSMessageTop: boolean = true;
    isInternationalSMSMMSMessageTop: boolean = true;
    isWhatsAppMessageTop: boolean = true;
    isLineMessageTop: boolean = true;
    isTelegramMessageTop: boolean = true;
    isShortCodeMessageTop: boolean = true;
    isShoutMessageTop: boolean = true;
    emailData: any = '';
    private _unsubscribeAll: Subject<any>;
    flagPreview: boolean = false;
    previewData: any;
    doc: any;
    docPreview: any;
    viewBrowserLink: any = '';
    contactChecked: boolean = false;
    currenMessageType: any = null;
    rowData: any[] = [];
    config: any = {
        height: 350,
        menubar: true,
        plugins: [
            'advlist autolink lists link charmap print hr preview',
            'searchreplace wordcount visualblocks visualchars advcode',
            'insertdatetime nonbreaking save table directionality',
            'powerpaste textpattern help',
            'fullpage tinymcespellchecker a11ychecker fullscreen image'
        ],
        toolbar1: 'undo redo | print custpreview | cut copy paste insert link | fontselect styleselect fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | spellchecker | code | a11ycheck | fullscreen | image',
        relative_urls: false,
        remove_script_host: false,
        powerpaste_allow_local_images: true,
        powerpaste_word_import: 'prompt',
        powerpaste_html_import: 'prompt',
        spellchecker_language: 'en',
        spellchecker_dialog: true,
        menu: {
            file: { title: 'File', items: 'print' },
            edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
            view: { title: 'View', items: 'code | visualaid visualchars visualblocks | preview fullscreen' },
            insert: { title: 'Insert', items: 'insertImage link | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats blockformats fontformats fontsizes align | removeformat' },
            table: { title: 'Table', items: 'inserttable tableprops deletetable row column cell' }
        },
        setup: (editor) => {
            this.tinymceEditor = editor;
        },
        convert_urls: false,
        image_advtab: false,
        image_list: [],
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: (cb, value, meta) => {
            var self = this;
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = function (event: any) {
                var file = event.target.files[0];
                if (file.type.includes('image')) {
                    self._pageService.getMediaUrl(file, (mediaUrl: any) => {
                        if (mediaUrl) {
                            cb(mediaUrl, { title: file.name });
                        }
                    });
                }
            };
            input.click();
        },
        content_style: "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Lato', sans-serif; }",
        font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats"
    };
    isSelectAllContacts: boolean = false;
    beeEditor: any = new Bee();
    notificationBeeEditor: any = new Bee();
    beeToken: any = JSON.parse(localStorage.getItem("BeeToken"));
    notificationbeeToken: any = JSON.parse(localStorage.getItem("NotificatioBeeToken"));
    hostHeight: any;
    hostWidth: any;
    isShowBeeTemplates: boolean;
    isSaveBeeTemplates: boolean;
    filterContactsCount: any;
    constructor(
        public matDialogRef: MatDialogRef<MailComposeDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
        private _mailService: MailService,
        public _contactsService: ContactsService,
        public _pageService: PageService,
        private _restService: RestService,
        public _ProvidersService: ProvidersService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        private _campaignsService: CampaignsService,
        private _htmlToPlaintextPipe: HtmlToPlaintextPipe
    ) {
        this._pageService.OnSelectViewBrowserUrlChange = new BehaviorSubject<any>(null);
        this._pageService.SlectionMergeFieldChanges = new BehaviorSubject<any>(false);
        this._pageService.SlectMediaChange = new BehaviorSubject<any>(false);
        this.maxAllowed = 160;
        this.maxAllowedInt = 160;
        this._contactsService.appledFilters = new BehaviorSubject<any>(null);
        this.isShowBeeTemplates = this._pageService.checkPermissions('show-bee-templates');
        this.isSaveBeeTemplates = this._pageService.checkPermissions('save-bee-templates');
        // Set the defaults
        this.composeForm = this.createComposeForm();
        this.showExtraToFields = false;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        if(_data && _data.groupId != undefined && _data.groupId != null && _data.groupId != 0) {
            this.composeForm.patchValue({
                groups: this._data.groupId
            });
        }
    }
    ngOnInit(): void {
        if(this._data && this._data.action == "edit") {
            this.flagPreview = true;
        }
        if (this.beeToken) {
            this.beeEditor = new Bee(this.beeToken);
        }
        else {
            this.beeEditor.getToken('89c6b4fd-09fb-4bd4-9deb-f22065e3a5b5', 'xl0SgQ9nwQeGw4LX9P7E5tOclxfhbE3gmJkkZ9YKd0yXJPVp0QzP').then((token) => {
                this.beeEditor = new Bee(token);
                localStorage.setItem("BeeToken", JSON.stringify(token));
            });
        }
        if(this.notificationbeeToken) {
            this.notificationBeeEditor = new Bee(this.notificationbeeToken);
        }else {
            this.notificationBeeEditor.getToken('89c6b4fd-09fb-4bd4-9deb-f22065e3a5b5', 'xl0SgQ9nwQeGw4LX9P7E5tOclxfhbE3gmJkkZ9YKd0yXJPVp0QzP').then((token) => {
                this.notificationBeeEditor = new Bee(token);
                localStorage.setItem("NotificatioBeeToken", JSON.stringify(token));
            });
        }
        this.composeForm.get('groups').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((event) => {
                this.showGroupContactsCountLoader = true;
                this._pageService.getGroupContactsCount(event).subscribe((res) => {
                    this.showGroupContactsCountLoader = false;
                    this.groupContactsCount = res;
                });
        });
        this.composeForm.get('isBeeEditor').valueChanges.subscribe((event) => {
                if(this._data && this._data.action == "new") {
                    this.composeForm.patchValue({
                        emailEditor: "",
                        MessageJson: this._pageService.defaultTemplate
                    });
                    this.emailLinks = [];
                    this.beeLinks = [];
                }
                if (event) {
                    this.getBeeRowList();
                    this.initBeeEditor();
                    this.isShowAdvance = true;
                } else {
                    this.isShowAdvance = false;
                }
            });
        this.composeForm.get('isNotificationBeeEditor').valueChanges.subscribe((event) => {
            this.composeForm.patchValue({
                notificationMessage: "",
                NotificationJson: this._pageService.defaultTemplate
            });
            this.notificationLinks = [];
            this.notificationBeeLinks = [];
            if (event) {
                this.initNotificationBeeEditor();
                this.isShowAdvance = true;
            } else {
                this.isShowAdvance = false;
            }
        });
        this.getReports();
        this.reportFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll),debounceTime(1000),distinctUntilChanged()).subscribe(() => {
                this.filterReport();
        });
        this._pageService.getMediaFiles('');
        this._pageService.onMediaFilesChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(list => {
            this.mediaList = list;
        });
        this._pageService.SlectionMergeFieldChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
            if(val) {
                this.onMergeField(this.mergeField.value);
            }
        });
        this._pageService.OnSelectViewBrowserUrlChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
            if(val != null) {
                this.onAddTinyUrl(val);
            }
        });
        this._pageService.SlectMediaChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {            
            if(val) {
                this.onSlectMediaChange();
            }
        });
        this.getTemplates();
        this.emailEditorConfig = {
            title: false
        };
        if (this._data && this._data.contact) {
            this.contacts.push(this._data.contact)
        }
        else if (this._data && this._data.contactItems) {
            Array.prototype.push.apply(this.contacts, this._data.contactItems);
        }
        else {
            this.contacts = [];
        }
        this._pageService.contacts = [];
        this.currentPageNumber = 1;
        this._pageService.getContactsToCompose(this._pageService.contactsBysearchText, this._pageService.contactByAssociatedId, this.currentPageNumber, this.pageSize, this._pageService.contactByselectedIds);
        this._pageService.stopNextCallingforContacts = false;
        this.contactsFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll),debounceTime(2000),distinctUntilChanged()).subscribe(() => {
                this.filterContacts();
            });
        this.contactsFilterAssociateIdCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll),debounceTime(2000),distinctUntilChanged())
            .subscribe(() => {
                this.filterContacts();
            });    
        this.groupFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll),debounceTime(1000),distinctUntilChanged()).subscribe(() => {
                this.filterGroup();
            });
        this._pageService.onAccountTypeByUserChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(types => {
                if (types) {
                    this.accountTypes = this._pageService.accountTypesByUser;
                }
                else {
                    this.accountTypes = [];
                }
            });

        this._pageService.onContactsChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(contacts => {
                let temp_list = [];
                this._pageService.contacts.forEach((obj: any) => {
                    const found = _.find(this.contacts, function (t) { return t.id == obj.id });
                    if (found == undefined || found == null) {
                        temp_list.push(obj);
                    }
                });
                Array.prototype.push.apply(this.contacts, temp_list);
                if (!this._pageService.stopNextCallingforContacts) {
                    this.scrollStart = true;
                }
                else {
                    this.scrollStart = false;
                }
                if (this.contacts.length == 0) {
                    this.contactsLabel = "No contact found";
                }
                else {
                    this.contactsLabel = "";
                }
                this.checkboxes = {};
                contacts.map(contact => {
                    this.checkboxes[contact.id] = false;
                });
                this.getDataToEdit();
            });
        this.getAllgroups();
        this._contactsService.onSelectedContactsChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(selectedContacts => {
                for (const id in this.checkboxes) {
                    if (!this.checkboxes.hasOwnProperty(id)) {
                        continue;
                    }

                    this.checkboxes[id] = selectedContacts.includes(parseInt(id));
                }
                this.selectedContacts = selectedContacts;
                this.composeForm.patchValue({
                    contactitems: this.selectedContacts
                });
                if (this.selectedContacts.length == this.contacts.length) {
                    this.hasSelectedAll = true;
                }
                else {
                    this.hasSelectedAll = false;
                }
            });   
        this.composeForm.get('accounttypes').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((types) => {
                if((!this.composeForm.get('newIsBeeEditor').value && types.includes(1)) || (!this.composeForm.get('newIsBeeEditor').value && types.includes(21))) {
                    this.composeForm.patchValue({
                        isBeeEditor: true
                    });
                }else if((this.composeForm.get('newIsBeeEditor').value && types.includes(1)) || (this.composeForm.get('newIsBeeEditor').value && types.includes(21))) {
                    this.composeForm.get('isBeeEditor').setValue(false);
                }
                if(!this.composeForm.get('newIsNotificationBeeEditor').value && types.includes(9)) {
                    this.composeForm.get('isNotificationBeeEditor').setValue(true);
                }else if(this.composeForm.get('newIsNotificationBeeEditor').value && types.includes(9)) {
                    this.composeForm.get('isNotificationBeeEditor').setValue(false);
                }
                this.accountValidations(types);
            });
        this.composeForm.get('emailEditor').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((types) => {
          var FormData = this.composeForm.getRawValue();
          var emailDataHtml =  FormData.emailEditor;
          this.doc = new DOMParser().parseFromString(emailDataHtml, "text/html");
          if(this.doc && this.doc.getElementById('previewEmail') && this.emailData != '') {
            this.emailData = this.emailData;
          }else if(this.emailData == ''){
            var x = this.doc.body.getElementsByTagName("style");
            for (var i = x.length - 1; i >= 0; i--) {
              x[i].parentElement.removeChild(x[i]);
            }
            if(this.doc.body && this.doc.body.firstElementChild) {
              if(this.doc.body.firstElementChild.tagName == 'STYLE') {
                  this.doc.body.firstElementChild.remove();
                  if(this.doc.body.textContent) {
                    let data = this.doc.body.textContent;
                    this.emailData = this.emailData != '' ? this.emailData : data.toString().replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g," ").slice(0, 200);
                }
              }else {
                if(this.doc.body.textContent) {
                  let data = this.doc.body.textContent;
                  this.emailData = this.emailData != '' ? this.emailData : data.toString().replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g," ").slice(0, 200);
              }
              }
            }
          }else if (this.emailData != '') {
              var x = this.doc.body.getElementsByTagName("style");
              for (var i = x.length - 1; i >= 0; i--) {
                  x[i].parentElement.removeChild(x[i]);
              }
              if (this.doc.body && this.doc.body.firstElementChild) {
                  if (this.doc.body.firstElementChild.tagName == 'STYLE') {
                      this.doc.body.firstElementChild.remove();
                      if (this.doc.body.textContent) {
                        this.addDatatoPreview();
                      }
                  } else {
                      if (this.doc.body.textContent) {
                        this.addDatatoPreview();
                      }
                  }
              }
          }
        });
        this.composeForm.get("contactitems").valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((val) => {
            if(val && !this.contactsFilterCtrl.value && !this.contactsFilterAssociateIdCtrl.value && !this.contactChecked) {
               this.filterContacts();
               this.contactChecked = true;
            }
           });
        if (this._data && this._data.selectContactIdClickMap) {
           this.composeForm.patchValue({
            contactitems: this._data.selectContactIdClickMap
           })
        }
        this.composeForm.get('newIsBeeEditor').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
            if(!val) {
                this.composeForm.get('isBeeEditor').setValue(true);
            }else if(val) {
                this.composeForm.get('isBeeEditor').setValue(false);
            }
        });
        this.composeForm.get('newIsNotificationBeeEditor').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
            if(!val) {
                this.composeForm.get('isNotificationBeeEditor').setValue(true);
            }else if(val) {
                this.composeForm.get('isNotificationBeeEditor').setValue(false);
            }
        });
        this.onValueChanges();
    }
    get message() { return this.composeForm.get('smsMessage'); }
    get intSmsMms() { return this.composeForm.get('internationalSMSMMSMessage'); }
    onValueChanges(): void {
        this.messageLengthObservable = this.message.valueChanges.pipe(
          tap((value: string) => {
            this.maxAllowed = 160;
            [...value].forEach((char: string) => {
              if (!this.gsm7.find(element => element === char)) {
                if (new Blob([char]).size >= 2) {
                  this.maxAllowed = 70;
                }
              }
            });
          }),
        ).subscribe();
        this.intMessageLengthObservable = this.intSmsMms.valueChanges.pipe(
          tap((value: string) => {
            this.maxAllowedInt = 160;
            [...value].forEach((char: string) => {
              if (!this.gsm7.find(element => element === char)) {
                if (new Blob([char]).size >= 2) {
                  this.maxAllowedInt = 70;
                }
              }
            });
          }),
        ).subscribe();
    }
    addDatatoPreview() {
        var FormData = this.composeForm.getRawValue();
        var emailDataHtml =  FormData.emailEditor;
         this.docPreview = new DOMParser().parseFromString(emailDataHtml, "text/html"); 
        if(!this.flagPreview && this._data.action == "new") {
            var previewdiv = this.docPreview.createElement('div');
            previewdiv.id='previewEmail';
            previewdiv.style="display: none;";
            previewdiv.textContent = this.emailData;
            this.docPreview.body.insertBefore(previewdiv, this.docPreview.body.firstElementChild);
            var referenceNode = this.docPreview.getElementById('previewEmail');
            var previewdiv2 = this.docPreview.createElement('div');
            previewdiv2.id='previewEmail2';
            previewdiv2.style="display: none; max-height: 0px; overflow: hidden;";
            previewdiv2.innerHTML = '<span>&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;</span>';
            referenceNode.parentNode.insertBefore(previewdiv2, referenceNode.nextSibling);
            this.previewData = new XMLSerializer().serializeToString(this.docPreview);
            this.composeForm.patchValue({
                emailEditor: this.previewData
            });
            this.flagPreview = true;
        }else if((this.flagPreview && this._data.action == "edit") || this.flagPreview) {
            if(this.docPreview &&  this.docPreview.getElementById('previewEmail')) {
                this.docPreview.getElementById('previewEmail').textContent = this.emailData;
                this.previewData = new XMLSerializer().serializeToString(this.docPreview);
                this.composeForm.patchValue({
                emailEditor: this.previewData
                });
            }else {
                var previewdiv = this.docPreview.createElement('div');
                previewdiv.id='previewEmail';
                previewdiv.style="display: none;";
                previewdiv.textContent = this.emailData;
                this.docPreview.body.insertBefore(previewdiv, this.docPreview.body.firstElementChild);
                var referenceNode = this.docPreview.getElementById('previewEmail');
                var previewdiv2 = this.docPreview.createElement('div');
                previewdiv2.id='previewEmail2';
                previewdiv2.style="display: none; max-height: 0px; overflow: hidden;";
                previewdiv2.innerHTML = '<span>&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;&#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp; &#847; &zwnj; &nbsp;</span>';
                referenceNode.parentNode.insertBefore(previewdiv2, referenceNode.nextSibling);
                this.previewData = new XMLSerializer().serializeToString(this.docPreview);
                this.composeForm.patchValue({
                    emailEditor: this.previewData
                });
            }
        }
    }
    ngAfterViewInit(): void {
        setTimeout(()=>{
            this.afterEditorInit = true;
        });
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    getReports() {
        this._fuseSplashScreenService.show();
        this._campaignsService.getReports()
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res) {
                    this.allReportsList = res.data;
                    this.reportsList = res.data;
                }
                else {
                    this.allReportsList = [];
                    this.reportsList = [];
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.allReportsList = [];
                    this.reportsList = [];
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    getContactsCount() {
        this._pageService.getContactsCountToCompose(this._pageService.contactsBysearchText, this.currentPageNumber, this.pageSize)
            .subscribe((res: any) => {
                if (res) {
                    this._pageService.contactsTotalCount = res.data;
                }
                else {
                    this._pageService.contactsTotalCount = 0;
                }
            },
                error => {
                    this._pageService.contactsTotalCount = 0;
                    this._toastr.error(error.message, 'Error');
                }
            );
    }

    getDataToEdit() {
        if (this._data) {
            if (this._data.contact) {
                this.composeForm.patchValue({
                    contactitems: this._data.contact.id ? [this._data.contact.id] : []
                });
                this.replyContact = true;
            }
            if (this._data.contactItems) {
                this.composeForm.patchValue({
                    contactitems: this._data.contactItems.map(ev => { return ev.id })
                });
                this.replyContact = true;
            }
            if(this._data.contactId) {
                this.composeForm.patchValue({
                    contactitems: [this._data.contactId]
                });
                this.replyContact = true;
            }
            if(this._data.link && this._data.link.length > 0) {
                var tempLinks = [];
                this._data.link.forEach(lk => {
                    var obj = {
                        "name": lk.Name || lk.name,
                        "url": lk.URL || lk.url
                    };
                    tempLinks.push(obj);
                });
                this._data.link = tempLinks;
                if (this._data.template.unifiedTypeId == 1 || this._data.template.unifiedTypeId == 21) { // For Mails
                   this.emailLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 2 || this._data.template.unifiedTypeId == 7 || this._data.template.unifiedTypeId == 11 || this._data.template.unifiedTypeId == 12 || this._data.template.unifiedTypeId == 15 || this._data.template.unifiedTypeId == 16 || this._data.template.unifiedTypeId == 22 || this._data.template.unifiedTypeId == 23) {
                    this.smsLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 3) {
                    this.whatsappLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 4) {
                    this.lineLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 5) {
                    this.fbLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 6) {
                    this.telegramLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 8 || this._data.template.unifiedTypeId == 24) {
                    this.shortCodeLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 9) {
                    this.notificationLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 13 || this._data.template.unifiedTypeId == 14 || this._data.template.unifiedTypeId == 17) {
                    this.internationalSMSMMSLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 18) {
                    this.verbNotificationLinks = this._data.link;
                } 
                else if (this._data.template.unifiedTypeId == 19) {
                    this.nowTechNotificationLinks = this._data.link;
                }
                else if (this._data.template.unifiedTypeId == 20) {
                    this.shoutLinks = this._data.link;
                }
            }

            if (this._data && this._data.isVerifyMessage) {
                this.composeForm.get('accounttypes').patchValue([this._data.deliveryMethod]);
                if (this._data.deliveryMethod == 1 || this._data.deliveryMethod == 21) {
                    this.composeForm.get('subject').patchValue("Update Information");
                    this.composeForm.get('emailEditor').patchValue("<p>Please click on the link below to update your information. If you have any questions, please reply or call this number for customer support.</p><p>[ValidateLink]</p>");
                }
                else if (this._data.deliveryMethod == 2 || this._data.deliveryMethod == 11 || this._data.deliveryMethod == 15 || this._data.deliveryMethod == 22) {
                    this.composeForm.get('smsMessage').patchValue('Please click on the link below to update your information. If you have any questions, please reply or call this number for customer support.\n\n[ValidateLink]\n');
                }else if (this._data.deliveryMethod == 13 || this._data.deliveryMethod == 14 || this._data.deliveryMethod == 17) {
                    this.composeForm.get('internationalSMSMMSMessage').patchValue('Please click on the link below to update your information. If you have any questions, please reply or call this number for customer support.\n\n[ValidateLink]\n');
                }
                else if (this._data.deliveryMethod == 18) {
                    this.composeForm.get('verbNotificationSubject').patchValue("Update Information");
                    this.composeForm.get('verbNotificationMessage').patchValue('Please click on the link below to update your information. If you have any questions, please reply or call this number for customer support.\n\n[ValidateLink]\n');
                }
                else if (this._data.deliveryMethod == 19) {
                    this.composeForm.get('nowTechNotificationSubject').patchValue("Update Information");
                    this.composeForm.get('nowTechNotificationMessage').patchValue('Please click on the link below to update your information. If you have any questions, please reply or call this number for customer support.\n\n[ValidateLink]\n');
                }
                else if (this._data.deliveryMethod == 20) {
                    this.composeForm.get('shoutMessage').patchValue('Please click on the link below to update your information. If you have any questions, please reply or call this number for customer support.\n\n[ValidateLink]\n');
                }
                this.composeForm.get('accounttypes').disable();
                this.composeForm.get('contactitems').disable();
            }
            if (this._data.template) {
                let attachFile = [];
                if((this._data.template.unifiedTypeId == 1 || this._data.template.unifiedTypeId == 21) && this._data.template && this._data.template.mediaURL) {
                    var mediaUrlData = this._data.template.mediaURL;
                    if(mediaUrlData.includes('{')) {
                        var mediaUrlDataKey = Object.keys(JSON.parse(mediaUrlData));
                        var mediaUrlDataValues = [];
                        mediaUrlDataValues = Object.values(JSON.parse(mediaUrlData));        
                        (mediaUrlDataValues[0]).forEach(item =>{
                            let attachData = mediaUrlDataKey[0]+item;
                            let attachDataSplit = attachData.split(' ', 2);
                            attachFile.push(attachDataSplit[0]);
                            
                        });
                    }else {
                        attachFile.push(mediaUrlData);
                    }
                }
                
                if (this._data.template.unifiedTypeId == 1 || this._data.template.unifiedTypeId == 21) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        emailEditor: this._data.template.messageText.includes('</html>') ? this._data.template.messageText.split('</html>')[0] + '</html>' : this._data.template.messageText,
                        emailTemplate: this._data.template.id,
                        subject: this._data.template.subject,
                        attachments: attachFile,
                        prefix: this._data.template.prefix,
                        replyTo: this._data.template.replyTo,
                        isBeeEditor: this._data && this._data.template && this._data.template.isBeeEditor ? this._data.template.isBeeEditor : false,
                        MessageJson: this._data.template.messageJson ? JSON.parse(this._data.template.messageJson) : this._pageService.defaultTemplate,
                        newIsBeeEditor: (this._data && this._data.template && this._data.template.isBeeEditor && this._data.template.isBeeEditor == true) ? false : true
                    });
                }
                else if (this._data.template.unifiedTypeId == 2 || this._data.template.unifiedTypeId == 11 || this._data.template.unifiedTypeId == 15 || this._data.template.unifiedTypeId == 22) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        smsMessage: this._data.template.messageText,
                        smsTemplate: this._data.template.id
                    });
                }
                else if (this._data.template.unifiedTypeId == 3) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        whatsappMessage: this._data.template.messageText,
                        whatsappTemplate: this._data.template.id,
                        whatsAppMediaUrl: this._data.template.mediaURL ? this._data.template.mediaURL : ''
                    });
                }
                else if (this._data.template.unifiedTypeId == 4) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        lineMessage: this._data.template.messageText,
                        lineTemplate: this._data.template.id,
                        lineMediaUrl: this._data.template.mediaURL ? this._data.template.mediaURL : ''
                    });
                }
                else if (this._data.template.unifiedTypeId == 5) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        fbMessage: this._data.template.messageText,
                        fbTemplate: this._data.template.id
                    });
                }
                else if (this._data.template.unifiedTypeId == 6) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        telegramMessage: this._data.template.messageText,
                        telegramTemplate: this._data.template.id,
                        telegramMediaUrl: this._data.template.mediaURL ? this._data.template.mediaURL : ''
                    });
                }
                else if (this._data.template.unifiedTypeId == 7 || this._data.template.unifiedTypeId == 12 || this._data.template.unifiedTypeId == 16 || this._data.template.unifiedTypeId == 23) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        smsMessage: this._data.template.messageText,
                        mmsTemplate: this._data.template.id,
                        mediaURL: this._data.template.mediaURL ? this._data.template.mediaURL : ''
                    });
                }
                else if (this._data.template.unifiedTypeId == 8 || this._data.template.unifiedTypeId == 24) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        shortCodeMessage: this._data.template.messageText,
                        shortCodeTemplate: this._data.template.id,
                        shortCodemediaURL: this._data.template.mediaURL ? this._data.template.mediaURL : ''
                    });
                }
                else if (this._data.template.unifiedTypeId == 9) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        notificationMessage:  this._data.template.messageText.includes('</html>') ? this._data.template.messageText.split('</html>')[0] + '</html>' : this._data.template.messageText,
                        notificationTemplate: this._data.template.id,
                        allNotificationType: this._data.template.subject,
                        isNotificationBeeEditor: this._data.template.isBeeEditor ? this._data.template.isBeeEditor : false,
                        notificationTypeId:0,
                        newIsNotificationBeeEditor: (this._data.template.isBeeEditor && this._data.template.isBeeEditor == true) ? false : true,
                    });
                }
                else if (this._data.template.unifiedTypeId == 13 || this._data.template.unifiedTypeId == 14 || this._data.template.unifiedTypeId == 17) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        internationalSMSMMSMessage: this._data.template.messageText,
                        internationalSMSMMSTemplate: this._data.template.id,
                        internationalSMSMMSMediaURL: this._data.template.mediaURL ? this._data.template.mediaURL : ''
                    });
                }
                else if (this._data.template.unifiedTypeId == 18) {
                    this.composeForm.patchValue({
                        verbNotificationSubject: this._data.template.subject,
                        accounttypes: [this._data.template.unifiedTypeId],
                        verbNotificationMessage: this._data.template.messageText,
                        verbNotificationTemplate: this._data.template.id
                    });
                }
                else if (this._data.template.unifiedTypeId == 19) {
                    this.composeForm.patchValue({
                        nowTechNotificationSubject: this._data.template.subject,
                        accounttypes: [this._data.template.unifiedTypeId],
                        nowTechNotificationMessage: this._data.template.messageText,
                        nowTechNotificationTemplate: this._data.template.id
                    });
                }
                else if (this._data.template.unifiedTypeId == 20) {
                    this.composeForm.patchValue({
                        accounttypes: [this._data.template.unifiedTypeId],
                        shoutMessage: this._data.template.messageText,
                        shoutTemplate: this._data.template.id,
                        shoutmediaURL: this._data.template.mediaURL,
                        notificationTypeId: this._data.template.notificationTypeId
                    });
                }
            }
        }
    }
    getAllgroups() {
        this._fuseSplashScreenService.show();
        this._pageService.getAllGroups()
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res) {
                    this.allGroupsList = res.data;
                    this.groupsList = res.data;
                }
                else {
                    this.allGroupsList = [];
                    this.groupsList = [];
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    createComposeForm(): FormGroup {
        return new FormGroup({
            subject: new FormControl(''),
            message: new FormControl(''),
            emailEditor: new FormControl(''),
            contactitems: new FormControl([]),
            accounttypes: new FormControl([], Validators.required),
            groups: new FormControl([]),
            reports: new FormControl([]),
            optOutTrailor: new FormControl('Reply STOP to stop'),
            smsMessage: new FormControl(''),
            internationalSMSMMSMessage: new FormControl(''),
            smsOptOutTrailor: new FormControl('Reply STOP to stop'),
            internationalSMSMMSOptOutTrailor: new FormControl('Reply STOP to stop'),
            shortCodeMessage: new FormControl(''),
            shortCodeOptOutTrailor: new FormControl('Reply STOP to stop'),
            fbMessage: new FormControl(''),
            fbOptOutTrailor: new FormControl('Reply STOP to stop'),
            telegramMessage: new FormControl(''),
            telegramOptOutTrailor: new FormControl('Reply STOP to stop'),
            lineMessage: new FormControl(''),
            lineOptOutTrailor: new FormControl('Reply STOP to stop'),
            whatsappMessage: new FormControl(''),
            MessageJson: new FormControl(this._pageService.defaultTemplate),
            whatsappOptOutTrailor: new FormControl('Reply STOP to stop'),
            notificationMessage: new FormControl(''),
            notificationOptOutTrailor: new FormControl('Reply STOP to stop'),
            emailTemplate: new FormControl(0),
            smsTemplate: new FormControl(0),
            shortCodeTemplate: new FormControl(0),
            fbTemplate: new FormControl(0),
            lineTemplate: new FormControl(0),
            whatsappTemplate: new FormControl(0),
            telegramTemplate: new FormControl(0),
            mmsTemplate: new FormControl(0),
            internationalSMSMMSTemplate: new FormControl(0),
            notificationTemplate: new FormControl(0),
            verbNotificationTemplate: new FormControl(0),
            allNotificationType: new FormControl(''),
            mediaURL: new FormControl(''),
            internationalSMSMMSMediaURL: new FormControl(''),
            shortCodemediaURL: new FormControl(''),
            telegramMediaUrl: new FormControl(''),
            lineMediaUrl: new FormControl(''),
            whatsAppMediaUrl: new FormControl(''),
            shoutmediaURL: new FormControl(''),
            checkTCPA: new FormControl(false),
            isBeeEditor: new FormControl(false),
            isNotificationBeeEditor: new FormControl(false),
            NotificationJson: new FormControl(this._pageService.defaultTemplate),
            domainType: new FormControl('0'),
            domaintypeSMS: new FormControl(''),
            domaintypeInternationalSMS: new FormControl(''),
            attachments: new FormControl([]),
            emailPreview: new FormControl(''),
	        prefix: new FormControl('', [Validators.pattern('[a-zA-Z]*')]),
            replyTo: new FormControl(''),
            verbNotificationSubject: new FormControl(''),
            verbNotificationMessage: new FormControl(''),
            nowTechNotificationSubject: new FormControl(''),
            nowTechNotificationMessage: new FormControl(''),
            nowTechNotificationTemplate: new FormControl(0),
            shoutMessage: new FormControl(''),
            shoutTemplate: new FormControl(0),
            newIsBeeEditor: new FormControl(false),
            newIsNotificationBeeEditor: new FormControl(false),
            notificationTypeId: new FormControl(0)
        });
    }
    getLinksFromHtml(htmlString, tag, text) {
        var doc = new DOMParser().parseFromString(htmlString, "text/html");
        var elements = doc.getElementsByTagName(tag);
        var found = [];
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].innerHTML.includes(text)) {
                found.push({
                    "name": elements[i]['innerText'].slice(1, -1),
                    "url": elements[i]['attributes']['href']['value']
                });
            }
        }
        return found;
    }
    sendMessage() {
        if (this.composeForm.invalid) {
            if( this.composeForm && this.composeForm.get('mediaURL').invalid) {
                this._toastr.warning('Please add a media file!')
            }
            this.validateAllFormFields(this.composeForm);
            return false;
        }
        var FormData = this.composeForm.getRawValue();
        if((FormData.accounttypes.indexOf(1) !== -1 || FormData.accounttypes.indexOf(21) !== -1) && this.composeForm.get('isBeeEditor').value) {
            this.beeEditor.instance.save();
        }
        if(FormData.accounttypes.indexOf(13) !== -1 || FormData.accounttypes.indexOf(14) !== -1) {
            if(!FormData.internationalSMSMMSMediaURL && !FormData.internationalSMSMMSMessage) {
                this._toastr.warning('Please fill either Message Text or Media of International SMS', 'Warning');
                return false;
            }
        }
        if (FormData.contactitems.length == 0 && FormData.groups.length == 0 && FormData.reports.length == 0) {
            this._toastr.warning('Please choose atleast one contact or group or report', 'Warning');
            return false;
        }
        if (!this.composeForm.get('checkTCPA').value) {
            this._toastr.warning('Please accept the policies', 'Warning');
            return false;
        }

        if (FormData.accounttypes.indexOf(1) !== -1 || FormData.accounttypes.indexOf(21) !== -1) {
            var tempLinks = [];
            if (FormData.isBeeEditor) {
                tempLinks = this.getLinksFromHtml(FormData.emailEditor, 'a', '[Link');
            }
            else {
                tempLinks = this.emailLinks;
            }
            if(this.beeLinks.length > 0 && FormData.isBeeEditor) {
                tempLinks = tempLinks.concat(this.beeLinks);
            }
            var attachmentsItem = [];
            if(FormData && FormData.attachments) {
                FormData.attachments.forEach((element, index) => {
                    this.mediaList.forEach((item, i) => {
                        if(element == item.filePath) {
                            attachmentsItem.push(item.filePath+' '+item.fileName);
                        }
                    })
                });
            }
            let mail: any = {
                "recipients": FormData.contactitems,
                "subject": FormData.subject,
                "messageText": FormData.emailEditor,
                "groups": FormData.groups,
                "reports": FormData.reports,
                "links": tempLinks,
                "domainType": FormData.domainType ? parseInt(FormData.domainType) : 0,
                "attachments": FormData.attachments ? attachmentsItem : [],
                "emailPreview": FormData.emailPreview ? FormData.emailPreview : '',
		        "prefix": FormData.prefix,
                "replyTo": FormData.replyTo,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            if (this._data && this._data.isVerifyMessage) {
                mail.isVerifyMessage = true;
            }
            this._fuseSplashScreenService.show();
            this._mailService.sendMail(mail,(FormData.accounttypes.indexOf(1) !== -1 ? 1 : 21))
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('Email Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('Email Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('Email Error: ' + error.error, 'Error');
                        }

                    }
                );
        }
        if (FormData.accounttypes.indexOf(2) !== -1 || FormData.accounttypes.indexOf(7) !== -1 || FormData.accounttypes.indexOf(11) !== -1 || FormData.accounttypes.indexOf(12) !== -1 || FormData.accounttypes.indexOf(15) !== -1 || FormData.accounttypes.indexOf(16) !== -1 || FormData.accounttypes.indexOf(22) !== -1 || FormData.accounttypes.indexOf(23) !== -1) {
            let twilioReq: any = {
                "deliveryTypeId": (FormData.accounttypes.indexOf(2) !== -1 ? 2 : (FormData.accounttypes.indexOf(7) !== -1 ? 7 : (FormData.accounttypes.indexOf(11) !== -1 ?  11 : (FormData.accounttypes.indexOf(12) !== -1 ? 12 : (FormData.accounttypes.indexOf(15) !== -1 ? 15 : (FormData.accounttypes.indexOf(16) !== -1 ? 16 : (FormData.accounttypes.indexOf(22) !== -1 ? 22 : 23))))))),
                "groups": FormData.groups,
                "reports": FormData.reports,
                "recipients": FormData.contactitems,
                "messageText": FormData.smsMessage + '\n' + FormData.smsOptOutTrailor,
                "links": this.smsLinks,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            if (FormData.accounttypes.indexOf(7) !== -1 || FormData.accounttypes.indexOf(12) !== -1 || FormData.accounttypes.indexOf(16) !== -1 || FormData.accounttypes.indexOf(23) !== -1) {
                twilioReq.mediaURL = FormData.mediaURL;
            }
            if (this._data && this._data.isVerifyMessage) {
                twilioReq.isVerifyMessage = true;
            }
            this._fuseSplashScreenService.show();
            var Url
            if(FormData.accounttypes.indexOf(2) !== -1 || FormData.accounttypes.indexOf(7) !== -1) {
                Url = "/TwilioMessage/SendToContact";
            }else if(FormData.accounttypes.indexOf(11) !== -1 || FormData.accounttypes.indexOf(12) !== -1) {
                twilioReq.domainType= FormData.domaintypeSMS ? parseInt(FormData.domaintypeSMS) : 0;
                Url = "/BandwidthMessage/SendToContact"; 
            }else if(FormData.accounttypes.indexOf(15) !== -1 || FormData.accounttypes.indexOf(16) !== -1) {
                Url = "/DirectTextMessage/SendToContact"; 
            }
            else if(FormData.accounttypes.indexOf(22) !== -1 || FormData.accounttypes.indexOf(23) !== -1) {
                Url = "/Infobip/SendMessageToContacts"; 
            }
            this._mailService.sendSMSMessage(twilioReq, Url)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(['save', res.data])
                } else {
                    this._toastr.error('SMS Error: ' + res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    if (error.error.error) {
                        this._toastr.error('SMS Error: ' + error.error.error, 'Error');
                    }
                    else {
                        this._toastr.error('SMS Error: ' + error.error, 'Error');
                    }
                }
            );

        }
        if (FormData.accounttypes.indexOf(3) !== -1) {
            let whatsappReq: any = {
                "groups": FormData.groups,
                "reports": FormData.reports,
                "recipients": FormData.contactitems,
                "messageText": FormData.whatsappMessage + '\n' + FormData.whatsappOptOutTrailor,
                "links": this.whatsappLinks,
                "mediaURL": FormData.whatsAppMediaUrl,
                "filter": this._contactsService.appledFilters.value != null ?{
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            this._fuseSplashScreenService.show();
            this._mailService.sendWhatsappMessage(whatsappReq)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('WhatsApp Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('WhatsApp Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('WhatsApp Error: ' + error.error, 'Error');
                        }
                    }
                );
        }
        if (FormData.accounttypes.indexOf(4) !== -1) {
            let lineReq: any = {
                "groups": FormData.groups,
                "reports": FormData.reports,
                "recipients": FormData.contactitems,
                "messageText": FormData.lineMessage + '\n' + FormData.lineOptOutTrailor,
                "links": this.lineLinks,
                "mediaURL": FormData.lineMediaUrl,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            this._fuseSplashScreenService.show();
            this._mailService.sendLineMessage(lineReq)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('Line Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('Line Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('Line Error: ' + error.error, 'Error');
                        }
                    }
                );
        }
        if (FormData.accounttypes.indexOf(5) !== -1) {
            let facebookReq: any = {
                "groups": FormData.groups,
                "reports": FormData.reports,
                "recipients": FormData.contactitems,
                "messageText": FormData.fbMessage + '\n' + FormData.fbOptOutTrailor,
                "links": this.fbLinks,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            this._fuseSplashScreenService.show();
            this._mailService.sendFacebookMessage(facebookReq)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('Facebook Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('Facebook Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('Facebook Error: ' + error.error, 'Error');
                        }
                    }
                );
        }
        if (FormData.accounttypes.indexOf(6) !== -1) {
            let telegramReq: any = {
                "groups": FormData.groups,
                "reports": FormData.reports,
                "recipients": FormData.contactitems,
                "messageText": FormData.telegramMessage + '\n' + FormData.telegramOptOutTrailor,
                "links": this.telegramLinks,
                "mediaURL": FormData.telegramMediaUrl,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            this._fuseSplashScreenService.show();
            this._mailService.sendTelegramMessage(telegramReq)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('Telegram Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('Telegram Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('Telegram Error: ' + error.error, 'Error');
                        }
                    }
                );
        }
        if (FormData.accounttypes.indexOf(8) !== -1 || FormData.accounttypes.indexOf(24) !== -1) {
             let twilioReq: any = {
                "deliveryTypeId":FormData.accounttypes.filter(formData=>(formData==8 || formData==24))[0],
                "groups": FormData.groups,
                "reports": FormData.reports,
                "recipients": FormData.contactitems,
                "messageText": FormData.shortCodeMessage + '\n' + FormData.shortCodeOptOutTrailor,
                "links": this.shortCodeLinks,
                "mediaURL": FormData.shortCodemediaURL,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            if (this._data && this._data.isVerifyMessage) {
                twilioReq.isVerifyMessage = true;
            }
            var Url;
            if(FormData.accounttypes.indexOf(8) !== -1){
                Url="/TwilioMessage/SendToContact";
            }
            if(FormData.accounttypes.indexOf(24) !== -1){
                Url="/Infobip/SendMessageToContacts";
            }
            this._fuseSplashScreenService.show();
            this._mailService.sendTwilioMessage(Url,twilioReq)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('ShortCode Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('ShortCode Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('ShortCode Error: ' + error.error, 'Error');
                        }
                    }
                );
        }
        if (FormData.accounttypes.indexOf(9) !== -1) {
            var tempLinks = [];
            if (FormData.isNotificationBeeEditor) {
                tempLinks = this.getLinksFromHtml(FormData.notificationMessage, 'a', '[Link');
            }
            else {
                tempLinks = this.notificationLinks;
            }
            let notificationReq: any = {
                "recipients": FormData.contactitems,
                "subject": FormData.allNotificationType,
                "messageText": FormData.notificationMessage,
                "groups": FormData.groups,
                "reports": FormData.reports,
                "links": tempLinks,
                "notificationTypeId":0,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            if (this._data && this._data.isVerifyMessage) {
                notificationReq.isVerifyMessage = true;
            }
            this._fuseSplashScreenService.show();
            this._mailService.NotificationMessage(notificationReq)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('Notification Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('Notification Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('Notification Error: ' + error.error, 'Error');
                        }

                    }
                );
        }
        if (FormData.accounttypes.indexOf(13) !== -1 || FormData.accounttypes.indexOf(14) !== -1 || FormData.accounttypes.indexOf(17) !== -1) {
            let twilioReq: any = {
                "deliveryTypeId": FormData.accounttypes.indexOf(13) !== -1 ? 13 : 14,
                "groups": FormData.groups,
                "reports": FormData.reports,
                "recipients": FormData.contactitems,
                "messageText": FormData.internationalSMSMMSMessage + '\n' + FormData.internationalSMSMMSOptOutTrailor,
                "links": this.internationalSMSMMSLinks,
                "isInternational": true,
                "mediaURL": FormData.internationalSMSMMSMediaURL,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0 
                } : null
            };
            if (this._data && this._data.isVerifyMessage) {
                twilioReq.isVerifyMessage = true;
            }
            this._fuseSplashScreenService.show();
            var Url
            if(FormData.accounttypes.indexOf(13) !== -1) {
                Url = "/TwilioMessage/SendToContact";
            }else if(FormData.accounttypes.indexOf(14) !== -1) {
                twilioReq.domainType= FormData.domaintypeInternationalSMS ? parseInt(FormData.domaintypeInternationalSMS) : 0;
                Url = "/BandwidthMessage/SendToContact"; 
            }else if(FormData.accounttypes.indexOf(17) !== -1) {
                Url = "/DirectTextMessage/SendToContact"; 
            }
            this._mailService.sendSMSMessage(twilioReq, Url)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(['save', res.data])
                } else {
                    this._toastr.error('International SMS Error: ' + res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    if (error.error.error) {
                        this._toastr.error('International SMS Error: ' + error.error.error, 'Error');
                    }
                    else {
                        this._toastr.error('International SMS Error: ' + error.error, 'Error');
                    }
                }
            );

        }
        if (FormData.accounttypes.indexOf(18) !== -1) {
            var verbNotificationMessage =  FormData.verbNotificationMessage;
            var docData = new DOMParser().parseFromString(verbNotificationMessage, "text/html");
            var verbNotificationMessageData = docData.body.innerHTML;
            var tempLinks = [];
            tempLinks = this.verbNotificationLinks;
            let verbNotificationReq: any = {
                "deliveryTypeId": 18,
                "recipients": FormData.contactitems,
                "subject": FormData.verbNotificationSubject,
                "messageText": verbNotificationMessageData,
                "groups": FormData.groups,
                "reports": FormData.reports,
                "links": tempLinks,
                "notificationTypeId":0,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            if (this._data && this._data.isVerifyMessage) {
                verbNotificationReq.isVerifyMessage = true;
            }
            this._fuseSplashScreenService.show();
            this._mailService.NotificationMessage(verbNotificationReq).subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('Verb Notification Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('Verb Notification Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('Verb Notification Error: ' + error.error, 'Error');
                        }

                    }
                );
        }
        if (FormData.accounttypes.indexOf(19) !== -1) {
            var nowTechNotificationMessage =  FormData.nowTechNotificationMessage;
            var docData = new DOMParser().parseFromString(nowTechNotificationMessage, "text/html");
            var nowTechNotificationMessageData = docData.body.innerHTML;
            var tempLinks = [];
            tempLinks = this.nowTechNotificationLinks;
            let nowTechNotificationReq: any = {
                "deliveryTypeId": 19,
                "recipients": FormData.contactitems,
                "subject": FormData.nowTechNotificationSubject,
                "messageText": nowTechNotificationMessageData,
                "groups": FormData.groups,
                "reports": FormData.reports,
                "links": tempLinks,
                "notificationTypeId":0,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            if (this._data && this._data.isVerifyMessage) {
                nowTechNotificationReq.isVerifyMessage = true;
            }
            this._fuseSplashScreenService.show();
            this._mailService.NotificationMessage(nowTechNotificationReq).subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('NowTech Notification Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('NowTech Notification Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('NowTech Notification Error: ' + error.error, 'Error');
                        }

                    }
                );
        }
        if (FormData.accounttypes.indexOf(20) !== -1) {
            let shoutReq: any = {
                "deliveryTypeId": 20,
                "recipients": FormData.contactitems,
                "messageText": FormData.shoutMessage,
                "groups": FormData.groups,
                "reports": FormData.reports,
                "links": this.shoutLinks,
                "notificationTypeId": FormData.notificationTypeId,
                "mediaURL": FormData.shoutmediaURL,
                "filter": this._contactsService.appledFilters.value != null ? {
                    "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
                    "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
                    "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
                    "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
                    "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
                    "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
                    "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
                    "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
                    "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
                    "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
                    "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
                    "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
                    "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
                    "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
                    "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
                    "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
                    "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
                    "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
                } : null
            };
            if (this._data && this._data.isVerifyMessage) {
                shoutReq.isVerifyMessage = true;
            }
            this._fuseSplashScreenService.show();
            this._mailService.NotificationMessage(shoutReq).subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close(['save', res.data])
                    } else {
                        this._toastr.error('Shout Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('Shout Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('Shout Error: ' + error.error, 'Error');
                        }

                    }
                );
        }
    }
    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void {
        this.showExtraToFields = !this.showExtraToFields;
    }

    getContactByFilter(id) {
        return this.contacts.filter(x => x.id === id)[0];
    }

    contactchange(event) {
        if (event.isUserInput) {
            this._contactsService.toggleSelectedContact(event.source.value);
        }
    }
    focusContacts() {
        let el = document.getElementById('contactSearchInput');
        if (el) {
          el.focus();
        }
    }
    filterContacts(): void {
        let search = this.contactsFilterCtrl.value;
        let contactByAssociatedId = this.contactsFilterAssociateIdCtrl.value ? this.contactsFilterAssociateIdCtrl.value : 0;
        let selectedIds = this.composeForm.get('contactitems').value.length > 0 ? this.composeForm.get('contactitems').value : [];
        this.scrollStart = true;
        this.currentPageNumber = 1;
        this.contacts = [];
        this.contactsLabel = "Searching...";
        this._pageService.getContactsToCompose(search, contactByAssociatedId, 1, this.pageSize, selectedIds);

    }
    filterGroup(): void {
        let search = this.groupFilterCtrl.value;
        if (search) {
            this.groupsList = this.allGroupsList.filter(x => (x.name.toLowerCase().indexOf(search.toLowerCase()) > -1));
        }
        else {
            this.groupsList = this.allGroupsList;
        }
    }
    filterReport(): void {
        let search = this.reportFilterCtrl.value;
        if (search) {
            this.reportsList = this.allReportsList.filter(x => (x.name.toLowerCase().indexOf(search.toLowerCase()) > -1));
        }
        else {
            this.reportsList = this.allReportsList;
        }
    }
    getNextContacts() {
        if (!this._pageService.stopNextCallingforContacts) {
            this.currentPageNumber += 1;
            this._pageService.getContactsToCompose(this._pageService.contactsBysearchText, this._pageService.contactByAssociatedId, this.currentPageNumber, this.pageSize, this._pageService.contactByselectedIds);
            this.scrollStart = false;
        }
    }
    onScrollDown(ev) {
        this.getNextContacts();
    }
    accountValidations(accArray) {
        if (accArray.length > 0) {
            if (accArray.indexOf(1) !== -1 || accArray.indexOf(21) !== -1) {
                this._pageService.addValidators(this.composeForm, "emailEditor");
                this._pageService.addValidators(this.composeForm, "subject");

            }
            else {
                this.removeValidators(this.composeForm, "emailEditor");
                this.removeValidators(this.composeForm, "subject");
            }
            if (accArray.indexOf(2) !== -1 || accArray.indexOf(11) !== -1 || accArray.indexOf(15) !== -1 || accArray.indexOf(22) !== -1) {
                this._pageService.addValidators(this.composeForm, "smsMessage");
                if (this.DomainTypeSMS.hasmarketingDomain) {
                    this._pageService.addValidators(this.composeForm, "domaintypeSMS");
                }
                else {
                    this._pageService.removeValidators(this.composeForm, "domaintypeSMS");
                }
            }
            else {
                this.removeValidators(this.composeForm, "smsMessage");
                this._pageService.removeValidators(this.composeForm, "domaintypeSMS");
            }
            if (accArray.indexOf(3) !== -1) {
                this._pageService.addValidators(this.composeForm, "whatsappMessage");
            }
            else {
                this.removeValidators(this.composeForm, "whatsappMessage");
            }
            if (accArray.indexOf(4) !== -1) {
                this._pageService.addValidators(this.composeForm, "lineMessage");
            }
            else {
                this.removeValidators(this.composeForm, "lineMessage");
            }
            if (accArray.indexOf(5) !== -1) {
                this._pageService.addValidators(this.composeForm, "fbMessage");
            }
            else {
                this.removeValidators(this.composeForm, "fbMessage");
            }
            if (accArray.indexOf(6) !== -1) {
                this._pageService.addValidators(this.composeForm, "telegramMessage");
            }
            else {
                this.removeValidators(this.composeForm, "telegramMessage");
            }
            if (accArray.indexOf(7) !== -1 || accArray.indexOf(12) !== -1 || accArray.indexOf(16) !== -1 || accArray.indexOf(23) !== -1) {
                this._pageService.addValidators(this.composeForm, "mediaURL");
            }
            else {
                this.removeValidators(this.composeForm, "mediaURL");
            }
            if (accArray.indexOf(8) !== -1 || accArray.indexOf(24) !== -1) {
                this._pageService.addValidators(this.composeForm, "shortCodeMessage");
            }
            else {
                this.removeValidators(this.composeForm, "shortCodeMessage");
            }
            if (accArray.indexOf(9) !== -1) {
                this._pageService.addValidators(this.composeForm, "notificationMessage");
                this._pageService.addValidators(this.composeForm, "allNotificationType");
            }
            else {
                this.removeValidators(this.composeForm, "notificationMessage");
                this.removeValidators(this.composeForm, "allNotificationType");
            }
             if (accArray.indexOf(13) !== -1 || accArray.indexOf(14) !== -1 || accArray.indexOf(17) !== -1) {
                this._pageService.addValidators(this.composeForm, "internationalSMSMMSMessage");
                if (this.DomainTypeInternationalSMS.hasmarketingDomain) {
                    this._pageService.addValidators(this.composeForm, "domaintypeInternationalSMS");
                }else {
                    this._pageService.removeValidators(this.composeForm, "domaintypeInternationalSMS");
                }
            }
            else {
                this.removeValidators(this.composeForm, "internationalSMSMMSMessage");
                this._pageService.removeValidators(this.composeForm, "domaintypeInternationalSMS");
            }
            if (accArray.indexOf(18) !== -1) {
                this._pageService.addValidators(this.composeForm, "verbNotificationMessage");
                this._pageService.addValidators(this.composeForm, "verbNotificationSubject");
            }
            else {
                this.removeValidators(this.composeForm, "verbNotificationMessage");
                this.removeValidators(this.composeForm, "verbNotificationSubject");
            }
            if (accArray.indexOf(19) !== -1) {
                this._pageService.addValidators(this.composeForm, "nowTechNotificationMessage");
                this._pageService.addValidators(this.composeForm, "nowTechNotificationSubject");
            }
            else {
                this.removeValidators(this.composeForm, "nowTechNotificationMessage");
                this.removeValidators(this.composeForm, "nowTechNotificationSubject");
            }
            if (accArray.indexOf(20) !== -1) {
                this._pageService.addValidators(this.composeForm, "shoutMessage");
            }
            else {
                this.removeValidators(this.composeForm, "shoutMessage");
            }
        }
    }
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control: any = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else {
                this.validateAllFormFields(control);
            }
        });
    }
    removeValidators(form: FormGroup, formControl: any) {
        form.get(formControl).clearValidators();
        form.get(formControl).updateValueAndValidity();
    }
    addValidators(form: FormGroup, formControl: any) {
        form.get(formControl).setValidators([Validators.required]);
        form.get(formControl).updateValueAndValidity();
    }
    onMergeField(event) {
        if (this.composeForm.get('accounttypes').value.indexOf(1) !== -1 || this.composeForm.get('accounttypes').value.indexOf(21) !== -1) {
            if (document.getElementById('emailEditor')) {
                this.tinymceEditor.insertContent(event);
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(2) !== -1 || this.composeForm.get('accounttypes').value.indexOf(7) !== -1 || this.composeForm.get('accounttypes').value.indexOf(11) !== -1 || this.composeForm.get('accounttypes').value.indexOf(12) !== -1 || this.composeForm.get('accounttypes').value.indexOf(15) !== -1 || this.composeForm.get('accounttypes').value.indexOf(16) !== -1 || this.composeForm.get('accounttypes').value.indexOf(22) !== -1 || this.composeForm.get('accounttypes').value.indexOf(23) !== -1) {
            let mobileElement = document.getElementById('smsMessage');
            if (mobileElement) {
                this.insertAtCursor(mobileElement, event, 'smsMessage');
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(3) !== -1) {
            let mobileElement = document.getElementById('whatsappMessage');
            if (mobileElement) {
                this.insertAtCursor(mobileElement, event, 'whatsappMessage');
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(4) !== -1) {
            let mobileElement = document.getElementById('lineMessage');
            if (mobileElement) {
                this.insertAtCursor(mobileElement, event, 'lineMessage');
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(5) !== -1) {
            let mobileElement = document.getElementById('fbMessage');
            if (mobileElement) {
                this.insertAtCursor(mobileElement, event, 'fbMessage');
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(6) !== -1) {
            let mobileElement = document.getElementById('telegramMessage');
            if (mobileElement) {
                this.insertAtCursor(mobileElement, event, 'telegramMessage');
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(8) !== -1 || this.composeForm.get('accounttypes').value.indexOf(24) !== -1) {
            let mobileElement = document.getElementById('shortCodeMessage');
            if (mobileElement) {
                this.insertAtCursor(mobileElement, event, 'shortCodeMessage');
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(9) !== -1) {
            if (document.getElementById('notificationMessage')) {
                this.tinymceEditor.insertContent(event);
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(13) !== -1 || this.composeForm.get('accounttypes').value.indexOf(14) !== -1 || this.composeForm.get('accounttypes').value.indexOf(17) !== -1) {
            let mobileElement = document.getElementById('internationalSMSMMSMessage'); 
            if (mobileElement) {
                this.insertAtCursor(mobileElement, event, 'internationalSMSMMSMessage');
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(18) !== -1) {
            if (document.getElementById('verbNotificationMessage')) {
                this.tinymceEditor.insertContent(event);
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(19) !== -1) {
            if (document.getElementById('nowTechNotificationMessage')) {
                this.tinymceEditor.insertContent(event);
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(20) !== -1) {
            let mobileElement = document.getElementById('shoutMessage');
            if (mobileElement) {
                this.insertAtCursor(mobileElement, event, 'shoutMessage');
            }
        }
    }
    onAddViewInBrowser() {
        let event = '<a id="viewInBrowser" style="cursor: pointer;" href="[ViewInBrowser]" target="_blank" rel="noopener">ViewInBrowser</a>';
        if (this.composeForm.get('accounttypes').value.indexOf(1) !== -1 || this.composeForm.get('accounttypes').value.indexOf(21) !== -1) {
            if (document.getElementById('emailEditor')) {
                this.tinymceEditor.insertContent(event);
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(9) !== -1) {
            if (document.getElementById('notificationMessage')) {
                this.tinymceEditor.insertContent(event);
            }
        }
    }
    onAddUnsubscribe() {
        let event = '<a id="unsubscribe-id" style="cursor: pointer;" href="%unsubscribe_url%" target="_blank" rel="noopener">Unsubscribe</a>';
        if (this.composeForm.get('accounttypes').value.indexOf(1) !== -1 || this.composeForm.get('accounttypes').value.indexOf(21) !== -1) {
            if (document.getElementById('emailEditor')) {
                this.tinymceEditor.insertContent(event);
            }
        }
        if (this.composeForm.get('accounttypes').value.indexOf(9) !== -1) {
            if (document.getElementById('notificationMessage')) {
                this.tinymceEditor.insertContent(event);
            }
        }
    }
    insertAtCursor(myField, myValue, key) {
        // Microsoft Edge
        if (window.navigator.userAgent.indexOf("Edge") > -1) {
            var startPos = myField.selectionStart;
            var endPos = myField.selectionEnd;

            myField.value = myField.value.substring(0, startPos) + myValue
                + myField.value.substring(endPos, myField.value.length);

            var pos = startPos + myValue.length;
            myField.focus();
            myField.setSelectionRange(pos, pos);
        }
        //MOZILLA and others
        else if (myField.selectionStart || myField.selectionStart == '0') {
            var startPos = myField.selectionStart;
            var endPos = myField.selectionEnd;
            myField.value = myField.value.substring(0, startPos)
                + myValue
                + myField.value.substring(endPos, myField.value.length);
        } else {
            myField.value += myValue;
        }
        this.composeForm.controls[key].patchValue(myField.value);
    }
    getTemplates() {
        this._fuseSplashScreenService.show();
        this._mailService.getAllTemplates()
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this.allTemplates = res.data;
                    this.emailTemplates = res.data.filter(x => ((x.unifiedTypeId == 1 && !x.isBeeEditor) || (x.unifiedTypeId == 1 && x.isPredefined)) || ((x.unifiedTypeId == 21 && !x.isBeeEditor) || (x.unifiedTypeId == 21 && x.isPredefined)));
                    this.beeEmailTemplates = res.data.filter(x => (x.unifiedTypeId == 1 && x.isBeeEditor));
                    this.smsTemplates = res.data.filter(x => ((x.unifiedTypeId == 2) || (x.unifiedTypeId == 11) || (x.unifiedTypeId == 15) || (x.unifiedTypeId == 22)));
                    this.whatsappTemplates = res.data.filter(x => (x.unifiedTypeId == 3));
                    this.lineTemplates = res.data.filter(x => (x.unifiedTypeId == 4));
                    this.fbTemplates = res.data.filter(x => (x.unifiedTypeId == 5));
                    this.telegramTemplates = res.data.filter(x => (x.unifiedTypeId == 6));
                    this.mmsTemplates = res.data.filter(x => ((x.unifiedTypeId == 7) || (x.unifiedTypeId == 12) || (x.unifiedTypeId == 16) || (x.unifiedTypeId == 23)));
                    this.internationalSMSMMSTemplates = res.data.filter(x => ((x.unifiedTypeId == 13) || (x.unifiedTypeId == 14) || (x.unifiedTypeId == 17)));
                    this.shortCodeTemplates = res.data.filter(x => ((x.unifiedTypeId == 8) || (x.unifiedTypeId == 24) ));
                    this.notificationTemplates = res.data.filter(x => ((x.unifiedTypeId == 9 && !x.isNotificationBeeEditor) || (x.unifiedTypeId == 9 && x.isPredefined)));
                    this.beeNotificationTemplates = res.data.filter(x => (x.unifiedTypeId == 9 && x.isNotificationBeeEditor));
                    this.verbNotificationTemplates = res.data.filter(x => (x.unifiedTypeId ==18));
                    this.nowTechNotificationTemplates = res.data.filter(x => (x.unifiedTypeId == 19));
                    this.shoutTemplates = res.data.filter(x => (x.unifiedTypeId == 20));
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    addTemplate(key, control, type, optOutTrailor, mediaType = null, subjectField = null) {
        let templateId = this.composeForm.get(control).value;
        if (templateId) {
            let template = this.allTemplates.filter(x => (x.id == templateId))[0];
            this.composeForm.controls[key].patchValue(template.messageText);
            if(subjectField != null) {
                this.composeForm.controls[subjectField].patchValue(template.subject);
            }
            if(mediaType != null) {
                this.composeForm.controls[mediaType].patchValue(template.mediaURL ? template.mediaURL : '');
            }
            if(key == 'smsMessage' || key == 'whatsappMessage' || key == 'lineMessage' || key == 'fbMessage' || key == 'telegramMessage' || key == 'internationalSMSMMSMessage' || key == 'shortCodeMessage') {
                this.composeForm.controls[optOutTrailor].patchValue(template.optOutTrailor ? template.optOutTrailor : '');
            }
            this.resetLinks(type);
            if (control == 'smsTemplate') {
                this.composeForm.controls['mmsTemplate'].patchValue(0);
            }
            if (control == 'mmsTemplate') {
                this.composeForm.controls['smsTemplate'].patchValue(0);
            }
        }
    }
    addNotificationTemplate(key, control, type) {
        let templateId = this.composeForm.get(control).value;
        if (templateId) {
            let template = this.allTemplates.filter(x => (x.id == templateId))[0];
            this.composeForm.controls[key].patchValue(template.messageJson);
            this.composeForm.controls['notificationMessage'].patchValue(template.messageText);
            this.composeForm.controls['allNotificationType'].patchValue(template.subject);
            this.composeForm.controls['mediaURL'].patchValue(template.mediaURL ? template.mediaURL : '');
            this.resetLinks(type);
        }
        this.initNotificationBeeEditor();
    }
    onAddTinyUrl(event) {
        if (event) {
            if (document.getElementById('emailEditor') || this.composeForm.get('isBeeEditor').value) {
                let linkIndex: any = this.emailLinks.length + 1;
                let flag = true;
                this.emailLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.emailLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    if (!this.composeForm.get('isBeeEditor').value) {
                        this.onMergeField('[Link' + linkIndex + ']');
                    }
                }
            }
            if (document.getElementById('smsMessage')) {
                let linkIndex: any = this.smsLinks.length + 1;
                let flag = true;
                this.smsLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.smsLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }     
            }
            if (document.getElementById('shortCodeMessage')) {
                let linkIndex: any = this.shortCodeLinks.length + 1;
                let flag = true;
                this.shortCodeLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.shortCodeLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }  
            }
            
            if (document.getElementById('whatsappMessage')) {
                let linkIndex: any = this.whatsappLinks.length + 1;
                let flag = true;
                this.whatsappLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.whatsappLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }
            }
            if (document.getElementById('lineMessage')) {
                let linkIndex: any = this.lineLinks.length + 1;
                let flag = true;
                this.lineLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.lineLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }
            }
            if (document.getElementById('fbMessage')) {
                let linkIndex: any = this.fbLinks.length + 1;
                let flag = true;
                this.fbLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.fbLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }
            }
            if (document.getElementById('telegramMessage')) {
                let linkIndex: any = this.telegramLinks.length + 1;
                let flag = true;
                this.telegramLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.telegramLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }
            }
            if (document.getElementById('notificationMessage') || this.composeForm.get('isNotificationBeeEditor').value) {
                let linkIndex: any = this.notificationLinks.length + 1;
                let flag = true;
                this.notificationLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.notificationLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    if (!this.composeForm.get('isNotificationBeeEditor').value) {
                        this.onMergeField('[Link' + linkIndex + ']');
                    }
                }
            }
            if (document.getElementById('internationalSMSMMSMessage')) {
                let linkIndex: any = this.internationalSMSMMSLinks.length + 1;
                let flag = true;
                this.internationalSMSMMSLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.internationalSMSMMSLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }
            }
            if (document.getElementById('verbNotificationMessage')) {
                let linkIndex: any = this.verbNotificationLinks.length + 1;
                let flag = true;
                this.verbNotificationLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.verbNotificationLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }
            }
            if (document.getElementById('nowTechNotificationMessage')) {
                let linkIndex: any = this.nowTechNotificationLinks.length + 1;
                let flag = true;
                this.nowTechNotificationLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.nowTechNotificationLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }
            }
            if (document.getElementById('shoutMessage')) {
                let linkIndex: any = this.shoutLinks.length + 1;
                let flag = true;
                this.shoutLinks.forEach(element => {
                  if(element.name == 'Link' + linkIndex) {
                    flag = false;
                  }
                });
                if(flag) {
                    this.shoutLinks.push({
                        "name": "Link" + linkIndex,
                        "url": event
                    });
                    this.onMergeField('[Link' + linkIndex + ']');
                }
            }
        }
    }
    removeLink(type, name) {
        if (type == 1 && document.getElementById('emailEditor') || this.composeForm.get('isBeeEditor').value) {

            if (!this.composeForm.get('isBeeEditor').value) {
                let tempLinks = [];
                let itemIndex = this.emailLinks.findIndex(element => element.name == name);
                this.emailLinks.splice(itemIndex, 1);
                let mdata = this.composeForm.get('emailEditor').value;
                let replaced_text = mdata.replace("[" + name + "]", "");
                this.emailLinks.forEach((link, index) => {
                    tempLinks.push({
                        "name": "Link" + Number(index + 1),
                        "url": link.url
                    });
                    replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
                });
                this.emailLinks = tempLinks;
                this.composeForm.controls['emailEditor'].patchValue(replaced_text);
            } else if (this.composeForm.get('isBeeEditor').value) {
                let itemIndex = this.emailLinks.findIndex(element => element.name == name);
                this.emailLinks.splice(itemIndex, 1);

            }
        }
        if ((type == 2 || type == 7) && document.getElementById('smsMessage')) {
            let tempLinks = [];
            let itemIndex = this.smsLinks.findIndex(element => element.name == name);
            this.smsLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('smsMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.smsLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.smsLinks = tempLinks;
            this.composeForm.controls['smsMessage'].patchValue(replaced_text);
        }
        if ((type == 8 || type==24) && document.getElementById('shortCodeMessage')) {
            let tempLinks = [];
            let itemIndex = this.shortCodeLinks.findIndex(element => element.name == name);
            this.shortCodeLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('shortCodeMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.shortCodeLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.shortCodeLinks = tempLinks;
            this.composeForm.controls['shortCodeMessage'].patchValue(replaced_text);
        }
       if (type == 3 && document.getElementById('whatsappMessage')) {
            let tempLinks = [];
            let itemIndex = this.whatsappLinks.findIndex(element => element.name == name);
            this.whatsappLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('whatsappMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.whatsappLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.whatsappLinks = tempLinks;
            this.composeForm.controls['whatsappMessage'].patchValue(replaced_text);
        }
        if (type == 4 && document.getElementById('lineMessage')) {
            let tempLinks = [];
            let itemIndex = this.lineLinks.findIndex(element => element.name == name);
            this.lineLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('lineMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.lineLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.lineLinks = tempLinks;
            this.composeForm.controls['lineMessage'].patchValue(replaced_text);
        }
        if (type == 5 && document.getElementById('fbMessage')) {
            let tempLinks = [];
            let itemIndex = this.fbLinks.findIndex(element => element.name == name);
            this.fbLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('fbMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.fbLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.fbLinks = tempLinks;
            this.composeForm.controls['fbMessage'].patchValue(replaced_text);
        }
        if (type == 6 && document.getElementById('telegramMessage')) {
            let tempLinks = [];
            let itemIndex = this.telegramLinks.findIndex(element => element.name == name);
            this.telegramLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('telegramMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.telegramLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.telegramLinks = tempLinks;
            this.composeForm.controls['telegramMessage'].patchValue(replaced_text);
        }
        if ((type == 13 || type == 14 || type == 17) && document.getElementById('internationalSMSMMSMessage')) {
            let tempLinks = [];
            let itemIndex = this.internationalSMSMMSLinks.findIndex(element => element.name == name);
            this.internationalSMSMMSLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('internationalSMSMMSMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.internationalSMSMMSLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.internationalSMSMMSLinks = tempLinks;
            this.composeForm.controls['internationalSMSMMSMessage'].patchValue(replaced_text);
        }
        if ((type == 9) && document.getElementById('notificationMessage') || this.composeForm.get('isNotificationBeeEditor').value) {
           if(!this.composeForm.get('isNotificationBeeEditor').value) {
            let tempLinks = [];
            let itemIndex = this.notificationLinks.findIndex(element => element.name == name);
            this.notificationLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('notificationMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.notificationLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.notificationLinks = tempLinks;
            this.composeForm.controls['notificationMessage'].patchValue(replaced_text);
           }
           else if (this.composeForm.get('isNotificationBeeEditor').value) {
            let itemIndex = this.notificationLinks.findIndex(element => element.name == name);
            this.notificationLinks.splice(itemIndex, 1);

            }
        }
        if ((type == 18) && document.getElementById('verbNotificationMessage')) {
            let tempLinks = [];
            let itemIndex = this.verbNotificationLinks.findIndex(element => element.name == name);
            this.verbNotificationLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('verbNotificationMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.verbNotificationLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.verbNotificationLinks = tempLinks;
            this.composeForm.controls['verbNotificationMessage'].patchValue(replaced_text);
        }
        if ((type == 19) && document.getElementById('nowTechNotificationMessage')) {
            let tempLinks = [];
            let itemIndex = this.nowTechNotificationLinks.findIndex(element => element.name == name);
            this.nowTechNotificationLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('nowTechNotificationMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.nowTechNotificationLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.nowTechNotificationLinks = tempLinks;
            this.composeForm.controls['nowTechNotificationMessage'].patchValue(replaced_text);
        }
        if ((type == 20) && document.getElementById('shoutMessage')) {
            let tempLinks = [];
            let itemIndex = this.shoutLinks.findIndex(element => element.name == name);
            this.shoutLinks.splice(itemIndex, 1);
            let mdata = this.composeForm.get('shoutMessage').value;
            let replaced_text = mdata.replace("[" + name + "]", "");
            this.shoutLinks.forEach((link, index) => {
                tempLinks.push({
                    "name": "Link" + Number(index + 1),
                    "url": link.url
                });
                replaced_text = replaced_text.replace("[" + link.name + "]", "[Link" + Number(index + 1) + "]");
            });
            this.shoutLinks = tempLinks;
            this.composeForm.controls['shoutMessage'].patchValue(replaced_text);
        }
    }
    resetLinks(type) {
        if (type == 1 || type == 21) {
            this.emailLinks = [];
        }
        if ((type == 2 || type == 7 || type == 11 || type == 12 || type == 15 || type == 16 || type == 22 || type == 23)) {
            this.smsLinks = [];
        }
        if (type == 8 || type==24) {
            this.shortCodeLinks = [];
        }        
        if (type == 3) {
            this.whatsappLinks = [];
        }
        if (type == 4) {
            this.lineLinks = [];
        }
        if (type == 5) {
            this.fbLinks = [];
        }
        if (type == 6) {
            this.telegramLinks = [];
        }
        if (type == 9) {
            this.notificationLinks = [];
        }
        if ((type == 13 || type == 14 || type == 17)) {
            this.internationalSMSMMSLinks = [];
        }
        if (type == 18) {
            this.verbNotificationLinks = [];
        }
        if(type == 19) {
            this.nowTechNotificationLinks = [];
        }
        if(type == 20) {
            this.shoutLinks = [];
        }
    }
    onAllContactSelection(event) {
        this.isSelectAllContacts = event;
        this.currentPageNumber = 2;
        this.contacts = this.contacts.slice(0, 100);
    }

    initBeeEditor() {
        if (document.getElementById('bee-plugin-container')) {
            document.getElementById('bee-plugin-container').innerHTML = '';
        }

        if ((this.composeForm.getRawValue().accounttypes.includes(1) || this.composeForm.getRawValue().accounttypes.includes(21)) && this.composeForm.get('isBeeEditor').value) {
            this._fuseSplashScreenService.show();
            var _matDialog = this._matDialog;
            var that = this;
            var beeConfig = {
                uid: this.userDetails.companyKey.replace(/\s/g, ''), //new approch
                //uid: this.beeEditor.token.userName, //needed for identify resources of the that user and billing stuff
                container: 'bee-plugin-container', //Identifies the id of div element that contains BEE Plugin
                language: 'en-US',
                trackChanges: true,
                mergeTags: this._pageService.mergeTags,
                specialLinks: this._pageService.specialLinks,
                onSaveRow: async (rowJSON, rowHTML, pageJSON) => {
                    await this.setRow(rowJSON);
                },
                rowsConfiguration: {
                    emptyRows: true,
                    defaultRows: true,
                    externalContentURLs: [{
                        name: "All rows",
                        value: "All rows",
                        handle: 'All rows',
                        isLocal: true,
                        behaviors: {
                            canEdit: true,
                            canDelete: true,
                        },
                    },
                    {
                        name: "Header rows",
                        value: "Header rows",
                        handle: 'Header rows',
                        isLocal: true,
                        behaviors: {
                            canEdit: true,
                            canDelete: true,
                        },
                    },
                    {
                        name: "Footer rows",
                        value: "Footer rows",
                        handle: 'Footer rows',
                        isLocal: true,
                        behaviors: {
                            canEdit: true,
                            canDelete: true,
                        },
                    }]
                },
                hooks: {
                    getRows: {
                        handler: async (resolve, reject, args) => {
                            const rows = await this.getRows(args.handle)
                            resolve(rows)
                        }
                    }
                },
                editorFonts: {
                    showDefaultFonts: true,
                    customFonts: [
                        {
                            name: "Playfair Display",
                            fontFamily: "'Playfair Display', serif",
                            url: "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;900&display=swap"
                        },{
                            name: "Indie Flower",
                            fontFamily: "'Indie Flower', cursive",
                            url: "https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap"
                          },{
                            name: "Montserrat Medium",
                            fontFamily: "'Montserrat', sans-serif",
                            url: "https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
                        },{
                            name: "Abril Fatface",
                            fontFamily: "'Abril Fatface', cursive",
                            url: "https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap"
                        },{
                            name: "Comfortaa",
                            fontFamily: "'Comfortaa', cursive",
                            url: "https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap"
                        },{
                            name: "Raleway",
                            fontFamily: "'Raleway', sans-serif",
                            url: "https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap",
                            fontWeight: {
                                200: 'Extra-light',
                                300: 'Light',
                                400: 'Regular',
                                500: 'Medium',
                                600: 'Semi-bold',
                                700: 'Bold',
                              }
                        },{
                            name: "Oswald",
                            fontFamily: "'Oswald', sans-serif",
                            url: "https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap",
                            fontWeight: {
                              200: 'Extra-light',
                              300: 'Light',
                              400: 'Regular',
                              500: 'Medium',
                              600: 'Semi-bold',
                              700: 'Bold',
                            }
                        },{
                            name: "FuturaPTLight",
                            fontFamily: "'FuturaPTLight'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_light.css"
                          },
                          {
                            name: "FuturaPTExtraBold",
                            fontFamily: "'FuturaPTExtraBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_extra_bold.css"
                          },
                          {
                            name: "FuturaPTBold",
                            fontFamily: "'FuturaPTBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_bold.css"
                          },
                          {
                            name: "FuturaPTBoldOblique",
                            fontFamily: "'FuturaPTBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_bold_oblique.css"
                          },
                          {
                            name: "FuturaPTBook",
                            fontFamily: "'FuturaPTBook'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_book.css"
                          },
                          {
                            name: "FuturaPTBookOblique",
                            fontFamily: "'FuturaPTBookOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_book_oblique.css"
                          },{
                            name: "FuturaPTCondBold",
                            fontFamily: "'FuturaPTCondBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBold.css"
                          },
                          {
                            name: "FuturaPTCondBoldOblique",
                            fontFamily: "'FuturaPTCondBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBold_oblique.css"
                          },
                          {
                            name: "FuturaPTCondBook",
                            fontFamily: "'FuturaPTCondBook'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBook.css"
                          },
                          {
                            name: "FuturaPTCondBookOblique",
                            fontFamily: "'FuturaPTCondBookOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBook_oblique.css"
                          },
                          {
                            name: "FuturaPTCondExtraBold",
                            fontFamily: "'FuturaPTCondExtraBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_Condextra_bold.css"
                          },
                          {
                            name: "FuturaPTCondExtraBoldOblique",
                            fontFamily: "'FuturaPTCondExtraBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_Condextra_bold_oblique.css"
                          },
                          {
                            name: "FuturaPTCondMedium",
                            fontFamily: "'FuturaPTCondMedium'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondMedium.css"
                          },
                          {
                            name: "FuturaPTCondMediumOblique",
                            fontFamily: "'FuturaPTCondMediumOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondMedium_oblique.css"
                          },
                          {
                            name: "FuturaPTDemi",
                            fontFamily: "'FuturaPTDemi'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_demi.css"
                          },
                          {
                            name: "FuturaPTDemiOblique",
                            fontFamily: "'FuturaPTDemiOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_demi_oblique.css"
                          },
                          {
                            name: "FuturaPTExtraBoldOblique",
                            fontFamily: "'FuturaPTExtraBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_extra_bold_oblique.css"
                          },
                          {
                            name: "FuturaPTHeavy",
                            fontFamily: "'FuturaPTHeavy'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_heavy.css"
                          },{
                            name: "FuturaPTHeavyOblique",
                            fontFamily: "'FuturaPTHeavyOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_heavy_oblique.css"
                          },
                          {
                            name: "FuturaPTLightOblique",
                            fontFamily: "'FuturaPTLightOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_light_oblique.css"
                          },
                          {
                            name: "FuturaPTMedium",
                            fontFamily: "'FuturaPTMedium'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_medium.css"
                          },
                          {
                            name: "FuturaPTMediumOblique",
                            fontFamily: "'FuturaPTMediumOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_medium_oblique.css"
                          },
                          {
                            name: "Mont-ExtraLight",
                            fontFamily: "'Mont-ExtraLight'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/Mont-ExtraLight.css"
                          },
                          {
                            name: "Mont Heavy",
                            fontFamily: "'Mont Heavy DEMO'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/Mont-HeavyDEMO.css"
                          },
                          {
                            name: "Montserrat-Medium",
                            fontFamily: "'Montserrat-Medium'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/montserrat_medium.css"
                          },
                          {
                            name: "Lobester",
                            fontFamily: "'Lobster', cursive",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/lobester.css"
                          }
                    ]
                },
                contentDialog: {
                    specialLinks: {
                        label: 'Link Tracking',
                        handler: function (resolve, reject) {
                            that.dialogRef = that._matDialog.open(addTinyUrlDialogComponent, {
                                panelClass: 'tiny-url-dialog',
                                width: '600px',
                                height: '350px',
                            });
                            that.dialogRef.afterClosed()
                                .subscribe(response => {
                                    if (response) {
                                        let linkIndex: any = that.beeLinks.length + 1;
                                        var url = {
                                            type: 'custom',
                                            label: '[Link' + linkIndex + ']',
                                            link: response
                                        }
                                        that.beeLinks.push({
                                            // "name": '[Link' + linkIndex + ']',
                                            "name": response,
                                            "url": response
                                        });
                                        //that.onAddTinyUrl(response);
                                        resolve(url);
                                    } else {
                                        reject('');
                                    }
                                });
                        }
                    },
                    saveRow: {
                        handler: async function (resolve, reject, args) {
                            that.dialogRef = await _matDialog.open(SaveRowComponent, {
                                panelClass: 'saveRow-dialog',
                                disableClose: true,
                                data: {
                                    pageName: "saveRowPage",
                                    args: args,
                                    dialogTitle: "Save Row",
                                    action: 'add',
                                    rowData: that.rowData
                                }
                            });
                            that.dialogRef.afterClosed()
                                .subscribe((response: any) => {
                                    if (!response) {
                                        reject('')
                                        return;
                                    }
                                    const metadata = {
                                        name: response.journeyName,
                                        category: response.category
                                    }
                                    resolve(metadata);
                                });
                        }
                    },
                    onEditRow: {
                        handler: async function (resolve, reject, args) {
                            that.dialogRef = await _matDialog.open(SaveRowComponent, {
                                panelClass: 'saveRow-dialog',
                                disableClose: true,
                                data: {
                                    pageName: "saveRowPage",
                                    args: args,
                                    dialogTitle: "Edit Row",
                                    action: 'edit',
                                    // rowData: this.rowData
                                }
                            });
                            that.dialogRef.afterClosed()
                                .subscribe((response: any) => {
                                    if (!response) {
                                        return;
                                    }
                                    if (response) {
                                        that.handleEditRow(args, response)
                                        resolve(true)
                                    } else {
                                        reject('')
                                    }
                                });
                        }
                    },
                    onDeleteRow: {
                        handler: async (resolve, reject, args) => {
                            await that.handleDeleteRow(args)
                            resolve(true)
                        }
                    },
                },
                onSave: (jsonFile, htmlFile) => {
                    this.composeForm.patchValue({
                        emailEditor: htmlFile,
                        MessageJson: JSON.parse(jsonFile)
                    });
                },
                onSaveAsTemplate: (jsonFile) => {
                    this.composeForm.patchValue({
                        MessageJson: JSON.parse(jsonFile)
                    });
                    that.saveAsTemplate();
                },
                onChange: (jsonFile, response) => {
                    this.composeForm.patchValue({
                        MessageJson: JSON.parse(jsonFile)
                    });
                    this.beeEditor.instance.send();
                },
                onSend: (htmlFile) => {
                    this.composeForm.patchValue({
                        emailEditor: htmlFile
                    });
                },
                onError: (errorMessage) => { console.log("onError", errorMessage); },
                onWarning: (alertMessage) => { console.log("onError", alertMessage); }
            }
            this.beeEditor.start(beeConfig, this.composeForm.get('MessageJson').value);
            this.isShowAdvance = true;
            this._fuseSplashScreenService.hide();
        }
    }
    initNotificationBeeEditor() {
        if (document.getElementById('bee-plugin-container-1')) {
            document.getElementById('bee-plugin-container-1').innerHTML = '';
        }
        if(this.composeForm.getRawValue().accounttypes.includes(9) && this.composeForm.get('isNotificationBeeEditor').value) {
            this._fuseSplashScreenService.show();
            var _matDialog = this._matDialog;
            var that = this;
            var notificationbeeConfig = {
                uid: this.userDetails.companyKey.replace(/\s/g, ''), //new approch
                //uid: this.notificationBeeEditor.token.userName, //needed for identify resources of the that user and billing stuff
                container: 'bee-plugin-container-1', //Identifies the id of div element that contains BEE Plugin
                language: 'en-US',
                trackChanges: true,
                mergeTags: this._pageService.mergeTags,
                specialLinks: this._pageService.specialLinks,
                editorFonts: {
                    showDefaultFonts: true,
                    customFonts: [
                        {
                            name: "Playfair Display",
                            fontFamily: "'Playfair Display', serif",
                            url: "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;900&display=swap"
                        },{
                            name: "Indie Flower",
                            fontFamily: "'Indie Flower', cursive",
                            url: "https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap"
                          },{
                            name: "Montserrat Medium",
                            fontFamily: "'Montserrat', sans-serif",
                            url: "https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
                        },{
                            name: "Abril Fatface",
                            fontFamily: "'Abril Fatface', cursive",
                            url: "https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap"
                        },{
                            name: "Comfortaa",
                            fontFamily: "'Comfortaa', cursive",
                            url: "https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap"
                        },{
                            name: "Raleway",
                            fontFamily: "'Raleway', sans-serif",
                            url: "https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap",
                            fontWeight: {
                                200: 'Extra-light',
                                300: 'Light',
                                400: 'Regular',
                                500: 'Medium',
                                600: 'Semi-bold',
                                700: 'Bold',
                              }
                        },{
                            name: "Oswald",
                            fontFamily: "'Oswald', sans-serif",
                            url: "https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap",
                            fontWeight: {
                              200: 'Extra-light',
                              300: 'Light',
                              400: 'Regular',
                              500: 'Medium',
                              600: 'Semi-bold',
                              700: 'Bold',
                            }
                        },{
                            name: "FuturaPTLight",
                            fontFamily: "'FuturaPTLight'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_light.css"
                          },
                          {
                            name: "FuturaPTExtraBold",
                            fontFamily: "'FuturaPTExtraBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_extra_bold.css"
                          },
                          {
                            name: "FuturaPTBold",
                            fontFamily: "'FuturaPTBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_bold.css"
                          },
                          {
                            name: "FuturaPTBoldOblique",
                            fontFamily: "'FuturaPTBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_bold_oblique.css"
                          },
                          {
                            name: "FuturaPTBook",
                            fontFamily: "'FuturaPTBook'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_book.css"
                          },
                          {
                            name: "FuturaPTBookOblique",
                            fontFamily: "'FuturaPTBookOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_book_oblique.css"
                          },{
                            name: "FuturaPTCondBold",
                            fontFamily: "'FuturaPTCondBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBold.css"
                          },
                          {
                            name: "FuturaPTCondBoldOblique",
                            fontFamily: "'FuturaPTCondBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBold_oblique.css"
                          },
                          {
                            name: "FuturaPTCondBook",
                            fontFamily: "'FuturaPTCondBook'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBook.css"
                          },
                          {
                            name: "FuturaPTCondBookOblique",
                            fontFamily: "'FuturaPTCondBookOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBook_oblique.css"
                          },
                          {
                            name: "FuturaPTCondExtraBold",
                            fontFamily: "'FuturaPTCondExtraBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_Condextra_bold.css"
                          },
                          {
                            name: "FuturaPTCondExtraBoldOblique",
                            fontFamily: "'FuturaPTCondExtraBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_Condextra_bold_oblique.css"
                          },
                          {
                            name: "FuturaPTCondMedium",
                            fontFamily: "'FuturaPTCondMedium'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondMedium.css"
                          },
                          {
                            name: "FuturaPTCondMediumOblique",
                            fontFamily: "'FuturaPTCondMediumOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondMedium_oblique.css"
                          },
                          {
                            name: "FuturaPTDemi",
                            fontFamily: "'FuturaPTDemi'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_demi.css"
                          },
                          {
                            name: "FuturaPTDemiOblique",
                            fontFamily: "'FuturaPTDemiOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_demi_oblique.css"
                          },
                          {
                            name: "FuturaPTExtraBoldOblique",
                            fontFamily: "'FuturaPTExtraBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_extra_bold_oblique.css"
                          },
                          {
                            name: "FuturaPTHeavy",
                            fontFamily: "'FuturaPTHeavy'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_heavy.css"
                          },{
                            name: "FuturaPTHeavyOblique",
                            fontFamily: "'FuturaPTHeavyOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_heavy_oblique.css"
                          },
                          {
                            name: "FuturaPTLightOblique",
                            fontFamily: "'FuturaPTLightOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_light_oblique.css"
                          },
                          {
                            name: "FuturaPTMedium",
                            fontFamily: "'FuturaPTMedium'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_medium.css"
                          },
                          {
                            name: "FuturaPTMediumOblique",
                            fontFamily: "'FuturaPTMediumOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_medium_oblique.css"
                          },
                          {
                            name: "Mont-ExtraLight",
                            fontFamily: "'Mont-ExtraLight'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/Mont-ExtraLight.css"
                          },
                          {
                            name: "Mont Heavy",
                            fontFamily: "'Mont Heavy DEMO'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/Mont-HeavyDEMO.css"
                          },
                          {
                            name: "Montserrat-Medium",
                            fontFamily: "'Montserrat-Medium'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/montserrat_medium.css"
                          },
                          {
                            name: "Lobester",
                            fontFamily: "'Lobster', cursive",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/lobester.css"
                          }
                    ]
                },
                contentDialog: {
                    specialLinks: {
                        label: 'Link Tracking',
                        handler: function (resolve, reject) {
                            that.dialogRef = _matDialog.open(addTinyUrlDialogComponent, {
                                panelClass: 'tiny-url-dialog',
                                width: '600px',
                                height: '350px',
                            });
                            that.dialogRef.afterClosed()
                                .subscribe(response => {
                                    if (response) {
                                        let linkIndex: any = that.notificationBeeLinks.length + 1;
                                        var url = {
                                            type: 'custom',
                                            label: '[Link' + linkIndex + ']',
                                            link: response
                                        }
                                        that.notificationBeeLinks.push({
                                            "name": '[Link' + linkIndex + ']',
                                            "url": response
                                        });
                                        //that.onAddTinyUrl(response);
                                        resolve(url);
                                    } else {
                                        reject('');
                                    }
                                });
                        }
                    }
                },
                onSave: (jsonFile, htmlFile) => {
                    this.composeForm.patchValue({
                        notificationMessage: htmlFile,
                        NotificationJson: JSON.parse(jsonFile)
                    });
                },
                onSaveAsTemplate: (jsonFile) => {
                    this.composeForm.patchValue({
                        NotificationJson: JSON.parse(jsonFile)
                    });
                    that.saveAsTemplate();
                },
                onChange: (jsonFile, response) => {
                    this.composeForm.patchValue({
                        NotificationJson: JSON.parse(jsonFile)
                    });
                    this.notificationBeeEditor.instance.send();
                },
                onSend: (htmlFile) => {
                    this.composeForm.patchValue({
                        notificationMessage: htmlFile
                    });
                },
                onError: (errorMessage) => { console.log("onError", errorMessage); },
                onWarning: (alertMessage) => { console.log("onError", alertMessage); }
            }
            this.notificationBeeEditor.start(notificationbeeConfig, this.composeForm.get('NotificationJson').value);
            this.isShowAdvance = true;
            this._fuseSplashScreenService.hide();
        }
    }
    onTabChange(event) {
        if (event.tab.textLabel === "Email") {
            this.initBeeEditor();
            this.isShowAdvance = this.composeForm.get('isBeeEditor').value ? true : false;
        }
        else if (event.tab.textLabel === "Notification"){
             this.initNotificationBeeEditor();
            this.isShowAdvance = this.composeForm.get('isNotificationBeeEditor').value ? true : false;
           
        } else {
            this.isShowAdvance = false;
        }
    }
    addEmialTemplate(key, control, type) {
        let templateId = this.composeForm.get(control).value;
        if (templateId) {
            let template = this.allTemplates.filter(x => (x.id == templateId))[0];
            this.composeForm.controls[key].patchValue(template.messageJson);
            this.composeForm.controls['emailEditor'].patchValue(template.messageText);
            this.composeForm.controls['subject'].patchValue(template.subject);
            this.composeForm.controls['mediaURL'].patchValue(template.mediaURL ? template.mediaURL : '');
        }
        this.initBeeEditor();
    }
    onAddMergeField(event) {
        if(this.composeForm.getRawValue().accounttypes.indexOf(1) !== -1 || this.composeForm.getRawValue().accounttypes.indexOf(21) !== -1) {
         this.composeForm.controls['subject'].patchValue(this.composeForm.controls['subject'].value + event);
        }
    }
    onAddMergeFieldReplyTo(event) {
        if(this.composeForm.getRawValue().accounttypes.indexOf(1) !== -1 || this.composeForm.getRawValue().accounttypes.indexOf(21) !== -1) {
         this.composeForm.controls['replyTo'].patchValue(this.composeForm.controls['replyTo'].value + event);
        }
    }
    emailDataViewInBrowser(emailEditorData) {
        localStorage.setItem('emailEditorData', emailEditorData)
        window.open("/public/emailview"); 
    }
    fullScreenFrame() {
        this.IsIFrameMax = !this.IsIFrameMax;
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("mail-compose-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("mail-content").style.maxHeight = "65vh";
        document.getElementById("mail-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("mail-compose-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("mail-content").style.maxHeight = "100vh";
        document.getElementById("mail-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    saveAsTemplate() {
        this.dialogRef = this._matDialog.open(CommonNameDialogComponent, {
            panelClass: 'CommonName',
        });
        this.dialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            if (response) {
               if(this.selectType == 1) {
                var commonForm = response.getRawValue();
                var FormData = this.composeForm.getRawValue();
                let req: any = {
                    "name": commonForm.name,
                    "unifiedTypeId": FormData.accounttypes.indexOf(1) !== -1 ? 1 : 21,
                    "subject": FormData.subject,
                    "messageText": FormData.emailEditor ? FormData.emailEditor : "",
                    "provider": null,
                    "mediaURL": commonForm.mediaUrl,
                    "messageJson": JSON.stringify(FormData.MessageJson),
                    "isBeeEditor": true,
                    "isPredefined": false,
                };
                this._fuseSplashScreenService.show();
                this._ProvidersService.SaveTemplate(req)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this._fuseSplashScreenService.hide();
                        } else {
                            this._toastr.error(res.error, 'Error');
                            this._fuseSplashScreenService.hide();
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error) {
                                this._toastr.error(error.error, 'Error');
                            }
                            else {
                                this._toastr.error(error.error, 'Error');
                            }
                        }
                    );
               }
               if(this.selectType == 9) {
                var commonForm = response.getRawValue();
                var FormData = this.composeForm.getRawValue();
                let req: any = {
                    "name": commonForm.name,
                    "unifiedTypeId": 9,
                    "subject": FormData.allNotificationType,
                    "messageText": FormData.notificationMessage ? FormData.notificationMessage : "",
                    "provider": null,
                    "mediaURL": commonForm.mediaUrl,
                    "messageJson": JSON.stringify(FormData.NotificationJson),
                    "isBeeEditor": true,
                    "isPredefined": false,
                };
                this._fuseSplashScreenService.show();
                this._ProvidersService.SaveTemplate(req)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this._fuseSplashScreenService.hide();
                        } else {
                            this._toastr.error(res.error, 'Error');
                            this._fuseSplashScreenService.hide();
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error) {
                                this._toastr.error(error.error, 'Error');
                            }
                            else {
                                this._toastr.error(error.error, 'Error');
                            }
                        }
                    );
               }
            }
        });

    }
    selectTemplate (type){
        this.selectType = type
    }

    emailTemplateCatalog(unifiedTypeId) {
        this.dialogRef = this._matDialog.open(emailTemplateCatalogDialogComponent, {
            panelClass: 'emailTemplateCatalog',
            data: {
                emailTemplates: this.beeEmailTemplates,
                accTyped : unifiedTypeId
            }
        });
        this.dialogRef.afterClosed().subscribe(response => {

            if (!response) {
                return;
            }
            if (response) {
                if (!response.messageJson) {
                    this._toastr.error('Template content not found', 'Error');
                    return;
                }
                var MessageJson = response.messageJson;
                var emailEditor = response.messageText;
                this.composeForm.controls['MessageJson'].patchValue(JSON.parse(MessageJson));
                this.composeForm.controls['emailEditor'].patchValue(emailEditor);
                this.composeForm.controls['subject'].patchValue(response.subject);
                this.initBeeEditor();
            }
        });
    }
    notificationTemplateCatalog() {
        this.dialogRef = this._matDialog.open(emailTemplateCatalogDialogComponent, {
            panelClass: 'emailTemplateCatalog',
            data: {
                emailTemplates: this.beeNotificationTemplates,
                accTyped : 9
            }
        });
        this.dialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            if (response) {
                if (!response.messageJson) {
                    this._toastr.error('Template content not found', 'Error');
                    return;
                }
                var NotificationJson = response.messageJson;
                var notificationMessage = response.messageText;
                this.composeForm.controls['NotificationJson'].patchValue(JSON.parse(NotificationJson));
                this.composeForm.controls['notificationMessage'].patchValue(notificationMessage);
                this.composeForm.controls['allNotificationType'].patchValue(response.subject);
                this.initNotificationBeeEditor();
            }
        });
    }
    testContact(type, typeID) {
        var FormData = this.composeForm.getRawValue();
        if (FormData.accounttypes.length > 0) {
            if ((FormData.accounttypes.indexOf(1) !== -1 || FormData.accounttypes.indexOf(21) !== -1) && typeID == 1) {
                if(!FormData.subject) {
                    this.composeForm.get('subject').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
                if(!FormData.emailEditor) {
                    this.composeForm.get('emailEditor').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if (typeID == 2 && (FormData.accounttypes.indexOf(2) !== -1 || FormData.accounttypes.indexOf(11) !== -1 || FormData.accounttypes.indexOf(15) !== -1 || FormData.accounttypes.indexOf(22) !== -1)) {
                if(!FormData.smsMessage) {
                    this.composeForm.get('smsMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if (FormData.accounttypes.indexOf(3) !== -1 && typeID == 3) {
                if(!FormData.whatsappMessage) {
                    this.composeForm.get('whatsappMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if (FormData.accounttypes.indexOf(4) !== -1 && typeID == 4) {
                if(!FormData.lineMessage) {
                    this.composeForm.get('lineMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if (FormData.accounttypes.indexOf(5) !== -1 && typeID == 5) {
                if(!FormData.fbMessage) {
                    this.composeForm.get('fbMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if (FormData.accounttypes.indexOf(6) !== -1 && typeID == 6) {
                if(!FormData.telegramMessage) {
                    this.composeForm.get('telegramMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if (typeID == 2 && (FormData.accounttypes.indexOf(7) !== -1 || FormData.accounttypes.indexOf(12) !== -1 || FormData.accounttypes.indexOf(16) !== -1 || FormData.accounttypes.indexOf(23) !== -1)) {
                if(!FormData.mediaURL) {
                    this.composeForm.get('mediaURL').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if ((FormData.accounttypes.indexOf(8) !== -1 && typeID == 8) || FormData.accounttypes.indexOf(24) !== -1 && typeID == 24) {
                if(!FormData.shortCodeMessage) {
                    this.composeForm.get('shortCodeMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
           if (FormData.accounttypes.indexOf(9) !== -1 && typeID == 9) {
                if(!FormData.notificationMessage) {
                    this.composeForm.get('notificationMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if (FormData.accounttypes.indexOf(18) !== -1 && typeID == 18) {
                if(!FormData.verbNotificationSubject) {
                    this.composeForm.get('verbNotificationSubject').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
                if(!FormData.verbNotificationMessage) {
                    this.composeForm.get('verbNotificationMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if (FormData.accounttypes.indexOf(19) !== -1 && typeID == 19) {
                if(!FormData.nowTechNotificationSubject) {
                    this.composeForm.get('nowTechNotificationSubject').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
                if(!FormData.nowTechNotificationMessage) {
                    this.composeForm.get('nowTechNotificationMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if (FormData.accounttypes.indexOf(20) !== -1 && typeID == 20) {
                if(!FormData.shoutMessage) {
                    this.composeForm.get('shoutMessage').markAsTouched();
                    this._toastr.warning('Please fill all required fields', 'Warning');
                    return false
                }
            }
            if(typeID == 13 && (FormData.accounttypes.indexOf(13) !== -1 || FormData.accounttypes.indexOf(14) !== -1 || FormData.accounttypes.indexOf(17) !== -1)) {
                if(!FormData.internationalSMSMMSMediaURL && !FormData.internationalSMSMMSMessage) {
                    this._toastr.warning('Please fill either Message Text or Media of International SMS', 'Warning');
                    return false;
                }
            }
        }

        this.dialogRef = this._matDialog.open(TestContactDialogComponent, {
            panelClass: 'testContact-dialog',
            width: '850px',
            height: '270px',
            data: {
                typed: type,
                typeId: typeID,
                Form: this.composeForm,
                emailLinks: this.emailLinks,
                smsLinks: this.smsLinks,
                whatsappLinks: this.whatsappLinks,
                lineLinks: this.lineLinks,
                fbLinks: this.fbLinks,
                telegramLinks: this.telegramLinks,
                shortCodeLinks: this.shortCodeLinks,
                notificationLinks: this.notificationLinks,
                internationalSMSMMSLinks: this.internationalSMSMMSLinks,
                verbNotificationLinks: this.verbNotificationLinks,
                nowTechNotificationLinks: this.nowTechNotificationLinks,
                shoutLinks: this.shoutLinks
            }
        });
        this.dialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
        });
    }
    selectedMergeField(Event) {
        this.onMergeField(this.mergeField.value);
    }
    applyFilters(): void {
        this.dialogRef = this._matDialog.open(ContactsFiltersComponent, {
            panelClass: 'contact-filters-dialog',
            disableClose: true,
            data: {
                action: 'new',
                dialogType: 'filters',
                dialogTitle: 'Add Filters'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: any) => {
                this.appliedFilters = this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value : null;
                this.getFilterContactsLength();
            });
           
    }
    getFilterContactsLength() {
        let contactReq: any = {
            paidAsRanks: this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
            highestRanks: this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
            countries: this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
            states:  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
            associateTypes: this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
            associateStatus: this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
            languageCodes: this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
            optFilter: this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
            invalidData: this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
            fromDate: this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
            toDate: this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
            groupRangeFrom: this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
            groupRangeTo: this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
            hasActiveAutoship: this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
            channels: this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
            customFields: this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
            customFieldsCompare: this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
            sponsorId: this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
        }
        this.showfilterContactsCountLoader = true;
        this._restService.Post('/Contact/GetAllContactsCount?IsAgent=' + this._pageService.isAgentView, contactReq)
            .subscribe((response: any) => {
                if (response.status == 200) {
                    this.showfilterContactsCountLoader = false;
                    this.filterContactsCount = response.data;
                }
            },
            error => {
                this._toastr.error(error.message, 'Error');
            });
    }
    removeContactFilterList() {
        this._contactsService.appledFilters.next(null);
    }
    addGroupContactFilterList() {
        this.dialogRef = this._matDialog.open(AddGroupComponent, {
            panelClass: 'add-group-dialog',
            disableClose: true,
            data: {
                action: 'new',
                addToGroup: false
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }
                this.getAllgroups();
            });
    }
    viewContactFilterList() {
            this.dialogRef = this._matDialog.open(ContactListFilterComponent, {
              panelClass: 'contact-list-filter-dialog',
              disableClose: true,
              maxWidth: "80vm",
              data: {
                appliedFilters: this.appliedFilters,
                listLength: this.filterContactsCount
              }
            });
            this.dialogRef.afterClosed()
              .subscribe(response => {
                if (!response) {
                  return;
                }
              });
    }
    onSlectMediaChange() {
        if(this.currenMessageType == 'smsMessage' && !this.composeForm.get('smsMessage').value){
            this.isSMSMessageTop = false;
        }
        if(this.currenMessageType == 'whatsappMessage' && !this.composeForm.get('whatsappMessage').value) {
            this.isWhatsAppMessageTop = false;
        }
        if(this.currenMessageType == 'lineMessage' && !this.composeForm.get('lineMessage').value) {
            this.isLineMessageTop = false;
        }
        if(this.currenMessageType == 'telegramMessage' && !this.composeForm.get('telegramMessage').value) {
            this.isTelegramMessageTop = false;
        }
        if(this.currenMessageType == 'shortCodeMessage' && !this.composeForm.get(this.currenMessageType).value) {
            this.isShortCodeMessageTop = false;
        }
        if(this.currenMessageType == 'internationalSMSMMSMessage' && !this.composeForm.get('internationalSMSMMSMessage').value) {
            this.isInternationalSMSMMSMessageTop = false;
        }
        if(this.currenMessageType == 'shoutMessage' && !this.composeForm.get('shoutMessage').value) {
            this.isShoutMessageTop = false;
        }
    }

    viewBrowser() {
        this.dialogRef = this._matDialog.open(ViewBrowserNameDialogComponent,
            {
                panelClass: 'view-browser-dialog'
            }
        );
        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (response != undefined && response !=null) {
                this.generateViewBrowserLink(response);
            }
        });
    }
    generateViewBrowserLink(name) {
        var FormData = this.composeForm.getRawValue();
        let generateViewBrowserLinkReq: any = {
            "name": name,
            "messageText": FormData.emailEditor ? FormData.emailEditor : "",
        };
        this._fuseSplashScreenService.show();
        this._pageService.generateViewBrowserLink(generateViewBrowserLinkReq)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                   this.viewBrowserLink = res.data;
                } else {
                    this._toastr.error(res.error, 'Error');
                }
                },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error, 'Error');
                }
            );
    }
    viewBrowserList() {
        this.dialogRef = this._matDialog.open(ViewBrowserListComponent, {
          panelClass: 'view-browser-list-dialog',
          disableClose: true,
          data: {
            dialogTitle: 'Browser Link'
          }
        });
        this.dialogRef.afterClosed()
          .subscribe(response => {
            if (!response) {
              return;
            }
        });
    }
    copyText() {
        navigator.clipboard.writeText(this.viewBrowserLink);
        this._toastr.success('Copied!');
    }
    setRow = async function (row) {
        this.addEditBeeRows(row, 'add');
    }
    getRows = async handle => {
        var selctedCategory = this.rowData.filter((row) => (row.metadata.category == handle));
        return (handle && handle == "All rows") ? this.rowData : selctedCategory;
    }
    handleEditRow = async (args, response) => {
        this.rowData.forEach(row => {
            if (row.metadata.name === args.row.metadata.name) {
                row.metadata.name = response.journeyName;
                this.addEditBeeRows(row, "edit");
                return;
            }

        });
    }
    handleDeleteRow = async function (args) {
        this.rowData.forEach((row, index) => {
            if (row.metadata.name === args.row.metadata.name) {
                var id = row.id;
                delete (this.rowData[index]);
                this.deleteRow(id);
            }

        });
    }
    getBeeRowList() {
        this._campaignsService.GetBeeRowList()
            .subscribe((res: any) => {
                if (res.status == 200) {
                    var dataRow = res.data;
                    this.rowData = [];
                    if (dataRow.length > 0) {
                        dataRow.forEach(element => {
                            let row = JSON.parse(element.rowData);
                            row.id = element.id;
                            this.rowData.push(row);
                        });
                    }
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._toastr.error(error.error, 'Error');
                }
            );
    }
    addEditBeeRows(row, action) {
        var addReq;
        if (action == "edit") {
            addReq = {
                "id": row.id,
                "companyId": this.userDetails.companyId,
                "rowData": JSON.stringify(row)
            }
        } else if (action == "add") {
            addReq = {
                "companyId": this.userDetails.companyId,
                "rowData": row
            }
        }
        this._campaignsService.AddEditBeeRows(addReq)
            .subscribe((res: any) => {
                if (res.status == 200) {
                    this._toastr.success((action == "edit" ? "Row Edit successfully" : "Row added successfully"), 'Success');
                    this.getBeeRowList();
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._toastr.error(error.error, 'Error');
                }
            );
    }
    deleteRow(id) {
        this._campaignsService.DeleteBeeRow(id)
            .subscribe((res: any) => {
                if (res.status == 200) {
                    this.getBeeRowList();
                    this._toastr.success("Row deleted successfully", 'Success');
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._toastr.error(error.error, 'Error');
                }
            );
    }
    addMedia(filed, messageType) {
        this.currenMessageType = messageType;
        this.dialogRef = this._matDialog.open(AddMediaComponent, {
            panelClass: "add-media-dialog",
            disableClose: true,
            data: {
                dialogTitle: "Add Media",

            },
        });
        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (!response) {
                return;
            }
            this.composeForm.get(filed).setValue(response);
        });
    }
    addMergeField() {
        this.dialogRef = this._matDialog.open(AddMergeFieldComponent, {
            panelClass: "add-merge-field-dialog",
            disableClose: true,
            data: {
                dialogTitle: "Add Merge Field",
                mergeField: this.mergeField
            },
        });
        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (!response) {
                return;
            }
        });
    }
    addLinkTracking() { 
        this.dialogRef = this._matDialog.open(AddLinkTrackingComponent, {
            panelClass: "add-link-tracking-dialog",
            disableClose: true,
            data: {
                dialogTitle: "Add Link Tracking"
            },
        });
        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (!response) {
                return;
            }
            this.onAddTinyUrl(response);
        });
    }
    addPageLink(){
        
        this.dialogRef = this._matDialog.open(AddPageLinkComponent, {
            panelClass: "add-pages-link-dialog",
            disableClose: true,
            data: {
                dialogTitle: "Add Page Link",
             },
        });
        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (!response) {
                return;
            }
            this.onMergeField(response);
        });
    }
}

