import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { PageService } from "app/main/pages/pages.service";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";

@Component({
  selector: 'app-add-page-link',
  templateUrl: './add-page-link.component.html',
  styleUrls: ['./add-page-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddPageLinkComponent implements OnInit {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    formData: any;
    dialogTitle: string = "Add Pages Link";
    dialogRef: any;
    pageLink:any;
    pageLinkList: any[] = [];
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    //pagesLinkList:any;
    constructor(
        public matDialogRef: MatDialogRef<AddPageLinkComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        public _pageService: PageService
    ){
        this.dialogTitle = this._data.dialogTitle;
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
        this.PagesList();
    }
    ngAfterViewInit(): void {  
    }

    selectedPagesLink(event) {
        this.pageLink=event.value;
    }
    PagesList(){
        this._pageService.getAllPageBuilderUrl().subscribe(response => {
            this.pageLinkList = response['data'];
         });
    }
    addPageLink() {
        this.matDialogRef.close(this.pageLink);
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("add-pages-link-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("add-pages-link-content").style.maxHeight = "65vh";
        document.getElementById("add-pages-link-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("add-pages-link-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("add-pages-link-content").style.maxHeight = "100vh";
        document.getElementById("add-pages-link-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }

}
