import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ApiLogsService } from 'app/main/pages/api-logs/api-logs.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment-timezone";
import * as _ from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fuseAnimations } from '@fuse/animations';
import { ViewMessageDialogComponent } from '../dialogs/view-message/view-message.component';
@Component({
  selector: 'app-campaign-api-log',
  templateUrl: './campaign-api-log.component.html',
  styleUrls: ['./campaign-api-log.component.scss'],
  animations: [
    trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    fuseAnimations
]
})
export class CampaignApiLogComponent implements OnInit {
  hostHeight: any;
  hostWidth: any;
  IsDialogMax: boolean = false;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  List: any = [];
  pageNumber = 1;
  pageSize = 100;
  sortBy: string = "id";
  sortDirection: string = "desc";
  @ViewChild(FusePerfectScrollbarDirective, { static: false })
  directiveScroll: FusePerfectScrollbarDirective;
  dialogRef:any;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['apiName', 'companyName', 'requestDateTime', 'responseDateTime', 'reRunAPI', 'status'];
  expandedElement: any = {};
  userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
  filterForm: FormGroup;
  CampaignId:any;
  selectedRow: any;
  constructor(
    public matDialogRef: MatDialogRef<CampaignApiLogComponent>,
    private _apiLogsService: ApiLogsService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _toastr: ToastrService,
    private _matDialog: MatDialog,
    private _formBuilder: FormBuilder,
  ) { 
    this.filterForm = this._formBuilder.group({
      searchByFromDate: [''],
      searchByToDate: [''],
      accounttypes: [[]],
      groupType: [0],
      errortype: [0],
      companyName: '',
      eventName: [],
      searchText: '',
  });
  }

  ngOnInit() {
    this.CampaignId = this._data.CampData.id;
    this.GetPublicApiLogs();
  }
  onSearch(event) {
    this.pageNumber = 1;
    this.pageSize = 100;
    this.List = [];

    this.GetPublicApiLogs();
  }
  onRefreshList() {
    this.List = [];
    this.pageNumber = 1;
    this.pageSize = 100;
    this.directiveScroll.scrollToTop();
    this.filterForm.patchValue({
        companyName: '',
        searchByFromDate: '',
        searchByToDate: '',
        errortype: 0,
        eventName: [],
        searchText: ''
    });
    this.GetPublicApiLogs();
  }
  GetPublicApiLogs() {
    let req = {
        campaignId: this.CampaignId ? this.CampaignId : 0,
        companyName: '',
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        from: this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).local().format("YYYY-MM-DD") : '',
        to: this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).local().format("YYYY-MM-DD") : '',
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        type: this.filterForm.get('errortype').value,
        searchText: '',
        eventNames: ''

    };
    this._fuseSplashScreenService.show();
    this._apiLogsService.GetPublicApiLogs(req).subscribe((res: any) => {
        if (res.status == 200) {
            this._fuseSplashScreenService.hide();
            if (res.data.report.length > 0) {
                let temp_list = [];
                res.data.report.forEach((obj: any) => {
                    this.expandedElement[obj.id] = false;
                    const found = _.find(this.List, function (t) { return t.id == obj.id });
                    if (found == undefined || found == null) {
                        obj.apiRequest = JSON.stringify(obj.apiRequest).replace(/\\/g, "");
                        if (obj.errorDescription) {

                            obj.errorDescription = JSON.stringify(obj.errorDescription).replace(/\\/g, "");
                        }

                        temp_list.push(obj);
                    }
                });
                Array.prototype.push.apply(this.List, temp_list);
                this.dataSource = new MatTableDataSource(this.List);
            }
            else {
                this._fuseSplashScreenService.hide();
                this.dataSource = new MatTableDataSource(this.List);

            }
        }
        else {
            this._fuseSplashScreenService.hide();
            this.List = [];
            this.dataSource = new MatTableDataSource(this.List);
        }
    },
        error => {
            this._fuseSplashScreenService.hide();
            console.log(error);
            //this._toastr.error(error.error.error, 'Error');
        }
    );
  }

  viewDetail(message): void {
    let apiReq = message.apiRequest == 'null' ? '' : message.apiRequest;
    let apiReq2 = JSON.parse(JSON.stringify(apiReq));
    let formattedApiReq = '',
    f = {
        brace: 0
    };
    formattedApiReq = apiReq2.replace(/['"]+/g, '').replace(/({|}[,]*|[^{}:]+:[^{},]*[,{]*)/g, function (m, p1) {
        let rtnFn = function() {
                return '<div class="api-text" style="text-indent: ' + -(f['brace'] * 20) + 'px; padding-left: ' +(f['brace'] * 40)+ 'px;">' + p1 + '</div>';
            },
            rtnStr;
        if (p1.lastIndexOf('{') === (p1.length - 1)) {
            rtnStr = rtnFn();
            f['brace'] += 1;
        } else if (p1.indexOf('}') === 0) {
                f['brace'] -= 1;
            rtnStr = rtnFn();
        } else {
            rtnStr = rtnFn();
        }
        return rtnStr;
    });

    let beautifiedReq = formattedApiReq.replace(/[,]/g, '<br>').replace(/[\])}[{(|,]+/g, ' ').replace(/(\w+(?=:))/g, function(m) {
        if( m == 'indent' || m == 'left' || /^https/.test(m) || /\b[0-9]{2,}/.test(m)) {
            return m;
        }
        return `<b>${m}</b>`
    })
    // .replace(/(?<!https):/g, ' : ');
    this.selectedRow = message.id;
    this.dialogRef = this._matDialog.open(ViewMessageDialogComponent, {
        panelClass: 'view-message',
        data: {
            message: `<div class="Responselog"><h1>Api Request</h1><p class="Resdesc">${beautifiedReq}</p>` +
                `<h2>API Response</h2><p class="Resdesc">${message.response}</p>` +
                (message.errorDescription ? `<div><h2>Error Description</h2> <p class="Resdesc"> ${message.errorDescription}</p></div>` : '') +
                    (message.status ? `<div *ngIf="report.status"><h2>Status</h2><p class="Resdesc">${message.status}</p></div>` : '') +
            `</p></div>`,
            mediaURL: '',
            dialogTitle: 'API Logs'
        }
    });
    if (this.dialogRef) {
    }
    this.dialogRef.afterClosed()
        .subscribe(response => {
            if (!response) {
                return;
            }
        });
  }
  sortData(event) {
    this.List = [];
    this.pageNumber = 1;
    this.sortBy = event.active;
    this.sortDirection = event.direction;
    this.directiveScroll.scrollToTop();
    this.GetPublicApiLogs();
  }
  onScrollDown(ev) {
  if(this.dataSource.filteredData.length< this.List.length) {
    this.pageNumber += 1;
    this.GetPublicApiLogs();
  } 
  }
  ReRunAPI(id) {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to run again this request?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
            this._fuseSplashScreenService.show();
            this._apiLogsService.ReRunAPI(id, false).subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success('Process Started', 'Success');
                }
            },
                (error) => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error.error, 'Error');
                })
        }
        this.confirmDialogRef = null;
    });
  }

  minimizeDialog() {
    let elements: any = document.getElementsByClassName("campaign-apiLogs-details");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("campaignApiLogDetail-content").style.maxHeight = "65vh";
    document.getElementById("campaignApiLogDetail-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("campaign-apiLogs-details");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("campaignApiLogDetail-content").style.maxHeight = "100vh";
    document.getElementById("campaignApiLogDetail-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
