import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AccountType } from 'app/main/pages/accounts/account.model';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { PageService } from 'app/main/pages/pages.service';
import { ProvidersService } from '../../../../main/pages/providers/providers.service';
import { MailService } from 'app/main/pages/mail/mail.service';
import Bee from 'bee-plugin'
import { addTinyUrlDialogComponent } from '../add-tiny-url/add-tiny-url.component';
import { CommonNameDialogComponent } from '../common-name/common-name.component';
import { emailTemplateCatalogDialogComponent } from '../email-template-catalog/email-template-catalog.component';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { SaveRowComponent } from '../save-row/save-row.component';
import { TestContactDialogComponent } from '../test-contact/test-contact.component';
import { ViewBrowserNameDialogComponent } from '../../view-browser-name-dialog/view-browser-name.component';
import { AddMergeFieldComponent } from '../add-merge-field/add-merge-field.component';
import { AddMediaComponent } from '../add-media/add-media.component';
@Component({
    selector: 'template-dialog',
    templateUrl: './template-dialog.component.html',
    styleUrls: ['./template-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TemplateDialogComponent {
    hostHeight: any;
    hostWidth: any;
    IsDialogMax:boolean =false;
    allNotificationType:any;
    addTemplateAsJson: boolean = false;
    action: string;
    dialogTitle: string;
    emailLinks: any[] = [];
    beeLinks: any[] = [];
    dialogRef: any;
    isSMSMessageTop: boolean = true;
    public showExtraToFields: boolean;
    public composeForm: FormGroup;
    public accountTypes: AccountType[];
    private _unsubscribeAll: Subject<any>;
    public isemail: boolean;
    public userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    public allGroupsList: any[] = [];
    public replyContact: boolean = false;
    scrollStart: boolean = true;
    emailEditorConfig: any;
    notificationTypeName: any;
    notificationId:any;
    IsIFrameMax: boolean = false;
    rowData: any[] = [];
    selectType:any;
    beeEmailTemplates: any[] = [];
    viewBrowserLink: any = '';
    mergeField: FormControl = new FormControl();
    @ViewChild('composeEmailEditor', null) ckeditor: any;
    tinymceEditor: any;
    config: any = {
        height: 350,
        menubar: true,
        plugins: [
            'advlist autolink lists link charmap print hr preview',
            'searchreplace wordcount visualblocks visualchars advcode',
            'insertdatetime nonbreaking save table directionality',
            'powerpaste textpattern help',
            'fullpage tinymcespellchecker a11ychecker'
        ],
        toolbar1: 'undo redo | print custpreview | cut copy paste insert link | fontselect styleselect fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | spellchecker | code | a11ycheck',
        image_advtab: true,
        relative_urls: false,
        remove_script_host: false,
        image_list: [],
        powerpaste_allow_local_images: true,
        powerpaste_word_import: 'prompt',
        powerpaste_html_import: 'prompt',
        spellchecker_language: 'en',
        spellchecker_dialog: true,
        menu: {
            file: { title: 'File', items: 'print' },
            edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
            view: { title: 'View', items: 'code | visualaid visualchars visualblocks | preview fullscreen' },
            insert: { title: 'Insert', items: 'insertImage link | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats blockformats fontformats fontsizes align | removeformat' },
            table: { title: 'Table', items: 'inserttable tableprops deletetable row column cell' }
        },
        setup: (editor) => {
            this.tinymceEditor = editor;
        },
        content_style: "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Lato', sans-serif; }",
        font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats"
    };
    mediaList: any[] = [];
    beeEditor: any = new Bee();
    beeToken: any = JSON.parse(localStorage.getItem("BeeToken"));
    isShowBeeTemplates: boolean;
    isSaveBeeTemplates: boolean;
    constructor(
        public matDialogRef: MatDialogRef<TemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
        public _providersService: ProvidersService,
        private _pageService: PageService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        private _mailService: MailService,
        private _campaignsService: CampaignsService,
        public _ProvidersService: ProvidersService,
    ) {
        this.isShowBeeTemplates = this._pageService.checkPermissions('show-bee-templates');
        this.isSaveBeeTemplates = this._pageService.checkPermissions('save-bee-templates');
        this.action = this._data.action;
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Template';
        }
        else {
            this.dialogTitle = 'Add Template';
        }
        // Set the defaults
        this.composeForm = this.createComposeForm();
        this.showExtraToFields = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._pageService.SlectMediaChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {            
            if(val) {
                this.onSlectMediaChange();
            }
        });
    }

    ngOnInit(): void {
        this.getTemplates();
        if (this.beeToken) {
            this.getBeeRowList();
            this.beeEditor = new Bee(this.beeToken);
        }
        else {
            this.getBeeRowList();
            this.beeEditor.getToken('89c6b4fd-09fb-4bd4-9deb-f22065e3a5b5', 'xl0SgQ9nwQeGw4LX9P7E5tOclxfhbE3gmJkkZ9YKd0yXJPVp0QzP').then((token) => {
                this.beeEditor = new Bee(token);
                localStorage.setItem("BeeToken", JSON.stringify(token));
            });
        }
        this.composeForm.get('isDragDropEditor').valueChanges.subscribe((event) => {
            if (this.action !== 'edit') {
                this.composeForm.patchValue({
                    emailEditor: "",
                    messageJson: this._pageService.defaultTemplate
                });
                this.emailLinks = [];
                this.beeLinks = [];
            }
            if (event) {
                this.initBeeEditor();
            }
        });
        this._pageService.getMediaFiles('');
        this._pageService.onMediaFilesChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(list => {
            this.mediaList = list;
        });
        this.emailEditorConfig = {
            title: false
        };
        if (this._data.role == 'admin') {
            this._pageService.getAccountsTypes();
            this._pageService.onAccountTypeChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(types => {
                    this.accountTypes = this._pageService.allAccountTypes.filter(obj => (obj.id != 7 && obj.id != 8 && obj.id != 11 && obj.id != 14));
                    if (this._data.typeId) {
                        this.composeForm.controls['accounttypes'].patchValue(this._data.typeId);
                    }
                });
        }
        else {
            if (this.action === 'edit' && this._data.template.provider) {
                this._pageService.getAccountsTypes();
                this._pageService.onAccountTypeChanged
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(types => {
                        this.accountTypes = this._pageService.allAccountTypes;
                        this.composeForm.controls['accounttypes'].patchValue(this._data.template.unifiedTypeId);
                        this.composeForm.disable();
                    });
            }
            else {
                this._pageService.getAccountsTypesByUser();
                this._pageService.onAccountTypeByUserChanged
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(types => {
                        if (types) {
                            this.accountTypes = types
                        }
                        else {
                            this.accountTypes = [];
                        }
                    });
            }

        }
        if (this.action === 'edit') {
            this.getTemplateById(this._data.template.id);
            this.composeForm.get('accounttypes').valueChanges.subscribe((event) => {
                if(event == 1 || event == 21) {
                    this.initBeeEditor();
                }

            });
        }
        this.composeForm.get('newIsDragDropEditor').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
            if(!val) {
                this.composeForm.get('isDragDropEditor').setValue(true);
            }else if(val) {
                this.composeForm.get('isDragDropEditor').setValue(false);
            }
        });
        this.composeForm.get('accounttypes').valueChanges
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((types) => {
            if(!this.composeForm.get('newIsDragDropEditor').value && (types == 1 || types == 21)) {
                this.composeForm.get('isDragDropEditor').setValue(true);
            }else if(this.composeForm.get('newIsDragDropEditor').value && (types == 1 || types == 21)) {
                this.composeForm.get('isDragDropEditor').setValue(false);
            }
          this.accountChange(types);
        });
        this._pageService.SlectionMergeFieldChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
            if(val) {
                this.onMergeField(this.mergeField.value);
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getTemplateById(id: number) {
        this._fuseSplashScreenService.show();
        this._providersService.getTemplateById(id)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    if (res.data.unifiedTypeId == 1 || res.data.unifiedTypeId == 21 ) {
                        this.composeForm.patchValue({
                            isDragDropEditor: res.data.isBeeEditor ? res.data.isBeeEditor : false,
                            templateName: res.data.name,
                            subject: res.data.subject,
                            message: '',
                            emailEditor: res.data.messageText,
                            notificationMessage: '',
                            optOutTrailor: 'Reply STOP to stop',
                            allNotificationType: '',
                            messageJson: res.data.messageJson ? JSON.parse(res.data.messageJson) : '',
                            accounttypes: res.data.unifiedTypeId,
                            newIsDragDropEditor: (res.data.isBeeEditor && res.data.isBeeEditor == true) ? false : true
                        });
                    }
                    else if (res.data.unifiedTypeId == 9 || res.data.unifiedTypeId == 18 || res.data.unifiedTypeId == 19) {
                        this.composeForm.patchValue({
                            templateName: res.data.name,
                            allNotificationType: res.data.subject,
                            message: '',
                            emailEditor: '',
                            notificationMessage: res.data.messageText,
                            accounttypes: res.data.unifiedTypeId,
                            optOutTrailor: 'Reply STOP to stop'
                        });
                    }
                    else {
                        this.composeForm.patchValue({
                            templateName: res.data.name,
                            subject: res.data.subject,
                            message: res.data.messageText,
                            emailEditor: '',
                            notificationMessage: '',
                            accounttypes: res.data.unifiedTypeId,
                            optOutTrailor: res.data.optOutTrailor,
                            mediaURL: res.data.mediaURL,
                            allNotificationType: ''
                        });
                    }
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    createComposeForm(): FormGroup {
        return new FormGroup({
            templateName: new FormControl('', Validators.required),
            subject: new FormControl(''),
            message: new FormControl(''),
            emailEditor: new FormControl(''),
            accounttypes: new FormControl('', Validators.required),
            optOutTrailor: new FormControl('Reply STOP to stop'),
            mediaURL: new FormControl(''),
            messageJson: new FormControl({}),
            isBeeEditor: new FormControl(false),
            isBeeTemplateUpload:new FormControl(false),
            notificationMessage: new FormControl(''),
            allNotificationType:  new FormControl(''),
            isDragDropEditor: new FormControl(false),
            newIsDragDropEditor: new FormControl(false),
        });
    }
    updateBeeEditor (event) {
        if (event.source.name == "isBeeEditor" && event.checked) {
            this.composeForm.get("isBeeEditor").setValue(event.checked);
            this.composeForm.get("isBeeTemplateUpload").setValue(!event.checked);
        } else if(event.source.name == "isBeeTemplateUpload" && event.checked)
        {
            this.composeForm.get("isBeeTemplateUpload").setValue(event.checked);
            this.composeForm.get("isBeeEditor").setValue(!event.checked);
        }   
    }
    saveTemplate() {
        if (this.composeForm.invalid) {
            if( this.composeForm && this.composeForm.get('mediaURL').invalid) {
                this._toastr.warning('Please add a media file!')
            }
            this.validateAllFormFields(this.composeForm);
            return false;
        }
        var FormData = this.composeForm.getRawValue();
        let subject = '';
        let messageText = FormData.message;
        if (FormData.accounttypes == 1 || FormData.accounttypes == 21) {
            subject = FormData.subject;
            messageText = FormData.emailEditor;
        }
        if (FormData.accounttypes == 9 || FormData.accounttypes == 18 || FormData.accounttypes == 19) { 
            subject = FormData.accounttypes == 20 ? '' : FormData.allNotificationType;
            messageText = FormData.notificationMessage; 
        }
        let req: any = {
            "name": FormData.templateName,
            "unifiedTypeId": FormData.accounttypes,
            "subject": subject,
            "messageText": messageText,
            "provider": this._data.providerId ? this._data.providerId : null,
            "mediaURL": FormData.mediaURL,
            "notificationTypeId":0,
            "messageJson": FormData.messageJson ? JSON.stringify(FormData.messageJson) : '',
            "isBeeEditor": FormData.isDragDropEditor,
            "optOutTrailor": FormData.optOutTrailor
        };
        if (this.action === 'edit') {
            req = {
                "name": FormData.templateName,
                "unifiedTypeId": FormData.accounttypes,
                "subject": subject,
                "messageText": messageText,
                "companyId": this._data.template.companyId,
                "id": this._data.template.id,
                "provider": this._data.template.provider,
                "mediaURL": FormData.mediaURL,
                "notificationTypeId":0,
                "isBeeEditor": FormData.isDragDropEditor,
                "messageJson": FormData.messageJson ? JSON.stringify(FormData.messageJson) : '',
                "optOutTrailor": FormData.optOutTrailor,
            };
        }
        if(this._data.isPredefined) {
            req.isPredefined = this._data.isPredefined;
        }
        if(this._data && this._data.pageName == 'companyApprovedTemplate') {
            req.approved = true;
            req.companyId = this._data.companyId;
        }else {
            req.approved = false;
        }
        this._fuseSplashScreenService.show();
        this._providersService.SaveTemplate(req)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(['save', res.data])
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    if (error.error.error) {
                        this._toastr.error(error.error.error, 'Error');
                    }
                    else {
                        this._toastr.error(error.error, 'Error');
                    }
                }
            );
    }
    /**
     * Toggle extra to fields
     */
    accountChange(account) {
        if (account == 1 || account == 21) {
            this._pageService.addValidators(this.composeForm, "emailEditor");
            this._pageService.addValidators(this.composeForm, "subject");
        }
        else {
            this.removeValidators(this.composeForm, "emailEditor");
            this.removeValidators(this.composeForm, "subject");
        }
        if ((account == 22) || (account == 2) || (account == 3) || (account == 4) || (account == 5) || (account == 6) || (account == 11) || (account == 13) || (account == 14) || (account == 18)) {
            this._pageService.addValidators(this.composeForm, "message");
        }
        else {
            this.removeValidators(this.composeForm, "message");
        }

        if (account == 7 || account == 12 || account == 16 || account == 23) {
            this.composeForm.controls['mediaURL'].patchValue('');
            this._pageService.addValidators(this.composeForm, "mediaURL");
        }
        else {
            this.composeForm.controls['mediaURL'].patchValue('');
            this.removeValidators(this.composeForm, "mediaURL");
        }
        if (account == 9 || account == 18 || account == 19) {
            this._pageService.addValidators(this.composeForm, "notificationMessage");
            this._pageService.addValidators(this.composeForm, "allNotificationType");
        }
        else {
            this.removeValidators(this.composeForm, "notificationMessage");
            this.removeValidators(this.composeForm, "allNotificationType");
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control: any = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else {
                this.validateAllFormFields(control);
            }
        });
    }

    removeValidators(form: FormGroup, formControl: any) {
        form.get(formControl).clearValidators();
        form.get(formControl).updateValueAndValidity();
    }


    addValidators(form: FormGroup, formControl: any) {
        form.get(formControl).setValidators([Validators.required]);
        form.get(formControl).updateValueAndValidity();
    }
    onMergeField(event) {
        if (this.composeForm.get('accounttypes').value == 1 || this.composeForm.get('accounttypes').value == 21) {
            if (document.getElementById('emailEditor')) {
                this.tinymceEditor.insertContent(event);
            }
        }
        else if (this.composeForm.get('accounttypes').value == 9 || this.composeForm.get('accounttypes').value == 18 || this.composeForm.get('accounttypes').value == 19) {
            if (document.getElementById('notificationMessage')) {
                this.tinymceEditor.insertContent(event);
            }
        }
        else {
            let mobileElement = document.getElementById('message');
            if (mobileElement) {
                this.insertAtCursor(mobileElement, event, 'message');
            }
        }
    }
    insertAtCursor(myField, myValue, key) {
        // Microsoft Edge
        if (window.navigator.userAgent.indexOf("Edge") > -1) {
            var startPos = myField.selectionStart;
            var endPos = myField.selectionEnd;

            myField.value = myField.value.substring(0, startPos) + myValue
                + myField.value.substring(endPos, myField.value.length);

            var pos = startPos + myValue.length;
            myField.focus();
            myField.setSelectionRange(pos, pos);
        }
        //MOZILLA and others
        else if (myField.selectionStart || myField.selectionStart == '0') {
            var startPos = myField.selectionStart;
            var endPos = myField.selectionEnd;
            myField.value = myField.value.substring(0, startPos)
                + myValue
                + myField.value.substring(endPos, myField.value.length);
        } else {
            myField.value += myValue;
        }
        this.composeForm.controls[key].patchValue(myField.value);
    }
    saveAsBeeTemplate() {
        var FormData = this.composeForm.getRawValue();
        let subject = '';
        let messageText = '';
        let req: any = {
            "name": FormData.templateName,
            "unifiedTypeId": FormData.accounttypes,
            "subject": subject,
            "messageText": FormData.message,
            "provider": this._data.providerId ? this._data.providerId : null,
            "mediaURL": FormData.mediaURL,
            "messageJson": FormData.messageJson,
            "isBeeEditor": true,
            "isPredefined": true
        };
        this._fuseSplashScreenService.show();
        this._providersService.SaveTemplate(req)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(['save', res.data])
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    if (error.error.error) {
                        this._toastr.error(error.error.error, 'Error');
                    }
                    else {
                        this._toastr.error(error.error, 'Error');
                    }
                }
            );
    }
    initBeeEditor() {
        if (document.getElementById('bee-plugin-container')) {
            document.getElementById('bee-plugin-container').innerHTML = '';
        }
        if ((this.composeForm.get('accounttypes').value == 1 || this.composeForm.get('accounttypes').value == 21) && this.composeForm.get('isDragDropEditor').value) {
            this._fuseSplashScreenService.show();
            var _matDialog = this._matDialog;
            var that = this;
            var beeConfig = {
                uid: this.userDetails.companyKey.replace(/\s/g, ''), //new approch
                //uid: this.beeEditor.token.userName, //needed for identify resources of the that user and billing stuff
                container: 'bee-plugin-container', //Identifies the id of div element that contains BEE Plugin
                language: 'en-US',
                trackChanges: true,
                mergeTags: this._pageService.mergeTags,
                specialLinks: this._pageService.specialLinks,
                onSaveRow: async (rowJSON, rowHTML, pageJSON) => {
                    await this.setRow(rowJSON);
                },
                rowsConfiguration: {
                    emptyRows: true,
                    defaultRows: true,
                    externalContentURLs: [{
                        name: "All rows",
                        value: "All rows",
                        handle: 'All rows',
                        isLocal: true,
                        behaviors: {
                            canEdit: true,
                            canDelete: true,
                        },
                    },
                    {
                        name: "Header rows",
                        value: "Header rows",
                        handle: 'Header rows',
                        isLocal: true,
                        behaviors: {
                            canEdit: true,
                            canDelete: true,
                        },
                    },
                    {
                        name: "Footer rows",
                        value: "Footer rows",
                        handle: 'Footer rows',
                        isLocal: true,
                        behaviors: {
                            canEdit: true,
                            canDelete: true,
                        },
                    }]
                },
                hooks: {
                    getRows: {
                        handler: async (resolve, reject, args) => {
                            const rows = await this.getRows(args.handle)
                            resolve(rows)
                        }
                    }
                },
                editorFonts: {
                    showDefaultFonts: true,
                    customFonts: [
                        {
                            name: "Playfair Display",
                            fontFamily: "'Playfair Display', serif",
                            url: "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;900&display=swap"
                        },{
                            name: "Indie Flower",
                            fontFamily: "'Indie Flower', cursive",
                            url: "https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap"
                          },{
                            name: "Montserrat Medium",
                            fontFamily: "'Montserrat', sans-serif",
                            url: "https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
                        },{
                            name: "Abril Fatface",
                            fontFamily: "'Abril Fatface', cursive",
                            url: "https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap"
                        },{
                            name: "Comfortaa",
                            fontFamily: "'Comfortaa', cursive",
                            url: "https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap"
                        },{
                            name: "Raleway",
                            fontFamily: "'Raleway', sans-serif",
                            url: "https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap",
                            fontWeight: {
                                200: 'Extra-light',
                                300: 'Light',
                                400: 'Regular',
                                500: 'Medium',
                                600: 'Semi-bold',
                                700: 'Bold',
                              }
                        },{
                            name: "Oswald",
                            fontFamily: "'Oswald', sans-serif",
                            url: "https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap",
                            fontWeight: {
                              200: 'Extra-light',
                              300: 'Light',
                              400: 'Regular',
                              500: 'Medium',
                              600: 'Semi-bold',
                              700: 'Bold',
                            }
                        },{
                            name: "FuturaPTLight",
                            fontFamily: "'FuturaPTLight'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_light.css"
                          },
                          {
                            name: "FuturaPTExtraBold",
                            fontFamily: "'FuturaPTExtraBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_extra_bold.css"
                          },
                          {
                            name: "FuturaPTBold",
                            fontFamily: "'FuturaPTBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_bold.css"
                          },
                          {
                            name: "FuturaPTBoldOblique",
                            fontFamily: "'FuturaPTBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_bold_oblique.css"
                          },
                          {
                            name: "FuturaPTBook",
                            fontFamily: "'FuturaPTBook'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_book.css"
                          },
                          {
                            name: "FuturaPTBookOblique",
                            fontFamily: "'FuturaPTBookOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_book_oblique.css"
                          },{
                            name: "FuturaPTCondBold",
                            fontFamily: "'FuturaPTCondBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBold.css"
                          },
                          {
                            name: "FuturaPTCondBoldOblique",
                            fontFamily: "'FuturaPTCondBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBold_oblique.css"
                          },
                          {
                            name: "FuturaPTCondBook",
                            fontFamily: "'FuturaPTCondBook'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBook.css"
                          },
                          {
                            name: "FuturaPTCondBookOblique",
                            fontFamily: "'FuturaPTCondBookOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondBook_oblique.css"
                          },
                          {
                            name: "FuturaPTCondExtraBold",
                            fontFamily: "'FuturaPTCondExtraBold'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_Condextra_bold.css"
                          },
                          {
                            name: "FuturaPTCondExtraBoldOblique",
                            fontFamily: "'FuturaPTCondExtraBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_Condextra_bold_oblique.css"
                          },
                          {
                            name: "FuturaPTCondMedium",
                            fontFamily: "'FuturaPTCondMedium'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondMedium.css"
                          },
                          {
                            name: "FuturaPTCondMediumOblique",
                            fontFamily: "'FuturaPTCondMediumOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_CondMedium_oblique.css"
                          },
                          {
                            name: "FuturaPTDemi",
                            fontFamily: "'FuturaPTDemi'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_demi.css"
                          },
                          {
                            name: "FuturaPTDemiOblique",
                            fontFamily: "'FuturaPTDemiOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_demi_oblique.css"
                          },
                          {
                            name: "FuturaPTExtraBoldOblique",
                            fontFamily: "'FuturaPTExtraBoldOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_extra_bold_oblique.css"
                          },
                          {
                            name: "FuturaPTHeavy",
                            fontFamily: "'FuturaPTHeavy'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_heavy.css"
                          },{
                            name: "FuturaPTHeavyOblique",
                            fontFamily: "'FuturaPTHeavyOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_heavy_oblique.css"
                          },
                          {
                            name: "FuturaPTLightOblique",
                            fontFamily: "'FuturaPTLightOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_light_oblique.css"
                          },
                          {
                            name: "FuturaPTMedium",
                            fontFamily: "'FuturaPTMedium'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_medium.css"
                          },
                          {
                            name: "FuturaPTMediumOblique",
                            fontFamily: "'FuturaPTMediumOblique'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/futura_pt_medium_oblique.css"
                          },
                          {
                            name: "Mont-ExtraLight",
                            fontFamily: "'Mont-ExtraLight'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/Mont-ExtraLight.css"
                          },
                          {
                            name: "Mont Heavy",
                            fontFamily: "'Mont Heavy DEMO'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/Mont-HeavyDEMO.css"
                          },
                          {
                            name: "Montserrat-Medium",
                            fontFamily: "'Montserrat-Medium'",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/montserrat_medium.css"
                          },
                          {
                            name: "Lobester",
                            fontFamily: "'Lobster', cursive",
                            url: "https://ziplingods.wsicloud.net/assets/fontCss/lobester.css"
                          }
                    ]
                },
                contentDialog: {
                    specialLinks: {
                        label: 'Link Tracking',
                        handler: function (resolve, reject) {
                            this.dialogRef = _matDialog.open(addTinyUrlDialogComponent, {
                                panelClass: 'tiny-url-dialog',
                                width: '600px',
                                height: '350px',
                            });
                            this.dialogRef.afterClosed()
                                .subscribe(response => {
                                    if (response) {
                                        let linkIndex: any = that.beeLinks.length + 1;
                                        var url = {
                                            type: 'custom',
                                            label: '[Link' + linkIndex + ']',
                                            link: response
                                        }
                                        that.beeLinks.push({
                                            "name": '[Link' + linkIndex + ']',
                                            "url": response
                                        });
                                        //that.onAddTinyUrl(response);
                                        resolve(url);
                                    } else {
                                        reject();
                                    }
                                });
                        }
                    },
                    saveRow: {
                        handler: async function (resolve, reject, args) {
                            that.dialogRef = await _matDialog.open(SaveRowComponent, {
                                panelClass: 'saveRow-dialog',
                                disableClose: true,
                                data: {
                                    pageName: "saveRowPage",
                                    args: args,
                                    dialogTitle: "Save Row",
                                    action: 'add',
                                    rowData: that.rowData
                                }
                            });
                            that.dialogRef.afterClosed()
                                .subscribe((response: any) => {
                                    if (!response) {
                                        reject('')
                                        return;
                                    }
                                    const metadata = {
                                        name: response.journeyName,
                                        category: response.category
                                    }
                                    resolve(metadata);
                                });
                        }
                    },
                    onEditRow: {
                        handler: async function (resolve, reject, args) {
                            that.dialogRef = await _matDialog.open(SaveRowComponent, {
                                panelClass: 'saveRow-dialog',
                                disableClose: true,
                                data: {
                                    pageName: "saveRowPage",
                                    args: args,
                                    dialogTitle: "Edit Row",
                                    action: 'edit',
                                    // rowData: this.rowData
                                }
                            });
                            that.dialogRef.afterClosed()
                                .subscribe((response: any) => {
                                    if (!response) {
                                        return;
                                    }
                                    if (response) {
                                        that.handleEditRow(args, response)
                                        resolve(true)
                                    } else {
                                        reject('')
                                    }
                                });
                        }
                    },
                    onDeleteRow: {
                        handler: async (resolve, reject, args) => {
                            await that.handleDeleteRow(args)
                            resolve(true)
                        }
                    },
                },
                onSave: (jsonFile, htmlFile) => {
                    this.composeForm.patchValue({
                        emailEditor: htmlFile,
                        messageJson: JSON.parse(jsonFile)
                    });
                },
                onSaveAsTemplate: (jsonFile) => {
                    this.composeForm.patchValue({
                        messageJson: JSON.parse(jsonFile)
                    });
                    that.saveAsTemplate();
                },
                onChange: (jsonFile, response) => {
                    this.composeForm.patchValue({
                        messageJson: JSON.parse(jsonFile)
                    });
                    this.beeEditor.instance.send();
                },
                onSend: (htmlFile) => {
                    this.composeForm.patchValue({
                        emailEditor: htmlFile
                    });
                },
                onError: (errorMessage) => { console.log("onError", errorMessage); },
                onWarning: (alertMessage) => { console.log("onError", alertMessage); }
            }
            this.beeEditor.start(beeConfig, this.composeForm.get('messageJson').value);
            // this.isShowAdvance = true;
            this._fuseSplashScreenService.hide();
        }
    }
    uploadedThumbnail(ev) {
        this._fuseSplashScreenService.show();
        var file = ev.target.files[0];
        var formData = new FormData();
        formData.append('formFile', file);
        this._providersService.UploadMediaFile(formData, null).subscribe(
            (res: any) => {
                this.composeForm.get('mediaURL').setValue(res.data);
                this._fuseSplashScreenService.hide();
            }, error => {
                this._fuseSplashScreenService.hide();
                this._toastr.error(error.error, 'Error');
              }
        )
    }
    fullScreenFrame() {
        this.IsIFrameMax = !this.IsIFrameMax;
    }
    onSlectMediaChange() {
        if(!this.composeForm.get('message').value) {
            this.isSMSMessageTop = false;
        }
    }
    setRow = async function (row) {
        this.addEditBeeRows(row, 'add');
      }
      getRows = async handle => {
        var selctedCategory =  this.rowData.filter((row)=> (row.metadata.category == handle));
        return (handle && handle =="All rows") ? this.rowData : selctedCategory;
      }
      handleEditRow = async (args, response) => {
        this.rowData.forEach(row => {
          if (row.metadata.name === args.row.metadata.name) {
            row.metadata.name = response.journeyName;
            this.addEditBeeRows(row, "edit");
            return;
          }
    
        });
      }
      handleDeleteRow = async function(args) {
         this.rowData.forEach((row, index) => {
          if (row.metadata.name === args.row.metadata.name) {
            var id = row.id;
            delete (this.rowData[index]);
            this.deleteRow(id);
          }
    
        });
      }
      getBeeRowList() {
        this._campaignsService.GetBeeRowList()
          .subscribe((res: any) => {
            if (res.status == 200) {
              var dataRow = res.data;
              this.rowData = [];
              if(dataRow.length>0) {
                dataRow.forEach(element => {
                  let row = JSON.parse(element.rowData);
                  row.id = element.id;
                  this.rowData.push(row);
                });
              }
            } else {
              this._toastr.error(res.error, 'Error');
            }
          },
            error => {
              this._toastr.error(error.error, 'Error');
            }
          );
      }
      addEditBeeRows(row, action) {
        var addReq;
        if(action == "edit") {
          addReq = {
            "id": row.id,
            "companyId": this.userDetails.companyId,
            "rowData": JSON.stringify(row)
          }
        }else if(action == "add") {
          addReq = {
            "companyId": this.userDetails.companyId,
            "rowData": row
          }
        }
        this._campaignsService.AddEditBeeRows(addReq)
          .subscribe((res: any) => {
            if (res.status == 200) {
              this._toastr.success((action == "edit" ? "Row Edit successfully" : "Row added successfully"), 'Success');
              this.getBeeRowList();
            } else {
              this._toastr.error(res.error, 'Error');
            }
          },
            error => {
              this._toastr.error(error.error, 'Error');
            }
          );
      }
      deleteRow(id) {
        this._campaignsService.DeleteBeeRow(id)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.getBeeRowList();
            this._toastr.success("Row deleted successfully", 'Success');
          } else {
            this._toastr.error(res.error, 'Error');
          }
        },
          error => {
            this._toastr.error(error.error, 'Error');
          }
        );
      }
      saveAsTemplate() {
        this.dialogRef = this._matDialog.open(CommonNameDialogComponent, {
            panelClass: 'CommonName',
        });
        this.dialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            if (response) {
               if(this.selectType == 1 || this.selectType == 21) {
                var commonForm = response.getRawValue();
                var FormData = this.composeForm.getRawValue();
                let req: any = {
                    "name": commonForm.name,
                    "unifiedTypeId": FormData.accounttypes.value,
                    "subject": FormData.subject,
                    "messageText": FormData.emailEditor ? FormData.emailEditor : "",
                    "provider": null,
                    "mediaURL": commonForm.mediaUrl,
                    "messageJson": JSON.stringify(FormData.MessageJson),
                    "isBeeEditor": true,
                    "isPredefined": false,
                };
                this._fuseSplashScreenService.show();
                this._ProvidersService.SaveTemplate(req)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this._fuseSplashScreenService.hide();
                        } else {
                            this._toastr.error(res.error, 'Error');
                            this._fuseSplashScreenService.hide();
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error) {
                                this._toastr.error(error.error, 'Error');
                            }
                            else {
                                this._toastr.error(error.error, 'Error');
                            }
                        }
                    );
               }
               if(this.selectType == 9) {
                var commonForm = response.getRawValue();
                var FormData = this.composeForm.getRawValue();
                let req: any = {
                    "name": commonForm.name,
                    "unifiedTypeId": 9,
                    "subject": FormData.allNotificationType,
                    "messageText": FormData.notificationMessage ? FormData.notificationMessage : "",
                    "provider": null,
                    "mediaURL": commonForm.mediaUrl,
                    "messageJson": JSON.stringify(FormData.NotificationJson),
                    "isBeeEditor": true,
                    "isPredefined": false,
                };
                this._fuseSplashScreenService.show();
                this._ProvidersService.SaveTemplate(req)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this._fuseSplashScreenService.hide();
                        } else {
                            this._toastr.error(res.error, 'Error');
                            this._fuseSplashScreenService.hide();
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error) {
                                this._toastr.error(error.error, 'Error');
                            }
                            else {
                                this._toastr.error(error.error, 'Error');
                            }
                        }
                    );
               }
            }
        });

    }
    emailTemplateCatalog() {
        this.dialogRef = this._matDialog.open(emailTemplateCatalogDialogComponent, {
            panelClass: 'emailTemplateCatalog',
            data: {
                emailTemplates: this.beeEmailTemplates,
                accTyped : 1
            }
        });
        this.dialogRef.afterClosed().subscribe(response => {

            if (!response) {
                return;
            }
            if (response) {
                if (!response.messageJson) {
                    this._toastr.error('Template content not found', 'Error');
                    return;
                }
                var messageJson = response.messageJson;
                var emailEditor = response.messageText;
                this.composeForm.controls['messageJson'].patchValue(JSON.parse(messageJson));
                this.composeForm.controls['emailEditor'].patchValue(emailEditor);
                this.composeForm.controls['subject'].patchValue(response.subject);
                this.initBeeEditor();
            }
        });
    }
    selectTemplate (type){
        this.selectType = type
    } 
    getTemplates() {
        this._fuseSplashScreenService.show();
        this._mailService.getAllTemplates()
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this.beeEmailTemplates = res.data.filter(x => ((x.unifiedTypeId == 1 || x.unifiedTypeId == 21) && x.isBeeEditor));
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    emailDataViewInBrowser(emailEditorData) {
        localStorage.setItem('emailEditorData', emailEditorData)
        window.open("/public/emailview");
    }
    viewBrowser() {
        this.dialogRef = this._matDialog.open(ViewBrowserNameDialogComponent,
            {
                panelClass: 'view-browser-dialog'
            }
        );
        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (response != undefined && response !=null) {
                this.generateViewBrowserLink(response);
            }
        });
    }
    generateViewBrowserLink(name) {
        var FormData = this.composeForm.getRawValue();
        let generateViewBrowserLinkReq: any = {
            "name": name,
            "messageText": FormData.emailEditor ? FormData.emailEditor : "",
        };
        this._fuseSplashScreenService.show();
        this._pageService.generateViewBrowserLink(generateViewBrowserLinkReq)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                   this.viewBrowserLink = res.data;
                } else {
                    this._toastr.error(res.error, 'Error');
                }
                },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error, 'Error');
                }
            );
    }
    copyText() {
        navigator.clipboard.writeText(this.viewBrowserLink);
        this._toastr.success('Copied!');
    }
    testContact(typeID) {
        var typed;
        if(typeID) {
            this.accountTypes.forEach(item => {
                if(item.id == typeID) {
                    typed = item.name;
                }
            });
        }
        var FormData = this.composeForm.getRawValue();
        if (typeID == 1 || typeID ==21) {
            if(!FormData.subject) {
                this.composeForm.get('subject').markAsTouched();
                this._toastr.warning('Please fill all required fields', 'Warning');
                return false
            }
            if(!FormData.emailEditor) {
                this.composeForm.get('emailEditor').markAsTouched();
                this._toastr.warning('Please fill all required fields', 'Warning');
                return false
            }
        }else if (typeID == 9 || typeID == 18 || typeID == 19) {
            if(!FormData.allNotificationType) {
                this.composeForm.get('allNotificationType').markAsTouched();
                this._toastr.warning('Please fill all required fields', 'Warning');
                return false
            }
            if(!FormData.notificationMessage) {
                this.composeForm.get('notificationMessage').markAsTouched();
                this._toastr.warning('Please fill all required fields', 'Warning');
                return false
            }
        }else if(typeID != 9 && typeID != 18 && typeID != 19 && typeID != 1) {
            if(!FormData.message) {
                this.composeForm.get('message').markAsTouched();
                this._toastr.warning('Please fill all required fields', 'Warning');
                return false
            }
        }
        this.dialogRef = this._matDialog.open(TestContactDialogComponent, {
            panelClass: 'testContact-dialog',
            width: '850px',
            height: '270px',
            data: {
                typed: typed,
                typeId: typeID,
                Form: this.composeForm,
                emailLinks: [],
                smsLinks: [],
                whatsappLinks: [],
                lineLinks: [],
                fbLinks: [],
                telegramLinks: [],
                shortCodeLinks: [],
                notificationLinks: [],
                internationalSMSMMSLinks: [],
                verbNotificationLinks: [],
                nowTechNotificationLinks: [],
                shoutLinks: [],
                pageName: 'template_dialog'
            }
        });
        this.dialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
        });
    }
    addMergeField() {
        this.dialogRef = this._matDialog.open(AddMergeFieldComponent, {
            panelClass: "add-merge-field-dialog",
            disableClose: true,
            data: {
                dialogTitle: "Add Merge Field",
                mergeField: this.mergeField
            },
        });
        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (!response) {
                return;
            }
        });
    }
    addMedia(field) {
        this.dialogRef = this._matDialog.open(AddMediaComponent, {
            panelClass: "add-media-dialog",
            disableClose: true,
            data: {
                dialogTitle: "Add Media",
            },
        });
        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (!response) {
                return;
            }
            this.composeForm.get(field).setValue(response);
        });
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("template-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width =  (this.hostWidth+1+'px');
            elements[i].style.height =  (this.hostHeight+1+'px');
        }
        document.getElementById("addnewtemplate-content").style.maxHeight = "60vh";
        document.getElementById("addnewtemplate-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("template-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("addnewtemplate-content").style.maxHeight = "75vh";
        document.getElementById("addnewtemplate-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
     }
}

