import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PartialSplashLoaderComponent } from './partial-splash-loader.component';

@NgModule({
    declarations: [
        PartialSplashLoaderComponent
    ],
    imports     : [
        CommonModule,
        RouterModule,
        FlexLayoutModule
    ],
    exports     : [
        PartialSplashLoaderComponent
    ],
    providers   : []
})
export class PartialSplashLoaderModule
{
}
