import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'addTo-menu',
    templateUrl: './addTo-menu.component.html',
    styleUrls: ['./addTo-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddToMenu implements OnInit, AfterViewInit {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    menuList: any[] = [];
    dialogTitle: string = "Menu List";
    dialogRef: any;
    menuId: FormControl = new FormControl('');
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    constructor(
        public matDialogRef: MatDialogRef<AddToMenu>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        public _pageService: PageService
    ) {
        this._unsubscribeAll = new Subject();

    }
    ngOnInit() {
        this.getAllMenuList();
    }

    getAllMenuList() {
        this._fuseSplashScreenService.show();
        this._pageService.getAllMenuList(this._data.getAllMenuListApi).subscribe((res) => {
            this._fuseSplashScreenService.hide();
            if (res.status == 200) {
                this.menuList = res['data'];
            } else {
                this._toastr.error(res['error'], 'Error');
            }
        }, error => {
            this._fuseSplashScreenService.hide();
            this._toastr.error(error.error.error, 'Error');
        });
    }

    ngAfterViewInit(): void {

    }
    addToMenu() {
        if (this.menuId.value != '' && this.menuId.value != null && this.menuId.value != undefined) {
            this.matDialogRef.close(this.menuId.value);
        } else {
            this._toastr.info("Please select any option!", "Info");
        }
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("addTo-menu-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("addTo-menu-content").style.maxHeight = "65vh";
        document.getElementById("addTo-menu-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("addTo-menu-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("addTo-menu-content").style.maxHeight = "100vh";
        document.getElementById("addTo-menu-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

