import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FuseUtils } from '@fuse/utils';
import { RestService } from '../../../shared/rest.service';
import { AuthService } from 'app/shared/auth.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { PageService } from '../pages.service';

@Injectable({
    providedIn: 'root'
})
export class CampaignsService {
    onEventTypeChanged = new BehaviorSubject<any>(null);
    onCampaignTypesChanged = new BehaviorSubject<any>(null);
    onTimeZonesChanged = new BehaviorSubject<any>(null);
    OnEventFilterChanged = new BehaviorSubject<any>(null);
    OnEventSubFilterChanged = new BehaviorSubject<any>(null);
    OnEventKeysChanged = new BehaviorSubject<any>(null);
    callSearchRefresh = new BehaviorSubject<any>('');
    showCurrentFolder = new BehaviorSubject<any>('');
    getGroupList = new BehaviorSubject<any>([]);
    saveContinueblast: boolean= false;
    isJourney = new BehaviorSubject<any>(null);
    refreshGetAllCampaign = new BehaviorSubject<any>(null);
    geteventTypesData = new BehaviorSubject<any>(null);
    refreshGetAllJourney = new BehaviorSubject<any>(null);
    onCampaignListChanged = new BehaviorSubject<any>([]);
    onSaveButtonClick = new BehaviorSubject<any>(false);
    // onEventValuePatch = new BehaviorSubject<any>(null);
    /**
     * Constructor
     *
     * @param _restService: RestService
     */
    constructor(
        private _restService: RestService,
        private _authService: AuthService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
        private _pageService: PageService,
    ) {
        this.init();
    }

    resolve(): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.init()
            ]).then(
                (res) => {
                    resolve('init');
                },
                reject
            );
        });
    }

    init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.OnEventFilterChanged = new BehaviorSubject(0);
            this.OnEventSubFilterChanged = new BehaviorSubject(0);
            this.OnEventKeysChanged = new BehaviorSubject(0);
            this.callSearchRefresh = new BehaviorSubject<any>('');
            this.showCurrentFolder = new BehaviorSubject<any>('');
            this.saveContinueblast = false;
            this.getGroupList = new BehaviorSubject<any>([]);
            this.isJourney = new BehaviorSubject(false);
            this.refreshGetAllCampaign = new BehaviorSubject(false);
            this.geteventTypesData = new BehaviorSubject<any>([]);
            this.refreshGetAllJourney = new BehaviorSubject<any>(false);
            this.onCampaignListChanged = new BehaviorSubject<any>([]);
            this.onSaveButtonClick = new BehaviorSubject<any>(false);
            // this.onEventValuePatch = new BehaviorSubject<any>(null);
            resolve('initialized');
        });
    }



    getReports(): any {
        return this._restService.Get('/Group/GetReports');
    }
    getTimeZones(): BehaviorSubject<any> {
        if (!this.onTimeZonesChanged.value || this.onTimeZonesChanged.value.length == 0) {
            this._restService.Get('/Campaign/GetTimeZones').subscribe((res: any) => {
                this.onTimeZonesChanged.next(res);
            }, (error: any) => {
                this.onTimeZonesChanged.next(null);
            })
        }
        return this.onTimeZonesChanged;
    }
    getEventTypes(): BehaviorSubject<any> {
        if (!this.onEventTypeChanged.value || this.onEventTypeChanged.value.length == 0) {
            this._restService.Get('/Campaign/GetEventTypes').subscribe((res: any) => {
                this.onEventTypeChanged.next(res);
            }, (error: any) => {
                this.onEventTypeChanged.next(null);
            })
        }
        return this.onEventTypeChanged;
    }
    // getEventKeys(): BehaviorSubject<any> {
    //     if (!this.OnEventKeysChanged.value || this.OnEventKeysChanged.value.length == 0) {
    //         this._restService.Get('/Campaign/GetTriggerEvents').subscribe((res: any) => {
    //             this.OnEventKeysChanged.next(res);
    //         }, (error: any) => {
    //             this.OnEventKeysChanged.next(null);
    //         })
    //     }
    //     return this.OnEventKeysChanged;
    // }
    getEventKeys() {
        return  this._restService.Get('/Campaign/GetTriggerEvents')
    }

    getCampaignTypes(): BehaviorSubject<any> {
        if (!this.onCampaignTypesChanged.value || this.onCampaignTypesChanged.value.length == 0) {
            this._restService.Get('/Campaign/GetCampaignTypes').subscribe((res: any) => {
                this.onCampaignTypesChanged.next(res);
            }, (error: any) => {
                this.onCampaignTypesChanged.next(null);
            })
        }
        return this.onCampaignTypesChanged;
    }
    getCampaigns(campaignReq?: any) {
        return this._restService.Post('/Campaign/GetAllCampaigns', campaignReq);
    }
    getCampaignById(id: number) {
        return this._restService.Get('/Campaign/GetCampaign/' + id);
    }

    AddEditCampaign(blastReq: any, resumeNewMes) {
        var _url
        if(resumeNewMes) {
            _url = "/Campaign/UpdateDripCampaign";
        }else {
            _url = "/Campaign/AddEditCampaign";
        }
        return this._restService.Post(_url, blastReq);
    }
    deleteCampaign(id: number) {
        var _url = "/Campaign/DeleteCampaign/" + id;
        return this._restService.Delete(_url, null);
    }
    GetEventTriggers(request) {
        return this._restService.Post('/Campaign/GetEventTriggers', request);
    }
    deleteTrigger(id: number) {
        var _url = "/Campaign/DeleteEventTrigger/" + id;
        return this._restService.Delete(_url, null);
    }
    AddEditEventTrigger(trigReq: any) {
        return this._restService.Post("/Campaign/AddEditEventTrigger", trigReq);
    }
    // GetAllTriggers() {
    //     return this._restService.Get('/Campaign/GetEventTriggers');
    // }
    GetTriggerEvents(providerId) {
        if (providerId) {
            return this._restService.Get('/Campaign/GetTriggerEventsByProvider/' + providerId);
        }
        else {
            return this._restService.Get('/Campaign/GetTriggerEvents');
        }
    }
    GetTriggerProperties(providerId) {
        if (providerId) {
            return this._restService.Get('/Template/GetTriggerPropertyPatternsByProvider/' + providerId);
        }
        else {
            return this._restService.Get('/Template/GetTriggerPropertyPatternsByCompany');
        }
    }
    AddEditTriggerEvent(trigReq: any) {
        return this._restService.Post("/Campaign/AddEditTriggerEvent", trigReq);
    }
    AddEditTriggerProperty(req: any) {
        return this._restService.Post("/Template/AddEditTriggerPropertyPatterns", req);
    }
    deleteTriggerEvent(id: number) {
        return this._restService.Delete("/Campaign/DeleteTriggerEvent/" + id, null);
    }
    deleteTriggerProperty(id: number) {
        return this._restService.Delete("/Template/DeleteTriggerPropertyPattern/" + id, null);
    }
    GetTriggerEventFields(fields) {
        return this._restService.Post('/Campaign/GetTriggerEventFields', fields);
    }
    UpdateCampaignStatus(CampaignId,IsActive, IsArchive){
        return this._restService.Get("/Campaign/UpdateCampaignStatus/" + CampaignId +'/'+ IsActive +'?IsArchive='+ IsArchive); 
    }
    getDripContacts(request){
      var _url ="/Campaign/GetDripContacts";
      return this._restService.Post(_url,request);
    }
    getDripContactsCount(request){
        var _url ="/Campaign/GetDripContactsCount";
        return this._restService.Post(_url,request); 
    }
    removeContactsFromDrip(request,campaignId){
        var _url ="/Campaign/RemoveContactsFormDrip/" + campaignId;
        return this._restService.Post(_url,request);
    }
    addDripForContact(request,dripCampaignId){
        var _url = "/Campaign/AddOrRestartDripForContacts/" + dripCampaignId;
        return this._restService.Post(_url,request)
    }
    getAllCompanyNames(request){
        var _url = "/Company/GetAllCompany";
        return this._restService.Post(_url,request)
    }
    getClickMapData(CampaignId) {
        return this._restService.Get("/Campaign/GetClickMap/" + CampaignId); 
    }
    GetURLClickMapDetails(request, exportContant){
        if(exportContant) {
            var _url ="/Campaign/GetURLClickMapDetails?export=" + exportContant;
            return this._restService.Postblob(_url,request); 
        }else {
            var _url ="/Campaign/GetURLClickMapDetails?export=" + exportContant;
            return this._restService.Post(_url,request); 
        }
    }
    GetARTemplates(Allfields,request?) {
        return this._restService.Post("/Campaign/GetARTemplates?Allfields="+Allfields, request);
    }
    GetARTemplateFields(id) {
        return this._restService.Get("/Campaign/GetARTemplateFields/" +  id); 
    }
    updateARTemplate(ARTempReq) {
        var _url ="/Campaign/UpdateARTemplate";
        return this._restService.Post(_url,ARTempReq);
    }
    SyncARTemplates() {
          return this._restService.Get("/Campaign/SyncARTemplates"); 
    }
    GetBeeRowList() {
        return this._restService.Get("/Template/GetBeeRowList"); 
    }
    AddEditBeeRows(request) {
        return this._restService.Post("/Template/AddBeeRows",request); 
    }
    DeleteBeeRow(id) {
        return this._restService.Delete("/Template/DeleteBeeRow/" + id, null);
    }
    getJourneyEvents() {
        return this._restService.Get("/Journey/GetEventTypes");
    }
    AddEditJourney(request, ExecuteCampaign = false) {
        return this._restService.Post("/Journey/AddEditJourney?ExecuteCampaign="+ExecuteCampaign,request);
    }
    GetJourneys(request) {
        return this._restService.Post("/Journey/GetAllJourneys", request); 
    }
    GetJourneyById(id) {
        return this._restService.Get("/Journey/GetJourney/" + id); 
    }
    deleteJourney(id) {
        return this._restService.Delete("/Journey/DeleteJourney/" + id, null);
    }
    UpdateJourneyStatus(JourneyId,IsActive, IsArchive){
        return this._restService.Get("/Journey/UpdateJourneyStatus/" + JourneyId +'/'+ IsActive +'?IsArchive='+ IsArchive); 
    }
    GetTrackingDataForCampaign(request, exportContant){
        if(exportContant) {
            var _url ="/Campaign/GetTrackingDataForCampaign?export=" + exportContant;
            return this._restService.Postblob(_url,request); 
        }else {
            var _url ="/Campaign/GetTrackingDataForCampaign?export=" + exportContant;
            return this._restService.Post(_url,request); 
        }
    }
    getSegments() {
        return this._restService.Get('/Segment/GetSegments');
    }

    getAllCampaignEventMenus(companyId?,providerId?) {
        return this._restService.Get('/Campaign/GetAllCampaignEventMenus?CompanyId='+companyId+'&ProviderId='+providerId);
    }

    addEditMenu(req: any) {
        var _url = '/Campaign/AddEditCampaignEventMenu';
        return this._restService.Post(_url, req);
      }

    DeleteMenu(id) {
        var _url = '/Campaign/DeleteCampaignEventMenu/'+id;
    return this._restService.Delete(_url, null);
    }

    updateCampaignEventMenus(MenuId,CampaignId) {
        return this._restService.Get('/Campaign/UpdateCampaignMenuId?MenuId='+MenuId+'&CampaignId='+CampaignId);
    }

}