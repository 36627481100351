import { Component, Inject, ViewEncapsulation, ViewChild, Pipe } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
@Component({
    selector: 'view-message',
    templateUrl: './view-message.component.html',
    styleUrls: ['./view-message.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ViewMessageDialogComponent {
    dialogTitle: string = "Message Details";
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    attach:any[] = []
    constructor(
        public matDialogRef: MatDialogRef<ViewMessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
        private _pageService: PageService, private _toastr: ToastrService,    private _fuseSplashScreenService: FuseSplashScreenService,
    ) {
        if(this._data.unifiedTypeId == 1 && (_data && _data.reportData)) {
            this._data.message = '';
            this._fuseSplashScreenService.show();
            this._pageService.getMessageTextById(_data.reportData.id).subscribe((res: any) => {
                if (res.status == 200) {
                    this._fuseSplashScreenService.hide();
                    this._data.message = res.data;
                }  
                },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.message, 'Error');
                });
        }
        if (_data.dialogTitle) {
            this.dialogTitle = _data.dialogTitle;
        }
    }
    ngOnInit(): void {
        if(this._data && this._data.unifiedTypeId == 1 && this._data.mediaURL && this._data.mediaURL != null) {
            if(this.checkJson(this._data.mediaURL)) {
                var mediaUrlData = JSON.parse(this._data.mediaURL);
                var mediaUrlDataKey = Object.keys(mediaUrlData);
                var mediaUrlDataValues = [] ;
                mediaUrlDataValues = Object.values(mediaUrlData);        
                (mediaUrlDataValues[0]).forEach(item =>{
                    let attachData = mediaUrlDataKey[0]+item;
                    let attachDataSplit = attachData.split(' ');
                    if(attachDataSplit && attachDataSplit.length == 1) {
                        this.attach.push( {
                            "fileName": attachDataSplit[0], "filePath": attachDataSplit[0]
                        }
                        )
                    }else {
                        let fileName= '';
                        attachDataSplit.forEach((item, index) => {
                            if(index != 0) {
                                fileName += item+' ';
                            }
                        });
                        this.attach.push( {
                            "fileName": fileName, "filePath": attachDataSplit[0]
                        });
                    }
                });
            } 
        }
    }
    checkJson(mediaUrlData) {
        try {
            var json = JSON.parse(mediaUrlData);
            return (typeof json === 'object');
        } catch(e) {
            return false
        }
    }
    goToLink(url: string) {
        window.open(url, "_blank");
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("view-message");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("viewmessage-content").style.maxHeight = "65vh";
        document.getElementById("viewmessage-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("view-message");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("viewmessage-content").style.maxHeight = "100vh";
        document.getElementById("viewmessage-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

