import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'mergefield-option',
  templateUrl: './mergefield-option.component.html',
  styleUrls: ['./mergefield-option.component.scss'],
})
export class MergefieldOptionComponent implements OnInit,AfterViewInit {
  @Output() mergefieldValue = new EventEmitter<any>();
  @Input() customFieldOptions: any;
  @Input() mergeTagArray: any;
  mergefield: FormControl = new FormControl();
  fieldOptions: any = [
    {
      name: 'Basic',
      basicTag: [
        {
          name: " Associate ID",
          value: "[AssociateId]"
        },
        {
          name: "First Name",
          value: "[firstName]"
        },
        {
          name: "Last Name",
          value: "[lastName]"
        },
        {
          name: "Phone",
          value: "[Phone]"
        },
        {
          name: "Email",
          value: "[Email]"
        },
        {
          name: "Highest Rank",
          value: "[HighestRank]"
        },
        {
          name: "Paid Rank",
          value: "[paidAsRank]"
        },
        {
          name: "WebAlias",
          value: "[WebAlias]"
        },
        {
          name: "Associate Status",
          value: "[AssociateStatus]"
        },
        {
          name: "Associate Type",
          value: "[AssociateType]"
        },
        {
          name: "Company Logo",
          value: "[CompanyLogoURL]"
        },
        {
          name: "Company Name",
          value: "[CompanyName]"
        },
        {
          name: "CompanyAddress",
          value: "[CompanyAddress]"
        },
        {
          name: "CompanyCity",
          value: "[CompanyCity]"
        },
        {
          name: "CompanyState",
          value: "[CompanyState]"
        },
        {
          name: "CompanyZip",
          value: "[CompanyZipCode]"
        },
        {
          name: "BackOffice Id",
          value: "[BackOfficeId]"
        }
      ],
    },
    {
      name: 'Sponsor',
      sponsorTag: [
        {
          name: "Sponsor First Name",
          value: "[_SponsorfirstName]"
        },
        {
            name: "Sponsor Last Name",
            value: "[_SponsorlastName]"
        },
        {
            name: "Sponsor Email",
            value: "[_Sponsoremail]"
        },
        {
            name: "Sponsor Phone Number",
            value: "[_SponsorphoneNumber]"
        },
        {
            name: "Sponsor WebAlias",
            value: "[_SponsorWebAlias]"
        },
        {
            name: "Sponsor ID",
            value: "[_SponsorAssociateId]"
        }
      ],
    },
    {
      name: 'Custom',
      customTag: [],
    }
  ];
  constructor() { 
    for(let i = 1; i <=20; i++) {
      let tag = {
          name: "Custom"+i,
          value: "[Custom"+i+"]"
      };
      this.fieldOptions[2].customTag.push(tag);
    }
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    if(this.mergeTagArray && this.mergeTagArray.length>2 && this.mergeTagArray[2].customTag && this.mergeTagArray[2].customTag.length == 0) {
      this.mergeTagArray.splice(2, 1)
    }
  }
  addFieldToSub(event) {
    this.mergefieldValue.emit(event)
  }
}
