import { Component, OnInit, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatTableDataSource, MatDialogRef, MatDialog, MatPaginator } from '@angular/material';
import { FormBuilder, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { AddTriggerEventsComponent } from 'app/shared/common/dialogs/add-trigger-events/add-trigger-events.component';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { ActivatedRoute } from '@angular/router';
import { ProvidersService } from 'app/main/pages/providers/providers.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-trigger-events-list',
  templateUrl: './trigger-events-list.component.html',
  styleUrls: ['./trigger-events-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class TriggerEventsListComponent implements OnInit {
  userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
  @Input() noTitle: any;
  dialogRef: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  List: any = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['eventName', 'properties'];
  pageSize = 10;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  providerId: number;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _campaignsService: CampaignsService,
    private _providersService: ProvidersService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._activeRoute.params.subscribe((routeParams: any) => {
      this.providerId = parseInt(routeParams.id);
      if(this.providerId) {
        this.displayedColumns.push("actions");
      }
      this.getTriggerEvents(this.providerId);
    });
    if(this.providerId){
      this._providersService.onProviderAddTriggerEvent
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(val => {
        if (val) {
         this.addTriggerEvent();
        }
      });
    }
 
  }
  ShowproperitesIn(Properties)
  {
   
  var Stringformat = Properties.split(",").join('\n');
  
    return Stringformat;
   
  }
  
  
  getTriggerEvents(providerId) {
    this._fuseSplashScreenService.show();
    this._campaignsService.GetTriggerEvents(providerId)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          this.List = res.data;
        }
        else {
          this.List = [];
        }
        this.dataSource = new MatTableDataSource(this.List);
      },
        error => {
          this._fuseSplashScreenService.hide();
          this.List = [];
          this.dataSource = new MatTableDataSource(this.List);
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  addTriggerEvent() {
    this.dialogRef = this._matDialog.open(AddTriggerEventsComponent, {
      panelClass: 'add-trigger-event',
      disableClose: false,
      data: {
        action: 'Add',
        providerId: this.providerId
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        this.getTriggerEvents(this.providerId);
      });
  }
  editTriggerEvent(event: any) {
    this.dialogRef = this._matDialog.open(AddTriggerEventsComponent, {
      panelClass: 'add-trigger-event',
      disableClose: false,
      data: {
        action: 'Edit',
        triggerEvent: event,
        providerId: this.providerId
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        this.getTriggerEvents(this.providerId);
      });
  }
  deleteTriggerEvent(id): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete this event?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._fuseSplashScreenService.show();
        this._campaignsService.deleteTriggerEvent(id)
          .subscribe((res: any) => {
            this._fuseSplashScreenService.hide();
            if (res.status == 200) {
              this._toastr.success('Event Deleted Successfully', 'Success');
              this.getTriggerEvents(this.providerId);
            } else {
              this._toastr.error(res.error, 'Error');
            }
          },
            error => {
              this._fuseSplashScreenService.hide();
              console.log(error);
              if (error.error) {
                this._toastr.error(error.error.error, 'Error');
              }
              else {
                this._toastr.error(error.message, 'Error');
              }
            }
          );
      }
      this.confirmDialogRef = null;
    });
  }
}
