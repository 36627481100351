import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import * as _ from 'lodash';
import { MatTableDataSource, MatDialog, MatPaginator, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ViewMessageDialogComponent } from '../dialogs/view-message/view-message.component';
import { SentMessageDetailsComponent } from '../dialogs/sent-message-details/sent-message-details.component';

@Component({
	selector: 'app-recent-sent-inprogress',
	templateUrl: './recent-sent-inprogress.component.html',
	styleUrls: [ './recent-sent-inprogress.component.scss' ],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})
export class RecentSentInprogressComponent implements OnInit {
	dialogTitle: string;
	dialogRef: any;
	dataSource: MatTableDataSource<any>;
	TotalCount: any = 0;
	pageNumber: number = 1;
	pageSize: number = 10;
	displayedColumns: string[] = [
		'typeName',
		'createdAt',
		'messageText',
		'sent',
		'rejected',
		'errors',
		'delivered',
		'opened',
		'click',
		'complained',
		'replies',
		'segments',
		// 'optedOut',
	];
	List: any[] = [];
	@ViewChild(MatPaginator, { static: false })
	paginator: MatPaginator;
	IsDialogMax: boolean = false;
	hostWidth: number;
	hostHeight: number;

	constructor(
		private _matDialog: MatDialog,
		public matDialogRef: MatDialogRef<RecentSentInprogressComponent>,
		@Inject(MAT_DIALOG_DATA) public _data: any
	) {
		this.dialogTitle = 'Recent Sent In Progress';
		if (_data && _data.List.length > 0) {
			this.List = _data.List;
			this.dataSource = new MatTableDataSource(this.List);
		} else {
			this.List = [];
			this.dataSource = new MatTableDataSource(this.List);
		}
	}

	ngOnInit() {}

	viewMessage(message, mediaURL, unifiedTypeId, report): void {
		this.dialogRef = this._matDialog.open(ViewMessageDialogComponent, {
			panelClass: 'view-message',
			data: {
				message: message,
				mediaURL: mediaURL,
				unifiedTypeId: unifiedTypeId,
				reportData: report
			}
		});
		this.dialogRef.afterClosed().subscribe((response) => {
			if (!response) {
				return;
			}
		});
	}

	minimizeDialog() {
		let elements: any = document.getElementsByClassName('recentSentInprogress-dialog');
		for (var i = 0; i < elements.length; i++) {
			elements[i].style.width = this.hostWidth + 1 + 'px';
			elements[i].style.height = this.hostHeight + 1 + 'px';
		}
		document.getElementById('recentSentInprogress-content').style.maxHeight = '65vh';
		document.getElementById('recentSentInprogress-wrapper').style.maxHeight = '85vh';
		this.IsDialogMax = !this.IsDialogMax;
	}
	maximizeDialog() {
		let elements: any = document.getElementsByClassName('recentSentInprogress-dialog');
		for (var i = 0; i < elements.length; i++) {
			this.hostHeight = elements[i].clientHeight;
			this.hostWidth = elements[i].clientWidth;
			elements[i].style.width = '100%';
			elements[i].style.height = '100%';
		}
		document.getElementById('recentSentInprogress-content').style.maxHeight = '100vh';
		document.getElementById('recentSentInprogress-wrapper').style.maxHeight = '100vh';
		this.IsDialogMax = !this.IsDialogMax;
	}
	goToDetails(type, messageId, count): void {
        this.dialogRef = this._matDialog.open(SentMessageDetailsComponent, {
            panelClass: 'sent-message-details',
            data: {
                reportingType: type,
                messageId: messageId,
				isRecentSentInProgress: true,
				totalSelectedRedisRecords: count
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
            });
    }
}
