import { sequence } from '@angular/animations';
import { Component, Inject, ViewEncapsulation, ViewChild, Pipe } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { PageService } from 'app/main/pages/pages.service';
import { MailService } from 'app/main/pages/mail/mail.service';
import * as moment from "moment-timezone";
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
@Component({
    selector: 'test-contact',
    templateUrl: './test-contact.component.html',
    styleUrls: ['./test-contact.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TestContactDialogComponent {
    dialogTitle: string = "Test Contact";
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    testContactForm: FormGroup;
    filterTestContact: FormControl = new FormControl('');
    filterTestContactAssoId: FormControl = new FormControl('');
    contacts: any[] = [];
    pageSize: number = 100;
    currentPageNumber: number = 1;
    searchContact: string = "";
    searchAssoID: number = 0;
    contactsLabel: string = "";
    stopNextCallingforContacts = false;
    private _unsubscribeAll: Subject<any>;
    constructor(
        public matDialogRef: MatDialogRef<TestContactDialogComponent>,
        private _formBuilder: FormBuilder,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _pageService: PageService,
        private _mailService: MailService,
        private _calendarService: CalendarService,
        private _campaignsService: CampaignsService,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
        this._unsubscribeAll = new Subject();
        this.testContactForm = this.createTestContactForm();
    }
    createTestContactForm(): FormGroup {
        return this._formBuilder.group({
            testContact: [[]]
        })
    }
    ngOnInit(): void {
       this.getContacts();
       this.testContactForm.get('testContact').valueChanges.subscribe((val) => {
        if(!this.filterTestContact.value && !this.filterTestContactAssoId && val) {
          this.getContacts();
        }
      })
      this.filterTestContact.valueChanges
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(1000),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.filterContacts();
        });
        this.filterTestContactAssoId.valueChanges
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(1000),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.filterContacts();
        });  
    }
    getContacts() {
        let contactReq = {
          "searchText": this.searchContact,
          "pageNumber": this.currentPageNumber,
          "pageSize": this.pageSize,
          "selectedIds": this.testContactForm.get('testContact').value.length > 0 ? this.testContactForm.get('testContact').value : [],
          "associateId": this.searchAssoID
        };
        this._fuseSplashScreenService.show();
        this._pageService.getContactsForDropDown(contactReq)
          .subscribe((res: any) => {
            this._fuseSplashScreenService.hide();
            if(res.data) {
              this.contacts = res.data;
            }
            if(res.data && res.data.length < this.pageSize) {
                this.stopNextCallingforContacts = true;
              }
            if (this.contacts.length == 0) {
                this.contactsLabel = "No contact found";
              }
              else {
                this.contactsLabel = "";
              }
          },
            error => {
              this._fuseSplashScreenService.hide();
              this._toastr.error(error.message, 'Error');
            }
          );
    }
    focusContacts() {
        let el = document.getElementById('contactSearchInput');
        if (el) {
          el.focus();
        }
    }
    filterContacts(): void {
        this.searchContact = this.filterTestContact.value;
        this.searchAssoID = this.filterTestContactAssoId.value ? this.filterTestContactAssoId.value : 0;
        this.currentPageNumber = 1;
        this.contacts = [];
        this.contactsLabel = "Searching...";
        this.getContacts();
      }
    getNextContacts() {
        if (!this.stopNextCallingforContacts) {
          this.currentPageNumber += 1;
          this.getContacts();
        }
    }
    onScrollDown(ev) {
        this.getNextContacts();
    }  
    testContactNow() {
        if(this.testContactForm.get('testContact').value.length == 0) {
            this._toastr.warning('Please select atleast one contact', 'Warning');
            return false;
        }
        var contactData = this.testContactForm.get('testContact').value;  
        if(this._data.Form && this.testContactForm.get('testContact').value.length>0) {
            var FormData = this._data.Form.getRawValue();
            if(this._data && this._data.pageName && this._data.pageName == 'template_dialog') {
                FormData.accounttypes = [FormData.accounttypes];
            }
            if(this._data && this._data.pageName && this._data.pageName == 'journey') {
                FormData.accounttypes = [FormData.deliveryTypeId];
            }
            if(this._data && this._data.createFormGroup && this._data.page =="campaign") {
                var createFormGroup = this._data.createFormGroup.getRawValue();
                FormData.accounttypes = createFormGroup.accounttypes;
            }
            if (this._data.typeId == 1 && this._data.typed == 'Email') {
                var tempLinks = [];
                if (FormData.isBeeEditor) {
                    tempLinks = this.getLinksFromHtml(FormData.emailEditor, 'a', '[Link');
                }
                else {
                    tempLinks = (this._data && this._data.emailLinks) ? this._data.emailLinks : [];
                }
                let mail: any = {
                    "recipients": contactData,
                    "subject": FormData.subject,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : FormData.emailEditor,
                    "groups":[],
                    "reports": [],
                    "links": tempLinks,
                    "domainType": FormData.domainType ? parseInt(FormData.domainType) : 0,
                    "attachments": FormData.attachments ? FormData.attachments : [],
                };
                if (this._data && this._data.isVerifyMessage) {
                    mail.isVerifyMessage = true;
                }
                this._fuseSplashScreenService.show();
                this._mailService.sendMail(mail, (FormData.accounttypes.indexOf(1) !== -1 ? 1 : 21))
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('Email Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('Email Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('Email Error: ' + error.error, 'Error');
                            }
    
                        }
                    );
            }
            if (this._data.typeId == 2 && this._data.typed == 'SMS') {
                let twilioReq: any = {
                    "deliveryTypeId": (FormData.accounttypes.indexOf(2) !== -1 ? 2 : (FormData.accounttypes.indexOf(7) !== -1 ? 7 : (FormData.accounttypes.indexOf(11) !== -1 ?  11 : (FormData.accounttypes.indexOf(12) !== -1 ? 12 : (FormData.accounttypes.indexOf(15) !== -1 ? 15 : (FormData.accounttypes.indexOf(16) !== -1 ? 16 : (FormData.accounttypes.indexOf(22) !== -1 ? 22 : 23))))))),
                    "groups": [],
                    "reports": [],
                    "recipients": contactData,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? (FormData.message + '\n' + FormData.optOutTrailor) : ((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : (FormData.smsMessage + '\n' + FormData.smsOptOutTrailor)),
                    "links": (this._data && this._data.page =="campaign") ? FormData.smsLinks : this._data.smsLinks
                };
                if (FormData.accounttypes.indexOf(7) !== -1 || FormData.accounttypes.indexOf(12) !== -1) {
                    twilioReq.mediaURL = FormData.mediaURL;
                }
                if (this._data && this._data.isVerifyMessage) {
                    twilioReq.isVerifyMessage = true;
                }
                this._fuseSplashScreenService.show();
                var Url
                if(FormData.accounttypes.indexOf(2) !== -1 || FormData.accounttypes.indexOf(7) !== -1) {
                    Url = "/TwilioMessage/SendToContact";
                }else if(FormData.accounttypes.indexOf(11) !== -1 || FormData.accounttypes.indexOf(12) !== -1) {
                    Url = "/BandwidthMessage/SendToContact"; 
                }else if(FormData.accounttypes.indexOf(15) !== -1 || FormData.accounttypes.indexOf(16) !== -1) {
                    Url = "/DirectTextMessage/SendToContact"; 
                }else if(FormData.accounttypes.indexOf(22) !== -1 || FormData.accounttypes.indexOf(23) !== -1) {
                    Url = "/Infobip/SendMessageToContacts"; 
                }
                this._mailService.sendSMSMessage(twilioReq, Url)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close()
                    } else {
                        this._toastr.error('SMS Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('SMS Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('SMS Error: ' + error.error, 'Error');
                        }
                    }
                );
    
            }
            if (this._data.typeId == 3 && this._data.typed == 'WhatsApp') {
                let whatsappReq: any = {
                    "groups": [],
                    "reports": [],
                    "recipients": contactData,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? (FormData.message + '\n' + FormData.optOutTrailor) :((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : (FormData.whatsappMessage + '\n' + FormData.whatsappOptOutTrailor)),
                    "links": (this._data && this._data.page =="campaign") ? FormData.whatsappLinks : this._data.whatsappLinks,
                    "mediaURL": (this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.mediaURL : ''
                };
                this._fuseSplashScreenService.show();
                this._mailService.sendWhatsappMessage(whatsappReq)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('WhatsApp Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('WhatsApp Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('WhatsApp Error: ' + error.error, 'Error');
                            }
                        }
                    );
            }
            if (this._data.typeId == 4 && this._data.typed == 'Line') {
                let lineReq: any = {
                    "groups": [],
                    "reports": [],
                    "recipients": contactData,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? (FormData.message + '\n' + FormData.optOutTrailor) : ((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : (FormData.lineMessage + '\n' + FormData.lineOptOutTrailor)),
                    "links": (this._data && this._data.page =="campaign") ? FormData.lineLinks : this._data.lineLinks,
                    "mediaURL": (this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.mediaURL : ''
                };
                this._fuseSplashScreenService.show();
                this._mailService.sendLineMessage(lineReq)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('Line Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('Line Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('Line Error: ' + error.error, 'Error');
                            }
                        }
                    );
            }
            if (this._data.typeId == 5 && this._data.typed == 'Facebook') {
                let facebookReq: any = {
                    "groups": [],
                    "reports": [],
                    "recipients": contactData,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? (FormData.message + '\n' + FormData.optOutTrailor) : ((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : (FormData.fbMessage + '\n' + FormData.fbOptOutTrailor)),
                    "links": (this._data && this._data.page =="campaign") ? FormData.fbLinks : this._data.fbLinks
                };
                this._fuseSplashScreenService.show();
                this._mailService.sendFacebookMessage(facebookReq)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('Facebook Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('Facebook Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('Facebook Error: ' + error.error, 'Error');
                            }
                        }
                    );
            }
            if (this._data.typeId == 6 && this._data.typed == 'Telegram') {
                let telegramReq: any = {
                    "groups": [],
                    "reports": [],
                    "recipients": contactData,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? (FormData.message + '\n' + FormData.optOutTrailor) : ((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : (FormData.telegramMessage + '\n' + FormData.telegramOptOutTrailor)),
                    "links": (this._data && this._data.page =="campaign") ? FormData.telegramLinks : this._data.telegramLinks,
                    "mediaURL": (this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.mediaURL : ''
                };
                this._fuseSplashScreenService.show();
                this._mailService.sendTelegramMessage(telegramReq)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('Telegram Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('Telegram Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('Telegram Error: ' + error.error, 'Error');
                            }
                        }
                    );
            }
            if ((this._data.typeId == 8 || this._data.typeId==24) && this._data.typed == 'ShortCode') {
                let twilioReq: any = {
                    "deliveryTypeId":this._data.typeId,
                    "groups": [],
                    "reports": [],
                    "recipients": contactData,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? (FormData.message + '\n' + FormData.optOutTrailor) : ((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : (FormData.shortCodeMessage + '\n' + FormData.shortCodeOptOutTrailor)),
                    "links": (this._data && this._data.page =="campaign") ? FormData.shortCodeLinks : this._data.shortCodeLinks,
                    "mediaURL": (this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.mediaURL : FormData.shortCodemediaURL
                };
                if (this._data && this._data.isVerifyMessage) {
                    twilioReq.isVerifyMessage = true;
                }
                var url;
                if(this._data.typeId==8){
                    url="/TwilioMessage/SendToContact"
                }
                if(this._data.typeId==24){
                    url="/Infobip/SendMessageToContacts";
                }
                this._fuseSplashScreenService.show();
                this._mailService.sendTwilioMessage(url,twilioReq)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('ShortCode Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('ShortCode Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('ShortCode Error: ' + error.error, 'Error');
                            }
                        }
                    );
            }
            if (this._data.typeId == 9 && this._data.typed == 'Notification') {
                var tempLinks = [];
                if (FormData.isNotificationBeeEditor) {
                    tempLinks = this.getLinksFromHtml(FormData.notificationMessage, 'a', '[Link');
                }
                else {
                    tempLinks = this._data.notificationLinks;
                }
                let notificationReq: any = {
                    "deliveryTypeId": 9,
                    "recipients": contactData,
                    "subject": (this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.subject : FormData.allNotificationType,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : FormData.notificationMessage,
                    "groups": [],
                    "reports": [],
                    "links": tempLinks,
                    "notificationTypeId":0
                };
                if (this._data && this._data.isVerifyMessage) {
                    notificationReq.isVerifyMessage = true;
                }
                this._fuseSplashScreenService.show();
                this._mailService.NotificationMessage(notificationReq)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('Notification Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('Notification Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('Notification Error: ' + error.error, 'Error');
                            }
    
                        }
                    );
            }
            if ((this._data.typeId == 13) && this._data.typed == 'International SMS') {
                let twilioReq: any = {
                    "groups": [],
                    "reports": [],
                    "recipients": contactData,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? (FormData.message + '\n' + FormData.optOutTrailor) : ((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : (FormData.internationalSMSMMSMessage + '\n' + FormData.internationalSMSMMSOptOutTrailor)),
                    "links": (this._data && this._data.page =="campaign") ? FormData.internationalSMSMMSLinks : this._data.internationalSMSMMSLinks,
                    "isInternational": true,
                    "mediaURL": (this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.mediaURL : FormData.internationalSMSMMSMediaURL
                };
                if (this._data && this._data.isVerifyMessage) {
                    twilioReq.isVerifyMessage = true;
                }
                this._fuseSplashScreenService.show();
                var Url
                if(FormData.accounttypes.indexOf(13) !== -1) {
                    Url = "/TwilioMessage/SendToContact";
                }else if(FormData.accounttypes.indexOf(14) !== -1) {
                    Url = "/BandwidthMessage/SendToContact"; 
                }else if(FormData.accounttypes.indexOf(17) !== -1) {
                    Url = "/DirectTextMessage/SendToContact"; 
                }
                this._mailService.sendSMSMessage(twilioReq, Url)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this._toastr.success(res.data, 'Success');
                        this.matDialogRef.close()
                    } else {
                        this._toastr.error('International SMS Error: ' + res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        if (error.error.error) {
                            this._toastr.error('International SMS Error: ' + error.error.error, 'Error');
                        }
                        else {
                            this._toastr.error('International SMS Error: ' + error.error, 'Error');
                        }
                    }
                );
    
            }
            if (this._data.typeId == 18 && this._data.typed == 'Verb Notification') {
                var tempLinks = [];
                tempLinks = this._data.verbNotificationLinks;
                let notificationReq: any = {
                    "deliveryTypeId": 18,
                    "recipients": contactData,
                    "subject": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? FormData.allNotificationType : FormData.verbNotificationSubject,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? FormData.notificationMessage : ((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : FormData.verbNotificationMessage),
                    "groups": [],
                    "reports": [],
                    "links": tempLinks,
                    "notificationTypeId":0
                };
                if (this._data && this._data.isVerifyMessage) {
                    notificationReq.isVerifyMessage = true;
                }
                this._fuseSplashScreenService.show();
                this._mailService.NotificationMessage(notificationReq)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('Verb Notification Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('Verb Notification Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('Verb Notification Error: ' + error.error, 'Error');
                            }
    
                        }
                    );
            }
            if (this._data.typeId == 19 && this._data.typed == 'NowTech Notification') {
                var tempLinks = [];
                tempLinks = this._data.nowTechNotificationLinks;
                let nowTechNotificationReq: any = {
                    "deliveryTypeId": 19,
                    "recipients": contactData,
                    "subject": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? FormData.allNotificationType : FormData.nowTechNotificationSubject,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? FormData.notificationMessage : ((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : FormData.nowTechNotificationMessage),
                    "groups": [],
                    "reports": [],
                    "links": tempLinks,
                    "notificationTypeId":0
                };
                if (this._data && this._data.isVerifyMessage) {
                    nowTechNotificationReq.isVerifyMessage = true;
                }
                this._fuseSplashScreenService.show();
                this._mailService.NotificationMessage(nowTechNotificationReq)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('NowTech Notification Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('NowTech Notification Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('NowTech Notification Error: ' + error.error, 'Error');
                            }
    
                        }
                    );
            }
            if (this._data.typeId == 20 && this._data.typed == 'Shout') {
                var tempLinks = [];
                tempLinks = this._data.shoutLinks;
                let shoutReq: any = {
                    "deliveryTypeId": 20,
                    "recipients": contactData,
                    "messageText": (this._data && this._data.pageName && this._data.pageName == 'template_dialog') ? FormData.message : ((this._data && this._data.pageName && this._data.pageName == 'journey') ? FormData.messageText : FormData.shoutMessage),
                    "groups": [],
                    "reports": [],
                    "links": tempLinks,
                    "notificationTypeId": FormData.notificationTypeId
                };
                if (this._data && this._data.isVerifyMessage) {
                    shoutReq.isVerifyMessage = true;
                }
                this._fuseSplashScreenService.show();
                this._mailService.NotificationMessage(shoutReq)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close()
                        } else {
                            this._toastr.error('Shout Error: ' + res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('Shout Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('Shout Error: ' + error.error, 'Error');
                            }
    
                        }
                    );
            }
        }
    }
    testContactMoreOptionNow() {
        var createFormData = this._data.createFormGroup.getRawValue();
        let campaignAdvanceOptions = [];
        this._data.Form.get('messagesArray')['controls'].forEach((SeqArrayObj: any, index) => {
          let deliveryTypeIds = SeqArrayObj.get('deliveryTypeIDs').value;
          if (deliveryTypeIds.indexOf(1) !== -1) {
            let obj = SeqArrayObj.get('EmailField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(1,  createFormData.eventtype, false, obj));
          }
          if ((deliveryTypeIds.indexOf(2) !== -1 && deliveryTypeIds.indexOf(7) == -1)) {
            let obj = SeqArrayObj.get('SMSField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(2, createFormData.eventtype, false, obj));
          }
          if ((deliveryTypeIds.indexOf(11) !== -1 && deliveryTypeIds.indexOf(12) == -1)) {
            let obj = SeqArrayObj.get('SMSField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(11, createFormData.eventtype, false, obj));
          }
          if (deliveryTypeIds.indexOf(7) !== -1) {
            let obj = SeqArrayObj.get('SMSField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(7, createFormData.eventtype, true, obj));
          }
          if (deliveryTypeIds.indexOf(12) !== -1) {
            let obj = SeqArrayObj.get('SMSField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(12, createFormData.eventtype, true, obj));
          }
          if (deliveryTypeIds.indexOf(8) !== -1) {
            let obj = SeqArrayObj.get('ShortCodeField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(8, createFormData.eventtype, true, obj));
          }
          if (deliveryTypeIds.indexOf(3) !== -1) {
            let obj = SeqArrayObj.get('WhatsappField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(3, createFormData.eventtype, false, obj));
          }
          if (deliveryTypeIds.indexOf(4) !== -1) {
            let obj = SeqArrayObj.get('LineField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(4, createFormData.eventtype, false, obj));
          }
          if (deliveryTypeIds.indexOf(5) !== -1) {
            let obj = SeqArrayObj.get('FacebookField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(5, createFormData.eventtype, false, obj));
          }
          if (deliveryTypeIds.indexOf(6) !== -1) {
            let obj = SeqArrayObj.get('TelegramField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(6, createFormData.eventtype, false, obj));
          }
          if (deliveryTypeIds.indexOf(9) !== -1) {
            let obj = SeqArrayObj.get('NotificationField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(9, createFormData.eventtype, false, obj));
          }
          if (deliveryTypeIds.indexOf(13) !== -1) {
            let obj = SeqArrayObj.get('InternationalSMSMMSField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(13, createFormData.eventtype, true, obj));
          }
          if (deliveryTypeIds.indexOf(14) !== -1) {
            let obj = SeqArrayObj.get('InternationalSMSMMSField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(14, createFormData.eventtype, true, obj));
          }
          if (deliveryTypeIds.indexOf(18) !== -1) {
            let obj = SeqArrayObj.get('VerbNotificationField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(18, createFormData.eventtype, true, obj));
          }
          if (deliveryTypeIds.indexOf(19) !== -1) {
            let obj = SeqArrayObj.get('NowTechNotificationField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(19, createFormData.eventtype, true, obj));
          }
          if (deliveryTypeIds.indexOf(20) !== -1) {
            let obj = SeqArrayObj.get('ShoutField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(20, createFormData.eventtype, true, obj));
          }
          if (deliveryTypeIds.indexOf(21) !== -1) {
            let obj = SeqArrayObj.get('EmailField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(21,  createFormData.eventtype, false, obj));
          }
          if ((deliveryTypeIds.indexOf(22) !== -1 && deliveryTypeIds.indexOf(23) == -1)) {
            let obj = SeqArrayObj.get('SMSField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(22, createFormData.eventtype, false, obj));
          }
          if (deliveryTypeIds.indexOf(23) !== -1) {
            let obj = SeqArrayObj.get('SMSField').value;
            obj.sequence = index+1;
            Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(23, createFormData.eventtype, true, obj));
          }
        });
        if(this.testContactForm.get('testContact').value.length == 0) {
            this._toastr.warning('Please select atleast one contact', 'Warning');
            return false;
        }
        var contactData = this.testContactForm.get('testContact').value;
        if(this._data.Form && this.testContactForm.get('testContact').value.length>0) {
            campaignAdvanceOptions.forEach(element => {
                if(this._data.typeId == 1 && this._data.typed == 'email' && element.sequence == this._data.Index) {
                    let mail: any = {
                        "recipients": contactData,
                        "subject": element.subject,
                        "messageText": element.messageText,
                        "groups":[],
                        "reports": [],
                        "links": element.links,
                        "domainType": element.domainType ? parseInt(element.domainType) : 0,
                        "attachments": element.attachments ? element.attachments : [],
                    };
                    if (this._data && this._data.isVerifyMessage) {
                        mail.isVerifyMessage = true;
                    }
                    this._fuseSplashScreenService.show();
                    this._mailService.sendMail(mail, (element.deliveryTypeId == 1 ? 1 : 21))
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success(res.data, 'Success');
                                this.matDialogRef.close();
                                return
                            } else {
                                this._toastr.error('Email Error: ' + res.error, 'Error');
                            }
                        },
                            error => {
                                this._fuseSplashScreenService.hide();
                                if (error.error.error) {
                                    this._toastr.error('Email Error: ' + error.error.error, 'Error');
                                }
                                else {
                                    this._toastr.error('Email Error: ' + error.error, 'Error');
                                }
        
                            }
                        );
                }
                if (this._data.typeId == 2 && this._data.typed == 'sms' && element.sequence == this._data.Index && (element.deliveryTypeId == 2 || element.deliveryTypeId == 7  || element.deliveryTypeId == 11 || element.deliveryTypeId == 12 || element.deliveryTypeId == 15 || element.deliveryTypeId == 16 || element.deliveryTypeId == 22 || element.deliveryTypeId == 23)) {
                    let twilioReq: any = {
                        "deliveryTypeId": element.deliveryTypeId,
                        "recipients": contactData,
                        "messageText": element.messageText,
                        "groups":[],
                        "reports": [],
                        "links": element.links
                    };
                    if (element.deliveryTypeId == 7 || element.deliveryTypeId == 12) {
                        twilioReq.mediaURL = element.mediaURL;
                    }
                    if (this._data && this._data.isVerifyMessage) {
                        twilioReq.isVerifyMessage = true;
                    }
                    this._fuseSplashScreenService.show();
                    var Url
                    if(element.deliveryTypeId == 2 || element.deliveryTypeId == 7) {
                        Url = "/TwilioMessage/SendToContact";
                    }else if(element.deliveryTypeId == 11 || element.deliveryTypeId == 12) {
                        Url = "/BandwidthMessage/SendToContact"; 
                    }else if(element.deliveryTypeId == 15 || element.deliveryTypeId == 16) {
                        Url = "/DirectTextMessage/SendToContact"; 
                    }else if(element.deliveryTypeId == 22 || element.deliveryTypeId == 23) {
                        Url = "/Infobip/SendMessageToContacts"; 
                    }

                    if(Url != '' && Url != undefined) {
                        this._mailService.sendSMSMessage(twilioReq, Url)
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success(res.data, 'Success');
                                this.matDialogRef.close();
                                return
                            } else {
                                this._toastr.error('SMS Error: ' + res.error, 'Error');
                            }
                            },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('SMS Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('SMS Error: ' + error.error, 'Error');
                            }
                        }
                        );
                    }
        
                }
                if (this._data.typeId == 3 && this._data.typed == 'WhatsApp' && element.sequence == this._data.Index && element.deliveryTypeId == this._data.typeId) {
                    let whatsappReq: any = {
                        "groups": [],
                        "reports": [],
                        "recipients": contactData,
                        "messageText":  element.messageText,
                        "links": element.links
                    };
                    this._fuseSplashScreenService.show();
                    this._mailService.sendWhatsappMessage(whatsappReq)
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success(res.data, 'Success');
                                this.matDialogRef.close();
                                return
                            } else {
                                this._toastr.error('WhatsApp Error: ' + res.error, 'Error');
                            }
                        },
                            error => {
                                this._fuseSplashScreenService.hide();
                                if (error.error.error) {
                                    this._toastr.error('WhatsApp Error: ' + error.error.error, 'Error');
                                }
                                else {
                                    this._toastr.error('WhatsApp Error: ' + error.error, 'Error');
                                }
                            }
                        );
                }
                if (this._data.typeId == 4 && this._data.typed == 'Line' && element.sequence == this._data.Index && element.deliveryTypeId == this._data.typeId) {
                    let lineReq: any = {
                        "groups": [],
                        "reports": [],
                        "recipients": contactData,
                        "messageText": element.messageText,
                        "links":  element.links
                    };
                    this._fuseSplashScreenService.show();
                    this._mailService.sendLineMessage(lineReq)
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success(res.data, 'Success');
                                this.matDialogRef.close();
                                return
                            } else {
                                this._toastr.error('Line Error: ' + res.error, 'Error');
                            }
                        },
                            error => {
                                this._fuseSplashScreenService.hide();
                                if (error.error.error) {
                                    this._toastr.error('Line Error: ' + error.error.error, 'Error');
                                }
                                else {
                                    this._toastr.error('Line Error: ' + error.error, 'Error');
                                }
                            }
                        );
                }
                if (this._data.typeId == 5 && this._data.typed == 'Facebook' && element.sequence == this._data.Index && element.deliveryTypeId == this._data.typeId) {
                    let facebookReq: any = {
                        "groups": [],
                        "reports": [],
                        "recipients": contactData,
                        "messageText": element.messageText,
                        "links": element.links
                    };
                    this._fuseSplashScreenService.show();
                    this._mailService.sendFacebookMessage(facebookReq)
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success(res.data, 'Success');
                                this.matDialogRef.close();
                                return
                            } else {
                                this._toastr.error('Facebook Error: ' + res.error, 'Error');
                            }
                        },
                            error => {
                                this._fuseSplashScreenService.hide();
                                if (error.error.error) {
                                    this._toastr.error('Facebook Error: ' + error.error.error, 'Error');
                                }
                                else {
                                    this._toastr.error('Facebook Error: ' + error.error, 'Error');
                                }
                            }
                        );
                }
                if (this._data.typeId == 6 && this._data.typed == 'Telegram' && element.sequence == this._data.Index && element.deliveryTypeId == this._data.typeId) {
                    let telegramReq: any = {
                        "groups": [],
                        "reports": [],
                        "recipients": contactData,
                        "messageText": element.messageText,
                        "links": element.links
                    };
                    this._fuseSplashScreenService.show();
                    this._mailService.sendTelegramMessage(telegramReq)
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success(res.data, 'Success');
                                this.matDialogRef.close();
                                return
                            } else {
                                this._toastr.error('Telegram Error: ' + res.error, 'Error');
                            }
                        },
                            error => {
                                this._fuseSplashScreenService.hide();
                                if (error.error.error) {
                                    this._toastr.error('Telegram Error: ' + error.error.error, 'Error');
                                }
                                else {
                                    this._toastr.error('Telegram Error: ' + error.error, 'Error');
                                }
                            }
                        );
                }
                if ((this._data.typeId == 8 || this._data.typeId == 24) && this._data.typed == 'ShortCode' && element.sequence == this._data.Index && element.deliveryTypeId == this._data.typeId) {
                    let twilioReq: any = {
                        "deliveryTypeId":this._data.typeId,
                        "groups": [],
                        "reports": [],
                        "recipients": contactData,
                        "messageText": element.messageText,
                        "links": element.links,
                        "mediaURL": element.mediaURL
                    };
                    if (this._data && this._data.isVerifyMessage) {
                        twilioReq.isVerifyMessage = true;
                    }
                    this._fuseSplashScreenService.show();
                    var url;
                    if(this._data.typeId==8){
                        url="/TwilioMessage/SendToContact"
                    }
                    if(this._data.typeId==24){
                        url="/Infobip/SendMessageToContacts";
                    }
                    this._mailService.sendTwilioMessage(url,twilioReq)
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success(res.data, 'Success');
                                this.matDialogRef.close();
                                return
                            } else {
                                this._toastr.error('ShortCode Error: ' + res.error, 'Error');
                            }
                        },
                            error => {
                                this._fuseSplashScreenService.hide();
                                if (error.error.error) {
                                    this._toastr.error('ShortCode Error: ' + error.error.error, 'Error');
                                }
                                else {
                                    this._toastr.error('ShortCode Error: ' + error.error, 'Error');
                                }
                            }
                        );
                }
                 if (this._data.typeId == 9 && this._data.typed == 'Notification' && element.sequence == this._data.Index && element.deliveryTypeId == this._data.typeId) {
                    let notificationReq: any = {
                        "deliveryTypeId": 9,
                        "recipients": contactData,
                        "subject": element.subject,
                        "messageText": element.messageText,
                        "groups": [],
                        "reports": [],
                        "links": element.links,
                        "notificationTypeId":0
                    };
                    if (this._data && this._data.isVerifyMessage) {
                        notificationReq.isVerifyMessage = true;
                    }
                    this._fuseSplashScreenService.show();
                    this._mailService.NotificationMessage(notificationReq)
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success(res.data, 'Success');
                                this.matDialogRef.close();
                                return
                            } else {
                                this._toastr.error('Notification Error: ' + res.error, 'Error');
                            }
                        },
                            error => {
                                this._fuseSplashScreenService.hide();
                                if (error.error.error) {
                                    this._toastr.error('Notification Error: ' + error.error.error, 'Error');
                                }
                                else {
                                    this._toastr.error('Notification Error: ' + error.error, 'Error');
                                }
        
                            }
                        );
                }
                if (this._data.typeId == 13 && this._data.typed == 'International SMS' && element.sequence == this._data.Index && (element.deliveryTypeId == 13 || element.deliveryTypeId == 14)) {
                    let twilioReq: any = {
                        "groups": [],
                        "reports": [],
                        "recipients": contactData,
                        "messageText": element.messageText,
                        "links": element.links,
                        "isInternational": true,
                        "mediaURL": element.mediaURL
                    };
                    if (this._data && this._data.isVerifyMessage) {
                        twilioReq.isVerifyMessage = true;
                    }
                    this._fuseSplashScreenService.show();
                    var Url
                    if(element.deliveryTypeId == 13) {
                        Url = "/TwilioMessage/SendToContact";
                    }else if(element.deliveryTypeId == 14) {
                        Url = "/BandwidthMessage/SendToContact"; 
                    }
                    this._mailService.sendSMSMessage(twilioReq, Url)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this._toastr.success(res.data, 'Success');
                            this.matDialogRef.close();
                            return
                        } else {
                            this._toastr.error('International SMS Error: ' + res.error, 'Error');
                        }
                        },
                        error => {
                            this._fuseSplashScreenService.hide();
                            if (error.error.error) {
                                this._toastr.error('International SMS Error: ' + error.error.error, 'Error');
                            }
                            else {
                                this._toastr.error('International SMS Error: ' + error.error, 'Error');
                            }
                        }
                    );
        
                }
                if ((this._data.typeId == 18 || this._data.typeId == 19 || this._data.typeId == 20) && (this._data.typed == 'Verb Notification' || this._data.typed == 'NowTech Notification' || this._data.typed == 'Shout') && element.sequence == this._data.selectedStepIndex && element.deliveryTypeId == this._data.typeId) {
                    let verbNotificationReq: any = {
                        "deliveryTypeId": this._data.typeId,
                        "recipients": contactData,
                        "subject": element.subject,
                        "messageText": element.messageText,
                        "groups": [],
                        "reports": [],
                        "links": element.links,
                        "notificationTypeId":0
                    };
                    if (this._data && this._data.isVerifyMessage) {
                        verbNotificationReq.isVerifyMessage = true;
                    }
                    this._fuseSplashScreenService.show();
                    this._mailService.NotificationMessage(verbNotificationReq)
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success(res.data, 'Success');
                                this.matDialogRef.close();
                                return
                            } else {
                                this._toastr.error((this._data.typeId == 18 ? 'Verb Notification Error: ' : (this._data.typeId == 19 ? 'NowTech Notification Error: ' : 'Shout Error: ' )) + res.error, 'Error');
                            }
                        },
                            error => {
                                this._fuseSplashScreenService.hide();
                                if (error.error.error) {
                                    this._toastr.error((this._data.typeId == 18 ? 'Verb Notification Error: ' : (this._data.typeId == 19 ? 'NowTech Notification Error: ' : 'Shout Error: ' )) + error.error.error, 'Error');
                                }
                                else {
                                    this._toastr.error((this._data.typeId == 18 ? 'Verb Notification Error: ' : (this._data.typeId == 19 ? 'NowTech Notification Error: ' : 'Shout Error: ' )) + error.error, 'Error');
                                }
        
                            }
                        );
                }
            });
        }
    }
    getLinksFromHtml(htmlString, tag, text) {
        var doc = new DOMParser().parseFromString(htmlString, "text/html");
        var elements = doc.getElementsByTagName(tag);
        var found = [];
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].innerHTML.includes(text)) {
                found.push({
                    "name": elements[i]['innerText'].slice(1, -1),
                    "url": elements[i]['attributes']['href']['value']
                });
            }
        }
        return found;
    }
    getDripAdvanceArrayNew(typeId, eventtype, isMedia, obj) {
        let campaignAdvanceOptions = [];
          let tempObj = this._data.dripResponse ? (this._data.dripResponse.campaignAdvanceOptions.filter(x => (x.deliveryTypeId == typeId) || (x.deliveryTypeId==2 && typeId==11) || (x.deliveryTypeId==11 && typeId==2) || (x.deliveryTypeId==7 && typeId==12) || (x.deliveryTypeId==12 && typeId==7) || (x.deliveryTypeId==2 && typeId==7) || (x.deliveryTypeId==7 && typeId==2) || (x.deliveryTypeId==12 && typeId==11) || (x.deliveryTypeId==11 && typeId==12) || (x.deliveryTypeId==2 && typeId==12) || (x.deliveryTypeId==12 && typeId==2) || (x.deliveryTypeId==11 && typeId==7) || (x.deliveryTypeId==7 && typeId==11) || (x.deliveryTypeId==13 && typeId==14) || (x.deliveryTypeId==14 && typeId==13))) : null;
          let tempSeq = (tempObj && tempObj.length > 0) ? (tempObj.filter(x => (x.sequence == obj.sequence))) : null;
          if (obj.messageText || obj.mediaURL) {
            var tempLinks = [];
            if (obj.isBeeEditor) {
              tempLinks = this.getLinksFromHtml(obj.messageText, 'a', '[Link');
            }
            else {
              tempLinks = obj.links;
            }
            campaignAdvanceOptions.push({
              ...((tempSeq && tempSeq.length > 0) ? tempSeq[0] : {}),
              "sequence": obj.sequence,
              "deliveryTypeId": typeId,
              "subject": obj.subject,
              "messageText": obj.messageText,
              "optOutTrailor": typeId > 1 ? obj.optOutTrailor : '',
              "sendDate": (obj.isImmediate || eventtype == 2 || eventtype == 3 || eventtype == 5) ? null : (obj.sendDate ? moment(moment(obj.sendDate).format("YYYY-MM-DD") + ' ' + obj.sendTime + ':00').format("YYYY-MM-DD HH:mm:ss") : null),
              "timeZone": (obj.isImmediate || eventtype == 2 || eventtype == 3 || eventtype == 5) ? '' : (obj.timeZone),
              "tinyURL": obj.messageTinyURL,
              "links": tempLinks,
              "mediaURL": isMedia ? obj.mediaURL : '',
              "interval": obj.isImmediate ? '' : parseInt(obj.interval),
              "timeUnit": obj.isImmediate ? '' : obj.timeUnit,
              "isImmediate": obj.isImmediate,
              "messageJson": obj.messageJson ? JSON.stringify(obj.messageJson) : "",
              "isBeeEditor": obj.isBeeEditor ? obj.isBeeEditor : false,
              "notificationTypeId": 0,
              "domainType": obj.domainType ? parseInt(obj.domainType) : 0,
              "attachments": obj.attachments ? obj.attachments : []
            });
          }
        return campaignAdvanceOptions;
      }
}

