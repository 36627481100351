import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MailService } from 'app/main/pages/mail/mail.service';
import { RestService } from '../../../../../shared/rest.service';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '../../../../../../@fuse/services/splash-screen.service';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import * as _ from 'lodash';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { PageService } from 'app/main/pages/pages.service';
import * as moment from "moment-timezone";
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'contacts-filters-dialog',
    templateUrl: './contacts-filters.component.html',
    styleUrls: ['./contacts-filters.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ContactsFiltersComponent {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    action: string;
    dialogType: string;
    filterForm: FormGroup;
    customFieldsFilterForm: FormGroup;
    customFieldsCompareFrom: FormGroup;
    dialogTitle: string;
    countries: any[] = [];
    states: any[] = [];
    ranks: any[] = [];
    paidAsRanks: any[] = [];
    highestRanks: any[] = [];
    associateTypes: any[] = [];
    associateStatus: any[] = [];
    languages: any[] = [];
    customFiledList: any[] = [];
    optFilters: any[] = [
        {
            name: 'SMS Opt In',
            value: 1
        },
        {
            name: 'SMS Opt Out',
            value: 2
        },
        {
            name: 'Email Opt In',
            value: 3
        },
        {
            name: 'Email Opt Out',
            value: 4
        }
    ];
    statusFilters: any[] = [
        {
            id: 1,
            name: "Valid Mobile"
        },
        {
            id: 2,
            name: "Valid Email"
        },
        {
            id: 3,
            name: "Invalid Mobile"
        },
        {
            id: 4,
            name: "Invalid Email"
        }
    ];
    activeAutoShip: any[] = [
        {
            id: true,
            status: "Yes"
        },
        {
            id: false,
            status: "No"
        } 
    ]
    private _unsubscribeAll: Subject<any>;
    filterRank: FormControl = new FormControl('');
    filterCountry: FormControl = new FormControl('');
    filterState: FormControl = new FormControl('');
    filterAssociate: FormControl = new FormControl('');
    filterLanguage: FormControl = new FormControl('');
    filterChannel: FormControl = new FormControl('');
    public date = new Date()
    public currentyear: number = this.date.getFullYear()

    maxdate = new Date(this.currentyear, 12, 1)
    minDate = new Date(2000, 0, 1);
    offset = new Date().getTimezoneOffset();
    filterOffset = (this.offset >= 720) ? 719 : (this.offset < -720) ? -600 : this.offset;
    accountTypes: any[] = [];
    constructor(
        public matDialogRef: MatDialogRef<ContactsFiltersComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _contactsService: ContactsService,
        private _restService: RestService,
        public _pageService: PageService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _mailService: MailService,
        public _matDialog: MatDialog
    ) {
        for(let i= 1; i <=20; i++) {
            this.customFiledList.push(i);
        }
        // Set the defaults
        this.action = _data.action;
        this.dialogType = _data.dialogType;
        this.dialogTitle = _data.dialogTitle;
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.filterForm = this.createFilterForm();
        this.customFieldsFilterForm = this.createCustomFieldsFilterForm();
        this.customFieldsCompareFrom = this.createCustomFieldsCompareFrom();
    }
    ngOnInit(): void {
        this.getCountries();
        this.getCountryStates(["US"]);
        this.getRanks();
        this.getAssociates();
        this.getAssociatesStatus();
        this.getLanguages();
        this._pageService.onAccountTypeByUserChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(types => {
            if (types) {
                this.accountTypes = types.filter(type => (type.id != 9));
            }
            else {
                this.accountTypes = [];
            }
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    createFilterForm(): FormGroup {
        return this._formBuilder.group({
            paidAsRanks: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.paidAsRanks : []],
            highestRanks: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.highestRanks : []],
            countries: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.countries : []],
            states: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.states : []],
            associateTypes: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.associateTypes : []],
            associateStatus: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.associateStatus : []],
            languageCodes: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.languageCodes : []],
            invalidData: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.invalidData : []],
            optFilter: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.optFilter : []],
            // searchByFromDate: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.fromDate ? moment.utc(this._contactsService.appledFilters.value.fromDate).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '') : ''],
            // searchByToDate: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.toDate ? moment.utc(this._contactsService.appledFilters.value.toDate).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '') : ''],
            searchByFromDate: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.fromDate ? this._contactsService.appledFilters.value.fromDate+'T00:00:00' : '') : ''],
            searchByToDate: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.toDate ? this._contactsService.appledFilters.value.toDate+'T00:00:00' : '') : ''],
            groupRangeFrom: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.groupRangeFrom ? this._contactsService.appledFilters.value.groupRangeFrom : 0) : 0],
            groupRangeTo: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.groupRangeTo ? this._contactsService.appledFilters.value.groupRangeTo : 0) : 0],
            hasActiveAutoship: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.hasActiveAutoship : null],
            channels: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.channels : []],
            sponsorId: [this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value.sponsorId : 0]
        });
    }
    createCustomFieldsFilterForm(): FormGroup {
        return this._formBuilder.group({
            custom1: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom1 ? this._contactsService.appledFilters.value.customFields.custom1 : '') : '') : ''],
            custom2: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom2 ? this._contactsService.appledFilters.value.customFields.custom2 : '') : '') : ''],
            custom3: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom3 ? this._contactsService.appledFilters.value.customFields.custom3 : '') : '') : ''],
            custom4: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom4 ? this._contactsService.appledFilters.value.customFields.custom4 : '') : '') : ''],
            custom5: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom5 ? this._contactsService.appledFilters.value.customFields.custom5 : '') : '') : ''],
            custom6: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom6 ? this._contactsService.appledFilters.value.customFields.custom6 : '') : '') : ''],
            custom7: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom7 ? this._contactsService.appledFilters.value.customFields.custom7 : '') : '') : ''],
            custom8: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom8 ? this._contactsService.appledFilters.value.customFields.custom8 : '') : '') : ''],
            custom9: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom9 ? this._contactsService.appledFilters.value.customFields.custom9 : '') : '') : ''],
            custom10:[this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom10 ? this._contactsService.appledFilters.value.customFields.custom10 : '') : '') : ''],
            custom11: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom11 ? this._contactsService.appledFilters.value.customFields.custom11 : '') : '') : ''],
            custom12: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom12 ? this._contactsService.appledFilters.value.customFields.custom12 : '') : '') : ''],
            custom13: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom13 ? this._contactsService.appledFilters.value.customFields.custom13 : '') : '') : ''],
            custom14: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom14 ? this._contactsService.appledFilters.value.customFields.custom14 : '') : '') : ''],
            custom15: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom15 ? this._contactsService.appledFilters.value.customFields.custom15 : '') : '') : ''],
            custom16: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom16 ? this._contactsService.appledFilters.value.customFields.custom16 : '') : '') : ''],
            custom17: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom17 ? this._contactsService.appledFilters.value.customFields.custom17 : '') : '') : ''],
            custom18: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom18 ? this._contactsService.appledFilters.value.customFields.custom18 : '') : '') : ''],
            custom19: [this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom19 ? this._contactsService.appledFilters.value.customFields.custom19 : '') : '') : ''],
            custom20:[this._contactsService.appledFilters.value ? (this._contactsService.appledFilters.value.customFields ? (this._contactsService.appledFilters.value.customFields.custom20 ? this._contactsService.appledFilters.value.customFields.custom20 : '') : '') : '']
        });
    }
    createCustomFieldsCompareFrom(): FormGroup {
        return this._formBuilder.group({
            compare1: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare1 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare1 : true],
            compare2: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare2 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare2 : true],
            compare3: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare3 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare3 : true],
            compare4: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare4 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare4 : true],
            compare5: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare5 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare5 : true],
            compare6: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare6 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare6 : true],
            compare7: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare7 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare7 : true],
            compare8: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare8 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare8 : true],
            compare9: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare9 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare9 : true],
            compare10: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare10 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare10 : true],
            compare11: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare11 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare11 : true],
            compare12: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare12 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare12 : true],
            compare13: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare13 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare13 : true],
            compare14: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare14 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare14 : true],
            compare15: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare15 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare15 : true],
            compare16: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare16 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare16 : true],
            compare17: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare17 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare17 : true],
            compare18: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare18 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare18 : true],
            compare19: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare19 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare19 : true],
            compare20: [(this._contactsService.appledFilters.value && this._contactsService.appledFilters.value.customFieldsCompare && this._contactsService.appledFilters.value.customFieldsCompare.compare20 != undefined) ? this._contactsService.appledFilters.value.customFieldsCompare.compare20 : true],
        });
    }
    getCountries() {
        this._pageService.getAllCountries().subscribe(
            (res: any) => {
                if (res) {
                    this.countries = res;
                }
                else {
                    this.countries = [];
                }
            }
        )
    }
    getAssociates() {
        this._pageService.getAssociateTypes().subscribe(
            (res: any) => {
                if (res) {
                    this.associateTypes = res.data;
                }
                else {
                    this.associateTypes = [];
                }
            }
        )
    }
    getAssociatesStatus() {
        this._pageService.getAssociateStatus().subscribe(
            (res: any) => {
                if (res) {
                    this.associateStatus = res.data;
                }
                else {
                    this.associateStatus = [];
                }
            }
        )
    }
    getLanguages() {
        this._pageService.GetAllLanguages().subscribe(
            (res: any) => {
                if (res) {
                    this.languages = res;
                }
                else {
                    this.languages = [];
                }
            }
        )
    }
    getRanks() {
        this._pageService.getRanks().subscribe(
            (res: any) => {
                if (res) {
                    this.ranks = res.data;
                    this.paidAsRanks = res.data.filter(e => e.isPaidAsRank);
                    this.highestRanks = res.data.filter(e => e.isHighestRank);
                }
                else {
                    this.ranks = [];
                    this.paidAsRanks = [];
                    this.highestRanks = [];
                }
            }
        )
    }
    addFilters() {
        if (this.filterForm.valid) {
            let filterData = this.filterForm.getRawValue();
            let customFieldsFilterData = this.customFieldsFilterForm.getRawValue();
            let customFieldsCompareData = this.customFieldsCompareFrom.getRawValue();
            let appliedFilters = {
                "paidAsRanks": filterData.paidAsRanks,
                "highestRanks": filterData.highestRanks,
                "countries": filterData.countries,
                "states": filterData.countries.includes(223) ? filterData.states : [],
                "associateTypes": filterData.associateTypes,
                "associateStatus": filterData.associateStatus,
                "languageCodes": filterData.languageCodes,
                "invalidData": filterData.invalidData,
                "optFilter": filterData.optFilter,
                "fromDate": filterData.searchByFromDate ? moment.utc(filterData.searchByFromDate).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
                "toDate": filterData.searchByToDate ? moment.utc(filterData.searchByToDate).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
                "groupRangeFrom": filterData.groupRangeFrom ? filterData.groupRangeFrom : 0,
                "groupRangeTo": filterData.groupRangeTo ? filterData.groupRangeTo : 0,
                "customFields": null,
                "hasActiveAutoship": filterData.hasActiveAutoship,
                "channels": filterData.channels,
                "customFieldsCompare": null,
                "sponsorId": filterData.sponsorId ? filterData.sponsorId : 0
            };
            if (customFieldsFilterData.custom1 || customFieldsFilterData.custom2 || customFieldsFilterData.custom3 || customFieldsFilterData.custom4 || customFieldsFilterData.custom5 || customFieldsFilterData.custom6 || customFieldsFilterData.custom7 || customFieldsFilterData.custom8 || customFieldsFilterData.custom9 || customFieldsFilterData.custom10 ||
                customFieldsFilterData.custom11 || customFieldsFilterData.custom12 || customFieldsFilterData.custom13 || customFieldsFilterData.custom14 || customFieldsFilterData.custom15 || customFieldsFilterData.custom16 || customFieldsFilterData.custom17 || customFieldsFilterData.custom18 || customFieldsFilterData.custom19 || customFieldsFilterData.custom20) {
                appliedFilters.customFields = {
                    "custom1": customFieldsFilterData.custom1 ? customFieldsFilterData.custom1 : null,
                    "custom2": customFieldsFilterData.custom2 ? customFieldsFilterData.custom2 : null,
                    "custom3": customFieldsFilterData.custom3 ? customFieldsFilterData.custom3 : null,
                    "custom4": customFieldsFilterData.custom4 ? customFieldsFilterData.custom4 : null,
                    "custom5": customFieldsFilterData.custom5 ? customFieldsFilterData.custom5 : null,
                    "custom6": customFieldsFilterData.custom6 ? customFieldsFilterData.custom6 : null,
                    "custom7": customFieldsFilterData.custom7 ? customFieldsFilterData.custom7 : null,
                    "custom8": customFieldsFilterData.custom8 ? customFieldsFilterData.custom8 : null,
                    "custom9": customFieldsFilterData.custom9 ? customFieldsFilterData.custom9 : null,
                    "custom10": customFieldsFilterData.custom10 ? customFieldsFilterData.custom10 : null,
                    "custom11": customFieldsFilterData.custom11 ? customFieldsFilterData.custom11 : null,
                    "custom12": customFieldsFilterData.custom12 ? customFieldsFilterData.custom12 : null,
                    "custom13": customFieldsFilterData.custom13 ? customFieldsFilterData.custom13 : null,
                    "custom14": customFieldsFilterData.custom14 ? customFieldsFilterData.custom14 : null,
                    "custom15": customFieldsFilterData.custom15 ? customFieldsFilterData.custom15 : null,
                    "custom16": customFieldsFilterData.custom16 ? customFieldsFilterData.custom16 : null,
                    "custom17": customFieldsFilterData.custom17 ? customFieldsFilterData.custom17 : null,
                    "custom18": customFieldsFilterData.custom18 ? customFieldsFilterData.custom18 : null,
                    "custom19": customFieldsFilterData.custom19 ? customFieldsFilterData.custom19 : null,
                    "custom20": customFieldsFilterData.custom20 ? customFieldsFilterData.custom20 : null,
                };
                appliedFilters.customFieldsCompare = {
                    "compare1": customFieldsCompareData.compare1,
                    "compare2": customFieldsCompareData.compare2,
                    "compare3": customFieldsCompareData.compare3,
                    "compare4": customFieldsCompareData.compare4,
                    "compare5": customFieldsCompareData.compare5,
                    "compare6": customFieldsCompareData.compare6,
                    "compare7": customFieldsCompareData.compare7,
                    "compare8": customFieldsCompareData.compare8,
                    "compare9": customFieldsCompareData.compare9,
                    "compare10": customFieldsCompareData.compare10,
                    "compare11": customFieldsCompareData.compare11,
                    "compare12": customFieldsCompareData.compare12,
                    "compare13": customFieldsCompareData.compare13,
                    "compare14": customFieldsCompareData.compare14,
                    "compare15": customFieldsCompareData.compare15,
                    "compare16": customFieldsCompareData.compare16,
                    "compare17": customFieldsCompareData.compare17,
                    "compare18": customFieldsCompareData.compare18,
                    "compare19": customFieldsCompareData.compare19,
                    "compare20": customFieldsCompareData.compare20,
                };
            }
            if (filterData.paidAsRanks.length > 0 || filterData.highestRanks.length > 0 || filterData.countries.length > 0 || filterData.states.length > 0 || filterData.associateTypes.length > 0 || filterData.associateStatus.length > 0
                || filterData.languageCodes.length > 0 || filterData.invalidData.length > 0 || filterData.optFilter.length > 0 || filterData.channels.length > 0 || filterData.sponsorId ||
                filterData.searchByFromDate || filterData.searchByToDate || filterData.groupRangeFrom || filterData.groupRangeTo || filterData.hasActiveAutoship != null || appliedFilters.customFields) {
                this._contactsService.appledFilters.next(appliedFilters);
            }
            else {
                this._contactsService.appledFilters.next(null);
            }
            this.matDialogRef.close();
        }
    }
    clearFilters() {
        this.filterForm.patchValue({
            paidAsRanks: [],
            highestRanks: [],
            countries: [],
            states: [],
            associateTypes: [],
            associateStatus: [],
            languageCodes: [],
            invalidData: [],
            optFilter: [],
            searchByToDate: '',
            searchByFromDate: '',
            groupRangeFrom: 0,
            groupRangeTo: 0,
            hasActiveAutoship: null,
            channels: [],
            sponsorId: 0
        });
        this.customFieldsFilterForm.patchValue({
            custom1: '',
            custom2: '',
            custom3: '',
            custom4: '',
            custom5: '',
            custom6: '',
            custom7: '',
            custom8: '',
            custom9: '',
            custom10: '',
            custom11: '',
            custom12: '',
            custom13: '',
            custom14: '',
            custom15: '',
            custom16: '',
            custom17: '',
            custom18: '',
            custom19: '',
            custom20: '',
        });
        this.customFieldsCompareFrom.patchValue({
            compare1: true,
            compare2: true,
            compare3: true,
            compare4: true,
            compare5: true,
            compare6: true,
            compare7: true,
            compare8: true,
            compare9: true,
            compare10: true,
            compare11: true,
            compare12: true,
            compare13: true,
            compare14: true,
            compare15: true,
            compare16: true,
            compare17: true,
            compare18: true,
            compare19: true,
            compare20: true,
        });
        if (this._contactsService.appledFilters.value) {
            this._contactsService.appledFilters.next(null);
        }
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("contact-filters-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("contactfilter-content").style.maxHeight = "65vh";
        document.getElementById("contactfilter-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("contact-filters-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("contactfilter-content").style.maxHeight = "100vh";
        document.getElementById("contactfilter-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    getCountryStates(countryCodes) {
        this._pageService.getCountryStates(countryCodes).subscribe((res) => {
            this.states = res;
        });
    }
}

