import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseConfirmDialogModule, FuseSidebarModule, PartialSplashLoaderModule } from '@fuse/components';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import {FuseWidgetModule} from '@fuse/components/widget/widget.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import { MatExpansionModule, MatSlideToggleModule } from '@angular/material';
import { FuseSureDialogModule } from './components/sure-dialog/sure-dialog.module';
const MaterialModules=[
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        FlexLayoutModule,
        MatDividerModule,
        MatTabsModule,
        MatPaginatorModule,
        MatSortModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatExpansionModule
];
const fuseModules=[
    FuseDirectivesModule,
    FusePipesModule,
    FuseWidgetModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    PartialSplashLoaderModule,
    FuseSureDialogModule
]
@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ...MaterialModules,
        ...fuseModules
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ...MaterialModules,
        ...fuseModules
    ]
})
export class FuseSharedModule
{
}
