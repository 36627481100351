import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Account, AccountType } from 'app/main/pages/accounts/account.model';
import { RestService } from '../../../shared/rest.service';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from '../pages.service';

@Injectable()
export class AccountsService implements Resolve<any>
{
    userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
    isAgentRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
    onAccountsChanged: BehaviorSubject<any>;
    onSelectedAccountsChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    accounts: Account[];
    accountTypes: AccountType[];
    accountTypesByUser: AccountType[];
    selectedAccounts: any[] = [];
    searchText: string;
    filterBy: any;
    filterByType: any;
    /**
     * Constructor
     *
     * @param _restService: RestService
     */
    constructor(
        private _restService: RestService,
        private _authService: AuthService,
        private _pageService: PageService
    ) {
        // Set the defaults
        this.onAccountsChanged = new BehaviorSubject([]);
        this.onSelectedAccountsChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.init()
            ]).then(
                ([files]) => {
                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getAccounts();
                    });
                    this.onFilterChanged.subscribe(filter => {
                        if (filter == 'all') {
                            this.getAccounts();
                        }
                        else {
                            this.filterByType = filter;
                            this.getAccountsByType(this.filterByType);
                        }
                    });
                    resolve('');

                },
                reject
            );
        });
    }
    init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
            this.isAgentRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
            this.onAccountsChanged = new BehaviorSubject([]);
            this.onSelectedAccountsChanged = new BehaviorSubject([]);
            this.onSearchTextChanged = new Subject();
            this.onFilterChanged = new Subject();
            this._pageService.getAccountsTypesByUser();
            this.deselectAccount();
            this.getAccounts();
            resolve('initialized');
        });
    }
    /**
     * Get accounts
     *
     * @returns {Promise<any>}
     */

    getAccounts(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._restService.Get('/UnifiedAccount/GetAllUnifiedAccounts?isAgent=' + this._pageService.isAgentView)
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.accounts = response.data;
                        this.accounts = this.accounts.map(account => {
                            return new Account(account);
                        });

                        this.onAccountsChanged.next(this.accounts);
                        resolve(this.accounts);
                    }
                }, reject);
        }
        );
    }


    getAccountsByType(typeid): Promise<any> {
        return new Promise((resolve, reject) => {
            this._restService.Get('/UnifiedAccount/GetUnifiedAccountByUserAndType' + '/' + typeid + '?isAgent=' + this._pageService.isAgentView)
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        if (response.data) {
                            this.accounts = [response.data];
                        }
                        else {
                            this.accounts = [];
                        }
                        this.accounts = this.accounts.map(account => {
                            return new Account(account);
                        });
                        this.onAccountsChanged.next(this.accounts);
                        resolve(this.accounts);
                    }
                }, reject);
        }
        );
    }

    /**
     * Toggle selected account by id
     *
     * @param id
     */
    toggleSelectedAccount(id): void {
        // First, check if we already have that account as selected...
        if (this.selectedAccounts.length > 0) {
            const index = this.selectedAccounts.indexOf(id);

            if (index !== -1) {
                this.selectedAccounts.splice(index, 1);

                // Trigger the next event
                this.onSelectedAccountsChanged.next(this.selectedAccounts);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedAccounts.push(id);

        // Trigger the next event
        this.onSelectedAccountsChanged.next(this.selectedAccounts);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedAccounts.length > 0) {
            this.deselectAccount();
        }
        else {
            this.selectAccount();
        }
    }

    /**
     * Select accounts
     *
     * @param filterParameter
     * @param filterValue
     */
    selectAccount(filterParameter?, filterValue?): void {
        this.selectedAccounts = [];

        // If there is no filter, select all accounts
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedAccounts = [];
            this.accounts.map(account => {
                this.selectedAccounts.push(account.id);
            });
        }

        // Trigger the next event
        this.onSelectedAccountsChanged.next(this.selectedAccounts);
    }

    /**
     * Deselect accounts
     */
    deselectAccount(): void {
        this.selectedAccounts = [];

        // Trigger the next event
        this.onSelectedAccountsChanged.next(this.selectedAccounts);
    }



    /**
     * Delete selected accounts
     */
    deleteselectedAccounts(): void {
        for (const accountId of this.selectedAccounts) {
            const account = this.accounts.find(_account => {
                return _account.id === accountId;
            });
            const accountIndex = this.accounts.indexOf(account);
            this.accounts.splice(accountIndex, 1);
        }
        this.onAccountsChanged.next(this.accounts);
        this.deselectAccount();
    }
    getAccountById(id: any) {
        return this._restService.Get('/UnifiedAccount/GetUnifiedAccountById/' + id)
    }
    saveAccount(AccountRequest: any) {
        var _url = "/UnifiedAccount/CreateUnifiedAccount";
        return this._restService.Post(_url, AccountRequest);
    }
    editAccount(AccountRequest: any) {
        var _url = "/UnifiedAccount/UpdateUnifiedAccount";
        return this._restService.Post(_url, AccountRequest);
    }
    /**
     * Delete account
     *
     * @param account
     */
    deleteAccount(account) {
        var _url = "/UnifiedAccount/DeleteUnifiedAccount/" + account.unifiedTypeId;
        return this._restService.Delete(_url, null);
    }
    GetTelegramMeByToken(token: any) {
        return this._restService.Get('/TelegramMessage/GetTelegramMeByToken?token=' + token)
    }

}
