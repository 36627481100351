import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { ClickMapLinkDetailsComponent } from '../click-map-link-details/click-map-link-details.component';
import { FormControl } from '@angular/forms';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-click-map-link',
  templateUrl: './click-map-link.component.html',
  styleUrls: ['./click-map-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class ClickMapLinkComponent implements OnInit {
  IsDialogMax: boolean = false;
  dialogRef:any;
  campaignId: any;
  linkArray: any[] = [];
  clicksPer: any;
  uniqueClicksLinkPer: any;
  totalContacts: any;
  totalClickPer: any;
  totalClickPerUnique: any;
  showSummary: boolean = false;
  clickMapLink: FormControl = new FormControl(0);
  urlData: any[] =[];
  linkParentElement: any;
  urlDetailList: any[] =[];
  constructor(
    public matDialogRef: MatDialogRef<ClickMapLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matDialog: MatDialog,
    private _campaignsService: CampaignsService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.getTrackingDataForURL();
    this.clicksPer = ((parseFloat(this._data.item.clicks) / parseFloat(this._data.totalClicks)) * 100).toFixed(2);
    this.uniqueClicksLinkPer = ((parseFloat(this._data.item.urlUniqueClicks) / parseFloat(this._data.uniqueClicks)) * 100).toFixed(2);
  }

  showSummaryCard() {
    this.showSummary = !this.showSummary;
  }
  
  clickMapLinkDetails(type, isClickMapPage): void {
    this.dialogRef = this._matDialog.open(ClickMapLinkDetailsComponent, {
        panelClass: 'clickMap-link-details',
        disableClose: true,
        data: {
          CampaignId: this._data.CampaignId,
          htmlLink: this._data.item.htmlLink,
          isClickMapPage: isClickMapPage,
          totalContacts: this._data.item.urlUniqueClicks,
          type: type,
        }
    });
    this.dialogRef.afterClosed()
        .subscribe((response: any) => {

    });
       
  }
  getTrackingDataForURL() {
    let linkDetailsReq: any = {
      "campaignId": this._data.CampaignId,
      "messageId": 0,
      "pageNumber": 1,
      "pageSize": 100,
      "searchText": '',
      "isDrip": 0,
      "withContact": false,
      "uRL": this._data.item.htmlLink
    }
    this._fuseSplashScreenService.show();
    this._campaignsService.GetTrackingDataForCampaign(linkDetailsReq, false).subscribe((res: any) => {
      if (res.status == 200) {
        this._fuseSplashScreenService.hide();
        this.urlDetailList = res.data['report'][0];
      } else {
        this._toastr.error(res.message, 'Error');
      }
    },
      error => {
        this._fuseSplashScreenService.hide();
        this.urlDetailList = [];
        this._toastr.error(error.message, 'Error');
      });
  }

}
