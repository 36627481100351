import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MailService } from 'app/main/pages/mail/mail.service';
import { FuseSplashScreenService } from '../../../../../@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import {ProvidersService} from 'app/main/pages/providers/providers.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AddToMenu } from '../addTo-menu/addTo-menu.component';
import { PageService } from 'app/main/pages/pages.service';
@Component({
    selector: 'email-template-catalog',
    templateUrl: './email-template-catalog.component.html',
    styleUrls: ['./email-template-catalog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class emailTemplateCatalogDialogComponent {
    emailTemplates: any = [];
    preBuildTemplates: any = [];
    savedTemplates: any = [];
    dialogRef: any;
    notificationTemplates: any = [];
    savedNotificationTemplates: any = [];
    preBuildNotificationTemplates: any = [];
    menuId:any=0;
    public sideMenuApiList = {
        getMenuListApi: "/Template/GetAllTemplatesMenu",
        addEditMenuApi: "/Template/AddEditTemplateMenu",
        deleteMenuApi: "/Template/DeleteTemplateMenu/"
      }
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    constructor(
        public _matDialog: MatDialog,
        private _mailService: MailService,
        private _toastr: ToastrService,
        private _snackBar: MatSnackBar,
        private _ProvidersService: ProvidersService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public matDialogRef: MatDialogRef<emailTemplateCatalogDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
        private _pageService: PageService

    ) {
        // Set the private default
    }

    ngOnInit(): void {
        //this.emailTemplates = this._data.emailTemplates ? this._data.emailTemplates : [];
        this.getTemplates();
    }
    getTemplates() {
        this._fuseSplashScreenService.show();
        this._mailService.getAllTemplates(this.menuId)
            .subscribe((res: any) => {
                if (res.status == 200) {
                    if(this._data.accTyped == 1 || this._data.accTyped == 21){
                        this.emailTemplates = res.data.filter(x => ((x.unifiedTypeId == 1 || x.unifiedTypeId == 21) && x.isBeeEditor));
                        this.preBuildTemplates = this.emailTemplates.filter(x => (x.isPredefined));
                        this.savedTemplates = this.emailTemplates.filter(x => (!x.isPredefined));
                        console.log("1111",this.savedTemplates)
                    }
                    if(this._data.accTyped == 9) {
                        this.notificationTemplates = res.data.filter(x => (x.unifiedTypeId == 9 && x.isBeeEditor));
                        this.preBuildNotificationTemplates = this.notificationTemplates.filter(x => (x.isPredefined));
                        this.savedNotificationTemplates = this.notificationTemplates.filter(x => (!x.isPredefined));
                    }
                   
                }
                this._fuseSplashScreenService.hide();
            },
                error => {
                    this._toastr.error(error.message, 'Error');
                    this._fuseSplashScreenService.hide();
                }
            );
    }
    useTemplate(template) {
        this.matDialogRef.close(template);
    }
    deleteTemplate(template) {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {  
        this._fuseSplashScreenService.show();
        this._ProvidersService.DeleteTemplate(template.id).subscribe((res: any) => {
            if (res.status == 200) {
                this._toastr.success(res.data, 'Success');
                this.getTemplates();
            }
            this._fuseSplashScreenService.hide();
        },
            error => {
                this._toastr.error(error.message, 'Error');
                this._fuseSplashScreenService.hide();
            }
        );
     }
        this.confirmDialogRef = null;
       });
    }

    
  addToFolder(item?) {
    this.dialogRef = this._matDialog.open(AddToMenu, {
      panelClass: "addTo-menu-dialog",
      disableClose: true,
      data: {
          getAllMenuListApi: '/Template/GetAllTemplatesMenu'
      }
  });
  this.dialogRef.afterClosed().subscribe((response: any) => {
          if (!response) {
              return;
          }
          this._fuseSplashScreenService.show();
          let url = '/Template/UpdateTemplateMenuId?MenuId='+response+'&TemplateId='+item.id
          this._pageService.addToMenu(url).subscribe((res: any) => {
                    if (res.status == 200) {
                        this.menuId=item.menuId
                        this.getTemplates();
                        this._toastr.success(res.data, 'Success');
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._toastr.error(error.error.error, 'Error');
                    }
                );
      });
  }
  removeToFolder(item) {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: false
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to remove from folder?';
    
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._fuseSplashScreenService.show();
          this._mailService.UpdateTemplateMenuId(0,item.id).subscribe((res: any) => {
                    if (res.status == 200) {
                        this.menuId=item.menuId
                        this.getTemplates();
                        this._toastr.success(res.data, 'Success');
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._toastr.error(error.error.error, 'Error');
                    }
                );
        }
        this.confirmDialogRef = null;
      })
      
  }
  onMenuIdChange(id) {
    this.menuId=id;
    this.getTemplates();
  }
  onTabChange() {
    this._pageService.onEmailTemplateCatlogChange.next(true);
    this.menuId=0;
    this.getTemplates();
  }

}

