import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from
  '@angular/router';
import { AuthService } from './shared/auth.service';
import { Router } from '@angular/router';
import { views } from 'app/navigation/navigation';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _auth: AuthService,
    private _route: Router,
    private _toastr: ToastrService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

    if (this._auth.isLoggedIn()) {
      let currentState = this.findItems(views, state.url);
      let LoggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserData"));
      let role: any = LoggedInUserData.roles[0];
      let companyRoles: any = LoggedInUserData.companyRoles ? LoggedInUserData.companyRoles : [];
      let agentRoles: any = LoggedInUserData.agentRoles ? LoggedInUserData.agentRoles.pages : (LoggedInUserData.companyRoles ? LoggedInUserData.companyRoles : []);
      if (currentState) {
        if (role == 'company') {
          if (companyRoles.length > 0) {
            if (this.isPageExist(companyRoles, currentState.id)) {
              return true;
            }
            else {
              this._auth.logout();
              this._toastr.warning('Please contact to admin for ' + currentState.title + ' permission', 'Warning');
            }
          }
          else {
            this._auth.logout();
            this._toastr.warning('Please contact to admin for permissions', 'Warning');
          }
        }
        else if (role == 'agent') {
          if (agentRoles.length > 0) {
            if (this.isPageExist(agentRoles, currentState.id)) {
              return true;
            }
            else {
              this._auth.logout();
              this._toastr.warning('Please contact to admin for ' + currentState.title + ' permissions', 'Warning');
            }
          }
          else {
            this._auth.logout();
            this._toastr.warning('Please contact to admin for permissions', 'Warning');
          }
        }
        else {
          return true;
        }
      }
      else {
        return true;
      }
    } else {
      this._route.navigate(["/login"]);
      return false;
    }

  }
  isPageExist(arr: any, page: string) {
    return arr.some(function (el) {
      return el == page;
    });
  }
  findItems(obj: any, url: string) {
    let foundObj = obj.filter(x => ((x.type === 'item') && (url.toLowerCase().includes(x.url.toLowerCase()))));
    if (foundObj && foundObj.length > 0) {
      return foundObj[0];
    }

    let collapsableArr = obj.filter(x => ((x.type === 'collapsable')));
    let groupObj;
    if (collapsableArr && collapsableArr.length > 0) {
      collapsableArr.forEach((child) => {
        if (child.id === 'learning' && (url.toLowerCase().includes('learning'))) {
          groupObj = child;
        }
        else {
          let foundObj = child.children.filter(x => ((x.type === 'item') && (url.toLowerCase().includes(x.url.toLowerCase()))));
          if (foundObj && foundObj.length > 0) {
            groupObj = foundObj[0];
          }
        }
      });
      if (groupObj) {
        return groupObj;
      }
    }
  }
}
