import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { BASE_URL } from './constants';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private _router : Router,
        private _authService : AuthService,
        private _toastr: ToastrService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (this.getBasePath(request.url) == BASE_URL && err.status === 401) {
                //this._toastr.error('Unautherised user, Please login again!!','Error');
                this._authService.logout();
                return throwError('Error');
            }
            else {
                return throwError(err);
            }           
        }))
    }
    getBasePath(url) {
        var r = ('' + url).match(/^(https?:)?\/\/[^/]+/i);
        return r ? r[0] : '';
    }
}