import { Component, ViewEncapsulation, OnInit, Input, ViewChild, Inject, ViewChildren } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { PageService } from 'app/main/pages/pages.service';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { AuthService } from 'app/shared/auth.service';
import { takeUntil } from 'rxjs/operators';
import * as moment from "moment-timezone";
import { SentMessageDetailsComponent } from '../sent-message-details/sent-message-details.component';
import { ViewMessageDialogComponent } from '../view-message/view-message.component';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import * as _ from 'lodash';
import { differenceInDays, endOfMonth, startOfMonth } from 'date-fns';
import { RecentSentInprogressComponent } from '../../recent-sent-inprogress/recent-sent-inprogress.component';

@Component({
  selector: 'app-sent-messages',
  templateUrl: './sent-messages.component.html',
  styleUrls: ['./sent-messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SentMessagesDialogComponent implements OnInit {
  dialogRef: any;
  weekType: any = 0;
  List: any = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['typeName', 'createdAt', 'messageText', 'delivered', 'replies', 'errors', 'rejected', 'click', 'segments', 'sent', 'inRedis'];
  TotalCount: any;
  pageNumber = 1;
  pageSize = 30;
  filterForm: FormGroup;
  private _unsubscribeAll: Subject<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  sortBy: string = "createdAt";
  sortDirection: string = "desc";
  @ViewChildren(FusePerfectScrollbarDirective)
  directiveScroll: any;
  showCountLoader: boolean;
  IsDialogMax: boolean = false;
  hostHeight: any;
  hostWidth: any;
  offset = new Date().getTimezoneOffset();
  filterOffset = (this.offset >= 720) ? 719 : (this.offset < -720) ? -600 : this.offset;  
  constructor(
    public _matDialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _formBuilder: FormBuilder,
    private _pageService: PageService,
    private _projectDashboardService: ProjectDashboardService,
    private _authService: AuthService,
    public matDialogRef: MatDialogRef<SentMessageDetailsComponent>, @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.weekType = parseInt(this._data.weekType);
    this._unsubscribeAll = new Subject();
    this.filterForm = this._formBuilder.group({
      searchText: [''],
      searchByFromDate: [this.weekType ==2 ? startOfMonth(new Date()) : ''],
      searchByToDate: [this.weekType ==2 ? endOfMonth(new Date()) : ''],
      accounttypes: [[]],
      groupType: [0]
    });
  }


  ngOnInit() {
    this.filterForm.get("searchByToDate").valueChanges.subscribe((val) => {
      if(this.weekType == 1 && val) {
        this.weekType = 0;
      }else {
        this.weekType = parseInt(this._data.weekType);
      }
    });
    // this._pageService.getAccountsTypesByUser();
    this.getRecentSent();
  }
  getRecentSent() {
    if (this.filterForm.invalid) {
      return false;
    }
    let GetSentMessagelogsrequest = {
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "from": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "to": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "unifiedTypes": this.filterForm.get('accounttypes').value ? this.filterForm.get('accounttypes').value.toString() : null,
      "sortBy": this.sortBy,
      "sortDirection": this.sortDirection,
      "reportGroupType": this.filterForm.get('groupType').value,
      "week": 0,
      "searchText": this.filterForm.get('searchText').value,
    };
    if(this.weekType == 1) {
      GetSentMessagelogsrequest.week =  this.weekType;
    }
    this._fuseSplashScreenService.show();
    this._projectDashboardService.getRecentSent(GetSentMessagelogsrequest)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          let temp_list = [];
          res.data.forEach((obj: any) => {
            const found = _.find(this.List, function (t) { return t.id == obj.id });
            if (found == undefined || found == null) {
              temp_list.push(obj);
            }
          });
          Array.prototype.push.apply(this.List, temp_list);
          this.dataSource = new MatTableDataSource(this.List);
          if(this.pageNumber == 1) {
            this.getRecentSentCount();
          }
         
        }
        else {
          this.List = [];
          this.dataSource = new MatTableDataSource(this.List);
        }
      },
        error => {
          this._fuseSplashScreenService.hide();
          this.List = [];
          this.dataSource = new MatTableDataSource(this.List);
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  getRecentSentCount() {
    if (this.filterForm.invalid) {
      return false;
    }
    let GetSentMessagelogsrequest = {
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "from": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "to": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "unifiedTypes": this.filterForm.get('accounttypes').value ? this.filterForm.get('accounttypes').value.toString() : null,
      "sortBy": this.sortBy,
      "week": 0,
      "sortDirection": this.sortDirection,
      "reportGroupType": this.filterForm.get('groupType').value,
      "searchText": this.filterForm.get('searchText').value
    };
    if(this.weekType == 1) {
      GetSentMessagelogsrequest.week =  this.weekType;
    }
    this.showCountLoader = true;
    this._projectDashboardService.getRecentSentCount(GetSentMessagelogsrequest)
      .subscribe((res: any) => {
        this.showCountLoader = false;
        if (res.status == 200) {
          this.TotalCount = res.data;
        }
        else {
          this.TotalCount = 0;
        }
      },
        error => {
          this.showCountLoader = false;
          this.TotalCount = 0;
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  // onPageChange(event): void {
  //   this.pageNumber = event.pageIndex + 1;
  //   this.pageSize = event.pageSize;
  //   this.getRecentSent();
  // }
  sortData(event) {
    this.List = [];
    this.pageNumber = 1;
    this.sortBy = event.active;
    this.sortDirection = event.direction;
    if (this.directiveScroll._results[0]) {
      this.directiveScroll._results[0].scrollToTop();      
    }
    if (this.directiveScroll._results[1]) {
      this.directiveScroll._results[1].scrollToTop();      
    }
    this.getRecentSent();
  }

  reportGroupTypeChange() {
    this.List = [];
    this.pageNumber = 1;
    //this.paginator.pageIndex = 0;
    if (this.directiveScroll._results[0]) {
      this.directiveScroll._results[0].scrollToTop();      
    }
    if (this.directiveScroll._results[1]) {
      this.directiveScroll._results[1].scrollToTop();      
    }
    this.getRecentSent();
  }
  refreshList() {
    this.List = [];
    this.pageNumber = 1;
    this.pageSize = 100;
    if (this.directiveScroll._results[0]) {
      this.directiveScroll._results[0].scrollToTop();      
    }
    if (this.directiveScroll._results[1]) {
      this.directiveScroll._results[1].scrollToTop();      
    }
    //this.paginator.pageIndex = 0;
    //this.paginator.pageSize = 10;
    this.filterForm.patchValue({
      searchText: '',
      searchByFromDate: '',
      searchByToDate: '',
      accounttypes: [],
      groupType: 0
    });
    this.getRecentSent();
  }
  onSearch() {
    this.List = [];
    this.pageNumber = 1;
    //this.paginator.pageIndex = 0;
    if (this.directiveScroll._results[0]) {
      this.directiveScroll._results[0].scrollToTop();      
    }
    if (this.directiveScroll._results[1]) {
      this.directiveScroll._results[1].scrollToTop();      
    }
    this.getRecentSent();
  }

  goToDetails(type, messageId): void {
    this.dialogRef = this._matDialog.open(SentMessageDetailsComponent, {
      panelClass: 'sent-message-details',
      data: {
        reportingType: type,
        messageId: messageId
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        this.matDialogRef.close();
      });
  }
  viewMessage(message, mediaURL, unifiedTypeId, report): void {
    this.dialogRef = this._matDialog.open(ViewMessageDialogComponent, {
      panelClass: 'view-message',
      data: {
        message: message,
        mediaURL: mediaURL,
        unifiedTypeId: unifiedTypeId,
        reportData: report
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  getProgressMessageReport(report) {
    if(report.inRedis) {
        this._projectDashboardService.getProgressMessageReport(report.id)
        .subscribe((res: any) => {
            if (res.status == 200) {
                if(res.data) {
                    let progressData = res.data;
                    progressData.createdAt = report.createdAt;
                    progressData.messageText = report.messageText;
                    progressData.typeName = report.typeName;
                    progressData.mediaURL = report.mediaURL ? report.mediaURL : null;
                    progressData.unifiedTypeId = report.unifiedTypeId ? report.unifiedTypeId : 0;
                    let list = [];
                    list.push(progressData);
                    this.dialogRef = this._matDialog.open(RecentSentInprogressComponent, {
                        panelClass: 'recentSentInprogress-dialog',
                        disableClose: true,
                        maxWidth: "80vw",
                        data: {
                            List: list
                        }
                    });
                    this.dialogRef.afterClosed()
                        .subscribe(response => {
                            if (!response) {
                                return;
                            }
                        });

                }
            }
        },
            error => {
                this._toastr.error(error.message, 'Error');
            }
        );
    }
  }
  onScrollDown(ev) {
    if (this.dataSource.filteredData.length < this.TotalCount) {
      this.pageNumber += 1;
      this.getRecentSent();
    }
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("sent-messages");
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.width = (this.hostWidth + 1 + 'px');
        elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("sendmesage-content").style.maxHeight = "65vh";
    document.getElementById("sendmesage-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
}
maximizeDialog() {
    let elements: any = document.getElementsByClassName("sent-messages");
    for (var i = 0; i < elements.length; i++) {
        this.hostHeight = elements[i].clientHeight;
        this.hostWidth = elements[i].clientWidth;
        elements[i].style.width = ("100%");
        elements[i].style.height = ("100%");
    }
    document.getElementById("sendmesage-content").style.maxHeight = "100vh";
    document.getElementById("sendmesage-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
}
}

