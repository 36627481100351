import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { BriefPipe } from './brief.pipe';
import { localeTime } from './localeTime.pipe';
import { removeLastBR } from './removeLastBR.pipe';
import { htmlToTruncatetextPipe } from './htmlToTruncatetext.pipe';
import { GetCountryByISDPipe } from './getCountryByISD.pipe';
import { GetByNamePipe } from './getByName.pipe';
import { GetByColumnPipe } from './getByColumn.pipe';
import { localeDateTime } from './localeDateTime.pipe';
import { MessageSegmentsPipe } from './messageSegments.pipe';
import { InnerHTMLTruncatePipe } from './innerHTMLTruncate';
import { SanitizeHtmlPipe } from './sanitizeHtml.pipe';
import { SearchPipe } from './search.pipe';
import { GetByDynamicColumnPipe } from './getBydynamicColumn.pipe';
import { ConvertToTimezone } from './convertTimeZone.pipe';
import { ArrayStringFilterPipe } from './arrayStringfilter.pipe';
import { HtmldecoderPipe } from './htmldecoder.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        BriefPipe,
        localeTime,
        removeLastBR,
        htmlToTruncatetextPipe,
        GetCountryByISDPipe,
        GetByNamePipe,
        GetByColumnPipe,
        localeDateTime,
        MessageSegmentsPipe,
        InnerHTMLTruncatePipe,
        SanitizeHtmlPipe,
        SearchPipe,
        GetByDynamicColumnPipe,
        ConvertToTimezone,
        ArrayStringFilterPipe,
        HtmldecoderPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        BriefPipe,
        localeTime,
        removeLastBR,
        htmlToTruncatetextPipe,
        GetCountryByISDPipe,
        GetByNamePipe,
        GetByColumnPipe,
        localeDateTime,
        MessageSegmentsPipe,
        InnerHTMLTruncatePipe,
        SanitizeHtmlPipe,
        SearchPipe,
        GetByDynamicColumnPipe,
        ConvertToTimezone,
        ArrayStringFilterPipe,
        HtmldecoderPipe
    ]
})
export class FusePipesModule
{
}
