import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // Holds and pushes the value of the currently active User
  private activeUser$: Subject<any>;

  // Computes the active User from local storage and store it as initial value
  constructor() { 
    const initialActiveUser = JSON.parse(window.localStorage.getItem('LoggedInUserData'));
    this.activeUser$ = new BehaviorSubject<any>(initialActiveUser);
  }

  // Returns the observable (read-only) part of this subject
  getActiveUser$(): Observable<any> {
    return this.activeUser$.asObservable();
  }

  // Stores the new User value in local storage and pushes it to the subject
  setActiveUser(user: string) {
    window.localStorage.setItem('LoggedInUserData', user);
    this.activeUser$.next(JSON.parse(user));
  }

}
