import { takeUntil } from 'rxjs/operators';
import { CampaignsService } from './../../../../main/pages/campaigns/campaigns.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
@Component({
    selector: 'artemplate-override',
    templateUrl: './ARTemplate-override.component.html',
    styleUrls: ['./ARTemplate-override.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ARTemplateOverrideComponent {
    LoggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserData"));
    dialogTitle: string;
    overrideForm: FormGroup;
    countries: any[] = [];
    languages: any[] = [];
    timeZones: any[] = [];
    filterCountry: FormControl = new FormControl('');
    filterLanguage: FormControl = new FormControl('');
    filterTimeZone: FormControl = new FormControl('');
    testFilterCountry: FormControl = new FormControl('');
    private _unsubscribeAll: Subject<any>;
    constructor(
        public matDialogRef: MatDialogRef<ARTemplateOverrideComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: FormBuilder,
        private _pageService: PageService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _campaignsService: CampaignsService,
        private _toastr: ToastrService
    ) {
        this._unsubscribeAll = new Subject();
        this.overrideForm = this.createOverrideForm();
    }
    ngOnInit(): void {
        this.dialogTitle = this._data.dialogTitle;
        this.getCountries();
        this.getLanguages();
        this.getTimeZones();
        this.overrideForm.get('useTestAccount').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((val) => {
            if (val) {
                this._pageService.addValidators(this.overrideForm, "testEmail");
                this._pageService.addValidators(this.overrideForm, "testISDCode");
                this._pageService.addValidators(this.overrideForm, "testPhone");
            } else {
                this._pageService.removeValidators(this.overrideForm, "testEmail");
                this._pageService.removeValidators(this.overrideForm, "testISDCode");
                this._pageService.removeValidators(this.overrideForm, "testPhone");
            }
        });
    }

    createOverrideForm() {
        return this._formBuilder.group({
            description: [this._data.report.description],
            country: [(this._data.report && this._data.report.countryIds) ? this._data.report.countryIds : [], [Validators.required]],
            language: [(this._data.report && this._data.report.languageCodes) ? this._data.report.languageCodes : [], [Validators.required]],
            // isActive: [(this._data.report && this._data.report.isActive) ? this._data.report.isActive : false, [Validators.required]],
            useTestAccount: [(this._data.report && this._data.report.useTestAccount) ? this._data.report.useTestAccount : false],
            isBlackOutOn: [(this._data.report && this._data.report.isBlackOutOn) ? this._data.report.isBlackOutOn : false],
            timeZone: [(this._data.report && this._data.report.timeZone) ? this._data.report.timeZone : this.LoggedInUserData.timeZone],
            testEmail: [(this._data.report && this._data.report.testEmail) ? this._data.report.testEmail : '', [Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
            testISDCode: [(this._data.report && this._data.report.testISDCode) ? this._data.report.testISDCode : '+1'],
            testPhone: [(this._data.report && this._data.report.testPhone) ? this._data.report.testPhone : ''],
        });
    }
    getCountries() {
        this._pageService.getAllCountries().subscribe(
            (res: any) => {
                if (res) {
                    this.countries = res;
                }
                else {
                    this.countries = [];
                }
            }
        )
    }
    getLanguages() {
        this._pageService.GetAllLanguages().subscribe(
            (res: any) => {
                if (res) {
                    this.languages = res;
                }
                else {
                    this.languages = [];
                }
            }
        )
    }

    getTimeZones(search: string = "") {
        this._pageService.getTimeZones().subscribe(
            (res: any) => {
                if (res) {
                    this.timeZones = res.filter(function (zone) { return zone.text.toLowerCase().indexOf(search.toLowerCase()) > -1 });
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    save() {
        if (this.overrideForm.invalid) {
            this._pageService.validateAllFormFields(this.overrideForm);
            return false;
        }
        var ARTempFormData = this.overrideForm.getRawValue();
        let ARTempReq = {
            "id": this._data.report.masterId ? this._data.report.id : 0,
            "arTemplateID": this._data.report.arTemplateID,
            "arTypeID": this._data.report.arTypeID,
            "arType": this._data.report.arType,
            "description": ARTempFormData.description,
            "subject": this._data.report.subject,
            "formatType": this._data.report.formatType,
            "localeID": this._data.report.localeID,
            "replaceTags": this._data.report.replaceTags,
            "companyId": this._data.report.companyId,
            "createdAt": this._data.report.createdAt,
            "updatedAt": this._data.report.updatedAt,
            "addToGroup": this._data.report.addToGroup,
            "groupId": this._data.report.groupId,
            "masterId": this._data.report.masterId != 0 ? this._data.report.masterId : this._data.report.id,
            "countryIds": ARTempFormData.country,
            "languageCodes": ARTempFormData.language,
            "useTestAccount": ARTempFormData.useTestAccount,
            "testEmail": ARTempFormData.testEmail,
            "testPhone": ARTempFormData.testPhone,
            "testISDCode": ARTempFormData.testISDCode,
            "isBlackOutOn": ARTempFormData.isBlackOutOn,
            "timeZone": ARTempFormData.timeZone,
            // "isActive": ARTempFormData.isActive
        }
        this._fuseSplashScreenService.show();
        this._campaignsService.updateARTemplate(ARTempReq)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.message, 'Success');
                    this.matDialogRef.close(true);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
}