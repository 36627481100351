import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'localeTime'})
export class localeTime implements PipeTransform
{
    /**
     * Transform
     *
     * @param {string} value
     */
    transform(value: string): any
    {
        return value ? new Date(value + 'Z').toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : '';
    }
}
