import { Component, OnInit, Inject } from '@angular/core'
import { ViewEncapsulation } from '@angular/core'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import {
    MAT_DIALOG_DATA
} from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service'

@Component({
    selector: 'view-browser-name-dialog',
    templateUrl: './view-browser-name.component.html',
    styleUrls: ['./view-browser-name.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ViewBrowserNameDialogComponent implements OnInit {
    viewBrowserForm: FormGroup
    IsDialogMax: boolean = false
    dialogTitle: string="Title"
    dialogbottomButtom: string
    hostHeight: any
    hostWidth: any
    constructor (
        public matDialogRef: MatDialogRef<ViewBrowserNameDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: FormBuilder,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService
    ) {
        this.viewBrowserForm = this.createviewBrowserForm();
    }

    ngOnInit () {
    }

    createviewBrowserForm (): FormGroup {
        return this._formBuilder.group(
            {
                templateName: ['', [Validators.required]],
            }
        )
    }
    create() {
        if(this.viewBrowserForm.invalid) {
            this._toastr.error('Fill the required field', 'Error');
            return false;
        }
        var name = this.viewBrowserForm.get('templateName').value;
        this.matDialogRef.close(name)
    }
    minimizeDialog () {
        let elements: any = document.getElementsByClassName('view-browser-dialog')
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = this.hostWidth + 1 + 'px'
            elements[i].style.height = this.hostHeight + 1 + 'px'
        }
        document.getElementById('view-browser-content').style.maxHeight = '65vh';
        document.getElementById('view-browser-wrapper').style.maxHeight = '85vh';
        this.IsDialogMax = !this.IsDialogMax;
    }

    maximizeDialog () {
        let elements: any = document.getElementsByClassName('view-browser-dialog')
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight
            this.hostWidth = elements[i].clientWidth
            elements[i].style.width = '100%'
            elements[i].style.height = '100%'
        }
        document.getElementById('view-browser-content').style.maxHeight = '100vh';
        document.getElementById('view-browser-wrapper').style.maxHeight = '100vh';
        this.IsDialogMax = !this.IsDialogMax;
    }
}