import { ARTemplateOverrideComponent } from './common/dialogs/ARTemplate-override/ARTemplate-override.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { RestService } from './rest.service';
import { FuseSharedModule } from '@fuse/shared.module';
import { RecentRepliesComponent } from './common/recent-replies/recent-replies.component';
import { MatTooltipModule, MatStepperModule, MatExpansionModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import {MatCardModule} from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSidebarModule } from '../../@fuse/components/sidebar/sidebar.module';
import { RecentSentComponent} from './common/recent-sent/recent-sent.component';
import { PreviewComponent } from './common/preview/preview.component';
import { SentMessageDetailsComponent } from './common/dialogs/sent-message-details/sent-message-details.component'
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChatDetailsDialogComponent } from './common/dialogs/chat-details/chat-details.component';
import { ProjectDashboardService } from '../main/pages/dashboard/project.service';
import { HelpMergeFieldDialogComponent } from './common/dialogs/help-merge-field/help-merge-field.component';
import { HelpTinyUrlDialogComponent } from './common/dialogs/help-tiny-url/help-tiny-url.component';
import { CommonNameDialogComponent } from './common/dialogs/common-name/common-name.component';
import { addTinyUrlDialogComponent } from './common/dialogs/add-tiny-url/add-tiny-url.component';
import { emailTemplateCatalogDialogComponent } from './common/dialogs/email-template-catalog/email-template-catalog.component';
import { AdvanceOptionsComponent } from './common/advance-options/advance-options.component';
import { ImportInstructionsDialogComponent } from './common/dialogs/import-instructions/import-instructions.component';
import { ImportContactsDialogComponent } from './common/dialogs/import-contacts/import-contacts.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ImportStatsDialogComponent } from './common/dialogs/import-stats/import-stats.component';
import { AccountsFormDialogComponent } from './common/dialogs/account-form/account-form.component';
import { TemplateDialogComponent } from './common/dialogs/template-dialog/template-dialog.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { FiltersComponent } from './common/filters/filters.component';
import { SentMessagesDialogComponent } from './common/dialogs/sent-messages/sent-messages.component';
import { ErrorDescriptionDialogComponent } from './common/dialogs/error-description/error-description.component';
import { DSRecentSentComponent } from './common/ds-recent-sent/ds-recent-sent.component';
import { DSRecentRepliesComponent } from './common/ds-recent-replies/ds-recent-replies.component';
import { DSSentMessagesDialogComponent } from './common/dialogs/ds-sent-messages/ds-sent-messages.component';
import { DSReceivedMessagesDialogComponent } from './common/dialogs/ds-received-messages/ds-received-messages.component';
import { DSSentMessageDetailsComponent } from './common/dialogs/ds-sent-message-details/ds-sent-message-details.component';
import { ViewMessageDialogComponent } from './common/dialogs/view-message/view-message.component';
import { SelectCheckAllComponent } from './common/select-check-all/select-check-all.component';
import { AddTriggerEventsComponent } from './common/dialogs/add-trigger-events/add-trigger-events.component';
import { TriggerEventsListComponent } from './common/trigger-events-list/trigger-events-list.component';
import { UnifyVideosListComponent } from './common/unify-videos-list/unify-videos-list.component';
import { AddUnifyVideosComponent } from './common/dialogs/add-unify-videos/add-unify-videos.component';
import { AddCategoryComponent } from './common/dialogs/add-category/add-category.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ViewDocumetsDialogComponent } from './common/dialogs/view-documents/view-documents.component';
import { AddLearningMenuComponent } from './common/dialogs/add-learning-menu/add-learning-menu.component';
import { twoStepAuthSettingsDialog } from './common/dialogs/two-step-auth-settings/two-step-auth-settings.component';
import { TriggerPropertiesListComponent } from './common/trigger-properties-list/trigger-properties-list.component';
import { AddTriggerPropertiesComponent } from './common/dialogs/add-trigger-properties/add-trigger-properties.component';
import { MessageQueueDetailsComponent } from './common/dialogs/message-queue-details/message-queue-details.component';
import { AddContactsComponent } from './common/dialogs/add-contacts/add-contacts.component';
import { MergefieldOptionComponent } from './common/mergefield-option/mergefield-option.component';
import { CampaignApiLogComponent } from './common/campaign-api-log/campaign-api-log.component';
import { ExternalAccountsFormDialogComponent } from './common/dialogs/external-account-form/external-account-form.component';
import { ExternalReportingSentMessagesDialogComponent } from './common/dialogs/external-reporting-sent-messages/external-reporting-sent-messages.component';
import { ExternalReportingSentMessageDetailsComponent } from './common/dialogs/external-reporting-sent-message-details/external-reporting-sent-message-details.component';
import { ExternalReportingReceivedMessagesDialogComponent } from './common/dialogs/external-reporting-received-messages/external-reporting-received-messages.component';
import { ExternalReportingRecentSentComponent } from './common/external-reporting-recent-sent/external-reporting-recent-sent.component';
import { ExternalReportingRecentRepliesComponent } from './common/external-reporting-recent-replies/external-reporting-recent-replies.component';
import { TestContactDialogComponent } from './common/dialogs/test-contact/test-contact.component';
import { RecentSentInprogressComponent } from './common/recent-sent-inprogress/recent-sent-inprogress.component';
import { ViewBrowserNameDialogComponent } from './common/view-browser-name-dialog/view-browser-name.component';
import { AddMoreNumberDialog } from './common/dialogs/add-more-number/add-more-number.component';
import { AddMoreNumList } from './common/dialogs/add-more-number-list/add-more-num-list.component';
import { ClickMapComponent } from './common/dialogs/click-map/click-map.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ClickMapLinkDetailsComponent } from './common/dialogs/click-map-link-details/click-map-link-details.component';
import { ClickMapLinkComponent } from './common/dialogs/click-map-link/click-map-link.component';
import { UniqueClickLinkComponent } from './common/dialogs/unique-click-link/unique-click-link.component';
import { SetPageNumSize } from './common/dialogs/setPage-Num-Size/setPage-Num-Size.component';
import { SaveRowComponent } from './common/dialogs/save-row/save-row.component';
import { CamapignListComponent } from './common/dialogs/campaign-list/campaign-list.component';
import { ImportContactsMappingViewerDialogComponent } from './common/dialogs/import-contacts-mapping-viewer/import-contacts-mapping-viewer.component';
import { RoiSummaryDetailsComponent } from './common/dialogs/roi-summary-details/roi-summary-details.component';
import { OrderRoiSummaryDetailsComponent } from './common/dialogs/order-roi-summary-details/order-roi-summary-details.component';
import { InvoicePageComponent } from './common/dialogs/inovice-page/invoice-page.component';
import { AddMediaComponent } from './common/dialogs/add-media/add-media.component';
import { AddMergeFieldComponent } from './common/dialogs/add-merge-field/add-merge-field.component';
import { AddLinkTrackingComponent } from './common/dialogs/add-link-tracking/add-link-tracking.component';
import { RoiClicksDetailsComponent } from './common/dialogs/roi-clicks-details/roi-clicks-details.component';
import { ViewBrowserListComponent } from './common/dialogs/view-browser-list/view-browser-list.component';
import { AddUrlComponent } from './common/dialogs/add-url/add-url.component';
import { EditLogoComponent } from './common/dialogs/edit-logo/edit-logo.component';
import { AddPageLinkComponent } from './common/dialogs/add-page-link/add-page-link.component';
import { MenuSidebarComponent } from './common/dialogs/menu-sidebar/menu-sidebar.component';
import { AddEditSideMenuName } from './common/dialogs/addEdit-sideMenu-name/addEdit-sideMenu-name.component';
import { AddToMenu } from './common/dialogs/addTo-menu/addTo-menu.component';

@NgModule({
  declarations: [
    RecentRepliesComponent,
    RecentSentComponent,
    emailTemplateCatalogDialogComponent,
    PreviewComponent,
    CommonNameDialogComponent,
    SentMessageDetailsComponent,
    ChatDetailsDialogComponent,
    HelpMergeFieldDialogComponent,
    ImportInstructionsDialogComponent,
    ImportContactsDialogComponent,
    ImportStatsDialogComponent,
    HelpTinyUrlDialogComponent,
    AdvanceOptionsComponent,
    AccountsFormDialogComponent,
    TemplateDialogComponent,
    FiltersComponent,
    SentMessagesDialogComponent,
    ErrorDescriptionDialogComponent,
    DSRecentSentComponent,
    DSRecentRepliesComponent,
    DSSentMessagesDialogComponent,
    DSReceivedMessagesDialogComponent,
    addTinyUrlDialogComponent,
    DSSentMessageDetailsComponent,
    ViewMessageDialogComponent,
    SelectCheckAllComponent,
    AddTriggerEventsComponent,
    TriggerEventsListComponent,
    UnifyVideosListComponent,
    AddUnifyVideosComponent,
    AddCategoryComponent,
    ViewDocumetsDialogComponent,
    AddLearningMenuComponent,
    twoStepAuthSettingsDialog,
    TriggerPropertiesListComponent,
    AddTriggerPropertiesComponent,
    MessageQueueDetailsComponent,
    AddContactsComponent,
    MergefieldOptionComponent,
    CampaignApiLogComponent,
    ExternalAccountsFormDialogComponent,
    ExternalReportingSentMessagesDialogComponent,
    ExternalReportingSentMessageDetailsComponent,
    ExternalReportingReceivedMessagesDialogComponent,
    ExternalReportingRecentSentComponent,
    ExternalReportingRecentRepliesComponent,
    TestContactDialogComponent,
    RecentSentInprogressComponent,
    ViewBrowserNameDialogComponent,
    AddMoreNumberDialog,
    AddMoreNumList,
    ClickMapComponent,
    ClickMapLinkDetailsComponent,
    ClickMapLinkComponent,
    UniqueClickLinkComponent,
    ARTemplateOverrideComponent,
    SetPageNumSize,
    SaveRowComponent,
    CamapignListComponent,
    ImportContactsMappingViewerDialogComponent,
    RoiSummaryDetailsComponent,
    OrderRoiSummaryDetailsComponent,
    InvoicePageComponent,
    AddMediaComponent,
    AddMergeFieldComponent,
    AddLinkTrackingComponent,
    RoiClicksDetailsComponent,
    ViewBrowserListComponent,
    AddUrlComponent,
    EditLogoComponent,
    AddPageLinkComponent,
    MenuSidebarComponent,
    AddEditSideMenuName,
    AddToMenu
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatCardModule,
    MatRippleModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    MatPaginatorModule,
    TranslateModule,
    FuseSharedModule,
    FuseSidebarModule,
    InfiniteScrollModule,
    MatStepperModule,
    NgxMatSelectSearchModule,
    CKEditorModule,
    EditorModule,
    MatExpansionModule,
    MatProgressBarModule
  ],
  providers: [
    AuthService,
    RestService,
    ProjectDashboardService
  ],
  exports: [
    RecentRepliesComponent,
    RecentSentComponent,
    PreviewComponent,
    SentMessageDetailsComponent,
    AdvanceOptionsComponent,
    FiltersComponent,
    DSRecentSentComponent,
    DSRecentRepliesComponent,
    DSSentMessagesDialogComponent,
    DSReceivedMessagesDialogComponent,
    DSSentMessageDetailsComponent,
    ViewMessageDialogComponent,
    SelectCheckAllComponent,
    TriggerEventsListComponent,
    UnifyVideosListComponent,
    twoStepAuthSettingsDialog,
    TriggerPropertiesListComponent,
    MergefieldOptionComponent,
    CampaignApiLogComponent,
    ExternalReportingSentMessagesDialogComponent,
    ExternalReportingSentMessageDetailsComponent,
    ExternalReportingReceivedMessagesDialogComponent,
    ExternalReportingRecentSentComponent,
    ExternalReportingRecentRepliesComponent,
    TestContactDialogComponent,
    RecentSentInprogressComponent,
    ViewBrowserNameDialogComponent,
    AddMoreNumberDialog,
    AddMoreNumList,
    ClickMapComponent,
    ClickMapLinkDetailsComponent,
    ClickMapLinkComponent,
    UniqueClickLinkComponent,
    ARTemplateOverrideComponent,
    SetPageNumSize,
    SaveRowComponent,
    CamapignListComponent,
    ImportContactsMappingViewerDialogComponent,
    RoiSummaryDetailsComponent,
    OrderRoiSummaryDetailsComponent,
    InvoicePageComponent,
    AddMediaComponent,
    AddMergeFieldComponent,
    AddLinkTrackingComponent,
    RoiClicksDetailsComponent,
    ViewBrowserListComponent,
    AddUrlComponent,
    EditLogoComponent,
    AddPageLinkComponent,
    MenuSidebarComponent,
    AddEditSideMenuName,
    AddToMenu
  ],
  entryComponents: [
    RecentRepliesComponent,
    RecentSentComponent,
    SentMessageDetailsComponent,
    ChatDetailsDialogComponent,
    HelpMergeFieldDialogComponent,
    HelpTinyUrlDialogComponent,
    CommonNameDialogComponent,
    addTinyUrlDialogComponent,
    emailTemplateCatalogDialogComponent,
    ImportInstructionsDialogComponent,
    ImportContactsDialogComponent,
    ImportStatsDialogComponent,
    AccountsFormDialogComponent,
    TemplateDialogComponent,
    SentMessagesDialogComponent,
    ErrorDescriptionDialogComponent,
    DSSentMessagesDialogComponent,
    DSReceivedMessagesDialogComponent,
    DSSentMessageDetailsComponent,
    ViewMessageDialogComponent,
    AddTriggerEventsComponent,
    AddUnifyVideosComponent,
    AddCategoryComponent,
    ViewDocumetsDialogComponent,
    AddLearningMenuComponent,
    twoStepAuthSettingsDialog,
    AddTriggerPropertiesComponent,
    MessageQueueDetailsComponent,
    AddContactsComponent,
    CampaignApiLogComponent,
    ExternalAccountsFormDialogComponent,
    ExternalReportingSentMessagesDialogComponent,
    ExternalReportingSentMessageDetailsComponent,
    ExternalReportingReceivedMessagesDialogComponent,
    ExternalReportingRecentSentComponent,
    ExternalReportingRecentRepliesComponent,
    TestContactDialogComponent,
    RecentSentInprogressComponent,
    ViewBrowserNameDialogComponent,
    AddMoreNumberDialog,
    AddMoreNumList,
    ClickMapComponent,
    ClickMapLinkDetailsComponent,
    ClickMapLinkComponent,
    UniqueClickLinkComponent,
    ARTemplateOverrideComponent,
    SetPageNumSize,
    SaveRowComponent,
    CamapignListComponent,
    ImportContactsMappingViewerDialogComponent,
    RoiSummaryDetailsComponent,
    OrderRoiSummaryDetailsComponent,
    InvoicePageComponent,
    AddMediaComponent,
    AddMergeFieldComponent,
    AddLinkTrackingComponent,
    RoiClicksDetailsComponent,
    ViewBrowserListComponent,
    AddUrlComponent,
    EditLogoComponent,
    AddPageLinkComponent,
    MenuSidebarComponent,
    AddEditSideMenuName,
    AddToMenu
  ]
})
export class SharedModule { }
