import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'addEdit-sideMenu-name',
  templateUrl: './addEdit-sideMenu-name.component.html',
  styleUrls: ['./addEdit-sideMenu-name.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditSideMenuName implements OnInit, AfterViewInit {
  IsDialogMax: boolean = false;
  hostHeight: any;
  hostWidth: any;
  formData: any;
  dialogTitle: string = "Add Menu";
  dialogRef: any;
  menuName: FormControl = new FormControl('');
  private _unsubscribeAll: Subject<any>;
  userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
  constructor(
    public matDialogRef: MatDialogRef<AddEditSideMenuName>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    public _matDialog: MatDialog,
    public _pageService: PageService
  ) {
    this.dialogTitle = this._data.dialogTitle;
    this._unsubscribeAll = new Subject();

  }
  ngOnInit(): void {

  }
  ngAfterViewInit(): void {

  }
  addEditMenu() {
    if (this.menuName.value != '' && this.menuName.value != null && this.menuName.value != undefined) {
      this.matDialogRef.close(this.menuName.value);
    }
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("addEdit-menu-dialog");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("addEdit-menu-content").style.maxHeight = "65vh";
    document.getElementById("addEdit-menu-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("addEdit-menu-dialog");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("addEdit-menu-content").style.maxHeight = "100vh";
    document.getElementById("addEdit-menu-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}

