import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import { ContactsService } from '../../../main/pages/contacts/contacts.service';
import { MailService } from 'app/main/pages/mail/mail.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import { AuthService } from 'app/shared/auth.service';
import { Router } from '@angular/router';
import { ChatService } from 'app/main/pages/chat/chat.service';

@Component({
    selector: 'chat-panel',
    templateUrl: './chat-panel.component.html',
    styleUrls: ['./chat-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatPanelComponent implements OnInit, AfterViewInit, OnDestroy {
    contacts: any[] = [];
    chat: any[] = [];
    selectedContact: any = {};
    sidebarFolded: boolean;
    accountTypeColors: any[];
    nextSendMessageType: any;
    nextSendMessageReplyMessageId: any;
    pageNumber = 1;
    detailspageNumber = 1;
    direction: string = 'down';
    lastElement: string = '';

    @ViewChild('replyForm', { static: false })
    private _replyForm: NgForm;

    @ViewChild('replyInput', { static: false })
    private _replyInput: ElementRef;

    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;


    // Private
    private _chatViewScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;
    constructor(
        private _chatPanelService: ChatPanelService,
        private _fuseSidebarService: FuseSidebarService,
        private _contactsService: ContactsService,
        private _mailService: MailService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
        private _auth: AuthService,
        private _router: Router,
        private _chatService: ChatService
    ) {
        // Set the defaults
        this.sidebarFolded = true;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._chatPanelService.chats = [];
        this._chatPanelService.chatLogsList = [];
        this._chatPanelService.chatDetailsList = [];
        this._chatPanelService.onChatLogsChanged = new BehaviorSubject([]);
        this._chatPanelService.onChatSearchChanged = new Subject();
        this._chatPanelService.onMarkAsReadChanged = new Subject();
        this._chatPanelService.onChatDetailsChanged = new BehaviorSubject([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.accountTypeColors = this._mailService.accountTypeColors;
        if (this._auth.isLoggedIn()) {
            this._chatPanelService.init();
        }
        this._chatPanelService.onChatDetailsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((chat: any) => {
                if(chat.length > 0) {
                    for(let i = 0; i<chat.length; i++) {
                       var attach = [] 
                       if(chat[i].unifiedTypeId === 1 && chat[i].mediaURL && chat[i].mediaURL != null) {
                           if(this.checkJson(chat[i].mediaURL)) {
                               var mediaUrlData = JSON.parse(chat[i].mediaURL);
                               var mediaUrlDataKey = Object.keys(mediaUrlData);
                               var mediaUrlDataValues = [] ;
                               mediaUrlDataValues = Object.values(mediaUrlData);        
                               (mediaUrlDataValues[0]).forEach(item =>{
                                   let attachData = mediaUrlDataKey[0] + item;
                                   let attachDataSplit = attachData.split(' ');
                                   if (attachDataSplit && attachDataSplit.length == 1) {
                                       attach.push({
                                           "fileName": attachDataSplit[0], "filePath": attachDataSplit[0]
                                       }
                                       )
                                   } else {
                                       let fileName = '';
                                       attachDataSplit.forEach((item, index) => {
                                           if (index != 0) {
                                               fileName += item + ' ';
                                           }
                                       });
                                       attach.push({
                                           "fileName": fileName, "filePath": attachDataSplit[0]
                                       });
                                   }
                               });
                           } 
                           chat[i].mediaURL = attach;  
                       }                      
                    }
                }
                let temp_list = [];
                chat.forEach((obj: any) => {
                    obj.viewLength = 1000;
                    const found = _.find(this.chat, function (t) { return t.id == obj.id });
                    if (found == undefined || found == null) {
                        if (this.chat.length > 0) {
                            if (this.chat[0].id < obj.id) {
                                this.chat.unshift(obj);
                            }
                            else {
                                temp_list.push(obj);
                            }
                        }
                        else {
                            temp_list.push(obj);
                        }
                    }
                });
                Array.prototype.push.apply(this.chat, temp_list);
                this.chat.forEach((element, index) => {
                    if(element.replaceTags != null) {
                        if(this.checkJson(element.replaceTags)) {
                            if(this.direction != 'up') {
                                element.replaceTags = element.replaceTags.replace(/\s+/g," ");
                            }
                            if(typeof(element.replaceTags) != 'object') {
                                element.replaceTags=JSON.parse(element.replaceTags);
                            }
                            for (const item in element.replaceTags) {
                                if(item.includes('[')) {
                                    element.messageText= element.messageText.replaceAll(item, element.replaceTags[item]);
                                }else {
                                    element.messageText= element.messageText.replaceAll("["+item+"]", element.replaceTags[item]);
                                }
                            } 
                        }
                    }
                });
                if (this.chat.length > 0) {
                    let latestReceivedIndex = this.chat.findIndex(x => !x.sendByUser);
                    if (latestReceivedIndex > -1) {
                        this.nextSendMessageType = this.chat[latestReceivedIndex].unifiedTypeId;
                        this.nextSendMessageReplyMessageId = this.chat[latestReceivedIndex].id;
                    }
                    else {
                        this.nextSendMessageType = this.chat[0].unifiedTypeId;
                        this.nextSendMessageReplyMessageId = 0;
                    }
                }
                // Prepare the chat for the replies
                this._prepareChatForReplies();
            });

        // Subscribe to the foldedChanged observable
        this._fuseSidebarService.getSidebar('chatPanel').foldedChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((folded: any) => {
                this.sidebarFolded = folded;
            });

        this._chatPanelService.onChatLogsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chatLogsList => {
                let temp_list = [];
                chatLogsList.forEach((obj: any) => {
                    const found = _.find(this.contacts, function (t) { return t.contactId == obj.contactId });
                    if (found == undefined || found == null) {
                        temp_list.push(obj);
                    }
                });
                Array.prototype.push.apply(this.contacts, temp_list);
            });

        this._chatPanelService.onMarkAsReadChanged.subscribe(messageId => {
            let objIndex = this.contacts.findIndex((obj => obj.messageId == messageId));
            if (objIndex > -1) {
                this.contacts[objIndex].unread = 0;
            }
        });
        this._chatPanelService.onRefreshChatLogsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(event => {
                this.contacts = [];
                this.chat = [];
                this.selectedContact = {};
                this.pageNumber = 1;
                this.detailspageNumber = 1;
                this.direction = 'down';
                this.lastElement = '';
                this._chatPanelService.init();
            });
    }
    checkJson(mediaUrlData) {
        try {
            var json = JSON.parse(mediaUrlData);
            return (typeof json === 'object');
        } catch(e) {
            return false
        }
    }
    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Prepare the chat for the replies
     */
    private _prepareChatForReplies(): void {
        setTimeout(() => {
            this.readyToReply();
            // Focus to the reply input
            //this._replyInput.nativeElement.focus();

            // Scroll to the bottom of the messages list
            // if (this._chatViewScrollbar) {
            //     this._chatViewScrollbar.update();

            //     setTimeout(() => {
            //         this._chatViewScrollbar.scrollToBottom(0);
            //     });
            // }

        });
    }
    readyToReply(): void {
        setTimeout(() => {
            if (this.direction == 'down') {
                this.scrollToBottom();
            }
            else {
                if (!this.lastElement) {
                    this.scrollToTop(200);
                }
                else {
                    this.scrollToElement(this.lastElement);
                }

            }
        });
    }
    scrollToBottom(speed?: number): void {
        speed = speed || 400;
        if (this._chatViewScrollbar) {
            this._chatViewScrollbar.update();

            setTimeout(() => {
                this._chatViewScrollbar.scrollToBottom(0, speed);
            });
        }
    }
    scrollToTop(offset?: number, speed?: number): void {
        speed = speed || 400;
        if (this._chatViewScrollbar) {
            this._chatViewScrollbar.update();

            setTimeout(() => {
                this._chatViewScrollbar.scrollToTop(offset || 0, speed);
            });
        }
    }
    scrollToElement(qs: string, offset?: number, speed?: number): void {
        speed = speed || 400;
        if (this._chatViewScrollbar) {
            this._chatViewScrollbar.update();

            setTimeout(() => {
                this._chatViewScrollbar.scrollToElement(qs, offset || 0, speed);
            });
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
    }

    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
    }

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean {
        return (
            message.messageId === this.selectedContact.messageId &&
            ((this.chat[i + 1] && this.chat[i + 1].messageId !== this.selectedContact.messageId) || !this.chat[i + 1])
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {
        return (i === 0 || this.chat[i - 1] && this.chat[i - 1].id !== message.id);
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (i === this.chat.length - 1 || this.chat[i + 1] && this.chat[i + 1].id !== message.id);
    }
    chatRefresh() {
        this.unfoldSidebarTemporarily();

        // Set the selected contact

        this.pageNumber = 1;
        this._chatPanelService.chatDetailsPageNumber = 1;
        this.chat = [];
        this.direction = 'down';
        this.lastElement = '';

        // Load the chat
        if (this.selectedContact.unread > 0) {
            this._chatPanelService.getChatDetails(this.selectedContact.messageId, true);
        }
        else {
            this._chatPanelService.getChatDetails(this.selectedContact.messageId, false);
        }
    }



    /**
     * Toggle chat with the contact
     *
     * @param contact
     */
    toggleChat(contact): void {
        // If the contact equals to the selectedContact,
        // that means we will deselect the contact and
        // unload the chat
        if (this.selectedContact && contact.contactId === this.selectedContact.contactId) {
            // Reset
            this.resetChat();
        }
        // Otherwise, we will select the contact, open
        // the sidebar and start the chat
        else {
            // Unfold the sidebar temporarily
            this.unfoldSidebarTemporarily();

            // Set the selected contact
            this.selectedContact = contact;
            this.pageNumber = 1;
            this._chatPanelService.chatDetailsPageNumber = 1;
            this.chat = [];
            this.direction = 'down';
            this.lastElement = '';

            // Load the chat
            if (contact.unread > 0) {
                this._chatPanelService.getChatDetails(contact.messageId, true);
            }
            else {
                this._chatPanelService.getChatDetails(contact.messageId, false);
            }
        }
    }

    /**
     * Remove the selected contact and unload the chat
     */
    resetChat(): void {
        // Set the selected contact as null
        this.selectedContact = {};

        // Set the chat as null
        this.chat = [];
    }

    /**
     * Reply
     */
    reply(event): void {
        event.preventDefault();

        if (!this._replyForm.form.value.message) {
            return;
        }

        this.sendMessage();
    }

    sendMessage() {
        let req: any = {
            "unifiedTypes": [this.nextSendMessageType],
            "recipients": [this.selectedContact.contactId],
            "subject": this.selectedContact.title,
            "mailText": this._replyForm.form.value.message,
            "messageText": this._replyForm.form.value.message
        };
        if (this.nextSendMessageReplyMessageId) {
            req.IsReply = true;
            req.ReplyMessageId = this.nextSendMessageReplyMessageId;
        }
        this._fuseSplashScreenService.show();
        this._mailService.SendMessageToContacts(req)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this._chatPanelService.chatDetailsPageNumber = 1;
                    this._chatPanelService.getChatDetails(this.selectedContact.messageId, false);
                    this._replyForm.reset();
                    this._prepareChatForReplies();
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    console.log(error);
                    if (error.error.error) {
                        this._toastr.error(error.error.error, 'Error');
                    }
                    else {
                        this._toastr.error(error.error, 'Error');
                    }
                }
            );
    }

    goToContactDetails(contactId): void {
        this._router.navigate(['/pages/contactdetails/' + contactId]);
    }

    onScrollDown(ev) {
        this.pageNumber += 1;
        this._chatPanelService.chatLogsPageNumber = this.pageNumber;
        this._chatPanelService.getChatlogs();
    }

    onDetailScrollUp(messageId) {
        this.direction = 'up';
        //if (this.chat.length < this._chatPanelService.chatDetailsTotalCount) {
            let last = this.chat[this.chat.length - 1];
            this.lastElement = '.message-detail-id-' +  last ? last.id : '';
            this.pageNumber += 1;
            this._chatPanelService.chatDetailsPageNumber = this.pageNumber;
            this._chatPanelService.getChatDetails(messageId, false);
        //}
    }
    onDetailScrollDown(ev) {
        this.direction = 'down';
    }
    goToLink(url: string) {
        window.open(url, "_blank");
    }
}
