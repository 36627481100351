import { FormControl } from '@angular/forms';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import { PageService } from 'app/main/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ClickMapLinkDetailsComponent } from '../click-map-link-details/click-map-link-details.component';
@Component({
  selector: 'unique-click-link',
  templateUrl: './unique-click-link.component.html',
  styleUrls: ['./unique-click-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class UniqueClickLinkComponent implements OnInit {
  hostHeight: any;
  hostWidth: any;
  IsDialogMax: boolean = false;
  pageNumber: number = 1;
  pageSize: number = 100;
  TotalCount: number = 0;
  dataSource: MatTableDataSource<any>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  @ViewChild(FusePerfectScrollbarDirective, { static: false })
  directiveScroll: FusePerfectScrollbarDirective;
  dialogRef: any;
  selectedCont: any[] = [];
  checkboxes: {};
  List: any[] =[];
  url: any = '';
  searchText: FormControl = new FormControl('')
  constructor(
    public matDialogRef: MatDialogRef<UniqueClickLinkComponent>,
    private _campaignsService: CampaignsService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _toastr: ToastrService,
    private _pageService: PageService,
    public _contactsService: ContactsService,
    public _matDialog: MatDialog,
    public _router: Router
  ) { 
    this._contactsService.appledFilters = new BehaviorSubject<any>(null);
    }
  displayedColumns: string[] = ['url', 'urlUniqueClicks']
  ngOnInit() {
    if(this._data.linkArray.length>0) {
      this.dataSource = new MatTableDataSource(this._data.linkArray);
      this.TotalCount = this._data.linkArray.length;
    }else {
      this.dataSource = new MatTableDataSource([]);
      this.TotalCount = 0;
    }
  }
  onScrollDown(ev) {
  }
  clickMapLinkDetails(url): void {
    this.dialogRef = this._matDialog.open(ClickMapLinkDetailsComponent, {
        panelClass: 'clickMap-link-details',
        disableClose: true,
        data: {
          CampaignId: this._data.CampaignId,
          htmlLink: url,
          isClickMapPage: false,
          type: 'totalClicks'
        }
    });
    this.dialogRef.afterClosed()
        .subscribe((response: any) => {

    });
       
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("unique-click-link");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("unique-click-link-content").style.maxHeight = "65vh";
    document.getElementById("unique-click-link-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("unique-click-link");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("unique-click-link-content").style.maxHeight = "100vh";
    document.getElementById("unique-click-link-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
