import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { RestService } from '../../../shared/rest.service';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from '../pages.service';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import * as momentTimezone from 'moment-timezone';
@Injectable()
export class ProjectDashboardService implements Resolve<any>
{
    userDetails: any;
    isAdminRole: any;
    isCompanyRole: any;
    isAgentRole: any;
    projects: any[];
    typeForErrorList: number = 0;
    typeForOptOutList: number = 0;
    dashboardMergedWidget: any = {};
    ondashboardMergedWidgetChanged: BehaviorSubject<any>;
    sentTotalWidget: any = {};
    onsentTotalWidgetChanged: BehaviorSubject<any>;
    deliveredWidget: any = {};
    onDeliveredWidgetChanged: BehaviorSubject<any>;
    openedWidget: any = {};
    onOpenedWidgetChanged: BehaviorSubject<any>;
    clickedWidget: any = {};
    onclickWidgetChanged: BehaviorSubject<any>;
    bouncedWidget: any = {};
    onUnsubscribedWidgetChanged: BehaviorSubject<any>;
    unsubscribedWidget: any = {};
    onBouncedWidgetChanged: BehaviorSubject<any>;
    onProjectChanged: Subject<any>;
    projectType: string = 'Unify';
    onDSWidgetChanged: BehaviorSubject<any>;
    onExternalReportingWidgetChanged: BehaviorSubject<any>;
    DSWidget: any = {};
    ExternalReportingWidget: any = {};
    validationWidget: any = [];
    // onValidationWidgetChanged: BehaviorSubject<any>;
    constructor(
        private _restService: RestService,
        private _authService: AuthService,
        private _pageService: PageService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService
    ) {
        this.onProjectChanged = new Subject();
        this.ondashboardMergedWidgetChanged = new BehaviorSubject({});
        this.onsentTotalWidgetChanged = new BehaviorSubject({});
        this.onDeliveredWidgetChanged = new BehaviorSubject({});
        this.onOpenedWidgetChanged = new BehaviorSubject({});
        this.onclickWidgetChanged = new BehaviorSubject({});
        this.onUnsubscribedWidgetChanged = new BehaviorSubject({});
        this.onDSWidgetChanged = new BehaviorSubject({});
        this.onExternalReportingWidgetChanged = new BehaviorSubject({});
        this.onBouncedWidgetChanged = new BehaviorSubject({});
    }
    resolve(): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.init()
            ]).then(
                (res) => {
                    resolve('init');
                },
                reject
            );
        });
    }

    init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
            this.isAdminRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'user' || this.userDetails.roles[0] == 'administrator');
            this.isCompanyRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'company');
            this.isAgentRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
            if ( this.userDetails.defaultAgentView) {
                this.projectType = 'Agent';
            }
            else {
                this.projectType = 'Unify';
            }
            this.typeForErrorList = 0;
            this.typeForOptOutList = 0;
            this.deliveredWidget = {};
            this.openedWidget = {};
            this.clickedWidget = {};
            this.DSWidget = {};
            this.ExternalReportingWidget = {};
            this.validationWidget = [];
            this.bouncedWidget = {};
            this.onProjectChanged = new Subject();
            this.ondashboardMergedWidgetChanged = new BehaviorSubject({});
            this.onsentTotalWidgetChanged = new BehaviorSubject({});
            this.onDeliveredWidgetChanged = new BehaviorSubject({});
            this.onOpenedWidgetChanged = new BehaviorSubject({});
            this.onclickWidgetChanged = new BehaviorSubject({});
            this.onUnsubscribedWidgetChanged = new BehaviorSubject({});
            this.onBouncedWidgetChanged = new BehaviorSubject({});
            this.onDSWidgetChanged = new BehaviorSubject({});
            this.onExternalReportingWidgetChanged = new BehaviorSubject({});
            // this.onValidationWidgetChanged = new BehaviorSubject([]);
            this._fuseSplashScreenService._initById('splash-delivered-widget');
            this._fuseSplashScreenService._initById('splash-opened-widget');
            this._fuseSplashScreenService._initById('splash-clicked-widget');
            this._fuseSplashScreenService._initById('splash-bounced-widget');
            this._fuseSplashScreenService._initById('splash-unsubscribed-widget');
            this._fuseSplashScreenService._initByClass('splash-DS-widget');
            this._pageService.getCurrentPage(false);
            this._pageService.getAccountsTypesByUser();
            this.GetDashboardMergedWidget(0)
            this.getDashboardWidgetUndeliveredWidget(0,5);
            resolve('initialized');
        });
    }


    onProjectChange(project) {
        this.projectType = project;
        if (project == 'Unify') {
            this.GetDashboardMergedWidget(0)
            this.getDashboardWidgetUndeliveredWidget(0,5);
        }
        else if (project == 'Agent') {
            this.GetDashboardMergedWidget(0)
            this.getDashboardWidgetUndeliveredWidget(0,5);
        }else if(project == 'ExternalReporting') {
            this.getExternalReportingWidget();
        }
        else {
            this.getDSWidget();
        }
    }
    getDSWidget() {
        if (this.userDetails.isLocalDSReport) {
            this.getDSWidgetByUnifyDB();
        }
        else {
            this.getDSWidgetByDSDB();
        }
    }
    getDSWidgetByDSDB() {
        this._fuseSplashScreenService.showByClass('splash-DS-widget');
        let apiUrl: string = "/DSReporting/GetCompanyHomeDetails?companyName=" + this.userDetails.companyKey;
        this._restService.Get(apiUrl)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideByClass('splash-DS-widget');
                if (response.status == 200) {
                    this.DSWidget = response.data.data;
                    this.onDSWidgetChanged.next(this.DSWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideByClass('splash-DS-widget');
                });
    }
    getDSWidgetByUnifyDB() {
        this._fuseSplashScreenService.showByClass('splash-DS-widget');
        let apiUrl: string = "/DSReporting/GetCompanyHomeDetailsNew?TimeZoneId=" + momentTimezone.tz.guess();
        this._restService.Get(apiUrl)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideByClass('splash-DS-widget');
                if (response.status == 200) {
                    this.DSWidget = response.data;
                    this.onDSWidgetChanged.next(this.DSWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideByClass('splash-DS-widget');
                });
    }
    GetDashboardMergedWidget(type) {
        this._fuseSplashScreenService.showById('splash-delivered-widget');
        this._fuseSplashScreenService.showById('splash-sentTotal-widget');
        this._fuseSplashScreenService.showById('splash-opened-widget');
        this._fuseSplashScreenService.showById('splash-clicked-widget');
        this._fuseSplashScreenService.showById('splash-bounced-widget');
        this._restService.Get('/Inbox/GetDashboardWidgetMerged?typeId=' + type + '&isAgent=' + (this.isAgentRole && this.projectType == 'Agent'))
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideById('splash-delivered-widget');
                this._fuseSplashScreenService.hideById('splash-sentTotal-widget');
                this._fuseSplashScreenService.hideById('splash-opened-widget');
                this._fuseSplashScreenService.hideById('splash-clicked-widget');
                this._fuseSplashScreenService.hideById('splash-bounced-widget');
                if (response.status == 200) {
                    this.dashboardMergedWidget = response.data;
                    this.ondashboardMergedWidgetChanged.next(this.dashboardMergedWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideById('splash-delivered-widget');
                    this._fuseSplashScreenService.hideById('splash-sentTotal-widget');
                    this._fuseSplashScreenService.hideById('splash-opened-widget');
                    this._fuseSplashScreenService.hideById('splash-clicked-widget');
                    this._fuseSplashScreenService.hideById('splash-bounced-widget');
                });
    }
    GetSentTotalWidgetDelivered(type, reportType) {
        this._fuseSplashScreenService.showById('splash-sentTotal-widget');
        this._restService.Get('/Inbox/GetDashboardWidget?typeId=' + type + '&&ReportType='+ reportType + '&isAgent=' + (this.isAgentRole && this.projectType == 'Agent'))
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideById('splash-sentTotal-widget');
                if (response.status == 200) {
                    this.sentTotalWidget = response.data;
                    this.onsentTotalWidgetChanged.next(this.sentTotalWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideById('splash-sentTotal-widget');
                });
    }
    GetDashboardWidgetDelivered(type, reportType) {
        this._fuseSplashScreenService.showById('splash-delivered-widget');
        this._restService.Get('/Inbox/GetDashboardWidget?typeId=' + type + '&&ReportType='+ reportType + '&isAgent=' + (this.isAgentRole && this.projectType == 'Agent'))
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideById('splash-delivered-widget');
                if (response.status == 200) {
                    this.deliveredWidget = response.data;
                    this.onDeliveredWidgetChanged.next(this.deliveredWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideById('splash-delivered-widget');
                });
    }
    GetDashboardWidgetopened(type, reportType) {
        this._fuseSplashScreenService.showById('splash-opened-widget');
        this._restService.Get('/Inbox/GetDashboardWidget?typeId=' + type + '&&ReportType='+ reportType + '&isAgent=' + (this.isAgentRole && this.projectType == 'Agent'))
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideById('splash-opened-widget');
                if (response.status == 200) {
                    this.openedWidget = response.data;
                    this.onOpenedWidgetChanged.next(this.openedWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideById('splash-opened-widget');
                });
    }
    getDashboardWidgetClickWidget(type, reportType) {
        this._fuseSplashScreenService.showById('splash-clicked-widget');
        this._restService.Get('/Inbox/GetDashboardWidget?typeId=' + type + '&&ReportType='+ reportType + '&isAgent=' + (this.isAgentRole && this.projectType == 'Agent'))
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideById('splash-clicked-widget');
                if (response.status == 200) {
                    this.clickedWidget = response.data;
                    this.onclickWidgetChanged.next(this.clickedWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideById('splash-clicked-widget');
                });
    }
    getDashboardWidgetBounced(type, reportType) {
        this._fuseSplashScreenService.showById('splash-bounced-widget');
        this._restService.Get('/Inbox/GetDashboardWidget?typeId=' + type + '&&ReportType='+ reportType + '&isAgent=' + (this.isAgentRole && this.projectType == 'Agent'))
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideById('splash-bounced-widget');
                if (response.status == 200) {
                    this.bouncedWidget = response.data;
                    this.onBouncedWidgetChanged.next(this.bouncedWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideById('splash-bounced-widget');
                });
    }
    getDashboardWidgetUndeliveredWidget(type, reportType) {
        this._fuseSplashScreenService.showById('splash-unsubscribed-widget');
        this._restService.Get('/Inbox/GetDashboardWidget?typeId=' + type + '&&ReportType='+ reportType + '&isAgent=' + (this.isAgentRole && this.projectType == 'Agent'))
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideById('splash-unsubscribed-widget');
                if (response.status == 200) {
                    this.unsubscribedWidget = response.data;
                    this.onUnsubscribedWidgetChanged.next(this.unsubscribedWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideById('splash-unsubscribed-widget');
                });
    }
    getErrorList(getErrorListsRequest, isExport) {
        if (isExport) {
            return this._restService.Postblob('/Inbox/GetErrorlogs?export=' + isExport, getErrorListsRequest);
        }
        else {
            return this._restService.Post('/Inbox/GetErrorlogs?export=' + isExport, getErrorListsRequest);
        }
    }
    getErrorListCount(getErrorListsRequest) {
        return this._restService.Post('/Inbox/GetErrorlogsCount', getErrorListsRequest);
    }
    getOptOutList(getOptOutListsRequest, isExport) {
        if (isExport) {
            return this._restService.Postblob('/Inbox/GetUnsubscribedlogDetail?export=' + isExport, getOptOutListsRequest);
        }
        else {
            return this._restService.Post('/Inbox/GetUnsubscribedlogDetail?export=' + isExport, getOptOutListsRequest);
        }
    }
    getOptOutListCount(getOptOutListsRequest) {
        return this._restService.Post('/Inbox/GetUnsubscribedlogDetailCount', getOptOutListsRequest);
    }
    getRecentReplies(GetMessagelogsrequest, apiUrl) {
        return this._restService.Post(apiUrl, GetMessagelogsrequest);
    }
    getDSRecentReplies(GetDSMessagelogsrequest, isExport): Observable<any> {
        if (this.userDetails.isLocalDSReport) {
            return new Observable(subscriber => {
                switch (GetDSMessagelogsrequest.sortColumn) {
                    case 'CreatedDateTime':
                        GetDSMessagelogsrequest.sortColumn = "CreatedAt";
                        break;
                    case 'MessageText':
                        GetDSMessagelogsrequest.sortColumn = "Message";
                        break;
                    case 'SenderName':
                        GetDSMessagelogsrequest.sortColumn = "SenderFirstName";
                        break;
                    case 'RecipientName':
                        GetDSMessagelogsrequest.sortColumn = "RecipientFirstName";
                        break;
                }
                GetDSMessagelogsrequest.timeZoneId = momentTimezone.tz.guess();
                this._restService.Post('/DSReporting/GetReceivedMessageListNew', GetDSMessagelogsrequest)
                    .subscribe((res: any) => {
                        if (res.status == 200) {
                            let response: any = {};
                            response.data = res.data.messages.map((obj, index) => {
                                return {
                                    "id": obj.id,
                                    "messageId": obj.messageId ? obj.messageId : "",
                                    "messageText": obj.message ? obj.message : "",
                                    "createdDateTime": obj.createdAt ? obj.createdAt : "",
                                    "senderName": (obj.senderFirstName ? obj.senderFirstName : "") + (obj.senderLastName ? " " + obj.senderLastName : ""),
                                    "senderMobile": obj.senderMobile ? obj.senderMobile : "",
                                    "senderemail": obj.senderemail ? obj.senderemail : "",
                                    "recipientName": (obj.recipientFirstName ? obj.recipientFirstName : "") + (obj.recipientLastName ? " " + obj.recipientLastName : ""),
                                    "recipientemail": obj.recipientemail ? obj.recipientemail : "",
                                    "recipientMobile": obj.recipientMobile ? obj.recipientMobile : "",
                                    "recipientId ": obj.recipientId ? obj.recipientId : 0,
                                    "senderId ": obj.senderId ? obj.senderId : 0
                                };
                            });
                            response.totalCount = res.data.total;
                            res.data = response;
                            subscriber.next(res);
                        }
                        else {
                            subscriber.next(res);
                        }
                    }, (error: any) => {
                        subscriber.next(error);
                    });
            });
        }
        else {
            if (isExport) {
                return this._restService.Postblob('/DSReporting/GetReceivedList?export=' + isExport, GetDSMessagelogsrequest);
            }
            else {
                return this._restService.Post('/DSReporting/GetReceivedList?export=' + isExport, GetDSMessagelogsrequest);
            }
        }
    }
    getRecentSent(GetMessagelogsrequest) {
        return this._restService.Post('/Inbox/GetSentMessagelogsCommon', GetMessagelogsrequest);
    }
    getRecentSentCount(GetMessagelogsrequest) {
        return this._restService.Post('/Inbox/GetSentMessagelogsCount', GetMessagelogsrequest);
    }
    getDSRecentSent(GetDSSentMessagelogsrequest, isExport) {
        if (this.userDetails.isLocalDSReport) {
            return new Observable(subscriber => {
                GetDSSentMessagelogsrequest.timeZoneId = momentTimezone.tz.guess();
                switch (GetDSSentMessagelogsrequest.sortColumn) {
                    case 'CreatedDateTime':
                        GetDSSentMessagelogsrequest.sortColumn = "CreatedAt";
                        break;
                    case 'MessageText':
                        GetDSSentMessagelogsrequest.sortColumn = "Message";
                        break;
                }
                this._restService.Post('/DSReporting/GetSentMessagesListNew', GetDSSentMessagelogsrequest)
                    .subscribe((res: any) => {
                        if (res.status == 200) {
                            let response: any = {};
                            response.messages = res.data.messages.map((obj, index) => {
                                return {
                                    "id": obj.id,
                                    "message": obj.message ? obj.message : "",
                                    "createdDateTime": obj.createdDateTime ? obj.createdDateTime : "",
                                    "delivered": obj.delivered ? obj.delivered : 0,
                                    "rejected": obj.rejected ? obj.rejected : 0,
                                    "error": obj.error ? obj.error : 0,
                                    "sent": obj.sent ? obj.sent : 0,
                                    "total": obj.total ? obj.total : 0
                                };
                            });
                            response.totalCount = res.data.total;
                            res.data = response;
                            subscriber.next(res);
                        }
                        else {
                            subscriber.next(res);
                        }
                    }, (error: any) => {
                        subscriber.next(error);
                    });
            });
        }
        else {
            if (isExport) {
                return this._restService.Postblob('/DSReporting/GetMessages?export=' + isExport, GetDSSentMessagelogsrequest);
            }
            else {
                return this._restService.Post('/DSReporting/GetMessages?export=' + isExport, GetDSSentMessagelogsrequest);
            }
        }
    }

    getDSSentDetails(messageId, searchText, status) {
        if (this.userDetails.isLocalDSReport) {
            return new Observable(subscriber => {
                let req: any = {
                    "messageId": messageId,
                    "status": status,
                    "searchText": searchText
                };
                this._restService.Post('/DSReporting/GetMessagesDetailsNew', req)
                    .subscribe((res: any) => {
                        if (res.status == 200) {
                            let response: any = {};
                            response.data = res.data.messages.map((obj, index) => {
                                return {
                                    "id": obj.id,
                                    "messageId": obj.messageId ? obj.messageId : "",
                                    "messageText": obj.message ? obj.message : "",
                                    "createdDateTime": obj.createdAt ? obj.createdAt : "",
                                    "senderName": (obj.senderFirstName ? obj.senderFirstName : "") + (obj.senderLastName ? " " + obj.senderLastName : ""),
                                    "senderMobile": obj.senderMobile ? obj.senderMobile : "",
                                    "senderEmail": obj.senderEmail ? obj.senderEmail : "",
                                    "recipientName": (obj.recipientFirstName ? obj.recipientFirstName : "") + (obj.recipientLastName ? " " + obj.recipientLastName : ""),
                                    "recipientEmail": obj.recipientEmail ? obj.recipientEmail : "",
                                    "recipientMobile": obj.recipientMobile ? obj.recipientMobile : "",
                                    "recipientID ": obj.recipientId ? obj.recipientId : 0,
                                    "senderID ": obj.senderId ? obj.senderId : 0,
                                    "isEmail": obj.isEmail,
                                    "isText": !obj.isEmail
                                };
                            });
                            response.totalCount = res.data.total;
                            res.data = response;
                            subscriber.next(res);
                        }
                        else {
                            subscriber.next(res);
                        }
                    }, (error: any) => {
                        subscriber.next(error);
                    });
            });
        }
        else {
            let _url = '/DSReporting/GetMessagesDetails?messageId=' + messageId;
            if (searchText) {
                _url = _url + '&searchText=' + searchText;
            }
            if (status) {
                _url = _url + '&status=' + status;
            }
            return this._restService.Get(_url);
        }
    }
    getSentDetails(getSentDetailsrequest, isExport) {
        if (isExport) {
            return this._restService.Postblob('/Inbox/GetSentMessageDetaillogs?export=' + isExport, getSentDetailsrequest);
        }
        else {
            return this._restService.Post('/Inbox/GetSentMessageDetaillogs?export=' + isExport, getSentDetailsrequest);
        }
    }
    getSentDetailsCount(getSentDetailsrequest) {
        return this._restService.Post('/Inbox/GetSentMessageDetaillogsCount', getSentDetailsrequest);
    }

    getInredisSentMessage(getInredisSentMessagerequest) {
        return this._restService.Post('/Inbox/GetInredisSentMessageDetaillogs', getInredisSentMessagerequest);
    }

    saveFile(blob, fileName) {
        if (window.navigator.msSaveOrOpenBlob) { // For IE:
            navigator.msSaveBlob(blob, fileName);
        } else { // For other browsers:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
        }
    }
    getDSErrorlogs(req: any) {
        if (this.userDetails.isLocalDSReport) {
            return new Observable(subscriber => {
                switch (req.sortColumn) {
                    case 'CreatedDateTime':
                        req.sortColumn = "CreatedAt";
                        break;
                    case 'MessageText':
                        req.sortColumn = "Message";
                        break;
                    case 'SenderName':
                        req.sortColumn = "SenderFirstName";
                        break;
                    case 'RecipientName':
                        req.sortColumn = "RecipientFirstName";
                        break;
                    case 'MailgunError':
                        req.sortColumn = "Status";
                        break;
                }
                req.timeZoneId = momentTimezone.tz.guess();
                this._restService.Post('/DSReporting/GetErrorListNew', req)
                    .subscribe((res: any) => {
                        if (res.status == 200) {
                            let response: any = {};
                            response.data = res.data.messages.map((obj, index) => {
                                return {
                                    "id": obj.id,
                                    "messageId": obj.messageId ? obj.messageId : "",
                                    "messageText": obj.message ? obj.message : "",
                                    "createdDateTime": obj.createdAt ? obj.createdAt : "",
                                    "senderName": (obj.senderFirstName ? obj.senderFirstName : "") + (obj.senderLastName ? " " + obj.senderLastName : ""),
                                    "senderMobile": obj.senderMobile ? obj.senderMobile : "",
                                    "senderEmail": obj.senderEmail ? obj.senderEmail : "",
                                    "recipientName": (obj.recipientFirstName ? obj.recipientFirstName : "") + (obj.recipientLastName ? " " + obj.recipientLastName : ""),
                                    "recipientEmail": obj.recipientEmail ? obj.recipientEmail : "",
                                    "recipientMobile": obj.recipientMobile ? obj.recipientMobile : "",
                                    "recipientId ": obj.recipientId ? obj.recipientId : 0,
                                    "senderId ": obj.senderId ? obj.senderId : 0,
                                    "isEmail": obj.isEmail,
                                    "isText": !obj.isEmail,
                                    "mailgunError": obj.status
                                };
                            });
                            response.totalCount = res.data.total;
                            res.data = response;
                            subscriber.next(res);
                        }
                        else {
                            subscriber.next(res);
                        }
                    }, (error: any) => {
                        subscriber.next(error);
                    });
            });
        }
        else {
            return this._restService.Post('/DSReporting/GetErrorList', req);
        }
    }
    getDSOptOutlogs(req: any) {
        if (this.userDetails.isLocalDSReport) {
            return new Observable(subscriber => {
                req.timeZoneId = momentTimezone.tz.guess();
                this._restService.Post('/DSReporting/GetOptOutListNew', req)
                    .subscribe((res: any) => {
                        if (res.status == 200) {
                            let response: any = {};
                            response.data = res.data.data.map((obj, index) => {
                                return {
                                    "id": obj.id,
                                    "firstName": obj.firstName ? obj.firstName : "",
                                    "lastName": obj.lastName ? obj.lastName : "",
                                    "email": obj.email ? obj.email : "",
                                    "mobile": obj.mobile ? obj.mobile : "",
                                    "webalias": obj.webalias ? obj.webalias : "",
                                    "customerId": obj.customerId ? obj.customerId : 0,
                                };
                            });
                            response.totalCount = res.data.total;
                            res.data = response;
                            subscriber.next(res);
                        }
                        else {
                            subscriber.next(res);
                        }
                    }, (error: any) => {
                        subscriber.next(error);
                    });
            });
        }
        else {
            return this._restService.Post('/DSReporting/getDSOptOutlogs', req);
        }
    }
    getValidDatalogs(req: any) {
        return this._restService.Post('/Inbox/GetContactValidationDetails', req);
    }
    getValidDatalogsList(req, isExport) {
        return this._restService.Postblob('/Inbox/GetContactValidationDetails?export=' + isExport, req);
    }
    getDSClickslogs(req: any) {
        if (this.userDetails.isLocalDSReport) {
            return new Observable(subscriber => {                
                switch (req.sortColumn) {
                    case 'CreatedDateTime':
                        req.sortColumn = "CreatedAt";
                        break;
                    case 'MessageText':
                        req.sortColumn = "Message";
                        break;
                    case 'SenderName':
                        req.sortColumn = "SenderFirstName";
                        break;
                    case 'RecipientName':
                        req.sortColumn = "RecipientFirstName";
                        break;
                    case 'MailgunError':
                        req.sortColumn = "Status";
                        break;
                }
                req.timeZoneId = momentTimezone.tz.guess();
                this._restService.Post('/DSReporting/GetClickslogsNew', req)
                    .subscribe((res: any) => {
                        if (res.status == 200) {
                            let response: any = {};
                            response.messageList = res.data.messages.map((obj, index) => {
                                return {
                                    "messageId": obj.messageId ? obj.messageId : "",
                                    "messageText": obj.message ? obj.message : "",
                                    "createdDateTime": obj.createdAt ? obj.createdAt : "",
                                    "senderName": (obj.senderFirstName ? obj.senderFirstName : "") + (obj.senderLastName ? " " + obj.senderLastName : ""),
                                    "senderMobile": obj.senderMobile ? obj.senderMobile : "",
                                    "senderEmail": obj.senderEmail ? obj.senderEmail : "",
                                    "recipientName": (obj.recipientFirstName ? obj.recipientFirstName : "") + (obj.recipientLastName ? " " + obj.recipientLastName : ""),
                                    "recipientEmail": obj.recipientEmail ? obj.recipientEmail : "",
                                    "recipientMobile": obj.recipientMobile ? obj.recipientMobile : "",
                                    "recipientId ": obj.recipientId ? obj.recipientId : 0,
                                    "senderId ": obj.senderId ? obj.senderId : 0,
                                    "isEmail": obj.isEmail,
                                    "isText": !obj.isEmail
                                };
                            });
                            response.totalCount = res.data.total;
                            res.data = response;
                            subscriber.next(res);
                        }
                        else {
                            subscriber.next(res);
                        }
                    }, (error: any) => {
                        subscriber.next(error);
                    });
            });
        }
        else {
            return this._restService.Post('/DSReporting/GetClickslogs', req);
        }
    }
    getDSOpenslogs(req: any) {
        if (this.userDetails.isLocalDSReport) {
            return new Observable(subscriber => {                
                switch (req.sortColumn) {
                    case 'CreatedDateTime':
                        req.sortColumn = "CreatedAt";
                        break;
                    case 'MessageText':
                        req.sortColumn = "Message";
                        break;
                    case 'SenderName':
                        req.sortColumn = "SenderFirstName";
                        break;
                    case 'RecipientName':
                        req.sortColumn = "RecipientFirstName";
                        break;
                    case 'MailgunError':
                        req.sortColumn = "Status";
                        break;
                }
                req.timeZoneId = momentTimezone.tz.guess();
                this._restService.Post('/DSReporting/GetOpenslogsNew', req)
                    .subscribe((res: any) => {
                        if (res.status == 200) {
                            let response: any = {};
                            response.messageList = res.data.messages.map((obj, index) => {
                                return {
                                    "messageId": obj.messageId ? obj.messageId : "",
                                    "messageText": obj.message ? obj.message : "",
                                    "createdDateTime": obj.createdAt ? obj.createdAt : "",
                                    "senderName": (obj.senderFirstName ? obj.senderFirstName : "") + (obj.senderLastName ? " " + obj.senderLastName : ""),
                                    "senderMobile": obj.senderMobile ? obj.senderMobile : "",
                                    "senderEmail": obj.senderEmail ? obj.senderEmail : "",
                                    "recipientName": (obj.recipientFirstName ? obj.recipientFirstName : "") + (obj.recipientLastName ? " " + obj.recipientLastName : ""),
                                    "recipientEmail": obj.recipientEmail ? obj.recipientEmail : "",
                                    "recipientMobile": obj.recipientMobile ? obj.recipientMobile : "",
                                    "recipientId ": obj.recipientId ? obj.recipientId : 0,
                                    "senderId ": obj.senderId ? obj.senderId : 0,
                                    "isEmail": obj.isEmail,
                                    "isText": !obj.isEmail
                                };
                            });
                            response.totalCount = res.data.total;
                            res.data = response;
                            subscriber.next(res);
                        }
                        else {
                            subscriber.next(res);
                        }
                    }, (error: any) => {
                        subscriber.next(error);
                    });
            });
        }
        else {
            return this._restService.Post('/DSReporting/GetOpenslogs', req);
        }
    }
    getExternalReportingWidget() {
        this._fuseSplashScreenService.showByClass('splash-ExternalReporting-widget');
        let apiUrl: string = "/ExternalMessage/GetExternalDashboardReport?TimeZoneId=" + momentTimezone.tz.guess();
        this._restService.Get(apiUrl)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hideByClass('splash-ExternalReporting-widget');
                if (response.status == 200) {
                    this.ExternalReportingWidget = response.data;
                    this.onExternalReportingWidgetChanged.next(this.ExternalReportingWidget);
                }
            },
                error => {
                    this._fuseSplashScreenService.hideByClass('splash-ExternalReporting-widget');
                });
    }
    getExternalReportingSentMessage(ExternalReportingSentMessagelogsrequest) {
       return this._restService.Post('/ExternalMessage/GetExternalSentMessages', ExternalReportingSentMessagelogsrequest);
    }
    getExternalReportingSentDetails(externalReportingSentDetails) {
       return this._restService.Post('/ExternalMessage/GetExternalMessagesDetails', externalReportingSentDetails);
    }
    getExternalReportingRecentReplies(ExternalReportReceiveMessagerequest) {
        return this._restService.Post('/ExternalMessage/GetExternalReceivedMessages', ExternalReportReceiveMessagerequest);
    }
    getExternalReportingErrorlogs(ExternalReportErrorLogsrequest) {
        return this._restService.Post('/ExternalMessage/GetExternalErrors', ExternalReportErrorLogsrequest);
    } 
    getExternalReportingOptOutlogs(ExternalReportOptOutLogsrequest) {
        return this._restService.Post('/ExternalMessage/GetExternalOptOuts', ExternalReportOptOutLogsrequest);
    }
    getExternalReportingOpenslogs(externalReportingOpensReq) {
        return this._restService.Post('/ExternalMessage/GetOpenslogsNew', externalReportingOpensReq);
    }
    getExternalReportingClickslogs(externalReportingClickReq) {
        return this._restService.Post('/ExternalMessage/GetExternalClicklogs', externalReportingClickReq);
    }
    getProgressMessageReport(id) {
        return this._restService.Get('/Inbox/GetInredisMessageReport?MessageId='+id);
    }
    getInRediesReplies(pageNumber, pageSize) {
        return this._restService.Get('/Inbox/GetInredisReplies?PageNumber='+pageNumber+'&PageSize='+pageSize);
    }
    GetContactIdByMessageId(id) {
        return this._restService.Get('/Contact/GetContactIdByMessageId/'+id);
    }
    RendSendMessage(MessageId) {
        return this._restService.Get('/Inbox/ResendMessage/'+MessageId);
    }
    getSentMessagelogsTracking(data:any){
        return this._restService.Post(`/Inbox/GetSentMessagelogsTracking`,data);
    }
    GetSentMessagelogsTrackingCount(data:any){
        return this._restService.Post(`/Inbox/GetSentMessagelogsTrackingCount`,data);
    }
}
