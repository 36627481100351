import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from 'app/main/pages/pages.service';

@Component({
  selector: 'app-external-reporting-sent-message-details',
  templateUrl: './external-reporting-sent-message-details.component.html',
  styleUrls: ['./external-reporting-sent-message-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ExternalReportingSentMessageDetailsComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  filterForm: FormGroup;
  reportingTypes: any[] = [
    {
      id: 0,
      name: "Total"
    },
    {
      id: 1,
      name: "Delivered"
    },
    {
      id: 2,
      name: "Failed"
    },
    {
      id: 3,
      name: "Opened"
    },
    {
      id: 4,
      name: "Replies"
    },
    {
      id: 5,
      name: "Clicked"
    }
  ];
  List: any = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['type', 'RecipientName', 'email', 'phone', 'date'];
  TotalCount: any = 0;
  pageNumber = 1;
  pageSize = 10;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _projectDashboardService: ProjectDashboardService,
    private _authService: AuthService,
    public matDialogRef: MatDialogRef<ExternalReportingSentMessageDetailsComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _pageService: PageService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.filterForm = this._formBuilder.group({
      reportingtype: [this._data.reportingType],
      searchtext: ['']
    });
  }

  ngOnInit(): void {
    this.getExternalReportingSentDetails();
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  getExternalReportingSentDetails() {
    this._fuseSplashScreenService.show();
    const externalReportingSentDetails = {
        "messageId": this._data.messageId,
        "status": this.filterForm.get('reportingtype').value,
        "pageNo": 0,
        "pageSize": 0,
        "searchText": this.filterForm.get('searchtext').value,
        "sortColumn": "",
        "sortColumnDir": ""
      
    }
    this._projectDashboardService.getExternalReportingSentDetails(externalReportingSentDetails)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          this.List = res.data.messages;
          this.TotalCount = res.data.total;
        }
        else {
          this.List = [];
          this.TotalCount = 0;
        }
        this.dataSource = new MatTableDataSource(this.List);
      },
        error => {
          this.List = [];
          this.TotalCount = 0;
          this.dataSource = new MatTableDataSource(this.List);
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  search() {
    this.getExternalReportingSentDetails();
  }
  goToContactDetails(id: number, email: string, mobile: string) {
    this._pageService.getContactIdByAssociateId(id, email, mobile)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200 && res.data) {
          this.matDialogRef.close(true);
          this._router.navigate(['/pages/contactdetails/' + res.data]);
        }
        else {
          this._toastr.error('Contact Not Found', 'Error');
        }
      },
        error => {
          console.log(error);
          this._fuseSplashScreenService.hide();
          if (error && error.error && error.error.error) {
            this._toastr.error(error.error.error, 'Error');
          }
          else {
            this._toastr.error(error.message, 'Error');
          }
        }
      );
  }
}
