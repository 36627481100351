import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { HelpTinyUrlDialogComponent } from '../help-tiny-url/help-tiny-url.component';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'add-link-tracking',
    templateUrl: './add-link-tracking.component.html',
    styleUrls: ['./add-link-tracking.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddLinkTrackingComponent implements OnInit, AfterViewInit{
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    formData: any;
    dialogTitle: string = "Add Link Tracking";
    dialogRef: any;
    linkTrackingUrlStatus:any = "";
    linkTrackingUrl: FormControl = new FormControl();
    @ViewChild('linkTrackingData', {static:false}) linkTrackingUrlDataRef: ElementRef;
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    constructor(
        public matDialogRef: MatDialogRef<AddLinkTrackingComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        public _pageService: PageService
    ) {
        this.dialogTitle = this._data.dialogTitle;
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {

    }
    ngAfterViewInit(): void {
     
    }
    addLinkTracking() {
      if(this.linkTrackingUrlStatus == "Valid") {
        this.matDialogRef.close(this.linkTrackingUrl.value);
      }  
    }
    helpTinyUrl() {
        this.dialogRef = this._matDialog.open(HelpTinyUrlDialogComponent, {
          panelClass: 'help-tiny-url-dialog'
        });
        this.dialogRef.afterClosed().subscribe(response => {
            if (!response) {
              return;
            }
          });
    }
    linkTrackingUrlData() {
        var expression = /https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const tinyUrl = this.linkTrackingUrlDataRef.nativeElement.value;
        if(tinyUrl != "" && tinyUrl != null) {
          if (tinyUrl.match(expression)) {
            this.linkTrackingUrlStatus = "Valid";
          } else {
            this.linkTrackingUrlStatus = "InValid";
          }
        }
        
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("add-link-tracking-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("add-link-tracking-content").style.maxHeight = "65vh";
        document.getElementById("add-link-tracking-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("add-link-tracking-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("add-merge-field-content").style.maxHeight = "100vh";
        document.getElementById("add-merge-field-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

