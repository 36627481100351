import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Account, AccountType } from 'app/main/pages/accounts/account.model';
import { AccountsService } from 'app/main/pages/accounts/accounts.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuseSplashScreenService } from '../../../../../@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { PageService } from 'app/main/pages/pages.service';
import { CompanyService } from 'app/main/pages/company/company.service';
import { isThisSecond } from 'date-fns';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'account-form-dialog',
    templateUrl: './account-form.component.html',
    styleUrls: ['./account-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})

export class AccountsFormDialogComponent {
    IsDialogMax: boolean = false;
    companyId: number;
    action: string;
    account: any;
    accountTypes: any[] = [];
    allUnifiedAccounts: any[];
    registredAccounts: any;
    accountForm: FormGroup;
    dialogTitle: string;// Private
    private _unsubscribeAll: Subject<any>;
    istitle: boolean = false;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    usernameLabel: string = "Username";
    passwordLabel: string = "Password";
    emailOrNumberLabel: string = "Email/Phone Number";
    supportDomainsId: number = 0;
    supportDomainsArray: any = [];
    marketingSupportDomainsId: number = 0;
    marketingSupportDomainsArray: any = [];
    customSupportDomainsId: number = 0;
    customSupportDomainsArray: any = [];
    hostWidth: number;
    hostHeight: number;
    alphaPattern: string = "^[a-zA-Z]$";
    accountTypeId:any;
    password;
    show: boolean = false;
    supportDomainLabel: string = "Support Account";
    marketingLabel: string = "Marketing Domain";
    marketingSupportLabel: string = "Marketing Support Domain";
    editMessage1: boolean = false;
    editMessage2: boolean = false;
    editMessage3: boolean = false;
    public optOutMessage: any;
    public helpMessage: any;
    public optInMessage: any;
    optOutChecked: boolean;
    helpChecked: boolean;
    optInChecked: boolean;
    constructor(
        public matDialogRef: MatDialogRef<AccountsFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: FormBuilder,
        private _accountsService: AccountsService,
        private _pageService: PageService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
        private _companyService: CompanyService,
        private chRef: ChangeDetectorRef,
    ) {
        // Set the defaults
        this.companyId = this._data.companyId;
        this.action = this._data.action;
        this.accountTypeId = this._data.typeId;
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Account';
            this.account = this._data.account;
            this.istitle = true;
        }
        else {
            this.dialogTitle = 'New Account';
            this.account = new Account({});
            this.istitle = false;
        }
        this.accountForm = this.createaccountForm();
    }
    ngOnInit(): void {
        this.password = 'password';
        this.accountTypes = [];
        this._pageService.onAccountTypeChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(types => {
                if (!this._data.agentId) {
                    this.accountTypes = this._pageService.allAccountTypes.filter(type => ((type.id != 7) && (type.id != 11) && (type.id != 12) && (type.id != 14) && (type.id != 15) && (type.id != 16) && (type.id != 17) && (type.id != 21) && (type.id != 22) && (type.id != 23) && (type.id != 24) ));
                    if (this.action === 'new') {
                        if (this.accountTypes.length > 0) {
                            if (this._data.typeId) {
                                this.accountForm.patchValue({
                                    unifiedTypeId: this._data.typeId
                                });
                            }
                            else {
                                this.chRef.detectChanges();
                                this.accountForm.patchValue({
                                    unifiedTypeId: this.accountTypes[0].id
                                });
                            }
                        }
                        this.changeAccountType(true);
                        this.optOutMessage = 'You have been successfully opted out. To opt back in, reply START to this message.';
                        this.helpMessage ='For more HELP, contact ZipLingo at support@ziplingo.com.';
                        this.optInMessage = 'You have been successfully opted in.'
                        this.accountForm.get('unifiedAccountAutoresponder.optOutMessage').setValue(this.optOutMessage);
                        this.accountForm.get('unifiedAccountAutoresponder.helpMessage').setValue(this.helpMessage);
                        this.accountForm.get('unifiedAccountAutoresponder.optInMessage').setValue(this.optInMessage);
                    }
                }
            });
            this._pageService.onAccountTypeByUserChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(types => {
                if (this._data.agentId) {
                    this.accountTypes = this._pageService.accountTypesByUser.filter(type => ((type.id != 7) && (type.id != 11) && (type.id != 12) && (type.id != 14) && (type.id != 15) && (type.id != 16) && (type.id != 17) && (type.id != 21) && (type.id != 22) && (type.id != 23) && (type.id != 24)));
                    if (this.action === 'new') {
                        if (this.accountTypes.length > 0) {
                            if (this._data.typeId) {
                                this.accountForm.patchValue({
                                    unifiedTypeId: this._data.typeId
                                });
                            }
                            else {
                                this.accountForm.patchValue({
                                    unifiedTypeId: this.accountTypes[0].id
                                });
                            }
                        }
                        this.changeAccountType(true);

                    }
                }
            });
        if (this.action === 'edit') {
            this._fuseSplashScreenService.show();
            this._pageService.getAccountById(this._data.account.id, this._data.agentId)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this.account = res.data;
                        if(this.account && this.account.unifiedTypeId) {
                            if(this.account.unifiedTypeId == 2) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "SMS") {
                                        item.id = 2;
                                    }
                                });
                            }else if(this.account.unifiedTypeId == 11) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "SMS") {
                                        item.id = 11;
                                    }
                                });
                            }else if(this.account.unifiedTypeId == 13) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "International SMS") {
                                        item.id = 13;
                                    }
                                });
                            }else if(this.account.unifiedTypeId == 14) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "International SMS") {
                                        item.id = 14;
                                    }
                                });
                            }else if(this.account.unifiedTypeId == 15) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "SMS") {
                                        item.id = 15;
                                    }
                                });
                            }else if(this.account.unifiedTypeId == 17) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "International SMS") {
                                        item.id = 17;
                                    }
                                });
                            }
                            else if(this.account.unifiedTypeId == 21) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "Email") {
                                        item.id = 21;
                                    }
                                });
                            }
                            else if(this.account.unifiedTypeId == 1) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "Email") {
                                        item.id = 1;
                                    }
                                });
                            }
                            else if(this.account.unifiedTypeId == 22) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "SMS") {
                                        item.id = 22;
                                    }
                                });
                            }
                            else if(this.account.unifiedTypeId == 8) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "ShortCode") {
                                        item.id = 8;
                                    }
                                });
                            }
                            else if(this.account.unifiedTypeId == 24) {
                                this.accountTypes.forEach((item, index) => {
                                    if(item.name == "ShortCode") {
                                        item.id = 24;
                                    }
                                });
                            }
                            
                            this.chRef.detectChanges();
                        }
                        this.accountForm.patchValue({
                            id: this.account.id,
                            userId: this.account.userId,
                            username: this.account.userName,
                            password: this.account.password,
                            unifiedTypeId: this.account.unifiedTypeId,
                            emailOrNumber: this.account.emailOrNumber,
                            signatureText: this.account.signatureText,
                            allowMMS: this.account.allowMMS,
                            allowInternationalSMS: this.account.allowInternationalSMS,
                            prefix: this.account.prefix
                        });
                        if(this.account && (this.account.unifiedTypeId == 11 || this.account.unifiedTypeId == 14)) {
                            this.accountForm.patchValue({
                                apiToken: this.account.apiToken,
                                apiSecret: this.account.apiSecret,
                                providerType: this.account.unifiedTypeId
                            });    
                        }
                        if(this.account && (this.account.unifiedTypeId == 13 || this.account.unifiedTypeId == 15 || this.account.unifiedTypeId == 17 || this.account.unifiedTypeId == 21  || this.account.unifiedTypeId == 1 || this.account.unifiedTypeId == 22 || this.account.unifiedTypeId==24 || this.account.unifiedTypeId==8 )) {
                            this.accountForm.patchValue({
                                providerType: this.account.unifiedTypeId
                            });    
                        }
                        if(this.account && this.account.unifiedTypeId == 11) {
                            this.accountForm.patchValue({
                                mps: this.account.mps
                            });    
                        }
                        if(this.account && this.account.unifiedTypeId == 20) {
                            this.accountForm.patchValue({
                                apiClientId: this.account.apiClientId
                            });    
                        }
                        
                        if(this.account.supportAccounts && this.account.supportAccounts.length > 0) {
                            this.account.supportAccounts.forEach((value, index) => {
                                if(value.type == 1) {
                                    this.accountForm.get('supportAccounts.domain').setValue(value.domain ? value.domain : '');
                                    this.accountForm.get('supportAccounts.prefix').patchValue(value.prefix ? value.prefix : '');
                                }else if(value.type == 2) {
                                    this.accountForm.get('customAccounts.domain').setValue(value.domain ? value.domain : '');
                                    this.accountForm.get('customAccounts.prefix').patchValue(value.prefix ? value.prefix : '');
                                }
                            });
                        }
                        this.optOutMessage = this.account && this.account.unifiedAccountAutoresponder !== null && this.account.unifiedAccountAutoresponder.optOutMessage !== null ? this.account.unifiedAccountAutoresponder.optOutMessage : 'You have been successfully opted out. To opt back in, reply START to this message.';
                        this.helpMessage =this.account && this.account.unifiedAccountAutoresponder !== null && this.account.unifiedAccountAutoresponder.helpMessage !== null ? this.account.unifiedAccountAutoresponder.helpMessage : 'For more HELP, contact ZipLingo at support@ziplingo.com.';
                        this.optInMessage = this.account && this.account.unifiedAccountAutoresponder !== null && this.account.unifiedAccountAutoresponder.optInMessage !== null ? this.account.unifiedAccountAutoresponder.optInMessage : 'You have been successfully opted in.';
                        this.optOutChecked = this.account && this.account.unifiedAccountAutoresponder !== null && this.account.unifiedAccountAutoresponder.optOut !== null ? this.account.unifiedAccountAutoresponder.optOut : false;
                        this.helpChecked = this.account && this.account.unifiedAccountAutoresponder !== null && this.account.unifiedAccountAutoresponder.help !== null ? this.account.unifiedAccountAutoresponder.help : false;
                        this.optInChecked = this.account && this.account.unifiedAccountAutoresponder !== null && this.account.unifiedAccountAutoresponder.optIn !== null ? this.account.unifiedAccountAutoresponder.optIn : false;
                        this.accountForm.get('unifiedAccountAutoresponder.optOut').setValue(this.optOutChecked);
                        this.accountForm.get('unifiedAccountAutoresponder.optOutMessage').setValue(this.optOutMessage);
                        this.accountForm.get('unifiedAccountAutoresponder.help').setValue(this.helpChecked);
                        this.accountForm.get('unifiedAccountAutoresponder.helpMessage').setValue(this.helpMessage);
                        this.accountForm.get('unifiedAccountAutoresponder.optIn').setValue(this.optInChecked);
                        this.accountForm.get('unifiedAccountAutoresponder.optInMessage').setValue(this.optInMessage);
                        if (this.account.supportDomains.length > 0) {
                            this.account.supportDomains.forEach((domain: any, index: number) => {
                                this.addDomains(domain);
                            });
                        }
                        if (this.account.supportAccounts && this.account.supportAccounts.length > 0) {
                            this.account.supportAccounts.forEach((value, index) => {
                                if(value.type == 1 && value.supportDomains.length > 0) {
                                        value.supportDomains.forEach((supportDomainValue: any, index: number) => {
                                        this.addMarketingDomains(supportDomainValue);
                                    });
                                }else if((value.type == 2) && value.supportDomains.length > 0) {
                                        value.supportDomains.forEach((supportCustomDomainValue: any, index: number) => {
                                        this.addCustomDomains(supportCustomDomainValue);
                                    });
                                }
                            });
                        }
                        this.accountForm.get('unifiedTypeId').disable();
                        this.accountForm.get('providerType').disable();
                        this.changeAccountType(false);
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        console.log(error);
                        //this._toastr.error(error.error.error, 'Error');
                    }
                );
        }
        this.accountForm.get('unifiedTypeId').valueChanges.subscribe(val => {
            if (val == 11 || val == 14) {
              this.accountForm.controls['apiToken'].setValidators([Validators.required]);
              this.accountForm.controls['apiSecret'].setValidators([Validators.required]);
            } else {
              this.accountForm.controls['apiToken'].clearValidators();
              this.accountForm.controls['apiSecret'].clearValidators();
            }
            this.accountForm.controls['apiToken'].updateValueAndValidity();
            this.accountForm.controls['apiSecret'].updateValueAndValidity();
            if(val == 2 || val == 13 || val == 14 || val == 11 || val == 15) {
                this.accountForm.patchValue({
                    providerType: val
                }); 
            }
            if (val == 9) {
                this._pageService.removeValidators(this.accountForm, "username");
                this._pageService.removeValidators(this.accountForm, "password");
                this._pageService.removeValidators(this.accountForm, "emailOrNumber");
                this._pageService.removeValidators(this.accountForm, "apiClientId");
            }else if (val == 19) {
                this._pageService.removeValidators(this.accountForm, "username");
                this._pageService.removeValidators(this.accountForm, "emailOrNumber");
                this._pageService.removeValidators(this.accountForm, "apiClientId");
            }else if(val == 20) {
                this._pageService.removeValidators(this.accountForm, "emailOrNumber");
                this._pageService.addValidators(this.accountForm, "apiClientId");
            }
            else if(val == 22 || val==24) {
                this._pageService.removeValidators(this.accountForm, "password");
            }
            else {
                this._pageService.addValidators(this.accountForm, "username");
                this._pageService.addValidators(this.accountForm, "password");
                this._pageService.addValidators(this.accountForm, "emailOrNumber");
                this._pageService.removeValidators(this.accountForm, "apiClientId");
            }
        }); 
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createaccountForm(): FormGroup {
        return this._formBuilder.group({
            id: [0],
            userId: [''],
            username: ['', [Validators.required]],
            password: ['', [Validators.required]],
            unifiedTypeId: ['', [Validators.required]],
            emailOrNumber: this.accountTypeId !=1 ? ['', [Validators.required]] : ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
            signatureText: [''],
            supportDomains: this._formBuilder.array([]),
            allowMMS: [false],
            prefix: ['', [Validators.pattern('[a-zA-Z]*')]],
            supportAccounts: new FormGroup({
                // id:new FormControl(0),
                domain: new FormControl(''),
                supportDomains: this._formBuilder.array([]),
                prefix:new FormControl('',  [Validators.pattern('[a-zA-Z]*')]),
              }),
            customAccounts: new FormGroup({
                // id:new FormControl(0),
                domain: new FormControl(''),
                supportDomains: this._formBuilder.array([]),
                prefix:new FormControl('',  [Validators.pattern('[a-zA-Z]*')]),
            }),  
            providerType: [''],
            apiToken: [''],
            apiSecret: [''],
            mps: [0],
            allowInternationalSMS: [false],
            apiClientId: [''],
            unifiedAccountAutoresponder: new FormGroup({
                optOut: new FormControl([true]),
                optOutMessage: new FormControl(''),
                help: new FormControl([true]),
                helpMessage: new FormControl(''),
                optIn: new FormControl([true]),
                optInMessage: new FormControl('')
            })
        });
    }

    addAccount() {
        var FormData = this.accountForm.getRawValue();
        if (this.accountForm.invalid) {
            this._pageService.validateAllFormFields(this.accountForm);
            return false;
        }
        if (FormData.unifiedTypeId == 6 && !FormData.emailOrNumber) {
            this._toastr.warning('Bot Id is not found, Please use a valid API Token', 'Warning');
            return false;
        }
        let supportDomains: any = [];
        let marketingSupportDomain: any = [];
        let customSupportDomain: any = [];
        if (FormData.unifiedTypeId == 1 || FormData.unifiedTypeId == 21 || FormData.unifiedTypeId == 11 || FormData.unifiedTypeId == 14 || FormData.unifiedTypeId == 2 || FormData.unifiedTypeId == 13 || FormData.unifiedTypeId == 15 || FormData.unifiedTypeId == 17) {
            FormData.supportDomains.map(item => {
                if (item.domain) {
                    supportDomains.push(item.domain);
                }
            });
            FormData.supportAccounts.supportDomains.map(item => {
                if (item.marketingdomain) {
                    marketingSupportDomain.push(item.marketingdomain);
                }
            });
            FormData.supportAccounts.supportDomains = marketingSupportDomain;
            FormData.customAccounts.supportDomains.map(item => {
                if (item.customDomain) {
                    customSupportDomain.push(item.customDomain);
                }
            });
            FormData.customAccounts.supportDomains = customSupportDomain;
            this.optOutMessage = FormData.unifiedAccountAutoresponder.optOutMessage;
            this.helpMessage = FormData.unifiedAccountAutoresponder.helpMessage;
            this.optInMessage = FormData.unifiedAccountAutoresponder.optInMessage;   
        }        
        let ContactsRequest: any = {
            "unifiedTypeId": FormData.unifiedTypeId,
            "emailOrNumber": (FormData.unifiedTypeId == 9 || FormData.unifiedTypeId == 19) ? 'notification' : FormData.emailOrNumber,
            "userName": FormData.username,
            "password": FormData.password,
            "signatureText": (FormData.unifiedTypeId == 1 || FormData.unifiedTypeId == 21) ? FormData.signatureText : '',
            "supportDomains": supportDomains,
            "allowMMS": FormData.allowMMS,
            "allowInternationalSMS": FormData.allowInternationalSMS,
            "unifiedAccountAutoresponder": {
                "optOut": FormData.unifiedAccountAutoresponder.optOut[0],
                "optOutMessage": FormData.unifiedAccountAutoresponder.optOutMessage,
                "help": FormData.unifiedAccountAutoresponder.help[0],
                "helpMessage": FormData.unifiedAccountAutoresponder.helpMessage,
                "optIn": FormData.unifiedAccountAutoresponder.optIn[0],
                "optInMessage": FormData.unifiedAccountAutoresponder.optInMessage
            },
        };
        let supAccount = [];
        if (FormData.unifiedTypeId == 1 || FormData.unifiedTypeId == 21) {
            let supportAccountsData =  FormData.supportAccounts;
            supportAccountsData.type = FormData.supportAccounts.domain != "" ? 1 : 0;
            let customAccountData = FormData.customAccounts;
            customAccountData.type = FormData.customAccounts.domain != "" ? 2 : 0;
            supAccount.push(supportAccountsData);
            supAccount.push(customAccountData);
            ContactsRequest.prefix = FormData.prefix;
            ContactsRequest.supportAccounts = supAccount;
        }
        if (FormData.unifiedTypeId == 11 || FormData.unifiedTypeId == 14) {
            let supportAccountsData =  FormData.supportAccounts;
            supportAccountsData.type = FormData.supportAccounts.domain != "" ? 1 : 0;
            supAccount.push(supportAccountsData);
            ContactsRequest.supportAccounts = supAccount;
            ContactsRequest.apiToken = FormData.apiToken;
            ContactsRequest.apiSecret = FormData.apiSecret;
            ContactsRequest.mps = FormData.unifiedTypeId == 11 ? FormData.mps : null;
        }
        if (FormData.unifiedTypeId == 2 || FormData.unifiedTypeId == 13) {
            let supportAccountsData =  FormData.supportAccounts;
            supportAccountsData.type = FormData.supportAccounts.domain != "" ? 1 : 0;
            supAccount.push(supportAccountsData);
            ContactsRequest.supportAccounts = supAccount;
        }
        if(FormData.unifiedTypeId == 20) {
            ContactsRequest.apiClientId = FormData.apiClientId;
        }
        this._fuseSplashScreenService.show();
        this._pageService.saveAccount(ContactsRequest, this._data.agentId, this.companyId)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(this.accountForm);
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error.error, 'Error');
                }
            );
    }
    editAccount() {
        var FormData = this.accountForm.getRawValue();
        if (this.accountForm.invalid) {
            this._pageService.validateAllFormFields(this.accountForm);
            return false;
        }
        if (FormData.unifiedTypeId == 6 && !FormData.emailOrNumber) {
            this._toastr.warning('Bot Id is not found, Please use a valid API Token', 'Warning');
            return false;
        }
        let supportDomains: any = [];
        let marketingSupportDomain: any = [];
        let customSupportDomain: any = [];
        if (FormData.unifiedTypeId == 1 || FormData.unifiedTypeId == 21 || FormData.unifiedTypeId == 2 || FormData.unifiedTypeId == 13 || FormData.unifiedTypeId == 11 || FormData.unifiedTypeId == 14 || FormData.unifiedTypeId == 15 || FormData.unifiedTypeId == 17) {
            FormData.supportDomains.map(item => {
                if (item.domain) {
                    supportDomains.push(item.domain);
                }
            });
            FormData.supportAccounts.supportDomains.map(item => {
                if (item.marketingdomain) {
                    marketingSupportDomain.push(item.marketingdomain);
                }
            });
            FormData.supportAccounts.supportDomains = marketingSupportDomain;
            FormData.customAccounts.supportDomains.map(item => {
                if (item.customDomain) {
                    customSupportDomain.push(item.customDomain);
                }
            });
            FormData.customAccounts.supportDomains = customSupportDomain;
        }
        let ContactsRequest: any = {
            "id": this.account.id,
            "unifiedTypeId": FormData.unifiedTypeId,
            "emailOrNumber": (FormData.unifiedTypeId == 9 || FormData.unifiedTypeId == 19) ? 'notification' : FormData.emailOrNumber,
            "userName": FormData.username,
            "password": FormData.password,
            "signatureText": FormData.unifiedTypeId == 1 || FormData.unifiedTypeId == 21 ? FormData.signatureText : '',
            "supportDomains": supportDomains,
            "allowMMS": FormData.allowMMS,
            "allowInternationalSMS": FormData.allowInternationalSMS,
            "unifiedAccountAutoresponder": {
                "id": this.account.unifiedAccountAutoresponder && this.account.unifiedAccountAutoresponder.id > 0 && this.account.unifiedAccountAutoresponder.id !== null ? this.account.unifiedAccountAutoresponder.id : 0,
                "accountId": this.account.unifiedAccountAutoresponder && this.account.unifiedAccountAutoresponder.accountId > 0 && this.account.unifiedAccountAutoresponder.accountId !== null ? this.account.unifiedAccountAutoresponder.accountId : 0,
                "optOut": FormData.unifiedAccountAutoresponder.optOut,
                "optOutMessage": FormData.unifiedAccountAutoresponder.optOutMessage,
                "help": FormData.unifiedAccountAutoresponder.help,
                "helpMessage": FormData.unifiedAccountAutoresponder.helpMessage,
                "optIn": FormData.unifiedAccountAutoresponder.optIn,
                "optInMessage": FormData.unifiedAccountAutoresponder.optInMessage,
            },
        };
        let supAccount = [];
        if (FormData.unifiedTypeId == 1 || FormData.unifiedTypeId == 21) {
            let supportAccountsData =  FormData.supportAccounts;
            supportAccountsData.type = FormData.supportAccounts.domain != "" ? 1 : 0;
            let customAccountData = FormData.customAccounts;
            customAccountData.type = FormData.customAccounts.domain != "" ? 2 : 0;
            supAccount.push(supportAccountsData);
            supAccount.push(customAccountData);
            ContactsRequest.prefix = FormData.prefix;
            ContactsRequest.supportAccounts = supAccount;
            if(this.account && this.account.supportAccounts && this.account.supportAccounts.length>0) {
                this.account.supportAccounts.forEach((value, index) => {
                    ContactsRequest.supportAccounts[index].id = value.id;
                });
            }
        }
        if (FormData.unifiedTypeId == 11 || FormData.unifiedTypeId == 14) {
            let supportAccountsData =  FormData.supportAccounts;
            supportAccountsData.type = FormData.supportAccounts.domain != "" ? 1 : 0;
            supAccount.push(supportAccountsData);
            ContactsRequest.supportAccounts = supAccount;
            ContactsRequest.apiToken = FormData.apiToken;
            ContactsRequest.apiSecret = FormData.apiSecret;
            ContactsRequest.mps = FormData.unifiedTypeId == 11 ? FormData.mps : null;
        }
        if (FormData.unifiedTypeId == 2 || FormData.unifiedTypeId == 13) {
            let supportAccountsData =  FormData.supportAccounts;
            supportAccountsData.type = FormData.supportAccounts.domain != "" ? 1 : 0;
            supAccount.push(supportAccountsData);
            ContactsRequest.supportAccounts = supAccount;
        }
        if(FormData.unifiedTypeId == 20) {
            ContactsRequest.apiClientId = FormData.apiClientId;
        }
        this._fuseSplashScreenService.show();
        this._pageService.editAccount(ContactsRequest, this._data.agentId, this.companyId)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(this.accountForm);
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.error.error, 'Error');
                }
            );

    }
    changeAccountType(reset) {
        var FormData = this.accountForm.getRawValue();
        if (!FormData.unifiedTypeId) {
            this.usernameLabel = "Username";
            this.passwordLabel = "Password";
            this.emailOrNumberLabel = "Email/Phone Number";
            this.accountForm.get('emailOrNumber').enable();
        }
        if (FormData.unifiedTypeId == 1 || FormData.unifiedTypeId == 21 ) {
            this.usernameLabel = "Transactional Domain";
            this.passwordLabel = "API Key";
            this.emailOrNumberLabel = "Email";
            this.marketingLabel = "Marketing Domain";
            this.marketingSupportLabel = "Marketing Support Domain";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 2 || FormData.unifiedTypeId == 8  || FormData.unifiedTypeId == 13) {
            this.usernameLabel = "ACCOUNT SID";
            this.passwordLabel = "AUTH TOKEN";
            this.emailOrNumberLabel = "Message Service Id";
            this.supportDomainLabel = "Message Service Id";
            this.marketingLabel = "Marketing Message Service Id";
            this.marketingSupportLabel = "Marketing Message Service Id";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 3) {
            this.usernameLabel = "ACCOUNT SID";
            this.passwordLabel = "AUTH TOKEN";
            this.emailOrNumberLabel = "Phone Number";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 4) {
            this.usernameLabel = "ACCOUNT SID";
            this.passwordLabel = "AUTH TOKEN";
            this.emailOrNumberLabel = "Channel Id";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 5) {
            this.usernameLabel = "ACCOUNT SID";
            this.passwordLabel = "AUTH TOKEN";
            this.emailOrNumberLabel = "Page Id";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 6) {
            this.usernameLabel = "API Token";
            this.passwordLabel = "Password";
            this.emailOrNumberLabel = "Bot Id";
            this.accountForm.get('emailOrNumber').disable();
        }
        else if(FormData.unifiedTypeId == 11 || FormData.unifiedTypeId == 14) {
            this.usernameLabel = "Application Id";
            this.passwordLabel = "Account Id";
            this.emailOrNumberLabel = "Transactional Sender Number";
            this.supportDomainLabel = "Transactional Sender Number";
            this.marketingLabel = "Marketing Sender Number";
            this.marketingSupportLabel = "Marketing Sender Number";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if(FormData.unifiedTypeId == 15 || FormData.unifiedTypeId == 17) {
            this.usernameLabel = "Username";
            this.passwordLabel = "Password";
            this.emailOrNumberLabel = "Short Code";
            this.supportDomainLabel = "Short Code";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if(FormData.unifiedTypeId == 18 || FormData.unifiedTypeId == 20) {
            this.usernameLabel = "Username";
            this.passwordLabel = "Password";
            this.emailOrNumberLabel = "Backoffice URL";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if (FormData.unifiedTypeId == 19) {
            this.passwordLabel = "Token";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if(FormData.unifiedTypeId == 22) {
            this.usernameLabel = "ApiKey";
            this.emailOrNumberLabel = "Number / Shortcode";
            this.accountForm.get('emailOrNumber').enable();
        }
        else if(FormData.unifiedTypeId == 24) {
            this.usernameLabel = "ApiKey";
            this.emailOrNumberLabel = "Shortcode";
            this.accountForm.get('emailOrNumber').enable();
        }
        if (reset) {
            this.resetAccountForm();
        }
    }
    removeByAttr(arr, attr, value) {
        var i = arr.length;
        while (i--) {
            if (arr[i]
                && arr[i].hasOwnProperty(attr)
                && (arguments.length > 2 && arr[i][attr] === value)) {
                arr.splice(i, 1);
            }
        }
        return arr;
    }
    onUsernameChange() {
        if (this.accountForm.get('unifiedTypeId').value == 6) {
            this.accountForm.patchValue({
                emailOrNumber: ''
            });
            this._fuseSplashScreenService.show();
            this._accountsService.GetTelegramMeByToken(this.accountForm.get('username').value)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this.accountForm.patchValue({
                            emailOrNumber: res.data.id
                        });
                    } else {
                        this._toastr.error('Bot Id not found', 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error('Bot Id not found', 'Error');
                        //this._toastr.error(error.error.error, 'Error');
                    }
                );
        }
    }
    resetAccountForm() {
        this.accountForm.patchValue({
            userId: '',
            username: '',
            password: '',
            emailOrNumber: ''
        });
    }
    addDomains(domainValue) {
        this.supportDomainsArray.push(this.supportDomainsId);
        this.supportDomainsId = this.supportDomainsId + 1;
        this.getArray().push(this._formBuilder.group({
            domain: [domainValue ? domainValue : '']
        }));
    }
    addMarketingDomains(supportDomainValue) {
        this.marketingSupportDomainsArray.push(this.marketingSupportDomainsId);
        this.marketingSupportDomainsId = this.marketingSupportDomainsId + 1;
        this.getMarketingSupportDomainArray().push(this._formBuilder.group({
            marketingdomain: [supportDomainValue ? supportDomainValue : '']
        }));
    }
    addCustomDomains(supportCustomDomainValue) {
        this.customSupportDomainsArray.push(this.customSupportDomainsId);
        this.customSupportDomainsId = this.customSupportDomainsId + 1;
        this.getCustomSupportDomainArray().push(this._formBuilder.group({
            customDomain: [supportCustomDomainValue ? supportCustomDomainValue : '']
        }));
    }
    removeDomain(index) {
        this.supportDomainsArray.splice(index, 1);
        this.getArray().removeAt(index);
    }
    getArray() {
        return this.accountForm.get('supportDomains') as FormArray;
    }
    getMarketingSupportDomainArray() {
        return this.accountForm.get("supportAccounts.supportDomains") as FormArray;
    }
    removeMarketingSupportDomain(index) {
        this.marketingSupportDomainsArray.splice(index, 1);
        this.getMarketingSupportDomainArray().removeAt(index);
    }
    getCustomSupportDomainArray() {
        return this.accountForm.get("customAccounts.supportDomains") as FormArray;
    }
    removeCustomSupportDomain(index) {
        this.customSupportDomainsArray.splice(index, 1);
        this.getCustomSupportDomainArray().removeAt(index);
    }
    providerTypeChange(Event) {
        if(Event.value == 2) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "SMS") {
                    item.id = 2;
                }
            });
        }else if(Event.value == 11) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "SMS") {
                    item.id = 11;
                }
            });
        }else if(Event.value == 13) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "International SMS") {
                    item.id = 13;
                }
            });
        }else if(Event.value == 14) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "International SMS") {
                    item.id = 14;
                }
            });
        }else if(Event.value == 15) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "SMS") {
                    item.id = 15;
                }
            });
        }else if(Event.value == 17) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "International SMS") {
                    item.id = 17;
                }
            });
        }
        else if(Event.value == 21) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "Email") {
                    item.id = 21;
                }
            });
        }
        else if(Event.value == 1) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "Email") {
                    item.id = 1;
                }
            });
        }
        else if(Event.value == 22) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "SMS") {
                    item.id = 22;
                }
            });
        }
        else if(Event.value == 8) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "ShortCode") {
                    item.id = 8;
                }
            });
        }
        else if(Event.value == 24) {
            this.accountTypes.forEach((item, index) => {
                if(item.name == "ShortCode") {
                    item.id = 24;
                }
            });
        }
        this.chRef.detectChanges();
        this.accountForm.get("unifiedTypeId").setValue(Event.value);
        this.changeAccountType(true);
    }
    onClick() {
        if (this.password === 'password') {
          this.password = 'text';
          this.show = true;
        } else {
          this.password = 'password';
          this.show = false;
        }
    }
    keyPressNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
          event.preventDefault();
          return false;
        } else {
          return true;
        }
    }
    onPaste(Event) {
       let value = Event.value;
       if(isNaN(value)) {
        Event.preventDefault();
        return false; 
       }else {
        return true;
      }
    }
    checkMPSValidation(event) {
        if(!(event.target.value >=1 && event.target.value<=30)) {
            if(event.target.value != '') {
                this.accountForm.get('mps').markAsTouched();
                this.accountForm.get('mps').setErrors({'incorrect': true});
            }
        }
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("account-form-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("newaccount-content").style.maxHeight = "65vh";
        document.getElementById("newaccount-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("account-form-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("newaccount-content").style.maxHeight = "100vh";
        document.getElementById("newaccount-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}
