import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { ToastrService } from 'ngx-toastr';
import { AddContactsComponent } from '../add-contacts/add-contacts.component';

@Component({
  selector: 'app-message-queue-details',
  templateUrl: './message-queue-details.component.html',
  styleUrls: ['./message-queue-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class MessageQueueDetailsComponent implements OnInit {
  hostHeight: any;
  hostWidth: any;
  IsDialogMax: boolean = false;
  pageNumber: number = 1;
  pageSize: number = 100;
  TotalCount: number = 0;
  dialogRef:any;
  dataSource: MatTableDataSource<any>;
  dripCampaignId:any;
  selectedContacts:any[] = [];
  displayedColumns: string[] = ['checkbox', 'contactName', 'email', 'emailVerified', 'phoneNumber', 'verifiedNumber', 'status', 'actions'];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  searchInput: FormControl = new FormControl('');
  flagRestartDripCont: boolean = true;
  constructor(
    public matDialogRef: MatDialogRef<MessageQueueDetailsComponent>,
    private _campaignsService: CampaignsService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _toastr: ToastrService,
    private _matDialog: MatDialog,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.dripCampaignId = this._data.dripCampData.id;
    this.getMessageQueue();
    this.getMessageQueueCount();
  }

  getMessageQueue(){
    let request:any = {
      "campaignId" : this._data.dripCampData.id,
      "searchString": this.searchInput.value !='' ? this.searchInput.value : "",
      "pageNumber" : this.pageNumber,
      "pageSize" : this.pageSize
    }
      this._fuseSplashScreenService.show();
      this._campaignsService.getDripContacts(request).subscribe((res:any)=>{
        if(res.status==200){
          this._fuseSplashScreenService.hide();
          this.dataSource = new MatTableDataSource(res.data);
        }else{
          this._toastr.error(res.message, 'Error');
        }
      },
      error => {
        this._fuseSplashScreenService.hide();
        this._toastr.error(error.message, 'Error');
      });
  }
  getMessageQueueCount(){
    let request:any = {
      "campaignId" : this._data.dripCampData.id,
      "searchString": "",
      "pageNumber" : this.pageNumber,
      "pageSize" : this.pageSize
    }
    this._campaignsService.getDripContactsCount(request).subscribe((res:any)=>{
      if(res.status==200){
        this.TotalCount = res.data
      }else{
        this._toastr.error(res.message, 'Error');
      }
    },
    error => {
      this._fuseSplashScreenService.hide();
      this._toastr.error(error.message, 'Error');
    });
  }
  deleteCampaign(contactId){
    let request:any = [contactId];
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
  this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure to remove this user from Queue?';
  this.confirmDialogRef.afterClosed().subscribe(result => {
    if(result){
        this._fuseSplashScreenService.show();
        this._campaignsService.removeContactsFromDrip(request,this.dripCampaignId).subscribe((res:any)=>{
        if(res.status==200){
        this._fuseSplashScreenService.hide();
        this._toastr.success("Removed successfully", 'Success');
        this.getMessageQueue();
        this.getMessageQueueCount();
        }else{
        this._toastr.error(res.message, 'Error');
        }
      },
        error => {
        this._fuseSplashScreenService.hide();
        this._toastr.error(error.message, 'Error');
      });
    }
    this.confirmDialogRef = null;
    });
  }

 onPageChange(event): void {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getMessageQueue();
    this.getMessageQueueCount();
  }

  addContact(){
    this.dialogRef = this._matDialog.open(AddContactsComponent, {
      panelClass: 'add-contacts-details',
      data: {
        campaignId:this.dripCampaignId,
      }
  });
  this.dialogRef.afterClosed().subscribe((response: any) => {
    if(response) {
      this.getMessageQueue();
      this.getMessageQueueCount();
    }
      
    });
  }
 
  onContactSelectionToggle(contactid) {
    if(this.selectedContacts.includes(contactid)) {
      let index = this.selectedContacts.indexOf(contactid);
      this.selectedContacts.splice(index, 1);
    }
    else {
      this.selectedContacts.push(contactid);
    }
  }

  restartDripforContacts() {
    if(this.flagRestartDripCont) {
      if (this.selectedContacts.length > 0) {
        this.flagRestartDripCont = false;
        this._campaignsService.addDripForContact(this.selectedContacts, this.dripCampaignId).subscribe((res: any) => {
          if (res.status == 200) {
            this._fuseSplashScreenService.hide();
            this._toastr.success("Process started", 'Success');
            this.selectedContacts = [];
            this.getMessageQueue();
            this.getMessageQueueCount();
            this.flagRestartDripCont = true;
          } else {
            this.flagRestartDripCont = true;
            this._toastr.error(res.message, 'Error');
          }
        },
          error => {
            this.flagRestartDripCont = true;
            this._fuseSplashScreenService.hide();
            this._toastr.error(error.message, 'Error');
          });
      } else {
        this._toastr.warning("Please select any Contact to Restart", 'Warning');
      }
    }
  }
  goToContactDetails(contactId): void {
    this._router.navigate(['/pages/contactdetails/' + contactId]);
    this.matDialogRef.close();
}
  search() {
    this.getMessageQueue();
  }
  onRefreshList() {
    this.searchInput.setValue('');
    this.getMessageQueue();
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("sent-message-details");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("sendmsgdetail-content").style.maxHeight = "65vh";
    document.getElementById("sendmsgdetail-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("sent-message-details");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("sendmsgdetail-content").style.maxHeight = "100vh";
    document.getElementById("sendmsgdetail-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
