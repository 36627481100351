import { FormControl } from '@angular/forms';
import { AddGroupComponent } from './../../../../main/pages/contacts/dialogs/add-group/add-group.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import { MailComposeDialogComponent } from 'app/main/pages/mail/dialogs/compose/compose.component';
import { PageService } from 'app/main/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import * as moment from "moment-timezone";
import { startOfMonth, subMonths } from 'date-fns';
@Component({
  selector: 'click-map-link-details',
  templateUrl: './click-map-link-details.component.html',
  styleUrls: ['./click-map-link-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ClickMapLinkDetailsComponent implements OnInit {
  hostHeight: any;
  hostWidth: any;
  IsDialogMax: boolean = false;
  pageNumber: number = 1;
  pageSize: number = 100;
  TotalCount: number = 0;
  dataSource: MatTableDataSource<any>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  @ViewChild(FusePerfectScrollbarDirective, { static: false })
  directiveScroll: FusePerfectScrollbarDirective;
  dialogRef: any;
  selectedCont: any[] = [];
  checkboxes: {};
  List: any[] =[];
  url: any = '';
  searchText: FormControl = new FormControl('')
  constructor(
    public matDialogRef: MatDialogRef<ClickMapLinkDetailsComponent>,
    private _campaignsService: CampaignsService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _toastr: ToastrService,
    private _pageService: PageService,
    public _contactsService: ContactsService,
    public _matDialog: MatDialog,
    public _router: Router,
    private _projectDashboardService: ProjectDashboardService,
  ) { 
    this._contactsService.appledFilters = new BehaviorSubject<any>(null);
    }
  displayedColumns: string[] = !this._data.isClickMapPage ? ['checkbox', 'firstName', 'lastName', 'email', 'clicks'] :(((this._data.type == 'pageViews' || this._data.type == 'abandonedCart' || this._data.type == 'revenue') && this._data.isClickMapPage) ? ['firstName', 'lastName', this._data.type] : ((this._data.type == 'totalClicks' && this._data.isClickMapPage) ? ['checkbox', 'firstName', 'lastName', 'email', 'clicks'] : ['checkbox', 'firstName', 'lastName', 'email'] ));
  ngOnInit() {
    this. checkboxes = {};
    if(this._data.isClickMapPage) {
      if(this._data.type == 'totalClicks') {
        this.getLinkDetails();
      }else if(this._data.type == 'pageViews' || this._data.type == 'abandonedCart' || this._data.type == 'revenue') {
        this.getTrackingDataForCampaign();
      }
      else {
        this.getCustomLinkDetails();
        this.getCustomLinkDetailsCount();
      }
    }else {
      this.getLinkDetails();
    }
  }

  getLinkDetails() {
    if(!this._data.isClickMapPage) {
       this.url = this._data.htmlLink.replace(/\[.*\]/, ',');
    }
    if(this.url == ',') {
      this.url = this._data.htmlLink == '[ValidateLink]' ? '[OptInLink]' : this._data.htmlLink;
    }
    let linkDetailsReq: any = {
      "searchText": this.searchText.value,
      "campaignId": this._data.CampaignId,
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "url": this.url
    }
    this._fuseSplashScreenService.show();
    this._campaignsService.GetURLClickMapDetails(linkDetailsReq, false).subscribe((res: any) => {
      if (res.status == 200) {
        this._fuseSplashScreenService.hide();
        let temp_list = [];
        res.data.report.forEach((obj: any) => {
            const found = _.find(this.List, function (t) { return t.id == obj.id });
            if (found == undefined || found == null) {
                temp_list.push(obj);
            }
        });
        Array.prototype.push.apply(this.List, temp_list);
        var URLClickMapDetails = this.List;
        URLClickMapDetails.map(contact => {
          this.checkboxes[contact.contactId] = false;
        });
        this.dataSource = new MatTableDataSource(URLClickMapDetails);
        this.TotalCount = res.data.totalCount;
      } else {
        this._toastr.error(res.message, 'Error');
        this.TotalCount = 0;
      }
    },
      error => {
        this._fuseSplashScreenService.hide();
        this.TotalCount = 0;
        this._toastr.error(error.message, 'Error');
      });
  }
  viewDetails(contactId): void {
    this._pageService.contactDetailsRedirection = {
        selectedPage: this._contactsService.filterByGroup ? 'pages/groups' : 'pages/allcontacts',
        selectedPageNumber: this._contactsService.contactsPageNumber,
        selectedSearchText: this._contactsService.searchText,
        selectedSearchInteger: this._contactsService.searchInteger,
        selectedGroupId: this._contactsService.filterByGroup,
        statusFilter: this._contactsService.statusFilter
    };
    this._router.navigate([]).then(result => {  window.open('pages/contactdetails/' + contactId, '_blank'); });
  }
  composeDialog(): void {
    this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
        panelClass: 'mail-compose-dialog',
        disableClose: true,
        data: {
          selectContactIdClickMap: this.selectedCont
        }
    });
    this.dialogRef.afterClosed()
        .subscribe(response => {
            if (!response) {
                return;
            }else {
              this.selectedCont = [];
              for (const id in this.checkboxes) {
                if (!this.checkboxes.hasOwnProperty(id)) {
                    continue;
                }
                this.checkboxes[id] = this.selectedCont.includes(parseInt(id));
              }
            }
        });
  }
  addGroup(addToGroup): void {
    this.dialogRef = this._matDialog.open(AddGroupComponent, {
        panelClass: 'add-group-dialog',
        disableClose: true,
        data: {
            action: 'new',
            selectedCont: this.selectedCont,
            pageName: 'clickMapLinkDetails',
            url: this.url,
            searchText: this.searchText.value,
            campaignId: this._data.CampaignId,
            totalCount: this.TotalCount,
            addToGroup: addToGroup,
            isClickMapPage: this._data.isClickMapPage,
            type: this._data.type,
            unifiedTypes: "",
            dripId: 0,
            messageId: 0,
            timeZoneId: "",
            week: 0,
        }
    });

    this.dialogRef.afterClosed()
        .subscribe((response: any) => {
            if (!response) {
                return;
            }else {
              this.selectedCont = [];
              for (const id in this.checkboxes) {
                if (!this.checkboxes.hasOwnProperty(id)) {
                    continue;
                }
                this.checkboxes[id] = this.selectedCont.includes(parseInt(id));
              }
            }
        });
  }
  toggleSelectedCont(id): void {
    if (this.selectedCont.length > 0) {
        const index = this.selectedCont.indexOf(id);

        if (index !== -1) {
            this.selectedCont.splice(index, 1);
            this.checkboxes[id] = this.selectedCont.includes(parseInt(id));
            return;
        }
    }
    this.selectedCont.push(id);
    this.checkboxes[id] = this.selectedCont.includes(parseInt(id));
  }
  exportContacts(exportContant) {
    if (!this._data.isClickMapPage) {
      var urldata = this._data.htmlLink.replace(/\[.*\]/, ',');
    }
    if (urldata == ',') {
      urldata = this._data.htmlLink == '[ValidateLink]' ? '[OptInLink]' : this._data.htmlLink;
    }
    let linkDetailsReq: any = {
      "campaignId": this._data.CampaignId,
      "pageNumber": 1,
      "pageSize": this._data.totalContacts,
      "url": urldata
    }
    this._fuseSplashScreenService.show();
    this._campaignsService.GetURLClickMapDetails(linkDetailsReq, exportContant).subscribe((res: any) => {
      this._fuseSplashScreenService.hide();
      var blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
      this._pageService.saveFile(blob, "ClickMap.csv");
    },
      error => {
        this._fuseSplashScreenService.hide();
        this._toastr.error(error.message, 'Error');
      }
    );
  }
  getCustomLinkDetails() {
    let status = this._data.type == 'totalDeliveries' ? 1 : (this._data.type == 'uniqueOpens' ? 7 : 0);
    let CustomLinkDetailsReq = {
      "campaignId": this._data.CampaignId,
      "status": status,
      "messageId": 0,
      "pageNumber":  this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.searchText.value,
      "timeZoneId": "",
      "week": 0,
      "from": moment.utc(subMonths(new Date(), 1)).local().format("YYYY-MM-DD"),
      "to": moment.utc(new Date()).local().format("YYYY-MM-DD"),
      "unifiedTypes": "",
      "dripId": 0
    }
    this._fuseSplashScreenService.show();
    this._projectDashboardService.getSentDetails(CustomLinkDetailsReq, false).subscribe((res: any) => {
        if (res.status == 200) {
          this._fuseSplashScreenService.hide();
          let temp_list = [];
          res.data.forEach((obj: any) => {
              const found = _.find(this.List, function (t) { return t.id == obj.id });
              if (found == undefined || found == null) {
                  temp_list.push(obj);
              }
          });
          Array.prototype.push.apply(this.List, temp_list);
          var URLClickMapDetails = this.List;
          URLClickMapDetails.map(contact => {
            this.checkboxes[contact.contactId] = false;
          });
          this.dataSource = new MatTableDataSource(URLClickMapDetails);
        } else {
          this._toastr.error(res.message, 'Error');
        }
      },
        error => {
          this._fuseSplashScreenService.hide();
          this.List = [];
          this.dataSource = new MatTableDataSource(this.List);
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  getCustomLinkDetailsCount() {
    let status = this._data.type == 'totalDeliveries' ? 1 : (this._data.type == 'uniqueOpens' ? 7 : 0);
    let CustomLinkDetailsCountReq = {
      "campaignId": this._data.CampaignId,
      "status": status,
      "messageId": 0,
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.searchText.value,
      "timeZoneId": "",
      "week": 0,
      "from": moment.utc(subMonths(new Date(), 1)).local().format("YYYY-MM-DD"),
      "to": moment.utc(new Date()).local().format("YYYY-MM-DD"),
      "unifiedTypes": "",
      "dripId": 0
    }
    this._projectDashboardService.getSentDetailsCount(CustomLinkDetailsCountReq)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.TotalCount = res.data.totalCount;
        }
        else {
          this.TotalCount = 0;
        }
      },
        error => {
          this.TotalCount = 0;
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  getTrackingDataForCampaign() {
    let linkDetailsReq: any = {
      "campaignId": this._data.CampaignId,
      "messageId": 0,
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.searchText.value,
      "isDrip": 0,
      "withContact": true,
      "uRL": this._data.htmlLink
    }
    this._fuseSplashScreenService.show();
    this._campaignsService.GetTrackingDataForCampaign(linkDetailsReq, false).subscribe((res: any) => {
      if (res.status == 200) {
        this._fuseSplashScreenService.hide();
        let temp_list = [];
        res.data.report.forEach((obj: any) => {
            const found = _.find(this.List, function (t) { return t.id == obj.id });
            if (found == undefined || found == null) {
                temp_list.push(obj);
            }
        });
        Array.prototype.push.apply(this.List, temp_list);
        this.dataSource = new MatTableDataSource(this.List);
        this.TotalCount = res.data.totalCount;
      } else {
        this._toastr.error(res.message, 'Error');
        this.TotalCount = 0;
      }
    },
      error => {
        this._fuseSplashScreenService.hide();
        this.List = [];
        this.dataSource = new MatTableDataSource(this.List);
        this.TotalCount = 0;
        this._toastr.error(error.message, 'Error');
      });
  }
  search() {
    this.List = [];
    this.pageNumber = 1;
    this.directiveScroll.scrollToTop();
    if(this._data.isClickMapPage) {
      if(this._data.type == 'totalClicks') {
        this.getLinkDetails();
      }else {
        this.getCustomLinkDetails();
        this.getCustomLinkDetailsCount();
      }
    }else {
      this.getLinkDetails();
    }
  }
  onRefreshList() {
    this.List = [];
    this.searchText.setValue(null);
    this.pageNumber = 1;
    this.directiveScroll.scrollToTop();
    if(this._data.isClickMapPage) {
      if(this._data.type == 'totalClicks') {
        this.getLinkDetails();
      }else {
        this.getCustomLinkDetails();
        this.getCustomLinkDetailsCount();
      }
    }else {
      this.getLinkDetails();
    }
  }
  onScrollDown(ev) {
    if(this.dataSource.filteredData.length< this.TotalCount) {
      this.pageNumber += 1;
      if(this._data.isClickMapPage) {
        if(this._data.type == 'totalClicks') {
          this.getLinkDetails();
        }else {
          this.getCustomLinkDetails();
        }
      }else {
        this.getLinkDetails();
      }
    }
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("clickMap-link-details");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("clickMap-link-details-content").style.maxHeight = "65vh";
    document.getElementById("clickMap-link-details-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("clickMap-link-details");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("clickMap-link-details-content").style.maxHeight = "100vh";
    document.getElementById("clickMap-link-details-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
