import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getCountryByISD',
    pure: false
})
export class GetCountryByISDPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} value
     * @param {number} id
     * @param {string} property
     * @returns {any}
     */
    transform(value: any[], isdCode: string, property: string): any
    {
        const foundItem = value.find(item => {
            if ( item.isdCode !== undefined )
            {
                return item.isdCode === isdCode;
            }

            return false;
        });

        if ( foundItem )
        {
            return foundItem[property];
        }
    }
}
