import { Injectable } from '@angular/core';
import { BASE_URL, API_URL, EXIGO_URL } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestService {


  constructor(private _http: HttpClient, private route: ActivatedRoute) { }

  Delete(resultUrl: string, requestParameterData: any) {

    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {}
    if (requestParameterData) {
      httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json-patch+json; charset=UTF-8',
        }),
        body: requestParameterData
      };
    }
    else {
      httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json-patch+json; charset=UTF-8',
        })
      };
    }
    return this._http.delete<HttpResponse<Object>>(url, httpOptions).pipe();

  }


  Put(resultUrl: string, requestParameterData: any) {

    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };

    return this._http.put<HttpResponse<Object>>(url, requestParameterData, httpOptions).pipe();
  }


  Get(resultUrl: string) {
    var url = API_URL + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      })
    };
    return this._http.get<HttpResponse<Object>>(url, httpOptions).pipe(tap());
  }

  Getblob(resultUrl: string) {
    const apiUrl = API_URL;
    const url = apiUrl + resultUrl;
    return this._http.get<HttpResponse<Blob>>(url, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }).pipe(tap());
  }

  Post(resultUrl: string, requestParameterData: any, timeout?: any) {

    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
    };

    return this._http.post<HttpResponse<Object>>(url, requestParameterData, httpOptions).pipe(tap());
  }

  Postblob(resultUrl: string, requestParameterData: any, timeout?: any) {
    const apiUrl = API_URL;
    const url = apiUrl + resultUrl;
    return this._http.post<HttpResponse<Blob>>(url, requestParameterData, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }).pipe(tap());
  }


  PostFile(resultUrl: string, requestParameterData: any, timeout?: any) {
    const apiUrl = API_URL;
    var url = apiUrl + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
        'Accept': 'application/json'
      })
    };

    return this._http.post<HttpResponse<Object>>(url, requestParameterData, httpOptions).pipe(tap());
  }

  Token(request) {
    var url = BASE_URL + '/connect/token';
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      })
    };

    return this._http.post<HttpResponse<Object>>(url, request, httpOptions).pipe(tap());
  }

  GetUserProfile() {
    var url = "/Account/users/me";
    return this.Get(url);
  }

  PostExigo(resultUrl: string, requestParameterData: any, timeout?: any) {
    const EXIGO_URL_FULL = EXIGO_URL;
    var url = EXIGO_URL_FULL + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
    };

    return this._http.post<HttpResponse<Object>>(url, requestParameterData, httpOptions).pipe(tap());
  }

  GetExigo(resultUrl: string) {
    const EXIGO_URL_FULL = EXIGO_URL;
    var url = EXIGO_URL_FULL + resultUrl;
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      })
    };

    return this._http.post<HttpResponse<Object>>(url, httpOptions).pipe(tap());
  }


}
