import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';

@Component({
    selector: 'help-tiny-url',
    templateUrl: './help-tiny-url.component.html',
    styleUrls: ['./help-tiny-url.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HelpTinyUrlDialogComponent {
    private _unsubscribeAll: Subject<any>;
    previewMessage: string = "Welcome to Text World. For more information click http://tinyu.us/t/XXXX. Welcome to Text World. For more information click http://tinyu.us/t/AB03D.";

    constructor(
        public matDialogRef: MatDialogRef<HelpTinyUrlDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any

    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    
}

