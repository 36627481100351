import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ProvidersService } from 'app/main/pages/providers/providers.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'view-browser-list',
    templateUrl: './view-browser-list.component.html',
    styleUrls: ['./view-browser-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ViewBrowserListComponent implements OnInit {
    viewBrowserUrl:FormControl = new FormControl('');
    filterViewBrowser:FormControl = new FormControl('');
    List: any[] = [];
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    dialogTitle: string = "Browser Link";
    dialogRef: any;
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    constructor(
        public matDialogRef: MatDialogRef<ViewBrowserListComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        public _pageService: PageService,
        private _providersService: ProvidersService,
    ) {
        this.dialogTitle = this._data.dialogTitle;
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
        this.getLinkedMessageList();
    }
    getLinkedMessageList() {
        this._fuseSplashScreenService.show();
        this._providersService.GetLinkedMessageList().subscribe((res: any) => {
            this._fuseSplashScreenService.hide();
            if (res.status == 200) {
              this.List = res.data;    
            }
          },
          error => {
              this._fuseSplashScreenService.hide();
              console.log(error);
              if (error.error) {
                this._toastr.error(error.error.error, 'Error');
              }
              else {
                this._toastr.error(error.message, 'Error');
              }
            }
          );
        }
    addViewUrl() {
      this._pageService.OnSelectViewBrowserUrlChange.next(this.viewBrowserUrl.value);
      this.matDialogRef.close();
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("view-browser-list-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("view-browser-list-content").style.maxHeight = "65vh";
        document.getElementById("view-browser-list-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("view-browser-list-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("view-browser-list-content").style.maxHeight = "100vh";
        document.getElementById("view-browser-list-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

