import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';

@Component({
    selector: 'help-merge-field',
    templateUrl: './help-merge-field.component.html',
    styleUrls: ['./help-merge-field.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HelpMergeFieldDialogComponent {
    private _unsubscribeAll: Subject<any>;
    previewMessage: string = "Welcome [FirstName] [LastName] to Text World. [Company] would like to invite you to a meeting on Tue Nov 27th at 8:00 PM. If you can attend please reply 'Y'";

    constructor(
        public matDialogRef: MatDialogRef<HelpMergeFieldDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any

    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    
}

