import { Component, ViewEncapsulation, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { PageService } from 'app/main/pages/pages.service';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { AuthService } from 'app/shared/auth.service';
import * as moment from "moment-timezone";
import { ViewMessageDialogComponent } from '../view-message/view-message.component';
import { startOfMonth, lastDayOfMonth, subDays } from 'date-fns';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ds-received-messages',
  templateUrl: './ds-received-messages.component.html',
  styleUrls: ['./ds-received-messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class DSReceivedMessagesDialogComponent implements OnInit {
  userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
  dialogRef: any;
  List: any = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['CreatedDateTime', 'SenderName', 'SenderEmail', 'SenderMobile', 'RecipientName', 'RecipientEmail', 'RecipientMobile', 'MessageText'];
  TotalCount: any;
  pageNumber = 1;
  pageSize = 10;
  filterForm: FormGroup;
  private _unsubscribeAll: Subject<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  sortBy: string = "";
  sortDirection: string = "";
  constructor(
    public _matDialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _formBuilder: FormBuilder,
    private _pageService: PageService,
    private _projectDashboardService: ProjectDashboardService,
    private _authService: AuthService,
    private _router: Router,
    public matDialogRef: MatDialogRef<DSReceivedMessagesDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this._unsubscribeAll = new Subject();
    

    if (this._data.type == 'month') {
      this.filterForm = this._formBuilder.group({
        searchText: [''],
        searchByFromDate: [subDays(new Date(), 30)],
        searchByToDate: [new Date()],
        accounttypes: [[]]
      });
    }
    else {
      this.filterForm = this._formBuilder.group({
        searchText: [''],
        searchByFromDate: [''],
        searchByToDate: [''],
        accounttypes: [[]]
      });
    }
  }


  ngOnInit() {
    this.getDSRecentReplies();
  }
  onSearch(event) {
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.getDSRecentReplies();
  }

  getDSRecentReplies() {
    const GetDSMessagelogsrequest = {
      "companyName": this.userDetails.companyKey,
      "startDate": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).local().format("MM/DD/YYYY") : '',
      "endDate": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).local().format("MM/DD/YYYY") : '',
      "pageNo": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.filterForm.get('searchText').value,
      "sortColumn": this.sortBy,
      "sortColumnDir": this.sortDirection
    };
    this._fuseSplashScreenService.show();
    this._projectDashboardService.getDSRecentReplies(GetDSMessagelogsrequest, false)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          this.List = res.data.data;
          this.TotalCount = res.data.totalCount;
          this.dataSource = new MatTableDataSource(this.List);
        }
        else {
          this.List = [];
          this.TotalCount = 0;
          this.dataSource = new MatTableDataSource(this.List);
        }
      },
        error => {
          this.List = [];
          this.TotalCount = 0;
          this.dataSource = new MatTableDataSource(this.List);
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  onPageChange(event): void {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getDSRecentReplies();
  }
  sortData(event) {
    //console.log(event);
    this.sortBy = event.active;
    this.sortDirection = event.direction;
    this.getDSRecentReplies();
  }
  onRefreshList(event) {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.filterForm.patchValue({
      searchText: '',
      searchByFromDate: '',
      searchByToDate: '',
      accounttypes: []
    });
    this.getDSRecentReplies();
  }
  viewMessage(message): void {
    this.dialogRef = this._matDialog.open(ViewMessageDialogComponent, {
      panelClass: 'view-message',
      data: {
        message: message
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  downloadCSV() {
    const GetDSMessagelogsrequest = {
      "companyName": this.userDetails.companyKey,
      "startDate": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).local().format("MM/DD/YYYY") : '',
      "endDate": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).local().format("MM/DD/YYYY") : '',
      "searchText": this.filterForm.get('searchText').value,
      "sortColumn": this.sortBy,
      "sortColumnDir": this.sortDirection
    };
    this._fuseSplashScreenService.show();
    this._projectDashboardService.getDSRecentReplies(GetDSMessagelogsrequest, true)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
          var blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
          this._projectDashboardService.saveFile(blob, "ReceivedList.csv");
      },
        error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  goToContactDetails(id: number, email: string, mobile: string) {
    this._pageService.getContactIdByAssociateId(id, email, mobile)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200 && res.data) {
          this.matDialogRef.close();
          this._router.navigate(['/pages/contactdetails/' + res.data]);
        }
        else {
          this._toastr.error('Contact Not Found', 'Error');
        }
      },
        error => {
          console.log(error);
          this._fuseSplashScreenService.hide();
          if (error && error.error && error.error.error) {
            this._toastr.error(error.error.error, 'Error');
          }
          else {
            this._toastr.error(error.message, 'Error');
          }
        }
      );
  }
}

