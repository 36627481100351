import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-invoice-page',
  templateUrl: './invoice-page.component.html',
  styleUrls: ['./invoice-page.component.scss']
})
export class InvoicePageComponent implements OnInit {
  orderNumber: any;
  Warning: any = {};
  GetOrderDetails: any = [];
  IsDialogMax: boolean = false;
  hostHeight: any;
  hostWidth: any;
  OrderData: any = {};

  constructor(
    private titleService: Title,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<InvoicePageComponent>,
  ) {
    let data1 = JSON.parse(JSON.stringify(this.data.invoiceDetail))
    data1 = JSON.parse(data1)
    console.log(data1)
    this.orderNumber = data;
    this.OrderData = {
      "DistributorID": data1[0].customerId,
      "SponsorID": data1[0].sponsorId,
      "OrderDate": this.data.orderDate,
      "OrderTypeDescription": data1[0].sponsorId,
      "OrderNumber": data1[0].orderId,
      "SpecialInstructions": "",
      "Status": "Paid",
      "TotalBonus": 0,
      "TotalCost": 0,
      "TotalCV": 75,
      "TotalQV": 75,
      "Void": false,
      "BillAddress": {
          "ID": 1,
          "Line1": "758 Automall Dr #8",
          "Line2": null,
          "Line3": "",
          "City": "American Fork",
          "State": "UT",
          "Zip": "84003",
          "CountryCode": "us"
      },
      "IsPaid": true,
      "IsShipped": false,
      "LineItems": data1[0].orderDetail,
      "Packages": [],
      "Totals": [
          {
              "TaxableSubTotal": data1[0].orderDetail.totalAmount - data1[0].orderDetail.subTotal || 0,
              "CurrencyCode": "usd",
              "ExchangeRate": 0,
              "OrderNumber": 1,
              "Shipping": 0,
              "SubTotal": data1[0].orderDetail.subTotal,
              "GrandTotal": data1[0].orderDetail.totalAmount
          }
      ],
      "Payments": [
          {
              "SavePaymentMethodId": null,
              "OnFileCard": null,
              "CurrencyCode": "USD",
              "MerchantId": 0,
              "SavePayment": false,
              "OrderRewardPoints": null,
              "OrderCreditCard": null,
              "AuthorizationNumber": "test",
              "TransactionNumber": "test",
              "ExchangeRate": 1,
              "Merchant": 13,
              "OrderNumber": 1,
              "PayDate": "2022-11-01T13:16:03.07",
              "PaymentResponse": "0: Success",
              "PayType": "Charge",
              "Reference": "test",
              "Status": "Paid"
          }
      ],
      "OrderCustom": {
          "AutoShipId": 0,
          "Field1": null,
          "Field2": null,
          "Field3": null,
          "Field4": null,
          "Field5": null
      }
    }
    
  }

  ngOnInit(): void {
    this.translate.get('global_Company_Title').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('pagetitle_order_history') + ' | ' + text);
    });
  }
  getOrderDetail() {
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("invoice-page");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("invoice-content").style.maxHeight = "65vh";
    document.getElementById("invoice-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("invoice-page");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("invoice-content").style.maxHeight = "100vh";
    document.getElementById("invoice-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
