import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import {  takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MediaService } from 'app/main/pages/manage/media.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'add-media',
    templateUrl: './add-media.component.html',
    styleUrls: ['./add-media.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AddMediaComponent implements OnInit, AfterViewInit{
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    formData: any;
    dialogTitle: string = "Add Media";
    dialogRef: any;
    mediaList: any[] = [];
    mediaURL: FormControl = new FormControl();
    mediaFilterCtrl: FormControl = new FormControl();
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    constructor(
        public matDialogRef: MatDialogRef<AddMediaComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        private _pageService: PageService,
        private _fileManagerService: MediaService
    ) {
        this.dialogTitle = this._data.dialogTitle;
        this._unsubscribeAll = new Subject();
        this.formData = this._data.formData;
    }
    ngOnInit(): void {
      this._pageService.onMediaFilesChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(list => {
        this.mediaList = list;
      });
    }
    ngAfterViewInit(): void {
     
    }
    onSlectMediaChange() {
      this._pageService.SlectMediaChange.next(true);
    }
    addMedia() {
      if(this.mediaURL && this.mediaURL.value) {
        this.matDialogRef.close(this.mediaURL.value);
      }
    }
    uploadMedia(ev) {
      var file = ev.target.files[0];
      var formData = new FormData();
      formData.append('formFile', file);
      this._fuseSplashScreenService.show();
      this._fileManagerService.uploadMedia(formData).subscribe(
        (res: any) => {
          this._fuseSplashScreenService.hide();
          if (res.status == 200) {
            this._toastr.success('File uploaded successfully', 'Success');
            this._fileManagerService.mediaPageNumber = 1;
            this._pageService.getMediaFiles('');
          }
          else {
            this._toastr.error(res.error, 'Error');
          }
        },
        (error: any) => {
          this._fuseSplashScreenService.hide();
          if (error && error.error && error.error.error) {
            this._toastr.error(error.error.error, 'Error');
          }
          else {
            this._toastr.error(error.error, 'Error');
          }
        }
      );
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("add-media-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("add-media-content").style.maxHeight = "65vh";
        document.getElementById("add-media-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("add-media-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("add-media-content").style.maxHeight = "100vh";
        document.getElementById("add-media-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

