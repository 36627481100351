import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AuthService } from 'app/shared/auth.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import * as _ from 'lodash';
import { ErrorDescriptionDialogComponent } from '../error-description/error-description.component';
import { TrackingService } from 'app/main/pages/tracking/tracking.service';
import { ViewMessageDialogComponent } from '../view-message/view-message.component';
import { InvoicePageComponent } from '../inovice-page/invoice-page.component';
import * as moment from 'moment';
import { startOfDay, startOfMonth } from 'date-fns';
@Component({
  selector: 'app-roi-summary-details',
  templateUrl: './roi-summary-details.component.html',
  styleUrls: ['./roi-summary-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class RoiSummaryDetailsComponent implements OnInit {
  IsDialogMax: boolean = false;
  private _unsubscribeAll: Subject<any>;
  weekType: any = 0;
  filterForm: FormGroup;
  List: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  TotalCount: any = 0;
  pageNumber = 1;
  pageSize = 30;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(FusePerfectScrollbarDirective, { static: false })
  directiveScroll: FusePerfectScrollbarDirective;
  showCountLoader: boolean;
  hostHeight: any;
  hostWidth: any;
  DialogRef: any;
  userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
  offset = new Date().getTimezoneOffset();
  filterOffset = (this.offset >= 720) ? 719 : (this.offset < -720) ? -600 : this.offset;
  repoType: any = null;
  pageTitle = "Details";
  companyTimeZone : any = '';
  selectedRow: any;
  dialogRefView: MatDialogRef<ViewMessageDialogComponent,any>;
  constructor(
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _projectDashboardService: ProjectDashboardService,
    private _authService: AuthService,
    public matDialogRef: MatDialogRef<RoiSummaryDetailsComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialog,
    private _matDialog: MatDialog,
    public trackingService:TrackingService
  ) {
    try {
      this.companyTimeZone = this.userDetails.timeZone.match(new RegExp("[A-Z](?!.*[\(])","g")).join('');
    } catch (error) {
      
    }
    this._unsubscribeAll = new Subject();
    this.filterForm = this._formBuilder.group({
      searchByFromDate: [(_data && _data.from) ? _data.from : startOfMonth(new Date())],
      searchByToDate: [(_data && _data.to) ? _data.to : startOfDay(new Date())]
    });
  }
  displayedColumns =  [];
  ngOnInit(): void {
    this.dataSource.filterPredicate = function (record,filter) {
      return record.sessionId.toLocaleLowerCase() == filter.toLocaleLowerCase();
    }
    if (this._data.type == 'pageView') {
      this.pageTitle = 'Page View Details'
      this.getPageViewDetails();
    }else if(this._data.type == 'abandonedCart'){
      this.pageTitle = 'Abandon Cart Details'
      this.getAbandonCartDetails();
    }
    else if(this._data.type === 'revenue' || this._data.type === 'orders'){
      this.pageTitle = 'Revenue'
      this.getRoiDetails()
    }
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
 
  search(event) {
    if (this._data.type == 'pageView') {
      this.getPageViewDetails();
    }else if(this._data.type == 'abandonedCart'){
      this.getAbandonCartDetails();
    }
    else if(this._data.type == 'revenue'){
      this.getRoiDetails();
    }
  }
  
  onRefreshList(event) {
    this.List = [];
    this.directiveScroll.scrollToTop();
    this.filterForm.patchValue({
      searchByFromDate: (this._data && this._data.from) ? this._data.from : startOfMonth(new Date()),
      searchByToDate: (this._data && this._data.to) ? this._data.to : startOfDay(new Date()),
    });
    if (this._data.type == 'pageView') {
      this.getPageViewDetails();
    }else if(this._data.type == 'abandonedCart'){
      this.getAbandonCartDetails();
    }
    else if(this._data.type == 'revenue'){
      this.getRoiDetails();
    }
  }
  findDate(date){
    if(!isNaN(date) || date.includes("?") || date.includes("&") || date.includes("=")) return false

    let response = Date.parse(date)
    if(response && response > 0){
      return true
    }
    else{
      return false
    }
  }

  findType(obj){
    try {
      obj = JSON.parse(obj) || '';
    } catch (error) {
      obj = '';
    }
    if((typeof obj == 'object')){
      return true
    }
  }
  errorDescription(reason) {
    this.DialogRef = this._matDialog.open(ErrorDescriptionDialogComponent, {
      panelClass: 'error-description-dialog',
      disableClose: true,
      data: {
        reason: reason
      }
    });
    this.DialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }

  getPageViewDetails(){
    let pageViewReq = {
      "companyId": this.userDetails.companyKey ? this.userDetails.companyKey : 0,
      "campaignId": this._data.campaignId,
      "eventKey": this._data.eventKey,
      "startDate": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "endDate": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "messageId": this._data.messageId,
      "contactId": this._data.contactId.toString()
    }
    this._fuseSplashScreenService.show();
    this.trackingService.getCampaignEventDetails(pageViewReq).subscribe((res:any) => {
      this._fuseSplashScreenService.hide();
      if (res && res.data.length > 0) {
        this.List = [];
        this.List = res.data;
        this.TotalCount = this.List.length;
        this.dataSource = new MatTableDataSource(this.List);
        if(this.List.length > 0) {
          this.displayedColumns = Object.keys(this.List[0]);
        }else {
          this.displayedColumns =[];
        }
    }
    },error =>{
      this._fuseSplashScreenService.hide();
      this._toastr.error(error.error.error, 'Error');
    });
  }
  changeUnderscore(initValue){
  return initValue.replace('_',' ');
  }
  getAbandonCartDetails(){
    let AbandonCartReq = {
      "companyId": this.userDetails.companyKey ? this.userDetails.companyKey : 0,
      "campaignId": this._data.campaignId,
      "eventKey": this._data.eventKey,
      "startDate": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "endDate": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "messageId": this._data.messageId,
      "contactId": this._data.contactId.toString()
    }
    this._fuseSplashScreenService.show();
    this.trackingService.getCampaignEventDetails(AbandonCartReq).subscribe((res:any) => {
      this._fuseSplashScreenService.hide();
      if (res && res.status == 200) {
        this.List = [];
        this.List = res.data;
        this.TotalCount = this.List.length;
        this.dataSource = new MatTableDataSource(this.List);
        if(this.List.length > 0) {
          this.displayedColumns = Object.keys(this.List[0]);
        }else {
          this.displayedColumns =[];
        }
     }
    },error =>{
      this._fuseSplashScreenService.hide();
      this._toastr.error(error.error.error, 'Error');
    });
  }
  getRoiDetails(){
    let RoiDetailsReq = {
      "companyId": this.userDetails.companyKey ? this.userDetails.companyKey : 0,
      "campaignId": this._data.campaignId,
      "eventKey": this._data.eventKey,
      "startDate": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "endDate": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
      "messageId": this._data.messageId,
      "contactId": this._data.contactId.toString()
    }
    this._fuseSplashScreenService.show();
    this.trackingService.getCampaignEventDetails(RoiDetailsReq).subscribe((res:any) => {
      this._fuseSplashScreenService.hide();
      if (res && res.status == 200) {
        this.List = [];
        this.List = res.data;
        this.TotalCount = this.List.length;
        this.dataSource = new MatTableDataSource(this.List);
        if(this.List.length > 0) {
          this.displayedColumns = Object.keys(this.List[0]);
          this.displayedColumns.push('Invoice')
        }else {
          this.displayedColumns =[];
        }
    }
    },error =>{
      this._fuseSplashScreenService.hide();
      this._toastr.error(error.error.error, 'Error');
    });
  }
  
  viewDetail(message): void {
    let apiReq2 = JSON.parse(JSON.stringify(message));
    let formattedApiReq = '',
    f = {
        brace: 0
    };
    formattedApiReq = apiReq2.replace(/['"]+/g, '').replace(/({|}[,]*|[^{}:]+:[^{},]*[,{]*)/g, function (m, p1) {
        let rtnFn = function() {
                return '<div class="api-text" style="text-indent: ' + -(f['brace'] * 20) + 'px; padding-left: ' +(f['brace'] * 40)+ 'px;">' + p1 + '</div>';
            },
            rtnStr;
        if (p1.lastIndexOf('{') === (p1.length - 1)) {
            rtnStr = rtnFn();
            f['brace'] += 1;
        } else if (p1.indexOf('}') === 0) {
                f['brace'] -= 1;
            rtnStr = rtnFn();
        } else {
            rtnStr = rtnFn();
        }
        return rtnStr;
    });
    let beautifiedReq = formattedApiReq.replace(/[,]/g, '<br>').replace(/[\])}[{(|,]+/g, ' ').replace(/(\w+(?=:))/g, function(m) {
        if( m == 'indent' || m == 'left' || /^https/.test(m) || /\b[0-9]{2,}/.test(m)) {
            return m;
        }
        return `<b>${m}</b>`
    })
    let pageHeader = this._data.type == 'pageView' ? 'Page View Details' : this._data.type == 'abandonedCart' ? 'Abandoned Cart Details' : "ROI Details"
    this.dialogRefView = this._matDialog.open(ViewMessageDialogComponent, {
        panelClass: 'view-message',
        data: {
            message: `<div class="Responselog"><h1>${pageHeader}</h1><p class="Resdesc">${beautifiedReq}</p>` +
                `</div>`,
            mediaURL: '',
            dialogTitle: 'JSON Data'
        }
    });
    if (this.dialogRef) {
    }
    this.dialogRefView.afterClosed()
        .subscribe(response => {
            if (!response) {
                return;
            }
        });
}
  invoiceDetail(message , date): void {
  this._matDialog.open(InvoicePageComponent, {
      panelClass: 'addCompany-form-dialog',
      data: {
          invoiceDetail : message,
          orderDate: date
      }
  });
}
  goToInvoicePage(): void {
    this.DialogRef = this._matDialog.open(InvoicePageComponent, {
      panelClass: 'invoice-page',
      data: {
        type:1
      }
    });
    this.DialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("roi-summary-details");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("roisummarydetail-content").style.maxHeight = "65vh";
    document.getElementById("roisummarydetail-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("roi-summary-details");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("roisummarydetail-content").style.maxHeight = "100vh";
    document.getElementById("roisummarydetail-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
