import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MailService } from 'app/main/pages/mail/mail.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ProfileService } from 'app/main/pages/profile/profile.service';

@Component({
    selector: 'two-step-auth-settings-dialog-dialog',
    templateUrl: './two-step-auth-settings.component.html',
    styleUrls: ['./two-step-auth-settings.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class twoStepAuthSettingsDialog {
    optSent: boolean = false;
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    twoWayForm: FormGroup;
    dialogTitle: string = "Two Step Verification";
    private _unsubscribeAll: Subject<any>;
    countries: any[] = [];
    ISDFilterCtrl: FormControl = new FormControl('');
    constructor(
        public matDialogRef: MatDialogRef<twoStepAuthSettingsDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _profileService: ProfileService,
        private _pageService: PageService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _mailService: MailService,
        public _matDialog: MatDialog
    ) {
        // Set the defaults
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.twoWayForm = this.createTwoWayForm();
    }
    ngOnInit(): void {
        this.getAllCountries();
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    createTwoWayForm(): FormGroup {
        return this._formBuilder.group({
            verificationCode: [''],
            isdCode: [this._data.isdCode],
            phoneNumber: [this._data.phoneNumber, Validators.required]
        });
    }
    getAllCountries() {
        this._pageService.getAllCountries()
            .subscribe((res: any) => {
                if (res) {
                    this.countries = res;
                }
                else {
                    this.countries = [];
                }
            },
                error => {
                    //console.log(error);
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    sendOTP() {
        if (this.twoWayForm.invalid) {
            this._pageService.validateAllFormFields(this.twoWayForm);
            return false;
        }
        var req: any = {
            "isdCode": this.twoWayForm.get('isdCode').value,
            "phoneNumber": this.twoWayForm.get('phoneNumber').value
        };
        this._fuseSplashScreenService.show();
        this._profileService.two2FA(this._data.userId, req)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.optSent = true;
                    this.twoWayForm.get('isdCode').disable();
                    this.twoWayForm.get('phoneNumber').disable();
                    this._pageService.addValidators(this.twoWayForm, "verificationCode");
                } else {
                    this._toastr.error(res.error, 'Error');
                    this.twoWayForm.get('isdCode').enable();
                    this.twoWayForm.get('phoneNumber').enable();
                    this._pageService.removeValidators(this.twoWayForm, "verificationCode");
                    this.optSent = false;
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.optSent = false;
                    this.twoWayForm.get('isdCode').enable();
                    this.twoWayForm.get('phoneNumber').enable();
                    this._pageService.removeValidators(this.twoWayForm, "verificationCode");
                    if (error && error.error && error.error.error) {
                        this._toastr.error(error.error.error, 'Error');
                    }
                    console.log(error);
                }
            );
    }
    resendOTP() {
        var req: any = {
            "isdCode": this.twoWayForm.get('isdCode').value,
            "phoneNumber": this.twoWayForm.get('phoneNumber').value
        };
        this._fuseSplashScreenService.show();
        this._profileService.two2FA(this._data.userId, req)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                } else {
                    this._toastr.error(res.error, 'Error'); 
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    if (error && error.error && error.error.error) {
                        this._toastr.error(error.error.error, 'Error');
                    }
                    console.log(error);
                }
            );
    }
    verifyOTP() {
        if (this.twoWayForm.invalid) {
            this._pageService.validateAllFormFields(this.twoWayForm);
            return false;
        }
        var req: any = {
            "isdCode": this.twoWayForm.get('isdCode').value,
            "phoneNumber": this.twoWayForm.get('phoneNumber').value,
            "verificationCode": this.twoWayForm.get('verificationCode').value,
            "isPrimary": true
        };
        this._fuseSplashScreenService.show();
        this._profileService.two2FA(this._data.userId, req)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    this._toastr.success(res.data, 'Success');
                    this.matDialogRef.close(this.twoWayForm);
                } else {
                    this._toastr.error(res.error, 'Error');
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    if (error && error.error && error.error.error) {
                        this._toastr.error(error.error.error, 'Error');
                    }
                    console.log(error);
                }
            );
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("two-step-auth-settings-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("two-way-content").style.maxHeight = "65vh";
        document.getElementById("two-way-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("two-step-auth-settings-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("two-way-content").style.maxHeight = "100vh";
        document.getElementById("two-way-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

