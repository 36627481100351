import { Validators } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Component({
    selector: 'save-row',
    templateUrl: './save-row.component.html',
    styleUrls: ['./save-row.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveRowComponent {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    saveRowForm: FormGroup;
    dialogTitle: string;
    dialogRef: any;
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    category = [
        { name: "All rows", category: "All rows"},
        { name: "Header rows", category: "Header rows"},
        { name: "Footer rows", category: "Footer rows"},
    ];
    constructor(
        public matDialogRef: MatDialogRef<SaveRowComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: FormBuilder,
        private _pageService: PageService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        private chRef: ChangeDetectorRef
    ) {
        // this.ContactId = _data.ContactId;
        this._unsubscribeAll = new Subject();
        this.dialogTitle = this._data.dialogTitle;
        this.saveRowForm = this.createGroupForm();
        setTimeout(() => {
            this.dirtyComponent();
        }, 1)
    }
    ngOnInit(): void {
        if(this._data.action == "edit" && this._data.pageName == 'saveRowPage') {
            this.saveRowForm.patchValue({
                journeyName: this._data.args.row.metadata.name,
                category: this._data.args.row.metadata.category
            })
        }
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    createGroupForm(): FormGroup {
        return this._formBuilder.group({
            journeyName: ['', [Validators.required]],
            category: ['', [Validators.required]]
        });
    }
    save() {
        if(!this.saveRowForm.valid) {
            return false;
        }
        var FormData = this.saveRowForm.getRawValue();
        if(this._data && this._data.action =="add" && this._data.rowData.length>0) {
           var preRow = this._data.rowData.filter(item => (item.metadata.name == FormData.journeyName));
            if(preRow && preRow.length>0){
                this._toastr.error('Name already taken', 'Error');
                return false;
           }
        }
        this.matDialogRef.close(FormData);
    }
    dirtyComponent() {
        document.getElementById('name').click();
        this.chRef.markForCheck();
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("saveRow-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("saveRow-content").style.maxHeight = "65vh";
        document.getElementById("saveRow-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("saveRow-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("saveRow-content").style.maxHeight = "100vh";
        document.getElementById("saveRow-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

