import { Component, OnInit, ViewEncapsulation, Input, ViewChild, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from 'app/main/pages/pages.service';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatPaginator, MatDialog, Sort, MatSort, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from "moment-timezone";
import { ChatDetailsDialogComponent } from '../dialogs/chat-details/chat-details.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import { ViewMessageDialogComponent } from '../dialogs/view-message/view-message.component';
import * as _ from 'lodash';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { startOfMonth, subDays } from 'date-fns';
@Component({
    selector: 'app-recent-replies',
    templateUrl: './recent-replies.component.html',
    styleUrls: ['./recent-replies.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RecentRepliesComponent implements OnInit, OnChanges {
    IsDialogMax: boolean = false;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    dialogRef: any;
    hostHeight: any;
    hostWidth: any;
    List: any = [];
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['avatar', 'createdAt', 'firstName', 'lastName', 'phoneNumber', 'messageText', 'isOptOut', 'reply', 'actions', 'source'];
    TotalCount: any = 0;
    pageNumber = 1;
    pageSize = 10;
    searchInput: FormControl;
    filterForm: FormGroup;
    reportType: any = 1;
    showCountLoader: boolean;
    private _unsubscribeAll: Subject<any>;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    sortBy: string = "createdAt";
    sortDirection: string = "desc";
    @ViewChild(FusePerfectScrollbarDirective, { static: false })
    directiveScroll: FusePerfectScrollbarDirective;
    constructor(
        public _matDialog: MatDialog,
        private _toastr: ToastrService,
        public matDialogRef: MatDialogRef<RecentRepliesComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _projectDashboardService: ProjectDashboardService,
        private _authService: AuthService,
        private _formBuilder: FormBuilder,
        private _pageService: PageService,
        private _router: Router,
        private _contactsService: ContactsService
    ) {
        this._unsubscribeAll = new Subject();
        this.searchInput = new FormControl('');
        this.filterForm = this._formBuilder.group({
            searchText: [''],
            // searchByFromDate: [subDays(new Date(), 30)],
            searchByFromDate: [startOfMonth(new Date())],
            searchByToDate: [new Date()],
            accounttypes: [[]]
        });
    }

    ngOnInit() {
        this.getRecentReplies();
    }
    ngOnChanges(changes?: SimpleChanges) {
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    onSearch(event) {
        this.List = [];
        this.pageNumber = 1;
        this.directiveScroll.scrollToTop();
        this.getRecentReplies();
    }

    getRecentReplies() {
        if (this.filterForm.invalid) {
            return false;
        }
        const GetMessagelogsrequest = {
            archived: false,
            deleted: false,
            sendByUser: false,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            searchName: this.filterForm.get('searchText').value,
            from: this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).local().format("YYYY-MM-DD") : '',
            to: this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).local().format("YYYY-MM-DD") : '',
            unifiedTypes: this.filterForm.get('accounttypes').value ? this.filterForm.get('accounttypes').value.toString() : null,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            contactId: this._data.contactId ? this._data.contactId : 0,
            isAgent: this._data.isAgent && this._data._pageService.isAgentView
        };
        let apiUrl = this._data.contactId ? '/Inbox/GetMessagelogsInbox' : '/Inbox/GetMessagelogs'
        this._fuseSplashScreenService.show();
        this._projectDashboardService.getRecentReplies(GetMessagelogsrequest, apiUrl)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    let temp_list = [];
                    res.data.forEach((obj: any) => {
                        const found = _.find(this.List, function (t) { return t.messageDetailId == obj.messageDetailId });
                        if (found == undefined || found == null) {
                            temp_list.push(obj);
                        }
                    });
                    Array.prototype.push.apply(this.List, temp_list);
                    this.dataSource = new MatTableDataSource(this.List);

                    if(!(this.pageSize > this.dataSource.filteredData.length)){
                        this.getRecentRepliesCount();
                    }
                    if(this.pageSize >= this.dataSource.filteredData.length) {
                        this.TotalCount = this.dataSource.filteredData.length;
                    }
                }
                else {
                    this.List = [];
                    this.dataSource = new MatTableDataSource(this.List);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.List = [];
                    this.dataSource = new MatTableDataSource(this.List);
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    getRecentRepliesCount() {
        if (this.filterForm.invalid) {
            return false;
        }
        const GetMessagelogsrequest = {
            archived: false,
            deleted: false,
            sendByUser: false,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            searchName: this.filterForm.get('searchText').value,
            from: this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).local().format("YYYY-MM-DD") : '',
            to: this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).local().format("YYYY-MM-DD") : '',
            unifiedTypes: this.filterForm.get('accounttypes').value ? this.filterForm.get('accounttypes').value.toString() : null,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            contactId: this._data.contactId ? this._data.contactId : 0,
            isAgent: this._data.isAgent && this._pageService.isAgentView
        };
        let apiUrl = this._data.contactId ? '/Inbox/GetMessagelogsInboxCount' : '/Inbox/GetMessagelogsCount'
        this.showCountLoader = true;
        this._projectDashboardService.getRecentReplies(GetMessagelogsrequest, apiUrl)
            .subscribe((res: any) => {
                this.showCountLoader = false;
                if (res.status == 200) {
                    this.TotalCount = res.data;
                }
                else {
                    this.TotalCount = 0;
                }
            },
                error => {
                    this.TotalCount = 0;
                    this.showCountLoader = false;
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    sortData(event) {
        this.List = [];
        this.pageNumber = 1;
        this.sortBy = event.active;
        this.sortDirection = event.direction;
        this.directiveScroll.scrollToTop();
        this.getRecentReplies();
    }

    goToContactDetails(contactId): void {
        this._router.navigate(['/pages/contactdetails/' + contactId]);
        this.matDialogRef.close();
    }
    onRefreshList(event) {
        this.List = [];
        this.pageNumber = 1;
        this.pageSize = 10;
        this.directiveScroll.scrollToTop();
        this.filterForm.patchValue({
            searchText: '',
            searchByFromDate: '',
            searchByToDate: '',
            accounttypes: []
        });
        this.getRecentReplies();
    }
    chatDialog(contact): void {
        this.dialogRef = this._matDialog.open(ChatDetailsDialogComponent, {
            panelClass: 'chat-details-dialog',
            data: {
                type: 'message',
                contact: contact
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
            });
    }
    optoutContact(contact): void {
        if (contact.isOptOut) {
            this._toastr.warning('Contact is already opted out for ' + contact.typeName, 'Warning');
        }
        else {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = 'Do you really want to Opt-Out the contact for ' + contact.typeName + '?';

            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this._fuseSplashScreenService.show();
                    this._contactsService.UpdateOptoutByTypeId(contact.contactId, contact.unifiedTypeId, true)
                        .subscribe((res: any) => {
                            this._fuseSplashScreenService.hide();
                            if (res.status == 200) {
                                this._toastr.success('Contact opted out successfully for ' + contact.typeName, 'Success');
                                this.getRecentReplies();
                            }
                        },
                            error => {
                                this._fuseSplashScreenService.hide();
                                this._toastr.error(error.message, 'Error');
                            });
                }
                this.confirmDialogRef = null;
            });
        }
    }
    viewMessage(message, mediaURL): void {
        this.dialogRef = this._matDialog.open(ViewMessageDialogComponent, {
            panelClass: 'view-message',
            data: {
                message: message,
                mediaURL: mediaURL
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
            });
    }
    onScrollDown(ev) {
        if(this.dataSource.filteredData.length< this.TotalCount) {
            this.pageNumber += 1;
            this.getRecentReplies();
        }
    }

    minimizeDialog() {
        let elements: any = document.getElementsByClassName("replies-table");
        for (var i = 0; i < elements.length; i++) {
          elements[i].style.width = (this.hostWidth + 1 + 'px');
          elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("replies-table-content").style.maxHeight = "100vh";
        document.getElementById("replies-table-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
      }
      maximizeDialog() {
        let elements: any = document.getElementsByClassName("replies-table");
        for (var i = 0; i < elements.length; i++) {
          this.hostHeight = elements[i].clientHeight;
          this.hostWidth = elements[i].clientWidth;
          elements[i].style.width = ("100%");
          elements[i].style.height = ("100%");
        }
        document.getElementById("replies-table-content").style.maxHeight = "100vh";
        document.getElementById("replies-table-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
      }
}
