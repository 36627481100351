import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MailService } from 'app/main/pages/mail/mail.service';
import { RestService } from '../../../../../shared/rest.service';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '../../../../../../@fuse/services/splash-screen.service';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { MailComposeDialogComponent } from 'app/main/pages/mail/dialogs/compose/compose.component';
import { fuseAnimations } from '@fuse/animations';
import { AddCampaignComponent } from 'app/main/pages/campaigns/add-campaign/add-campaign.component';

@Component({
    selector: 'add-group-dialog',
    templateUrl: './add-group.component.html',
    styleUrls: ['./add-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AddGroupComponent {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    action: string;
    groupForm: FormGroup;
    dialogTitle: string;
    dialogRef: any;
    groupIdData: any[] = [];
    groupsList: any[] = [];
    allGroupsList: any[] = [];
    groupFilterCtrl: FormControl = new FormControl('');
    private _unsubscribeAll: Subject<any>;
    public filterRank: FormControl = new FormControl();
    public filterCountry: FormControl = new FormControl();
    constructor(
        public matDialogRef: MatDialogRef<AddGroupComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: FormBuilder,
        private _contactsService: ContactsService,
        private _restService: RestService,
        private _pageService: PageService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _mailService: MailService,
        public _matDialog: MatDialog,
        private _campaignsService: CampaignsService,
    ) {
        // Set the defaults
        this.action = _data.action;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        if (this.action === 'new') {
            this.dialogTitle = 'Add Group';
        }
        this.groupForm = this.createGroupForm();
        if (this.action === 'new' && this._data.addToGroup) {
            this.dialogTitle = 'Add to Group';
            this._pageService.addValidators(this.groupForm, "groupId");
            this._pageService.removeValidators(this.groupForm, "groupName");
        }else if (this.action === 'new' && !this._data.addToGroup){
            this._pageService.addValidators(this.groupForm, "groupName");
            this._pageService.removeValidators(this.groupForm, "groupId");
        }
    }
    ngOnInit(): void {
        if(this._data && this._data.addToGroup) {
            this.getAllgroups();

            this.groupFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll),debounceTime(1000),distinctUntilChanged()).subscribe(() => {
                    this.filterGroup();
            });
        }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    createGroupForm(): FormGroup {
        return this._formBuilder.group({
            groupName: [''],
            groupId: [null]
        });
    }

    addGroup() {
        let groupData = this.groupForm.getRawValue();
        var groupContacts = [];
        if (this.groupForm.invalid) {
            this._pageService.validateAllFormFields(this.groupForm);
            return false;
        }
        if (this._data && this._data.pageName == 'clickMapLinkDetails') {
            if (this._data && this._data.selectedCont.length > 0) {
                this._data.selectedCont.forEach((value, index) => {
                    groupContacts.push(
                        {
                            "contactId": value
                        }
                    );
                });

                var GroupRequest: any = {
                    "name": groupData.groupName,
                    "filter": this._contactsService.appledFilters.value,
                    "groupContacts": groupContacts,
                    "createdById": this.userDetails.id
                };
                this._fuseSplashScreenService.show();
                this._contactsService.saveGroup(GroupRequest)
                    .subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this.groupIdData= res.data;
                            this._toastr.success("Group Created SentMessageDetails", 'Success');
                            // this.matDialogRef.close(this.groupForm);
                        } else {
                            this._toastr.error(res.error, 'Error');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            this._toastr.error(error.error.error, 'Error');
                        }
                    );
            }else if(this._data.isClickMapPage) {
                if(this._data.type == "totalDeliveries" || this._data.type == "uniqueOpens") { 
                    let status = this._data.type == 'totalDeliveries' ? 1 : (this._data.type == 'uniqueOpens' ? 7 : 0);
                    let sentMessageGroupReq = {
                        "messageId": this._data.messageId,
                        "status": status,
                        "pageNumber": 1,
                        "pageSize":this._data.totalCount,
                        "searchText": this._data.searchText,
                        "timeZoneId": this._data.timeZoneId,
                        "week": this._data.week,
                        "campaignId":this._data.campaignId,
                        "unifiedTypes": this._data.unifiedTypes,
                        "dripID": this._data.dripID
                      }
                      if(this._data && this._data.addToGroup) {
                        sentMessageGroupReq['addToGroup'] = true;
                        sentMessageGroupReq['groupId'] = groupData.groupId;
                      }
                      if(this._data && !this._data.addToGroup) {
                        sentMessageGroupReq['createGroup'] = true;
                        sentMessageGroupReq['groupName'] = groupData.groupName;
                      }
                      this._contactsService.SaveSentMessageGroup(sentMessageGroupReq, false)
                      .subscribe((res: any) => {
                        if (res.status == 200) {
                            this.groupIdData= res.data.groupId;
                            this._toastr.success(res.data.message, 'success');
                        }
                    });
                 } else if(this._data.type === "totalClicks") {
                    let linkDetailsGroupReq: any = {
                        "searchText": this._data.searchText,
                        "campaignId": this._data.campaignId,
                        "pageNumber": 1,
                        "pageSize": this._data.totalCount,
                        "url": this._data.url
                    }
                    if(this._data && this._data.addToGroup) {
                        linkDetailsGroupReq['addToGroup'] = true;
                        linkDetailsGroupReq['groupId'] = groupData.groupId;
                      }
                      if(this._data && !this._data.addToGroup) {
                        linkDetailsGroupReq['createGroup'] = true;
                        linkDetailsGroupReq['groupName'] = groupData.groupName;
                      }
                    this._fuseSplashScreenService.show();
                    this._campaignsService.GetURLClickMapDetails(linkDetailsGroupReq, false).subscribe((res: any) => {
                        this._fuseSplashScreenService.hide();
                        if (res.status == 200) {
                            this.groupIdData= res.data.groupId;
                            this._toastr.success(res.data.message, 'success');
                        }
                    },
                        error => {
                            this._fuseSplashScreenService.hide();
                            this._toastr.error(error.error.error, 'Error');
                        });
                }
                 
            }
            else {
                let linkDetailsGroupReq: any = {
                    "searchText": this._data.searchText,
                    "campaignId": this._data.campaignId,
                    "pageNumber": 1,
                    "pageSize": this._data.totalCount,
                    "url": this._data.url
                }
                if(this._data && this._data.addToGroup) {
                    linkDetailsGroupReq['addToGroup'] = true;
                    linkDetailsGroupReq['groupId'] = groupData.groupId;
                  }
                  if(this._data && !this._data.addToGroup) {
                    linkDetailsGroupReq['createGroup'] = true;
                    linkDetailsGroupReq['groupName'] = groupData.groupName;
                  }
                this._fuseSplashScreenService.show();
                this._campaignsService.GetURLClickMapDetails(linkDetailsGroupReq, false).subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this.groupIdData= res.data.groupId;
                        this._toastr.success(res.data.message, 'success');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error(error.error.error, 'Error');
                    })
            }

        }else if(this._data && this._data.pageName =='sentMessageDetails'){
            let sentMessageGroupReq = {
                "messageId": this._data.messageId,
                "status":this._data.status,
                "pageNumber": this._data.pageNumber,
                "pageSize":this._data.pageSize,
                "searchText": this._data.searchText,
                "from": this._data.from,
                "to": this._data.to,
                "timeZoneId": this._data.timeZoneId,
                "week": this._data.week,
                "campaignId":this._data.campaignId,
                "unifiedTypes": this._data.unifiedTypes,
                "dripID": this._data.dripID,
                "journeyId": (this._data && this._data.journeyId) ? this._data.journeyId : 0,
                "journeyItemId": (this._data && this._data.journeyItemId) ? this._data.journeyItemId : 0
              }
              if(this._data && this._data.addToGroup) {
                sentMessageGroupReq['addToGroup'] = true;
                sentMessageGroupReq['groupId'] = groupData.groupId;
              }
              if(this._data && !this._data.addToGroup) {
                sentMessageGroupReq['createGroup'] = true;
                sentMessageGroupReq['groupName'] = groupData.groupName;
              }
              this._contactsService.SaveSentMessageGroup(sentMessageGroupReq, false)
              .subscribe((res: any) => {
                if (res.status == 200) {
                    this.groupIdData= res.data.groupId;
                    this._toastr.success(res.data.message, 'success');
                }
            });

        }else {
            if (this._contactsService.selectedContacts.length > 0) {
                this._contactsService.selectedContacts.forEach((value, index) => {
                    groupContacts.push(
                        {
                            "contactId": value
                        }
                    );
                });
            }
            if (this._contactsService.appledFilters.value) {
                this._contactsService.appledFilters.value.searchText = this._contactsService.searchText;
                this._contactsService.appledFilters.value.associateId = this._contactsService.searchInteger ? parseInt(this._contactsService.searchInteger) : 0;
            }
            else {
                this._contactsService.appledFilters.next({});
                this._contactsService.appledFilters.value.channels = [];
                this._contactsService.appledFilters.value.paidAsRanks = [];
                this._contactsService.appledFilters.value.highestRanks = [];
                this._contactsService.appledFilters.value.countries = [];
                this._contactsService.appledFilters.value.countries = [];
                this._contactsService.appledFilters.value.associateTypes = [];
                this._contactsService.appledFilters.value.associateStatus = [];
                this._contactsService.appledFilters.value.hasActiveAutoship = null;
                this._contactsService.appledFilters.value.languageCodes = [];
                this._contactsService.appledFilters.value.optFilter = [];
                this._contactsService.appledFilters.value.invalidData = [];
                this._contactsService.appledFilters.value.fromDate = '';
                this._contactsService.appledFilters.value.toDate = '';
                this._contactsService.appledFilters.value.customFields = null;
                this._contactsService.appledFilters.value.searchText = this._contactsService.searchText;
                this._contactsService.appledFilters.value.associateId = this._contactsService.searchInteger ? parseInt(this._contactsService.searchInteger) : 0;
                this._contactsService.appledFilters.value.customFieldsCompare = null;
                this._contactsService.appledFilters.value.sponsorId = 0;
            }
            var GroupRequest: any = {
                "name": groupData.groupName,
                "filter": this._contactsService.appledFilters.value,
                "groupContacts": groupContacts,
                "createdById": this.userDetails.id
            };
            this._fuseSplashScreenService.show();
            this._contactsService.saveGroup(GroupRequest)
                .subscribe((res: any) => {
                    this._fuseSplashScreenService.hide();
                    if (res.status == 200) {
                        this.groupIdData= res.data;
                        this._toastr.success("Group Created", 'Success');
                    } else {
                        this._toastr.error(res.error, 'Error');
                    }
                },
                    error => {
                        this._fuseSplashScreenService.hide();
                        this._toastr.error(error.error.error, 'Error');
                    }
                );
        }
    }
    composeDialog(): void {
        this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
            panelClass: 'mail-compose-dialog',
            disableClose: true,
            data: {
                groupId: this.groupIdData
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }else {
                 
                }
            });
    }
    addCampaign() {
        this.dialogRef = this._matDialog.open(AddCampaignComponent, {
          panelClass: 'add-campaign-dialog',
          disableClose: true,
          data: {
            type: 'new',
            pageType: 'groupPage',
            dialogTitle: 'Add Campaign',
            view: false,
            groupId: this.groupIdData
          }
        });
        this.dialogRef.afterClosed()
          .subscribe(response => {
            if (!response) {
              return;
            }
          });
    }
    getAllgroups() {
        this._fuseSplashScreenService.show();
        this._pageService.getAllGroups().subscribe((res: any) => {
            this._fuseSplashScreenService.hide();
            if (res) {
                this.allGroupsList = res.data;
                this.groupsList = res.data;
            }
            else {
                this.allGroupsList = [];
                this.groupsList = [];
            }
        },
            error => {
                this._fuseSplashScreenService.hide();
                this._toastr.error(error.message, 'Error');
            }
        );
    }
    filterGroup(): void {
        let search = this.groupFilterCtrl.value;
        if (search) {
            this.groupsList = this.allGroupsList.filter(x => (x.name.toLowerCase().indexOf(search.toLowerCase()) > -1));
        }
        else {
            this.groupsList = this.allGroupsList;
        }
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("add-group-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("addgroup-content").style.maxHeight = "65vh";
        document.getElementById("addgroup-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("add-group-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("addgroup-content").style.maxHeight = "100vh";
        document.getElementById("addgroup-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

