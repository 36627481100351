import { Component, OnInit, Input, ViewEncapsulation, ViewChild, OnChanges, SimpleChange, SimpleChanges, Inject } from '@angular/core';
import { MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { ProjectDashboardService } from '../../../main/pages/dashboard/project.service';
import { AuthService } from 'app/shared/auth.service';
import * as moment from "moment-timezone";
import { MatPaginator } from '@angular/material/paginator';
import { SentMessageDetailsComponent } from '../dialogs/sent-message-details/sent-message-details.component';
import { ViewMessageDialogComponent } from '../dialogs/view-message/view-message.component';
import * as _ from 'lodash';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { MailComposeDialogComponent } from 'app/main/pages/mail/dialogs/compose/compose.component';
import { RecentSentInprogressComponent } from '../recent-sent-inprogress/recent-sent-inprogress.component';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { subDays, startOfDay, startOfMonth } from 'date-fns';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
@Component({
    selector: 'app-recent-sent',
    templateUrl: './recent-sent.component.html',
    styleUrls: ['./recent-sent.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RecentSentComponent implements OnInit, OnChanges {
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    dialogRef: any;
    recentform: FormGroup;
    marks: MarkDTO[] = [
        new MarkDTO({ id: 1, name: 'Group', number: 1 }),
        new MarkDTO({ id: 2, name: 'Single', number: 2 })
    ];
    List: any = [];
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['typeName', 'createdAt', 'messageText', 'sent', 'rejected', 'errors', 'delivered', 'opened', 'click', 'complained', 'replies', 'segments', 'inRedis', 'resend'];
    TotalCount: any;
    pageNumber = 1;
    pageSize = 30;
    filterForm: FormGroup;
    private _unsubscribeAll: Subject<any>;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    sortBy: string = "createdAt";
    sortDirection: string = "desc";
    showCountLoader: boolean;
    @ViewChild(FusePerfectScrollbarDirective, { static: false })
    directiveScroll: FusePerfectScrollbarDirective;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    onScrollStopRecentCount:any = true;
    offset = new Date().getTimezoneOffset();
    filterOffset = (this.offset >= 720) ? 719 : (this.offset < -720) ? -600 : this.offset;
    userRecentSent: any;
    constructor(
        public _matDialog: MatDialog,
        private _toastr: ToastrService,
        public matDialogRef: MatDialogRef<RecentSentComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _formBuilder: FormBuilder,
        private _pageService: PageService,
        private _projectDashboardService: ProjectDashboardService,
        private _authService: AuthService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _contactsService: ContactsService,

    ) {
        this._unsubscribeAll = new Subject();
        this._contactsService.userApicall.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.userRecentSent = res;
        });
        this.filterForm = this._formBuilder.group({
            searchText: [''],
            // searchByFromDate: [subDays(startOfDay(new Date()), 30)],
            searchByFromDate: [ this.userRecentSent ? subDays(startOfDay(new Date()), 7) : startOfMonth(new Date())],
            searchByToDate: [startOfDay(new Date())],
            accounttypes: [[]],
            groupType: [0]
        });

        this.recentform = this._formBuilder.group({
            label: [this.marks[0]]
        });
    }
    ngOnChanges(changes?: SimpleChanges) {
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();    
        this._unsubscribeAll.complete();
    }
    getSelectedLabel(): MarkDTO {
        return this.recentform.get('label').value;
    }
    ngOnInit() {
        this.getRecentSent();
    }
    getRecentSent() {
        if (this.filterForm.invalid) {
            return false;
        }
        const GetSentMessagelogsrequest = {
            "pageNumber": this.pageNumber,
            "pageSize": this.pageSize,
            "from": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "to": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "unifiedTypes": this.filterForm.get('accounttypes').value ? this.filterForm.get('accounttypes').value.toString() : null,
            "reportGroupType": this.filterForm.get('groupType').value,
            "sortBy": this.sortBy,
            "sortDirection": this.sortDirection,
            "searchText": this.filterForm.get('searchText').value,
            "contactId": this._data.contactId ? this._data.contactId : 0,
            "isAgent": this._data.isAgent && this._pageService.isAgentView
        };
        this._fuseSplashScreenService.show();
        this._projectDashboardService.getRecentSent(GetSentMessagelogsrequest)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    let temp_list = [];
                    res.data.forEach((obj: any) => {
                        const found = _.find(this.List, function (t) { return t.id == obj.id });
                        if (found == undefined || found == null) {
                            temp_list.push(obj);
                        }
                    });
                    Array.prototype.push.apply(this.List, temp_list);
                    this.dataSource = new MatTableDataSource(this.List);
                    
                    if(this.onScrollStopRecentCount && !(this.pageSize > this.dataSource.filteredData.length)){
                        this.getRecentSentCount();
                    }
                    if(this.pageSize >= this.dataSource.filteredData.length) {
                        this.TotalCount = this.dataSource.filteredData.length;
                    }
                   
                }
                else {
                    this.List = [];
                    this.TotalCount = 0;
                    this.dataSource = new MatTableDataSource(this.List);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.List = [];
                    this.TotalCount = 0;
                    this.dataSource = new MatTableDataSource(this.List);
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    getRecentSentCount() {
        if (this.filterForm.invalid) {
            return false;
        }
        const GetSentMessagelogsrequest = {
            "pageNumber": this.pageNumber,
            "pageSize": this.pageSize,
            "from": this.filterForm.get('searchByFromDate').value ? moment.utc(this.filterForm.get('searchByFromDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "to": this.filterForm.get('searchByToDate').value ? moment.utc(this.filterForm.get('searchByToDate').value).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") : '',
            "unifiedTypes": this.filterForm.get('accounttypes').value ? this.filterForm.get('accounttypes').value.toString() : null,
            "reportGroupType": this.filterForm.get('groupType').value,
            "sortBy": this.sortBy,
            "sortDirection": this.sortDirection,
            "searchText": this.filterForm.get('searchText').value,
            "contactId": this._data.contactId ? this._data.contactId : 0,
            "isAgent": this._data.isAgent && this._pageService.isAgentView
        };
        this.showCountLoader = true;
        this._projectDashboardService.getRecentSentCount(GetSentMessagelogsrequest)
            .subscribe((res: any) => {
                this.showCountLoader = false;
                if (res.status == 200) {
                    this.TotalCount = res.data;
                }
                else {
                    this.TotalCount = 0;
                }
            },
                error => {
                    this.showCountLoader = false;
                    this.TotalCount = 0;
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    onSearch(): void {
        this.List = [];
        this.pageNumber = 1;
        //this.paginator.pageIndex = 0;
        this.directiveScroll.scrollToTop();
        this.onScrollStopRecentCount = true;
        this.getRecentSent();
    }
    onPageChange(event): void {
        this.pageNumber = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.getRecentSent();
    }
    sortData(event) {
        this.List = [];
        this.pageNumber = 1;
        this.sortBy = event.active;
        this.sortDirection = event.direction;
        this.directiveScroll.scrollToTop();
        this.getRecentSent();
    }

    reportGroupTypeChange() {
        this.List = [];
        this.pageNumber = 1;
        this.directiveScroll.scrollToTop();
        this.getRecentSent();
    }
    refreshList() {
        this.List = [];
        this.pageNumber = 1;
        this.pageSize = 30;
        this.directiveScroll.scrollToTop();
        this.filterForm.patchValue({
            searchText: '',
            searchByFromDate: this.userRecentSent ? subDays(startOfDay(new Date()), 7) : '',
            searchByToDate: this.userRecentSent ? startOfDay(new Date()) : '',
            accounttypes: [],
            groupType: 0
        });
        this.recentform.patchValue({
            label: this.marks[0]
        });
        this.onScrollStopRecentCount = true;
        this.getRecentSent();
    }

    goToDetails(type, messageId): void {
        this.dialogRef = this._matDialog.open(SentMessageDetailsComponent, {
            panelClass: 'sent-message-details',
            data: {
                reportingType: type,
                messageId: messageId,
                isType: true,
                accounttypes: this.filterForm.get('accounttypes').value ? this.filterForm.get('accounttypes').value : [],
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
            });
    }
    viewMessage(message, mediaURL, unifiedTypeId, report): void {
        this.dialogRef = this._matDialog.open(ViewMessageDialogComponent, {
            panelClass: 'view-message',
            data: {
                message: message,
                mediaURL: mediaURL,
                unifiedTypeId: unifiedTypeId,
                reportData: report
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
            });
    }
    checkStatus(status): void {
        this.dialogRef = this._matDialog.open(ViewMessageDialogComponent, {
            panelClass: 'view-message',
            data: {
                message: status,
                dialogTitle: "Message Status"
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
            });
    }
    resendMessage(messagerecord, compose): void {
        if(compose) {
            if(messagerecord.unifiedTypeId == 1) {
            this._pageService.getMessageTextById(messagerecord.id).subscribe((res: any) => {
                if (res.status == 200){
                    messagerecord.messageText = res.data;

            if (messagerecord.link && JSON.parse(messagerecord.link).length > 0) {
                let links = JSON.parse(messagerecord.link);
                links.forEach(lk => {
                    var obj = {
                        "name": lk.Name || lk.name,
                        "url": lk.URL || lk.url
                    };
                    if (messagerecord.messageText) {
                        messagerecord.messageText = messagerecord.messageText.replace(obj.url, obj.name);
                    }
                });
            }
            this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
                panelClass: 'mail-compose-dialog',
                data: {
                    contactId: this._data.contactId,
                    action: 'edit',
                    template: {
                        unifiedTypeId: messagerecord.unifiedTypeId,
                        messageText: messagerecord.messageText.includes('\nReply STOP to stop') ? messagerecord.messageText.split('\nReply STOP to stop')[0] : messagerecord.messageText,
                        id: messagerecord.id,
                        subject: messagerecord.title,
                        mediaURL: messagerecord.mediaURL
                    },
                    link: messagerecord.link ? JSON.parse(messagerecord.link) : []
                }
            });
            this.dialogRef.afterClosed().subscribe(response => {
                if (!response) {
                    return;
                }
            });
                }  
            },
            error => {
                this._fuseSplashScreenService.hide();
                this._toastr.error(error.message, 'Error');
            });
            }else {
                if (messagerecord.link && JSON.parse(messagerecord.link).length > 0) {
                    let links = JSON.parse(messagerecord.link);
                    links.forEach(lk => {
                        var obj = {
                            "name": lk.Name || lk.name,
                            "url": lk.URL || lk.url
                        };
                        if (messagerecord.messageText) {
                            messagerecord.messageText = messagerecord.messageText.replace(obj.url, obj.name);
                        }
                    });
                }
                this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
                    panelClass: 'mail-compose-dialog',
                    data: {
                        contactId: this._data.contactId,
                        action: 'edit',
                        template: {
                            unifiedTypeId: messagerecord.unifiedTypeId,
                            messageText: messagerecord.messageText.includes('\nReply STOP to stop') ? messagerecord.messageText.split('\nReply STOP to stop')[0] : messagerecord.messageText,
                            id: messagerecord.id,
                            subject: messagerecord.title,
                            mediaURL: messagerecord.mediaURL
                        },
                        link: messagerecord.link ? JSON.parse(messagerecord.link) : []
                    }
                });
                this.dialogRef.afterClosed().subscribe(response => {
                    if (!response) {
                        return;
                    }
                });
            }            
        } else {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure, you want to resend Message?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this._projectDashboardService.RendSendMessage(messagerecord.id).subscribe((res: any) => {
                        if (res.status == 200) {
                            this._toastr.success("Process Started", 'success');
                            this.getRecentSent();
                        }
                    }, error => {
                        this._toastr.error(error.message, 'Error');
                    }
                    );
                }
                this.confirmDialogRef = null;
            });
        }
    }
    onScrollDown(ev) {
        if(this.dataSource.filteredData.length< this.TotalCount) {
            this.pageNumber += 1;
            this.onScrollStopRecentCount = false;
            this.getRecentSent();
        }
    }
    getProgressMessageReport(report) {
        if(report.inRedis) {
            this._projectDashboardService.getProgressMessageReport(report.id)
            .subscribe((res: any) => {
                if (res.status == 200) {
                    if(res.data) {
                        let progressData = res.data;
                        progressData.createdAt = report.createdAt;
                        progressData.messageText = report.messageText;
                        progressData.typeName = report.typeName;
                        progressData.mediaURL = report.mediaURL ? report.mediaURL : null;
                        progressData.unifiedTypeId = report.unifiedTypeId ? report.unifiedTypeId : 0;
                        let list = [];
                        list.push(progressData);
                        this.dialogRef = this._matDialog.open(RecentSentInprogressComponent, {
                            panelClass: 'recentSentInprogress-dialog',
                            disableClose: true,
                            maxWidth: "80vw",
                            data: {
                                List: list
                            }
                        });
                        this.dialogRef.afterClosed()
                            .subscribe(response => {
                                if (!response) {
                                    return;
                                }
                            });

                    }
                }
            },
                error => {
                    this._toastr.error(error.message, 'Error');
                }
            );
        }
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("recent-sent");
        for (var i = 0; i < elements.length; i++) {
          elements[i].style.width = (this.hostWidth + 1 + 'px');
          elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("recent-sent-content").style.maxHeight = "100vh";
        document.getElementById("recent-sent-wrapper").style.maxHeight = "90vh";
        this.IsDialogMax = !this.IsDialogMax;
      }
      maximizeDialog() {
        let elements: any = document.getElementsByClassName("recent-sent");
        for (var i = 0; i < elements.length; i++) {
          this.hostHeight = elements[i].clientHeight;
          this.hostWidth = elements[i].clientWidth;
          elements[i].style.width = ("100%");
          elements[i].style.height = ("100%");
        }
        document.getElementById("recent-sent-content").style.maxHeight = "100vh";
        document.getElementById("recent-sent-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
      }
}

class MarkDTO {
    id: number;
    name: string;
    number: number;
    constructor(mark?: any) {
        this.id = mark && mark.id || null;
        this.name = mark && mark.name || null;
        this.number = mark && mark.number || null;
    }
    get color(): string {
        let color = '';
        switch (this.number) {
            case 1: color = 'lightblue'; break;
            case 2: color = 'green'; break;
            case 3: color = 'blue'; break;
        }
        return color;
    }
}
