import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment-timezone";

@Pipe({ name: 'localeDateTime' })
export class localeDateTime implements PipeTransform {
    transform(value: string, format: string, showTimeZone: boolean): any {
        if(showTimeZone) {
            return value ? moment.utc(value).local().format(format) + ' ' + moment.tz(moment.tz.guess()).zoneAbbr(): '';
        }
        else {
            return value ? moment.utc(value).local().format(format) : '';
        }
    }
}
