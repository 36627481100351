import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';

@Component({
    selector: 'error-description',
    templateUrl: './error-description.component.html',
    styleUrls: ['./error-description.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ErrorDescriptionDialogComponent {
    errorReason: string = '';
    errorDesriptions: any[] = [];
    possibleCauses: any[] = [];
    possibleSolutions: any[] = [];
    constructor(
        public matDialogRef: MatDialogRef<ErrorDescriptionDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
    }

    ngOnInit(): void {
        this.getErrorDesription(this._data.reason);
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }
    getErrorDesription(reason) {
        if (reason.toLowerCase().search('unsubscribed') > -1) {
            this.errorReason = reason;
            this.errorDesriptions = ["The user has unsubscribed."];
        }
        else if (reason.toLowerCase().search('undelivered whatsapp') > -1) {
            this.errorReason = reason;
            this.errorDesriptions = ["The 24 hr. window has expired for WhatsApp. Please send an approved template."];
        }
        else if (reason.search('63009') > -1) {
            this.errorReason = "Error 63009";
            this.errorDesriptions = ["Channel provider returned an internal service error."];
        }
        else if (reason.search('30008') > -1) {
            this.errorReason = "Error 30008";
            this.errorDesriptions = [
                "Message Delivery - Unknown error",
                "If a message you sent is not delivered to the end device and returns a 30008 error code, this means that delivery of your message failed for unknown reasons."
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "Check that the phone you were sending to is turned on and can receive SMS."
                },
                {
                    type: 0,
                    text: "Ensure that the phone is not roaming off network. We cannot guarantee message delivery on roaming phones."
                },
                {
                    type: 0,
                    text: "Try sending to other phones who have the same mobile carrier (you can use our Lookups API to determine the carrier if you’re unsure. If messages to other phones go through, the issue is likely device related. Try rebooting the device or contact the mobile carrier for help."
                },
                {
                    type: 0,
                    text: "Try sending a shorter message to the phone, with simple content that does not include any special characters. This would give our support team an idea as to whether the failure is related to concatenation or character encoding."
                }
            ];
        }
        else if (reason.search('21614') > -1) {
            this.errorReason = "Error 21614";
            this.errorDesriptions = [
                "'To' number is not a valid mobile number",
                "You have attempted to send a SMS with a 'To' number that is not a valid mobile number. It is likely that the number that you have specified is a landline number or is an invalid number."
            ];
            this.possibleCauses = [
                "The number you provided may be a landline number.",
                "The number you provided may be invalid or formatted incorrectly.",
                "If you are attempting to send SMS to Internet of Things (IoT) or machine-to-machine (M2M) numbers, the numbers may use a non-standard format that Twilio has not added to our number validation system yet."
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "Confirm that the number you are sending to is not a landline. Ensure that the phone is not roaming off network. We cannot guarantee message delivery on roaming phones."
                },
                {
                    type: 0,
                    text: "Please verify you have provided a valid mobile number in proper E.164 format"
                },
                {
                    type: 0,
                    text: "If you are attempting to send SMS to an IoT or M2M number, check whether the number format is different from the standard mobile numbers in that country or locality. Often, these numbers have additional digits or unusual formats which do not pass Twilio's number validation."
                }
            ];
        }
        else if (reason.search('21704') > -1) {
            this.errorReason = "Error 21704";
            this.errorDesriptions = [
                "The Messaging Service contains no phone numbers.",
                "A Messaging Service requires at least one phone number, Alphanumeric Sender ID or short code to send messages."
            ];
            this.possibleCauses = [
                "Your Messaging Service does not contain any senders"
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "Add at least one phone number Messaging Service, or enable Alphanumeric Sender ID."
                }
            ];
        }
        else if (reason.search('30004') > -1) {
            this.errorReason = "Error 30004";
            this.errorDesriptions = [
                "Message Delivery - Message blocked"
            ];
            this.possibleCauses = [
                "The destination number you are trying to reach is blocked from receiving this message (e.g., due to blacklisting).",
                "The device you are trying to reach does not have sufficient signal.",
                "The device cannot receive SMS (for example, the phone number belongs to a landline).",
                "The destination number is on India's national Do Not Call registry.",
                "There is an issue with the mobile carrier."
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "The first step to troubleshooting this issue is to attempt to replicate the problem. Attempt to send another test message to this user"
                },
                {
                    type: 1,
                    text: "If you see similar results, continue troubleshooting with the following checklist:",
                    group: [
                        {
                            type: 0,
                            text: "Is the destination device powered on?"
                        },
                        {
                            type: 0,
                            text: "Does the device have sufficient signal? If not, power the device off, then wait 30 seconds and then power it back up."
                        },
                        {
                            type: 0,
                            text: "Is the device connected to the home carrier's network? We cannot guarantee message delivery to devices roaming off-network."
                        },
                        {
                            type: 0,
                            text: "Can the device receive non-ZipLingo SMS?"
                        },
                        {
                            type: 0,
                            text: "Can the device receive messages from another SMS number or with a shorter one-segment (non-concatenated) body?"
                        },
                        {
                            type: 0,
                            text: "Can other devices using the same mobile carrier receive your messages "
                        }
                    ]
                }
            ];
        }
        else if (reason.search('21610') > -1) {
            this.errorReason = "Error 21610";
            this.errorDesriptions = [
                "Attempt to send to unsubscribed recipient",
                "The person you are trying to message has opted out of receiving messages from your Twilio phone number, Channels sender, or Messaging Service.",
                "You have attempted to message a 'To' number that has replied with 'STOP' to one of your previous messages. You will not be able to send to the phone number specified in the 'To' parameter until the subscriber identified by the phone number has responded with 'START'.."
            ];
            this.possibleCauses = [
                "The end user handset has responded with 'STOP'"
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "Consider removing this phone number from your list of recipients."
                },
                {
                    type: 0,
                    text: "Request the recipient to resubscribe to your messages by texting in 'START'"
                },
                {
                    type: 0,
                    text: "Before sending messages to a recipient, ensure they have consented to receive messages from you."
                }
            ];
        }
        else if (reason.search('30007') > -1) {
            this.errorReason = "Error 30007";
            this.errorDesriptions = [
                "Message Delivery - Carrier violation",
                "Your message was flagged as objectionable and blocked by the receiving wireless carrier. Some carriers do this to enforce rules or regulations about what types of messaging are allowed to that country or mobile network. Many carriers also have systems in place to protect mobile subscribers from spam or unwanted messages."
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "Please contact ZipLingo Support"
                }
            ];
        }
        else if (reason.search('30003') > -1) {
            this.errorReason = "Error 30003";
            this.errorDesriptions = [
                "Message Delivery - Unreachable destination handset"
            ];
            this.possibleCauses = [
                "The destination handset you are trying to reach is switched off or otherwise unavailable.",
                "The device you are trying to reach does not have sufficient signal",
                "The device cannot receive SMS (for example, the phone number belongs to a landline)",
                "There is an issue with the mobile carrier",
                "Repeated Error 30003 results on undelivered messages sent from long codes (regular phone numbers) could be due to carrier filtering.",
                "The rules for carrier filtering vary throughout the industry, making it difficult at times to pin down exactly why a message is being filtered out. Contact ZipLingo Support for more help."
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "The first step to troubleshooting this issue is to attempt to replicate the problem. Attempt to send another test message to this user"
                },
                {
                    type: 1,
                    text: "If you see similar results, continue troubleshooting with the following checklist:",
                    group: [
                        {
                            type: 0,
                            text: "Is the destination device powered on?"
                        },
                        {
                            type: 0,
                            text: "Does the device have sufficient signal? If not, power the device off, then wait 30 seconds and then power it back up."
                        },
                        {
                            type: 0,
                            text: "Is the device connected to the home carrier's network? We cannot guarantee message delivery to devices roaming off-network."
                        },
                        {
                            type: 0,
                            text: "Can the device receive non-ZipLingo SMS?"
                        },
                        {
                            type: 0,
                            text: "Can the device receive messages from another SMS number or with a shorter one-segment (non-concatenated) body?"
                        },
                        {
                            type: 0,
                            text: "Can other devices using the same mobile carrier receive your messages "
                        }
                    ]
                }
            ];
        }
        else if (reason.search('30006') > -1) {
            this.errorReason = "Error 30006";
            this.errorDesriptions = [
                "Message Delivery - Landline or unreachable carrier"
            ];
            this.possibleCauses = [
                "The destination number is unable to receive this message. Potential reasons could include trying to reach a landline or, in the case of short codes, an unreachable carrier."
            ];
        }
        else if (reason.search('21608') > -1) {
            this.errorReason = "Error 21608";
            this.errorDesriptions = [
                "This number can send messages only to verified numbers.",
                "You have attempted to send a message to an unverified phone number while using a trial account.",
                "To prevent spam, trial accounts can only send messages to phone numbers you've verified with Twilio. You must upgrade your account to remove this restriction."
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "Verify the phone number you wish to reach."
                }
            ]
        }
        else if (reason.search('30005') > -1) {
            this.errorReason = "Error 30005";
            this.errorDesriptions = [
                "Message Delivery - Unknown destination handset"
            ];
            this.possibleCauses = [
                "The destination number you are trying to reach is unknown and may no longer exist.",
                "The device you are trying to reach is not on or does not have sufficient signal.",
                "The device cannot receive SMS (for example, the phone number belongs to a landline).",
                "There is an issue with the mobile carrier."
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "The first step to troubleshooting this issue is to attempt to replicate the problems."
                },
                {
                    type: 0,
                    text: "If you see similar results, continue troubleshooting with the following checklist."
                },
                {
                    type: 0,
                    text: "Is the destination device powered on?"
                },
                {
                    type: 0,
                    text: "Does the device have sufficient signal? If not power the device off, wait 30 seconds, and then power it back up."
                },
                {
                    type: 0,
                    text: "Is the device connected to the home carrier's network? We cannot guarantee message delivery on devices roaming off-network."
                },
                {
                    type: 0,
                    text: "Can the device receive SMS?"
                },
                {
                    type: 0,
                    text: "Can the device receive messages from another number, or with a shorter one-segment (non-concatenated) body?"
                },
                {
                    type: 0,
                    text: "Can other devices using the same mobile carrier receive your messages?"
                }
            ]
        }
        else if (reason.search('21408') > -1) {
            this.errorReason = "Error 21408";
            this.errorDesriptions = [
                "Permission to send an SMS has not been enabled for the region indicated by the 'To' number.",
                "You have attempted to send an SMS to a region that has not been enabled in your account's Messaging Geo-Permissions settings. These settings exist to help you ensure you only send SMS to the countries or regions you operate in, and avoid unexpected charges."
            ];
            this.possibleCauses = [
                "You have attempted to send an SMS to a region that has not been enabled in your account's Geo-Permissions settings."
            ];
            this.possibleSolutions = [
                {
                    type: 0,
                    text: "If you wish to send messages to this region, please enable the relevant permissions on your account using the Messaging Geographic Permissions page."
                }
            ];
        }
        else if (reason.search('service-not-allowed') > -1) {
            this.errorReason = "Message was rejected for reasons other than those covered by other 4xxx codes";
            this.errorDesriptions = [
                "This is a general error that the service you are attempting to use is not allowed;you may have inaccurate permissions, formatting or may not be enable to use that service.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('malformed-invalid-encoding') > -1) {
            this.errorReason = "Malformed message encoding";
            this.errorDesriptions = [
                "The message contains invalid characters that are not supported. Bandwidth cannot re-encode message for destination.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('malformed-invalid-from-number') > -1) {
            this.errorReason = "Malformed From number";
            this.errorDesriptions = [
                "The From number associated with the message is a number not routable to a carrier or valid in the industry (Ex: a 9 digit number).",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('malformed-invalid-to-number') > -1) {
            this.errorReason = "Malformed To Number";
            this.errorDesriptions = [
                "The To number associated with the message is a number not routable to a carrier or valid in the industry (Ex: a 9 digit number).",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('malformed-for-destination') > -1) {
            this.errorReason = "Malformed message encoding";
            this.errorDesriptions = [
                "Message passed validation on receive stage, but failed on send. This is likely because the destination number (To) is an invalid number.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('message-not-sent-expiration-date-passed') > -1) {
            this.errorReason = "Message expired";
            this.errorDesriptions = [
                "Message was not sent because the specified expiration date passed before the message was able to send",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-routing-error') > -1) {
            this.errorReason = "BW is unable to route the message";
            this.errorDesriptions = [
                "Message is unable to be routed within Bandwidth particularly when the source and destination are the same number. The destination or To number is mis-provisioned or there is a configuration with the message that is causing a situation where a message is being sent repeatedly between the same numbers.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-forbidden-from-number') > -1) {
            this.errorReason = "Messaging forbidden on From number";
            this.errorDesriptions = [
                "Messaging on this From number is forbidden most commonly because the number does not belong to BW or the account. Other reasons include: the TN is not enabled in the Bandwidth Dashboard, the account associated with this number is not enabled for this type of messaging, the TN is disconnected, or it is an invalid number (i.e., 11111111111).",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-forbidden-to-number') > -1) {
            this.errorReason = "Messaging forbidden on To number";
            this.errorDesriptions = [
                "Messaging on this To number is forbidden. This could be the number is not active, not enabled for messaging or is an invalid number (i.e. 11111111111)",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-unallocated-from-number') > -1) {
            this.errorReason = "Unallocated from number";
            this.errorDesriptions = [
                "The From telephone number is considered unallocated when the number does not exist in our database as an active number. This number is either not enabled for messaging at the industry level, or the number is not yet released in the industry",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-unallocated-to-number') > -1) {
            this.errorReason = "Unallocated to number";
            this.errorDesriptions = [
                "The To number associated with this message, while a valid North American number, is not yet assigned to a carrier and the message cannot be sent downstream.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-account-not-defined-from-number') > -1) {
            this.errorReason = "From Number is associated with account";
            this.errorDesriptions = [
                "Undefined source account id. The From number associated with this message is not associated with this account, is an invalid number or not configured appropriately to send messages.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-account-not-defined-to-number') > -1) {
            this.errorReason = "To Number not associated with account";
            this.errorDesriptions = [
                "Undefined destination account id. The To (destination) number is not associated with an account, is an invalid number or not configured correctly to receive messages.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-invalid-from-profile') > -1) {
            this.errorReason = "Invalid destination profile";
            this.errorDesriptions = [
                "Bandwidth failed to create destination. The destination profile is considered invalid, most often this is because the destination number does not support MMS.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('media-unavailable') > -1) {
            this.errorReason = "Could not download media";
            this.errorDesriptions = [
                "There was an error retrieving the media from the media web server. Check the media URL and try to access directly to see if the media can be fetched successfully.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-message-size-limit-exceeded') > -1) {
            this.errorReason = "Combined size of media too large";
            this.errorDesriptions = [
                "The total size of MMS message media/attachments exceeded the max file size supported",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('media-content-invalid') > -1) {
            this.errorReason = "Failed to parse Content-Type for media";
            this.errorDesriptions = [
                "The media content type is not a supported media content type.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-carrier-does-not-exist') > -1) {
            this.errorReason = "No Route to Destination Carrier";
            this.errorDesriptions = [
                "The upstream carrier associated with the message does not exist in Bandwidth configuration",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-forbidden-no-destination') > -1) {
            this.errorReason = "No Route to Destination Carrier";
            this.errorDesriptions = [
                "The message cannot be sent downstream as the account associated with the message does not have permission to send to this destination. You may not be provisioned to send to this destination.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-forbidden-shortcode') > -1) {
            this.errorReason = "Messaging on shortcode forbidden";
            this.errorDesriptions = [
                "The message cannot be sent as the account associated with the message is not provisioned for Short code messaging",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-forbidden-country') > -1) {
            this.errorReason = "Messaging to country forbidden";
            this.errorDesriptions = [
                "Bandwidth system indicates the account associated with the message is not enabled for messaging this zone, this country or this country is outside of messaging reach (specifically for MMS).",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-forbidden-tollfree') > -1) {
            this.errorReason = "Messaging on Toll Free Number Forbidden";
            this.errorDesriptions = [
                "The account associated with this message is not enabled for toll free messaging",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-forbidden-tollfree-for-recipient') > -1) {
            this.errorReason = "Messaging to Toll Free Number Forbidden";
            this.errorDesriptions = [
                "Messaging to this toll free number is not allowed. Number is likely not enabled for messaging or not active.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('forbidden-too-many-recipients') > -1) {
            this.errorReason = "Too Many Recipients";
            this.errorDesriptions = [
                "The group message has too many recipients. When sending Group Messages, there's a maximum of 10 participants in a Group.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-wrong-user-id') > -1) {
            this.errorReason = "Invalid User Id";
            this.errorDesriptions = [
                "The user id is not a valid id. Verify the user ID and retry the message",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-wrong-application-id') > -1) {
            this.errorReason = "Invalid Application ID";
            this.errorDesriptions = [
                "The Application ID specified is not a valid Application Id, or the Application ID is not associated with the account",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-spam-detected') > -1) {
            this.errorReason = "Rejected as SPAM";
            this.errorDesriptions = [
                "This message has been filtered and blocked by Bandwidth for spam. Messages can be blocked for a variety of reason, including but not limited to volumetric filtering, content blocking, SHAFT violation, etc.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-from-number-in-blacklist') > -1) {
            this.errorReason = "From Number in black list";
            this.errorDesriptions = [
                "The From number has been flagged by Bandwidth as prohibited from sending messages. This is typically because Bandwidth or a downstream carriers has several violations; reports of spam, P2P violations, associated with this number.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-to-number-in-blacklist') > -1) {
            this.errorReason = "To Number in black list";
            this.errorDesriptions = [
                "The number you are attempting to send to is blocked from receiving messages.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('reject-emergency') > -1) {
            this.errorReason = "Message to emergency number forbidden";
            this.errorDesriptions = [
                "Messaging to an emergency number is forbidden",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('rejected-unauthorized') > -1) {
            this.errorReason = "Unauthorized";
            this.errorDesriptions = [
                "Bandwidth service indicates the sender is not authorized to send messages from the account.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }

        //Bandwidth Service Failures
        else if (reason.search('temporary-app-error') > -1) {
            this.errorReason = "Application Error";
            this.errorDesriptions = [
                "An application within the Bandwidth service is experiencing a temporary error that is preventing the message from being processed.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('temporary-app-shutdown') > -1) {
            this.errorReason = "Application Error";
            this.errorDesriptions = [
                "App going down. Message not received. Sender should send this messages later or to other host.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('impossible-to-route') > -1) {
            this.errorReason = "Impossible to route / Attempt to deliver through retries has failed.";
            this.errorDesriptions = [
                "Impossible to route / Attempt to deliver through retries has failed.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('temporary-app-connection-closed') > -1) {
            this.errorReason = "Application Error";
            this.errorDesriptions = [
                "Received messaged for connection which is already removed.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('temporary-rout-error-retries-exceeded') > -1) {
            this.errorReason = "Application Error";
            this.errorDesriptions = [
                "Bandwidth service expired the message after attempts to deliver through retries failed.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('temporary-app-error-app-busy') > -1) {
            this.errorReason = "Application Error";
            this.errorDesriptions = [
                "Bandwidth service application is temporarily busy so it cannot receive messages at this time",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('temporary-store-error') > -1) {
            this.errorReason = "Application Error";
            this.errorDesriptions = [
                "Message not received. Cannot save message to store.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('discarded-concatenation-timeout') > -1) {
            this.errorReason = "Application Error";
            this.errorDesriptions = [
                "Bandwidth did not receive all parts of message. Message can not be sent.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('message-send-failed') > -1) {
            this.errorReason = "General Message Send Failure";
            this.errorDesriptions = [
                "The destination carrier has reported a general service failure with sending the message.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('message-send-failed') > -1) {
            this.errorReason = "General Message Send Failure";
            this.errorDesriptions = [
                "The message is unable to send as no destination is available.",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else if (reason.search('unknown-error') > -1) {
            this.errorReason = "Unknown error from Bandwidth";
            this.errorDesriptions = [
                "Unknown error generated by Bandwidth when Bandwidth core reports an unknown error",
            ];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
        else {
            this.errorReason = reason;
            this.errorDesriptions = [];
            this.possibleCauses = [];
            this.possibleSolutions = [];
        }
    }
}

