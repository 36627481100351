export const BASE_URL="https://unifiedbetaapi.ziplingo.com";
// export const BASE_URL="http://localhost:62948";
export const exigoURL="https://exigoeventservice.wsicloud.net";
export const API_URL=`${BASE_URL}/api`;
export const EXIGO_URL=`${exigoURL}/api`;
export const API_ENDPOINTS={
    ACCOUNT:{
        USERS:`${API_URL}/Account/users`,
        ROLES:`${API_URL}/Account/roles`,
    },
    AUTH:`${BASE_URL}/connect/token`,
    PERMISSIONS:{
        PAGES:`${API_URL}/api/Permissions/Pages`
    }
}

export function objectToParams(object): string {
    function clean(obj) {
        for (let propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
          }
        }
      }
      clean(object);
    return Object.keys(object).map((key) => (typeof (object[key]) === "object") ?
        subObjectToParams(encodeURIComponent(key), object[key]) :
        `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
    ).join('&');
}

function subObjectToParams(key, object): string {
    return Object.keys(object).map((childKey) => (typeof (object[childKey]) === "object") ?
        subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]) :
        `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`
    ).join('&');
}