import { Component, OnInit, ViewEncapsulation, Inject , ViewChild} from '@angular/core';
import { fuseAnimations } from '../../../../../../@fuse/animations/index';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatDialog, MatPaginator,  MAT_DIALOG_DATA ,MatSort, MatDialogRef } from '@angular/material';
import { PageService } from 'app/main/pages/pages.service';
import { RestService } from 'app/shared/rest.service';

@Component({
  selector: 'contact-list-filter',
  templateUrl: './contact-list-filter.component.html',
  styleUrls: ['./contact-list-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ContactListFilterComponent implements OnInit {
  dialogTitle: string = 'Contacts';
  dataSource: MatTableDataSource<any>;
  TotalCount: any = 0;
  pageNumber: number = 1;
  pageSize: number = 100;
  displayedColumns = ['avatar', 'fname', 'lname', 'phonenumber', 'associateId', 'emailVerified', 'verifiedNumber', 'statusName'];
  IsDialogMax:boolean =false;
  hostWidth: number;
  hostHeight: number;
  filterData:any;
  contactList: any[] = [];
  constructor( private _matDialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    public matDialogRef: MatDialogRef<ContactListFilterComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _pageService: PageService,
    private _restService: RestService,
    ) {
      this.filterData = _data.appliedFilters;
     }


  ngOnInit() {
    this.getContacts();
  }

  getContacts() {
    let contactReq: any = {

        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        paidAsRanks: this.filterData && this.filterData.paidAsRanks ? this.filterData.paidAsRanks : [],
        highestRanks: this.filterData && this.filterData.highestRanks ? this.filterData.highestRanks : [],
        countries: this.filterData && this.filterData.countries ? this.filterData.countries : [],
        states:  this.filterData && this.filterData.states ? this.filterData.states : [],
        associateTypes: this.filterData && this.filterData.associateTypes ? this.filterData.associateTypes : [],
        associateStatus: this.filterData && this.filterData.associateStatus ? this.filterData.associateStatus : [],
        languageCodes: this.filterData && this.filterData.languageCodes ? this.filterData.languageCodes : [],
        optFilter: this.filterData && this.filterData.optFilter ? this.filterData.optFilter : [],
        invalidData: this.filterData && this.filterData.invalidData ? this.filterData.invalidData : [],
        fromDate: this.filterData && this.filterData.fromDate ? this.filterData.fromDate : '',
        toDate: this.filterData && this.filterData.toDate ? this.filterData.toDate : '',
        groupRangeFrom: this.filterData && this.filterData.groupRangeFrom ? this.filterData.groupRangeFrom : 0,
        groupRangeTo: this.filterData && this.filterData.groupRangeTo ? this.filterData.groupRangeTo : 0,
        customFields: this.filterData && this.filterData.customFields ? this.filterData.customFields : null,
        channels: this.filterData && this.filterData.channels ? this.filterData.channels : [],
        customFieldsCompare: this.filterData && this.filterData.customFieldsCompare ? this.filterData.customFieldsCompare : null,
        sponsorId: this.filterData && this.filterData.sponsorId ? this.filterData.sponsorId : 0
      }
    this._fuseSplashScreenService.show();
    this._restService.Post('/Contact/GetAllContacts?IsAgent=' + this._pageService.isAgentView, contactReq)
        .subscribe((response: any) => {
            this._fuseSplashScreenService.hide();
            if (response.status == 200) {
              this.contactList = response.data.report;
              this.dataSource = new MatTableDataSource(response.data.report);
              this.TotalCount = response.data.totalCount;
            }
            else {
              this.contactList = [];
              this.dataSource = new MatTableDataSource([]);
              this.TotalCount = 0;
            }
        },
            error => {
                this._fuseSplashScreenService.hide();
                this._toastr.error(error.error.error, 'Error');
                this.contactList = [];
            });
}
onScrollDown(ev) {
  if(this.dataSource.filteredData.length< this._data.listLength) {
   this.pageNumber += 1;
   this.getContacts();
  }
}
 
minimizeDialog() {
    let elements: any = document.getElementsByClassName("contact-list-filter-dialog");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width =  (this.hostWidth+1+'px');
      elements[i].style.height =  (this.hostHeight+1+'px');
    }
    document.getElementById("contact-list-filter-content").style.maxHeight = "75vh";
    document.getElementById("contact-list-filter-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
}
maximizeDialog() {
    let elements: any = document.getElementsByClassName("contact-list-filter-dialog");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth; 
      elements[i].style.width = ("100%");
        elements[i].style.height = ("100%");
    }
    document.getElementById("contact-list-filter-content").style.maxHeight = "100vh";
    document.getElementById("contact-list-filter-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
 }


}
