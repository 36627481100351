import { cloneDeep } from 'lodash';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { ClickMapLinkComponent } from '../click-map-link/click-map-link.component';
import { ClickMapLinkDetailsComponent } from '../click-map-link-details/click-map-link-details.component';
import { UniqueClickLinkComponent } from '../unique-click-link/unique-click-link.component';

@Component({
  selector: 'app-click-map',
  templateUrl: './click-map.component.html',
  styleUrls: ['./click-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class ClickMapComponent implements OnInit {
  hostHeight: any;
  hostWidth: any;
  IsDialogMax: boolean = false;
  dialogRef: any;
  emailData: any;
  campaignId: any;
  linkArray: any[] = [];
  totalClicks: any;
  uniqueClicks: any;
  totalContacts: any;
  totalClickPer: any;
  totalDeliveries: any;
  uniqueOpens: any;
  totalClickPerUnique: any;
  pageViews: any;
  abandonedCart: any;
  revenue: any;
  showSummary: boolean = false;
  clickMapLink: FormControl = new FormControl(0);
  urlData: any[] = [];
  linkParentElement: any;
  constructor(
    public matDialogRef: MatDialogRef<ClickMapComponent>,
    private _campaignsService: CampaignsService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _toastr: ToastrService,
    private _matDialog: MatDialog
  ) {
    this.campaignId = _data.CampData.id;
    if (this.campaignId != null && this.campaignId != '') {
      this.getClickMapData(this.campaignId);
    }
  }

  ngOnInit() {

  }
  getClickMapData(campaignId) {
    this._fuseSplashScreenService.show();
    this._campaignsService.getClickMapData(campaignId)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          this.emailData = res.data.message;
          this.urlData = res.data.urLclicks;
          this.totalClicks = res.data.totalClicks;
          this.uniqueClicks = res.data.uniqueClicks;
          this.totalContacts = res.data.totalContacts;
          this.totalDeliveries = res.data.totalDeliveries;
          this.uniqueOpens = res.data.uniqueOpens;
          this.pageViews = res.data.pageViews;
          this.abandonedCart = res.data.abandonedCart;
          this.revenue = res.data.revenue;
          this.totalClickPer = isNaN(((parseFloat(this.totalContacts) / parseFloat(this.totalDeliveries)) * 100)) ? 0 :((parseFloat(this.totalContacts) / parseFloat(this.totalDeliveries)) * 100).toFixed(2);
          this.totalClickPerUnique = isNaN(((parseFloat(this.totalContacts) / parseFloat(this.uniqueClicks)) * 100)) ? 0 : ((parseFloat(this.totalContacts) / parseFloat(this.uniqueClicks)) * 100).toFixed(2);
          this.mapEmailData();
        }
        else {
          this.emailData = '';
          this._toastr.error(res.message, 'Error');
        }
      },
        error => {
          this.emailData = '';
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.message, 'Error');
        }
      );

  }
  mapEmailData() {
    var doc = new DOMParser().parseFromString(this.emailData, "text/html");
    var links = doc.getElementsByTagName("a")
    var htmlUrlsArray = [];
    for (var i = 0; i < links.length; i++) {
      htmlUrlsArray.push(links[i].getAttribute("href"));
    }
    var responseUrlDataArray = cloneDeep(this.urlData);
    for (let i = 0; i < htmlUrlsArray.length; i++) {
      var htmlUrl = htmlUrlsArray[i];
      var replaceHtmlUrl = htmlUrl.replace(/\[.*\]/, '~');
      if (replaceHtmlUrl.includes('~')) {
        if (replaceHtmlUrl == '~') {
          replaceHtmlUrl = htmlUrl;
        }
        var splitHtmlUrl = replaceHtmlUrl.split('~');
        if (splitHtmlUrl.length > 0) {
          for (let j = 0; j < responseUrlDataArray.length; j++) {
            if (responseUrlDataArray[j].url == "[OptInLink]" && replaceHtmlUrl == "[ValidateLink]") {
              responseUrlDataArray[j].url = "[ValidateLink]";
            }
            if (responseUrlDataArray[j].url == "[ValidateLink]" && replaceHtmlUrl == "[OptInLink]") {
              responseUrlDataArray[j].url = "[OptInLink]";
            }
            var subStringIncludes = false;
            for (let l = 0; l < splitHtmlUrl.length; l++) {
              if (responseUrlDataArray[j].url.includes(splitHtmlUrl[l])) {
                subStringIncludes = true;
              } else {
                subStringIncludes = false;
                break;
              }
            }
            if (subStringIncludes) {
              if (this.linkArray.length == 0) {
                this.linkArray.push(responseUrlDataArray[j]);
                this.linkArray[0].htmlLink = htmlUrlsArray[i];
                responseUrlDataArray.splice(j, 1);
                // break;
              } else {
                var flag = false;
                for (let k = 0; k < this.linkArray.length; k++) {
                  if (htmlUrlsArray[i] == this.linkArray[k].htmlLink) {
                    this.linkArray[k].clicks = this.linkArray[k].clicks + responseUrlDataArray[j].clicks;
                    this.linkArray[k].urlUniqueClicks = this.linkArray[k].urlUniqueClicks + responseUrlDataArray[j].urlUniqueClicks;
                    this.linkArray[k].per = isNaN(((parseFloat(this.linkArray[k].clicks) / parseFloat(this.totalClicks)) * 100)) ? 0 : ((parseFloat(this.linkArray[k].clicks) / parseFloat(this.totalClicks)) * 100).toFixed(2);
                    flag = true;
                  }
                }
                if (!flag) {
                  this.linkArray.push(responseUrlDataArray[j]);
                  let length = this.linkArray.length - 1;
                  this.linkArray[length].htmlLink = htmlUrlsArray[i];
                }
                responseUrlDataArray.splice(j, 1);
                // break;
              }
            }
          }
        }
      } else {
        if (replaceHtmlUrl) {
          for (let j = 0; j < responseUrlDataArray.length; j++) {
            if (responseUrlDataArray[j].url == "[OptInLink]" && replaceHtmlUrl == "[ValidateLink]") {
              responseUrlDataArray[j].url = "[ValidateLink]";
            }
            if (responseUrlDataArray[j].url == "[ValidateLink]" && replaceHtmlUrl == "[OptInLink]") {
              responseUrlDataArray[j].url = "[OptInLink]";
            }
            var urlgmatch = false;
            if (replaceHtmlUrl == responseUrlDataArray[j].url) {
              urlgmatch = true;
            }
            if (urlgmatch) {
              if (this.linkArray.length == 0) {
                this.linkArray.push(responseUrlDataArray[j]);
                this.linkArray[0].htmlLink = htmlUrlsArray[i];
                responseUrlDataArray.splice(j, 1);
                // break;
              } else {
                var flag = false;
                for (let k = 0; k < this.linkArray.length; k++) {
                  if (htmlUrlsArray[i] == this.linkArray[k].htmlLink) {
                    this.linkArray[k].clicks = this.linkArray[k].clicks + responseUrlDataArray[j].clicks;
                    this.linkArray[k].urlUniqueClicks = this.linkArray[k].urlUniqueClicks + responseUrlDataArray[j].urlUniqueClicks;
                    this.linkArray[k].per = isNaN(((parseFloat(this.linkArray[k].clicks) / parseFloat(this.totalClicks)) * 100)) ? 0 : ((parseFloat(this.linkArray[k].clicks) / parseFloat(this.totalClicks)) * 100).toFixed(2);
                    flag = true;
                  }
                }
                if (!flag) {
                  this.linkArray.push(responseUrlDataArray[j]);
                  let length = this.linkArray.length - 1;
                  this.linkArray[length].htmlLink = htmlUrlsArray[i];
                }
                responseUrlDataArray.splice(j, 1);
                // break;
              }
            }
          }
        }
      }
    }
    this.linkArray.sort((a, b) => (a.per > b.per ? -1 : 1));
    this.linkArray.forEach((link, index) => {
      if (link.url == '[OptInLink]') {
        var url = '[href="' + link.htmlLink + '"]';
        var lst = doc.querySelectorAll(url);
        if (lst == null || lst.length == 0) {
          var url = '[href="[ValidateLink]"]';
          var lst = doc.querySelectorAll(url);
        }
      } else if (link.url == '[ValidateLink]') {
        var url = '[href="' + link.htmlLink + '"]';
        var lst = doc.querySelectorAll(url);
        if (lst == null || lst.length == 0) {
          var url = '[href="[OptInLink]"]';
          var lst = doc.querySelectorAll(url);
        }
      } else {
        var url = '[href="' + link.htmlLink + '"]';
        var lst = doc.querySelectorAll(url);
      }
      if (lst != null && lst.length > 0) {
        lst.forEach((item) => {
          item.setAttribute('class', 'clicktTooltip');
          var spantag = doc.createElement('span');
          spantag.setAttribute('class', 'clickTooltiptext');
          spantag.textContent = link.clicks +' ('+Math.round(link.per)+')%';
          item.appendChild(spantag);
        })
      }
    })
    this.emailData = new XMLSerializer().serializeToString(doc);
  }
  showSummaryCard() {
    this.showSummary = !this.showSummary;
  }
  linkData(link) {
    var doc = new DOMParser().parseFromString(this.emailData, "text/html");
    var url = '[href="' + link + '"]';
    var el = doc.querySelector(url);
    this.linkParentElement = el.parentElement;
  }
  clickMapLinkData(item): void {
    var htmlLinkData = new XMLSerializer().serializeToString(this.linkParentElement);
    this.dialogRef = this._matDialog.open(ClickMapLinkComponent, {
      panelClass: 'click-map-link',
      disableClose: true,
      data: {
        CampaignId: this.campaignId,
        item: item,
        htmlLinkData: htmlLinkData,
        totalContacts: this.totalContacts,
        totalClicks: this.totalClicks,
        uniqueClicks: this.uniqueClicks
      }
    });
    this.dialogRef.afterClosed()
      .subscribe((response: any) => {

      });
  }
  clickMapLinkContactDetails(type): void {
    this.dialogRef = this._matDialog.open(ClickMapLinkDetailsComponent, {
        panelClass: 'clickMap-link-details',
        disableClose: true,
        data: {
          CampaignId: this.campaignId,
          isClickMapPage: true,
          totalContacts: this.totalContacts,
          type: type,
          htmlLink: null
        }
    });
    this.dialogRef.afterClosed()
        .subscribe((response: any) => {

    });
       
  }
  sortList() {
    if (this.clickMapLink.value == 0) {
      this.linkArray.sort((a, b) => (a.per > b.per ? -1 : 1));
    } else if (this.clickMapLink.value == 1) {
      this.linkArray.sort((a, b) => (a.per < b.per ? -1 : 1));
    }
  }
  closePanel(panel) {
    panel.expanded = false;
  }
  uniqueUrlList() {
    this.dialogRef = this._matDialog.open(UniqueClickLinkComponent, {
      panelClass: 'unique-click-link',
      disableClose: true,
      data: {
        linkArray: this.linkArray,
        CampaignId: this.campaignId,
      }
    });
    this.dialogRef.afterClosed()
      .subscribe((response: any) => {

      });
  }
}
