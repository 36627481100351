import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RestService } from '../../../shared/rest.service';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from '../pages.service';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService implements Resolve<any> {
  selectedCompanyName: string = "";
  approvedTemplates: any[] = [];
  onApprovedTemplatesChanged: BehaviorSubject<any>;
  learningMenuList: any[] = [];
  releaseNotesMenuList: any[]= [];
  onMenuChanged: BehaviorSubject<any>;
  onReleaseNotesMenuChanged: BehaviorSubject<any>;
  itemArray: any[] = [];
  isMasterType: boolean = false;
  deleteStatus: boolean = false;
  currentRoutParamData: any;
  onProviderAdd: BehaviorSubject<any>;
  onProviderAddCamp: BehaviorSubject<any>;
  onProviderAddTriggerEvent: BehaviorSubject<any>;
  onProviderAddTriggerPro:BehaviorSubject<any>;
  onProviderAddTempAll:BehaviorSubject<any>;
  onProviderJourneyReport: BehaviorSubject<any>;
  constructor(
    private _restService: RestService,
    private _authService: AuthService,
    private _pageService: PageService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _toastr: ToastrService
  ) {
    this.init();
  }
  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.init()
      ]).then(
        (res) => {
          resolve('init');
        },
        reject
      );
    });
  }
  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.selectedCompanyName = "";
      this.approvedTemplates = [];
      this.onApprovedTemplatesChanged = new BehaviorSubject([]);
      this.learningMenuList = [];
      this.releaseNotesMenuList = [];
      this.onMenuChanged = new BehaviorSubject([]);
      this.onReleaseNotesMenuChanged = new BehaviorSubject([]);
      this.onProviderAdd = new BehaviorSubject(false);
      this.onProviderAddCamp = new BehaviorSubject(false);
      this.onProviderAddTriggerEvent =new BehaviorSubject(false);
      this.onProviderAddTriggerPro =new BehaviorSubject(false);
      this.onProviderAddTempAll =new BehaviorSubject(false);
      this.onProviderJourneyReport = new BehaviorSubject(false);
      this.itemArray = [];
      this.isMasterType = false;
      resolve('initialized');
    });
  } 
  getProviders() {
    return this._restService.Get('/Company/GetProviders');
  }
  AddEditProvider(req: any) {
    return this._restService.Post("/Company/AddEditProvider", req);
  }
  deleteProvider(id: number) {
    return this._restService.Delete("/Company/DeleteProvider/" + id, null);
  }
  getProviderAccounts(req: any) {
    return this._restService.Post('/Company/GetAllCompany', req);
  }
  getCompanyDomains(id: number) {
    return this._restService.Get('/Company/GetAllDSDomains/' + id);
  }
  getApprovedTemplates(ProviderId, typeId): Promise<any> {
    return new Promise((resolve, reject) => {
      let api_url = '/Template/GetProviderTemplatesByType/' + ProviderId + '/' + typeId;
      if (!typeId) {
        api_url = '/Template/GetProviderTemplates/' + ProviderId;
      }
      this._fuseSplashScreenService.show();
      this._restService.Get(api_url)
        .subscribe((response: any) => {
          this._fuseSplashScreenService.hide();
          if (response.status == 200) {
            this.approvedTemplates = response.data;
            this.onApprovedTemplatesChanged.next(this.approvedTemplates);
            resolve(this.approvedTemplates);
          }
        },
          error => {
            this._fuseSplashScreenService.hide();
          },
          reject);
    }
    );
  }

  DeleteTemplate(id: number) {
    var _url = "/Template/DeleteTemplate/" + id;
    return this._restService.Delete(_url, null);
  }

  getTemplateById(id: number) {
    return this._restService.Get('/Template/GetTemplate/' + id);
  }

  SaveTemplate(req: any) {
    var _url = "/Template/AddEditTemplate";
    return this._restService.Post(_url, req);
  }
  addEditMenu(req: any, pageName:null) {
    if(pageName == 'releaseNotes'){
      var _url = '/ReleaseNotes/AddEditMenu';
    }else{
      var _url = '/LearningCenter/AddEditMenu';
    }
    return this._restService.Post(_url, req);
  }
  addEditCategory(req: any, pageName:null) {
    if(pageName == 'releaseNotes'){
      var _url = '/ReleaseNotes/AddEditCategory';
    }else{
      var _url = '/LearningCenter/AddEditCategory';
    }
    return this._restService.Post(_url, req);
  }
  getAllCategories(menuId) {
    return this._restService.Get('/LearningCenter/GetAllCategories/' + menuId);
  }
  getAllMenus(ProviderId) {
    this._fuseSplashScreenService.show();
    this._restService.Get('/LearningCenter/GetAllMenus/' + ProviderId)
      .subscribe((response: any) => {
        this._fuseSplashScreenService.hide();
        if (response.status == 200) {
          this.learningMenuList = response.data;
        }
        else {
          this.learningMenuList = [];
        }
        this.onMenuChanged.next(this.learningMenuList);
      },
        error => {
          this._fuseSplashScreenService.hide();
        }
      );
  }
  getAllReleaseNotesMenus(ProviderId){
    this._fuseSplashScreenService.show();
    this._restService.Get('/ReleaseNotes/GetAllMenus/' + ProviderId)
      .subscribe((response: any) => {
        this._fuseSplashScreenService.hide();
        if (response.status == 200) {
          this.releaseNotesMenuList = response.data;
        }
        else {
          this.releaseNotesMenuList = [];
        }
        this.onReleaseNotesMenuChanged.next(this.releaseNotesMenuList);
      },
        error => {
          this._fuseSplashScreenService.hide();
        }
      );
  }
  DeleteCategory(id, pageName) {
    if(pageName == 'releaseNotes'){
      var _url = '/ReleaseNotes/DeleteCategory/'+id;
    }else{
      var _url = '/LearningCenter/DeleteCategory/'+id;
    }
    return this._restService.Delete(_url, null);
  }
  GetFiles(req: any, pageName) {
    if(pageName =='releaseNotes'){
      var _url = '/ReleaseNotes/GetFiles';
    }else{
      var _url = '/LearningCenter/GetFiles';
    }
    return this._restService.Post(_url, req);
  }
  UploadMediaFile(data, pageName = null) {
    if(pageName =='releaseNotes'){
      var _url = '/ReleaseNotes/UploadMediaFile';
    }else{
      var _url = '/LearningCenter/UploadMediaFile';
    }
    return this._restService.PostFile(_url, data);
  }
  AddEditFile(req: any, pageName) {
    if(pageName =='releaseNotes'){
          var _url = '/ReleaseNotes/AddEditFile';
    }else{
          var _url = '/LearningCenter/AddEditFile';
    }
    return this._restService.Post(_url, req);
  }
  DeleteVideo(id, pageName: any) {
    if(pageName =='releaseNotes'){
      var _url = '/ReleaseNotes/DeleteFile/'+id;
    }else{
      var _url = '/LearningCenter/DeleteFile/'+id;
    }
    return this._restService.Delete(_url, null);
  }
  DeleteMenu(id, pageName: null) {
    if(pageName == 'releaseNotes'){
      var _url = '/ReleaseNotes/DeleteMenu/'+id;
    }else{
      var _url = '/LearningCenter/DeleteMenu/'+id;
    }
    return this._restService.Delete(_url, null);
  }
  getDomainById(id: any) {
    return this._restService.Get('/Company/GetDSDomainById/' + id)
  }
  saveDomain(CompanyId: number, req: any) {
    return this._restService.Post("/Company/CreateDSDomain/" + CompanyId, req);
  }
  editDomain(CompanyId: number, req: any) {
    return this._restService.Post("/Company/UpdateDSDomain/" + CompanyId, req);
  }
  deleteDomain(id: number) {
    return this._restService.Delete("/Company/DeleteDSDomain/" + id, null);
  }
  UpdateCompanyApimSubscriptionKey(CompanyId: number, key:string, isActive: boolean) {
      return this._restService.Get("/Company/UpdateCompanyApimSubscriptionKey/" + CompanyId + '/' + key + '?IsActivePreferenceCheck=' + isActive);
  }
  GetCompanyApimSubscriptionKey(CompanyId: number) {
    return this._restService.Get("/Company/GetCompanyApimSubscriptionKey/" + CompanyId);
  }
  GetZiplingoAPISettings(companyKey: string) {
    return this._restService.Get("/Company/GetZiplingoAPISettings?companyKey=" + companyKey);
  }
  UpdateZiplingoSettings(companyKey: string, req: any) {
    return this._restService.Post("/Company/UpdateZiplingoSettings?companyKey=" + companyKey, req);
  }
  GetAllDSNotificationAccounts(CompanyId: number) {
    return this._restService.Get("/Company/GetAllDSNotificationAccounts/" + CompanyId);
  }
  GetDSNotificationAccountById(Id: number) {
    return this._restService.Get("/Company/GetDSNotificationAccountById/" + Id);
  }
  CreateDSNotificationAccount(CompanyId: number, req: any) {
    return this._restService.Post("/Company/CreateDSNotificationAccount/"  + CompanyId, req);
  }
  UpdateDSNotificationAccount(CompanyId: number, req: any) {
    return this._restService.Post("/Company/UpdateDSNotificationAccount/"  + CompanyId, req);
  }

  DeleteDSNotificationAccount(id: number) {
    return this._restService.Delete("/Company/DeleteDSNotificationAccount/" + id, null);
  }

  GetAllDSMessagingAccounts(CompanyId: number) {
    return this._restService.Get("/Company/GetAllDSMessagingAccounts/" + CompanyId);
  }
  GetDSMessagingAccountById(Id: number) {
    return this._restService.Get("/Company/GetDSMessagingAccountById/" + Id);
  }
  CreateDSMessagingAccount(CompanyId: number, req: any) {
    return this._restService.Post("/Company/CreateDSMessagingAccount/"  + CompanyId, req);
  }
  UpdateDSMessagingAccount(CompanyId: number, req: any) {
    return this._restService.Post("/Company/UpdateDSMessagingAccount/"  + CompanyId, req);
  }

  DeleteDSMessagingAccount(id: number) {
    return this._restService.Delete("/Company/DeleteDSMessagingAccount/" + id, null);
  }
  GetLinkedMessageList() {
    return this._restService.Get("/Template/GetLinkedMessageList");
  }
  deleteLink(id) {
    return this._restService.Delete("/Template/DeleteLinkedMessage/" + id, null);
  }
  GetPreDefineTemplates(menuId?) {
    let menuID = (menuId !== null && menuId !== undefined && menuId !== '') ? menuId : 0;
    return this._restService.Get("/Template/GetProviderTemplates/" + 0 + "?MenuId="+menuID);
  }
}
