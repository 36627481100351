import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment-timezone";

const timeZoneList = [
    { key: "W. Central Africa Standard Time", value: "Africa/Bangui" },
    { key:"Egypt Standard Time", value:"Africa/Cairo" },
    { key:"Morocco Standard Time", value:"Africa/Casablanca" },
    { key:"South Africa Standard Time", value:"Africa/Harare" },
    { key:"South Africa Standard Time", value:"Africa/Johannesburg" },
    { key:"W. Central Africa Standard Time", value:"Africa/Lagos" },
    { key:"Greenwich Standard Time", value:"Africa/Monrovia" },
    { key:"E. Africa Standard Time", value:"Africa/Nairobi" },
    { key:"Namibia Standard Time", value:"Africa/Windhoek" },
    { key:"Alaskan Standard Time", value:"America/Anchorage" },
    { key:"Argentina Standard Time", value:"America/Argentina/San_Juan" },
    { key:"Paraguay Standard Time", value:"America/Asuncion" },
    { key:"Bahia Standard Time", value:"America/Bahia" },
    { key:"SA Pacific Standard Time", value:"America/Bogota" },
    { key:"Argentina Standard Time", value:"America/Buenos_Aires" },
    { key:"Venezuela Standard Time", value:"America/Caracas" },
    { key:"SA Eastern Standard Time", value:"America/Cayenne" },
    { key:"Central Standard Time", value:"America/Chicago" },
    { key:"Mountain Standard Time (Mexico)", value:"America/Chihuahua" },
    { key:"Central Brazilian Standard Time", value:"America/Cuiaba" },
    { key:"Mountain Standard Time", value:"America/Denver"},
    { key:"SA Eastern Standard Time", value:"America/Fortaleza"},
    { key:"Greenland Standard Time", value:"America/Godthab" },
    { key:"Central America Standard Time", value:"America/Guatemala" },
    { key:"Atlantic Standard Time", value:"America/Halifax" },
    { key:"US Eastern Standard Time", value:"America/Indianapolis" },
    { key:"US Eastern Standard Time", value:"America/Indiana/Indianapolis" },
    { key:"SA Western Standard Time", value:"America/La_Paz" },
    { key:"Pacific Standard Time", value:"America/Los_Angeles" },
    { key:"Mexico Standard Time", value:"America/Mexico_City" },
    { key:"Montevideo Standard Time", value:"America/Montevideo" },
    { key:"Eastern Standard Time", value:"America/New_York" },
    { key:"UTC-02", value:"America/Noronha" },
    { key:"US Mountain Standard Time", value:"America/Phoenix" },
    { key:"Canada Central Standard Time", value:"America/Regina" },
    { key:"Pacific Standard Time (Mexico)", value:"America/Santa_Isabel" },
    { key:"Pacific SA Standard Time", value:"America/Santiago" },
    { key:"E. South America Standard Time", value:"America/Sao_Paulo" },
    { key:"Newfoundland Standard Time", value:"America/St_Johns" },
    { key:"Pacific Standard Time", value:"America/Tijuana" },
    { key:"New Zealand Standard Time", value:"Antarctica/McMurdo" },
    { key:"UTC-02", value:"Atlantic/South_Georgia" },
    { key:"Central Asia Standard Time", value:"Asia/Almaty" },
    { key:"Jordan Standard Time", value:"Asia/Amman" },
    { key:"Arabic Standard Time", value:"Asia/Baghdad" },
    { key:"Azerbaijan Standard Time", value:"Asia/Baku" },
    { key:"SE Asia Standard Time", value:"Asia/Bangkok" },
    { key:"Middle East Standard Time", value:"Asia/Beirut" },
    { key:"India Standard Time", value:"Asia/Calcutta" },
    { key:"Sri Lanka Standard Time", value:"Asia/Colombo" },
    { key:"Syria Standard Time", value:"Asia/Damascus" },
    { key:"Bangladesh Standard Time", value:"Asia/Dhaka" },
    { key:"Arabian Standard Time", value:"Asia/Dubai" },
    { key:"North Asia East Standard Time", value:"Asia/Irkutsk" },
    { key:"Israel Standard Time", value:"Asia/Jerusalem" },
    { key:"Afghanistan Standard Time", value:"Asia/Kabul" },
    { key:"Kamchatka Standard Time", value:"Asia/Kamchatka" },
    { key:"Pakistan Standard Time", value:"Asia/Karachi" },
    { key:"Nepal Standard Time", value:"Asia/Katmandu" },
    { key:"India Standard Time", value:"Asia/Kolkata" },
    { key:"North Asia Standard Time", value:"Asia/Krasnoyarsk" },
    { key:"Singapore Standard Time", value:"Asia/Kuala_Lumpur" },
    { key:"Arab Standard Time", value:"Asia/Kuwait" },
    { key:"Magadan Standard Time", value:"Asia/Magadan" },
    { key:"Arabian Standard Time", value:"Asia/Muscat" },
    { key:"N. Central Asia Standard Time", value:"Asia/Novosibirsk" },
    { key:"West Asia Standard Time", value:"Asia/Oral" },
    { key:"Myanmar Standard Time", value:"Asia/Rangoon" },
    { key:"Arab Standard Time", value:"Asia/Riyadh" },
    { key:"Korea Standard Time", value:"Asia/Seoul" },
    { key:"China Standard Time", value:"Asia/Shanghai" },
    { key:"Singapore Standard Time", value:"Asia/Singapore" },
    { key:"Taipei Standard Time", value:"Asia/Taipei" },
    { key:"West Asia Standard Time", value:"Asia/Tashkent" },
    { key:"Georgian Standard Time", value:"Asia/Tbilisi" },
    { key:"Iran Standard Time", value:"Asia/Tehran" },
    { key:"Tokyo Standard Time", value:"Asia/Tokyo" },
    { key:"Ulaanbaatar Standard Time", value:"Asia/Ulaanbaatar" },
    { key:"Vladivostok Standard Time", value:"Asia/Vladivostok" },
    { key:"Yakutsk Standard Time", value:"Asia/Yakutsk" },
    { key:"Ekaterinburg Standard Time", value:"Asia/Yekaterinburg" },
    { key:"Armenian Standard Time", value:"Asia/Yerevan" },
    { key:"Azores Standard Time", value:"Atlantic/Azores" },
    { key:"Cape Verde Standard Time", value:"Atlantic/Cape_Verde" },
    { key:"Greenwich Standard Time", value:"Atlantic/Reykjavik" },
    { key:"Cen. Australia Standard Time", value:"Australia/Adelaide" },
    { key:"E. Australia Standard Time", value:"Australia/Brisbane" },
    { key:"AUS Central Standard Time", value:"Australia/Darwin" },
    { key:"Tasmania Standard Time", value:"Australia/Hobart" },
    { key:"W. Australia Standard Time", value:"Australia/Perth" },
    { key:"AUS Eastern Standard Time", value:"Australia/Sydney" },
    { key:"UTC", value:"Etc/GMT" },
    { key:"UTC-11", value:"Etc/GMT+11" },
    { key:"Dateline Standard Time", value:"Etc/GMT+12" },
    { key:"UTC-02", value:"Etc/GMT+2" },
    { key:"UTC+12", value:"Etc/GMT-12" },
    { key:"W. Europe Standard Time", value:"Europe/Amsterdam" },
    { key:"GTB Standard Time", value:"Europe/Athens" },
    { key:"Central Europe Standard Time", value:"Europe/Belgrade" },
    { key:"W. Europe Standard Time", value:"Europe/Berlin" },
    { key:"Romance Standard Time", value:"Europe/Brussels" },
    { key:"Central Europe Standard Time", value:"Europe/Budapest" },
    { key:"GMT Standard Time", value:"Europe/Dublin" },
    { key:"FLE Standard Time", value:"Europe/Helsinki" },
    { key:"GTB Standard Time", value:"Europe/Istanbul" },
    { key:"FLE Standard Time", value:"Europe/Kiev" },
    { key:"GMT Standard Time", value:"Europe/London" },
    { key:"E. Europe Standard Time", value:"Europe/Minsk" },
    { key:"Russian Standard Time", value:"Europe/Moscow" },
    { key:"Romance Standard Time", value:"Europe/Paris" },
    { key:"Central European Standard Time", value:"Europe/Sarajevo" },
    { key:"Central European Standard Time", value:"Europe/Warsaw" },
    { key:"Mauritius Standard Time", value:"Indian/Mauritius" },
    { key:"Samoa Standard Time", value:"Pacific/Apia" },
    { key:"New Zealand Standard Time", value:"Pacific/Auckland" },
    { key:"Fiji Standard Time", value:"Pacific/Fiji" },
    { key:"Central Pacific Standard Time", value:"Pacific/Guadalcanal" },
    { key:"West Pacific Standard Time", value:"Pacific/Guam" },
    { key:"Hawaiian Standard Time", value:"Pacific/Honolulu" },
    { key:"UTC-11", value:"Pacific/Pago_Pago" },
    { key:"West Pacific Standard Time", value:"Pacific/Port_Moresby" },
    { key:"Tonga Standard Time", value:"Pacific/Tongatapu" }
]

@Pipe({ name: 'convertToTimezone' })

export class ConvertToTimezone implements PipeTransform {
    transform(value: string, format: string, TimeZoneData: string): any {
        var val;
        if(value && TimeZoneData != undefined && TimeZoneData != null) {
            timeZoneList.forEach(item => {
                if(item.key == TimeZoneData) {
                    // val = moment.utc(value).tz(item.value).format(format)+ ' ' + moment().tz(item.value).zoneAbbr();
                    val = moment.utc(value).tz(item.value).format(format);
                    return;
                }
            });
            return val;
        }
    }
}
