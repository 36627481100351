import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FuseUtils } from '@fuse/utils';
import { Contact } from 'app/main/pages/contacts/contact.model';
import { RestService } from '../../../shared/rest.service';
import { AuthService } from 'app/shared/auth.service';
import { PageService } from '../pages.service';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { API_URL } from 'app/constants';
import { map } from 'rxjs/operators';
import { ProjectDashboardService } from '../dashboard/project.service';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ContactsService implements Resolve<any>
{
    userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
    isAdminRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'user' || this.userDetails.roles[0] == 'administrator');
    isCompanyRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'company');
    isAgentRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
    onContactsChanged: BehaviorSubject<any>;
    onContactsLengthChanged: BehaviorSubject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    onGroupChanged: Subject<any>;
    onGroupSelection: Subject<any>;
    onResetContactList: Subject<any>;
    onFilterChanged: Subject<any>;
    showCountChanged: Subject<any>;
    contacts: Contact[];
    selectedContacts: string[] = [];
    resyncEmit: BehaviorSubject<any>;
    contactColumns: any[] = [];
    onContactColumnsChanged: BehaviorSubject<any>;

    searchText: string;
    searchInteger: any;
    statusFilter: any;
    filterBy: string;
    filterByType: any = 0;
    filterByGroup: any = 0;
    contactsPageNumber: number = 1;
    contactsPageSize: number = 100;
    contactsTotalCount: number = 0;
    assignToGroupId: number = 0;
    appledFilters: BehaviorSubject<any>;
    onResponseChange: BehaviorSubject<any>;
    userApicall: BehaviorSubject<any>;
    sortBy: string;
    sortDirection: string;
    associateStatus: any;
    constructor(
        private _restService: RestService,
        private _authService: AuthService,
        private _pageService: PageService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _toastr: ToastrService,
        private _http: HttpClient,
        private _projectDashboardService: ProjectDashboardService,
    ) {
        this.onContactsChanged = new BehaviorSubject([]);
        this.onResponseChange = new BehaviorSubject({});
        this.onContactsLengthChanged = new BehaviorSubject(null);
        this.onSelectedContactsChanged = new BehaviorSubject([]);
        this.onContactColumnsChanged = new BehaviorSubject([]);
        this.userApicall = new BehaviorSubject(false);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.init()
            ]).then(
                (res) => {
                    resolve('init');
                },
                reject
            );
        });
    }

    init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
            this.isAdminRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'user' || this.userDetails.roles[0] == 'administrator');
            this.isCompanyRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'company');
            this.isAgentRole = this.userDetails && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
            this.filterByType = 0;
            this.searchText = localStorage.getItem('ContactSearchText') ? localStorage.getItem('ContactSearchText') : '';
            this.searchInteger = localStorage.getItem('ContactSearchAssociateId') ? localStorage.getItem ('ContactSearchAssociateId') : 0;
            this.statusFilter = [];
            this.contactsPageNumber = 1
            this.contactsPageSize = 100;
            this.contactsTotalCount = 0;
            this.filterByGroup = 0;
            this.assignToGroupId = 0;
            this.onContactsChanged = new BehaviorSubject([]);
            this.onContactsLengthChanged = new BehaviorSubject(null);
            this.onSelectedContactsChanged = new BehaviorSubject([]);
            this.onContactColumnsChanged = new BehaviorSubject([]);
            this.onFilterChanged = new Subject();
            this.onGroupChanged = new Subject();
            this.onGroupSelection = new Subject();
            this.onResetContactList = new Subject();
            this.showCountChanged = new Subject();
            this.appledFilters = new BehaviorSubject<any>(null);
            this.resyncEmit = new BehaviorSubject<any>(null);
            this.onResponseChange = new BehaviorSubject({});
            this.userApicall = new BehaviorSubject<any>(false);
            this.deselectContacts();
            this._pageService.getCurrentPage(false);
            this._pageService.getAccountsTypesByUser();
            this.associateStatus = this._pageService.getAssociateStatus();
            resolve('initialized');
        });
    }



    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts() {
        let contactReq: any = {
            unifiedTypeId: this.filterByType,
            groupId: this.filterByGroup,
            searchText: this.searchText,
            sortBy:this.sortBy,
            sortDirection:this.sortDirection,
            associateId: this.searchInteger != null && parseInt(this.searchInteger) > 0 ? parseInt(this.searchInteger) : 0,
            pageNumber: this.contactsPageNumber,
            pageSize: this.contactsPageSize,
            paidAsRanks: this.appledFilters.value ? this.appledFilters.value.paidAsRanks : [],
            highestRanks: this.appledFilters.value ? this.appledFilters.value.highestRanks : [],
            countries: this.appledFilters.value ? this.appledFilters.value.countries : [],
            states: this.appledFilters.value && this.appledFilters.value.countries && this.appledFilters.value.countries.includes(223) ? this.appledFilters.value.states : [],
            associateTypes: this.appledFilters.value ? this.appledFilters.value.associateTypes : [],
            associateStatus: this.appledFilters.value ? this.appledFilters.value.associateStatus : [],
            languageCodes: this.appledFilters.value ? this.appledFilters.value.languageCodes : [],
            optFilter: this.appledFilters.value ? this.appledFilters.value.optFilter : [],
            filterIds: this.appledFilters.value ? this.appledFilters.value.invalidData : this.statusFilter,
            fromDate: this.appledFilters.value ? this.appledFilters.value.fromDate : '',
            toDate: this.appledFilters.value ? this.appledFilters.value.toDate : '',
            groupRangeFrom: this.appledFilters.value ? this.appledFilters.value.groupRangeFrom : 0,
            groupRangeTo: this.appledFilters.value ? this.appledFilters.value.groupRangeTo : 0,
            hasActiveAutoship: this.appledFilters.value ? this.appledFilters.value.hasActiveAutoship : null,
            channels: this.appledFilters.value && this.appledFilters.value.channels ? this.appledFilters.value.channels : [],
            customFields: this.appledFilters.value ? this.appledFilters.value.customFields : null,
            assignToGroupId: this.assignToGroupId ? this.assignToGroupId : 0,
            customFieldsCompare: this.appledFilters.value ? this.appledFilters.value.customFieldsCompare : null,
            sponsorId: this.appledFilters.value && this.appledFilters.value.sponsorId ? this.appledFilters.value.sponsorId : 0
        }
        if(this.searchText == '' && (this.searchInteger == 0 || this.searchInteger == '' || this.searchInteger == null || this.searchInteger == 'null') && this.appledFilters.value == null && this.filterByGroup == 0) {
            this.contacts = [];
            this.onResetContactList.next(true);
            this.onContactsChanged.next(this.contacts);
            this.getContactsLength();
            return false;
        }
        this._fuseSplashScreenService.show();
        this._restService.Post('/Contact/GetAllContacts?IsAgent=' + this._pageService.isAgentView, contactReq)
            .subscribe((response: any) => {
                this.onResponseChange.next(response);
                this._fuseSplashScreenService.hide();
                if (response.status == 200) {
                    response.data.report.forEach((obj: any) => {
                        var found = _.find((this.associateStatus.value && this.associateStatus.value.data), function (t) { return t.statusId == obj.associateStatus });
                        if (found) {
                            obj.statusName = found.statusName;
                        }
                    });
                    this.contacts = response.data.report;
                    this.contactsTotalCount = response.data.totalCount;                    
                    if(this.contactsPageNumber == 1 && !(this.contactsPageSize > response.data.report.length)){
                        this.getContactsLength();
                    }
                    if(this.contactsPageSize >= response.data.report.length) {
                        this.showCountChanged.next(false);
                        this.contactsTotalCount = response.data.report.length;
                        this.onContactsLengthChanged.next(this.contactsTotalCount);
                    }
                    this.onContactsChanged.next(this.contacts);
                }
                else {
                    this.contacts = [];
                    this.contactsTotalCount = 0;
                    this.onContactsChanged.next(this.contacts);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this.contacts = [];
                    this.contactsTotalCount = 0;
                    this.onContactsChanged.next(this.contacts);
                });
    }
    getContactsCSV(response) {
        let contactReq: any = {
            unifiedTypeId: this.filterByType,
            groupId: this.filterByGroup,
            searchText: this.searchText,
            associateId: this.searchInteger != null && parseInt(this.searchInteger) > 0 ? parseInt(this.searchInteger) : 0,
            pageNumber: response.pageNumber,
            pageSize: response.pageSize,
            paidAsRanks: this.appledFilters.value ? this.appledFilters.value.paidAsRanks : [],
            highestRanks: this.appledFilters.value ? this.appledFilters.value.highestRanks : [],
            countries: this.appledFilters.value ? this.appledFilters.value.countries : [],
            states: this.appledFilters.value && this.appledFilters.value.countries && this.appledFilters.value.countries.includes(223) ? this.appledFilters.value.states : [],
            associateTypes: this.appledFilters.value ? this.appledFilters.value.associateTypes : [],
            associateStatus: this.appledFilters.value ? this.appledFilters.value.associateStatus : [],
            languageCodes: this.appledFilters.value ? this.appledFilters.value.languageCodes : [],
            optFilter: this.appledFilters.value ? this.appledFilters.value.optFilter : [],
            filterIds: this.appledFilters.value ? this.appledFilters.value.invalidData : this.statusFilter,
            fromDate: this.appledFilters.value ? this.appledFilters.value.fromDate : '',
            toDate: this.appledFilters.value ? this.appledFilters.value.toDate : '',
            groupRangeFrom: this.appledFilters.value ? this.appledFilters.value.groupRangeFrom : 0,
            groupRangeTo: this.appledFilters.value ? this.appledFilters.value.groupRangeTo : 0,
            hasActiveAutoship: this.appledFilters.value ? this.appledFilters.value.hasActiveAutoship : null,
            channels: this.appledFilters.value && this.appledFilters.value.channels ? this.appledFilters.value.channels : [],
            customFields: this.appledFilters.value ? this.appledFilters.value.customFields : null,
            customFieldsCompare: this.appledFilters.value ? this.appledFilters.value.customFieldsCompare : null,
            sponsorId: this.appledFilters.value ? this.appledFilters.value.sponsorId : 0
        }
        this._fuseSplashScreenService.show();
        this._restService.Postblob('/Contact/GetAllContacts?export=' + true, contactReq)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hide();
                var blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
                this._projectDashboardService.saveFile(blob, "ContactDetails.csv");
            },
            error => {
                this._fuseSplashScreenService.hide();
                this._toastr.error(error.message, 'Error');
                });
    }
    getContactsLength() {
        let contactReq: any = {
            unifiedTypeId: this.filterByType,
            groupId: this.filterByGroup,
            searchText: this.searchText,
            associateId: this.searchInteger != null && parseInt(this.searchInteger) > 0 ? parseInt(this.searchInteger) : 0,
            pageNumber: this.contactsPageNumber,
            pageSize: this.contactsPageSize,
            paidAsRanks: this.appledFilters.value ? this.appledFilters.value.paidAsRanks : [],
            highestRanks: this.appledFilters.value ? this.appledFilters.value.highestRanks : [],
            countries: this.appledFilters.value ? this.appledFilters.value.countries : [],
            states: this.appledFilters.value && this.appledFilters.value.countries && this.appledFilters.value.countries.includes(223) ? this.appledFilters.value.states : [],
            associateTypes: this.appledFilters.value ? this.appledFilters.value.associateTypes : [],
            associateStatus: this.appledFilters.value ? this.appledFilters.value.associateStatus : [],
            languageCodes: this.appledFilters.value ? this.appledFilters.value.languageCodes : [],
            optFilter: this.appledFilters.value ? this.appledFilters.value.optFilter : [],
            fromDate: this.appledFilters.value ? this.appledFilters.value.fromDate : '',
            toDate: this.appledFilters.value ? this.appledFilters.value.toDate : '',
            groupRangeFrom: this.appledFilters.value ? this.appledFilters.value.groupRangeFrom : 0,
            groupRangeTo: this.appledFilters.value ? this.appledFilters.value.groupRangeTo : 0,
            hasActiveAutoship: this.appledFilters.value ? this.appledFilters.value.hasActiveAutoship : null,
            channels: this.appledFilters.value && this.appledFilters.value.channels ? this.appledFilters.value.channels : [],
            filterIds: this.appledFilters.value ? this.appledFilters.value.invalidData : this.statusFilter,
            customFields: this.appledFilters.value ? this.appledFilters.value.customFields : null,
            customFieldsCompare: this.appledFilters.value ? this.appledFilters.value.customFieldsCompare : null,
            sponsorId: this.appledFilters.value ? this.appledFilters.value.sponsorId : 0
        }
        this.showCountChanged.next(true);
        this._restService.Post('/Contact/GetAllContactsCount?IsAgent=' + this._pageService.isAgentView, contactReq)
            .subscribe((response: any) => {
                this.showCountChanged.next(false);
                if (response.status == 200) {
                    this.contactsTotalCount = response.data;
                    this.onContactsLengthChanged.next(this.contactsTotalCount);
                }
                else {
                    this.contactsTotalCount = 0;
                    this.onContactsLengthChanged.next(this.contactsTotalCount);
                }
            },
                error => {
                    this.showCountChanged.next(false);
                    this.contactsTotalCount = 0;
                    this.onContactsLengthChanged.next(this.contactsTotalCount);
                });
    }

    exportContacts(responseData) {
        let contactReq: any = {
            unifiedTypeId: this.filterByType,
            groupId: this.filterByGroup,
            searchText: this.searchText,
            associateId: this.searchInteger != null && parseInt(this.searchInteger) > 0 ? parseInt(this.searchInteger) : 0,
            pageNumber: (responseData && responseData.pageNumber) ? responseData.pageNumber : this.contactsPageNumber,
            pageSize: (responseData && responseData.pageSize) ? responseData.pageSize : this.contactsPageSize,
            paidAsRanks: this.appledFilters.value ? this.appledFilters.value.paidAsRanks : [],
            highestRanks: this.appledFilters.value ? this.appledFilters.value.highestRanks : [],
            countries: this.appledFilters.value ? this.appledFilters.value.countries : [],            
            states: this.appledFilters.value && this.appledFilters.value.countries && this.appledFilters.value.countries.includes(223) ? this.appledFilters.value.states : [],
            associateTypes: this.appledFilters.value ? this.appledFilters.value.associateTypes : [],
            associateStatus: this.appledFilters.value ? this.appledFilters.value.associateStatus : [],
            languageCodes: this.appledFilters.value ? this.appledFilters.value.languageCodes : [],
            optFilter: this.appledFilters.value ? this.appledFilters.value.optFilter : [],
            fromDate: this.appledFilters.value ? this.appledFilters.value.fromDate : '',
            toDate: this.appledFilters.value ? this.appledFilters.value.toDate : '',
            groupRangeFrom: this.appledFilters.value ? this.appledFilters.value.groupRangeFrom : 0,
            groupRangeTo: this.appledFilters.value ? this.appledFilters.value.groupRangeTo : 0,
            hasActiveAutoship: this.appledFilters.value ? this.appledFilters.value.hasActiveAutoship : null,
            channels: this.appledFilters.value && this.appledFilters.value.channels ? this.appledFilters.value.channels : [],
            filterIds: this.appledFilters.value ? this.appledFilters.value.invalidData : this.statusFilter,
            customFields: this.appledFilters.value ? this.appledFilters.value.customFields : null,
            customFieldsCompare: this.appledFilters.value ? this.appledFilters.value.customFieldsCompare : null,
            sponsorId: this.appledFilters.value ? this.appledFilters.value.sponsorId : 0
        }
        return this._restService.Postblob('/Contact/GetAllContacts?export=true&IsAgent=' + this._pageService.isAgentView, contactReq);
    }

    verifyContacts() {
        let verifyContactsReq: any = {
            unifiedTypeId: this.filterByType,
            groupId: this.filterByGroup,
            searchText: this.searchText,
            associateId: this.searchInteger != null && parseInt(this.searchInteger) > 0 ? parseInt(this.searchInteger) : 0,
            pageNumber: this.contactsPageNumber,
            pageSize: this.contactsPageSize,
            paidAsRanks: this.appledFilters.value ? this.appledFilters.value.paidAsRanks : [],
            highestRanks: this.appledFilters.value ? this.appledFilters.value.highestRanks : [],
            countries: this.appledFilters.value ? this.appledFilters.value.countries : [],
            states: this.appledFilters.value && this.appledFilters.value.countries && this.appledFilters.value.countries.includes(223) ? this.appledFilters.value.states : [],
            associateTypes: this.appledFilters.value ? this.appledFilters.value.associateTypes : [],
            associateStatus: this.appledFilters.value ? this.appledFilters.value.associateStatus : [],
            languageCodes: this.appledFilters.value ? this.appledFilters.value.languageCodes : [],
            optFilter: this.appledFilters.value ? this.appledFilters.value.optFilter : [],
            filterIds: this.appledFilters.value ? this.appledFilters.value.invalidData : this.statusFilter,
            fromDate: this.appledFilters.value ? this.appledFilters.value.fromDate : '',
            toDate: this.appledFilters.value ? this.appledFilters.value.toDate : '',
            groupRangeFrom: this.appledFilters.value ? this.appledFilters.value.groupRangeFrom : 0,
            groupRangeTo: this.appledFilters.value ? this.appledFilters.value.groupRangeTo : 0,
            hasActiveAutoship: this.appledFilters.value ? this.appledFilters.value.hasActiveAutoship : null,
            channels: this.appledFilters.value && this.appledFilters.value.channels ? this.appledFilters.value.channels : [],
            customFields: this.appledFilters.value ? this.appledFilters.value.customFields : null,
            customFieldsCompare: this.appledFilters.value ? this.appledFilters.value.customFieldsCompare : null,
            sponsorId: this.appledFilters.value ? this.appledFilters.value.sponsorId : 0
        }
        this._fuseSplashScreenService.show();
        this._restService.Post('/Contact/VerifyContactsByFilter', verifyContactsReq)
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hide();
                if (response.status == 200) {
                    this._toastr.success("Contacts Verification Started", 'Success');
                }
                else {
                    this._toastr.error(response.error, 'Error');
                }
            },
                error => {
                    this._toastr.error(error.message, 'Error');
                    this._fuseSplashScreenService.hide();
                });
    }


    /**
     * Toggle selected contact by id
     *
     * @param id
     */
    toggleSelectedContact(id): void {
        // First, check if we already have that contact as selected...
        if (this.selectedContacts.length > 0) {
            const index = this.selectedContacts.indexOf(id);

            if (index !== -1) {
                this.selectedContacts.splice(index, 1);

                // Trigger the next event
                this.onSelectedContactsChanged.next(this.selectedContacts);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedContacts.push(id);

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedContacts.length > 0) {
            this.deselectContacts();
        }
        else {
            this.selectContacts();
        }
    }

    /**
     * Select contacts
     *
     * @param filterParameter
     * @param filterValue
     */
    selectContacts(filterParameter?, filterValue?): void {
        this.selectedContacts = [];

        // If there is no filter, select all contacts
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedContacts = [];
            this.contacts.map(contact => {
                this.selectedContacts.push(contact.id);
            });
        }

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Deselect contacts
     */
    deselectContacts(): void {
        this.selectedContacts = [];

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    saveContact(ContactsRequest: any) {
        var _url = "/Contact/SetContact";
        return this._restService.Post(_url, ContactsRequest);
    }
    saveGroup(GroupRequest: any) {
        var _url = "/Group/SetGroup";
        return this._restService.Post(_url, GroupRequest);
    }
    GenerateSignUpURL(GroupRequest: any) {
        var _url = "/Group/GenerateGroupURL";
        return this._restService.Post(_url, GroupRequest);
    }
    UpdateGroup(GroupRequest: any) {
        var _url = "/Group/UpdateGroup";
        return this._restService.Post(_url, GroupRequest);
    }
    deleteGroup(id) {
        var _url = "/Group/DeleteGroup/" + id;
        return this._restService.Delete(_url, null);
    }
    editContact(ContactsRequest: any) {
        var _url = "/Contact/UpdateContact";
        return this._restService.Post(_url, ContactsRequest);
    }
    getContactsById(id: any) {
        return this._restService.Get('/Contact/GetContact/' + id)
    }
    /**
     * Delete contact
     *
     * @param contact
     */
    deleteContact(contactIds) {
        var _url = "/Contact/DeleteContacts";
        return this._restService.Delete(_url, contactIds);
    }
    deleteSelectedContacts() {
        var _url = "/Contact/DeleteContacts";
        return this._restService.Delete(_url, this.selectedContacts);
    }
    removeContactsFromGroup(GroupId, ContactIds) {
        var _url = "/Group/RemoveContactsFromGroup/" + GroupId;
        return this._restService.Post(_url, ContactIds);
    }
    removeFilteredContacts(req: any) {
        var _url = "/Contact/DeleteContactsByFilter";
        return this._restService.Post(_url, req);
    }
    verifySelectedContacts() {
        var _url = "/Contact/VerifyContactsByIds";
        return this._restService.Post(_url, this.selectedContacts);
    }
    verifyContact(id: number) {
        var _url = "/Contact/VerifyContactsByIds";
        return this._restService.Post(_url, [id]);
    }

    uploadImage(data) {
        var _url = "/Contact/UploadImage";
        return this._restService.PostFile(_url, data);
    }
    GetGroups() {
        return this._restService.Get('/Group/GetGroups');
    }

    getAllGroups() {
        return this._restService.Get('/Group/GetGroupsNew');
    }
    getGroupById(id: any) {
        return this._restService.Get('/Group/GetGroup/' + id);
    }
    getRecentMessages(GetMessagelogsrequest) {
        return this._restService.Post('/Inbox/GetMessageDetailsNew', GetMessagelogsrequest);
    }
    saveNotes(notesReq: any) {
        var _url = "/Contact/SetNotes";
        return this._restService.Post(_url, notesReq);
    }
    getNotes(contactId: any) {
        return this._restService.Get('/Contact/GetNotes/' + contactId);
    }
    UpdateOptoutByTypeId(ContactId, UnifiedTypeId, optout) {
        return this._restService.Get('/Inbox/UpdateOptoutByContactAndTypeId?ContactId=' + ContactId + '&UnifiedTypeId=' + UnifiedTypeId + '&optout=' + optout);
    }

    importContacts(formData: any): Observable<object> {
        const apiUrl = API_URL;
        var url = apiUrl + '/Contact/ContactUploadCSV';
        const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
        return this._http
            .post(url, formData, { headers: headers })
            .pipe(map(response => response));
    }
    importedContactList(req: any) {
        return this._restService.Post('/Contact/GetImportedContactStats', req);
    }
    saveFile(blob, fileName) {
        if (window.navigator.msSaveOrOpenBlob) { // For IE:
            navigator.msSaveBlob(blob, fileName);
        } else { // For other browsers:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
        }
    }
    downloadImportFile(id: number) {
        return this._restService.Getblob('/Contact/DownloadImportFile/' + id);
    }
    deleteImport(id: number) {
        return this._restService.Delete('/Contact/DeleteImportedContacts/' + id, null);
    }
    verifyContactsByImportId(id: number) {
        return this._restService.Get('/Contact/VerifyContacts/' + id);
    }
    getContactColumns() {
        this._fuseSplashScreenService.show();
        return this._restService.Get('/Contact/GetContactHeaderFields')
            .subscribe((response: any) => {
                this._fuseSplashScreenService.hide();
                if (response.status == 200) {
                    this.contactColumns = response.data;
                    this.onContactColumnsChanged.next(this.contactColumns);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                });
    }
    getStatsById(id: any) {
        return this._restService.Get('/Contact/GetImportDetailedStats/' + id)
    }
    getContactGroupsList(ContactId: number) {
        return this._restService.Get('/Group/GetContactGroupsList/' + ContactId);
    }
    updateContactGroups(ContactId: number, GroupIds: any) {
        return this._restService.Post('/Group/AddContactGroups/' + ContactId, GroupIds);
    }
    GetCommunicationPreference(ContactId: number) {
        return this._restService.Get('/Contact/GetCommunicationPreference/' + ContactId);
    }
    GetCompanyOptInDetails() {
        return this._restService.Get('/Company/GetCompanyOptInDetails');
    }
    SaveSentMessageGroup(sentMessageGroupReq, isExport) {
        return this._restService.Post('/Inbox/GetSentMessageDetaillogs?export=' + isExport, sentMessageGroupReq);
    }
}
