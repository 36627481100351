import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'pages',
        title: '',
        type: 'group',
        icon: 'pages',
        children: []
    }
];
export const views: any[] = [
    {
        id: 'home',
        title: 'Dashboard',
        type: 'item',
        icon: 'home',
        avaiableForCompany: true,
        avaiableForAgents: true,
        url: '/pages/dashboard'
    },
    {
        id: 'chat',
        title: 'Quick Reply',
        type: 'item',
        icon: 'chat',
        avaiableForCompany: true,
        avaiableForAgents: true,
        url: '/pages/chat'
    },
    {
        id: 'contacts',
        title: 'Contacts',
        type: 'collapsable',
        icon: 'account_box',
        avaiableForCompany: true,
        avaiableForAgents: true,
        children: [
            {
                id: 'allcontacts',
                title: 'All Contacts',
                type: 'item',
                icon: 'account_box',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/allcontacts'
            },
            {
                id: 'importcontacts',
                title: 'Import Contacts',
                type: 'item',
                icon: 'cloud_upload',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/importcontacts'
            }
        ]
    },
    {
        id: 'audience',
        title: 'Audience',
        type: 'collapsable',
        icon: 'group',
        avaiableForCompany: true,
        avaiableForAgents: true,
        children: [
            {
                id: 'segments',
                title: 'Segments',
                type: 'item',
                icon: 'group',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/segments',
            },
            {
                id: 'groups',
                title: 'Groups',
                type: 'item',
                icon: 'group',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/groups'
            },
        ]
    },
    {
        id: 'mail',
        title: 'Inbox',
        type: 'item',
        icon: 'email',
        avaiableForCompany: true,
        avaiableForAgents: true,
        url: '/pages/mail',
    },
    {
        id: 'calendar',
        title: 'Calendar',
        type: 'item',
        icon: 'today',
        avaiableForCompany: true,
        avaiableForAgents: true,
        url: '/pages/calendar'
    },
    {
        id: 'builder',
        title: 'Builder',
        type: 'collapsable',
        icon: 'view_list',
        avaiableForCompany: true,
        avaiableForAgents: true,
        children: [
            {
                id: 'campaigns',
                title: 'Campaigns',
                type: 'item',
                icon: 'send',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/builder'
            },
            {
                id: 'journey',
                title: 'Journeys',
                type: 'item',
                icon: 'show_chart',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/journey-report',
            },
            {
                id: 'pages',
                title: 'Pages',
                type: 'item',
                icon: 'view_list',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/Page-builder',
            }
        ]
    },
    {
        id: 'triggers',
        title: 'Triggers',
        type: 'collapsable',
        icon: 'settings',
        avaiableForCompany: true,
        avaiableForAgents: true,
        children: [
            {
                id: 'triggers',
                title: 'Triggers',
                type: 'item',
                icon: 'settings',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/triggers',
            },
            {
                id: 'triggerevents',
                title: 'Trigger Events',
                type: 'item',
                icon: 'event_note',
                avaiableForCompany: true,
                avaiableForAgents: false,
                url: '/pages/triggerevents',
            },
            {
                id: 'triggerproperties',
                title: 'Trigger Properties',
                type: 'item',
                icon: 'settings_ethernet',
                avaiableForCompany: true,
                avaiableForAgents: false,
                url: '/pages/triggerproperties',
            },
            {
                id: 'ARtemplates',
                title: 'AR Triggers',
                type: 'item',
                icon: 'settings',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/ARTemplates',
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'item',
                icon: 'list',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/reports'
            }
        ]
    },
    {
        id: 'reporting',
        title: 'Reporting',
        type: 'collapsable',
        icon: 'insert_chart_outlined',
        avaiableForCompany: true,
        avaiableForAgents: true,
        children: [
            {
                id: 'Analytics',
                title: 'Analytics',
                type: 'item',
                icon: 'insert_chart_outlined',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/analytics'
            },
            {
                id: 'link-trackingReport',
                title: 'Link Tracking',
                type: 'item',
                icon: 'track_changes',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/tracking'
            },
            {
                id: 'Monthly Messaging',
                title: 'Monthly Messaging',
                type: 'item',
                icon: 'dashboard',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/monthlyMessaging'
            },
            {
                id: 'monthly-companyDetails',
                title: 'Monthly Messaging',
                type: 'item',
                icon: 'dashboard',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/monthly-companyDetails'
            }
        ]
    },
    {
        id: 'manage',
        title: 'Manage',
        type: 'collapsable',
        icon: 'view_list',
        avaiableForCompany: true,
        avaiableForAgents: true,
        children: [
            {
                id: 'media',
                title: 'Media',
                type: 'item',
                icon: 'folder',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/media',
            },
            {
                id: 'optOuts',
                title: 'Opt Outs',
                type: 'item',
                icon: 'input',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/optOuts',
            },
            {
                id: 'optIns',
                title: 'Opt Ins',
                type: 'item',
                icon: 'input',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/optIns',
            },
            {
                id: 'tickets',
                title: 'Tickets',
                type: 'item',
                icon: 'confirmation_number',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/tickets',
            },
            {
                id: 'agent',
                title: 'Agent Settings',
                type: 'item',
                icon: 'account_circle',
                avaiableForCompany: true,
                avaiableForAgents: false,
                url: '/pages/agent',
            },
            {
                id: 'providers',
                title: 'Providers',
                type: 'item',
                icon: 'list',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/providers',
            },
            {
                id: 'company',
                title: 'Main Accounts',
                type: 'item',
                icon: 'report',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/mainaccounts',
            },
            {
                id: 'subaccounts',
                title: 'Sub Accounts',
                type: 'item',
                icon: 'report',
                avaiableForCompany: true,
                avaiableForAgents: false,
                url: '/pages/subaccounts',
            }   ,
            {
                id: 'apilogs',
                title: 'API Logs',
                type: 'item',
                icon: 'lock',
                avaiableForCompany: true,
                avaiableForAgents: false,
                url: '/pages/api-logs'
            },
            {
                id: 'activityLogs',
                title: 'Activity Logs',
                type: 'item',
                icon: 'lock',
                avaiableForCompany: true,
                avaiableForAgents: false,
                url: '/pages/activity-logs'
            },
            {
                id: 'exigo-api-logs',
                title: 'Exigo API Logs',
                type: 'item',
                icon: 'lock',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/exigo-api-logs'
            },
            {
                id: 'exigo-event-logs',
                title: 'Exigo Event Logs',
                type: 'item',
                icon: 'lock',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/exigo-event-logs'
            },
            {
                id: 'emailPreferences',
                title: 'Email Preferences',
                type: 'item',
                icon: 'email',
                avaiableForCompany: true,
                avaiableForAgents: false,
                url: '/pages/email-preferences'
            },
            {
                id: 'companyoptin',
                title: 'Company Opt-in',
                type: 'item',
                icon: 'work_outline',
                avaiableForCompany: true,
                avaiableForAgents: false,
                url: '/pages/company-opt-in'
            },
            {
                id: 'mailinglist',
                title: 'Mailing List',
                type: 'item',
                icon: 'contact_mail',
                avaiableForCompany: true,
                avaiableForAgents: false,
                url: '/pages/mailing-list'
            },
            {
                id: 'template',
                title: 'Template',
                type: 'item',
                icon: 'library_books',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/template'

            },            
            {
                id: 'master-templates',
                title: 'Master Templates',
                type: 'item',
                icon: 'library_books',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/master-templates'
            },
            {
                id: 'master-learning-center',
                title: 'Master Learning Center',
                type: 'item',
                icon: 'library_books',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/masterLearningCenter'
            },
            {
                id: 'master-campaign',
                title: 'Master Campaign',
                type: 'item',
                icon: 'send',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/master-campaign'
            },
            {
                id: 'master-journeys',
                title: 'Master Journeys',
                type: 'item',
                icon: 'show_chart',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/master-journeyReport'
            }
        ]
    },
    {
        id: 'support',
        title: 'Support',
        type: 'collapsable',
        icon: 'headset_mic',
        avaiableForCompany: false,
        avaiableForAgents: false,
        children: [
            {
                id: 'learning',
                title: 'Learning Center',
                type: 'collapsable',
                icon: 'library_books',
                avaiableForCompany: false,
                avaiableForAgents: false,
                children: []
            },
            {
                id: 'noc',
                title: 'NOC',
                type: 'item',
                icon: 'library_books',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/noc'
            },
            {
                id: 'releaseNote',
                title: 'Release Notes',
                type: 'item',
                icon: 'library_books',
                avaiableForCompany: false,
                avaiableForAgents: false,
                url: '/pages/releaseNotes'
            }
        ]
    },
    
    {
        id: 'admin',
        title: 'Admin Settings',
        type: 'collapsable',
        icon: 'account_circle',
        avaiableForCompany: true,
        avaiableForAgents: true,
        children: [
            {
                id: 'myaccount',
                title: 'My Account',
                type: 'item',
                icon: 'person',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/profile'
            },
            {
                id: 'account',
                title: 'Unified Accounts',
                type: 'item',
                icon: 'account_box',
                avaiableForCompany: true,
                avaiableForAgents: true,
                url: '/pages/accounts'
            },
        ]
    },

];
