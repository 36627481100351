import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject} from 'rxjs';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'import-contacts-mapping-viewer',
    templateUrl: './import-contacts-mapping-viewer.component.html',
    styleUrls: ['./import-contacts-mapping-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ImportContactsMappingViewerDialogComponent implements OnInit, AfterViewInit, OnDestroy {  
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    IsDialogMax:boolean =false; 
    mappingfields: any[] = [];
    private _unsubscribeAll: Subject<any>;
    hostHeight: any;
    hostWidth: any;
    groupsList: any[] = [];
    groupNameArray: any[] = [];
    constructor(
        public matDialogRef: MatDialogRef<ImportContactsMappingViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _pageService: PageService,
        private _toastr: ToastrService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        var keyArray = Object.keys(this._data.fileRequest["mappingFields"]['Fields']);
        keyArray.forEach(item => {
            if(this._data.fileRequest["mappingFields"]['Fields'][item] != null) {
                this._data.contactColumns.forEach(field =>{
                    if(field.fieldName == item) {
                        this.mappingfields.push(
                            {fieldlabel: field.label, fieldValue: this._data.fileRequest["mappingFields"]['Fields'][item]}
                        );
                    }
                })
            }
        });
        this.getAllgroups();
    }
    ngAfterViewInit() {

    };
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    getAllgroups() {
        this._pageService.getAllGroups().subscribe((res: any) => {
                if (res.status == 200) {
                    this.groupsList = res.data;
                    if(this._data.fileRequest["mappingFields"] && this._data.fileRequest["mappingFields"]['GroupIds'] != null && this._data.fileRequest["mappingFields"]['GroupIds'].length > 0 ) {
                        this._data.fileRequest["mappingFields"]['GroupIds'].forEach(item => {
                            this.groupsList.forEach(group => {
                                if(item == group.id){
                                    this.groupNameArray.push(group.name);
                                  }
                            });
                        });
                    }
                }
                else {
                    this.groupsList = [];
                }
            },
            error => {
                this._toastr.error(error.message, 'Error');
            });
    }
}

