import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'messageSegments',
    pure: false
})
export class MessageSegmentsPipe implements PipeTransform {
    transform(message: string, optOutTrailer: string, chunkSize: number): any {
        var str = message ? message + '\n' + optOutTrailer : optOutTrailer;
        var arr = [];
        while (str !== '') {
            arr.push(str.slice(0, chunkSize + 1));
            str = str.slice(chunkSize + 1);
        }
        return arr;
    }
}
