import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'app-select-check-all',
  templateUrl: "./select-check-all.component.html",
  styleUrls: ['./select-check-all.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectCheckAllComponent {
  @Input() values = [];
  @Input() text = 'All';
  @Input() field: string;
  @Input() key: string;
  @Output() toggledEmitter = new EventEmitter<any>();
  constructor(private parentForm: FormGroupDirective) { }
  isChecked(): boolean {
    return this.parentForm.form.controls[this.field].value && this.values.length
      && this.parentForm.form.controls[this.field].value.length === this.values.length;
  }

  toggleSelection(change: any): void {
    if (change.checked) {
      this.parentForm.form.controls[this.field].patchValue(this.values.map(event => { return event[this.key] }));
      this.toggledEmitter.emit(true);
    } else {
      this.parentForm.form.controls[this.field].patchValue([]);
      this.toggledEmitter.emit(false);
    }
  }
}
