import { Data } from '@agm/core/services/google-maps-types';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FuseUtils } from '@fuse/utils';
import { RestService } from '../../shared/rest.service';
import { AccountType } from 'app/main/pages/accounts/account.model';
import { AuthService } from 'app/shared/auth.service';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import Bee from 'bee-plugin';

@Injectable({
    providedIn: 'root'
})
export class PageService {
    userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
    isAdminRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'user' || this.userDetails.roles[0] == 'administrator');
    isCompanyRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'company');
    isAgentRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
    isAgentView: any = this.userDetails && this.userDetails.defaultAgentView;
    allAccountTypes: AccountType[] = [];
    accountTypesByUser: AccountType[];
    externalAccountTypesByUser: AccountType[];
    groups: any[];
    onAccountTypeChanged: BehaviorSubject<any>;
    checkPage: BehaviorSubject<any>;
    onAccountTypeByUserChanged: BehaviorSubject<any>;
    onExternalAccountTypeChanged: BehaviorSubject<any>;
    onExternalAccountTypeByUserChanged: BehaviorSubject<any>;
    onCompanyList: BehaviorSubject<any>;
    onGroupChanged: BehaviorSubject<any>;
    onTimeZonesChanged = new BehaviorSubject<any>([]);
    onCountryChanged = new BehaviorSubject<any>([]);
    onLanguageChanged = new BehaviorSubject<any>([]);
    onOrderDateChanged = new BehaviorSubject<any>([]);
    onPageLinkChanged=new BehaviorSubject<any>([]);
    onRankChanged = new BehaviorSubject<any>(null);
    contactData: any
    onContactSelection: BehaviorSubject<any>;
    onAssociateTypeChanged = new BehaviorSubject<any>(null);
    onAssociateStatusChanged = new BehaviorSubject<any>(null);
    SlectMediaChange: BehaviorSubject<any>;
    SlectionMergeFieldChanges: BehaviorSubject<any>;
    OnSelectViewBrowserUrlChange: BehaviorSubject<any>;
    onMediaFilesChange: BehaviorSubject<any>; 
    contacts: any[];
    contactsTotalCount: number;
    stopNextCallingforContacts: boolean = false;
    contactsBysearchText: string;
    contactByAssociatedId: any;
    contactByselectedIds: any;
    onContactsChanged: BehaviorSubject<any>;
    selectedFolder: number = 0;
    inboxReportType: string = "";
    sentReportType: string = "";
    inboxAcountTypeFilter: number = 0;
    inboxIsAgent: any = null;
    contactDetailsRedirection: any = null;
    onApiLogsCompany = new BehaviorSubject<any>(null);
    private routerInfo: BehaviorSubject<boolean>;
    companyListApiLogs: any;
    beeEditor: any = new Bee();
    onEmailTemplateCatlogChange: BehaviorSubject<any>;
    JSON_START = /^\[|^\{(?!\{)/;
    JSON_ENDS = {
      '[': /]$/,
      '{': /}$/
    };
    defaultTemplate: any = {
        "page": {
            "title": "Template Base",
            "description": "Test template for BEE",
            "template": {
                "name": "template-base",
                "type": "basic",
                "version": "0.0.1"
            },
            "body": {
                "type": "mailup-bee-page-proprerties",
                "container": {
                    "style": {
                        "background-color": "#FFFFFF"
                    }
                },
                "content": {
                    "style": {
                        "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif",
                        "color": "#000000"
                    },
                    "computedStyle": {
                        "linkColor": "#0068A5",
                        "messageBackgroundColor": "transparent",
                        "messageWidth": "500px"
                    }
                },
                "webFonts": [

                ]
            },
            "rows": [
                {
                    "container": {
                        "style": {
                            "background-color": "transparent",
                            "background-image": "none",
                            "background-repeat": "no-repeat",
                            "background-position": "top left"
                        }
                    },
                    "content": {
                        "style": {
                            "background-color": "transparent",
                            "color": "#000000",
                            "width": "500px",
                            "background-image": "none",
                            "background-repeat": "no-repeat",
                            "background-position": "top left"
                        },
                        "computedStyle": {
                            "rowColStackOnMobile": true,
                            "rowReverseColStackOnMobile": false
                        }
                    },
                    "columns": [
                        {
                            "style": {
                                "background-color": "transparent",
                                "border-bottom": "0px solid transparent",
                                "border-left": "0px solid transparent",
                                "border-right": "0px solid transparent",
                                "border-top": "0px solid transparent",
                                "padding-bottom": "5px",
                                "padding-left": "0px",
                                "padding-right": "0px",
                                "padding-top": "5px"
                            },
                            "modules": [

                            ],
                            "grid-columns": 12,
                            "uuid": "0479d844-c978-4c61-9621-6dfe2f3c2179"
                        }
                    ],
                    "type": "one-column-empty",
                    "uuid": "22c62e2f-0dc8-4279-970e-aa1596244942"
                }
            ]
        },
        "comments": {

        }
    };
    specialLinks = [...this.setInitialSpecialLinks()];

    setInitialSpecialLinks(){
        return [
            {
                type: 'Frequently used',
                label: 'Unsubscribe',
                link: '%unsubscribe_url%'
            },
            {
                type: 'Frequently used',
                label: 'ViewInBrowser',
                link: '[ViewInBrowser]'
            },
            {
                type: 'Frequently used',
                label: 'ValidateLink',
                link: '[ValidateLink]'
            },
            {
                type: 'Frequently used',
                label: 'EmailPreferences',
                link: '[EmailPreferences]'
            }
        ]
    }



    mergeTags = [
        {
            name: "First Name",
            value: "[firstName]"
        },
        {
            name: "Last Name",
            value: "[lastName]"
        },
        {
            name: "Phone",
            value: "[Phone]"
        },
        {
            name: "Email",
            value: "[Email]"
        },
        {
            name: "Highest Rank",
            value: "[HighestRank]"
        },
        {
            name: "Paid Rank",
            value: "[paidAsRank]"
        },
        {
            name: "WebAlias",
            value: "[WebAlias]"
        },
        {
            name: "Associate Status",
            value: "[AssociateStatus]"
        },
        {
            name: "Associate Type",
            value: "[AssociateType]"
        },
        {
            name: "Company Logo",
            value: "[CompanyLogoURL]"
        },
        {
            name: "Company Name",
            value: "[CompanyName]"
        },
        {
            name: "CompanyAddress",
            value: "[CompanyAddress]"
        },
        {
            name: "CompanyCity",
            value: "[CompanyCity]"
        },
        {
            name: "CompanyState",
            value: "[CompanyState]"
        },
        {
            name: "CompanyZip",
            value: "[CompanyZipCode]"
        },
        {
            name: "BackOffice Id",
            value: "[BackOfficeId]"
        },
        {
            name: "Sponsor First Name",
            value: "[_SponsorfirstName]"
        },
        {
            name: "Sponsor Last Name",
            value: "[_SponsorlastName]"
        },
        {
            name: "Sponsor Email",
            value: "[_Sponsoremail]"
        },
        {
            name: "Sponsor Phone Number",
            value: "[_SponsorphoneNumber]"
        },
        {
            name: "Sponsor WebAlias",
            value: "[_SponsorWebAlias]"
        },
        {
            name: "Sponsor ID",
            value: "[_SponsorAssociateId]"
        }
    ];
    mergeTagsArray: any = [
        {
          name: 'Basic',
          basicTag: [
            {
              name: " Associate ID",
              value: "[AssociateId]"
            },
            {
              name: "First Name",
              value: "[firstName]"
            },
            {
              name: "Last Name",
              value: "[lastName]"
            },
            {
              name: "Phone",
              value: "[Phone]"
            },
            {
              name: "Email",
              value: "[Email]"
            },
            {
              name: "Highest Rank",
              value: "[HighestRank]"
              },
              {
                  name: "Paid Rank",
                  value: "[paidAsRank]"
              },
              {
                  name: "WebAlias",
                  value: "[WebAlias]"
              },
              {
                  name: "Associate Status",
                  value: "[AssociateStatus]"
              },
              {
                  name: "Associate Type",
                  value: "[AssociateType]"
              },
              {
                name: "Company Logo",
                value: "[CompanyLogoURL]"
              },
              {
                name: "Company Name",
                value: "[CompanyName]"
              },
              {
                name: "CompanyAddress",
                value: "[CompanyAddress]"
              },
              {
                name: "CompanyCity",
                value: "[CompanyCity]"
              },
              {
                name: "CompanyState",
                value: "[CompanyState]"
              },
              {
                name: "CompanyZip",
                value: "[CompanyZipCode]"
              },
              {
                name: "BackOffice Id",
                value: "[BackOfficeId]"
              }
            ],
        },
        {
          name: 'Sponsor',
          sponsorTag: [
            {
              name: "Sponsor First Name",
              value: "[_SponsorfirstName]"
            },
            {
                name: "Sponsor Last Name",
                value: "[_SponsorlastName]"
            },
            {
                name: "Sponsor Email",
                value: "[_Sponsoremail]"
            },
            {
                name: "Sponsor Phone Number",
                value: "[_SponsorphoneNumber]"
            },
            {
                name: "Sponsor WebAlias",
                value: "[_SponsorWebAlias]"
            },
            {
                name: "Sponsor ID",
                value: "[_SponsorAssociateId]"
            }
          ],
        },
        {
            name: 'Custom',
            customTag: [],
        }
    ];
    constructor(
        private _restService: RestService,
        private _authService: AuthService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _router: Router
    ) {
       for(let i = 1; i <=20; i++) {
          let tag = {
              name: "Custom"+i,
              value: "[Custom"+i+"]"
          };
          this.mergeTags.push(tag);
          this.mergeTagsArray[2].customTag.push(tag);
        }
        this.init();
        this.getAllPageBuilderUrl().subscribe((res)=>{
            this.onPageLinkChanged.next(res['data']);
        })
        
        this.onPageLinkChanged.subscribe((res)=>{
            this.specialLinks=[];
            this.specialLinks = [...this.setInitialSpecialLinks()];
            res.forEach((item)=>{
                this.specialLinks.push({
                    type:'Page Link',
                    label:item['name'],
                    link:item['url']
                });
            })
        })
        
    }


    init() {
        this.userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
            this.isAdminRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'user' || this.userDetails.roles[0] == 'administrator');
            this.isCompanyRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'company');
            this.isAgentRole = this.userDetails && this.userDetails.roles && this.userDetails.roles.length > 0 && (this.userDetails.roles[0] == 'agent');
        this.isAgentView = this.userDetails && this.userDetails.defaultAgentView;
        this.selectedFolder = 0;
        this.inboxAcountTypeFilter = 0;
        this.inboxIsAgent = null;
        this.inboxReportType = "";
        this.sentReportType = "";
        this.contactDetailsRedirection = null;
        this.allAccountTypes = [];
        this.accountTypesByUser = [];
        this.externalAccountTypesByUser = [];
        this.groups = [];
        this.onAccountTypeChanged = new BehaviorSubject([]);
        this.onExternalAccountTypeChanged = new BehaviorSubject([]);
        this.checkPage = new BehaviorSubject(null);
        this.onAccountTypeByUserChanged = new BehaviorSubject(null);
        this.onExternalAccountTypeByUserChanged = new BehaviorSubject(null);
        this.onGroupChanged = new BehaviorSubject([]);
        this.onTimeZonesChanged = new BehaviorSubject<any>([]);
        this.onCountryChanged = new BehaviorSubject<any>([]);
        this.onLanguageChanged = new BehaviorSubject<any>([]);
        this.onOrderDateChanged = new BehaviorSubject<any>([]);
        this.onPageLinkChanged= new BehaviorSubject<any>([]);
        this.onRankChanged = new BehaviorSubject<any>(null);
        this.contactData = {};
        this.onContactSelection = new BehaviorSubject([]);
        this.routerInfo = new BehaviorSubject<boolean>(false);
        this.onAssociateTypeChanged = new BehaviorSubject<any>(null);
        this.onAssociateStatusChanged = new BehaviorSubject<any>(null);
        this.contacts = [];
        this.contactsTotalCount = 1000;
        this.contactsBysearchText = '';
        this.contactByAssociatedId = 0;
        this.contactByselectedIds = [];
        this.onContactsChanged = new BehaviorSubject([]);
        this.onApiLogsCompany = new BehaviorSubject<any>(null);
        this.SlectMediaChange = new BehaviorSubject<any>(false);
        this.onMediaFilesChange = new BehaviorSubject<any>([]);
        this.SlectionMergeFieldChanges = new BehaviorSubject<any>(false);
        this.OnSelectViewBrowserUrlChange = new BehaviorSubject<any>(null);
        this.onEmailTemplateCatlogChange = new BehaviorSubject<any>(false);
        this.beeEditor = new Bee();
        localStorage.removeItem("ContactHeaders");
        localStorage.removeItem("Countries");  
        // this.getDomainType();
    }
    isJsonLike(str) {
        var jsonStart = str.match(this.JSON_START);
         return jsonStart && this.JSON_ENDS[jsonStart[0]].test(str);
    }
    getValue(): Observable<boolean> {
        return this.routerInfo.asObservable();
    }
    setValue(newValue): void {
        this.routerInfo.next(newValue);
    }
    printContent(id) {
        var printContents = document.getElementById(id).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    initBeeEditor() {
        this._restService.Get('/Inbox/GetBeeEditorToken')
            .subscribe((response: any) => {
                if (response.status == 200) {
                    if (response.data) {
                        localStorage.setItem("BeeToken", response.data.token);
                    }
                    else {
                        this.getBeeToken();
                    }
                }
            });
    }
    getBeeToken() {
        this.beeEditor.getToken('89c6b4fd-09fb-4bd4-9deb-f22065e3a5b5', 'xl0SgQ9nwQeGw4LX9P7E5tOclxfhbE3gmJkkZ9YKd0yXJPVp0QzP').then((token) => {
            localStorage.setItem("BeeToken", JSON.stringify(token));
            this._restService.Post('/Inbox/AddEditBeeEditorToken', { "token": JSON.stringify(token) })
                .subscribe((response: any) => {
                    if (response.status == 200) {
                    }
                });
        });
    }
    getAccountsTypes() {
        this._restService.Get('/UnifiedType/GetAllUnifiedTypes')
            .subscribe((response: any) => {
                if (response.status == 200) {
                    this.allAccountTypes = response.data;
                    this.onAccountTypeChanged.next(this.allAccountTypes);
                    this.onExternalAccountTypeChanged.next(this.allAccountTypes);
                }
            });
    }
    getAccountsTypesByUser() {
        if (!this.onAccountTypeByUserChanged.value) {
            this._restService.Get('/UnifiedType/GetUnifiedTypesForUser')
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.accountTypesByUser = response.data ? response.data : [];
                        this.onAccountTypeByUserChanged.next(this.accountTypesByUser);
                    }
                    else {
                        this.onAccountTypeByUserChanged.next(null);
                    }
                }, (error: any) => {
                    this.onAccountTypeByUserChanged.next(null);
                });
        }
        else {
            this.onAccountTypeByUserChanged.next(this.accountTypesByUser);
        }
    }
    getCurrentPage(data) {
        this.checkPage.next(data);
    }

    getGroupContactsCount(groupIds: any): Observable<any> {
        return new Observable(subscriber => {
            this._restService.Post('/Contact/GetGroupContactsCount', groupIds)
                .subscribe((res: any) => {
                    if (res.status == 200) {
                        subscriber.next(res.data);
                    }
                    else {
                        subscriber.next(0);
                    }
                }, (error: any) => {
                    subscriber.next(0);
                });
        });
    }
    getContactHeaders(): Observable<any> {
        return new Observable(subscriber => {
            let headers = JSON.parse(localStorage.getItem("ContactHeaders"));
            if (headers) {
                subscriber.next(headers);
            }
            else {
                this._restService.Get('/Contact/GetContactHeaderFields').subscribe((res: any) => {
                    let tempObj = {};
                    res.data.map(obj => {
                        tempObj[obj.fieldName ? (obj.fieldName.charAt(0).toLowerCase() + obj.fieldName.substring(1)) : obj.fieldName] = obj.label;
                    });
                    localStorage.setItem("ContactHeaders", JSON.stringify(tempObj));
                    subscriber.next(tempObj);
                }, (error: any) => {
                    subscriber.next(null);
                });
            }
        });
    }
    getApiLogsCompanyList(): BehaviorSubject<any> {
        if (!this.onApiLogsCompany.value || this.onApiLogsCompany.value.length == 0) {
            this._restService.Get('/Company/GetCompaniesNamesForPublicapis').subscribe((res: any) => {
                this.onApiLogsCompany.next(res);
            }, (error: any) => {
                this.onApiLogsCompany.next(null);
            })
        }
        return this.onApiLogsCompany;
    }

    getEventsForPublicapis(companyName: any) {
        let req = companyName ? ('?CompanyName=' + companyName) : '';
        return this._restService.Get('/Company/getEventsForPublicapis' + req);
    }

    getGroups() {
        this._restService.Get('/Group/GetGroups')
            .subscribe((response: any) => {
                if (response.status == 200) {
                    this.groups = response.data;
                    this.onGroupChanged.next(this.groups);
                }
            });
    }
    getDomainType() {
        localStorage.setItem("domainTypeEmail", JSON.stringify({"hasmarketingDomain": false, "hasCustomDomain": false}));
        localStorage.setItem("domainTypeSMS", JSON.stringify({"hasmarketingDomain": false, "hasCustomDomain": false}));
        localStorage.setItem("domainTypeInternationalSMS", JSON.stringify({"hasmarketingDomain": false, "hasCustomDomain": false})); 
        this._restService.Get('/UnifiedAccount/HasMarketingandCustomDomains').subscribe((res:any) => {
            var hasData = res.data;
            hasData.forEach(element => {
                if(element.id == 1) {
                    localStorage.setItem("domainTypeEmail", JSON.stringify(element));
                }else if(element.id == 11) {
                    localStorage.setItem("domainTypeSMS", JSON.stringify(element));
                }else if(element.id == 14) {
                    localStorage.setItem("domainTypeInternationalSMS", JSON.stringify(element));
                }
            });
        });
    } 
    getTimeZones(): BehaviorSubject<any> {
        let timezones = JSON.parse(localStorage.getItem("TimeZones"));
        if (timezones) {
            this.onTimeZonesChanged = new BehaviorSubject<any>(timezones);
            return this.onTimeZonesChanged;
        }
        else {
            this._restService.Get('/Campaign/GetTimeZones').subscribe((res: any) => {
                localStorage.setItem("TimeZones", JSON.stringify(res));
                this.onTimeZonesChanged.next(res);
            }, (error: any) => {
                this.onTimeZonesChanged.next([]);
            });
            return this.onTimeZonesChanged;
        }
    }
    getAllCountries(): BehaviorSubject<any> {
        let countries = JSON.parse(localStorage.getItem("Countries"));
        if (countries) {
            this.onCountryChanged = new BehaviorSubject<any>(countries);
            return this.onCountryChanged;
        }
        else {
            this._restService.Get('/Country/GetAllCountries?IsAgent=' + this.isAgentView).subscribe((res: any) => {
                localStorage.setItem("Countries", JSON.stringify(res.data));
                this.onCountryChanged.next(res.data);
            }, (error: any) => {
                this.onCountryChanged.next([]);
            });
            return this.onCountryChanged;
        }
    }
    getCountryStates(countryCodes: any): Observable<any> {
        return new Observable(subscriber => {
            this._restService.Post('/Country/GetAllCountryStates', countryCodes)
                .subscribe((res: any) => {
                    if (res.status == 200) {
                        subscriber.next(res.data);
                    }
                    else {
                        subscriber.next(0);
                    }
                }, (error: any) => {
                    subscriber.next(0);
                });
        });
    }

    GetAllLanguages(): BehaviorSubject<any> {
        let languages = JSON.parse(localStorage.getItem("Languages"));
        if (languages) {
            this.onLanguageChanged = new BehaviorSubject<any>(languages);
            return this.onLanguageChanged;
        }
        else {
            this._restService.Get('/Language/GetAllLanguages').subscribe((res: any) => {
                localStorage.setItem("Languages", JSON.stringify(res.data));
                this.onLanguageChanged.next(res.data);
            }, (error: any) => {
                this.onLanguageChanged.next([]);
            });
            return this.onLanguageChanged;
        }
    }
    keyPressNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
          event.preventDefault();
          return false;
        } else {
          return true;
        }
    }
    onPaste(Event) {
       let value = Event.value;
       if(isNaN(value)) {
        Event.preventDefault();
        return false; 
       }else {
        return true;
      }
    }
    keyPressAlphaNumeric(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
            return true;
          } else {
            event.preventDefault();
            return false;
          }
    }
    onPasteNotAlphaNumeric(event) {
        // a.replace(/[^a-zA-Z0-9]+/g," ")
        var charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }
    getOrderDateFilters(): BehaviorSubject<any> {
        let orderDates = JSON.parse(localStorage.getItem("OrderDate"));
        if (orderDates) {
            this.onOrderDateChanged = new BehaviorSubject<any>(orderDates);
            return this.onOrderDateChanged;
        }
        else {
            this._restService.Get('/Group/GetOrderDateFilters').subscribe((res: any) => {
                localStorage.setItem("OrderDate", JSON.stringify(res.data));
                this.onOrderDateChanged.next(res.data);
            }, (error: any) => {
                this.onOrderDateChanged.next([]);
            });
            return this.onOrderDateChanged;
        }
    }
    getRanks(): BehaviorSubject<any> {
        if (!this.onRankChanged.value || this.onRankChanged.value.length == 0) {
            this._restService.Get('/Group/GetRanks?IsAgent=' + this.isAgentView).subscribe((res: any) => {
                if (res.status == 200) {
                    this.onRankChanged.next(res);
                }
                else {
                    this.onRankChanged.next(null);
                }
            }, (error: any) => {
                this.onRankChanged.next(null);
            })
        }
        return this.onRankChanged;
    }
    getAssociateTypes(): BehaviorSubject<any> {
        if (!this.onAssociateTypeChanged.value || this.onAssociateTypeChanged.value.length == 0) {
            this._restService.Get('/Group/GetAssociateTypes').subscribe((res: any) => {
                if (res.status == 200) {
                    this.onAssociateTypeChanged.next(res);
                }
                else {
                    this.onAssociateTypeChanged.next(null);
                }
            }, (error: any) => {
                this.onAssociateTypeChanged.next(null);
            })
        }
        return this.onAssociateTypeChanged;
    }
    getAssociateStatus(): BehaviorSubject<any> {
        if (!this.onAssociateStatusChanged.value || this.onAssociateStatusChanged.value.length == 0) {
            this._restService.Get('/Group/GetAssociateStatus?IsAgent=' + this.isAgentView).subscribe((res: any) => {
                if (res.status == 200) {
                    this.onAssociateStatusChanged.next(res);
                }
                else {
                    this.onAssociateStatusChanged.next(null);
                }
            }, (error: any) => {
                this.onAssociateStatusChanged.next(null);
            })
        }
        return this.onAssociateStatusChanged;
    }
    getHasMarketingDomains() {
        return this._restService.Get('/UnifiedAccount/HasMarketingandCustomDomains');
    }
    GetAllMasterData() {
        return this._restService.Get('/Company/GetMasterData');
    }
    getAllAccounts() {
        return this._restService.Get('/UnifiedType/GetUnifiedTypesForUser');
    }
    getAllGroups() {
        return this._restService.Get('/Group/GetGroups');
    }
    getAllPageBuilderUrl(){
        return this._restService.Get('/Campaign/GetAllPageBuilderUrl');
    }
    GetCorporateGroups() {
        return this._restService.Get('/Group/GetCorporateGroups');
    }
    getContacts(contactReq) {
        return this._restService.Post('/Contact/GetAllContacts?IsAgent=' + this.isAgentView, contactReq);
    }
     getContactsForDropDown(contactReq) {
        return this._restService.Post('/Contact/GetContactsForDropDown?IsAgent=' + this.isAgentView, contactReq);
    }
    getContactsCount(contactReq) {
        return this._restService.Post('/Contact/GetAllContactsCount?IsAgent=' + this.isAgentView, contactReq);
    }
    getDownlines(req) {
        return this._restService.Post('/Contact/GetDownlines', req);
    }
    getDownlinesCount(req) {
        return this._restService.Post('/Contact/GetDownlinesCount', req);
    }
    getChat(chatReq) {
        return this._restService.Post('/Inbox/GetMessageDetailsNew', chatReq);
    }
    VerifyNumber(number) {
        return this._restService.Get('/TwilioMessage/VerifyNumber?Number=' + number);
    }
    VerifyEmail(email) {
        return this._restService.Get('/MailgunEmail/VerifyEmail?email=' + email);
    }
    getContactIdByAssociateId(id, email, mobile) {
        if (id) {
            return this._restService.Get('/Contact/GetContactIdByAssociateId/' + id);
        }
        else if (email) {
            return this._restService.Get('/Contact/GetContactIdByAssociateId/0?Email=' + email);
        }
        else if (mobile) {
            return this._restService.Get('/Contact/GetContactIdByAssociateId/0?Mobile=' + mobile);
        }
    }
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control: any = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else {
                this.validateAllFormFields(control);
            }
        });
    }
    removeValidators(form: FormGroup, formControl: any) {
        form.get(formControl).clearValidators();
        form.get(formControl).updateValueAndValidity();
    }
    addValidators(form: FormGroup, formControl: any) {
        form.get(formControl).setValidators([Validators.required, trimValidator]);
        form.get(formControl).updateValueAndValidity();
    }

    getContactsToCompose(searchText, contactByAssociatedId, pageNumber, pageSize, selectedIds): Promise<any> {
        return new Promise((resolve, reject) => {
            let contactReq = {
                "searchText": searchText,
                "pageNumber": pageNumber,
                "pageSize": pageSize,
                "associateId": contactByAssociatedId,
                "selectedIds": selectedIds
            };
            this._restService.Post('/Contact/GetContactsForDropDown?IsAgent=' + this.isAgentView, contactReq)
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.contacts = response.data;
                        if(response.data && response.data.length < pageSize){
                            this.stopNextCallingforContacts = true;
                        }
                        this.onContactsChanged.next(this.contacts);
                        resolve(this.contacts);
                    }
                }, reject);
        }
        );
    }
    getContactsCountToCompose(searchText, pageNumber, pageSize) {
        let contactReq = {
            "searchText": searchText,
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "valid": true
        };
        return this._restService.Post('/Contact/GetAllContactsCount?IsAgent=' + this.isAgentView, contactReq)
    }
    getMediaFiless(searchText) {
        return this._restService.Post('/MediaFile/GetMediaFiles', { "searchText": searchText });
    }
    isPageExist(arr: any, page: string) {
        return arr.some(function (el) {
            return el == page;
        });
    }
    checkPermissions(event) {
        let LoggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserData"));
        let role: any = LoggedInUserData.roles[0];
        let companyRoles: any = LoggedInUserData.companyRoles ? LoggedInUserData.companyRoles : [];
        let agentRoles: any = LoggedInUserData.agentRoles ? LoggedInUserData.agentRoles.pages : (LoggedInUserData.companyRoles ? LoggedInUserData.companyRoles : []);
        if (role == 'company') {
            if (companyRoles.length > 0) {
                if (this.isPageExist(companyRoles, event)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
        else if (role == 'agent') {
            if (agentRoles.length > 0) {
                if (this.isPageExist(agentRoles, event)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }
    UploadMediaFile(data) {
        return;
    }
    getMediaUrl(file: any, cb: any) {
        var formData = new FormData();
        formData.append('formFile', file);
        this._fuseSplashScreenService.show();
        this._restService.PostFile('/LearningCenter/UploadMediaFile', formData).subscribe(
            (res: any) => {
                this._fuseSplashScreenService.hide();
                if (res.status == 200) {
                    cb(res.data);
                }
                else {
                    cb(null);
                }
            },
            error => {
                this._fuseSplashScreenService.hide();
                console.log(error);
                this._toastr.error('Unable to upload', 'Error');
                cb(null);
            }
        );
    }
    syncContactWithDS(req: any) {
            return this._restService.Post('/Contact/SyncContactWithDS', req);
    }
    getExigoErrorLogs(request) {
        var _url = "/ExigoTrigger/GetErrorLogList";
        return this._restService.PostExigo(_url, request);
    }
      getExigoEventLogs(request) {
        var _url = "/ExigoTrigger/GetEventLogList";
        return this._restService.PostExigo(_url, request);
    }
    ExigoContactSync(ExigoContactreq: any) {
        return this._restService.PostExigo('/ExigoTrigger/ContactSync'
            + '?companyKey=' + ExigoContactreq.companyKey +
            '&associateId=' + ExigoContactreq.associateId, { ExigoContactreq });
    }


    saveFile(blob, fileName) {
        if (window.navigator.msSaveOrOpenBlob) { // For IE:
            navigator.msSaveBlob(blob, fileName);
        } else { // For other browsers:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
        }
    }

    getAccountById(id: any, agentId?: number) {
        if (agentId) {
            return this._restService.Get('/UnifiedAccount/GetUnifiedAccountById/' + id);
        }
        else {
            return this._restService.Get('/Company/GetUnifiedAccountById/' + id);
        }
    }
    saveAccount(AccountRequest: any, AgentId?: number, CompanyId?: number) {
        if (AgentId) {
            var _url = "/UnifiedAccount/CreateUnifiedAccount?AgentId=" + AgentId;
            return this._restService.Post(_url, AccountRequest);
        }
        else {
            var _url = "/Company/CreateUnifiedAccount/" + CompanyId;
            return this._restService.Post(_url, AccountRequest);
        }
    }
    editAccount(AccountRequest: any, AgentId?: number, CompanyId?: number) {
        if (AgentId) {
            var _url = "/UnifiedAccount/UpdateUnifiedAccount?AgentId=" + AgentId;
            return this._restService.Post(_url, AccountRequest);
        }
        else {
            var _url = "/Company/UpdateUnifiedAccount/" + CompanyId;
            return this._restService.Post(_url, AccountRequest);
        }
    }
    createExternalAccount(AccountRequest: any, CompanyId?: number) {
        var _url = "/Company/CreateExternalAccount/" + CompanyId;
            return this._restService.Post(_url, AccountRequest);
    }
    editExternalAccount(AccountRequest: any, CompanyId?: number) {
        var _url = "/Company/UpdateExternalAccount/" + CompanyId;
        return this._restService.Post(_url, AccountRequest);
    }
    getExternalAccountsTypesByUser() {
        if (!this.onExternalAccountTypeByUserChanged.value) {
            this._restService.Get('/UnifiedType/GetExternalUnifiedTypesForUser')
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.externalAccountTypesByUser = response.data ? response.data : [];
                        this.onExternalAccountTypeByUserChanged.next(this.externalAccountTypesByUser);
                    }
                    else {
                        this.onExternalAccountTypeByUserChanged.next(null);
                    }
                }, (error: any) => {
                    this.onExternalAccountTypeByUserChanged.next(null);
                });
        }
        else {
            this.onExternalAccountTypeByUserChanged.next(this.externalAccountTypesByUser);
        }
    }
    getExternalAccountById(id: any) {
        return this._restService.Get('/Company/GetExternalAccountById/' + id);
    }
    GetMarketShow() {
        return this._restService.Get('/Company/GetByDesignOrderTriggerMasters');
    }
    generateViewBrowserLink(req) {
        return this._restService.Post('/Template/AddLinkedMessages', req);
    }
    syncContactsWithProvider(req, baseProviderId) {
        if(baseProviderId == 9) {
            return this._restService.PostExigo('/ExigoTrigger/SyncContactsWithProvider', req);
        }else {
            return this._restService.Post('/Contact/SyncContactsWithProvider', req);
        }
    }
    deleteNumber(id) {
        return this._restService.Delete('/Company/DeleteUserNumber/' + id, null);
    }
    getMessageTextById(messageId) {
        return this._restService.Get('/Inbox/GetMessageText/' + messageId);
    }
    getEventCountLogs(req) {
        return this._restService.Post('/Inbox/GetEventCountLogs', req);
    }
    getExternalExpention(req) {
        return this._restService.Post('/Error/GetExternalExpention', req);
    }
    getMediaFiles(searchText) {
        this._fuseSplashScreenService.show();
        this.getMediaFiless(searchText)
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res) {
                    this.onMediaFilesChange.next(res.data.report);
                }
                else {
                    this.onMediaFilesChange.next([]);
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    getAllMenuList(url) {
        return this._restService.Get(url);
    }
    deleteMenu(url) {
        return this._restService.Delete(url,null)
    }
    addEditMenu(url, req) {
        return this._restService.Post(url, req);
    }
    addToMenu(url) {
        return this._restService.Get(url);
    }
}
const trimValidator: ValidatorFn = (control: FormControl) => {
    if (control && control.value && typeof control.value == "string" && !control.value.replace(/\s/g, '').length) {
        return {
            'trimError': { value: 'control has leading whitespace' }
        };
    }

    return null;
};
