import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectDashboardService } from 'app/main/pages/dashboard/project.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AuthService } from 'app/shared/auth.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import * as _ from 'lodash';
import { ErrorDescriptionDialogComponent } from '../error-description/error-description.component';
import { TrackingService } from 'app/main/pages/tracking/tracking.service';
import { ViewMessageDialogComponent } from '../view-message/view-message.component';
import { InvoicePageComponent } from '../inovice-page/invoice-page.component';
import * as moment from 'moment';
import { RoiSummaryDetailsComponent } from '../roi-summary-details/roi-summary-details.component';
import { PageService } from 'app/main/pages/pages.service';
@Component({
  selector: 'roi-clicks-details',
  templateUrl: './roi-clicks-details.component.html',
  styleUrls: ['./roi-clicks-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class RoiClicksDetailsComponent implements OnInit {
  IsDialogMax: boolean = false;
  private _unsubscribeAll: Subject<any>;
  // weekType: any = 0;
  filterForm: FormGroup;
  List: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  TotalCount: any = 0;
  pageNumber = 1;
  pageSize = 30;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(FusePerfectScrollbarDirective, { static: false })
  directiveScroll: FusePerfectScrollbarDirective;
  showCountLoader: boolean;
  hostHeight: any;
  hostWidth: any;
  DialogRef: any;
  userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
  offset = new Date().getTimezoneOffset();
  filterOffset = (this.offset >= 720) ? 719 : (this.offset < -720) ? -600 : this.offset;
  // repoType: any = null;
  pageTitle = "Details";
  constructor(
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    public matDialogRef: MatDialogRef<RoiClicksDetailsComponent>, @Inject(MAT_DIALOG_DATA) public _data: any,
    private _formBuilder: FormBuilder,
    public trackingService:TrackingService,
    private _matDialog: MatDialog,
    public _pageService: PageService
  ) {
    this._unsubscribeAll = new Subject();
    this.filterForm = this._formBuilder.group({
      ROIReportingtype: [this._data.ROIReportingtype ? this._data.ROIReportingtype : 0],
      searchText: ['']
    });
  }
  displayedColumns = ['contactName'];
  ngOnInit(): void {
    if(this._pageService.checkPermissions('pageView-OnROIWidget')) {
      this.displayedColumns.push('pageViews');
    }else if(!this._pageService.checkPermissions('pageView-OnROIWidget') && this.displayedColumns.includes('pageViews')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('pageViews'), 1);
    }
    if(this._pageService.checkPermissions('abandonedCart-OnROIWidget')) {
      this.displayedColumns.push('abandonedCart');
    }else if(!this._pageService.checkPermissions('abandonedCart-OnROIWidget') && this.displayedColumns.includes('abandonedCart')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('abandonedCart'), 1);
    }
    if(this._pageService.checkPermissions('revenue-OnROIWidget')) {
      this.displayedColumns.push('revenue');
    }else if(!this._pageService.checkPermissions('revenue-OnROIWidget') && this.displayedColumns.includes('revenue')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('revenue'), 1);
    }
    if(this._pageService.checkPermissions('clicks-OnROIWidget')) {
      this.displayedColumns.push('clicks');
    }else if(!this._pageService.checkPermissions('clicks-OnROIWidget') && this.displayedColumns.includes('clicks')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('clicks'), 1);
    }
    if(this._pageService.checkPermissions('orders-OnROIWidget')) {
      this.displayedColumns.push('orders');
    }else if(!this._pageService.checkPermissions('orders-OnROIWidget') && this.displayedColumns.includes('orders')) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('orders'), 1);
    }
    this.getClicksDetails();
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
 
  search(event) {
    this.getClicksDetails();
  }
  
  onRefreshList(event) {
    this.List = [];
    this.directiveScroll.scrollToTop();
    this.filterForm.patchValue({
      ROIReportingtype: 0,
      searchText: ''
    });
    this.getClicksDetails();
  }
  onScrollDown(event) {

  }
  getClicksDetails() {
    let clicksReq = {
      "messageDataId": this._data.messageId,
      "reportType": this.filterForm.get('ROIReportingtype').value,
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.filterForm.get('searchText').value
    }
    this._fuseSplashScreenService.show();
    this.trackingService.getClicksDetails(clicksReq).subscribe((res:any) => {
      this._fuseSplashScreenService.hide();
      if (res && res.status == 200) {
        this.List = [];
        this.List = res.data;
        this.TotalCount = this.List.length;
        this.dataSource = new MatTableDataSource(this.List);
     }
    },error =>{
      this._fuseSplashScreenService.hide();
      this._toastr.error(error.error.error, 'Error');
    });
  }
  goToROISummaryDetails(type:any , report): void {
    this.DialogRef = this._matDialog.open(RoiSummaryDetailsComponent, {
      panelClass: 'roi-summary-details',
      data: {
        type:type,
        eventKey: type === 'pageView' ? 0 : type === 'abandonedCart' ? 3 : 2,
        campaignId: this._data.campaignId,
        messageId: this._data.messageId,
        contactId: report.contactId,
        from: this._data.from,
        to: this._data.to
      }
    });
    this.DialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
      });
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("roi-summary-details");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("roisummarydetail-content").style.maxHeight = "65vh";
    document.getElementById("roisummarydetail-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("roi-summary-details");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("roisummarydetail-content").style.maxHeight = "100vh";
    document.getElementById("roisummarydetail-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
