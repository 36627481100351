import { Injectable } from '@angular/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { RestService } from 'app/shared/rest.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BeePageBuilderService {
  private _unsubscribeAll: Subject<any>;
  onFilesChanged: BehaviorSubject<any>;
  onFileSelected: BehaviorSubject<any>;
  onSubFileSelected: BehaviorSubject<any>;
  showCurrentFolder = new BehaviorSubject<any>('');
  Files: any = [];
  pageNumber = 1;
  pageSize = 100;
  sortBy: string = "createdAt";
  sortDirection: string = "desc";
  TotalCount: number = 0;
  constructor(
    private _restService: RestService,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService
  ) {
    this.init();
  }

  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.init()
      ]).then(
        (res) => {
          resolve('init');
        },
        reject
      );
    });
  }

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve('initialized');
      this.Files = [];
      this.onFilesChanged = new BehaviorSubject({});
      this.onFileSelected = new BehaviorSubject({});
      this.onSubFileSelected = new BehaviorSubject(0);
      this.showCurrentFolder = new BehaviorSubject<any>('');
      this.pageNumber = 1;
        this.pageSize = 100;
        this.TotalCount = 0;
        this.sortBy = "createdAt";
        this.sortDirection = "desc";
    });
  }
  addEditPageBuilder(req: any) {
    return this._restService.Post('/Campaign/AddEditPageBuilder', req);
  }
  UpdatePagesMenuId(MenuId,PagesFileId) {
    return this._restService.Get('/Campaign/UpdatePagesMenuId?MenuId='+MenuId+'&PagesFileId='+PagesFileId);
  }
  GetAllPageBuilder(req: any) {
    return this._restService.Post('/Campaign/GetAllPageBuilder', req);
  }
  DeletePageBuilder(id) {
    return this._restService.Delete('/Campaign/DeletePageBuilder/' + id, null);
  }
  addEditPageBuilderTemplates(req:any){
    return this._restService.Post('/Campaign/AddEditPageBuilderTemplate',req)
  }
  getAllPageBuilderTemplates(req:any){
    return this._restService.Post('/Campaign/GetAllPageBuilderTemplate',req)
  }
  deletePageBuilderTemplate(id){
    return this._restService.Delete('/Campaign/DeletePageBuilderTemplate/'+id,null)
  }
  getAllPagesTemplateMenu(){
    return this._restService.Get('/Campaign/GetAllPagesTemplatesMenu');
  }
  addEditPageMenuTemplate(req:any){
    return this._restService.Post('/Campaign/AddEditPagesTemplatesMenu',req);
  }
  deleteMenu(id){
    return this._restService.Delete('/Campaign/DeletePagesTemplatesMenu/'+id,null)
  }
  UpdatePagesMenuTemplateId(MenuId,TemplateId) {
    return this._restService.Get('/Campaign/UpdatePagesTemplatesMenuId?MenuId='+MenuId+'&TemplateId='+TemplateId);
  }
  getBeePageTemplates(id){
    return this._restService.Get('/Campaign/GetAllPagesPredefineTemplate/' + id);
  }
  getAllPagesPredefineCategotyTemp(category) {
    return this._restService.Get('/Campaign/GetAllPagesPredefineCategotyTemp/' + category);
  }
  GetAllPagesPredefineCatogries() {
    return this._restService.Get('/Campaign/GetAllPagesPredefineCatogries');
  }
}
