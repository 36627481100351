import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { MatStepper } from '@angular/material';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { ContactsService } from 'app/main/pages/contacts/contacts.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { PageService } from 'app/main/pages/pages.service';
import { RestService } from 'app/shared/rest.service';

@Component({
    selector: 'import-contacts',
    templateUrl: './import-contacts.component.html',
    styleUrls: ['./import-contacts.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ImportContactsDialogComponent {  
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    public groupsList: any[] = [];
    public allGroupsList: any[] = [];
    public groupFilterCtrl: FormControl = new FormControl();
    groupForm: FormGroup;  
    storeData: any;
    fileUploaded: File;
    fileName: string = "";
    worksheet: any;
    sheetCols = [];
    showImport: boolean = false;
    fieldsForm: FormGroup;
    IsDialogMax:boolean =false; 
    mappingfields: any[] = [];
    private _unsubscribeAll: Subject<any>;
    @ViewChild('stepper', { static: true }) stepper: MatStepper;
    hostHeight: any;
    hostWidth: any;
    accountTypesByUser: any[] = [];
    constructor(
        public matDialogRef: MatDialogRef<ImportContactsDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _toastr: ToastrService,
        private _contactsService: ContactsService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _pageService: PageService,
        private _restService: RestService,
    ) {
        this._unsubscribeAll = new Subject();  
        this.mappingfields = this._contactsService.contactColumns;
        this.groupForm = this.createGroupForm();
        this.fieldsForm = this.createFieldsForm();
    }

    ngOnInit(): void {
        this.getAccountsTypesByUser(); 
        this.getAllgroups();
        if(this._data && this._data.groupId != undefined && this._data.groupId != null && this._data.groupId != 0) {
            this.groupForm.patchValue({
                groups: [this._data.groupId]
            });
        }
        this.groupFilterCtrl.valueChanges
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
            this.filterGroup();
        });
        this.groupForm.get('AllowWithoutCustomerId').valueChanges
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((value) => {
            Object.keys(this.fieldsForm.controls).forEach(field => {
                const control: any = this.fieldsForm.get(field);
                (control.controls).forEach(formgroup => {
                    const formcontrol: any = formgroup.get('id');
                    if (formcontrol instanceof FormControl) {
                        if(value) {
                            if (formcontrol.value == 'AssociateId') {
                                this._pageService.removeValidators(formgroup, 'column');
                            }
                            if(this.groupForm.get('UniqueColumnName').value == 'Email') {
                                if(formcontrol.value == 'email') {
                                    this._pageService.addValidators(formgroup, 'column');
                                }
                            }
                            else if(this.groupForm.get('UniqueColumnName').value == 'PhoneNumber'){
                                if(formcontrol.value == 'phoneNumber') {
                                    this._pageService.addValidators(formgroup, 'column');
                                }
                            }
                            
                        }
                        else {
                            if (formcontrol.value == 'AssociateId') {
                                this._pageService.addValidators(formgroup, 'column');
                            }
                            if(formcontrol.value == 'email' || formcontrol.value == 'phoneNumber'){
                                this._pageService.removeValidators(formgroup, 'column');
                            }
                        }
                    }
                })
            });
        });
        this.groupForm.get('UniqueColumnName').valueChanges
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((value) => {
            let fieldname = value == "Email" ? 'email' : (value == 'PhoneNumber'  ? 'phoneNumber' : '');
            Object.keys(this.fieldsForm.controls).forEach(field => {
                const control: any = this.fieldsForm.get(field);
                (control.controls).forEach(formgroup => {
                    const formcontrol: any = formgroup.get('id');
                    if (formcontrol instanceof FormControl) {
                        if(formcontrol.value == fieldname) {
                            this._pageService.addValidators(formgroup, 'column');
                        }
                        else if(formcontrol.value !== fieldname && (fieldname == 'email' || fieldname == 'phoneNumber')){
                            this._pageService.removeValidators(formgroup, 'column');
                        }
                    }
                })
            });
            
        });
        this.groupForm.get('OptoutContacts').valueChanges.subscribe(val => {
            if (val) {
              this.groupForm.controls['OptoutTypes'].setValidators([Validators.required]);
            } else {
              this.groupForm.controls['OptoutTypes'].clearValidators();
            }
            this.groupForm.controls['OptoutTypes'].updateValueAndValidity();
          });
        if(this.userDetails.baseProviderId === 2) {
            this.groupForm.controls['IsRep'].setValidators([Validators.required]);
            this.groupForm.controls['IsRep'].updateValueAndValidity();
        }else {
            this.groupForm.controls['IsRep'].clearValidators();
            this.groupForm.controls['IsRep'].updateValueAndValidity();
        }  
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
   getAllgroups() {
        this._fuseSplashScreenService.show();
        this._pageService.getAllGroups()
            .subscribe((res: any) => {
                this._fuseSplashScreenService.hide();
                if (res) {
                    this.allGroupsList = res.data;
                    this.groupsList = res.data;
                }
                else {
                    this.allGroupsList = [];
                    this.groupsList = [];
                }
            },
                error => {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error(error.message, 'Error');
                }
            );
    }
    createGroupForm(): FormGroup {
        return new FormGroup({
            groups: new FormControl([]),
            checkTCPA: new FormControl(false),
            AllowUpdateContacts: new FormControl(false),
            AllowWithoutCustomerId: new FormControl(false),
            UniqueColumnName: new FormControl('Email'),
            OptoutContacts: new FormControl(false),
            OptoutTypes: new FormControl(''),
            IsRep: new FormControl(),
            reVerification: new FormControl(false)
        });
    }
    filterGroup(): void {
        let search = this.groupFilterCtrl.value;
        if (search) {
            this.groupsList = this.allGroupsList.filter(x => (x.name.toLowerCase().indexOf(search.toLowerCase()) > -1));
        }
        else {
            this.groupsList = this.allGroupsList;
        }
    }
    goForward(stepper: MatStepper) {
        if(!this.fileName) {
            this._toastr.warning('Sheet not found ', 'Warning');
        }        
        else if(this.sheetCols.length < 1){
            this._toastr.warning('Sheet columns not found ', 'Warning');
        }
        else {
            stepper.next();
        }
    }
    goBack(stepper: MatStepper) {
        stepper.previous();
    }
    uploadedFile(event) {
        if (event.target.files.length != 0) {
            this.fileUploaded = event.target.files[0];
            this.fileName = this.fileUploaded.name;
            this.readExcel();
        }
    }
    readExcel() {
        let readFile = new FileReader();
        readFile.onload = (e) => {
            this.storeData = readFile.result;
            var data = new Uint8Array(this.storeData);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            let jsonData: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            let cols = [];
            if (jsonData.length > 0) {
                jsonData[0].forEach(function (key) {
                    cols.push(key);
                });
                this.sheetCols = cols;
                this.sheetCols.unshift('');
            }
        }
        readFile.readAsArrayBuffer(this.fileUploaded);
    }
    
    createFieldsForm(): FormGroup {
        return this._formBuilder.group({
            mappingfield: this._formBuilder.array(this.fieldsDetails())
        });
    }

    fieldsDetails(): any {
        let fieldsArray = [];
            this.mappingfields.forEach((obj: any) => {
                if(obj.fieldName == 'AssociateId'){
                    fieldsArray.push(this._formBuilder.group({
                        id: [obj.fieldName],
                        name: [obj.label],
                        column: ['', [Validators.required]]
                    }));
                }
                else {
                    fieldsArray.push(this._formBuilder.group({
                        id: [obj.fieldName],
                        name: [obj.label],
                        column: ['']
                    }));
                }           
            });
             
        return fieldsArray;
    }
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control: any = formGroup.get(field);
            if (control instanceof FormControl) {
                    control.markAsTouched({ onlySelf: true });
            } else {
                this.validateAllFormFields(control);
            }
        });
    }
    import() {
        if(!this.fieldsForm.valid){
            this.validateAllFormFields(this.fieldsForm);
            return false;
        }

        let mappingfieldsArr =  this.fieldsForm.getRawValue().mappingfield;
        let formData: FormData = new FormData();
        formData.append('formFile', this.fileUploaded);
        let groupIds = [];
        this.groupForm.get('groups').value.forEach(function (obj) {
            formData.append('mappingFields.GroupIds[]', obj.toString());
        });
        //formData.append('mappingFields.GroupIds', JSON.stringify(groupIds));
        mappingfieldsArr.forEach(function (obj) {
            formData.append('mappingFields.Fields.' + obj.id, obj.column);
        });
        formData.append('AllowUpdateContacts', this.groupForm.get('AllowUpdateContacts').value);
        formData.append('reVerification', this.groupForm.get('reVerification').value);
        formData.append('AllowWithoutCustomerId', this.groupForm.get('AllowWithoutCustomerId').value);
        if(this.groupForm.get('AllowWithoutCustomerId').value) {
            formData.append('UniqueColumnName', this.groupForm.get('UniqueColumnName').value);
        }
        formData.append('OptoutContacts', this.groupForm.get('OptoutContacts').value);
        if(this.groupForm.get('OptoutContacts').value) {
            formData.append('OptoutTypes', this.groupForm.get('OptoutTypes').value);
        }
        if(this.userDetails.baseProviderId === 2) {
            if(this.groupForm.controls['IsRep'].status == "INVALID") {
                this.groupForm.get('IsRep').markAsTouched();
                return false;
            }
            formData.append('IsRep', this.groupForm.get('IsRep').value);
        }
        if (!this.groupForm.get('checkTCPA').value) {
            this._toastr.warning('Please accept the policies', 'Warning');
            return false;
        }
        this._fuseSplashScreenService.show();
        this._contactsService.importContacts(formData).subscribe(
            (res: any) => {
              if (res.status == "200") {
                this._fuseSplashScreenService.hide();
                this._toastr.success(res.data, 'Success');
                this.matDialogRef.close('imported');
              }
              else {
                this._fuseSplashScreenService.hide();
                this._toastr.error(res.error, 'Error');
              }
            },
            error => {
              this._fuseSplashScreenService.hide();
              this._toastr.error(error.error.error, 'Error');
            }
          );
    }
    getAccountsTypesByUser() {
        this._restService.Get('/UnifiedType/GetUnifiedTypesForUser')
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        response.data.forEach(element => {
                            if(element.id<4) {
                                this.accountTypesByUser.push(element);
                            }
                        });
                    }else {
                        this.accountTypesByUser = [];
                    }
                })
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("import-contacts-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width =  (this.hostWidth+1+'px');
            elements[i].style.height =  (this.hostHeight+1+'px');
        }
        document.getElementById("impcontact-content").style.maxHeight = "65vh";
        document.getElementById("impcontact-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("import-contacts-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("impcontact-content").style.maxHeight = "100vh";
        document.getElementById("impcontact-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
     }

}

