import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { cloneDeep } from 'lodash';
import { CampaignsService } from 'app/main/pages/campaigns/campaigns.service';
import { JourneyService } from 'app/main/pages/journeys/journey.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-campaign-list',
    templateUrl: './campaign-list.component.html',
    styleUrls: ['./campaign-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default
})
export class CamapignListComponent implements OnInit, AfterViewInit{
    IsDialogMax: boolean = false;
    hostHeight: any;
    hostWidth: any;
    dialogTitle: string = 'Campaign List';
    dialogRef: any;
    blastList: any[] = [];
    camData: any[] = [];
    campaignId: FormControl = new FormControl('');
    campEventType: any = '';
    private _unsubscribeAll: Subject<any>;
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
    constructor(
        public matDialogRef: MatDialogRef<CamapignListComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public _matDialog: MatDialog,
        private _campaignsService: CampaignsService,
        private _pageService: PageService,
        public chRef: ChangeDetectorRef,
        private _journeyService: JourneyService,
    ) {
      // this.eventTypes = this._data.eventTypes;
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
      if(this._data.campaignEventTypes.length > 0) {
        this._data.campaignEventTypes.forEach(eventType => {
            if(this._data.campaignEventTypeId == eventType.id) {
              this.campEventType = eventType.name;
            }
        });
      }
      this.getCampaigns();
      if(this._data.pageName == 'journeyFromCampaign') {
        this.campaignId.setValue(this._data.campaignId);
        this.campaignId.disable();
      }else {
        this.campaignId.enable();
      }
    }
    ngAfterViewInit(): void {
      this.chRef.detectChanges();
    }
    getCampaigns() {
        this._campaignsService.getCampaigns()
          .subscribe((res: any) => {
            if (res.status == 200) {
              this.blastList = res.data.report;
               var camDataList = cloneDeep(this.blastList);
                camDataList.forEach((element, index) => {
                var deliveryMethod = [];
                if(element.unifyTypeIds.length>0) {
                  element.unifyTypeIds.forEach((unifiedId, i) => {
                    this._data.accountTypes.forEach((accItem, j)=> {
                      if(accItem.id == unifiedId) {
                        if(deliveryMethod.indexOf(accItem.name) == -1) {
                          deliveryMethod.push(accItem.name);
                        }
                      }
                    });
                  });
                }
                element.deliveryMethod = deliveryMethod;
              });

            }
            this.camData = camDataList.filter(item => (item.campaignType == "Blast") && (item.eventType == this.campEventType));
          },
            error => {
              this._toastr.error(error.message, 'Error');
            }
        );
    }
    addCampaignTitle() {
      if(this.campaignId.value != null && this.campaignId.value != undefined && this.campaignId.value != '') {
        this._journeyService.AssignJourneytoCampaign(this._data.journeyId,this.campaignId.value).subscribe((res: any) => {
          if (res.status == 200) {
            this._toastr.success('Journey created successfully', 'Success');
            this.matDialogRef.close(res);
          }
        },
          error => {
            this._toastr.error(error.message, 'Error');
      });
      }else {
        this._toastr.warning("Select any campaign", 'Warning')
        return false;
      }
      
    }
    minimizeDialog() {
        let elements: any = document.getElementsByClassName("campaignList-dialog");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.width = (this.hostWidth + 1 + 'px');
            elements[i].style.height = (this.hostHeight + 1 + 'px');
        }
        document.getElementById("campaignList-content").style.maxHeight = "65vh";
        document.getElementById("campaignList-wrapper").style.maxHeight = "85vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
    maximizeDialog() {
        let elements: any = document.getElementsByClassName("campaignList-dialog");
        for (var i = 0; i < elements.length; i++) {
            this.hostHeight = elements[i].clientHeight;
            this.hostWidth = elements[i].clientWidth;
            elements[i].style.width = ("100%");
            elements[i].style.height = ("100%");
        }
        document.getElementById("campaignList-content").style.maxHeight = "100vh";
        document.getElementById("campaignList-wrapper").style.maxHeight = "100vh";
        this.IsDialogMax = !this.IsDialogMax;
    }
}

