import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeLastBR' })
export class removeLastBR implements PipeTransform {
    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} args
     * @returns {string}
     */
    transform(value: string, ): string {
        if (value) {
            var lastindex = value.lastIndexOf('<br>');
            return value.slice(0,lastindex) + value.slice(lastindex+4);
        }
        else {
            return value;
        }
    }
}
