import { Component, OnInit, ViewEncapsulation, ViewChild, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { PageService } from '../../pages.service';
import { CampaignsService } from '../campaigns.service';
import * as moment from "moment-timezone";
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { MailService } from '../../mail/mail.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { objectToParams } from 'app/constants';
import { subDays } from 'date-fns';
import { ContactsService } from '../../contacts/contacts.service';
import { ContactsFiltersComponent } from '../../contacts/dialogs/contacts-filters/contacts-filters.component';
import { RestService } from 'app/shared/rest.service';
import { ContactListFilterComponent } from '../../contacts/dialogs/contact-list-filter/contact-list-filter.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';
import { AddJourneyFromCampaignComponent } from '../../journeys/add-journey-fromCampaign/add-journey-fromCampaign.component';
import { JourneyService } from '../../journeys/journey.service';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class AddCampaignComponent implements OnInit {
  dialogTitle: string = "Add Campaign";
  offset = new Date().getTimezoneOffset();
  filterOffset = (this.offset >= 720) ? 719 : (this.offset < -720) ? -600 : this.offset;
  extraMessagesCount: number = 0;
  extraFields: any[] = [];
  isExit: boolean = false;
  IsDialogMax: boolean = false;
  userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData"));
  checkTCPA: FormControl;
  campaignId: number;
  blastResponse: any;
  dripResponse: any;
  notificationTypeName: any;
  notificationId:any;
  allNotificationType:any;
  isActiveStatus:any = false;
  resumeNewMes:boolean = false;
  intervalTimeUnit: boolean = true;
  filterContactsCount: any;
  dialogRef: any;
  saveContinue: boolean = false;
  media: any = {
    mediaList: [],
    mediaFilterCtrl: new FormControl()
  };
  accountTypes: any = [];
  createFormGroup: FormGroup;
  messageOptionsFormGroup: FormGroup;
  moreOptionFormGroup: FormGroup;
  dripScheduleFormGroup: FormGroup;
  blastScheduleFormGroup: FormGroup;
  templates: any = {
    emailTemplates: [],
    smsTemplates: [],
    shortCodeTemplates: [],
    mmsTemplates: [],
    internationalSMSMMSTemplates: [],
    whatsappTemplates: [],
    lineTemplates: [],
    fbTemplates: [],
    telegramTemplates: [],
    notificationTemplates: [],
    verbNotificationTemplates: [],
    nowTechNotificationTemplates: [],
    shoutTemplates: []
  };
 public customFieldOptions: any = [];
 public mergeTagArray: any = [];

  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  fields: any[] = [
    {
      sequence: 1,
      name: "Message 1",
    },
    {
      sequence: 2,
      name: "Message 2",
    },
    {
      sequence: 3,
      name: "Message 3",
    },
    {
      sequence: 4,
      name: "Message 4",
    },
    {
      sequence: 5,
      name: "Message 5",
    },
    {
      sequence: 6,
      name: "Message 6",
    },
    {
      sequence: 7,
      name: "Message 7",
    },
    {
      sequence: 8,
      name: "Message 8",
    },
    {
      sequence: 9,
      name: "Message 9",
    },
    {
      sequence: 10,
      name: "Message 10",
    }
  ];
  private _unsubscribeAll: Subject<any>;
  hostHeight: any;
  hostWidth: any;
  appliedFilters: any;
  emailData: any = '';
  isJourneyButton: boolean = false;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  constructor(
    public matDialogRef: MatDialogRef<AddCampaignComponent>,
    private _formBuilder: FormBuilder,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _campaignsService: CampaignsService,
    private _pageService: PageService,
    private _mailService: MailService,
    public chRef: ChangeDetectorRef,
    public _matDialog: MatDialog,
    private _contactsService: ContactsService,
    private _restService: RestService,
    private _journeyService: JourneyService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    this.mergeTagData();
    this.dialogTitle = (this._data && this._data.dialogTitle) ? this._data.dialogTitle : this.dialogTitle;
    this._contactsService.appledFilters = new BehaviorSubject<any>(null);
    this._unsubscribeAll = new Subject();
    this.checkTCPA = new FormControl(false);
    this.createFormGroup = this._formBuilder.group({
      campaignname: [_data.pageType == 'FlowPage' ? _data.flowForm.campaignname : '', [Validators.required]],
      accounttypes: [_data.pageType == 'FlowPage' ? _data.flowForm.accounttypes : []],
      trigger: [_data.pageType == 'FlowPage' ? _data.flowForm.trigger : []],
      report: [_data.pageType == 'FlowPage' ? _data.flowForm.report : ''],
      eventtype: [_data.pageType == 'FlowPage' ? _data.flowForm.eventtype : '', [Validators.required]],
      campaigntype: [_data.pageType == 'FlowPage' ? _data.flowForm.campaigntype : '', [Validators.required]],
      contactitems: [_data.pageType == 'FlowPage' ? _data.flowForm.contactitems : []],
      groups: [_data.pageType == 'FlowPage' ? _data.flowForm.groups : []],
      groupForOptIn: [_data.pageType == 'FlowPage' ? _data.flowForm.groupForOptIn : ''],
      arTemplateId: [_data.pageType == 'FlowPage' ? _data.flowForm.arTemplateId : ''],
    });
    this.messageOptionsFormGroup = this._formBuilder.group({
      subject: ['', [Validators.required]],
      emailEditor: [''],
      messageJson: [this._pageService.defaultTemplate],
      smsMessage: [''],
      internationalSMSMMSMessage: [''],
      internationalSMSMMSOptOutTrailor: ['Reply STOP to stop'],
      smsOptOutTrailor: ['Reply STOP to stop'],
      shortCodeMessage: [''],
      shortCodeOptOutTrailor: ['Reply STOP to stop'],
      fbMessage: [''],
      fbOptOutTrailor: ['Reply STOP to stop'],
      telegramMessage: [''],
      telegramOptOutTrailor: ['Reply STOP to stop'],
      lineMessage: [''],
      lineOptOutTrailor: ['Reply STOP to stop'],
      whatsappMessage: [''],
      whatsappOptOutTrailor: ['Reply STOP to stop'],
      notificationMessage: [''],
      allNotificationType: '',
      emailTemplate: [0],
      smsTemplate: [0],
      internationalSMSMMSTemplate: [0],
      shortCodeTemplate: [0],
      mmsTemplate: [0],
      fbTemplate: [0],
      lineTemplate: [0],
      whatsappTemplate: [0],
      telegramTemplate: [0],
      notificationTemplate: [0],
      verbNotificationTemplate: [0],
      emailTinyUrl: [''],
      smsTinyUrl: [''],
      internationalSMSMMSTinyUrl: [''],
      shortCodeTinyUrl: [''],
      fbTinyUrl: [''],
      lineTinyUrl: [''],
      whatsappTinyUrl: [''],
      telegramTinyUrl: [''],
      notificationTinyUrl: [''],
      mediaURL: [''],
      internationalSMSMMSMediaURL: [''],
      whatsAppMediaUrl: [''],
      lineMediaUrl: [''],
      telegramMediaUrl: [''],
      shortCodemediaURL: [''],
      emailLinks: this._formBuilder.array([]),
      beeLinks: this._formBuilder.array([]),
      smsLinks: this._formBuilder.array([]),
      internationalSMSMMSLinks: this._formBuilder.array([]),
      shortCodeLinks: this._formBuilder.array([]),
      whatsappLinks: this._formBuilder.array([]),
      lineLinks: this._formBuilder.array([]),
      fbLinks: this._formBuilder.array([]),
      telegramLinks: this._formBuilder.array([]),
      notificationLinks: this._formBuilder.array([]),
      isBeeEditor: new FormControl(false),
      notificationJson: [this._pageService.defaultTemplate],
      isNotificationBeeEditor: new FormControl(false),
      notificationBeeLinks: this._formBuilder.array([]),
      domainType: ['0'],
      domainTypeSMS: ['0'],
      domaintypeInternationalSMS: ['0'],
      attachments: [[]],
      emailPreview: [''],
      prefix: ['', [Validators.pattern('[a-zA-Z]*')]],
      replyTo: [''],
      verbNotificationMessage: [''],
      verbNotificationSubject: [''],
      verbNotificationLinks: this._formBuilder.array([]),
      nowTechNotificationMessage: [''],
      nowTechNotificationSubject: [''],
      nowTechNotificationLinks: this._formBuilder.array([]),
      nowTechNotificationTemplate: [0],
      newIsBeeEditor: new FormControl(false),
      newIsNotificationBeeEditor: new FormControl(false),
      shoutMessage: [''],
      shoutLinks: this._formBuilder.array([]),
      shoutTemplate: [0],
      shoutmediaURL: [''],
      notificationTypeId: [0]
    });

    this.moreOptionFormGroup = this._formBuilder.group({
      messagesArray: this.addInitialDripMessages()
    });
    this.dripScheduleFormGroup = this._formBuilder.group({
      startingDate: [new Date(), [Validators.required]],
      startingTime: ['00:00', [Validators.required]],
      endingDate: [new Date(), [Validators.required]],
      endingTime: ['00:00', [Validators.required]],
      primaryColor: ['#1e90ff'],
      secondaryColor: ['#D1E8FF'],
      timeZone: [this.userDetails.timeZone ? this.userDetails.timeZone : 'UTC', [Validators.required]]
    });

    this.blastScheduleFormGroup = this._formBuilder.group({
      Immediatecheck: [false],
      recurringCheck: [false],
      onGoingcheck: [false],
      compaingnTocalender: [false],
      deliverDate: [subDays(new Date(), 1), [Validators.required]],
      deliverTime: ['00:00', [Validators.required]],
      endDate: [new Date(), [Validators.required]],
      endTime: ['00:00', [Validators.required]],
      at: ['00:00'],
      day: ['1'],
      primaryColor: ['#1e90ff'],
      secondaryColor: ['#D1E8FF'],
      timeZone: [this.userDetails.timeZone ? this.userDetails.timeZone : 'UTC', [Validators.required]],
      hasJourney: [false]
    });
  }

  ngOnInit() {
    this.isJourneyButton = false;
    if (this._data && this._data.campaign) {
      this.getCampaignById(this._data.campaign.id);
    }
    this._pageService.getAccountsTypesByUser();
    this._pageService.onAccountTypeByUserChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(types => {
        if (types) {
          this.accountTypes = types;
        }
        else {
          this.accountTypes = [];
        }
      });
    this.getTemplates();
    this._pageService.getMediaFiles('');
    this._pageService.onMediaFilesChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(list => {
      this.media.mediaList = list;
    });
    this.media.mediaFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll),debounceTime(1000),distinctUntilChanged()).subscribe((search) => {
      this._pageService.getMediaFiles(search);
      });
    this.createFormGroup.get('accounttypes').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((types) => {
        this.accountValidations(types);
      });
    this.createFormGroup.get('eventtype').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((type) => {
        if (type == 3 || type == 5 || type == 6) {
          this.fields.forEach((obj: any, index: number) => {
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(1) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(21) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField'), "timeUnit");
            }
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(2) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(7) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(11) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(12) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(15) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(16) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(22) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(23) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField'), "timeUnit");
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(3) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField'), "timeUnit");
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(4) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField'), "timeUnit");
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(5) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField'), "timeUnit");
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(6) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField'), "timeUnit");
            }
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(8) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(24) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField'), "timeUnit");
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(9) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField'), "timeUnit");
            }
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(13) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(14) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(17) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField'), "timeUnit");
            }
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(18) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField'), "timeUnit");
            }
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(19) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField'), "timeUnit");
            }
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(20) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['isImmediate'].value) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField'), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField'), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField'), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField'), "timeUnit");
            }
          });
        }
      });
      this._campaignsService.isJourney.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
        if(val) {
          this.isJourneyButton = val;
          this.chRef.detectChanges();
        }else {
          this.isJourneyButton = false;
        }
      });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  addFieldElements(index: number) {
    return this._formBuilder.group({
      id:null,
      sequence: [index + 1],
      messageText: ['', [Validators.required]],
      messageJson: [this._pageService.defaultTemplate],
      optOutTrailor: ['Reply STOP to stop', [Validators.required]],
      sendDate: [new Date()],
      sendTime: ['00:00'],
      timeZone: [this.userDetails.timeZone ? this.userDetails.timeZone : 'UTC'],
      interval: [''],
      timeUnit: [''],
      messageTinyURL: [''],
      links: this._formBuilder.array([]),
      mediaURL: [''],
      template: [0],
      moreTemplate: [0],
      isImmediate: [index == 0 ? true : false],
      subject: ['', [Validators.required]],
      isDripBeeEditor: [false],
      beeLinks: this._formBuilder.array([]),
      isBeeEditor: [false],
      notificationTypeId:[0],
      notificationJson: [this._pageService.defaultTemplate],
      notificationBeeLinks: this._formBuilder.array([]),
      isNotificationBeeEditor: [false],
      isNotificationDripBeeEditor: [false],
      domainType: ['0'],
      attachments:[[]],
      useInterval: [false],
      emailPreview: [''],
      prefix: ['', [Validators.pattern('[a-zA-Z]*')]],
      browserLink: [''],
      replyTo: [''],
      newIsDripBeeEditor: [false],
      newIsNotificationDripBeeEditor: [false],
      isMessageTop: [true]
    })
  }
  addInitialDripMessages() {
    let intialArray = this._formBuilder.array([]);
    this.fields.forEach((obj: any, index: number) => {
      intialArray.push(this._formBuilder.group({
        deliveryTypeIDs: [[]],
        EmailField: this.addFieldElements(index),
        SMSField: this.addFieldElements(index),
        WhatsappField: this.addFieldElements(index),
        LineField: this.addFieldElements(index),
        FacebookField: this.addFieldElements(index),
        TelegramField: this.addFieldElements(index),
        ShortCodeField: this.addFieldElements(index),
        NotificationField: this.addFieldElements(index),
        InternationalSMSMMSField: this.addFieldElements(index),
        VerbNotificationField: this.addFieldElements(index),
        NowTechNotificationField: this.addFieldElements(index),
        ShoutField: this.addFieldElements(index)
      }));
    });
    return intialArray;
  }
  accountValidations(accArray) {
    if (accArray.length > 0) {
      if (accArray.indexOf(1) !== -1 || accArray.indexOf(21) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "emailEditor");
        this._pageService.addValidators(this.messageOptionsFormGroup, "subject");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "emailEditor");
        this.removeValidators(this.messageOptionsFormGroup, "subject");
      }
      if (accArray.indexOf(2) !== -1 || accArray.indexOf(11) !== -1 || accArray.indexOf(15) !== -1 || accArray.indexOf(22) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "smsMessage");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "smsMessage");
      }
      if (accArray.indexOf(8) !== -1 || accArray.indexOf(24) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "shortCodeMessage");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "shortCodeMessage");
      }
      if (accArray.indexOf(3) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "whatsappMessage");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "whatsappMessage");
      }
      if (accArray.indexOf(4) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "lineMessage");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "lineMessage");
      }
      if (accArray.indexOf(5) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "fbMessage");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "fbMessage");
      }
      if (accArray.indexOf(6) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "telegramMessage");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "telegramMessage");
      }
      if (accArray.indexOf(7) !== -1 || accArray.indexOf(12) !== -1 || accArray.indexOf(16) !== -1 || accArray.indexOf(23) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "mediaURL");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "mediaURL");
      }
      if (accArray.indexOf(9) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "notificationMessage");
        this._pageService.addValidators(this.messageOptionsFormGroup, "allNotificationType");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "notificationMessage");
        this.removeValidators(this.messageOptionsFormGroup, "allNotificationType");
      }
      if (accArray.indexOf(13) !== -1 || accArray.indexOf(14) !== -1 || accArray.indexOf(17) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "internationalSMSMMSMessage");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "internationalSMSMMSMessage");
      }
      if (accArray.indexOf(18) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "verbNotificationMessage");
        this._pageService.addValidators(this.messageOptionsFormGroup, "verbNotificationSubject");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "verbNotificationMessage");
        this.removeValidators(this.messageOptionsFormGroup, "verbNotificationSubject");
      }
      if (accArray.indexOf(19) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "nowTechNotificationMessage");
        this._pageService.addValidators(this.messageOptionsFormGroup, "nowTechNotificationSubject");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "nowTechNotificationMessage");
        this.removeValidators(this.messageOptionsFormGroup, "nowTechNotificationSubject");
      }
      if (accArray.indexOf(20) !== -1) {
        this._pageService.addValidators(this.messageOptionsFormGroup, "shoutMessage");
      }
      else {
        this.removeValidators(this.messageOptionsFormGroup, "shoutMessage");
      }
    }
  }
  getTemplates() {
    this._fuseSplashScreenService.show();
    this._mailService.getAllTemplates()
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200 && res.data) {
          this.templates.emailTemplates = res.data.filter(x => (((x.unifiedTypeId == 1 || x.unifiedTypeId == 21) && !x.isBeeEditor) || ((x.unifiedTypeId == 1 || x.unifiedTypeId == 21) && x.isPredefined)));
          this.templates.smsTemplates = res.data.filter(x => ((x.unifiedTypeId == 2) || (x.unifiedTypeId == 11) || (x.unifiedTypeId == 15) || (x.unifiedTypeId == 22)));
          this.templates.shortCodeTemplates = res.data.filter(x => ((x.unifiedTypeId == 8) || (x.unifiedTypeId==24)));
          this.templates.whatsappTemplates = res.data.filter(x => (x.unifiedTypeId == 3));
          this.templates.lineTemplates = res.data.filter(x => (x.unifiedTypeId == 4));
          this.templates.fbTemplates = res.data.filter(x => (x.unifiedTypeId == 5));
          this.templates.telegramTemplates = res.data.filter(x => (x.unifiedTypeId == 6));
          this.templates.mmsTemplates = res.data.filter(x => ((x.unifiedTypeId == 7) || (x.unifiedTypeId == 12) || (x.unifiedTypeId == 16) || (x.unifiedTypeId == 23)));
          this.templates.internationalSMSMMSTemplates = res.data.filter(x => ((x.unifiedTypeId == 13) || (x.unifiedTypeId == 14) || (x.unifiedTypeId == 17))); 
          this.templates.notificationTemplates = res.data.filter(x => ((x.unifiedTypeId == 9 && !x.isNotificationBeeEditor) || (x.unifiedTypeId == 9 && x.isPredefined)));
          this.templates.verbNotificationTemplates = res.data.filter(x => (x.unifiedTypeId == 18));
          this.templates.nowTechNotificationTemplates = res.data.filter(x => (x.unifiedTypeId == 19));
          this.templates.shoutTemplates = res.data.filter(x => (x.unifiedTypeId == 20));
        }
      },
        error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.message, 'Error');
        }
      );
  }
  getExtraMessageArray() {
    return this.moreOptionFormGroup.get('messagesArray') as FormArray;
  }
  patchFieldElements(sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, deliveryTypeId) {
    if (deliveryTypeId == 2 || deliveryTypeId == 11 || deliveryTypeId == 15 || deliveryTypeId == 22 ) {
      var obj = campaignAdvanceOptions.find(e => (e.sequence == sequence && (e.deliveryTypeId == 2 || e.deliveryTypeId == 7 || e.deliveryTypeId == 11 || e.deliveryTypeId == 12 || e.deliveryTypeId == 15 || e.deliveryTypeId == 16 || e.deliveryTypeId == 22 || e.deliveryTypeId == 23)));
    }
    else {
      var obj = campaignAdvanceOptions.find(e => (e.sequence == sequence && (e.deliveryTypeId == deliveryTypeId)));
    }
    if (obj) {
      return this._formBuilder.group({
        id: [obj.id],
        sequence: [obj.sequence],
        messageText: [obj.messageText, [Validators.required]],
        messageJson: [obj.messageJson ? JSON.parse(obj.messageJson) : this._pageService.defaultTemplate],
        optOutTrailor: [obj.optOutTrailor, [Validators.required]],
        sendDate: [obj.sendDate ? obj.sendDate : new Date()],
        sendTime: [obj.sendDate ? moment(obj.sendDate).format("HH:mm") : '00:00'],
        timeZone: [obj.timeZone ? obj.timeZone : this.userDetails.timeZone ? this.userDetails.timeZone : 'UTC'],
        interval: [obj.interval, ((this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6) && !obj.isImmediate) ? [Validators.required] : []],
        timeUnit: [obj.timeUnit, ((this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6) && !obj.isImmediate) ? [Validators.required] : []],
        messageTinyURL: [obj.tinyURL],
        links: obj.isBeeEditor ? [[]] : this.getAdvancedItemsLinks(obj.links),
        mediaURL: [obj.mediaURL],
        template: [0],
        moreTemplate: [0],
        isImmediate: [obj.isImmediate],
        subject: [obj.subject, [Validators.required]],
        isDripBeeEditor: [obj.isBeeEditor ? true : false],
        beeLinks: obj.isBeeEditor ? this.getAdvancedItemsLinks(obj.links) : [[]],
        isBeeEditor: [obj.isBeeEditor ? true : false],
        notificationId : [0],
        isNotificationDripBeeEditor: [(isNotificationDripBeeEditor && deliveryTypeId == 9)],
        domainType: [obj.domainType.toString()],
        attachments: [obj.attachments],
        useInterval: [obj.useInterval],
        prefix: [obj.prefix],
	      emailPreview: [deliveryTypeId == 1 ? obj.emailPreview : ''],
        browserLink: [obj.browserLink],
        replyTo: [obj.replyTo],
        newIsDripBeeEditor: [(obj.isBeeEditor && obj.isBeeEditor == true) ? false : true],
        newIsNotificationDripBeeEditor: [((isNotificationDripBeeEditor && deliveryTypeId == 9)) ? false : true],
        isMessageTop: [true]
      })
    }
    else {
      return this._formBuilder.group({
        id:null,
        sequence: [sequence],
        messageText: ['', [Validators.required]],
        messageJson: [this._pageService.defaultTemplate],
        optOutTrailor: ['Reply STOP to stop', [Validators.required]],
        sendDate: [new Date()],
        sendTime: ['00:00'],
        timeZone: [this.userDetails.timeZone ? this.userDetails.timeZone : 'UTC'],
        interval: ['', (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6) ? [Validators.required] : []],
        timeUnit: ['', (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6) ? [Validators.required] : []],
        messageTinyURL: [''],
        links: this._formBuilder.array([]),
        mediaURL: [''],
        template: [0],
        moreTemplate: [0],
        isImmediate: [false],
        subject: ['', [Validators.required]],
        isDripBeeEditor: [false],
        beeLinks: this._formBuilder.array([]),
        isBeeEditor: [false],
        notificationId: [0],
        isNotificationDripBeeEditor: [false],
        domainType: ['0'],
        attachments: [[]],
        useInterval: [false],
        prefix: ['', [Validators.pattern('[a-zA-Z]*')]],
	      emailPreview: [''],
        browserLink: [''],
        replyTo: [''],
        newIsDripBeeEditor: [false],
        newIsNotificationDripBeeEditor: [false],
        isMessageTop: [true]
      })
    }
  }
  groupBy(objectArray, property, field) {
    return objectArray.reduce((acc, obj) => {
       const key = obj[property];
       if (!acc[key]) {
          acc[key] = [];
       }
       // Add object to list for given key's value
       acc[key].push(obj[field]);
       return acc;
    }, {});
 }
  addExtraMessages(newFields, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor) {
    let seqWiseDeliveryId =  this.groupBy(campaignAdvanceOptions, 'sequence', 'deliveryTypeId');
    newFields.forEach((obj: any, index: number) => {
      this.getExtraMessageArray().push(this._formBuilder.group({
        deliveryTypeIDs: [seqWiseDeliveryId[obj.sequence]],
        EmailField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 1),
        SMSField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 2),
        WhatsappField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 3),
        LineField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 4),
        FacebookField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 5),
        TelegramField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 6),
        ShortCodeField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 8),
        NotificationField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 9),
        InternationalSMSMMSField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 13),
        VerbNotificationField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 18),
        NowTechNotificationField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 19),
        ShoutField: this.patchFieldElements(obj.sequence, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor, 20)
      }));
    });
  }
  emailDataPreview(fieldName, obj) {
    if(fieldName == "EmailField") {
      var emailDataHtml =  obj.messageText;
      var doc = new DOMParser().parseFromString(emailDataHtml, "text/html");
      var x = doc.body.getElementsByTagName("style");
      for (var i = x.length - 1; i >= 0; i--) {
        x[i].parentElement.removeChild(x[i]);
      }
      if(doc.body && doc.body.firstElementChild) {
          if(doc.body.firstElementChild.tagName == 'STYLE') {
              doc.body.firstElementChild.remove();
              if(doc.body.textContent) {
                let data = doc.body.textContent;
                this.emailData = data.toString().replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g," ").slice(0, 200);
            }
          }else {
            if(doc.body.textContent) {
              let data = doc.body.textContent;
              this.emailData = data.toString().replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g," ").slice(0, 200);
              }
          }
        }
    }     
  }
  patchDripAdnanceArray(campaignAdvanceOptions, fieldsArray, fieldType, isDripBeeEditor, isNotificationDripBeeEditor) {
    let extraFields = [];
    let seqWiseDeliveryId =  this.groupBy(campaignAdvanceOptions, 'sequence', 'deliveryTypeId');
    campaignAdvanceOptions.forEach((obj: any, optionIndex: number) => {   
      if(obj && obj['links'] && obj['links'].length > 0) {
        let linksData = obj['links'].filter((data, index) => {
          return index === obj['links'].findIndex(item => data.name === item.name);
        });
        obj.links = linksData;
      }
      this.fields.forEach((fieldsObj: any, fieldIndex: number) => {
        let fieldName;
        switch (obj.deliveryTypeId) {
          case 1:
            fieldName = 'EmailField';
            this.emailDataPreview(fieldName, obj);
            break;
          case 2:
            fieldName = 'SMSField';
            break;
          case 3:
            fieldName = 'WhatsappField';
            break;
          case 4:
            fieldName = 'LineField';
            break;
          case 5:
            fieldName = 'FacebookField';
            break;
          case 6:
            fieldName = 'TelegramField';
            break;
          case 7:
            fieldName = 'SMSField';
            break;
          case 8:
            fieldName = 'ShortCodeField';
            break;
          case 9:
            fieldName = 'NotificationField';
            break;
          case 11:
            fieldName = 'SMSField';
            break;
          case 12:
            fieldName = 'SMSField';
            break;
          case 13:
            fieldName = 'InternationalSMSMMSField';
            break;
          case 14:
            fieldName = 'InternationalSMSMMSField';
            break;
          case 15:
            fieldName = 'SMSField';
            break;
          case 16:
            fieldName = 'SMSField';
            break;
          case 17:
            fieldName = 'InternationalSMSMMSField';
            break;
          case 18:
            fieldName = 'VerbNotificationField';
            break;
          case 19:
            fieldName = 'NowTechNotificationField';
            break;
          case 20:
            fieldName = 'ShoutField';
            break;
          case 21:
            fieldName = 'EmailField';
            this.emailDataPreview(fieldName, obj);
            break;
          case 22:
            fieldName = 'SMSField';
            break;
          case 23:
            fieldName = 'SMSField';
            break;  
          case 24:
            fieldName = 'ShortCodeField';
            break;                   
          default:
            break; 
        };
        if (fieldsObj.sequence == obj.sequence) {
          let attachItem = [];
          if (obj.attachments && obj.attachments.length > 0) {
            obj.attachments.forEach(element => {
              let attachData = element.split(' ', 2);
              attachItem.push(attachData[0]);
            });
          }
          this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get('deliveryTypeIDs').patchValue(seqWiseDeliveryId[obj.sequence]);
          this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName).patchValue({
            id: obj.id,
            sequence: obj.sequence,
            subject: obj.subject,
            messageText: obj.messageText,
            optOutTrailor: obj.optOutTrailor,
            sendDate: obj.sendDate ? obj.sendDate : new Date(),
            sendTime: obj.sendDate ? moment(obj.sendDate).format("HH:mm") : '00:00',
            timeZone: obj.timeZone ? obj.timeZone : this.userDetails.timeZone ? this.userDetails.timeZone : 'UTC',
            isImmediate: obj.isImmediate,
            interval: obj.interval,
            timeUnit: obj.timeUnit,
            messageTinyURL: obj.tinyURL,
            messageJson: obj.messageJson ? JSON.parse(obj.messageJson) : this._pageService.defaultTemplate,
            isBeeEditor: obj.isBeeEditor ? obj.isBeeEditor : false,
            // isDripBeeEditor: obj.isBeeEditor ? obj.isBeeEditor : false,
            isDripBeeEditor: (obj.isBeeEditor && fieldName == 'EmailField') ? obj.isBeeEditor : false,
            mediaURL: obj.mediaURL,
            notificationTypeId: obj.deliveryTypeId == 20 ? obj.notificationTypeId : 0,
            isNotificationDripBeeEditor: (obj.isBeeEditor && fieldName == 'NotificationField') ? obj.isBeeEditor : false,
            domainType: obj.domainType.toString(),
            attachments: attachItem,
            useInterval: obj.useInterval,
            prefix: obj.prefix,
            emailPreview: fieldName == 'EmailField' ? this.emailData : '',
            replyTo: obj.replyTo,
            newIsDripBeeEditor: ((obj.isBeeEditor && obj.isBeeEditor == true) && fieldName == 'EmailField') ? false  : true,
            newIsNotificationDripBeeEditor: ((obj.isBeeEditor && obj.isBeeEditor == true) && fieldName == 'NotificationField') ? false  : true
            // browserLink: this.browserLinkData
          });
          if ((this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6) && !obj.isImmediate) {
            this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "interval");
            this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "timeUnit");
          }
          else {
            this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "interval");
            this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "timeUnit");
          }
          if ((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && !obj.isImmediate && obj.useInterval) {
            this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "interval");
            this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "timeUnit");
          }
          else {
            this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "interval");
            this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "timeUnit");
          }
          if (obj.isBeeEditor) {
            //if(isDripBeeEditor) {
              this.patchAdvancedItemsLinks(fieldName, fieldsObj.sequence - 1, obj.links, 'beeLinks');
           // }
            if(fieldName == 'NotificationField') {
              this.patchAdvancedItemsLinks(fieldName, fieldsObj.sequence - 1, obj.links, 'notificationBeeLinks');
            }
            // this.patchAdvancedItemsLinks(fieldName, fieldsObj.sequence - 1, obj.links, 'beeLinks');
          } else {
            this.patchAdvancedItemsLinks(fieldName, fieldsObj.sequence - 1, obj.links, 'links');
          }
        }
        else if (obj.sequence > 10) {
          if (!extraFields.includes(obj.sequence)) {
            extraFields.push(obj.sequence);
          }
        }
      });
    });
    let newFields = extraFields.sort().map((e) => {
      return {
        sequence: e,
        name: "Message" + e,
      };
    })
    if (newFields.length > 0  && !this.saveContinue) {
      Array.prototype.push.apply(this.fields, newFields);
      this.addExtraMessages(newFields, campaignAdvanceOptions, isDripBeeEditor, isNotificationDripBeeEditor)
    }
    if(newFields.length > 0 && this.saveContinue) {
      campaignAdvanceOptions.forEach((obj: any, optionIndex: number) => {     
        this.fields.forEach((fieldsObj: any, fieldIndex: number) => {
          let fieldName;
          switch (obj.deliveryTypeId) {
            case 1:
              fieldName = 'EmailField';
              this.emailDataPreview(fieldName, obj);
              break;
            case 2:
              fieldName = 'SMSField';
              break;
            case 3:
              fieldName = 'WhatsappField';
              break;
            case 4:
              fieldName = 'LineField';
              break;
            case 5:
              fieldName = 'FacebookField';
              break;
            case 6:
              fieldName = 'TelegramField';
              break;
            case 7:
              fieldName = 'SMSField';
              break;
            case 8:
              fieldName = 'ShortCodeField';
              break;
            case 9:
              fieldName = 'NotificationField';
              break;
            case 11:
              fieldName = 'SMSField';
              break;
            case 12:
              fieldName = 'SMSField';
              break;
            case 13:
              fieldName = 'InternationalSMSMMSField';
              break;
            case 14:
              fieldName = 'InternationalSMSMMSField';
              break;
            case 15:
              fieldName = 'SMSField';
              break;
            case 16:
              fieldName = 'SMSField';
              break;
            case 17:
              fieldName = 'InternationalSMSMMSField';
              break;
	          case 18:
              fieldName = 'VerbNotificationField';
              break;
            case 19:
              fieldName = 'NowTechNotificationField';
              break; 
            case 20:
              fieldName = 'ShoutField';
              break;
            case 21:
              fieldName = 'EmailField';
              this.emailDataPreview(fieldName, obj);
              break;
            case 22:
              fieldName = 'SMSField';
              break;
            case 23:
              fieldName = 'SMSField';
              break; 
	    case 24:
              fieldName = 'ShortCodeField';
              break;                  
            default:
              break;
          };
          if (fieldsObj.sequence == obj.sequence) {
            let attachItem = [];
            if (obj.attachments && obj.attachments.length > 0) {
              obj.attachments.forEach(element => {
                let attachData = element.split(' ', 2);
                attachItem.push(attachData[0]);
              });
            }
            this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get('deliveryTypeIDs').patchValue(seqWiseDeliveryId[obj.sequence]);
            this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName).patchValue({
              id: obj.id,
              sequence: obj.sequence,
              subject: obj.subject,
              messageText: obj.messageText,
              optOutTrailor: obj.optOutTrailor,
              sendDate: obj.sendDate ? obj.sendDate : new Date(),
              sendTime: obj.sendDate ? moment(obj.sendDate).format("HH:mm") : '00:00',
              timeZone: obj.timeZone ? obj.timeZone : this.userDetails.timeZone ? this.userDetails.timeZone : 'UTC',
              isImmediate: obj.isImmediate,
              interval: obj.interval,
              timeUnit: obj.timeUnit,
              messageTinyURL: obj.tinyURL,
              messageJson: obj.messageJson ? JSON.parse(obj.messageJson) : this._pageService.defaultTemplate,
              isBeeEditor: obj.isBeeEditor ? obj.isBeeEditor : false,
              isDripBeeEditor: (obj.isBeeEditor && fieldName == 'EmailField') ? obj.isBeeEditor : false,
              mediaURL: obj.mediaURL,
              notificationTypeId: obj.deliveryTypeId == 20 ? obj.notificationTypeId : 0,
              isNotificationDripBeeEditor: (obj.isBeeEditor && fieldName == 'NotificationField') ? obj.isBeeEditor : false,
              domainType: obj.domainType.toString(),
              attachments: attachItem,
              useInterval: obj.useInterval,
              prefix: obj.prefix,
	            emailPreview: fieldName == 'EmailField' ? this.emailData : '',
              replyTo: obj.replyTo,
              newIsDripBeeEditor: (obj.isBeeEditor && obj.isBeeEditor == true && fieldName == 'EmailField') ? false  : true,
              newIsNotificationDripBeeEditor: (obj.isBeeEditor && obj.isBeeEditor == true && fieldName == 'NotificationField') ? false  : true
              // browserLink: this.browserLinkData
            });
            if ((this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6) && !obj.isImmediate) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "timeUnit");
            }
            if ((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && !obj.isImmediate && obj.useInterval) {
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "interval");
              this._pageService.addValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "timeUnit");
            }
            else {
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "interval");
              this._pageService.removeValidators(this.moreOptionFormGroup.get('messagesArray')['controls'][fieldIndex].get(fieldName), "timeUnit");
            }
            if (obj.isBeeEditor) {
              //if(isDripBeeEditor) {
                this.patchAdvancedItemsLinks(fieldName, fieldsObj.sequence - 1, obj.links, 'beeLinks');
             // }
              if(fieldName == 'NotificationField') {
                this.patchAdvancedItemsLinks(fieldName, fieldsObj.sequence - 1, obj.links, 'notificationBeeLinks');
              }
              // this.patchAdvancedItemsLinks(fieldName, fieldsObj.sequence - 1, obj.links, 'beeLinks');
            } else {
              this.patchAdvancedItemsLinks(fieldName, fieldsObj.sequence - 1, obj.links, 'links');
            }
          }
        });
      });
    }
  }
  getCampaignById(id: number) {
    this._fuseSplashScreenService.show();
    this._campaignsService.getCampaignById(id)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          if(this._data.type != 'useCamp') {
            this.campaignId = res.data.id;
          }
          if (res.data.campaignTypeId == 1) {
            this.patchBlastData(res.data);
          }
          else if (res.data.campaignTypeId == 2) {
            this.saveContinue = false;
            this.appliedFilters = res.data.contactFilter;
            if(this.appliedFilters != null) {
              if(this.appliedFilters && this._data && this._data.type && this._data.type == 'edit') {
                this._contactsService.appledFilters.next(this.appliedFilters);
              }
              this.getFilterContactsLength();
            }
            this.dripResponse = res.data;
            if(this._data.type == 'useCamp') {
              delete this.dripResponse.id;
              var campaignAdvanceOptionsData: any = [];
              for (let i = 0; i < this.dripResponse.campaignAdvanceOptions.length; i++) {
                  var s: any = this.dripResponse.campaignAdvanceOptions[i];
                  delete s.id;
                  campaignAdvanceOptionsData.push(s);
                  }
                  this.dripResponse.campaignAdvanceOptions = campaignAdvanceOptionsData;
                  this.dripResponse.isMaster = false;  
            }
            this.createFormGroup.patchValue({
              campaignname: res.data.name,
              accounttypes: res.data.campaignAdvanceOptions.map(obj => obj.deliveryTypeId).filter((value, index, self) => self.indexOf(value) === index),
              trigger: res.data.triggerIds,
              report: res.data.reportId,
              arTemplateId: res.data.arTemplateId,
              eventtype: res.data.eventTypeId,
              campaigntype: res.data.campaignTypeId,
              contactitems: res.data.contacts,
              groups: res.data.groups,
              groupForOptIn: res.data.groupForOptIn
            });
            // this._campaignsService.onEventValuePatch.next(res.data.eventTypeId);
            // this.moreOptionFormGroup.get('messagesArray')['controls'][0].get('EmailField').patchValue({ isDripBeeEditor: res.data.isDripBeeEditor ? true : false });
            // let isNotificationDripBeeEditor = this.moreOptionFormGroup.get('messagesArray')['controls'][0].get('NotificationField').patchValue({ isNotificationDripBeeEditor: res.data.isDripBeeEditor ? true : false });
            this.patchDripAdnanceArray(res.data.campaignAdvanceOptions, this.fields, 'Field', res.data.isDripBeeEditor ? true : false, res.data.isDripBeeEditor ? true : false);
          }
          this.checkValidation();
        }
      },
        error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.message, 'Error');
          this.matDialogRef.close();
        }
      );
  }
  getCampaign(contData) {
    if(contData) {
      this.saveContinue = true;
            this.appliedFilters = contData.contactFilter;
            if(this.appliedFilters != null) {
              if(this.appliedFilters && this._data && this._data.type && this._data.type == 'edit') {
                this._contactsService.appledFilters.next(this.appliedFilters);
              }
              this.getFilterContactsLength();
            }
            this.dripResponse = contData;
            this.campaignId = contData.id;
            this.createFormGroup.patchValue({
              campaignname: contData.name,
              accounttypes: contData.campaignAdvanceOptions.map(obj => obj.deliveryTypeId).filter((value, index, self) => self.indexOf(value) === index),
              trigger: contData.triggerIds,
              report: contData.reportId,
              eventtype:contData.eventTypeId,
              campaigntype: contData.campaignTypeId,
              contactitems: contData.contacts,
              groups: contData.groups,
              groupForOptIn: contData.groupForOptIn
            });
            // this.moreOptionFormGroup.get('messagesArray')['controls'][0].get('EmailField').patchValue({ isDripBeeEditor: contData.isDripBeeEditor ? true : false });
            this.patchDripAdnanceArray(contData.campaignAdvanceOptions, this.fields, 'Field', contData.isDripBeeEditor ? true : false, contData.isDripBeeEditor ? true : false);
    }
  }
  patchBlastData(editData) {
    this.appliedFilters = editData.contactFilter;
            if(this.appliedFilters != null) {
              if(this.appliedFilters && this._data && this._data.type && this._data.type == 'edit') {
                this._contactsService.appledFilters.next(this.appliedFilters);
              }
              this.getFilterContactsLength();
            }
            this.blastResponse = editData;
            if(this._data.type == 'useCamp') {
              delete this.blastResponse.id;
              var campaignLineItemsData: any = [];
              for (let i = 0; i <  this.blastResponse.campaignLineItems.length; i++) {
                var s: any =  this.blastResponse.campaignLineItems[i];
                delete s.id;
                campaignLineItemsData.push(s);
              }
              this.blastResponse.campaignLineItems = campaignLineItemsData;
              this.blastResponse.isMaster = false;
            }
            this.createFormGroup.patchValue({
              campaignname: editData.name,
              accounttypes: editData.campaignLineItems.map(obj => { return obj.deliveryTypeId }),
              trigger: editData.triggerIds,
              report: editData.reportId,
              arTemplateId: editData.arTemplateId,
              eventtype: editData.eventTypeId,
              campaigntype: editData.campaignTypeId,
              contactitems: editData.contacts,
              groups: editData.groups,
              groupForOptIn: editData.groupForOptIn
            });
            // this._campaignsService.onEventValuePatch.next(editData.eventTypeId);
            this.blastResponse.campaignLineItems.forEach(obj => {
              if(obj && obj['links'] && obj['links'].length > 0) {
                let linksData = obj['links'].filter((data, index) => {
                  return index === obj['links'].findIndex(item => data.name === item.name);
                });
                obj.links = linksData;
              }
              if (obj.deliveryTypeId == 1 || obj.deliveryTypeId == 21) {
                let attachItemblast = [];
                if (obj.attachments && obj.attachments.length > 0) {
                  obj.attachments.forEach(element => {
                    let attachData = element.split(' ', 2);
                    attachItemblast.push(attachData[0]);
                  });
                }
                this.messageOptionsFormGroup.patchValue({
                  emailEditor: obj.messageText,
                  emailTinyUrl: obj.tinyURL,
                  subject: obj.subject,
                  isBeeEditor: obj.isBeeEditor,
                  messageJson: obj.messageJson ? JSON.parse(obj.messageJson) : this._pageService.defaultTemplate,
                  domainType: obj.domainType.toString(),
                  attachments: attachItemblast,
                  prefix: obj.prefix,
                  replyTo: obj.replyTo,
                  newIsBeeEditor: (obj.isBeeEditor && obj.isBeeEditor == true) ? false : true
                });
                if (obj.isBeeEditor) {
                  this.patchLineItemsLinks('beeLinks', obj.links);
                }
                else {
                  this.patchLineItemsLinks('emailLinks', obj.links);
                }
              }
              else if (obj.deliveryTypeId == 22 || obj.deliveryTypeId == 2 || obj.deliveryTypeId == 11 || obj.deliveryTypeId == 15) {
                this.messageOptionsFormGroup.patchValue({
                  smsMessage: obj.messageText,
                  smsOptOutTrailor: obj.optOutTrailor,
                  smsTinyUrl: obj.tinyURL,
                  smsLinks: []
                });
                if(obj.deliveryTypeId == 11) {
                  this.messageOptionsFormGroup.patchValue({
                    domainTypeSMS: obj.domainType.toString(),
                  });
                }
                this.patchLineItemsLinks('smsLinks', obj.links);
              }
              else if (obj.deliveryTypeId == 3) {
                this.messageOptionsFormGroup.patchValue({
                  whatsappMessage: obj.messageText,
                  whatsappOptOutTrailor: obj.optOutTrailor,
                  whatsappTinyUrl: obj.tinyURL,
                  whatsappLinks: obj.links,
                  whatsAppMediaUrl: obj.mediaURL
                });
                this.patchLineItemsLinks('whatsappLinks', obj.links);
              }
              else if (obj.deliveryTypeId == 4) {
                this.messageOptionsFormGroup.patchValue({
                  lineMessage: obj.messageText,
                  lineOptOutTrailor: obj.optOutTrailor,
                  lineTinyUrl: obj.tinyURL,
                  lineLinks: obj.links,
                  lineMediaUrl: obj.mediaURL
                });
                this.patchLineItemsLinks('lineLinks', obj.links);
              }
              else if (obj.deliveryTypeId == 5) {
                this.messageOptionsFormGroup.patchValue({
                  fbMessage: obj.messageText,
                  fbOptOutTrailor: obj.optOutTrailor,
                  fbTinyUrl: obj.tinyURL,
                  fbLinks: obj.links
                });
                this.patchLineItemsLinks('fbLinks', obj.links);
              }
              else if (obj.deliveryTypeId == 6) {
                this.messageOptionsFormGroup.patchValue({
                  telegramMessage: obj.messageText,
                  telegramOptOutTrailor: obj.optOutTrailor,
                  telegramTinyUrl: obj.tinyURL,
                  telegramLinks: obj.links,
                  telegramMediaUrl: obj.mediaURL
                });
                this.patchLineItemsLinks('telegramLinks', obj.links);
              }
              else if (obj.deliveryTypeId == 7 || obj.deliveryTypeId == 12 || obj.deliveryTypeId == 16 || obj.deliveryTypeId == 23) {
                this.messageOptionsFormGroup.patchValue({
                  smsMessage: obj.messageText,
                  smsOptOutTrailor: obj.optOutTrailor,
                  mediaURL: obj.mediaURL,
                  smsTinyUrl: obj.tinyURL,
                  smsLinks: obj.links
                });
                this.patchLineItemsLinks('smsLinks', obj.links);
              }
              else if (obj.deliveryTypeId == 8 || obj.deliveryTypeId == 24) {
                this.messageOptionsFormGroup.patchValue({
                  shortCodeMessage: obj.messageText,
                  shortCodeOptOutTrailor: obj.optOutTrailor,
                  shortCodemediaURL: obj.mediaURL,
                  shortCodeTinyUrl: obj.tinyURL,
                  shortCodeLinks: obj.links
                });
                this.patchLineItemsLinks('shortCodeLinks', obj.links);
              }
              else if (obj.deliveryTypeId == 9) {
                this.messageOptionsFormGroup.patchValue({
                  notificationMessage: obj.messageText,
                  notificationTinyUrl: obj.tinyURL,
                  allNotificationType: obj.subject ? obj.subject : '',
                  notificationTypeId: 0,
                  isNotificationBeeEditor: obj.isBeeEditor,
                  notificationJson: obj.messageJson ? JSON.parse(obj.messageJson) : this._pageService.defaultTemplate,
                  newIsNotificationBeeEditor: (obj.isBeeEditor && obj.isBeeEditor == true) ? false : true,
                });
                if (obj.isBeeEditor) {
                  this.patchLineItemsLinks('notificationBeeLinks', obj.links);
                }
                else {
                  this.patchLineItemsLinks('notificationLinks', obj.links);
                }
              }
              else if (obj.deliveryTypeId == 13 || obj.deliveryTypeId == 14 || obj.deliveryTypeId == 17) {
                this.messageOptionsFormGroup.patchValue({
                  internationalSMSMMSMessage: obj.messageText,
                  internationalSMSMMSOptOutTrailor: obj.optOutTrailor,
                  internationalSMSMMSMediaURL: obj.mediaURL,
                  internationalSMSMMSTinyUrl: obj.tinyURL,
                  internationalSMSMMSLinks: obj.links
                });
                if(obj.deliveryTypeId == 14) {
                  this.messageOptionsFormGroup.patchValue({
                    domaintypeInternationalSMS: obj.domainType.toString(),
                  });
                }
                this.patchLineItemsLinks('internationalSMSMMSLinks', obj.links);
              }
              else if (obj.deliveryTypeId == 18) {
                this.messageOptionsFormGroup.patchValue({
                  verbNotificationMessage: obj.messageText,
                  verbNotificationSubject: obj.subject ? obj.subject : '',
                });
                this.patchLineItemsLinks('verbNotificationLinks', obj.links);
              }
              else if (obj.deliveryTypeId == 19) {
                this.messageOptionsFormGroup.patchValue({
                  nowTechNotificationMessage: obj.messageText,
                  nowTechNotificationSubject: obj.subject ? obj.subject : '',
                });
                this.patchLineItemsLinks('nowTechNotificationLinks', obj.links);
              } 
              else if (obj.deliveryTypeId == 20) {
                this.messageOptionsFormGroup.patchValue({
                  shoutMessage: obj.messageText,
                  shoutmediaURL: obj.mediaURL,
                  notificationTypeId: obj.notificationTypeId
                });
                this.patchLineItemsLinks('shoutLinks', obj.links);
              }
            });
            this.blastScheduleFormGroup.patchValue({
              Immediatecheck: editData.isImmediate,
              recurringCheck: editData.isRecurring,
              compaingnTocalender: editData.showInCalendar,
              onGoingcheck: editData.onGoing,
              deliverDate: editData.sendDate ? editData.sendDate : new Date(),
              deliverTime: editData.sendDate ? moment(editData.sendDate).format("HH:mm") : '00:00',
              endDate: editData.endDate ? editData.endDate : new Date(),
              endTime: editData.endDate ? moment(editData.endDate).format("HH:mm") : '00:00',
              at: editData.recurringTime,
              day: editData.recurringDay.toString(),
              primaryColor: editData.primaryColor,
              secondaryColor: editData.secondaryColor,
              timeZone: editData.timeZone ? editData.timeZone : this.userDetails.timeZone ? this.userDetails.timeZone : 'UTC',
              hasJourney: editData.hasJourney
            });
            if(editData && editData.hasJourney) {
              this._campaignsService.refreshGetAllJourney.next(editData.hasJourney);
            }
  }
  checkValidation() {
    if (this.createFormGroup.get('campaigntype').value == 1) {
      if (this.blastScheduleFormGroup.get('Immediatecheck').value) {
        this.removeValidators(this.blastScheduleFormGroup, "deliverDate");
        this.removeValidators(this.blastScheduleFormGroup, "deliverTime");
      }
      else {
        this._pageService.addValidators(this.blastScheduleFormGroup, "deliverDate");
        this._pageService.addValidators(this.blastScheduleFormGroup, "deliverTime");
      }
      if (this.blastScheduleFormGroup.get('recurringCheck').value) {
        this._pageService.addValidators(this.blastScheduleFormGroup, "day");
        this._pageService.addValidators(this.blastScheduleFormGroup, "at");
      }
      else {
        this.removeValidators(this.blastScheduleFormGroup, "day");
        this.removeValidators(this.blastScheduleFormGroup, "at");
      }
      if (this.blastScheduleFormGroup.get('recurringCheck').value && !this.blastScheduleFormGroup.get('onGoingcheck').value) {
        this._pageService.addValidators(this.blastScheduleFormGroup, "endDate");
        this._pageService.addValidators(this.blastScheduleFormGroup, "endTime");
      }
      else {
        this.removeValidators(this.blastScheduleFormGroup, "endDate");
        this.removeValidators(this.blastScheduleFormGroup, "endTime");
      }
      if (!this.blastScheduleFormGroup.get('Immediatecheck').value || this.blastScheduleFormGroup.get('recurringCheck').value) {
        this._pageService.addValidators(this.blastScheduleFormGroup, "timeZone");
      }
      else {
        this.removeValidators(this.blastScheduleFormGroup, "timeZone");
      }
    }
  }
  geFormArray(formGroup, formControl) {
    return formGroup.get(formControl) as FormArray;
  }
  getDripAdvanceArrayNew(typeId, eventtype, isMedia, obj) {
    let campaignAdvanceOptions = [];
      let tempObj = this.dripResponse ? (this.dripResponse.campaignAdvanceOptions.filter(x => (x.deliveryTypeId == typeId) || (x.deliveryTypeId==2 && typeId==11) || (x.deliveryTypeId==11 && typeId==2) || (x.deliveryTypeId==7 && typeId==12) || (x.deliveryTypeId==12 && typeId==7) || (x.deliveryTypeId==2 && typeId==7) || (x.deliveryTypeId==7 && typeId==2) || (x.deliveryTypeId==12 && typeId==11) || (x.deliveryTypeId==11 && typeId==12) || (x.deliveryTypeId==2 && typeId==12) || (x.deliveryTypeId==12 && typeId==2) || (x.deliveryTypeId==11 && typeId==7) || (x.deliveryTypeId==7 && typeId==11) || (x.deliveryTypeId==13 && typeId==14) || (x.deliveryTypeId==14 && typeId==13))) : null;
      let tempSeq = (tempObj && tempObj.length > 0) ? (tempObj.filter(x => (x.sequence == obj.sequence))) : null;
      if (obj.messageText || obj.mediaURL) {
        var tempLinks = [];
        if (obj.isDripBeeEditor || obj.isNotificationDripBeeEditor) {
          tempLinks = this.getLinksFromHtml(obj.messageText, 'a', '[Link');
        }
        else {
          tempLinks = obj.links;
        }
        if(obj.beeLinks.length>0 && obj.isDripBeeEditor) {
          tempLinks = tempLinks.concat(obj.beeLinks);
        }
        let attachItem = [];
        if(obj.attachments && obj.attachments) {
          obj.attachments.forEach((element, index) => {
                this.media.mediaList.forEach((item, i) => {
                    if(element == item.filePath) {
                      attachItem.push(item.filePath+' '+item.fileName)
                    }
                })
            });
        }
        campaignAdvanceOptions.push({
          "id": obj.id,
          "sequence": obj.sequence,
          "deliveryTypeId": typeId,
          "subject": obj.subject,
          "messageText": obj.messageText,
          "optOutTrailor": (typeId > 1 && typeId !=9  && typeId !=18) ? obj.optOutTrailor : '',
          "sendDate": (obj.isImmediate || eventtype == 2 || eventtype == 3 || eventtype == 5) ? null : (obj.sendDate ? moment(moment(obj.sendDate).format("YYYY-MM-DD") + ' ' + obj.sendTime + ':00').format("YYYY-MM-DD HH:mm:ss") : null),
          "timeZone": (obj.isImmediate || eventtype == 2 || eventtype == 3 || eventtype == 5) ? '' : (obj.timeZone),
          "tinyURL": obj.messageTinyURL,
          "links": tempLinks,
          "mediaURL": isMedia ? obj.mediaURL : '',
          "interval": obj.isImmediate ? '' : parseInt(obj.interval),
          "timeUnit": obj.isImmediate ? '' : obj.timeUnit,
          "isImmediate": obj.isImmediate,
          "messageJson": obj.messageJson ? JSON.stringify(obj.messageJson) : "",
          "isBeeEditor": obj.isDripBeeEditor ? obj.isDripBeeEditor : (obj.isNotificationDripBeeEditor ? obj.isNotificationDripBeeEditor : false),
          "notificationTypeId": typeId == 20 ? obj.notificationTypeId : 0,
          "domainType": obj.domainType ? parseInt(obj.domainType) : 0,
          "attachments": attachItem ? attachItem : [],
          "useInterval": obj.useInterval ? obj.useInterval : false,
          "emailPreview": obj.emailPreview ? obj.emailPreview : '',
	        "prefix": obj.prefix ? obj.prefix : '',
		      "replyTo": obj.replyTo ? obj.replyTo : ''
        });
          campaignAdvanceOptions.forEach(item => {
            if(item.id == null) { 
              delete item.id;
            }
          });
      }
    return campaignAdvanceOptions;
  }
  getLinksFromHtml(htmlString, tag, text) {
    var doc = new DOMParser().parseFromString(htmlString, "text/html");
    var elements = doc.getElementsByTagName(tag);
    var found = [];
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].innerHTML.includes(text)) {
        found.push({
          "name": elements[i]['innerText'],
          "url": elements[i]['attributes']['href']['value']
        });
      }
    }
    return found;
  }
  save(status, cont) {
      this.isExit = false;
      let createFormData = this.createFormGroup.getRawValue();
      if (createFormData.campaigntype == 1) {
        // this.isActiveStatus = (!status && this._data && this._data.type && this._data.type == 'edit') ?  (this.blastResponse ? this.blastResponse.isActive : status) : status;
        this.isActiveStatus = status;
        let messageOptionsFormData = this.messageOptionsFormGroup.getRawValue();
        createFormData.accounttypes.forEach(accType => {
          if(accType == 13 || accType == 14) {
            if(!messageOptionsFormData.internationalSMSMMSMessage && !messageOptionsFormData.internationalSMSMMSMediaURL) {
              this._toastr.warning('Please fill either Message Text or Media of International SMS', 'Warning');
              this.isExit = true;
              return false;
          }
          }
        });
        let blastScheduleFormData = this.blastScheduleFormGroup.getRawValue();
        if (this.messageOptionsFormGroup.invalid) {
          if( this.messageOptionsFormGroup && this.messageOptionsFormGroup.get('mediaURL').invalid) {
            this._toastr.warning('Please add a media file!', 'Warning')
          }
          this.validateAllFormFields(this.messageOptionsFormGroup);
          return false;
        }
        if (this.blastScheduleFormGroup.invalid) {
          this.validateAllFormFields(this.blastScheduleFormGroup);
          return false;
        }
        if (!this.checkTCPA.value) {
          this._toastr.warning('Please accept the policies', 'Warning');
          return false;
        }

        /**
         *  Coverting into Date Object if date is already selected!
        */
       
        let _deliverDate = blastScheduleFormData.deliverDate;
        if(typeof blastScheduleFormData.deliverDate === 'string'){
          _deliverDate = new Date(blastScheduleFormData.deliverDate);
          _deliverDate.setHours(0, 0, 0, 0);
        }
        
        let sendDate = _deliverDate ? moment(moment.utc(_deliverDate).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") + ' ' + blastScheduleFormData.deliverTime + ':00').format("YYYY-MM-DD HH:mm:ss") : null;

        let endDate = blastScheduleFormData.endDate ? moment(moment.utc(blastScheduleFormData.endDate).utcOffset(this.filterOffset).endOf('day').local().format("YYYY-MM-DD") + ' ' + blastScheduleFormData.endTime + ':00').format("YYYY-MM-DD HH:mm:ss") : null;
        let timeZone = blastScheduleFormData.timeZone;
        if (blastScheduleFormData.Immediatecheck) {
          sendDate = new Date().toUTCString();
        }
        if (!(blastScheduleFormData.recurringCheck && !blastScheduleFormData.onGoingcheck)) {
          endDate = null;
        }
        if (blastScheduleFormData.Immediatecheck && !blastScheduleFormData.recurringCheck) {
          timeZone = '';
        }
        // blast campaign
        let campaignLineItems = [];
        createFormData.accounttypes.forEach(type => {
          if (type == 1 || type == 21) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 1 || x.deliveryTypeId == 21))) : null;
            var tempLinks = [];
            if (messageOptionsFormData.isBeeEditor) {
              tempLinks = this.getLinksFromHtml(messageOptionsFormData.emailEditor, 'a', '[Link')
            }
            else {
              tempLinks = messageOptionsFormData.emailLinks;
            }
            if(this.messageOptionsFormGroup.get('beeLinks').value.length > 0 && messageOptionsFormData.isBeeEditor) {
              tempLinks = tempLinks.concat(this.messageOptionsFormGroup.get('beeLinks').value);
            }
            let attachmentsItem = [];
            if(messageOptionsFormData && messageOptionsFormData.attachments) {
              messageOptionsFormData.attachments.forEach((element, index) => {
                    this.media.mediaList.forEach((item, i) => {
                        if(element == item.filePath) {
                            attachmentsItem.push(item.filePath+' '+item.fileName)
                        }
                    })
                });
            }
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.emailEditor,
              "messageJson": JSON.stringify(messageOptionsFormData.messageJson),
              "isBeeEditor": messageOptionsFormData.isBeeEditor,
              "links": tempLinks,
              "subject": messageOptionsFormData.subject,
              "domainType": parseInt(messageOptionsFormData.domainType),
              "attachments": attachmentsItem,
              "emailPreview": messageOptionsFormData.emailPreview,
	            "prefix": messageOptionsFormData.prefix,
              "replyTo": messageOptionsFormData.replyTo
            });
          }
          else if ((type == 2 && createFormData.accounttypes.indexOf(7) == -1) || (type == 15 && createFormData.accounttypes.indexOf(16) == -1) || (type == 22 && createFormData.accounttypes.indexOf(23) == -1) ) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 2 || x.deliveryTypeId == 15 || x.deliveryTypeId == 22))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.smsMessage,
              "optOutTrailor": messageOptionsFormData.smsOptOutTrailor,
              "links": messageOptionsFormData.smsLinks
            });
          }
          else if (type == 11 && createFormData.accounttypes.indexOf(12) == -1) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 11))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.smsMessage,
              "optOutTrailor": messageOptionsFormData.smsOptOutTrailor,
              "links": messageOptionsFormData.smsLinks,
              "domainType": parseInt(messageOptionsFormData.domainTypeSMS)
            });
          }
          else if (type == 7 || type == 12 || type == 16 || type == 23) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => ((x.deliveryTypeId == 7) || (x.deliveryTypeId == 12)  || (x.deliveryTypeId == 23)  || (x.deliveryTypeId == 16)))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.smsMessage,
              "optOutTrailor": messageOptionsFormData.smsOptOutTrailor,
              "links": messageOptionsFormData.smsLinks,
              "mediaURL": messageOptionsFormData.mediaURL
            });
          }
          else if (type == 8 || type==24) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => ((x.deliveryTypeId == 8)||(x.deliveryTypeId==24) ))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.shortCodeMessage,
              "optOutTrailor": messageOptionsFormData.shortCodeOptOutTrailor,
              "links": messageOptionsFormData.shortCodeLinks,
              "mediaURL": messageOptionsFormData.shortCodemediaURL
            });
          }
          else if (type == 3) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 3))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.whatsappMessage,
              "optOutTrailor": messageOptionsFormData.whatsappOptOutTrailor,
              "links": messageOptionsFormData.whatsappLinks,
              "mediaURL": messageOptionsFormData.whatsAppMediaUrl
            });
          }
          else if (type == 4) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 4))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.lineMessage,
              "optOutTrailor": messageOptionsFormData.lineOptOutTrailor,
              "links": messageOptionsFormData.lineLinks,
              "mediaURL": messageOptionsFormData.lineMediaUrl
            });
          }
          else if (type == 5) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 5))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.fbMessage,
              "optOutTrailor": messageOptionsFormData.fbOptOutTrailor,
              "links": messageOptionsFormData.fbLinks
            });
          }
          else if (type == 6) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 6))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.telegramMessage,
              "optOutTrailor": messageOptionsFormData.telegramOptOutTrailor,
              "links": messageOptionsFormData.telegramLinks,
              "mediaURL": messageOptionsFormData.telegramMediaUrl
            });
          }
          else if (type == 9) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 9))) : null;
            var tempLinks = [];
            if (messageOptionsFormData.isNotificationBeeEditor) {
              tempLinks = this.getLinksFromHtml(messageOptionsFormData.notificationMessage, 'a', '[Link');
            }
            else {
              tempLinks = messageOptionsFormData.notificationLinks;
            }
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.notificationMessage,
              "links": tempLinks,
              "subject": messageOptionsFormData.allNotificationType,
              "notificationTypeId": 0,
              "messageJson": JSON.stringify(messageOptionsFormData.notificationJson),
              "isBeeEditor": messageOptionsFormData.isNotificationBeeEditor,
            });
          }
          else if (type == 13 || type == 17) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 13 || x.deliveryTypeId == 17))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.internationalSMSMMSMessage,
              "optOutTrailor": messageOptionsFormData.internationalSMSMMSOptOutTrailor,
              "links": messageOptionsFormData.internationalSMSMMSLinks,
              "mediaURL": messageOptionsFormData.internationalSMSMMSMediaURL
            });
          }
          else if (type == 14) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 14))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.internationalSMSMMSMessage,
              "optOutTrailor": messageOptionsFormData.internationalSMSMMSOptOutTrailor,
              "links": messageOptionsFormData.internationalSMSMMSLinks,
              "mediaURL": messageOptionsFormData.internationalSMSMMSMediaURL,
              "domainType": parseInt(messageOptionsFormData.domaintypeInternationalSMS)
            });
          }
          else if (type == 18) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 18))) : null;
            var tempLinks = [];
            tempLinks = messageOptionsFormData.verbNotificationLinks;
            var verbNotificationMessage =  messageOptionsFormData.verbNotificationMessage;
            var docData = new DOMParser().parseFromString(verbNotificationMessage, "text/html");
            var verbNotificationMessageData = docData.body.innerHTML;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": verbNotificationMessageData,
              "links": tempLinks,
              "subject": messageOptionsFormData.verbNotificationSubject,
              "notificationTypeId": 0
            });
          }
          else if (type == 19) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 19))) : null;
            var tempLinks = [];
            tempLinks = messageOptionsFormData.nowTechNotificationLinks;
            var nowTechNotificationMessage =  messageOptionsFormData.nowTechNotificationMessage;
            var docData = new DOMParser().parseFromString(nowTechNotificationMessage, "text/html");
            var nowTechNotificationMessageData = docData.body.innerHTML;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": nowTechNotificationMessageData,
              "links": tempLinks,
              "subject": messageOptionsFormData.nowTechNotificationSubject,
              "notificationTypeId": 0
            });
          }
          else if (type == 20) {
            let tempObj = this.blastResponse ? (this.blastResponse.campaignLineItems.filter(x => (x.deliveryTypeId == 20))) : null;
            campaignLineItems.push({
              ...((tempObj && tempObj.length > 0) ? tempObj[0] : {}),
              "deliveryTypeId": type,
              "messageText": messageOptionsFormData.shoutMessage,
              "links": messageOptionsFormData.shoutLinks,
              "mediaURL": messageOptionsFormData.shoutmediaURL,
              "notificationTypeId": messageOptionsFormData.notificationTypeId
            });
          }
        });
        let blastReq: any = {
          ...(this.blastResponse ? this.blastResponse : {}),
          ...(this.campaignId ? { "id": this.campaignId } : {}),
          "name": createFormData.campaignname,
          "eventTypeId": createFormData.eventtype,
          "campaignTypeId": createFormData.campaigntype,
          "contacts": createFormData.contactitems,
          "groups": createFormData.groups,
          "groupForOptIn": createFormData.groupForOptIn ? createFormData.groupForOptIn : 0,
          "reportId": createFormData.report ? createFormData.report : 0,
          "triggerIds": createFormData.trigger,
          "arTemplateId": createFormData.arTemplateId,
          "isImmediate": blastScheduleFormData.Immediatecheck,
          "isRecurring": blastScheduleFormData.recurringCheck,
          "onGoing": blastScheduleFormData.onGoingcheck,
          "sendDate": sendDate,
          "endDate": endDate,
          "timeZone": timeZone,
          "recurringDay": blastScheduleFormData.day,
          "recurringTime": blastScheduleFormData.at,
          "primaryColor": blastScheduleFormData.primaryColor,
          "secondaryColor": blastScheduleFormData.secondaryColor,
          "hasJourney": blastScheduleFormData.hasJourney,
          "campaignLineItems": campaignLineItems,
          "isDripBeeEditor": messageOptionsFormData.isBeeEditor ? messageOptionsFormData.isBeeEditor : false,
          "showInCalendar": blastScheduleFormData.compaingnTocalender,
          "isActive":  this.isActiveStatus,
          "isMaster": (this._data.pageType == "masterCampaign" || this._data.pageType == "providerCampaign") ? true : false,
          "providerId": this._data.pageType == "providerCampaign" ? this._data.providerId : 0,
          "contactFilter": this._contactsService.appledFilters.value != null ? {
            "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
            "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
            "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
            "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
            "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
            "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
            "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
            "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
            "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
            "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
            "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
            "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
            "groupRangeTo": this.appliedFilters && this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
            "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
            "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
            "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
            "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
            "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0

          } : null
        }
        if(!this.isExit) {
          this._fuseSplashScreenService.show();
          this._campaignsService.AddEditCampaign(blastReq, false)
            .subscribe((res: any) => {
              this._fuseSplashScreenService.hide();
              if (res.status == 200) {
                if(cont) {
                  this.matDialogRef.close(res.data.data);
                }else if(!cont) {
                  this._campaignsService.saveContinueblast = true;
                  this.patchBlastData(res.data.data);
                }
                if(res.data && res.data.message) {
                  this._toastr.success(res.data.message, 'Success');
                }
              } else {
                this._toastr.error(res.error, 'Error');
              }
            },
              error => {
                this._fuseSplashScreenService.hide();
                if (error && error.error && error.error.error) {
                  this._toastr.error(error.error.error, 'Error');
                }
                else {
                  this._toastr.error(error.error, 'Error');
                }
              }
            );
        }
      }
      else if (createFormData.campaigntype == 2) {
        // this.isActiveStatus = (!status && this._data && this._data.type && this._data.type == 'edit') ?  (this.dripResponse ? this.dripResponse.isActive : status) : status;
        this.isActiveStatus = status;
        if(createFormData.accounttypes.indexOf(9) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][0].get('NotificationField').value.subject){
          this.moreOptionFormGroup.get('messagesArray')['controls'][0].get("NotificationField").markAllAsTouched();
          this._toastr.error('Please fill Notification subject field', 'Error');
          return
        }
          if (this.dripScheduleFormGroup.invalid) {
            this.validateAllFormFields(this.dripScheduleFormGroup);
            return false;
          }
          // drip campaign
          let campaignAdvanceOptions = [];
          this.moreOptionFormGroup.get('messagesArray')['controls'].forEach((SeqArrayObj: any, index) => {
            let deliveryTypeIds = SeqArrayObj.get('deliveryTypeIDs').value;
            if (deliveryTypeIds.indexOf(1) !== -1) {
              let obj = SeqArrayObj.get('EmailField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(1,  createFormData.eventtype, false, obj));
            }
            if (deliveryTypeIds.indexOf(21) !== -1) {
              let obj = SeqArrayObj.get('EmailField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(21,  createFormData.eventtype, false, obj));
            }
            if ((deliveryTypeIds.indexOf(2) !== -1 && deliveryTypeIds.indexOf(7) == -1)) {
              let obj = SeqArrayObj.get('SMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(2, createFormData.eventtype, false, obj));
            }
            if ((deliveryTypeIds.indexOf(22) !== -1 && deliveryTypeIds.indexOf(23) == -1)) {
              let obj = SeqArrayObj.get('SMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(22, createFormData.eventtype, false, obj));
            }
            if ((deliveryTypeIds.indexOf(11) !== -1 && deliveryTypeIds.indexOf(12) == -1)) {
              let obj = SeqArrayObj.get('SMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(11, createFormData.eventtype, false, obj));
            }
            if ((deliveryTypeIds.indexOf(15) !== -1 && deliveryTypeIds.indexOf(16) == -1)) {
              let obj = SeqArrayObj.get('SMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(15, createFormData.eventtype, false, obj));
            }
            if (deliveryTypeIds.indexOf(23) !== -1) {
              let obj = SeqArrayObj.get('SMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(23, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(7) !== -1) {
              let obj = SeqArrayObj.get('SMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(7, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(12) !== -1) {
              let obj = SeqArrayObj.get('SMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(12, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(16) !== -1) {
              let obj = SeqArrayObj.get('SMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(16, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(8) !== -1 || deliveryTypeIds.indexOf(24) !== -1) {
              let obj = SeqArrayObj.get('ShortCodeField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew((deliveryTypeIds.indexOf(8) !== -1 ? 8 : 24), createFormData.eventtype, true, obj));
            }
            
            if (deliveryTypeIds.indexOf(3) !== -1) {
              let obj = SeqArrayObj.get('WhatsappField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(3, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(4) !== -1) {
              let obj = SeqArrayObj.get('LineField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(4, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(5) !== -1) {
              let obj = SeqArrayObj.get('FacebookField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(5, createFormData.eventtype, false, obj));
            }
            if (deliveryTypeIds.indexOf(6) !== -1) {
              let obj = SeqArrayObj.get('TelegramField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(6, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(9) !== -1) {
              let obj = SeqArrayObj.get('NotificationField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(9, createFormData.eventtype, false, obj));
            }
            if (deliveryTypeIds.indexOf(13) !== -1) {
              let obj = SeqArrayObj.get('InternationalSMSMMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(13, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(14) !== -1) {
              let obj = SeqArrayObj.get('InternationalSMSMMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(14, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(17) !== -1) {
              let obj = SeqArrayObj.get('InternationalSMSMMSField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(17, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(18) !== -1) {
              let obj = SeqArrayObj.get('VerbNotificationField').value;
              obj.sequence = index+1;
              var verbNotificationMessage = obj.messageText;
              var docData = new DOMParser().parseFromString(verbNotificationMessage, "text/html");
              obj.messageText = docData.body.innerHTML;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(18, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(19) !== -1) {
              let obj = SeqArrayObj.get('NowTechNotificationField').value;
              obj.sequence = index+1;
              var nowTechNotificationMessage = obj.messageText;
              var docData = new DOMParser().parseFromString(nowTechNotificationMessage, "text/html");
              obj.messageText = docData.body.innerHTML;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(19, createFormData.eventtype, true, obj));
            }
            if (deliveryTypeIds.indexOf(20) !== -1) {
              let obj = SeqArrayObj.get('ShoutField').value;
              obj.sequence = index+1;
              Array.prototype.push.apply(campaignAdvanceOptions, this.getDripAdvanceArrayNew(20, createFormData.eventtype, true, obj));
            }
          });
          this.intervalTimeUnit = true;
          this.moreOptionFormGroup.getRawValue().messagesArray.forEach((obj: any, index: number) => {
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(1) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(21) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['subject'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['subject'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Email Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('EmailField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Email Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(2) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(7) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(11) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(12) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(15) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(16) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(22) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(23) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['messageText'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(2) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(11) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(15) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(22) !== -1) {
                      this._toastr.error('Please fill the SMS Interval', 'Error');
                    }else if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(7) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(12) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(16) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(23) !== -1) {
                      this._toastr.error('Please fill the MMS Interval', 'Error');
                    }else if((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(2) !== -1 && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(7) !== -1) || (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(11) !== -1 && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(12) !== -1) || (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(15) !== -1 && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(16) !== -1) || (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(22) !== -1 && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(23) !== -1)) {
                      this._toastr.error('Please fill the SMS/MMS Interval', 'Error');
                    }
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('SMSField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(2) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(11) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(15) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(22) !== -1) {
                      this._toastr.error('Please fill the SMS Time Unit', 'Error');
                    }else if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(7) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(12) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(16) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(23) !== -1) {
                      this._toastr.error('Please fill the MMS Time Unit', 'Error');
                    }else if((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(2) !== -1 && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(7) !== -1) || (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(11) !== -1 && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(12) !== -1) || (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(15) !== -1 && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(16) !== -1) || (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(22) !== -1 && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(23) !== -1)) {
                      this._toastr.error('Please fill the SMS/MMS Time Unit', 'Error');
                    }
                    return false;
                  }
                }
              }
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(3) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['messageText'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Whatsapp Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('WhatsappField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Whatsapp Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(4) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['messageText'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Line Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Line Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(4) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['messageText'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Line Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('LineField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Line Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(5) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['messageText'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Facebook Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('FacebookField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Facebook Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(6) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['messageText'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Telegram Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('TelegramField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Telegram Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(8) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(24) !== -1 ) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['messageText'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the ShortCode Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShortCodeField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the ShortCode Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(9) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['subject'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Notification Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NotificationField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Notification Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if ((this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(13) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(14) !== -1 || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(17) !== -1) && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['messageText'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the International SMS Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('InternationalSMSMMSField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the International SMS Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(18) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['subject'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Verb Notification Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('VerbNotificationField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Verb Notification Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(19) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['subject'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the NowTech Notification Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('NowTechNotificationField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the NowTech Notification Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
            if (this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('deliveryTypeIDs').value.indexOf(20) !== -1 && !this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['isImmediate'].value) {
              if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['messageText'].value!="" && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['subject'].value != null) {
                if(((this.createFormGroup.get('eventtype').value == 1 || this.createFormGroup.get('eventtype').value == 2 || this.createFormGroup.get('eventtype').value == 4) && this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['useInterval'].value) || (this.createFormGroup.get('eventtype').value == 3 || this.createFormGroup.get('eventtype').value == 5 || this.createFormGroup.get('eventtype').value == 6)) {
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['interval'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['interval'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['interval'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Shout Interval', 'Error');
                    return false;
                  }
                  if(this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['timeUnit'].value == null || this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['timeUnit'].value == "") {
                    this.moreOptionFormGroup.get('messagesArray')['controls'][index].get('ShoutField').controls['timeUnit'].markAsTouched();
                    this.intervalTimeUnit = false;
                    this._toastr.error('Please fill the Shout Time Unit', 'Error');
                    return false;
                  }
                }
              }
            }
          });
          if (!this.checkTCPA.value && cont) {
            this._toastr.warning('Please accept the policies', 'Warning');
            return false;
          }
          let dripReq: any = {
            "id": this.campaignId,
            "name": createFormData.campaignname,
            "eventTypeId": createFormData.eventtype,
            "campaignTypeId": createFormData.campaigntype,
            "contacts": createFormData.contactitems,
            "groups": createFormData.groups,
            "groupForOptIn": createFormData.groupForOptIn ? createFormData.groupForOptIn : 0,
            "reportId": createFormData.report ? createFormData.report : 0,
            "triggerIds": createFormData.trigger,
            "arTemplateId": createFormData.arTemplateId,
            "isImmediate": false,
            "isRecurring": false,
            "onGoing": false,
            "sendDate": null,
            "endDate": null,
            "timeZone": '',
            "primaryColor": '#1e90ff',
            "secondaryColor": '#D1E8FF',
            "isDripBeeEditor": false,
            "campaignAdvanceOptions": campaignAdvanceOptions,
            "isActive": this.isActiveStatus,
            "isMaster": (this._data.pageType == "masterCampaign" || this._data.pageType == "providerCampaign") ? true : false,
            "providerId": this._data.pageType == "providerCampaign" ? this._data.providerId : 0,
            "contactFilter": this._contactsService.appledFilters.value != null ? {
              "paidAsRanks": this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
              "highestRanks": this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
              "countries": this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
              "states":  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
              "associateTypes": this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
              "associateStatus": this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
              "languageCodes": this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
              "optFilter": this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
              "invalidData": this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
              "fromDate": this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
              "toDate": this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
              "groupRangeFrom": this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
              "groupRangeTo": this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
              "hasActiveAutoship": this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
              "channels": this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
              "customFields": this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
              "customFieldsCompare": this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
              "sponsorId": this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0              
            } : null
          };
          if(this.dripResponse && !_.isEmpty(this.dripResponse)) {
            dripReq.campaignLineItems = (this.dripResponse && this.dripResponse.campaignLineItems) ? this.dripResponse.campaignLineItems : [];
            dripReq.createdAt = (this.dripResponse && this.dripResponse.createdAt) ? this.dripResponse.createdAt : null;
            dripReq.recurringDay = (this.dripResponse && this.dripResponse.recurringDay) ? this.dripResponse.recurringDay : 0;
            dripReq.recurringTime = (this.dripResponse && this.dripResponse.recurringTime) ? this.dripResponse.recurringTime : null;
            dripReq.showInCalendar = (this.dripResponse && this.dripResponse.showInCalendar) ? this.dripResponse.showInCalendar : false;

          }
          if(this.intervalTimeUnit) {
            this._fuseSplashScreenService.show();
            this._campaignsService.AddEditCampaign(dripReq, this.resumeNewMes)
            .subscribe((res: any) => {
              this._fuseSplashScreenService.hide();
              if (res.status == 200) {
                if(cont) {
                  this.matDialogRef.close('success');
                }else if(!cont) {
                  this.getCampaign(res.data.data);
                }
                if(res.data && res.data.message) {
                  this._toastr.success(res.data.message, 'Success');
                }
              } else {
                this._toastr.error(res.error, 'Error');
              }
            },
              error => {
                this._fuseSplashScreenService.hide();
                if (error.error.error) {
                  this._toastr.error(error.error.error, 'Error');
                }
                else {
                  this._toastr.error(error.error, 'Error');
                }
              }
            );
          }
      }
  }
  closeCampaignConfirmation() {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to close this campaign?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.matDialogRef.close();
      }
      this.confirmDialogRef = null;
    });
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }
  moveStepper(index) {
    this.stepper.selectedIndex = index;
  }
  selectionChange($event: any) {
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control: any = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else {
        this.validateAllFormFields(control);
      }
    });
  }
  removeValidators(form: FormGroup, formControl: any) {
    form.get(formControl).clearValidators();
    form.get(formControl).updateValueAndValidity();
  }
  addValidators(form: FormGroup, formControl: any) {
    form.get(formControl).setValidators([Validators.required]);
    form.get(formControl).updateValueAndValidity();
  }
  onTriggerEmit(event) {
    this.mergeTagData();
    if (event && event.length > 0) {
      this.chRef.detectChanges();
      this._campaignsService.GetTriggerEventFields(event)
        .subscribe((res: any) => {
          if (res.status == 200) {
            res.data.forEach((dataEvent) => {
              this.customFieldOptions.push({
                name: dataEvent,
                value: "[_" + dataEvent + "]"
              });
              this.mergeTagArray.forEach(element => {
                if(element.name == "Custom") {
                  element.customTag.push({
                    name: dataEvent,
                    value: "[_" + dataEvent + "]"
                  });
                }
              });
            });
          }
          this.chRef.detectChanges();
        },
          error => {
            this._toastr.error(error.message, 'Error');
          }
        );
    }
  }
  onARTemplateEmit(ARTemplateData) {
    this.mergeTagData();
    if (ARTemplateData && ARTemplateData.length > 0) {
      this.chRef.detectChanges();
      ARTemplateData.forEach((ARTemplateDataEvent) => {
        this.customFieldOptions.push({
          name: ARTemplateDataEvent,
          value: "[_" + ARTemplateDataEvent + "]"
        });
        this.mergeTagArray.forEach(element => {
          if(element.name == "Custom") {
            element.customTag.push({
              name: ARTemplateDataEvent,
              value: "[_" + ARTemplateDataEvent + "]"
            });
          }
        });
      });
      this.chRef.detectChanges();
     }
  }
  onOptInNotificationEmit(optInNotificationField) {
    this.mergeTagData();
    if (optInNotificationField.length > 0) {
      this._campaignsService.GetTriggerEventFields(optInNotificationField)
        .subscribe((res: any) => {
          if (res.status == 200) {
            res.data.map(event => {
              this.customFieldOptions.push({
                name: event,
                value: "[_" + event + "]"
              });
              this.mergeTagArray.forEach(element => {
                if(element.name == "Custom") {
                  element.customTag.push({
                    name: event,
                    value: "[_" + event + "]"
                  });
                }
              });
            });
          }
        },
          error => {
            this._toastr.error(error.message, 'Error');
          }
        );
    }
  }
  isFieldsExist(field: string) {
    return this.customFieldOptions.some(function (el) {
      return el.name == field;
    });
  }
  onEventTypeChanged(event) {
    if (event == 4) {
      let textHTML: string = "<p>Please click on the link below to update and opt in to receive company notifications and communication. Thank you and have a great day!</p><p>[OptInLink]</p>";
      let textPlain: string = "Please click on the link below to update and opt in to receive company notifications and communication. Thank you and have a great day!\n\n[OptInLink]\n";
      if (this.createFormGroup.get('accounttypes').value.indexOf(1) !== -1 && this.createFormGroup.get('accounttypes').value.indexOf(21) !== -1) {
        this.messageOptionsFormGroup.get('emailEditor').patchValue(textHTML);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(2) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(11) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(15) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(22) !== -1) {
        this.messageOptionsFormGroup.get('smsMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(3) !== -1) {
        this.messageOptionsFormGroup.get('whatsappMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(4) !== -1) {
        this.messageOptionsFormGroup.get('lineMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(5) !== -1) {
        this.messageOptionsFormGroup.get('fbMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(6) !== -1) {
        this.messageOptionsFormGroup.get('telegramMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(7) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(12) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(16) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(23) !== -1) {
        this.messageOptionsFormGroup.get('smsMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(8) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(24) !== -1) {
        this.messageOptionsFormGroup.get('shortCodeMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(9) !== -1) {
        this.messageOptionsFormGroup.get('notificationMessage').patchValue(textHTML);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(13) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(14) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(17) !== -1) {
        this.messageOptionsFormGroup.get('internationalSMSMMSMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(18) !== -1) {
        this.messageOptionsFormGroup.get('verbNotificationMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(19) !== -1) {
        this.messageOptionsFormGroup.get('nowTechNotificationMessage').patchValue(textPlain);
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(20) !== -1) {
        this.messageOptionsFormGroup.get('shoutMessage').patchValue(textPlain);
      }
    }
    else {
      // this.resetMessages();
    }
  }
  onAccountChanged(event) {
    if (this.createFormGroup.get('eventtype').value == 4) {
      let textHTML: string = "<p>Please click on the link below to update and opt in to receive company notifications and communication. Thank you and have a great day!</p><p>[OptInLink]</p>";
      let textPlain: string = "Please click on the link below to update and opt in to receive company notifications and communication. Thank you and have a great day!\n\n[OptInLink]\n";
      if (this.createFormGroup.get('accounttypes').value.indexOf(1) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(21) !== -1) {
        if (this.blastResponse) {
          if (!this.messageOptionsFormGroup.get('emailEditor').value) {
            this.messageOptionsFormGroup.get('emailEditor').patchValue(textHTML);
          }
        }
        else {
          this.messageOptionsFormGroup.get('emailEditor').patchValue(textHTML);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(2) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(11) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(15) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(22) !== -1) {
        if (!this.messageOptionsFormGroup.get('smsMessage').value) {
          this.messageOptionsFormGroup.get('smsMessage').patchValue(textPlain);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(3) !== -1) {
        if (!this.messageOptionsFormGroup.get('whatsappMessage').value) {
          this.messageOptionsFormGroup.get('whatsappMessage').patchValue(textPlain);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(4) !== -1) {
        if (!this.messageOptionsFormGroup.get('lineMessage').value) {
          this.messageOptionsFormGroup.get('lineMessage').patchValue(textPlain);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(5) !== -1) {
        if (!this.messageOptionsFormGroup.get('fbMessage').value) {
          this.messageOptionsFormGroup.get('fbMessage').patchValue(textPlain);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(6) !== -1) {
        if (!this.messageOptionsFormGroup.get('telegramMessage').value) {
          this.messageOptionsFormGroup.get('telegramMessage').patchValue(textPlain);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(7) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(12) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(16) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(23) !== -1) {
        if (!this.messageOptionsFormGroup.get('smsMessage').value) {
          this.messageOptionsFormGroup.get('smsMessage').patchValue(textPlain);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(8) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(24) !== -1) {
        if (!this.messageOptionsFormGroup.get('shortCodeMessage').value) {
          this.messageOptionsFormGroup.get('shortCodeMessage').patchValue(textPlain);
        }
      }
      
      if (this.createFormGroup.get('accounttypes').value.indexOf(13) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(14) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(17) !== -1) {
        if (!this.messageOptionsFormGroup.get('internationalSMSMMSMessage').value) {
          this.messageOptionsFormGroup.get('internationalSMSMMSMessage').patchValue(textPlain);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(9) !== -1) {
        if (this.blastResponse) {
          if (!this.messageOptionsFormGroup.get('notificationMessage').value) {
            this.messageOptionsFormGroup.get('notificationMessage').patchValue(textHTML);
          }
        }
        else {
          this.messageOptionsFormGroup.get('notificationMessage').patchValue(textHTML);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(18) !== -1) {
        if (!this.messageOptionsFormGroup.get('verbNotificationMessage').value) {
          this.messageOptionsFormGroup.get('verbNotificationMessage').patchValue(textPlain);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(19) !== -1) {
        if (!this.messageOptionsFormGroup.get('nowTechNotificationMessage').value) {
          this.messageOptionsFormGroup.get('nowTechNotificationMessage').patchValue(textPlain);
        }
      }
      if (this.createFormGroup.get('accounttypes').value.indexOf(20) !== -1) {
        if (!this.messageOptionsFormGroup.get('shoutMessage').value) {
          this.messageOptionsFormGroup.get('shoutMessage').patchValue(textPlain);
        }
      }
    }
  }
  resetMessages() {
    if (this.createFormGroup.get('accounttypes').value.indexOf(1) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(21) !== -1) {
      this.messageOptionsFormGroup.get('emailEditor').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(2) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(11) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(15) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(22) !== -1) {
      this.messageOptionsFormGroup.get('smsMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(3) !== -1) {
      this.messageOptionsFormGroup.get('whatsappMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(4) !== -1) {
      this.messageOptionsFormGroup.get('lineMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(5) !== -1) {
      this.messageOptionsFormGroup.get('fbMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(6) !== -1) {
      this.messageOptionsFormGroup.get('telegramMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(7) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(12) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(16) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(23) !== -1) {
      this.messageOptionsFormGroup.get('smsMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(8) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(24) !== -1) {
      this.messageOptionsFormGroup.get('shortCodeMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(9) !== -1) {
      this.messageOptionsFormGroup.get('notificationMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(13) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(14) !== -1 || this.createFormGroup.get('accounttypes').value.indexOf(17) !== -1) {
      this.messageOptionsFormGroup.get('internationalSMSMMSMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(18) !== -1) {
      this.messageOptionsFormGroup.get('verbNotificationMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(19) !== -1) {
      this.messageOptionsFormGroup.get('nowTechNotificationMessage').patchValue("");
    }
    if (this.createFormGroup.get('accounttypes').value.indexOf(20) !== -1) {
      this.messageOptionsFormGroup.get('shoutMessage').patchValue("");
    }
  }
  patchLineItemsLinks(type, links) {
    let control = this.messageOptionsFormGroup.get(type) as FormArray;
    links.forEach((link, index) => {
      let flag = true;
      this.messageOptionsFormGroup.get(type).value.forEach(element => {
        if(element.name == ('Link' + (index+1).toString())) {
          flag = false;
          return
        }
      });
      if(flag) {
        control.push(this._formBuilder.group({
          name: type == "beeLinks" ?  link.url : link.name,
          url: link.url
        }));
      }
    });
    this.chRef.detectChanges();
  }
  getAdvancedItemsLinks(links) {
    let control = this._formBuilder.array([]);
    links.forEach((link, index) => {
      control.push(this._formBuilder.group({
        name: link.name,
        url: link.url
      }));
    });
    return control;
  }
  patchAdvancedItemsLinks(fieldName, index, links, controlName) {
    let control = this.moreOptionFormGroup.get('messagesArray')['controls'][index].get(fieldName).controls[controlName] as FormArray;
    links.forEach((link, index) => {
      control.push(this._formBuilder.group({
        name: controlName == 'beeLinks' ? link.url : link.name,
        url: link.url
      }));
    });
  }
  resumeMes(newMes) {
    this.resumeNewMes = newMes;
  }
  applyFilters(): void {
    this.dialogRef = this._matDialog.open(ContactsFiltersComponent, {
        panelClass: 'contact-filters-dialog',
        disableClose: true,
        data: {
            action: this._data.type,
            dialogType: 'filters',
            dialogTitle: 'Add Filters',
            contactFilter: this.appliedFilters
        }
    });

    this.dialogRef.afterClosed()
        .subscribe((response: any) => {
            this.appliedFilters = this._contactsService.appledFilters.value ? this._contactsService.appledFilters.value : null;
            this.chRef.detectChanges();
            this.getFilterContactsLength();
        });
       
  }
  getFilterContactsLength() {
    let contactReq: any = {
        paidAsRanks: this.appliedFilters && this.appliedFilters.paidAsRanks ? this.appliedFilters.paidAsRanks : [],
        highestRanks: this.appliedFilters && this.appliedFilters.highestRanks ? this.appliedFilters.highestRanks : [],
        countries: this.appliedFilters && this.appliedFilters.countries ? this.appliedFilters.countries : [],
        states:  this.appliedFilters && this.appliedFilters.states ? this.appliedFilters.states : [],
        associateTypes: this.appliedFilters && this.appliedFilters.associateTypes ? this.appliedFilters.associateTypes : [],
        associateStatus: this.appliedFilters && this.appliedFilters.associateStatus ? this.appliedFilters.associateStatus : [],
        languageCodes: this.appliedFilters && this.appliedFilters.languageCodes ? this.appliedFilters.languageCodes : [],
        optFilter: this.appliedFilters && this.appliedFilters.optFilter ? this.appliedFilters.optFilter : [],
        invalidData: this.appliedFilters && this.appliedFilters.invalidData ? this.appliedFilters.invalidData : [],
        fromDate: this.appliedFilters && this.appliedFilters.fromDate ? this.appliedFilters.fromDate : '',
        toDate: this.appliedFilters && this.appliedFilters.toDate ? this.appliedFilters.toDate : '',
        groupRangeFrom: this.appliedFilters && this.appliedFilters.groupRangeFrom ? this.appliedFilters.groupRangeFrom : 0,
        groupRangeTo: this.appliedFilters && this.appliedFilters.groupRangeTo ? this.appliedFilters.groupRangeTo : 0,
        hasActiveAutoship: this.appliedFilters ? this.appliedFilters.hasActiveAutoship : null,
        channels: this.appliedFilters && this.appliedFilters.channels ? this.appliedFilters.channels : [],
        customFields: this.appliedFilters && this.appliedFilters.customFields ? this.appliedFilters.customFields : null,
        customFieldsCompare: this.appliedFilters && this.appliedFilters.customFieldsCompare ? this.appliedFilters.customFieldsCompare : null,
        sponsorId: this.appliedFilters && this.appliedFilters.sponsorId ? this.appliedFilters.sponsorId : 0
    }
    this._restService.Post('/Contact/GetAllContactsCount?IsAgent=' + this._pageService.isAgentView, contactReq)
        .subscribe((response: any) => {
            if (response.status == 200) {
                this.filterContactsCount = response.data;
                this.chRef.detectChanges();
            }
        },
        error => {
            this._toastr.error(error.message, 'Error');
        });
  }
  mergeTagData() {
    this.customFieldOptions = [
      {
        name: " Associate ID",
        value: "[AssociateId]"
      },
      {
        name: "First Name",
        value: "[firstName]"
      },
      {
        name: "Last Name",
        value: "[lastName]"
      },
      {
        name: "Phone",
        value: "[Phone]"
      },
      {
        name: "Email",
        value: "[Email]"
      },
      {
        name: "Highest Rank",
        value: "[HighestRank]"
      },
      {
        name: "Paid Rank",
        value: "[paidAsRank]"
      },
      {
        name: "WebAlias",
        value: "[WebAlias]"
      },
      {
        name: "Associate Status",
        value: "[AssociateStatus]"
      },
      {
        name: "Associate Type",
        value: "[AssociateType]"
      },
      {
        name: "Company Logo",
        value: "[CompanyLogoURL]"
      },
      {
        name: "Company Name",
        value: "[CompanyName]"
      },
      {
        name: "CompanyAddress",
        value: "[CompanyAddress]"
      },
      {
        name: "CompanyCity",
        value: "[CompanyCity]"
      },
      {
        name: "CompanyState",
        value: "[CompanyState]"
      },
      {
        name: "CompanyZip",
        value: "[CompanyZipCode]"
      },
      {
        name: "BackOffice Id",
        value: "[BackOfficeId]"
      },
      {
        name: "Sponsor First Name",
        value: "[_SponsorfirstName]"
      },
      {
          name: "Sponsor Last Name",
          value: "[_SponsorlastName]"
      },
      {
          name: "Sponsor Email",
          value: "[_Sponsoremail]"
      },
      {
          name: "Sponsor Phone Number",
          value: "[_SponsorphoneNumber]"
      },
      {
          name: "Sponsor WebAlias",
          value: "[_SponsorWebAlias]"
      },
      {
          name: "Sponsor ID",
          value: "[_SponsorAssociateId]"
      }
    ];
    this.mergeTagArray = [
      {
        name: 'Basic',
        basicTag: [
          {
            name: " Associate ID",
            value: "[AssociateId]"
          },
          {
            name: "First Name",
            value: "[firstName]"
          },
          {
            name: "Last Name",
            value: "[lastName]"
          },
          {
            name: "Phone",
            value: "[Phone]"
          },
          {
            name: "Email",
            value: "[Email]"
          },
          {
            name: "Highest Rank",
            value: "[HighestRank]"
          },
          {
            name: "Paid Rank",
            value: "[paidAsRank]"
          },
          {
            name: "WebAlias",
            value: "[WebAlias]"
          },
          {
            name: "Associate Status",
            value: "[AssociateStatus]"
          },
          {
            name: "Associate Type",
            value: "[AssociateType]"
          },
          {
            name: "Company Logo",
            value: "[CompanyLogoURL]"
          },
          {
            name: "Company Name",
            value: "[CompanyName]"
          },
          {
            name: "CompanyAddress",
            value: "[CompanyAddress]"
          },
          {
            name: "CompanyCity",
            value: "[CompanyCity]"
          },
          {
            name: "CompanyState",
            value: "[CompanyState]"
          },
          {
            name: "CompanyZip",
            value: "[CompanyZipCode]"
          },
          {
            name: "BackOffice Id",
            value: "[BackOfficeId]"
          }
        ],
      },
      {
        name: 'Sponsor',
        sponsorTag: [
          {
            name: "Sponsor First Name",
            value: "[_SponsorfirstName]"
          },
          {
              name: "Sponsor Last Name",
              value: "[_SponsorlastName]"
          },
          {
              name: "Sponsor Email",
              value: "[_Sponsoremail]"
          },
          {
              name: "Sponsor Phone Number",
              value: "[_SponsorphoneNumber]"
          },
          {
              name: "Sponsor WebAlias",
              value: "[_SponsorWebAlias]"
          },
          {
              name: "Sponsor ID",
              value: "[_SponsorAssociateId]"
          }
        ],
      },
      {
        name: 'Custom',
        customTag: [],
      },
    ];
    for(let i = 1; i <=20; i++) {
      let tag = {
          name: "Custom"+i,
          value: "[Custom"+i+"]"
      };
      this.customFieldOptions.push(tag);
      this.mergeTagArray[2].customTag.push(tag);
    }
  }
  onSaveClick() {
    this._campaignsService.onSaveButtonClick.next(true);
  }
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("add-campaign-dialog");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("newcampagain-content").style.maxHeight = "65vh";
    document.getElementById("newcampagain-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("add-campaign-dialog");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("newcampagain-content").style.maxHeight = "100vh";
    document.getElementById("newcampagain-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }

}
