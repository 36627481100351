import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PageService } from 'app/main/pages/pages.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { ProvidersService } from 'app/main/pages/providers/providers.service';

@Component({
  selector: 'app-add-unify-videos',
  templateUrl: './add-unify-videos.component.html',
  styleUrls: ['./add-unify-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AddUnifyVideosComponent implements OnInit {
  action: string;
  videoForm: FormGroup;
  dialogTitle: string;
  saveButtom: string;
  providerId: any;
  fileType: string = "";
  constructor(
    public matDialogRef: MatDialogRef<AddUnifyVideosComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _pageService: PageService,
    private _toastr: ToastrService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _providersService: ProvidersService
  ) {
    this.action = this._data.action;
    this.providerId = this._data.providerId;
    this.videoForm = this.createVideoForm();
    if (this.action === 'add') {
      this.dialogTitle = 'Add Video';
      this.saveButtom = 'ADD';
    }
    else {
      this.dialogTitle = 'Edit Video';
      this.saveButtom = 'SAVE';
    }
  }

  ngOnInit() {
    if (this._data && this._data.video) {
      this.videoForm.patchValue({
        name: this._data.video.name,
        fileURL: this._data.video.fileURL,
        isURL: this._data.video.isURL
      });
    }
    if (this._data && this._data.video && this.action == 'edit') {
      if (this._data.video['fileURL'].includes('video')) {
        this.fileType = "Video";
      }
      else if (this._data.video['fileURL'].includes('pdf')) {
        this.fileType = "PDF";
      }
    }
  }
  createVideoForm(): FormGroup {
    return this._formBuilder.group({
      name: ['', [Validators.required]],
      fileURL: ['', [Validators.required]],
      isURL: [false]
    });
  }
  save() {
    if (this.videoForm.invalid) {
      this._pageService.validateAllFormFields(this.videoForm);
      if (this.videoForm.get('fileURL').value == '') {
        this._toastr.warning('Video file is required', 'Warning');
      }
      return false;
    }
    var req: any = {
      "name": this.videoForm.get('name').value,
      "fileURL": this.videoForm.get('fileURL').value,
      "fileType": this.videoForm.get('isURL').value ? 'Video' : this.fileType,
      "categoryId": this._data.categoryId,
      "isURL": this.videoForm.get('isURL').value,
    };
    if (this._data && this._data.video) {
      req.id = this._data.video.id;
      req.createdAt = this._data.video.createdAt;
    }
    this._fuseSplashScreenService.show();
    this._providersService.AddEditFile(req, this._data.pageName)
      .subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        if (res.status == 200) {
          this._toastr.success('File saved successfully', 'Success');
          this.matDialogRef.close(this.videoForm);
        } else {
          this._toastr.error(res.error, 'Error');
        }
      },
        error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error.error.error, 'Error');
          console.log(error);
        }
      );
  }
  changeFileType(event) {
    this.videoForm.get('fileURL').patchValue('');
  }
  uploadedFile(event) {
    if (event.target.files.length != 0) {
      let fileUploaded = event.target.files[0];
      if (fileUploaded.type.includes('video')) {
        this.fileType = "Video";
      }
      else if (fileUploaded.type.includes('pdf')) {
        this.fileType = "PDF";
      }
      else {
        this._toastr.warning('file must be video or pdf type', 'Warning');
        return false;
      }
      let fileName = fileUploaded.name;
      var formData = new FormData();
      formData.append('formFile', fileUploaded);
      this._fuseSplashScreenService.show();
      this._providersService.UploadMediaFile(formData, this._data.pageName).subscribe(
        (res: any) => {
          this._fuseSplashScreenService.hide();
          if (res.status == 200) {
            this.videoForm.get('fileURL').patchValue(res.data);
            this._toastr.success('File uploaded successfully', 'Success');
          }
        },
        error => {
          this._fuseSplashScreenService.hide();
          console.log(error);
          this._toastr.error('Unable to upload', 'Error');
        }
      );
    }
  }
  removeVideo() {
    this.videoForm.get('fileURL').patchValue('');
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
